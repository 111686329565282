@if (channel$ | async; as channel) {
  <form wenFormStore
    class="wen-form"
    [formGroup]="channelVisibilitySettingsFormGroup"
    wenIosKeyboardSticky>
    <div class="wen-form-group" [wen-test-id]="'wen-channel-visibility-settings-form'">
      <div class="wen-form-field-wrapper">
        <div portal-caption class="wen-form-field-label">{{ "CHANNEL_VISIBILITY_FIELD_LABEL" | translate }}</div>
        <wen-selection-list
          [formControlName]="'visibility'"
          [selectionItems]="visibilityPossibleValues"
          [selectionModel]="visibilitySelectionModel"
          [selectionOptionPosition]="'postfix'"
          [selectionOptionBorderType]="'borderless'">
        </wen-selection-list>
        @if (isRequiredError('visibility')) {
          <mat-error portal-caption>{{ "CHANNEL_VISIBILITY_FIELD_ERROR" | translate }}</mat-error>
        }
      </div>
    </div>
    @if (networkAccessEnabled) {
      <div class="wen-form-group">
        <div class="wen-form-field-wrapper">
          <div portal-caption class="wen-form-field-label">{{ "CHANNEL_NETWORK_ACCESS_FIELD_LABEL" | translate }}</div>
          <wen-selection-list
            [formControlName]="'networkAccess'"
            [selectionItems]="netWorkAccessPossibleValues"
            [selectionModel]="networkAccessSelectionModel"
            [selectionOptionPosition]="'postfix'"
            [selectionOptionBorderType]="'borderless'">
          </wen-selection-list>
          @if (isRequiredError('networkAccess')) {
            <mat-error portal-caption>{{ "CHANNEL_NETWORK_ACCESS_FIELD_ERROR" | translate }}</mat-error>
          }
        </div>
      </div>
    }
  </form>
}
