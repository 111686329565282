import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { ConfirmationDialogComponent, ConfirmationDialogData, OverlayManager } from '@portal/wen-components';
import { combineLatest, filter, first, map, merge, switchMap, tap } from 'rxjs';
import { mapWithFirstFrom } from '../../../../common/operators/map-with-first-from';
import { selectCurrentUserData } from '../../../auth/auth.selectors';
import { RootState } from '../../../root/public-api';
import { acknowledgeChannelJoinDenial, cleanUpPendingJoinRequestChannel, handleChannelJoinRequest, subscribeToJoinRequestChannelUpdates, withdrawJoinRequestForTheCurrentChannel } from '../../channel.actions';
import { selectCurrentChannel } from '../../channel.selectors';

@Injectable()
export class ChannelJoinRequestEffects {

  private readonly currentUserData$ = this.store.pipe(
    select(selectCurrentUserData)
  );

  joinRequestDecisionEffect$ = createEffect(() => this.actions$.pipe(
    ofType(handleChannelJoinRequest),
    mapWithFirstFrom(() => this.currentUserData$),
    tap(([{ requestId, subjectName, accepted }, { userId }]) => {
      let dialogData: Partial<ConfirmationDialogData> = {
        dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL'),
      };
      if (accepted) {
        dialogData = {
          ...dialogData,
          okLabel: this.translateService.instant('CHANNEL_ACCEPT_JOIN_REQUEST_OK_BUTTON_LABEL'),
          header: this.translateService.instant('CHANNEL_ACCEPT_JOIN_REQUEST_CONFIRMATION_DIALOG_TITLE'),
          content: this.translateService.instant('CHANNEL_ACCEPT_JOIN_REQUEST_CONFIRMATION_DIALOG_CONTENT', { name: subjectName }),
        };
      } else {
        dialogData = {
          ...dialogData,
          okLabel: this.translateService.instant('CHANNEL_DENY_JOIN_REQUEST_OK_BUTTON_LABEL'),
          header: this.translateService.instant('CHANNEL_DENY_JOIN_REQUEST_CONFIRMATION_DIALOG_TITLE'),
          content: this.translateService.instant('CHANNEL_DENY_JOIN_REQUEST_CONFIRMATION_DIALOG_CONTENT', { name: subjectName }),
        };
      }
      const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, dialogData, { disableClose: true });
      dialog.afterClosed().pipe(
        first(),
        filter(data => data?.result === 'ok')
      ).subscribe(() => {
        if (accepted) {
          this.socketIoService.channelInvitation.acceptJoinRequest.emit({ userId, inviteId: requestId });
        } else {
          this.socketIoService.channelInvitation.denyJoinRequest.emit({ userId, inviteId: requestId });
        }
      });
    })
  ), { dispatch: false });

  joinRequestDenialAcknowledgementEffect$ = createEffect(() => this.actions$.pipe(
    ofType(acknowledgeChannelJoinDenial),
    mapWithFirstFrom(() => this.currentUserData$),
    tap(([{ requestId }, { userId }]) => {
      this.socketIoService.channelInvitation.acknowledgeDenial.emit({ userId, inviteId: requestId });
    })
  ), { dispatch: false });

  joinRequestWithdrawCleanupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToJoinRequestChannelUpdates),
    first(),
    switchMap(() => merge(
      this.socketIoService.channelInvitation.withdrawJoinRequest.listen,
      this.socketIoService.channelInvitation.acceptJoinRequest.listen,
      this.socketIoService.channelInvitation.acknowledgeDenial.listen,
    ).pipe(
      map(({ channelId }) => cleanUpPendingJoinRequestChannel({ channelId }))
    ))
  ));

  joinRequestWithdrawEffect$ = createEffect(() => this.actions$.pipe(
    ofType(withdrawJoinRequestForTheCurrentChannel),
    switchMap(() => combineLatest([
      this.store.pipe(select(selectCurrentChannel), first()),
      this.store.pipe(select(selectCurrentUserData), first())
    ])),
    tap(([{ invite: { id } }, { userId }]) => this.socketIoService.channelInvitation.withdrawJoinRequest.emit({ userId, inviteId: id })),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
    private translateService: TranslateService,
    private overlayManager: OverlayManager,
  ) { }
}
