import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { filter, first, of, switchMap } from 'rxjs';
import { InputWithChipModel } from '../../../../shared/components/input-with-chips/input-with-chips.component';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { addMembersToRoom } from '../../chat/actions/chat-member.actions';
import { DataObjectType } from '../../common/data-objects';
import { RootState } from '../../root/public-api';
import { clearFormValues } from '../form.actions';
import { selectEditFormById } from '../form.selectors';

@Injectable()
export class ChatMembersFormEffects {

  addGroupChatMembers$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.CHAT_MEMBERS),
    switchMap(({ formId }) => this.store.pipe(
      select(selectEditFormById(formId)),
      first()
    )),
    switchMap((editForm) => {
      const { formId, changedValues } = editForm;
      const users: InputWithChipModel[] = changedValues.users;
      const userIds = users.map(user => user.id);
      const actions: Action[] = [];
      actions.push(addMembersToRoom({ userIds }));
      actions.push(clearFormValues({ formId }));
      return actions;
    }),
  ));

  constructor(
    private formStoreMediator: FormStoreMediator,
    private store: Store<RootState>
  ) { }

}

