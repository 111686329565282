import { NgModule } from '@angular/core';
import { EventDatePipe } from '../../shared/pipes/event-date-formatter.pipe';
import { SharedModule } from '../../shared/shared.module';
import { EventDetailModule } from './event-detail/event-detail.module';
import { ExploreEventModule } from './event-explore/explore-event.module';
import { EventListModule } from './event-list/event-list.module';
import { EventDatesLoader } from './providers/event-dates-loader';

@NgModule({
  imports: [
    SharedModule,
    EventDetailModule,
    EventListModule,
    ExploreEventModule
  ],
  providers: [
    EventDatePipe,
    EventDatesLoader,
  ]
})
export class EventsModule {
}
