import { Injectable } from '@angular/core';
import { SocketIoBackend } from '../../backend-api/socket-io.backend';
import { SocketIoConfig } from '../../config';

@Injectable()
export abstract class SocketIoService extends SocketIoBackend {
  constructor(protected config: SocketIoConfig) {
    super(config);
  }
}
