import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ProgressProcessType, ProgressState } from '../../../core/store/ui/ui.state';
import { AppHeaderController } from '../../../frame/components/header/components/app-header/app-header-controller';

@Component({
  selector: 'wen-form-progress',
  templateUrl: './form-progress.component.html',
  styleUrls: ['./form-progress.component.scss']
})
export class FormProgressComponent implements OnInit, OnDestroy {

  showProgress: boolean;

  @Input() showCurtain = true;

  @Input() set hasProgress(newValue: ProgressState) {
    this.showProgress = newValue.hasProgress;
    if (!this.appHeaderController) {
      return;
    }
    if (!newValue.hasProgress) {
      this.appHeaderController.setRightCommandEnablement(true);
      this.appHeaderController.setLeftCommandEnablement(true);
    } else if (newValue.progressType === ProgressProcessType.FOREGROUND) {
      this.appHeaderController.setRightCommandEnablement(false);
      this.appHeaderController.setLeftCommandEnablement(false);
    } else if (newValue.progressType === ProgressProcessType.BACKGROUND) {
      this.appHeaderController.setRightCommandEnablement(false);
    }
  }

  constructor(
    @Optional() private appHeaderController: AppHeaderController
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (!this.appHeaderController) {
      return;
    }
    this.appHeaderController.setRightCommandEnablement(true);
    this.appHeaderController.setLeftCommandEnablement(true);
  }

}
