<wen-form-progress [hasProgress]="hasProgress$ | async"></wen-form-progress>
<form wenFormStore class="wen-form" [formGroup]="inviteFormGroup">
  <div class="wen-form-group wen-invite-header">
    <wen-invitees-display [inviteeData]="importedInviteeData$ | async" [headerImage]="'/assets/wen-widget/image/invite-to-network-page-image.svg'"
      [extraCount]="manualInviteesCount" [importeeControlName]="'importedInvitees'">
      <span wen-invite-counter-label>{{ "INVITE_PAGE_COUNTER_LABEL" | translate }}</span>
      @if (hasImportedData$ | async) {
        <span wen-invite-counter-description>{{ "INVITE_PAGE_COUNTER_DESCRIPTION" | translate }}</span>
      }
    </wen-invitees-display>
  </div>
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper wen-invite-email-input">
      <mat-form-field (click)="chipInput.focus()">
        <mat-label>{{ emailInputLabel$ | async }}</mat-label>
        <mat-chip-grid class="wen-chip-grid" [required]="(hasImportedData$ | async) === false" #chipGrid>
          @for (invitee of inviteesValue; track invitee) {
            <mat-chip-row disableRipple class="wen-chip-row"
              [value]="invitee"
              (removed)="remove(invitee)">
              <div class="wen-chip-row-content">
                @if (isInvalidEmail(invitee.email)) {
                  <wen-icon class="wen-icon-medium wen-invitee-field-invalid-email"
                  [iconName]="'error_icon'"></wen-icon>
                }
                <span class="wen-chip-label">{{ invitee.email }}</span>
              </div>
              <wen-icon matChipRemove iconName="close" class="wen-icon-medium wen-chip-button"></wen-icon>
            </mat-chip-row>
          }
          <input #chipInput enterkeyhint="send" autocomplete="off" [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes" [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)" />
          </mat-chip-grid>
          @if (manualInviteesCount === 1) {
            <div class="wen-invite-email-input-sub-fix" portal-subheading-1>
              {{ "INVITE_PAGE_EMAIL_FIELD_SUB_FIX_TEXT.0" | translate }}
            </div>
          }
          @if (manualInviteesCount > 1) {
            <div class="wen-invite-email-input-sub-fix" portal-subheading-1>
              {{ "INVITE_PAGE_EMAIL_FIELD_SUB_FIX_TEXT.1" | translate: { count: manualInviteesCount } }}
            </div>
          }
          @if (showError) {
            <mat-error portal-caption>{{ "INVITE_PAGE_EMAIL_FIELD_ERROR_LABEL" | translate }}</mat-error>
          }
          @if (showHint) {
            <mat-hint portal-caption>
              <wen-icon class="wen-icon-small" [iconName]="'error_icon'"></wen-icon>
              <span class="portal-caption" portal-caption>{{ "INVITE_PAGE_EMAIL_FIELD_HINT_LABEL" | translate }}</span>
            </mat-hint>
          }
        </mat-form-field>
      </div>
      <div class="wen-form-group-hint" portal-caption>{{ emailDescriptionLabel$ | async }}</div>
    </div>
    <div class="wen-form-group">
      <div class="wen-form-field-wrapper">
        <mat-form-field>
          <mat-label>{{ "INVITE_PAGE_MESSAGE_FIELD_LABEL" | translate }}</mat-label>
          <portal-field [formControlName]="'emailBody'" [multiLine]="true" [rows]="{ min: 1, max: 5 }"></portal-field>
        </mat-form-field>
      </div>
      <div class="wen-form-group-hint" portal-caption>{{ "INVITE_PAGE_MESSAGE_FIELD_DESCRIPTION" | translate }}</div>
    </div>
  </form>
