import type * as SentryTypes from '@sentry/angular';

export const isRelevantException = (event: SentryTypes.ErrorEvent) => {
  const exceptions = event?.exception?.values;
  if (exceptions?.length > 0) {
    const isExpressionChangedError = exceptions[0]?.value?.includes('ExpressionChanged');
    if (isExpressionChangedError) {
      return false;
    }
  }
  return true;
};

const ignoredBreadcrumbRegexes = () => [
  /\/assets\/.*\/lottie\/.*photoloading.json$/gi,
  /\/assets\/.*\/icons\/.*\.svg/gi,
];

export const isRelevantBreadCrumb = (breadcrumb: SentryTypes.Breadcrumb) => {
  if (!breadcrumb) {
    return null;
  }
  if (breadcrumb.category === 'xhr') {
    const url = breadcrumb?.data?.url || '';
    const isIgnored = ignoredBreadcrumbRegexes().some(regX => regX.test(url));
    if (isIgnored) {
      return false;
    }
  }
  return true;
};
