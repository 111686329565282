import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EmbedDTOType, generateId } from '@portal/wen-backend-api';
import { filter, first } from 'rxjs';
import { getMediaType } from '../../../shared/components/message-box/components/embeds/embed-media/media-utils';
import { updateDraftChatMessage } from '../../store/chat/chat.actions';
import { PendingEmbedType } from '../../store/common/models';
import { MediaPreviewHandler } from './media-preview-handler';
import { MediaValidator } from './media-validator';

@Injectable()
export class DraftChatMediaPreviewHandler extends MediaPreviewHandler {
  constructor(
    private store: Store,
    mediaValidator: MediaValidator,
  ) {
    super(mediaValidator);
  }

  handleMediaPreview(file: File): void {
    this.mediaValidator.validate(file).pipe(
      first(),
      filter(result => Boolean(result))
    ).subscribe((result) => {
      this.store.dispatch(updateDraftChatMessage({
        message: {
          contextId: null,
          fileEmbed: {
            id: generateId(),
            type: EmbedDTOType.MEDIA,
            subType: getMediaType(result),
            file: result,
            pendingType: PendingEmbedType.UNSAFE
          }
        }
      }));
    });
  }
}
