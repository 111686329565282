import { Component, Inject, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SimpleUserDTO, SocketIoService } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { mapWithFirstFrom } from '../../../core/common/operators/map-with-first-from';
import { selectCurrentUserData } from '../../../core/store/auth/auth.selectors';
import { handleChannelJoinRequest } from '../../../core/store/channel/channel.actions';
import { RootState } from '../../../core/store/root/public-api';
import { selectRouteParam } from '../../../core/store/root/root.selectors';
import { IMAGE_PROXY_BASE_URL } from '../../../frame/api/tokens';
import { channelViewIdentifier } from '../tokens';

@Component({
  selector: 'wen-pending-channel-join-request',
  templateUrl: './pending-channel-join-request.component.html',
  styleUrls: ['./pending-channel-join-request.component.scss']
})
export class PendingChannelJoinRequestComponent implements OnInit {

  pendingJoinRequests$: Observable<{ id: string; user: SimpleUserDTO }[]>;

  constructor(
    private readonly store: Store<RootState>,
    private socketIoService: SocketIoService,
    private translateService: TranslateService,
    @Inject(IMAGE_PROXY_BASE_URL) private imageProxyBaseUrl: string,
  ) { }

  ngOnInit(): void {
    this.pendingJoinRequests$ = this.store.pipe(
      select(selectRouteParam(channelViewIdentifier)),
      mapWithFirstFrom(() => this.store.pipe(select(selectCurrentUserData))),
      switchMap(([channelId, { userId }]) => {
        this.socketIoService.channelInvitation.pendingJoinRequestList.emit({ userId, channelId });
        return this.socketIoService.channelInvitation.pendingJoinRequestList.listen.pipe(
          map(joinRequests => {
            const anonymousUser = this.translateService.instant('ANONYMOUS_USER');
            return joinRequests.map(joinRequest => {
              const subjectUserId = joinRequest.subjectUserId ?? anonymousUser;
              const displayName = joinRequest.subjectUserName ?? anonymousUser;
              const baseUrl = this.imageProxyBaseUrl ?? location.origin;
              const avatarUrl = joinRequest.subjectAvatarUrl
                ? `${baseUrl}${joinRequest.subjectAvatarUrl}`.replace('{size}', `100x`)
                : joinRequest.subjectAvatarUrl;
              return {
                id: joinRequest.id,
                user: {
                  userId: subjectUserId,
                  displayName,
                  avatarUrl
                }
              };
            });
          })
        );
      })
    );
  }

  acceptRequest(requestId: string, subjectName: string) {
    this.store.dispatch(handleChannelJoinRequest({ requestId, subjectName, accepted: true }));
  }

  denyRequest(requestId: string, subjectName: string) {
    this.store.dispatch(handleChannelJoinRequest({ requestId, subjectName, accepted: false }));
  }
}
