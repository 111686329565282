import { WenRoutes } from '../../../frame/routing/types';
import { addChannelInfoRoute, addChannelInteractionsRoute, addChannelRestrictionsRoute, addChannelVisibilityRoute, channelAddAdminRoute, channelAddContentProviderRoute, channelAddWelcomeRoute, channelAdminListRoute, channelContentProviderListRoute, channelDetailRoute, channelDiscoveryRoute, channelEditRoute, channelExploreRoute, channelFeaturedViewRoute, channelInteractionSettingsRoute, channelListRoute, channelMessageDetailRoute, channelPostEditorRoute, channelPostLocationSelectorRoute, channelRestrictionSettingsRoute, channelSchedulesRoute, channelSettingsRoute, channelSubscriberListRoute, channelViewRoute, channelVisibilitySettingsRoute, pendingChannelJoinRequestsRoute } from './channel.routes';

export const singleOutletChannelRoutes: WenRoutes = [
  channelListRoute,
  channelDiscoveryRoute,
  channelExploreRoute,
  channelDetailRoute,
  channelSettingsRoute,
  channelPostEditorRoute,
  channelPostLocationSelectorRoute,
  channelSchedulesRoute,
  channelEditRoute,
  channelVisibilitySettingsRoute,
  channelInteractionSettingsRoute,
  channelRestrictionSettingsRoute,
  pendingChannelJoinRequestsRoute,
  channelAdminListRoute,
  channelAddAdminRoute,
  channelAddContentProviderRoute,
  channelContentProviderListRoute,
  channelSubscriberListRoute,
  channelViewRoute,
  channelFeaturedViewRoute,
  channelAddWelcomeRoute,
  addChannelInfoRoute,
  addChannelVisibilityRoute,
  addChannelRestrictionsRoute,
  addChannelInteractionsRoute,
  {
    path: '**',
    redirectTo: 'list'
  }
];

export const singleOutletMessageRoutes: WenRoutes = [
  channelMessageDetailRoute
];
