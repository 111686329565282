import { Injectable } from '@angular/core';
import { ChatMessagePreprocessorResult, EncryptionAlgorithm, EncryptionType, isEditRelation, isQuoteRelation, RelationTypes, SendMessageContent, SendMessageEventDTO, SendToRoomEventPayload, ToRoomEventType } from '@portal/wen-backend-api';
import { MegOlmEncryptionResult, OlmDevice, WenChatClient } from '@portal/wen-chat-client';
import { map } from 'rxjs/operators';
import { WenStorageService } from '../../storage/wen-storage.service';
import { WenOAuthService } from '../../user-management/wen-oauth.service';
import { EncryptedEditMessageEventResult } from './edit-message-encryptor';
import { EncryptedQuoteSendMessageEventResult } from './quote-message-encryptor';

export class EncryptedSendMessageEventResult implements ChatMessagePreprocessorResult {

  constructor(
    public readonly encryptionResult: MegOlmEncryptionResult,
    public readonly sendMessageContent: SendMessageContent<false>,
    private readonly senderCurve25519: string,
    private readonly insertUserId: string,
    private readonly senderDeviceId: string,
    private readonly roomId: string,
  ) { }

  getPayload(): SendToRoomEventPayload {
    return {
      roomId: this.roomId,
      payload: this.asSendToRoomPayload()
    };
  }

  private asSendToRoomPayload() {
    if (!this.encryptionResult) {
      return;
    }
    const { usedSession: { sessionId } } = this.encryptionResult;
    const payload: SendMessageEventDTO<true> = {
      type: EncryptionType.ENCRYPTED,
      eventType: ToRoomEventType.SEND_MESSAGE,
      content: {
        algorithm: EncryptionAlgorithm.Megolm,
        ciphertext: { type: 0, body: this.encryptionResult.encrypted },
        senderKey: this.senderCurve25519,
        deviceId: this.senderDeviceId,
        sessionId
      },
      senderUserId: this.insertUserId,
      roomId: this.roomId
    };
    return payload;
  }

}

@Injectable()
export class SendMessageEncryptor {

  constructor(
    private chatClient: WenChatClient,
    private olmDevice: OlmDevice,
    private oAuthService: WenOAuthService,
    private storageService: WenStorageService,
  ) { }

  encryptChatMessage(roomId: string, content: SendMessageContent<false>, relation?: RelationTypes) {
    const { userId } = this.oAuthService.getUserData();
    const deviceId = this.storageService.getDeviceId();
    const { curve25519 } = this.olmDevice.getIdentityKeys();
    return this.chatClient.encryptGroupMessage(roomId, JSON.stringify(content)).pipe(
      map((encryptionResult) => {
        if (!relation) {
          return new EncryptedSendMessageEventResult(
            encryptionResult, content, curve25519, userId, deviceId, roomId
          );
        }
        if (isEditRelation(relation)) {
          return new EncryptedEditMessageEventResult(
            encryptionResult, content, curve25519, userId, deviceId, roomId, relation
          );
        }
        if (isQuoteRelation(relation)) {
          return new EncryptedQuoteSendMessageEventResult(
            encryptionResult, content, curve25519, userId, deviceId, roomId, relation
          );
        }
      })
    );
  }

}
