import { createSelector } from '@ngrx/store';
import { selectChatState, selectCurrentRoom, selectRoomById } from '../chat.selectors';
import { scheduledChatMessagesEntityAdapter } from '../chat.state';

const {
  selectAll: scheduledChatMessageSelectAll,
} = scheduledChatMessagesEntityAdapter.getSelectors();

export const selectCurrentRoomScheduledChatMessages = createSelector(
  selectCurrentRoom,
  (currentRoom) => {
    if (!currentRoom?.scheduledMessages) {
      return [];
    }
    return scheduledChatMessageSelectAll(currentRoom.scheduledMessages);
  }
);

export const selectScheduledChatMessageCollectionExtrasForCurrentRoom = createSelector(
  selectCurrentRoom,
  (currentRoom) => {
    const scheduledMessagesCollection = currentRoom?.scheduledMessages;
    if (!scheduledMessagesCollection) {
      return {};
    }
    return {
      hasMoreNewer: scheduledMessagesCollection.hasMoreNewer,
      hasMoreOlder: scheduledMessagesCollection.hasMoreOlder,
      totalCount: scheduledMessagesCollection.totalCount
    };
  }
);

export const selectScheduledChatMessageCollectionExtrasForRoom = createSelector(
  selectRoomById,
  (roomByIdSelector) => (roomId: string) => {
    const roomEntity = roomByIdSelector(roomId);
    const scheduledMessagesCollection = roomEntity?.scheduledMessages;
    if (!scheduledMessagesCollection) {
      return {};
    }
    return {
      hasMoreNewer: scheduledMessagesCollection.hasMoreNewer,
      hasMoreOlder: scheduledMessagesCollection.hasMoreOlder,
      totalCount: scheduledMessagesCollection.totalCount
    };
  }
);

export const selectScheduledChatMessageByEventId = createSelector(
  selectChatState,
  (state) => (roomId: string, eventId: string) => {
    const roomEntity = state.rooms.entities[roomId];
    if (!roomEntity) {
      return null;
    }
    const history = scheduledChatMessageSelectAll(roomEntity.scheduledMessages) || [];
    const messageEntity = history.find(message => {
      const messageEventId = message?.eventId;
      return messageEventId && messageEventId === eventId;
    });
    return messageEntity;
  }
);
