import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, Input } from '@angular/core';
import { ReactiveFormsModule, FormGroupDirective, FormGroup, FormBuilder } from '@angular/forms';
import { MultiSelectControlProps } from '@portal/wen-backend-api';
import { CanCreateControl } from '../../form-handling/control-creation';
import { FormValueManager } from '../../form-handling/form-value-manager';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'wen-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, MatSlideToggleModule],
  standalone: true
})
export class MultiselectComponent implements OnInit, CanCreateControl {
  private parentFormGroup = inject(FormGroupDirective);
  parentGroup: FormGroup;
  private formValueManager = inject(FormValueManager);
  private formBuilder = inject(FormBuilder);

  multiSelectGroup: FormGroup;
  contextValue: MultiSelectControlProps;
  labelMap = new Map<string, string>();

  @Input() set context(value: MultiSelectControlProps) {
    this.contextValue = value;
  }

  ngOnInit() {
    this.parentGroup = this.parentFormGroup.form;
    this.createControl(this.contextValue);
  }

  createControl(context: MultiSelectControlProps) {
    const formValue = this.formValueManager.getFormValue();
    const multiSelectData = formValue[context.name];
    this.multiSelectGroup = this.formBuilder.group({});

    context.possibleValues.forEach(possibleValue => {
      this.labelMap.set(possibleValue.value, possibleValue.label);
      this.multiSelectGroup.addControl(possibleValue.value, this.formBuilder.control(multiSelectData[possibleValue.value]));
    });
    this.parentGroup.addControl(context.name, this.multiSelectGroup);
  }

  get possibleValues() {
    return this.parentGroup.controls[this.contextValue.name].value;
  }
}
