<form class="wen-form" wenFormStore [formGroup]="chatGroupUsers" wenFocusFirstInput wenIosViewportFix>
  <div class="wen-form-group">
    <wen-input-with-chips cdkScrollable tabindex="-1" wenIosKeyboardScrollable [formControlName]="'users'"
      [placeholderTranslation]="'SEARCH_USER_TO_CHAT_SEARCH_PLACEHOLDER'" [selectedItems]="selectedChips$ | async"
      [maxNumberOfSelectableItems]="maxNumberOfSelectableUsers" [keepFocused]="true"
      (searchTermChange)="onSearchChange($event)" [itemCount]="selectedChipsCount$ | async">
    </wen-input-with-chips>
  </div>
  <div class="wen-search-list-wrapper wen-form-group">
    <wen-chat-selection-list [selectionModeEnabled]="selectionModeEnabled$ | async"
      (listItemClicked)="handleListItemClick($event)"
      [alwaysDisabledIds]="alwaysDisabledItemIds$ | async"></wen-chat-selection-list>
  </div>
</form>