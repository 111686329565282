import { EntityState } from '@ngrx/entity';
import { channelAdapter, ChannelEntity, ChannelMessageEntity, channelMessagesAdapter, ChannelState } from '../channel.state';
import { findChannelById } from './state-adapter-utils';

export const extraMessagesHelper = {

  upsertOne: (
    message: ChannelMessageEntity,
    channelState: ChannelState
  ) => {
    let newChannels = channelState.channels;
    const { channelId } = message;
    let targetChannel = findChannelById(channelId, newChannels);
    if (!targetChannel) {
      targetChannel = { id: channelId, extraMessages: channelMessagesAdapter.getInitialState() };
    } else if (!targetChannel.extraMessages) {
      targetChannel = { ...targetChannel, extraMessages: channelMessagesAdapter.getInitialState() };
    }

    const newChannelMessages = channelMessagesAdapter.upsertOne(message, targetChannel.extraMessages);
    newChannels = channelAdapter.upsertOne({ ...targetChannel, extraMessages: newChannelMessages }, newChannels);
    return newChannels;
  },

  upsertMany: (
    messages: ChannelMessageEntity[],
    channelState: ChannelState
  ) => {
    let newChannels: EntityState<ChannelEntity>;
    messages.forEach((message) => {
      newChannels = extraMessagesHelper.upsertOne(message, channelState);
    });
    return newChannels;
  },

  updateOne: (
    message: ChannelMessageEntity,
    channelState: ChannelState
  ) => {
    let newChannels = channelState.channels;
    const { channelId } = message;
    const targetChannel = findChannelById(channelId, newChannels);
    if (!targetChannel) {
      return newChannels;
    } else if (!targetChannel.extraMessages) {
      return newChannels;
    } else if (!(targetChannel.extraMessages.ids as string[]).includes(message.id)) {
      return newChannels;
    }

    const newChannelMessages = channelMessagesAdapter.upsertOne(message, targetChannel.extraMessages);
    newChannels = channelAdapter.upsertOne({ ...targetChannel, extraMessages: newChannelMessages }, newChannels);
    return newChannels;
  },

  updateMany: (
    messages: ChannelMessageEntity[],
    channelState: ChannelState
  ) => {
    let newChannels: EntityState<ChannelEntity>;
    messages.forEach((message) => {
      newChannels = extraMessagesHelper.updateOne(message, channelState);
    });
    return newChannels;
  }
};
