import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChannelMessagesModificationsResponse, ChannelMessagesReplayPayload, ChannelMessagesReplayResponse, ChannelMessagesResponse, ChannelRelevantMessagesResponse, PagingReplayDirection, SocketIoService } from '@portal/wen-backend-api';
import { WenSnackbarOpener } from '@portal/wen-components';
import { Observable, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { UserData } from '../../auth/models/UserData';
import { RootState } from '../../root/public-api';
import { bulkAction } from '../../root/root.actions';
import { DateUtil } from '../../../common/date/date-util';
import { ChannelEntity } from '../channel.state';
import { fetchScheduledMessagesCount, fetchScheduledMessagesForCurrentChannel, removeScheduledMessage, removeScheduledMessages, showScheduledChannelPostSuccessToast, subscribeToDiscoverChannelUpdates, subscribeToUserChannelUpdates, updateScheduledMessage, updateScheduledMessages, updateScheduledMessagesCount } from '../channel.actions';

export const createChannelScheduledMessageEffect = (
  actions$: Actions,
  currentUser$: Observable<UserData>,
  socketIoService: Pick<SocketIoService, 'channel'>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(fetchScheduledMessagesForCurrentChannel),
    withLatestFrom(currentUser$),
    tap(([{channelId, direction, timestamp }, { userId }]) => {
      const params: ChannelMessagesReplayPayload = {
        channelId,
        userId,
      };
      params.timestamp = !!timestamp ? timestamp : DateUtil.currentDateString();
      params.direction = !!direction ? direction : PagingReplayDirection.Down;
      socketIoService.channel.scheduledMessagesReplay.emit(params);
    })
  ), { dispatch: false });
};

export const createChannelScheduledMessagesCountUpdateEffect = (
  socketIoService: Pick<SocketIoService, 'channel'>,
  currentChannel$: Observable<ChannelEntity>
) => {
  return createEffect(() =>
    socketIoService.channel.scheduledMessagesCount.listen.pipe(
      withLatestFrom(currentChannel$),
      filter(([countResponse, currentChannel]) => Boolean(currentChannel?.id) && countResponse?.channelId === currentChannel?.id),
      map(([{ count }, { id }]) => {
        return updateScheduledMessagesCount({
          channelId: id,
          count
        });
      })
    )
  );
};

export const createChannelScheduledMessagesCountEffect = (
  actions$: Actions,
  currentUser$: Observable<UserData>,
  socketIoService: Pick<SocketIoService, 'channel'>,
) => {
  return createEffect(
    () => actions$.pipe(
      ofType(fetchScheduledMessagesCount),
      withLatestFrom(currentUser$),
      tap(([ { channelId } , { userId }]) => {
        socketIoService.channel.scheduledMessagesCount.emit({ channelId, userId });
      })
    ),
    { dispatch: false }
  );
};

export const createChannelScheduledMessagesReplayEffect = (
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() =>
    socketIoService.channel.scheduledMessagesReplay.listen.pipe(
      map((response: ChannelMessagesReplayResponse) => {
        return updateScheduledMessages({
          messages: response.messages,
          channelId: response.channelId,
          hasMore: response.more
        });
      })
    )
  );
};

export const createChannelScheduledMessagesUpdateEffect = (
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() =>
    socketIoService.channel.scheduledMessages.listen.pipe(
      map((response: ChannelMessagesResponse) => {
          const targetActions = response.map(( message ) => {
            return updateScheduledMessage({message});
          }
          );
          return bulkAction({ targetActions });
        }
      )
    )
  );
};

export const createChannelScheduledMessageModifiedEffect = (
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() =>
    socketIoService.channel.scheduledMessagesModification.listen.pipe(
      map((response: ChannelMessagesModificationsResponse) =>
        updateScheduledMessage({ message: response })
      )
    )
  );
};

export const createScheduledMessageOnPublishEffect = (
  channelMessages$: Observable<ChannelMessagesResponse>,
  store: Store<RootState>
) => {
  return createEffect(() => channelMessages$.pipe(
    filter(messages => messages.length === 1),
    switchMap((messageResponse) => {
      const actions: Action[] = [];
      const message = messageResponse[0];
      actions.push(removeScheduledMessage({ message }));
      return actions;
    })
  ));
};

export const createScheduledMessageOnPublisWhileOfflineEffect = (
  actions$: Actions,
  channelRelevantMessages$: Observable<ChannelRelevantMessagesResponse[]>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToUserChannelUpdates, subscribeToDiscoverChannelUpdates),
    first(),
    switchMap(() => {
      return channelRelevantMessages$.pipe(
        map((responses) => {
          const targetActions = responses.map(({ channelId, messages }) => {
            return removeScheduledMessages({
              channelId,
              messages,
            });
          });
          return bulkAction({ targetActions });
        })
      );
    })
  ));
};

export const createNotifySuccessfulScheduleEffect = (
  actions$: Actions,
  wenSnackbar: WenSnackbarOpener,
  translateService: TranslateService
) => {
  return createEffect(() => actions$.pipe(
    ofType(showScheduledChannelPostSuccessToast),
    tap(() => {
      wenSnackbar.openNotificationSnackbar({
        notificationIcon: 'success',
        notificationText: translateService.instant(
          'SCHEDULED_MESSAGES_CREATED_NOTIFICATION'
        ),
      });
    })
  ),
    { dispatch: false }
  );
};
