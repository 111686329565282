import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { DialogLayoutModule, WenIconModule, WenSliderComponent, WenTestIdModule } from '@portal/wen-components';
import { MaterialInputExtensionDirective } from '../../../../directives/material-input-extension/material-input-extension.directive';
import { LocationSelectorModule } from '../../../location-selector/location-selector.module';
import { GeoFilterLocationSelectorComponent } from './components/geo-filter-location-selector/geo-filter-location-selector.component';
import { GeoFilterSelectComponent } from './geo-filter-select.component';

@NgModule({
  imports: [
    MatDialogModule,
    DialogLayoutModule,
    ReactiveFormsModule,
    MatInputModule,
    PortalFieldModule,
    WenIconModule,
    CommonModule,
    LocationSelectorModule,
    TranslateModule.forChild(),
    WenSliderComponent,
    MaterialInputExtensionDirective,
    WenTestIdModule
  ],
  declarations: [
    GeoFilterSelectComponent,
    GeoFilterLocationSelectorComponent,
  ],
  exports: [
    GeoFilterSelectComponent
  ],
})
export class GeoFilterSelectModule { }
