
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { SelectionOption } from '@portal/wen-components';
import { catchError, first, map, Observable, of } from 'rxjs';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { ChannelEntity } from '../../../../core/store/channel/channel.state';
import { selectEditFormById } from '../../../../core/store/form/form.selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { WenRouteId } from '../../../../frame/routing/types';

export const inviteToChannelResolverFn = (route: ActivatedRouteSnapshot) => {
  return inject(InviteToChannelResolver).resolve(route);
};

@Injectable()
export class InviteToChannelResolver  {

  constructor(
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<SelectionOption<ChannelEntity>[]> {
    return this.store.pipe(
      select(selectEditFormById(WenRouteId.INVITE_TO_CHANNEL_SELECTOR)),
      first(),
      map((form) => {
        return form.changedValues.selectedChannels;
      }),
      catchError(() => {
        this.navigationHelper.navigateToCrmInviteToChannelSelector();
        return of(false);
      })
    );
  }
}
