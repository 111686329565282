import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wen-notification-count',
  templateUrl: './notification-count.component.html',
  styleUrls: ['./notification-count.component.scss'],
  animations: [
    trigger('scaleAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0)', opacity: 0 }),
        animate('75ms ease-in', style({ transform: 'scale(1)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1 }),
        animate('75ms ease-in', style({ transform: 'scale(0)', opacity: 0 }))
      ])
    ])
  ]
})
export class NotificationCountComponent implements OnInit {

  @Input() value: number | string;
  @Input() set disabled(isDisabled: boolean) { this.disabledClassName = isDisabled; }

  @HostBinding('class.wen-notification-count') className = true;
  @HostBinding('class.wen-notification-count-disabled') disabledClassName = false;
  @HostBinding('class.wen-notification-count-two-digit') get isTwoDigit() {
    return typeof this.value === 'number' && this.value && this.value > 9 && this.value <= 99;
  }
  @HostBinding('class.wen-notification-count-three-digit') get isThreeDigit() {
    return typeof this.value === 'number' && this.value && this.value > 99;
  }

  get isNumeric() {
    return !isNaN(this.value as number);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
