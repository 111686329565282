@if (availableEmojis$ | async; as availableEmojis) {
  @if (reactionData$ | async; as reactionData) {
    <div class="emojis-wrapper">
      @for (reaction of reactionData.reactions; track trackByFn($index, reaction)) {
        <div class="wen-emoji-wrapper" (click)="onAddReaction(reactionData.userSelection, reaction.emojiId)">
          <div [id]="emojiHtmlIds[reaction.emojiId]" class="wen-emoji-content">
            <wen-emoji [id]="reaction.emojiId"
              [selected]="reaction.emojiId === reactionData.userSelection"
              [wen-test-id]="'wen-emoji-' + reaction.emojiId">
            </wen-emoji>
          </div>
          <span class="emoji-counter" portal-subheading-1>{{ reaction.count | thousandFormat }}</span>
        </div>
      }
      <div class="wen-emoji-wrapper" (click)="handleAddReactionClick()">
        <wen-icon iconName="add_reaction" class="wen-add-emoji"/>
      </div>
    </div>
  } @else {
    @if (showPlaceholder) {
      @for (id of availableEmojis; track id) {
        <div class="wen-emoji-wrapper wen-emoji-wrapper-placeholder" (click)="onNewReaction(id)">
          <wen-emoji [id]="id" [wen-test-id]="'wen-emoji-' + id">
          </wen-emoji>
        </div>
      }
    }
  }
}
