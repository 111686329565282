import { Injectable } from '@angular/core';
import { filter, Observable, Subject } from 'rxjs';

@Injectable()
export class FileEventHandlerService {

  private subject = new Subject<FileList>();

  addFiles(files: FileList) {
    this.subject.next(files);
  }

  filesChanged(): Observable<FileList> {
    return this.subject.pipe(
      filter(fileList => Boolean(fileList))
    );
  }

  constructor() { }
}
