import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MassDataContainer } from '../mass-data-container';
import { ExtractEntityType } from '../types';

export type PageRequestDescriptor = {
  paging: {
    offset: number;
    size: number;
  };
};

export interface MassDataPageResponse<DTO> {
  data: DTO[];
}

@Injectable()
export abstract class MassDataRepository<C extends MassDataContainer<E>, E = ExtractEntityType<C>> {

  abstract fetchPage(pagingParams: PageRequestDescriptor): Observable<MassDataPageResponse<E>>;

}
