import { NativeBridge, ShareMenuData, WebApiEvent } from '@portal/react-native-api';
import { catchError, EMPTY, fromEventPattern } from 'rxjs';
import { NativeNotificationPayload } from '@portal/wen-native-api';

export class ReactNativeWrapper {

  backPress$ = fromEventPattern<void>((handler) => this.bridge.onBackPress(handler));
  keyboardDidShow$ = fromEventPattern<void>((handler) => this.bridge.onKeyboardEvent(WebApiEvent.KEYBOARD_DID_SHOW, handler));
  keyboardDidHide$ = fromEventPattern<void>((handler) => this.bridge.onKeyboardEvent(WebApiEvent.KEYBOARD_DID_HIDE, handler));
  notificationReceived$ = fromEventPattern<NativeNotificationPayload>((handler) => this.bridge.onNotificationEvent(handler));
  deepLinkChange$ = fromEventPattern<string>((handler) => {
    return this.bridge.onDeeplinkChange(handler);
  });
  shareMenuDataReceived$ = fromEventPattern<ShareMenuData>((handler) => {
    return this.bridge.onShareMenuEvent(handler);
  }).pipe(
    catchError(() => {
      return EMPTY;
    })
  );
  connectivityChange$ = fromEventPattern<boolean>((handler) => this.bridge.onConnectivityChange(handler));

  constructor(
    public readonly bridge: NativeBridge
  ) { }
}
