import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateUtil } from '../../../../../../core/common/date/date-util';

export const dateRangeValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  const start = formGroup.get('start');
  const end = formGroup.get('end');

  const startDate = start.value;
  const endDate = end.value;

  if (!startDate || !endDate || DateUtil.compareNullSafeDates(startDate, endDate) < 0) {
    return { invalidRange: true };
  }
  return null;
};
