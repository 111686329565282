import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { first, map, switchMap } from 'rxjs';
import { subscribeToContactsUpdates, updateContactsOpenRequestCount } from './contacts.actions';
import { FeatureEnablementService } from '../../services/configuration/feature-enablement';
import { filterBy } from '../../common/operators/fitler-by';
import { select, Store } from '@ngrx/store';
import { RootState } from '../root/public-api';
import { selectCurrentUserData } from '../auth/auth.selectors';
import { PermissionLevel } from '../../services/user-management/permission-level';

@Injectable()
export class ContactsEffects {

  private actions$ = inject(Actions);
  private socketIoService = inject(SocketIoService);
  private featureEnablementService = inject(FeatureEnablementService);
  private store = inject(Store<RootState>);

  subscribeToCotnactsOpenRequestCount$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToContactsUpdates),
    first(),
    filterBy(() => this.featureEnablementService.featureConfigObs.enableContacts$, true),
    filterBy(() => this.store.pipe(
      select(selectCurrentUserData),
      map(userData => userData.permissionLevel === PermissionLevel.REGISTERED_USER)
    )),
    switchMap(() => {
      this.socketIoService.contact.contactOpenRequestCount.emit();
      return this.socketIoService.contact.contactOpenRequestCount.listen.pipe(
        map(({ count }) => updateContactsOpenRequestCount({ openRequestCount: count }))
      );
    })
  ));

}
