import { Endpoint, windowEndpoint } from 'comlink';

export class DisposableWindowEndpoint implements Endpoint {

  private listeners: Array<EventListenerOrEventListenerObject> = [];

  constructor(
    private targetWindow: Parameters<typeof windowEndpoint>[0],
    private context: EventSource,
    private targetOrigin: string
  ) { }

  postMessage(message: any, transfer?: Transferable[]): void {
    return this.targetWindow.postMessage(message, this.targetOrigin, transfer);
  }

  addEventListener(type: string, listener: EventListener, options?: Record<string, string>): void {
    if (type === 'message') {
      this.listeners.push(listener);
    }
    return this.context.addEventListener(type, listener, options);
  }

  removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: Record<string, string>): void {
    this.context.removeEventListener(type, listener, options);
  }

  dispose() {
    this.listeners.forEach(listener => {
      this.context.removeEventListener('message', listener);
    });
    this.listeners = [];
  }

}

export const disposableWindowEndpoint = (...params: Parameters<typeof windowEndpoint>) => {
  const [targetWindow, context = self, targetOrigin = '*'] = params;
  return new DisposableWindowEndpoint(targetWindow, context as EventSource, targetOrigin);
};
