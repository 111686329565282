import { NgModule } from '@angular/core';
import { MatSnackBarConfig, MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { SnackbarNotificationComponent } from './snackbar-notification/snackbar-notification.component';
import { WenTestIdModule } from '../../directives/public-api';

@NgModule({
  imports: [
    WenIconModule,
    MatSnackBarModule,
    WenTestIdModule
  ],
  declarations: [
    SnackbarNotificationComponent
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        duration: 2500,
        verticalPosition: 'top'
      } as MatSnackBarConfig
    }
  ],
  exports: [
    SnackbarNotificationComponent
  ]
})
export class WenSnackbarModule { }
