/**
 * Counter to make sure Dedupe integration doesn't drop events
 */
let errCounter = 0;

/**
 * Merge an error with some custom data but keep the stack of the original one
 */
export const mergeError = (
  error: Error,
  additionalData: Record<string, string>,
  forceIgnoreDedupe?: boolean
) => {
  const extras = additionalData || {};
  if (forceIgnoreDedupe) {
    extras.errCount = `${errCounter++}`;
  }
  const mergedError = new Error();
  mergedError.stack = error.stack;
  mergedError.message = error.message + JSON.stringify(additionalData);
  return mergedError;
};
