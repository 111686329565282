import { Injectable } from '@angular/core';
import { RoomEventsRelevantReplayResponse, RoomMessagesSummaryResponse, SocketIoService } from '@portal/wen-backend-api';
import { Observable, map, switchMap } from 'rxjs';
import { CachedItemLoader } from './create-event-cacher';

@Injectable()
export class ChatEventOrchestrator {

  private relevantCachedItemLoader = new CachedItemLoader(
    (roomId) => this.socketIoService.chat.room.relevantMessages.acknowledgement$({ roomId }),
    (item) => item.roomId,
  );

  private summaryCachedItemLoader = new CachedItemLoader(
    (roomId) => this.socketIoService.chat.room.messagesSummary.acknowledgement$({ roomId }),
    (item) => item.roomId,
  );

  /**
   * @deprecated use enableSmartChatRelevantLoading feature with ensureRelevantMessagesLoaded(...)
   */
  public bufferedRelevant$: Observable<RoomEventsRelevantReplayResponse[]>;
  public bufferedSummary$: Observable<RoomMessagesSummaryResponse[]>;

  constructor(
    private readonly socketIoService: SocketIoService,
  ) {
    this.bufferedRelevant$ = this.relevantCachedItemLoader.items$.pipe(
      map((data) => Array.from(data.values()))
    );

    this.bufferedSummary$ = this.summaryCachedItemLoader.items$.pipe(
      map((data) => Array.from(data.values()))
    );
  }

  ensureRelevantMessagesLoaded(roomId: string) {
    return this.relevantCachedItemLoader.getOrLoadItem(roomId);
  }

  ensureRelevantMessagesSynced() {
    return this.relevantCachedItemLoader.reloadCache();
  }

  ensureSummaryMessagesLoaded(roomIds: string[]) {
    return this.summaryCachedItemLoader.getOrLoadItem(roomIds).pipe(
      switchMap(() => this.bufferedSummary$)
    );
  }

  ensureSummaryMessagesSynced() {
    return this.summaryCachedItemLoader.reloadCache();
  }

}
