import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CircleImageViewModule, MarkdownTextModule, StylingModule, WenCardsModule, WenIconModule } from '@portal/wen-components';
import { WenPipesModule } from '../../../../../pipes/wen-pipes.module';
import { NotificationTypeComponent } from './components/notification-type/notification-type.component';
import { EmbedNotificationComponent } from './embed-notification.component';

@NgModule({
  imports: [
    CommonModule,
    MarkdownTextModule,
    WenIconModule,
    StylingModule,
    WenPipesModule,
    WenCardsModule,
    CircleImageViewModule
  ],
  declarations: [EmbedNotificationComponent, NotificationTypeComponent],
  exports: [EmbedNotificationComponent],
})
export class EmbedNotificationModule { }
