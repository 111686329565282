import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class IosKeyboardStickyHelper {

  private sticky = new BehaviorSubject(false);
  public readonly sticky$ = this.sticky.pipe(distinctUntilChanged());

  makeSticky() {
    this.sticky.next(true);
  }

  unSticky() {
    this.sticky.next(false);
  }
}
