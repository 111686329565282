import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs';
import { userProfileIdentifier } from '../../views/user-profile/tokens';
import { WenNavigationHelper } from '../services/navigation/types';
import { RootState } from '../store/root/public-api';
import { selectUserProfile } from '../store/user/user.selectors';

export const ownProfileGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(OwnProfileGuard).canActivate(route, state);
};

@Injectable()
export class OwnProfileGuard {

  constructor(
    private readonly store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userIdFromRoute = route.paramMap.get(userProfileIdentifier);
    return this.store.pipe(
      select(selectUserProfile),
      map(user => {
        if (user?.userId === userIdFromRoute) {
          this.navigationHelper.navigateToUserProfileRead(false);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
