import { inject, Provider } from '@angular/core';
import { NATIVE_API_CONFIG_TOKEN, NativeApiConfiguration } from '@portal/wen-native-api';
import { FeatureEnablementService } from '../../core/services/configuration/feature-enablement';

export const APP_NATIVE_API_CONFIG_PROVIDER: Provider = {
  provide: NATIVE_API_CONFIG_TOKEN,
  useFactory: () => {
    const featureEnablementService = inject(FeatureEnablementService);
    const config: NativeApiConfiguration = {
      enableDynamicStatusBar: () => featureEnablementService.featureFlagMethods.isEnableComingSoonFeatures()
    };
    return config;
  }
};
