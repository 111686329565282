import { Injectable } from '@angular/core';
import type * as Sentry from '@sentry/angular';
import { getSentryIfLoaded } from '../sentry-wrapper';
import { TracerBreadcrumbParams } from './tracer-types';

@Injectable()
export class Tracer {

  addBreadcrumb(params: TracerBreadcrumbParams) {
    getSentryIfLoaded().subscribe(sentryModule => sentryModule.addBreadcrumb(params));
  }

  captureException(
    data: any,
    hintData?: Record<string, string>,
  ) {
    const hint: Sentry.EventHint = {
      data: hintData
    };
    getSentryIfLoaded().subscribe(sentryModule => sentryModule.captureException(data, hint));
  }

  captureMessage(
    data: string
  ) {
    getSentryIfLoaded().subscribe(sentryModule => sentryModule.captureMessage(data));
  }

}
