import { NgxMatNativeDateAdapter } from '@angular-material-components/datetime-picker';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable()
export class LuxonFormattingDateAdapter extends NgxMatNativeDateAdapter {
    parse(value: any): Date | null {
        return super.parse(value);
      }

      format(date: Date, displayFormat: any): string {
        return DateTime.fromJSDate(date).toFormat(displayFormat);
      }
}
