export const EMAIL_VERIFICATION_CODE_QUERY_PARAM = 'code';
export const REGISTRATION_FROM_APP_FLAG_QUERY_PARAM = 'fromApp';
export const AUTH_SERVER_SUGGESTED_USERNAME_QUERY_PARAM = 'suggested_username';
export const AUTH_SERVER_REDIRECT_URL_QUERY_PARAM = 'redirectAfterLogin';
export const INVITATION_ID_QUERY_PARAM = 'inviteId';
export const READER_DOC_ID_QUERY_PARAM = 'docId';
export const READER_START_PAGE_QUERY_PARAM = 'startIndex';
export const FILTER_QUERY_PARAM = 'filter';
export const EXCLUSIVE_INVITE_REDIRECT_AFTER_CODE = 'redirectAfterCode';
export const SIGNIN_RETURN_URL = 'returnUrl';
export const FAQ_TYPE_QUERY_PARAM = 'faqType';
export const CHAT_CREATE_FROM_SHARE_QUERY_PARAM = 'chatCreateFromShare';
export const CHAT_CREATE_FROM_FORWARD_QUERY_PARAM = 'chatCreateFromForward';
export const CREDENTIAL_TYPE = 'credentialType';

export const SSO_DEEP_LINK_PATH = 'sso-deep-link';

export const SD_WEB_API_HOST_QUERY_PARAM = 'host';
export const SD_WEB_API_BACKEND_TYPE_QUERY_PARAM = 'backend';
