import { Component, Input } from '@angular/core';
import { ShareService } from '../../channel-specific/providers/share.service';

@Component({
  selector: 'wen-share-button',
  templateUrl: './share-button.component.html'
})
export class ShareButtonComponent {

  @Input() deepLink: string;

  constructor(
    private readonly shareService: ShareService
  ) { }

  handleSharing() {
    this.shareService.shareDeepLink(this.deepLink);
  }
}
