<div class="wen-code-verification-field-content">
  <mat-form-field>
    <mat-label>{{ 'REGISTRATION_CODE_VERIFICATION_CODE_LABEL' | translate }}</mat-label>
    <input class="wen-code-verification-field-control-input" matInput autocomplete="off"
      [formControlName]="forFormControlName"
      [placeholder]="'REGISTRATION_CODE_VERIFICATION_CODE_PLACEHOLDER' | translate" wenIosKeyboardStickyTarget
      [type]="codeFieldType" pattern="\d*" />
      @if (hasError(['required', 'minlength', 'maxlength', 'pattern'])) {
        <mat-error portal-caption>{{
        "REGISTRATION_CODE_VERIFICATION_INVALID" | translate }}</mat-error>
      }
      @if (hasError('verifyCooldown')) {
        <mat-error portal-caption>{{ "REGISTRATION_CODE_VERIFICATION_COOLDOWN" |
        translate: { cooldown: (remainingResubmit$ | async) } }}</mat-error>
      }
    </mat-form-field>
    <wen-code-verification-resend-button [disabled]="resendDisabled$ | async" [remaining]="remainingResend$ | async"
    (resend)="onResendCode()"></wen-code-verification-resend-button>
  </div>