import { AfterViewInit, Directive, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subject, animationFrameScheduler, debounceTime, distinctUntilChanged, fromEvent, map, merge, observeOn, takeUntil } from 'rxjs';

@Directive({
  selector: 'wenViewportResize, [wenViewportResize]',
  standalone: true
})
export class ViewportResizeDirective implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  @Output() afterViewportChange = new EventEmitter<{ viewportHeight: number }>();

  ngAfterViewInit() {
    const viewport = window.visualViewport;
    const viewportScroll$ = fromEvent(viewport, 'scroll');
    const viewportResize$ = fromEvent(viewport, 'resize');
    merge(
      viewportScroll$, viewportResize$
    ).pipe(
      observeOn(animationFrameScheduler),
      debounceTime(300),
      map(() => viewport.height),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe((viewportHeight) => {
      this.afterViewportChange.emit({ viewportHeight });
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
