import { InjectionToken } from '@angular/core';
import { ManagerOptions, SocketOptions } from 'socket.io-client';
import { WenNetworkConfig } from '../../data-types/network/wen-networks';

export interface BackendApiConfig {
  backendUrl: string;
  mediaServiceBaseUrl?: string;
  network: WenNetworkConfig;
  extraHeaders?: { [key: string]: string };
  socketIoOptions?: Partial<ManagerOptions & SocketOptions>;
  enableBulkImageUploadOptimization?: boolean;
}

export const BACKEND_API_CONFIG = new InjectionToken<BackendApiConfig>('BACKEND_API_CONFIG');
