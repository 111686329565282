import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DiscoverAppListResponse } from '@portal/wen-backend-api';
import { merge, Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { LoadingState } from '../../../common/types/store-loading-state';
import { subscribeToDiscoverAppsUpdates, updateDiscoverAppsList, updateDiscoverAppsLoadingState } from '../apps.actions';

export const createAppListForDiscoverEffect = (
  actions$: Actions,
  appList$: Observable<DiscoverAppListResponse>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToDiscoverAppsUpdates),
    first(),
    switchMap(() => {
      return merge(
        appList$.pipe(
          switchMap((apps) => [
            updateDiscoverAppsList({ apps: apps.content }),
            updateDiscoverAppsLoadingState({ loadingState: LoadingState.LOADED })
          ])
        )
      ).pipe(
        catchError(() => of(updateDiscoverAppsLoadingState({ loadingState: LoadingState.ERROR })))
      );
    })
  ));
};
