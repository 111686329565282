import { Injectable } from '@angular/core';
import { PageRequestDescriptor } from '../repository/mass-data-repository';
import { MassDataPagingAdapter } from './mass-data-paging-adapter';

@Injectable()
export class OffsetBasedMassDataPagingAdapter extends MassDataPagingAdapter<PageRequestDescriptor['paging']> {

  protected defaultPageSize = 20;

  getInitialPageParams() {
    return {
      offset: 0,
      size: this.defaultPageSize
    };
  }

  getNextPageParams(currentLastLoadedOffset: number) {
    return {
      offset: currentLastLoadedOffset + 1,
      size: this.defaultPageSize
    };
  }

}
