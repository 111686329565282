import { AfterViewInit, Directive, OnDestroy } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { distinctUntilChanged, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { isNullOrUndefined } from '../../../core/common/operators/null-check-util';
import { FormStatus } from '../../../core/common/util/forms';
import { AppHeaderController } from '../../../frame/components/header/components/app-header/app-header-controller';

export const FORM_INVALID_ERROR = {
  formInvalid: true
};

@Directive({
  selector: '[wenFormControlledHeader]'
})
export class FormControlledHeaderDirective implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  constructor(
    private appHeaderController: AppHeaderController,
    private formGroupDirective: FormGroupDirective,
  ) { }

  ngAfterViewInit() {
    const statusChanges$: Observable<FormStatus> = this.formGroupDirective.statusChanges.pipe(
      startWith(this.formGroupDirective.status)
    );
    statusChanges$.pipe(
      map((statusChanges) => {
        const errors = this.formGroupDirective.errors;
        if (!isNullOrUndefined(errors?.formInvalid)) {
          return false;
        }
        return statusChanges === 'VALID';
      }),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(isEnabled => {
      this.appHeaderController.setRightCommandEnablement(isEnabled);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.appHeaderController.setRightCommandEnablement(true);
  }

}
