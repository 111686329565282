import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import player from 'lottie-web';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { AdaptiveBackgroundModule } from '../../../shared/components/adaptive-background/adaptive-background.module';
import { RoundedButtonModule } from '../../../shared/components/rounded-button/rounded-button.module';
import { OnboardingProgressComponent } from './components/onboarding-progress/onboarding-progress.component';
import { OnboardingStartComponent } from './components/onboarding-start/onboarding-start.component';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    RoundedButtonModule,
    TranslateModule,
    AdaptiveBackgroundModule,
    LottieComponent,
  ],
  providers: [
    provideLottieOptions({
      player: playerFactory
    })
  ],
  declarations: [
    OnboardingStartComponent,
    OnboardingProgressComponent
  ],
  exports: [
    OnboardingStartComponent
  ]
})
export class OnboardingStartModule { }
