import { PagingHistoryFlags, PagingReplayDirection } from '@portal/wen-backend-api';

export const mapReplayDirectionToStateFlags = (
  direction: PagingReplayDirection, more: boolean
) => {
  const flags: PagingHistoryFlags = {};
  if (direction === PagingReplayDirection.Up) {
    flags.hasMoreOlder = more;
  }
  if (direction === PagingReplayDirection.Down) {
    flags.hasMoreNewer = more;
  }
  return flags;
};

export const mapRelevantDirectionToStateFlags = (
  moreDown: boolean, moreUp: boolean
) => {
  const flags: PagingHistoryFlags = {
    hasMoreNewer: moreDown,
    hasMoreOlder: moreUp
  };
  return flags;
};
