import { Injectable, inject } from '@angular/core';
import { ErrorDialogData } from '@portal/wen-components';
import { first } from 'rxjs';
import { ChatAvailabilityCheckerWithOverlay } from '../../../../core/services/chat/chat-availability-checker-with-overlay';
import { ChatSelectionListItemModel } from '../components/chat-selection-list/providers/chat-selection-list-providers';

@Injectable()
export class ChatEditCheckAvailabilityService {

  private chatAvailabilityChecker = inject(ChatAvailabilityCheckerWithOverlay);

  validateSelection(item: ChatSelectionListItemModel) {
    const { id, title } = item.listItemEntity;
    const dialogTitle = 'ERROR_GENERIC_DIALOG_MESSAGE';
    const dialogData: ErrorDialogData = {
      errorMessageLabel: 'ERROR_USER_HAS_NO_KEYS_FOR_ROOM',
      messageParams: {
        userName: title
      }
    };
    return this.chatAvailabilityChecker.checkChatAvailabilityWithUsersWithErrorDialog([id], dialogData, dialogTitle).pipe(
      first(),
    );
  }

}
