import { Provider } from '@angular/core';
import { AppHeaderController } from '../../../../frame/components/header/components/app-header/app-header-controller';
import { FormStoreService } from '../../../../shared/form-store/form-store.service';
import { DefaultOutletHelper, DialogOutletHelper, OutletHelper } from './outlet-helper';

/**
 * Outlet specific providers are local services for a given route context/components
 * The services must not be provided and are not available in any module scope!
 */

export const rootOutletProviders: Provider[] = [
  {
    provide: OutletHelper,
    useClass: DefaultOutletHelper
  },
  FormStoreService,
  AppHeaderController,
];

export const dialogOutletProviders: Provider[] = [
  {
    provide: OutletHelper,
    useClass: DialogOutletHelper
  },
  FormStoreService,
  AppHeaderController,
];
