import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ForwardContext, ReactionContext } from '@portal/wen-backend-api';
import { OverlayManager, ReactionSelectorComponent, ReactionSelectorData } from '@portal/wen-components';
import { first } from 'rxjs';
import { ForwardService } from '../../../../core/services/forward/forward.service';
import { AppNavigator } from '../../../../core/services/navigation/app-navigator';
import { MessageDeepLinkable } from '../../../../core/services/navigation/deep-link/deep-linkables/message-deep-linkable';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { EmbeddedDocument } from '../../../../core/store/channel/channel.state';
import { RootState } from '../../../../core/store/root/public-api';
import { navigateToUserProfile } from '../../../../core/store/user/user.actions';
import { WenRouteId } from '../../../../frame/routing/types';
import { ShareService } from '../../../../shared/channel-specific/providers/share.service';
import { ChallengeContextMenuDatasource } from '../../../../shared/components/challenge/challenge-context-menu/challenge-context-menu-datasource';
import { MessageBoxMenuComponent, MessageBoxMenuData } from '../../../../shared/components/message-box-menu/message-box-menu.component';
import { messageBoxContextMenuId } from '../../../../shared/components/message-box/actions/message-box.action-menu';
import { BaseMessageModel } from '../../../../shared/components/message-box/models/base-message-box.model';
import { MessageBoxModel } from '../../../../shared/components/message-box/models/message-box.models';
import { MessageBoxActionsHandler } from '../../../../shared/components/message-box/providers/message-box-tokens';
import { channelMessageDetailIdentifier } from '../../tokens';
import { ChannelConfigurationProvider } from '../providers/channel-configuration';

@Injectable()
export class ChannelMessageBoxActionsHandler extends MessageBoxActionsHandler {

  protected store = inject(Store<RootState>);
  private appNavigator = inject(AppNavigator);
  private navigationHelper = inject(WenNavigationHelper);
  private forwardService = inject(ForwardService);
  private deepLinkProvider = inject(MessageDeepLinkable);
  private challengeDatasource = inject(ChallengeContextMenuDatasource, { optional: true });
  private shareService = inject(ShareService);
  private overlayService = inject(OverlayManager);
  private channelConfiguration = inject(ChannelConfigurationProvider);

  handleCommentsClicked(messageId: string): void {
    this.appNavigator.navigateToRoute(WenRouteId.CHANNEL_MESSAGE_DETAIL, {
      [channelMessageDetailIdentifier]: messageId
    });
  }

  handleDocumentClicked(messageId: string, documentModel: EmbeddedDocument): void {
    const { articleId, docId, startPage } = documentModel;
    if (articleId) {
      this.navigationHelper.navigateToTextView({
        articleId
      });
    } else if (docId) {
      this.navigationHelper.navigateToReaderView({
        docId,
        startPage: startPage || 1,
        articleId
      });
    }
  }

  handleSenderClicked(messageId: string, authorId: string): void {
    this.store.dispatch(navigateToUserProfile({ userId: authorId }));
  }

  handleForwardClicked(messageId: string): void {
    const messageDeepLink = this.deepLinkProvider.getDeepLink({ messageId });
    this.forwardService.initiateForward(messageDeepLink, ForwardContext.CHANNEL_MESSAGE, messageId);
  }

  handleShareClicked(messageId: string): void {
    const messageDeepLink = this.deepLinkProvider.getDeepLink({ messageId });
    this.shareService.shareDeepLink(messageDeepLink);
  }

  handleChallengeClicked(messageId: string): void {
    if (this.challengeDatasource) {
      this.challengeDatasource.openMenu(messageId);
    }
  }

  handleReactionClicked(message: BaseMessageModel): void {
    const reactionSelectorData: ReactionSelectorData = {
      reactionParentId: message.messageId,
      reactionParentType: ReactionContext.CHANNEL_MESSAGE
    };
    this.overlayService.menu.open(ReactionSelectorComponent, { reactionSelectorData });
  }

  handleMessageContentClicked(message: BaseMessageModel): void {
    this.channelConfiguration.getCurrentChannelData().pipe(
      first()
    ).subscribe(({ isPersonalCurrentChannel }) => {
      const channelReactionSelectorData: ReactionSelectorData = {
        reactionParentId: message.messageId,
        reactionParentType: ReactionContext.CHANNEL_MESSAGE
      };
      const reactionSelectorData = !isPersonalCurrentChannel ? channelReactionSelectorData: null;
      const menuData: MessageBoxMenuData = {
        reactionSelectorData,
        menuId: messageBoxContextMenuId,
        message
      };
      this.overlayService.menu.open(MessageBoxMenuComponent, menuData);
    });
  }

  handleSwipe(message: MessageBoxModel): void {
    throw new Error('ChannelMessageBoxActionsHandler does not support handleSwipe!');
  }
}
