import { Injectable, inject } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { MassDataRepository, PageRequestDescriptor } from '@portal/wen-data-core';
import { map } from 'rxjs';
import { ContactsMassDataContainer } from './contacts-mass-data-container';

@Injectable()
export class ContactsMassDataRepository extends MassDataRepository<ContactsMassDataContainer> {

  private socketIoService = inject(SocketIoService);

  fetchPage(pagingParams: PageRequestDescriptor) {
    return this.socketIoService.contact.listForUser.acknowledgement$(pagingParams).pipe(
      map((response) => {
        return {
          data: response.contacts
        };
      })
    );
  }

}
