import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogResult, OverlayManager } from '@portal/wen-components';
import { ChatSyncDialogComponent } from './chat-sync-dialog/chat-sync-dialog.component';

@Injectable()
export class ChatSyncDialogOpener {

  private currentDialog: MatDialogRef<ChatSyncDialogComponent, ConfirmationDialogResult>;

  constructor(
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
  ) { }

  openChatSyncDialog() {
    if (this.currentDialog) {
      return this.currentDialog.afterClosed();
    }
    this.currentDialog = this.overlayManager.dialog.openConfirmationDialog(ChatSyncDialogComponent, {
      okLabel: this.translateService.instant('CHAT_EXCHANGE_DIALOG_CONTENT_ACTION_OK'),
    });
    const afterClosed$ = this.currentDialog.afterClosed();
    afterClosed$.subscribe(() => {
      this.currentDialog = null;
    });
    return this.currentDialog.afterClosed();
  }

  closeChatSyncDialog() {
    this.currentDialog?.close();
  }

}
