import { NgModule } from '@angular/core';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { playerFactory } from '../message-box/components/embeds/embed-media/embed-media.module';
import { ChristmasComponent } from './christmas.component';

@NgModule({
  exports: [ChristmasComponent],
  declarations: [ChristmasComponent],
  imports: [
    LottieComponent
  ],
  providers: [
    provideLottieOptions({
      player: playerFactory
    })
  ]
})
export class ChristmasModule { }
