import { Injectable } from '@angular/core';
import { WenRouteId } from '../../../frame/routing/types';
import { FeatureEnablementService } from '../configuration/feature-enablement';
import { WenStorageService } from '../storage/wen-storage.service';
import { PermissionLevel } from '../user-management/permission-level';
import { WenOAuthService } from '../user-management/wen-oauth.service';

@Injectable()
export class ExclusiveInviteHelper {

  constructor(
    private oAuthService: WenOAuthService,
    private featureEnablementService: FeatureEnablementService,
    private storageService: WenStorageService,
  ) { }

  isCodeDialogNeeded(routeId?: WenRouteId) {
    if (routeId === WenRouteId.EXCLUSIVE_INVITE) {
      return false;
    }
    const inviteOnly = this.featureEnablementService.isInviteOnlyNetworkEnabled();
    if (!inviteOnly) {
      return false;
    }
    const userData = this.oAuthService.getUserData();
    const isAnonymous = userData?.permissionLevel === PermissionLevel.ANONYMOUS;
    const isInviteCodeAdded = this.storageService.getExclusiveInviteCodeValue();
    return isAnonymous && !isInviteCodeAdded;
  }

}
