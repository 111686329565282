@if (availableEmojiIds$ | async; as availableEmojiIds) {
  @for (availableEmojiId of availableEmojiIds; track availableEmojiId) {
    <div class="emoji-wrapper">
      @if (currentlySelectedEmojiId === availableEmojiId) {
        <div class="emoji-selected"></div>
      }
      <wen-emoji-animation [id]="availableEmojiId" (click)="onReaction(availableEmojiId);tryCloseMenu($event)"
      [wen-test-id]="'wen-emoji-animation' + availableEmojiId"></wen-emoji-animation>
    </div>
  }
}