import { isNullOrUndefined } from './null-check-util';

export const flattenArray = <T>(value: T[][]): T[] => {
  return [].concat.apply([], value);
};

export const lastItem = <T>(arr: T[]): T => {
  return arr[arr.length - 1];
};

export const nonNullArray = <T>(arr: T[]): T[] => {
  if (!arr) {
    return arr;
  }
  return arr.filter((item) => !isNullOrUndefined(item));
};

export const mergeArrays = <T>(arr1: T[], arr2: T[], matchingKey: string): T[] => {
  return arr1.map(item1 => {
    return {
      ...item1,
      ...arr2.find((item2) => item2[matchingKey] === item1[matchingKey])
    };
  });
};
