import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { DraftChatMediaPreviewHandler } from '../../../../../core/services/util/draft-chat-media-preview-handler';
import { MediaPreviewHandler } from '../../../../../core/services/util/media-preview-handler';
import { cleanUpDraftChat } from '../../../../../core/store/chat/chat.actions';
import { CreateChatSearchMassDataContainer } from '../../../../../core/store/chat/mass-data/create-chat-search-mass-data-config/create-chat-search-mass-data-container';
import { EditChatSearchFilterService } from '../../../../../core/store/chat/mass-data/providers/edit-chat-search-filter-service';
import { preloadWenUsersForCRM } from '../../../../../core/store/smartdesign/smartdesign.actions';
import { ProgressState } from '../../../../../core/store/ui/ui.state';
import { ChatCreateHeaderDataUpdater } from '../../../../../shared/chat-specific/chat-create-header-data-updater';
import { FileDropOverlayComponent } from '../../../../../shared/components/file-drop-overlay/file-drop-overlay.component';
import { InputWithChipModel, InputWithChipsComponent } from '../../../../../shared/components/input-with-chips/input-with-chips.component';
import { MessageSendFieldDataSource } from '../../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { FileEventHandlerService } from '../../../../../shared/services/file-event-handler.service';
import { ChatSelectionListItemModel, createChatSelectionListProviders } from '../chat-selection-list/providers/chat-selection-list-providers';
import { ChatCreationListSelectionService } from './providers/list/chat-creation-list-selection-service';
import { ChatCreationListService } from './providers/list/chat-creation-list-service';
import { ChatCreationMessageSendFieldDatasource } from './providers/chat-creation-message-send-field.datasource';

@Component({
  selector: 'wen-chat-creation',
  templateUrl: './chat-creation.component.html',
  styleUrls: ['./chat-creation.component.scss'],
  providers: [
    ChatCreationListService,
    ChatCreationListSelectionService,
    createChatSelectionListProviders(
      CreateChatSearchMassDataContainer,
      ChatCreationListSelectionService,
      EditChatSearchFilterService
    ),
    { provide: MessageSendFieldDataSource, useClass: ChatCreationMessageSendFieldDatasource },
    { provide: MediaPreviewHandler, useClass: DraftChatMediaPreviewHandler },
    ChatCreateHeaderDataUpdater,
  ]
})
export class ChatCreationComponent implements OnInit, OnDestroy {

  @ViewChild(InputWithChipsComponent) inputFieldChips: InputWithChipsComponent;

  maxNumberOfSelectableUsers = this.selectionService.maxSelectionCount;
  selectedChips$: Observable<InputWithChipModel[]>;
  selectedChipsCount$: Observable<number>;

  hasProgress$: Observable<ProgressState>;
  showForwardButton$: Observable<boolean>;

  selectionModeEnabled$: Observable<boolean>;

  overlayContent = FileDropOverlayComponent;

  constructor(
    private readonly store: Store,
    public listService: ChatCreationListService,
    private filterService: EditChatSearchFilterService,
    private selectionService: ChatCreationListSelectionService,
    private fileEventHandler: FileEventHandlerService,
    private headerDataUpdater: ChatCreateHeaderDataUpdater,
    private messageSendFieldDatasource: MessageSendFieldDataSource,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(preloadWenUsersForCRM());
    this.headerDataUpdater.initialize();

    this.showForwardButton$ = this.messageSendFieldDatasource.draftMessage$.pipe(
      map((draftMessage) => !!draftMessage?.forwardEmbed)
    );
    this.selectionModeEnabled$ = this.selectionService.selectionModeEnabled$;
    this.selectedChips$ = this.selectionService.selectedChips$;
    this.selectedChipsCount$ = this.selectionService.selectedChipsCount$;
    this.hasProgress$ = this.listService.hasProgress$;
  }

  handleListItemClick(item: ChatSelectionListItemModel) {
    this.selectionService.toggleSelection(item).pipe(
      catchError(() => EMPTY)
    ).subscribe(() => {
      this.filterService.resetSearchTerm();
      this.inputFieldChips.clearInput();
    });
  }

  onSearchChange(searchTerm: string) {
    this.filterService.setSearchTerm(searchTerm);
  }

  onItemRemove(item: InputWithChipModel) {
    this.selectionService.removeSelection(item.id);
  }

  onFilesDropped(files: FileList) {
    this.fileEventHandler.addFiles(files);
  }

  forward() {
    this.messageSendFieldDatasource.sendMessage();
  }

  ngOnDestroy(): void {
    this.store.dispatch(cleanUpDraftChat());
    this.filterService.resetSearchTerm();
  }
}
