import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { TranslateWithIconComponent } from './translate-with-icon.component';

@NgModule({
  imports: [WenIconModule, CommonModule],
  exports: [TranslateWithIconComponent],
  declarations: [TranslateWithIconComponent],
})
export class TranslateWithIconModule { }
