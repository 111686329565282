import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { inject, Injectable, Injector, StaticProvider } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { BehaviorSubject, filter, first, sample, shareReplay, skip } from 'rxjs';
import { CONTEXT_MENU_DATA } from '../../../components/context-menu/context-menu-data';
import { ContextMenuConfig, ContextMenuRef, CONTEXT_MENU_ID, CONTEXT_MENU_REF } from '../../../components/context-menu/context-menu-ref';
import { ContextMenuComponent } from '../../../components/context-menu/context-menu/context-menu.component';
import { EventListenerManager } from '../../../util/event-listener-manager';
import { WenBreakpointObserver } from '../../resize/wen-breakpoint-observer';
import { OverlayOpener } from '../overlay-types';
import { isNotOnScreenEdge } from './edge-detection-util';

const isValidScrollEvent = (event: TouchEvent, hammerRoot: HTMLElement) => {
  let currentElement = event.target as HTMLElement;
  while (currentElement && currentElement !== hammerRoot) {
    const isScrollable = currentElement.scrollHeight > currentElement.clientHeight;
    if (isScrollable && currentElement !== hammerRoot) {
      return false;
    }
    currentElement = currentElement.parentElement;
  }
  return true;
};

@Injectable()
export class ContextMenuHandler implements OverlayOpener {

  private injector = inject(Injector);
  private overlay = inject(Overlay);
  private eventManager = inject(EventManager);
  private breakpointObserver = inject(WenBreakpointObserver);
  private openedContextMenu: ContextMenuRef;
  private eventListenerManager = new EventListenerManager();
  private hasOpenOverlay = new BehaviorSubject<boolean>(false);

  hasOpenOverlay$ = this.hasOpenOverlay.pipe(
    shareReplay(1)
  );

  open<C = ComponentType<any>, D = any, S = any>(component: C, data: D, style?: S): ContextMenuRef {
    if (this.getContextMenuById(CONTEXT_MENU_ID)) {
      throw Error(`Context menu with id ${CONTEXT_MENU_ID} exists already. The context menu id must be unique.`);
    }
    const menuConfig: ContextMenuConfig = {
      hasBackdrop: true,
      panelClass: 'wen-context-menu-panel',
      id: CONTEXT_MENU_ID,
      positionStrategy: new GlobalPositionStrategy()
    };
    const overlayRef = this.overlay.create(menuConfig);
    const contextMenuRef = new ContextMenuRef(overlayRef, menuConfig);
    const containerRef = this.attachMenuToOverlay(component, overlayRef, contextMenuRef, data, style);
    contextMenuRef.containerInstance = containerRef.instance;

    this.openedContextMenu = contextMenuRef;
    this.listenToOrientationChange();
    this.listenToMenuClose();
    this.listenToSwipeDown();
    this.preventOverlayEdgeSwipe();
    this.hasOpenOverlay.next(true);
    contextMenuRef.closed$.subscribe(() => this.openedContextMenu = null);
    return contextMenuRef;
  }

  close() {
    const openedMenu = this.openedContextMenu;
    if (!openedMenu) {
      return;
    }
    this.hasOpenOverlay.next(false);
    openedMenu.close();
    this.removeOpenContextMenu();
  }

  isOpen() {
    return !!this.openedContextMenu;
  }

  private attachMenuToOverlay<C = ComponentType<any>, D = any, S = any>(
    component: C,
    overlayRef: OverlayRef,
    contextMenuRef: ContextMenuRef,
    data: D,
    style: S
  ) {
    const providers: StaticProvider[] = [
      { provide: CONTEXT_MENU_DATA, useValue: { component, data, style } },
      { provide: CONTEXT_MENU_REF, useValue: contextMenuRef }

    ];
    const injector = Injector.create({
      providers,
      parent: this.injector
    });
    return overlayRef.attach(new ComponentPortal(ContextMenuComponent, null, injector));
  }

  private listenToMenuClose() {
    if (!this.openedContextMenu) {
      return;
    }
    const contextMenuRef = this.openedContextMenu;
    const backdrop$ = contextMenuRef.containerInstance.animationStateChanged.pipe(
      sample(contextMenuRef.backdropClick()),
      filter(animationState => animationState.phaseName === 'done' && animationState.fromState === 'void')
    );
    backdrop$.subscribe(() => {
      this.close();
    });
  }

  private listenToOrientationChange() {
    this.breakpointObserver.orientationChanged$.pipe(
      skip(1),
      first()
    ).subscribe(() => {
      this.close();
    });
  }

  private preventOverlayEdgeSwipe() {
    const ignoreEdgeSwipe = (event) => {
      if (isNotOnScreenEdge(event)) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
    };

    this.eventListenerManager.addEvent = this.eventManager.addEventListener(
      this.openedContextMenu.menuRef.backdropElement,
      'touchstart',
      (event) => {
        ignoreEdgeSwipe(event);
      }
    );

    this.eventListenerManager.addEvent = this.eventManager.addEventListener(
      this.openedContextMenu.menuRef.overlayElement,
      'touchstart',
      (event) => {
        ignoreEdgeSwipe(event);
      }
    );
  }

  private listenToSwipeDown() {
    let startY = 0;
    let endY = 0;
    const overlayRef = this.openedContextMenu.menuRef;
    const rootElement = overlayRef.overlayElement;

    this.eventListenerManager.addEvent = this.eventManager.addEventListener(rootElement, 'touchstart', (event: TouchEvent) => {
      if (!isValidScrollEvent(event, rootElement)) {
        return;
      }
      startY = event.targetTouches[0].clientY;
      endY = startY;
    });

    this.eventListenerManager.addEvent = this.eventManager.addEventListener(overlayRef.overlayElement, 'touchmove', (event: TouchEvent) => {
      if (!isValidScrollEvent(event, rootElement)) {
        return;
      }
      const ydiff = Math.round(event.targetTouches[0].clientY - endY);

      if (ydiff > 0) {
        overlayRef.overlayElement.setAttribute('style', `transform: translateY(${ydiff}px)`);
      }
    });

    this.eventListenerManager.addEvent = this.eventManager.addEventListener(overlayRef.overlayElement, 'touchend', (event: TouchEvent) => {
      if (!isValidScrollEvent(event, rootElement)) {
        return;
      }
      const overlayHeight = overlayRef.overlayElement.offsetHeight;
      const movedHeight = Math.round(event.changedTouches[0].clientY - startY);

      if (Math.floor((movedHeight * 100) / overlayHeight) > 20) {
        this.close();
      } else {
        overlayRef.overlayElement.setAttribute('style', `transform: translateY(0)`);
      }
    });
  }

  private removeOpenContextMenu() {
    if (this.openedContextMenu) {
      this.openedContextMenu = null;
    }
  }

  private getContextMenuById(id: string) {
    return this.openedContextMenu?.id === id;
  }
}
