import { DeviceKeyAlgorithm, OlmDeviceModel } from '@portal/wen-backend-api';
import { AccountIdentityKeys } from '../types';

export const createDeviceKeysPayload = (
  userId: string,
  deviceId: string,
  identityKeys: AccountIdentityKeys
) => {
  const { curve25519, ed25519 } = identityKeys;
  const curveIdKey: OlmDeviceModel = {
    algorithm: DeviceKeyAlgorithm.curve25519,
    deviceId,
    userId,
    key: curve25519
  };
  const edIdKey: OlmDeviceModel = {
    algorithm: DeviceKeyAlgorithm.ed25519,
    deviceId,
    userId,
    key: ed25519
  };
  return {
    userId,
    deviceKeys: [curveIdKey, edIdKey]
  };
};
