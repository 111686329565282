import { RouteConfigInitializer } from './route-config-initializer';
import { WenNavigationHelper } from './types';

export class NavigationHelperProxy {

  private currentDelegate: WenNavigationHelper;

  constructor(
    private routeConfigInitializer: RouteConfigInitializer
  ) {
    this.setupDelegate();
    return new Proxy(this, this);
  }

  public get(thisObj: any, propKey: string) {
    if (!this.currentDelegate) {
      return thisObj[propKey];
    }
    const targetProp = this.currentDelegate[propKey];
    if (!targetProp || typeof targetProp !== 'function') {
      return targetProp;
    }
    return (...args: any[]) => {
      const result = targetProp.apply(this.currentDelegate, args);
      return result;
    };
  }

  private setupDelegate() {
    this.routeConfigInitializer.onNavigatorChange$.subscribe((navigatorImpl) => {
      this.currentDelegate = navigatorImpl;
    });
  }

}

export const createNavigationHelper = (
  routeConfigInitializer: RouteConfigInitializer,
) => {
  return new NavigationHelperProxy(routeConfigInitializer);
};
