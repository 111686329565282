@if (listItemModel$ | async; as model) {
  <wen-list-item
    [showAfter]="true"
    [selected]="isSelected$ | async"
    justifyInfoContent="center"
    (press)="openContextMenu(model)"
    >
    <wen-circle-image-view
      wen-list-item-icon
      [imageUrl]="model?.icon"
      [shape]="'circle'"
      [placeholderText]="model.title"
    ></wen-circle-image-view>
    <span wen-list-item-title>{{ model.title }}</span>
    @if (model?.muteIcon) {
      <wen-icon class="wen-icon-small" wen-list-item-title-suffixicon [iconName]="model.muteIcon" [color]="'#767676'"></wen-icon>
    }
    @if (model?.pinTimestamp) {
      <wen-icon wen-list-item-title-suffixicon class="wen-channel-list-item-title-icon wen-icon-small" iconName="pin" color="#767676"></wen-icon>
    }
    <div wen-list-item-description class="wen-chat-list-item-description">
      @if (!model.details?.deleted) {
        <wen-message-status-indicator *isLoggedInUser="model?.insertUser?.id; else otherUserTemplate"
          [messageStatus]="model.lastMessage | calculateMessageStatus: model" [iconClassName]="'wen-icon-small'"
          class="wen-message-status-indicator">
        </wen-message-status-indicator>
      }
      <ng-template #otherUserTemplate>
        @if (model.roomType === 'GROUP' && model?.insertUser?.name) {
          <span class="wen-list-item-description" with-colon>{{model?.insertUser?.name}}</span>
        }
      </ng-template>
      @if (model.details?.icon) {
        <wen-icon [iconName]="model.details?.icon" class="wen-icon-small"></wen-icon>
      }
      @if (model.details?.title) {
        <span class="wen-list-item-description" [innerHtml]="model.details.title | translate | removeMarkdown : markdownEnabled" [class.italic]="!!model.details?.isItalic"></span>
      }
    </div>
    <ng-container wen-list-item-after>
      <div portal-subheading-1 class="wen-list-item-timestamp">{{model.timestamp | messageDateFormatter }}</div>
      @if (model.count > 0) {
        <wen-notification-count class="wen-notification-count-big" [value]="model.count" [disabled]="model.isMuted">
        </wen-notification-count>
      }
    </ng-container>
  </wen-list-item>
}