

export enum NotificationType {
  COMMENT = 'COMMENT',
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
  CHANNEL_INVITE_REQUEST = 'CHANNEL_INVITE_REQUEST',
  REACTION = 'REACTION',
  PRIVILEGE_REVOKED = 'PRIVILEGE_REVOKED',
  CONTACT_REQUEST = 'CONTACT_REQUEST',
  CONTACT_DENY = 'CONTACT_DENY',
  CONTACT_ACCEPT = 'CONTACT_ACCEPT',
}

export enum NotificationContextType {
  CHANNEL = 'CHANNEL',
  CHANNEL_MESSAGE = 'CHANNEL_MESSAGE',
  CONTACT = 'CONTACT',
}

export interface TriggerUser {
  userId: string;
  displayName: string;
}

export type NotificationData = {
  notificationType: NotificationType;
  /** The target entity id where the notification is triggered from */
  contextId: string;
  contextType: NotificationContextType;
  triggerUser: TriggerUser;
  header: string;
  message: string;
};
