import { CdkScrollable, ExtendedScrollToOptions } from '@angular/cdk/scrolling';
import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, first, map, takeUntil } from 'rxjs/operators';
import { WenNativeApi } from '@portal/wen-native-api';

@Injectable()
export class MessageViewScrollHelper implements OnDestroy {

  private onDestroy$ = new Subject<void>();
  private scrollPosition = new ReplaySubject<number>(1);

  private cdkScrollable: CdkScrollable;

  public get scroller() { return this.cdkScrollable; }
  public scrollPosition$ = this.scrollPosition.asObservable();

  constructor(
    private readonly wenNativeApi: WenNativeApi
  ) { }

  connect(cdkScrollable: CdkScrollable) {
    this.cdkScrollable = cdkScrollable;
    this.cdkScrollable.elementScrolled().pipe(
      map((event) => {
        const target = event.target as HTMLElement;
        return target.scrollTop;
      }),
      debounceTime(100),
      takeUntil(this.onDestroy$)
    ).subscribe(this.scrollPosition);
  }

  scrollToBottomOnInputFocus() {
    if (this.wenNativeApi.isReactNativeApp()) {
      this.wenNativeApi.keyboardDidShow$.pipe(
        first()
      ).subscribe(() => {
        this.scrollTo({ bottom: 0 });
      });
    } else {
      this.scrollTo({ bottom: 0 });
    }
  }

  scrollTo(options: ExtendedScrollToOptions) {
    this.scroller.scrollTo(options);
  }

  getElementRef() {
    return this.scroller.getElementRef();
  }

  measureScrollOffset(from: Parameters<CdkScrollable['measureScrollOffset']>[0]) {
    return this.cdkScrollable.measureScrollOffset(from);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
