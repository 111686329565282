import { createAction, props } from '@ngrx/store';
import { CategoriesDTO, ChannelConfigurationResponse, ChannelDTO, ChannelMessageDTO, ChannelMessagesDetailResponse, EmbedDTOTypes, GeoAutocompleteFeature, PagingHistoryFlags, PagingReplayDirection, PasswordRestrictionResolveObject, RestrictionDTO, SimpleUserDTO, SubscribersDTO } from '@portal/wen-backend-api';
import { LoadingState } from '../../common/types/store-loading-state';
import { DraftMessageEntity } from '../common/models';
import { ChannelPermission } from '../user/models/Permission';
import { ChannelEntity, ChannelMessageEntity, ChannelMeta, FeaturedEntity } from './channel.state';

export const subscribeToUserChannelUpdates = createAction(
  '[Channel] Subscribe to channel updates for the logged in user'
);

export const subscribeToDiscoverChannelUpdates = createAction(
  '[Channel] Subscribe to channel updates for discover channel'
);

export const subscribeToChannelMessageModifications = createAction(
  '[Channel] Subscribe to channel message modification updates'
);

export const subscribeToChannelRestrictionUpdates = createAction(
  '[Channel] Subscribe to channel restriction updates'
);

export const subscribeToChannelUpdates = createAction(
  '[Channel] Subscribe to channel modification updates',
);

export const subscribeToJoinRequestChannelUpdates = createAction(
  '[Channel] Subscribe to join request channel modification updates',
);

export const subscribeToChannelJoinRequestWithdrawUpdates = createAction(
  '[Channel] Subscribe to channel join request withdraw updates',
);

export const subscribeToFeaturedItemUpdates = createAction(
  '[Channel] Subscribe to featured item updates',
);

export const subscribeToChannelRoleUpdates = createAction(
  '[Channel] Subscribe to channel role modification updates',
);

export const initializeChannelFilters = createAction(
  '[Channel] Initialize channel filters'
);

export const fetchPermissionsForChannel = createAction(
  '[Channel] Fetch permissions for channel',
  props<{ channelId: string; useCache?: boolean }>()
);

export const updateChannelList = createAction(
  '[Channel] Update channel list for unknown channel',
  props<{ channels: ChannelEntity[] }>()
);

export const updateChannel = createAction(
  '[Channel] Update channel',
  props<{ channel: ChannelEntity }>()
);

export const upsertChannelMeta = createAction(
  '[Channel] Update the channel metadata',
  props<{ channelMetas: ChannelMeta[] }>()
);

export const updateChannelDetail = createAction(
  '[Channel] Update channel detail',
  props<{ channel: ChannelEntity }>()
);

export const updateUserChannelList = createAction(
  '[Channel] Update channel list for the logged in user',
  props<{ channels: ChannelEntity[] }>()
);

export const updateInviteRequestUserChannelList = createAction(
  '[Channel] Update invite request related channels for the logged in user',
  props<{ channels: ChannelEntity[] }>()
);

export const updateChannelCategories = createAction(
  '[Channel] Update channel categories',
  props<{ categories: CategoriesDTO }>()
);

export const updateChannels = createAction(
  '[Channels] Update channels',
  props<{ channels: ChannelDTO[] }>()
);

export const cleanUpPendingJoinRequestChannel = createAction(
  '[Channel] Clean up the invite object of the withdrawn/denied channel for join requests',
  props<{ channelId: string }>()
);

export const cleanUpInviteToChannelRequestChannel = createAction(
  '[Channel] Clean up the invite object of the withdrawn/denied channel for invite to channel request',
  props<{ channelId: string }>()
);

export const withdrawJoinRequestForTheCurrentChannel = createAction(
  '[Channel] Withdraw the join request for the current channel'
);

export const handleChannelJoinRequest = createAction(
  '[Channel] The admin accepts/denies the channel join request',
  props<{ requestId: string; subjectName: string; accepted: boolean }>()
);

export const handleInviteToChannelRequest = createAction(
  '[Channel] The user accepts/denies the channel invitation request',
  props<{ inviteId: string; accepted: boolean }>()
);

export const acknowledgeDeletedCurrentChannel = createAction(
  '[Channel] The users accept that the admin deleted the current channel'
);

export const acknowledgeChannelJoinDenial = createAction(
  '[Channel] The users accept that the admin denied their join request',
  props<{ requestId: string }>()
);

export const reloadDiscoverChannelList = createAction(
  '[Channel] Reload channel list for discover channel'
);

export const updateDiscoverChannelList = createAction(
  '[Channel] Update channel list for discover channel',
  props<{ channels: ChannelEntity[] }>()
);

export const updateUserChannelLoadingState = createAction(
  '[Channel] Update the loaded and error state of the channel list',
  props<{ loadingState: LoadingState }>()
);

export const updateDiscoverChannelLoadingState = createAction(
  '[Channel] Update the loaded and error state of the channel list for discover channel',
  props<{ loadingState: LoadingState }>()
);

export const upsertChannelMessages = createAction(
  '[Channel] Upsert channel messages',
  props<{ messages: ChannelMessageEntity[] }>()
);

export const upsertChannelMessagesHistory = createAction(
  '[Channel] Upsert channel messages history',
  props<{ channelId: string; messages: ChannelMessageEntity[]; flags: PagingHistoryFlags }>()
);

export const replaceChannelMessagesHistory = createAction(
  '[Channel] Replace channel messages history',
  props<{ channelId: string; messages: ChannelMessageEntity[]; flags: PagingHistoryFlags }>()
);

export const updateChannelMessages = createAction(
  '[Channel] Update channel messages',
  props<{ messages: ChannelMessageEntity[] }>()
);

export const updateChannelMessagesHistory = createAction(
  '[Channel] Update channel messages history',
  props<{ channelId: string; messages: ChannelMessageEntity[]; flags: PagingHistoryFlags }>()
);

export const upsertChannelExtraMessages = createAction(
  '[Channel] Upsert channel extra messages',
  props<{ messages: ChannelMessageEntity[] }>()
);

export const updateChannelExtraMessages = createAction(
  '[Channel] Update channel extra messages',
  props<{ messages: ChannelMessageEntity[] }>()
);

export const clearChannelMessagesHistory = createAction(
  '[Channel] Clear channel messages history',
  props<{ channelId: string }>()
);

export const fetchHistoricalMessagesForCurrentChannel = createAction(
  '[Channel] Fetch historical messages for current channel',
  (payload: { timestamp: string; direction: PagingReplayDirection } = null) => payload
);

export const clearHistoricalMessagesForChannel = createAction(
  '[Channel] Clear historical messages for a channel',
  props<{ channelId: string; keepMessageId: string }>()
);

export const setSubscriptionToCurrentChannel = createAction(
  '[Channel] Set subscription for current channel',
  props<{ setSubscribed: boolean; restriction?: PasswordRestrictionResolveObject }>()
);

export const setSubscriptionToChannelById = createAction(
  '[Channel] Set subscription for channel by id',
  props<{ channelId: string; setSubscribed: boolean; restriction?: PasswordRestrictionResolveObject }>()
);

export const resolveSubscriptionRestriction = createAction(
  '[Channel] Resolve subscription restriction',
  props<{ channelId: string; autoAcknowledge?: boolean }>()
);

export const listChannelSubscribers = createAction(
  '[Channel] List channel subscribers for the current channel',
);
export const listElevatedPrivilegeChannelMembers = createAction(
  '[Channel] List channel admins, contributors etc for the current channel',
);

export const updateSubscriberList = createAction(
  '[Channel] Update subscriber list',
  props<{ channelId: string; users: SubscribersDTO }>()
);

export const toggleMuteChannel = createAction(
  '[Channel] Mute or unmute a channel',
  props<{ channelId: string; mute: boolean }>()
);

export const enableAutoAcknowledge = createAction(
  '[Channel] Enable auto acknowledge for channel',
  props<{ channelId: string }>()
);

export const disableAutoAcknowledge = createAction(
  '[Channel] Disable auto acknowledge'
);

export const clearChannelMessagesNewState = createAction(
  '[Channel] Reset message new state for a channel for all messages or based on the last read message timestamp',
  props<{ channelId: string; lastReadTimestamp?: string }>()
);

export const clearBatchChannelMessagesNewState = createAction(
  '[Channel] Reset message new state for a batch of message',
  props<{ messages: ChannelMessageDTO[] }>()
);

export const acknowledgeChannelMessagesLocally = createAction(
  '[Channel] Acknowledge a batch of channel message locally',
  props<{ messages: ChannelMessageDTO[] }>()
);

export const requestChannelMessageAcknowledgeBroadcast = createAction(
  '[Channel] Try to broadcast the current channel acknowledge state',
  props<{ channelId: string }>()
);

export const updateLastReadTimestamp = createAction(
  '[Channel] Update the last read timestamp for a channel',
  props<{ channelId: string; lastReadTimestamp: string }>()
);

export type MessageWithMediaPayload = {
  file: File;
  message: Exclude<Partial<ChannelMessageDTO>, 'timestamp'>;
  dateOfSend: string;
};

/**
 * @deprecated The pending messages are not existing anymore. Server draft messages are created instead
 */
export const updatePendingChannelMessage = createAction(
  '[Media] Upload is processed',
  props<{ uploadId: string; channelId: string }>()
);

/**
 * @deprecated The pending messages are not existing anymore. Server draft messages are created instead
 */
export const removePendingChannelMessage = createAction(
  '[Channel] Remove pending channel message',
  props<{ uploadId: string; channelId: string }>()
);

export const createPendingChannelMessage = createAction(
  '[Channel] Create pending message',
  props<{ message: Partial<ChannelMessageEntity> }>()
);

export const sendEditedChannelMessage = createAction(
  '[Channel] Send edited channel message',
  props<{ id: string; content?: string; embeds?: EmbedDTOTypes[]; scheduled?: boolean; timestamp?: string }>()
);

export const updateChannelPermission = createAction(
  '[Channel] Update the channel permission',
  props<{ channelId: string; permission: ChannelPermission }>()
);

export const updateChannelRestrictions = createAction(
  '[Channel] Update the channel restrictions',
  props<{ channelId: string; restrictions: RestrictionDTO[] }>()
);

export const setFeaturedData = createAction(
  '[Channel] Set the featured entity',
  props<{ featuredData: FeaturedEntity }>()
);

export const updateFeaturedChannel = createAction(
  '[Channel] Update featured channel data',
  props<{ channel: ChannelDTO }>()
);

export const fetchScheduledMessagesForCurrentChannel = createAction(
  '[Channel] Fetch scheduled messages for channel',
  props<{ channelId: string; timestamp: string; direction: PagingReplayDirection }>()
);

export const updateScheduledMessagesCount = createAction(
  '[Channel] Update scheduled message count for channel',
  props<{ channelId: string; count: number }>()
);

export const fetchScheduledMessagesCount = createAction(
  '[Channel] Fetch scheduled message count for channel',
  props<{ channelId: string }>()
);

export const updateScheduledMessages = createAction(
  '[Channel] Update scheduled messages for channel',
  props<{ messages: ChannelMessageDTO[]; channelId: string; hasMore: boolean }>()
);

export const updateScheduledMessage = createAction(
  '[Channel] Update scheduled message for channel',
  props<{ message: ChannelMessageDTO }>()
);

export const removeScheduledMessage = createAction(
  '[Channel] Remove scheduled message from store',
  props<{ message: ChannelMessageDTO }>()
);

export const removeScheduledMessages = createAction(
  '[Channel] Remove scheduled messages from store',
  props<{ channelId: string; messages: ChannelMessageDTO[] }>()
);

export const updateDraftChannelMessage = createAction(
  '[Channel] Update draft channel message',
  props<{ message: DraftMessageEntity }>()
);

export const removeDraftChannelMessage = createAction(
  '[Channel] Remove draft channel message',
  props<{ channelId: string }>()
);

export const prepareChannelMessage = createAction(
  '[Channel] Convert draft message to pending channel message',
  props<{ channelId: string }>()
);

export const editChannelMessage = createAction(
  '[Channel] Convert existing channel message to a draft',
  props<{ messageId: string }>()
);

export const deleteChannelMessage = createAction(
  '[Channel] Delete a channel message',
  props<{ messageId: string }>()
);

export const updateContentProvidersForChannel = createAction(
  '[Channel] Update content providers for channel',
  props<{ channelId: string; users: SimpleUserDTO[] }>()
);

export const addContentProviderToCurrentChannel = createAction(
  '[Channel] Add content provider to current channel',
  props<{ userId: string }>()
);

export const deleteContentProviderFromCurrentChannel = createAction(
  '[Channel] Delete content provider from current channel',
  props<{ userId: string }>()
);

export const updateContentProvider = createAction(
  '[Channel] Update content provider',
  props<{ channelId: string; user: SimpleUserDTO }>()
);

export const updateCurrentChannelAdmins = createAction(
  '[Channel] Update current channel admins',
  props<{ channelId: string; users: SimpleUserDTO[] }>()
);

export const addAdminForCurrentChannel = createAction(
  '[Channel] Add user as admin for current channel',
  props<{ userId: string }>()
);

export const updateAdminListWithNewUserForChannel = createAction(
  '[Channel] Update admin list with current user for channel',
  props<{ user: SimpleUserDTO; channelId: string }>()
);

export const deleteAdminForCurrentChannel = createAction(
  '[Channel] Delete an admin for current channel',
  props<{ userId: string }>()
);

export const updateGeoFilterForChannel = createAction(
  '[Channel] Update geo filter for current channel',
  props<{ channelId: string; geoJson: GeoAutocompleteFeature; distance: number }>()
);

export const togglePinChannel = createAction(
  '[Channel] Toggle pin current channel',
  props<{ channelId: string; isPinned: boolean }>()
);

export const removeUserFromCurrentChannel = createAction(
  '[Channel] Remove subscriber user from current channel',
  props<{ userIdToRemove?: string }>()
);

export const showScheduledChannelPostSuccessToast = createAction(
  '[Channel] Show scheduled post success toast'
);

export const updateChannelMessagesDetail = createAction(
  '[Channel] Update channel messages detail',
  props<{ payload: ChannelMessagesDetailResponse }>()
);


export const fetchConfigurationForChannel = createAction(
  '[Channel] Fetch configuration for channel'
);

export const updateChannelConfiguration = createAction(
  '[Channel] Update configuration for channel',
  props<{ response: ChannelConfigurationResponse }>()
);
