import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { StoreNavigator } from '../../../../../core/services/navigation/store-navigator';
import { WenNativeApi } from '@portal/wen-native-api';
import { logout } from '../../../../../core/store/auth/auth.actions';
import { clearAllFormValues } from '../../../../../core/store/form/form.actions';
import { RootState } from '../../../../../core/store/root/public-api';
import { WenOAuthService } from '../../../../../core/services/user-management/wen-oauth.service';

export interface RegistrationSuccessData {
  suggestedUsername: string;
}

@Component({
  selector: 'wen-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrls: ['./registration-success.component.scss']
})
export class RegistrationSuccessComponent {

  isNativeApp = this.nativeApi.isReactNativeApp();
  showAppStore = this.storeNavigator.isAppStoreConfigValid();
  showPlayStore = this.storeNavigator.isPlayStoreConfigValid();

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<any>,
    private nativeApi: WenNativeApi,
    private storeNavigator: StoreNavigator,
    private oauthService: WenOAuthService,
    @Inject(MAT_DIALOG_DATA) private dialogData: RegistrationSuccessData,
  ) { }

  navigateToLogin() {
    this.dialogRef.close();
    this.store.dispatch(clearAllFormValues());
    this.store.dispatch(logout({
      suggested_username: this.dialogData.suggestedUsername,
      postLogoutRedirectUri: this.oauthService.oauthRoutes.login
    }));
  }

  goToPlayStore() {
    this.storeNavigator.goToPlayStore();
  }

  goToAppStore() {
    this.storeNavigator.goToAppStore();
  }

}
