import { DataContext } from '../../common/types/data-context';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { pinFeatureKey } from './constants';
import { pinEntityAdapter, PinState } from './pin.state';

const selectPinState = createFeatureSelector<PinState>(pinFeatureKey);

export const selectPinEntities = createSelector(
  selectPinState,
  pinState => pinState.pinEntities
);

const {
  selectAll: selectAllPinItem
} = pinEntityAdapter.getSelectors(selectPinEntities);


export const selectPinnedItemsBy = (context: DataContext) => createSelector(
  selectAllPinItem,
  allPinItem => {
    return allPinItem.filter(pinItem => pinItem.contextType === context);
  }
);

export const selectPinItem = createSelector(
  selectAllPinItem,
  (allPinItems) => (channelId: string) => {
    return allPinItems.find(pinItem => pinItem.contextId === channelId);
  }
);
