import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wen-middle-ellipsize]',
})
export class MiddleEllipsizeDirective {
  private innerEndLength = 5;

  @Input('wen-middle-ellipsize')
  set endLength(value: number) {
    if (!value) {
      return;
    }
    this.innerEndLength = value;
  }
  @Input() set text(value: string) {
    this.ellipsize(value);
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private render: Renderer2
  ) {
  }

  ellipsize(text: string) {
    if (!text?.length) {
      return;
    }
    this.elementRef.nativeElement.innerHTML = '';

    const length = text.length;
    const textStart = this.render.createText(text.substring(0, length - this.innerEndLength));
    const textEnd = this.render.createText(text.substring(length - this.innerEndLength, length));

    const start = this.render.createElement('span') as HTMLElement;
    this.render.setStyle(start, 'overflow', 'hidden');
    this.render.setStyle(start, 'white-space', 'nowrap');
    this.render.setStyle(start, 'text-overflow', 'ellipsis');
    this.render.appendChild(start, textStart);

    const end = this.render.createElement('span') as HTMLElement;
    this.render.appendChild(end, textEnd);

    this.render.setStyle(this.elementRef.nativeElement, 'display', 'flex');
    this.render.appendChild(this.elementRef.nativeElement, start);
    this.render.appendChild(this.elementRef.nativeElement, end);
  }
}
