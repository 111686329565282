import { Component, Input } from '@angular/core';

@Component({
  selector: 'wen-interact-button',
  templateUrl: './interact-button.component.html',
  styleUrls: ['./interact-button.component.scss']
})
export class InteractButtonComponent {
  @Input() disabled: boolean;
}
