import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { exhaustMap, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { acceptLeaveEditMode, cancelLeaveEditMode, detectFormChanges, requestLeaveEditMode, saveEditForm } from '../../core/store/form/form.actions';
import { selectEditFormById } from '../../core/store/form/form.selectors';
import { RootState } from '../../core/store/root/public-api';
import { FormStoreKey } from './form-store-types';

@Injectable()
export class FormStoreMediator {

  constructor(
    protected readonly actions$: Actions,
    protected readonly store: Store<RootState>,
  ) { }

  private saveEditFormIfValid$ = this.actions$.pipe(
    ofType(saveEditForm),
    switchMap((action) => {
      return this.store.pipe(
        select(selectEditFormById(action.formId)),
        first(),
        filter(editForm => Boolean(editForm)),
        switchMap((editForm) => {
          return this.detectFormChanges(editForm.formId, false).pipe(
            first(),
            map(status => status === 'VALID'),
          );
        }),
        filter(validity => validity),
        map(() => action)
      );
    })
  );

  detectFormChanges(formId: FormStoreKey, skipValidation: boolean) {
    this.store.dispatch(detectFormChanges({ skipValidation }));
    const formIsValid$ = this.actions$.pipe(
      ofType(detectFormChanges),
      first(),
      switchMap(() => this.store.pipe(
        select(selectEditFormById(formId))
      )),
      filter((formEntity) => {
        const isDirty = formEntity?.dirty;
        const isStable = formEntity && formEntity.status !== 'PENDING';
        return isDirty && isStable;
      }),
      first(),
      map(formEntity => formEntity?.status)
    );
    return formIsValid$;
  }

  withEditModeConfirmation(afterLeave: () => void, formId: FormStoreKey, clearAllForms?: boolean) {
    this.actions$.pipe(
      ofType(acceptLeaveEditMode),
      first(),
      takeUntil(
        this.actions$.pipe(
          ofType(cancelLeaveEditMode),
          first()
        )
      )
    ).subscribe(() => {
      afterLeave();
    });
    this.store.dispatch(requestLeaveEditMode({ formId, clearAllForms }));
  }

  createSaveEditFormEffect(
    source: (saveActionPayload: ReturnType<typeof saveEditForm>) => Observable<Action>,
    config?: Parameters<typeof createEffect>[1]
  ) {
    return createEffect(() => {
      return this.saveEditFormIfValid$.pipe(
        exhaustMap(action => source(action)),
      );
    }, config);
  }

}
