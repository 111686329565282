import { walletFeature } from './wallet.reducers';
import { createSelector } from '@ngrx/store';
import { WalletCredentialInfo } from './constants';
import { WalletCredentialType } from '@portal/wen-backend-api';

export const {
  selectCredentials
} = walletFeature;

export const selectUnitedKioskFlatCredential = createSelector(
  selectCredentials,
  (credentials): WalletCredentialInfo => {
    const credential = credentials.find(singleCredential =>
      singleCredential.type === WalletCredentialType.UNITED_KIOSK_NEWS_FLAT
    );
    return credential ? {...credential, active: true} : { type: WalletCredentialType.UNITED_KIOSK_NEWS_FLAT, active: false };
  }
);
