

import { LocationStrategy } from '@angular/common';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { WenBreakpointObserver, WenPathLocationStrategy, WenRouteTransitionData } from '@portal/wen-components';
import { Observable, first, map } from 'rxjs';
import { BackNavigationBehaviorFactoryProvider, WenRouteId } from '../../../../../frame/routing/types';
import { RootState } from '../../../../store/root/public-api';
import { selectAllRouteParams } from '../../../../store/root/root.selectors';
import { WenRouteUrlBuilder } from '../../outlet-specific/wen-route-url-builder';
import { BackNavigationBehavior, BackNavigationBehaviorResult } from './back-navigation-behavior';

export interface DeepLinkedBackNavigationConfig {
  mobileTransition?: WenRouteTransitionData<WenRouteId>;
  desktopTransition?: WenRouteTransitionData<WenRouteId>;
}

export const createDeepLinkedBackNavigation = (config: DeepLinkedBackNavigationConfig): BackNavigationBehaviorFactoryProvider => {
  return (injector: Injector) => {
    const routeUrlBuilder = injector.get(WenRouteUrlBuilder);
    const store = injector.get(Store);
    const router = injector.get(Router);
    const breakpointObserver = injector.get(WenBreakpointObserver);
    const location = injector.get(LocationStrategy) as WenPathLocationStrategy;
    const deepLinkedBackNavigation = new DeepLinkedBackNavigation(config, routeUrlBuilder, router, location, store, breakpointObserver);
    return deepLinkedBackNavigation;
  };
};

export class DeepLinkedBackNavigation implements BackNavigationBehavior {

  constructor(
    private config: DeepLinkedBackNavigationConfig,
    private routeUrlBuilder: WenRouteUrlBuilder,
    private router: Router,
    private location: WenPathLocationStrategy,
    private store: Store<RootState>,
    private breakpointObserver: WenBreakpointObserver,
  ) { }

  execute(): BackNavigationBehaviorResult | Observable<BackNavigationBehaviorResult> {
    const locationState = this.location.getState();
    if (!locationState?.fromDeepLink) {
      return {
        canHandle: false
      };
    }
    return this.store.pipe(
      select(selectAllRouteParams),
      first(),
      map((params) => {
        const { mobileTransition, desktopTransition } = this.config;
        const transition = this.breakpointObserver.isDesktopStyleDevice ? desktopTransition : mobileTransition;
        const results = this.routeUrlBuilder.buildUrlsFor(transition, params);
        this.router.navigate([{
          outlets: results
        }], { replaceUrl: true, state: { fromDeepLink: true } });
        return { canHandle: true };
      })
    );
  }

}
