import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { distinctUntilKeyChanged, filter, mergeMap, first, switchMap, withLatestFrom } from 'rxjs';
import { DataContext } from '../../../common/types/data-context';
import { fetchSectionContent, updateAppExploreSectionLoadingState, updateSectionContent } from '../../sections/sections.actions';
import { updateApps } from '../apps.actions';
import { AppEntity } from '../apps.state';
import { Store, select } from '@ngrx/store';
import { RootState } from '../../root/public-api';
import { LoadingState } from '../../../common/types/store-loading-state';
import { selectAppExploreIsLoaded } from '../../sections/sections.selectors';

@Injectable()
export class AppSectionEffects {
  private readonly appSections$ = createEffect(() => this.actions$.pipe(
    ofType(fetchSectionContent),
    distinctUntilKeyChanged('sectionId'),
    filter(({ dataContext }) => dataContext === DataContext.APP),
    withLatestFrom(this.store.pipe(select(selectAppExploreIsLoaded))),
    filter(([_, isLoaded]) => !isLoaded),
    mergeMap(([{ params, sectionId }]) => {
      const requestParams = { filter: params.filter, size: params.limit, page: 0 };
      return this.socketIoService.app.listForDiscover.acknowledgement$(requestParams).pipe(
        first(),
        switchMap(({ content }) => {
          return [
            updateApps({apps: content as AppEntity[]}),
            updateSectionContent({ data: content, sectionId }),
            updateAppExploreSectionLoadingState({loadingState: LoadingState.LOADED})
          ];
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private store: Store<RootState>
  ) { }

}
