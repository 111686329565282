import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ComingSoonComponent } from './coming-soon.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [ComingSoonComponent],
  exports: [ComingSoonComponent]
})
export class ComingSoonModule { }
