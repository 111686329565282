import { Injectable } from '@angular/core';
import { MultitronicMassDataRepository } from '@portal/wen-data-core';
import { ExistingContactsCreateChatSearchMassDataRepository, FurtherCreateChatSearchMassDataRepository } from '../providers/chat-member-edit-search-repositories';
import { AddChatUsersSearchMassDataContainer } from './add-chat-users-search-mass-data-container';

@Injectable()
export class AddChatUsersSearchMassDataRepository extends MultitronicMassDataRepository<AddChatUsersSearchMassDataContainer> {

  constructor(
    existingContactsCreateChatSearchMassDataRepository: ExistingContactsCreateChatSearchMassDataRepository,
    furtherCreateChatSearchMassDataRepository: FurtherCreateChatSearchMassDataRepository,
  ) {
    super([existingContactsCreateChatSearchMassDataRepository, furtherCreateChatSearchMassDataRepository]);
  }

}
