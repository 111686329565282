import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MenuEntryModule, WenIconModule, WenTestIdModule } from '@portal/wen-components';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { WenRouteId } from '../../../../../frame/routing/types';
import { UserPersonalAccountSettingsDatasource } from './user-personal-account-settings.datasource';

@Component({
  selector: 'wen-user-personal-account-settings',
  templateUrl: './user-personal-account-settings.component.html',
  styleUrls: ['./user-personal-account-settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MenuEntryModule,
    WenIconModule,
    WenTestIdModule,
  ],
  providers: [
    UserPersonalAccountSettingsDatasource,
  ]
})
export class UserPersonalAccountSettingsComponent {

  public readonly featureEnablementService = inject(FeatureEnablementService);
  public readonly datasource = inject(UserPersonalAccountSettingsDatasource);

  private appNavigator = inject(AppNavigator);

  public readonly enableEmailVerification$ = this.featureEnablementService.featureConfigObs.enableEmailVerification$;
  public readonly enablePasswordChange$ = this.featureEnablementService.featureConfigObs.enablePasswordChange$;

  navigateToEmailSettings() {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_EMAIL_SETTINGS);
  }

  navigateToPasswordEdit() {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_CHANGE_PASSWORD);
  }

}
