import { createFeatureSelector, createSelector } from '@ngrx/store';
import { appDetailIdentifier, appViewIdentifier } from '../../../views/apps/tokens';
import { LoadingState } from '../../common/types/store-loading-state';
import { selectActiveNetwork } from '../network/network.selectors';
import { RootState } from '../root/public-api';
import { selectRouteParam } from '../root/root.selectors';
import { AppsState, appMetaAdapter, appNetworkEntityHelper, appsAdapter } from './apps.state';
import { appsFeatureKey } from './constants';

export interface WithFeatureState extends RootState {
  [appsFeatureKey]: AppsState;
}

export const selectAppsState = createFeatureSelector<AppsState>(
  appsFeatureKey
);

export const selectAppsEntityState = createSelector(
  selectAppsState,
  (state) => state.apps
);

export const selectAppMetas = createSelector(
  selectAppsState,
  (state) => {
    const channelMetas = appMetaAdapter.getSelectors().selectAll(state.appMetas);
    return channelMetas;
  }
);

export const selectUserAppIds = createSelector(
  selectAppsState,
  selectAppMetas,
  selectActiveNetwork,
  (state, appMetas, activeNetwork) => {
    const { userAppIds = [] } = state;
    const filteredForNetwork = appNetworkEntityHelper.filterActiveInNetwork(appMetas, userAppIds, activeNetwork);
    return filteredForNetwork;
  }
);

export const selectDiscoverAppIds = createSelector(
  selectAppsState,
  (state) => state.discoverAppIds || []
);

export const selectUserAppsLoaded = createSelector(
  selectAppsState,
  (state) => state.userAppsLoading !== LoadingState.NOT_LOADED
);

export const selectDiscoverAppsLoaded = createSelector(
  selectAppsState,
  (state) => state.discoverAppsLoading !== LoadingState.NOT_LOADED
);

const {
  selectAll: selectApps,
  selectEntities: selectAppEntities
} = appsAdapter.getSelectors(selectAppsEntityState);

export const selectUserApps = createSelector(
  selectApps,
  selectUserAppIds,
  (appEntities, appIds) => {
    return appEntities.filter((appEntity) => {
      const appId = appsAdapter.selectId(appEntity).toString();
      return appIds.includes(appId);
    });
  }
);

export const selectDiscoverApps = createSelector(
  selectAppEntities,
  selectDiscoverAppIds,
  (appEntities, discoveryAppIds) => {
    return [...discoveryAppIds].map(id => appEntities[id]).filter(app => Boolean(app));
  }
);

export const selectCurrentAppView = createSelector(
  selectApps,
  selectRouteParam(appViewIdentifier),
  (appEntities, appId) => {
    return appEntities.find((appEntity) => {
      const id = appsAdapter.selectId(appEntity);
      return id === appId;
    });
  }
);

export const selectCurrentAppDetail = createSelector(
  selectApps,
  selectRouteParam(appDetailIdentifier),
  (appEntities, appId) => {
    return appEntities.find((appEntity) => {
      const id = appsAdapter.selectId(appEntity);
      return id === appId;
    });
  }
);

export const selectAppPermission = createSelector(
  selectApps,
  (apps) => (appId: string) => {
    const appEntity = apps.find((app) => {
      const id = appsAdapter.selectId(app);
      return id === appId;
    });

    return appEntity?.permission;
  }
);

export const selectAppCategories = createSelector(
  selectAppsState,
  state => state.categories
);
