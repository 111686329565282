import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs';
import { WenRouteId } from '../../../../frame/routing/types';
import { userProfileIdentifier } from '../../../../views/user-profile/tokens';
import { filterBy } from '../../../common/operators/fitler-by';
import { FeatureEnablementService } from '../../../services/configuration/feature-enablement';
import { AppNavigator } from '../../../services/navigation/app-navigator';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { RootState } from '../../root/public-api';
import { navigateToUserProfile } from '../user.actions';

@Injectable()
export class UserNavigationEffects {

  private readonly store = inject(Store<RootState>);
  private readonly actions$ = inject(Actions);
  private readonly featureEnablementService = inject(FeatureEnablementService);
  private readonly appNavigator = inject(AppNavigator);

  navigateToProfile$ = createEffect(() => this.actions$.pipe(
    ofType(navigateToUserProfile),
    filterBy(() => this.featureEnablementService.featureConfigObs.enablePublicProfile$),
    withLatestFrom(this.store.pipe(
      select(selectCurrentUserData)
    )),
    tap(([{ userId }, currentUser]) => {
      if (currentUser.userId === userId) {
        this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_READ);
      } else {
        this.appNavigator.navigateToRoute(WenRouteId.OTHER_USERPROFILE_READ, { [userProfileIdentifier]: userId });
      }
    })
  ), { dispatch: false });

}
