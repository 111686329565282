<div>
  <img [src]=imageUrl />
  <h5 class="wen-create-welcome-title" [innerHtml]="translationKey + '.0' | translate"></h5>
  <wen-create-welcome-markdown [content]="translationKey + '.1' | translate "></wen-create-welcome-markdown>
  <wen-create-welcome-markdown [content]="translationKey + '.2' | translate "></wen-create-welcome-markdown>
  @if (!isPartnerApp) {
    <wen-create-welcome-markdown [content]="translationKey + '.3' | translate "></wen-create-welcome-markdown>
  }
</div>
<wen-rounded-button [wen-test-id]="'wen-create-welcome-button'" (click)="navigate()">{{ translationKey + '.4' | translate }}</wen-rounded-button>
