import { SuppressBackNavigation } from '../../core/services/navigation/back-navigation/back-navigation-behaviors/suppress-back-navigation-behavior';
import { WenRouteId, WenRoutes } from '../../frame/routing/types';
import { ErrorPageComponent } from './components/error-page/error-page.component';

export const errorPageRoutes: WenRoutes = [
  {
    path: '',
    component: ErrorPageComponent,
    data: {
      routeId: WenRouteId.ERROR_PAGE,
      backNavigationBehavior: SuppressBackNavigation
    }
  }
];
