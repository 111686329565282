import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { filter, first, of, switchMap } from 'rxjs';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { DataObjectType } from '../../common/data-objects';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { clearFormValues } from '../form.actions';
import { selectEditFormById } from '../form.selectors';
import { LanguageFormChangedValues } from '../types/language-types';
import { WenLanguageService } from '@portal/wen-translation';

@Injectable()
export class LanguageFormEffects {
  saveLanguageForm$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.LANGUAGE),
    switchMap(({ formId }) => this.store.pipe(
      select(selectEditFormById(formId)),
      first()
    )),
    switchMap((editForm) => {
      const { formId, changedValues } = editForm;
      const actions: Action[] = [];
      const changedValuesTyped = changedValues as LanguageFormChangedValues;
      const languageCode = changedValuesTyped.primaryLanguage;
      this.languageService.setLanguage(languageCode);
      actions.push(clearFormValues({ formId }));
      actions.push(doBackNavigation());
      return actions;
    })
  ));

  constructor(
    private formStoreMediator: FormStoreMediator,
    private store: Store<RootState>,
    private languageService: WenLanguageService
  ) { }
}
