import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenCardModule, WenIconModule } from '@portal/wen-components';
import { QuickSubscriptionPanelComponent } from './quick-subscription-panel.component';

@NgModule({
  imports: [
    WenCardModule,
    WenIconModule,
    CommonModule
  ],
  exports: [QuickSubscriptionPanelComponent],
  declarations: [QuickSubscriptionPanelComponent]
})
export class EventQuickSubscriptionPanelModule { }
