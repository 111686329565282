import { createFeature, createReducer, on } from '@ngrx/store';
import { WeatherState } from './weather.state';
import { weatherFeatureKey } from './constants';
import { weatherDataRetrieved } from './weather.actions';

export const weatherInitialState: WeatherState = {
  currentWeather: null
};

export const weatherFeature = createFeature({
  name: weatherFeatureKey,
  reducer: createReducer(
    weatherInitialState,
    on(weatherDataRetrieved, (state, { newState }) => {
      return {
        ...state,
        ...newState
      };
    })
  )
});
