import { Injectable, inject } from '@angular/core';
import { RoomType, SocketIoService } from '@portal/wen-backend-api';
import { MassDataContainer, MassDataRepository, PageRequestDescriptor } from '@portal/wen-data-core';
import { Observable, first, map, switchMap } from 'rxjs';
import { ChatMemberEditSearchEntity, ChatMemberEditSearchEntityType } from './chat-member-edit-state-types';
import { EditChatSearchFilterService } from './edit-chat-search-filter-service';


const createPayload = (searchTerm$: Observable<string>, pagingParams: PageRequestDescriptor) => {
  return searchTerm$.pipe(
    first(),
    map(searchTerm => {
      const payload = {
        ...pagingParams,
        search: searchTerm || ''
      };
      return payload;
    })
  );
};

@Injectable()
export class ExistingContactsCreateChatSearchMassDataRepository extends MassDataRepository<MassDataContainer<ChatMemberEditSearchEntity>> {

  private socketIoService = inject(SocketIoService);
  private createChatSearchFilterService = inject(EditChatSearchFilterService);

  fetchPage(pagingParams: PageRequestDescriptor) {
    return createPayload(this.createChatSearchFilterService.searchTerm$, pagingParams).pipe(
      switchMap((payload) => {
        return this.socketIoService.contact.listForUser.acknowledgement$({
          ...payload
        }).pipe(
          map((response) => {
            const entitites = response.contacts
              .filter(contact => contact.user.chatAllowed)
              .map(contact => {
                const { user: { id, displayName: title, avatarUrl } } = contact;
                const entity: ChatMemberEditSearchEntity = {
                  entityType: ChatMemberEditSearchEntityType.EXISTING_CONTACT,
                  listItemEntity: { id, avatarUrl, title }
                };
                return entity;
              });
            return { data: entitites };
          })
        );
      })
    );
  }

}

@Injectable()
export class GroupRoomCreateChatSearchMassDataRepository extends MassDataRepository<MassDataContainer<ChatMemberEditSearchEntity>> {

  private socketIoService = inject(SocketIoService);
  private createChatSearchFilterService = inject(EditChatSearchFilterService);

  fetchPage(pagingParams: PageRequestDescriptor) {
    return createPayload(this.createChatSearchFilterService.searchTerm$, pagingParams).pipe(
      switchMap((payload) => {
        return this.socketIoService.chat.search.acknowledgement$({
          search: payload.search, lastMessage: '', roomType: RoomType.GROUP
        }).pipe(
          map((response) => {
            const entitites = response.chats.map(user => {
              const { id, title, icon } = user;
              const entity: ChatMemberEditSearchEntity = {
                entityType: ChatMemberEditSearchEntityType.GROUP_CHATROOM,
                listItemEntity: { id, avatarUrl: icon, title }
              };
              return entity;
            });
            return { data: entitites };
          })
        );
      })
    );
  }
}

@Injectable()
export class FurtherCreateChatSearchMassDataRepository extends MassDataRepository<MassDataContainer<ChatMemberEditSearchEntity>> {

  private socketIoService = inject(SocketIoService);
  private createChatSearchFilterService = inject(EditChatSearchFilterService);

  fetchPage(pagingParams: PageRequestDescriptor) {
    return createPayload(this.createChatSearchFilterService.searchTerm$, pagingParams).pipe(
      switchMap((payload) => {
        return this.socketIoService.chat.chatSearch.acknowledgement$({
          ...payload
        }).pipe(
          map((response) => {
            const entitites = response.users.map(user => {
              const { userId: id, displayName: title, avatarUrl } = user;
              const entity: ChatMemberEditSearchEntity = {
                entityType: ChatMemberEditSearchEntityType.FURTHER_USER_RESULT,
                listItemEntity: { id, avatarUrl, title }
              };
              return entity;
            });
            return { data: entitites };
          })
        );
      })
    );
  }
}
