<ng-container #containerRef></ng-container>

<ng-template #default>
  <wen-dialog-layout [headerGravity]="'top'" [wen-test-id]= "'filter-selector'">
    <div wen-dialog-layout-static-header class="wen-filter-selector-header" portal-title>{{ filterSelectorHeader | translate }}</div>
    <div dialogLayoutContent>
      <div class="wen-filter-selector-form-group">
        @if (filterSelectionListData$ | async; as data) {
          <wen-selection-list class="wen-borderless-group" [selectionItems]="data.items"
            [selectionModel]="data.model" [selectionOptionPosition]="'postfix'"
            [selectionOptionBorderType]="'borderless'"
            (selectionChanged)="onSelection($event)">
          </wen-selection-list>
        }
      </div>
    </div>
    <div wen-dialog-layout-static-footer>
      <mat-dialog-actions class="wen-filter-dialog-actions">
        <button class="wen-filter-dialog-button" wen-primary-color (click)="onCancel()">{{ "CANCEL_BUTTON_LABEL" | translate }}</button>
      </mat-dialog-actions>
    </div>
  </wen-dialog-layout>
</ng-template>