import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChannelUserListResponse, NetworkMeta, SocketIoService } from '@portal/wen-backend-api';
import { catchError, first, merge, of, switchMap } from 'rxjs';
import { LastArrayElement } from 'type-fest';
import { LoadingState } from '../../../../common/types/store-loading-state';
import { subscribeToUserChannelUpdates, updateUserChannelList, updateUserChannelLoadingState, upsertChannelMeta } from '../../channel.actions';
import { ChannelMeta } from '../../channel.state';

@Injectable()
export class UserChannelListEffects {

  private readonly channelListForUser$ = this.socketIoService.channel.listForUser.listenWithReplay;

  onUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToUserChannelUpdates),
    first(),
    switchMap(() => {
      return merge(
        this.channelListForUser$.pipe(
          switchMap((channels) => {
            const channelMetas = channels.map((channel) => {
              return this.createChannelMetadata(channel);
            });
            return [
              updateUserChannelList({ channels }),
              upsertChannelMeta({ channelMetas }),
              updateUserChannelLoadingState({ loadingState: LoadingState.LOADED })
            ];
          })
        ),
      ).pipe(
        catchError(() => of(updateUserChannelLoadingState({ loadingState: LoadingState.ERROR })))
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
  ) { }

  private createChannelMetadata(channel: LastArrayElement<ChannelUserListResponse>) {
    if (channel.network) {
      const networkData: NetworkMeta = {
        id: channel.network
      };
      if (channel.pinTimestamp) {
        networkData.pinTimestamp = channel.pinTimestamp;
      }
      const channelMetaOldApi: ChannelMeta = {
        entityId: channel.id,
        networkMetas: [networkData]
      };
      return channelMetaOldApi;
    }
    const channelMeta: ChannelMeta = {
      entityId: channel.id,
      networkMetas: channel.networks
    };
    return channelMeta;
  }
}
