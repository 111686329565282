import { Injectable, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { EMPTY, Observable, ReplaySubject, combineLatest, distinctUntilChanged, filter, first, map, of, shareReplay, startWith, switchMap } from 'rxjs';
import { selectCurrentUserData } from '../../../../../../../core/store/auth/auth.selectors';
import { selectCurrentRoomMembers } from '../../../../../../../core/store/chat/chat.selectors';
import { EditChatSearchFilterService } from '../../../../../../../core/store/chat/mass-data/providers/edit-chat-search-filter-service';
import { RootState } from '../../../../../../../core/store/root/public-api';
import { WenRouteId, WenRouteWithId } from '../../../../../../../frame/routing/types';
import { InputWithChipModel } from '../../../../../../../shared/components/input-with-chips/input-with-chips.component';
import { FormStoreService } from '../../../../../../../shared/form-store/form-store.service';
import { maxNumberOfUsersForMassMessageSending } from '../../../../../constants';
import { ChatEditCheckAvailabilityService } from '../../../../providers/chat-edit-check-availability-service';
import { ChatSelectionListItemModel } from '../../../chat-selection-list/providers/chat-selection-list-providers';

@Injectable()
export class AddChatUsersSelectionService {

  public maxSelectionCount = maxNumberOfUsersForMassMessageSending;

  private store = inject(Store<RootState>);
  private formStoreService = inject(FormStoreService);
  private chatAvailabilityChecker = inject(ChatEditCheckAvailabilityService);
  private filterService = inject(EditChatSearchFilterService);
  private activatedRoute = inject(ActivatedRoute);

  public initiallySelectedIds$: Observable<string[]>;
  public selectedIds$: Observable<string[]>;
  public selectedChips$: Observable<InputWithChipModel[]>;
  public selectedChipsCount$: Observable<number>;

  public selectionModeEnabled$: Observable<boolean>;

  constructor() {
    const isEdit$ = this.activatedRoute.data.pipe(
      map((routeData: WenRouteWithId) => routeData?.routeId === WenRouteId.ADD_CHAT_GROUP_MEMBERS)
    );
    const existingRoomMembers$ = this.store.pipe(select(selectCurrentRoomMembers));
    const currentUser$ = this.store.pipe(select(selectCurrentUserData));
    const initialSelection$: Observable<InputWithChipModel[]> = combineLatest([
      isEdit$, existingRoomMembers$, currentUser$
    ]).pipe(
      first(),
      map(([isEdit, roomMembers = [], userData]) => {
        if (isEdit) {
          return roomMembers.map(({ userId, displayName, avatarUrl }) => {
            return { id: userId, text: displayName, icon: avatarUrl };
          });
        }
        const currentUserChip = [{ id: userData.userId, text: userData.username, icon: null }];
        return currentUserChip;
      }),
      shareReplay(1)
    );
    this.initiallySelectedIds$ = initialSelection$.pipe(
      map((chips) => chips.map((chip) => chip.id))
    );

    this.selectedChips$ = this.formStoreService.getFormData().pipe(
      filter(data => !!data?.changedValues),
      smartDistinctUntilChanged(),
      map(({ changedValues }) => {
        const users: InputWithChipModel[] = changedValues?.users;
        return users?.length > 0 ? users : [];
      }),
      shareReplay(1)
    );

    this.selectedIds$ = combineLatest([
      initialSelection$, this.selectedChips$
    ]).pipe(
      map(([dirtyUsers, currentUsers]) => {
        const dirtyUserIds = dirtyUsers.map(user => user.id);
        const currentUserIds = currentUsers.map(user => user.id);
        return [...currentUserIds, ...dirtyUserIds];
      }),
      shareReplay(1)
    );

    this.selectedChipsCount$ = this.selectedIds$.pipe(
      map((chips) => chips?.length || 0)
    );

    this.selectionModeEnabled$ = this.filterService.searchTerm$.pipe(
      distinctUntilChanged(),
      map(searchTerm => Boolean(!searchTerm || searchTerm.length < 1)),
      startWith(true),
      shareReplay(1)
    );
  }

  toggleSelection(item: ChatSelectionListItemModel, control: FormControl) {
    const { id, title, avatarUrl } = item.listItemEntity;
    const usersfield: InputWithChipModel[] = control.value || [];
    const result$ = new ReplaySubject(1);
    if (item.selected) {
      control.markAsDirty();
      control.setValue([...usersfield.filter((user) => user.id !== id)]);
      result$.next(null);
      result$.complete();
    } else if (usersfield.length >= this.maxSelectionCount) {
      result$.error(new Error());
      result$.complete();
    } else {
      this.chatAvailabilityChecker.validateSelection(item).pipe(
        switchMap((unavailableKeys) => {
          if (unavailableKeys.includes(id)) {
            result$.error(new Error());
            result$.complete();
            return EMPTY;
          }
          return of(null);
        })
      ).subscribe(() => {
        control.markAsDirty();
        control.setValue([
          ...control.value,
          { id, text: title, icon: avatarUrl }
        ]);
        result$.next(null);
        result$.complete();
      });
    }
    return result$.asObservable();
  }

}
