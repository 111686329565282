import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { AppDTO, WalletCredentialType } from '@portal/wen-backend-api';
import { WenRoutesProvider } from '../../../../frame/routing/routes/routes.provider';
import { WenRouteId } from '../../../../frame/routing/types';
import { isNullOrUndefined } from '../../../common/operators/null-check-util';
import { WenNativeApi } from '@portal/wen-native-api';
import { WenColorService } from '../../util/wen-color.service';
import { AppNavigator } from '../app-navigator';
import { CHAT_CREATE_FROM_FORWARD_QUERY_PARAM, CHAT_CREATE_FROM_SHARE_QUERY_PARAM, CREDENTIAL_TYPE, EXCLUSIVE_INVITE_REDIRECT_AFTER_CODE, FAQ_TYPE_QUERY_PARAM, FILTER_QUERY_PARAM, INVITATION_ID_QUERY_PARAM, READER_DOC_ID_QUERY_PARAM, READER_START_PAGE_QUERY_PARAM, REGISTRATION_FROM_APP_FLAG_QUERY_PARAM, SIGNIN_RETURN_URL } from '../query-params';
import { PassFilterData, WenNavigationHelper } from '../types';
import { ReaderParams } from '../types/reader-params';
import { WenRouteUrlBuilder } from './wen-route-url-builder';
import { credentialOfferIdentifier, returnUrlIdentifier } from '../../../../views/wallet/tokens';

@Injectable()
export class SingleOutletNavigator extends WenNavigationHelper {

  constructor(
    readonly router: Router,
    readonly wenNativeApi: WenNativeApi,
    readonly wenColorService: WenColorService,
    readonly location: PlatformLocation,
    readonly routesProvider: WenRoutesProvider,
    readonly routeUrlBuilder: WenRouteUrlBuilder,
    readonly appNavigator: AppNavigator,
  ) {
    super(router, location, wenNativeApi, wenColorService, routeUrlBuilder, appNavigator);
  }

  navigateToWelcomeScreen(extras?: NavigationExtras) {
    this.router.navigateByUrl(this.routesProvider.getSingleOutletStartPage(), extras);
  }

  navigateToChatList(extras?: NavigationExtras) {
    this.router.navigateByUrl(this.getNavigateToChatListLink(), extras);
  }

  navigateToDialogChatCreation(): void {
    this.navigateWithHistoryStack(['/chat/create']);
  }

  navigateToDialogChatCreationFromShare(): void {
    const queryParams: Params = { [CHAT_CREATE_FROM_SHARE_QUERY_PARAM]: true };
    this.navigateWithHistoryStack(['/chat/create'], { queryParams, state: { fromDeepLink: true } });
  }

  navigateToDialogChatCreationFromForward(): void {
    const queryParams: Params = { [CHAT_CREATE_FROM_FORWARD_QUERY_PARAM]: true };
    this.navigateWithHistoryStack(['/chat/create'], { queryParams });
  }

  navigateToGroupChatCreation(): void {
    this.navigateWithHistoryStack(['/chat/add-chat-users']);
  }

  navigateToAutoReplyEdit(): void {
    this.navigateWithHistoryStack(['/chat/auto-reply-edit']);
  }

  navigateToAddGroupChatInfo(): void {
    this.router.navigateByUrl('/chat/add-chat-info');
  }

  navigateToChatView(chatId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/chat/${chatId}`, extras);
  }

  navigateToChatEdit(chatId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/chat/${chatId}/edit`, extras);
  }

  navigateToGroupChatDetail(chatId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/chat/${chatId}/detail`, extras);
  }

  navigateToGroupChatAddMembers(chatId: string): void {
    this.router.navigateByUrl(`/chat/${chatId}/add-members`);
  }

  navigateToApp(app: AppDTO) {
    const { id, nativeUri, uri, androidPlayStoreId, iosAppStoreId, iosAppName, embedded } = app;
    if (embedded) {
      this.router.navigateByUrl(`/apps/${id}`);
      return;
    }
    const nativeHandle = this.tryOpenUrlInNative(uri, { androidPlayStoreId, iosAppStoreId, iosAppName, nativeUri });
    if (!nativeHandle) {
      if (uri) {
        this.router.navigateByUrl(`/apps/${id}`);
      } else {
        this.navigateToAppDetail(id);
      }
    }
  }

  navigateToAppDetail(appId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/apps/${appId}/detail`, extras);
  }

  navigateToAppDiscoverWithFilter(filterData?: PassFilterData) {
    const queryParams: Params = { [FILTER_QUERY_PARAM]: filterData };
    // TODO: Fix hacking of navigation extras: query params shouldn't be handles as part of extras
    this.router.navigateByUrl(`/apps/discovery`, { queryParams } as any);
  }

  navigateToEmbeddedPage(url: string) {
    if (this.wenNativeApi.isReactNativeApp()) {
      this.openNativeUrl(url);
    } else {
      this.router.navigateByUrl(`/embedded-page?url=${encodeURI(url)}`);
    }
  }

  navigateToChannelList(extras?: NavigationExtras) {
    this.router.navigate([this.getNavigateToChannelListLink()], extras);
  }

  navigateToChannelView(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}`, extras);
  }

  navigateToChannelPostEditor(channelId: string, messageId?: string, extras?: NavigationExtras) {
    const withMessageId = messageId ? `/${messageId}` : '';
    this.router.navigateByUrl(`/channel/${channelId}/post-editor${withMessageId}`, extras);
  }

  navigateToChannelPostLocationSelector(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/post-location-selector`, extras);
  }

  navigateToChannelFeaturedView() {
    this.router.navigateByUrl(`/channel/featured`);
  }

  navigateToChannelDiscover() {
    this.router.navigateByUrl('/channel/discovery');
  }

  navigateToChannelDiscovery(filterData?: PassFilterData, extras?: NavigationExtras) {
    const queryParams: Params = { [FILTER_QUERY_PARAM]: filterData };
    this.router.navigateByUrl('/channel/discovery', { queryParams, ...extras });
  }

  navigateToChannelExplore() {
    this.router.navigateByUrl('/channel/explore');
  }

  navigateToChannelDetail(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/detail`, extras);
  }

  navigateToChannelSettings(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/settings`, extras);
  }

  navigateToChannelEdit(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/edit`, extras);
  }

  navigateToChannelScheduledMessages(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/schedules`, extras);
  }

  navigateToChannelVisibilitySettings(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/visibility-settings`, extras);
  }

  navigateToChannelRestrictionSettings(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/restriction-settings`, extras);
  }

  navigateToChannelInteractionSettings(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/interaction-settings`, extras);
  }

  navigateToChannelAddWelcome() {
    this.navigateWithHistoryStack(['/channel/add-welcome']);
  }

  navigateToAddChannelInfo() {
    this.router.navigate(['/channel/add-channel-info']);
  }

  navigateToAddChannelVisibility() {
    this.router.navigate(['/channel/add-channel-visibility']);
  }

  navigateToAddChannelRestrictions() {
    this.router.navigate(['/channel/add-channel-restrictions']);
  }

  navigateToAddChannelInteractions() {
    this.router.navigate(['/channel/add-channel-interactions']);
  }

  navigateToChannelMessageDetail(messageId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/message/${messageId}`, extras);
  }

  navigateToPendingJoinRequestsPage(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/pending-join-requests`, extras);
  }

  navigateToAdminListPage(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/admin-list`, extras);
  }

  navigateToAddAdminPage(channelId: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(`/channel/${channelId}/add-admin`, extras);
  }

  navigateToContentProvidersPage(channelId: string, extras?: NavigationExtras): void {
    this.router.navigateByUrl(`channel/${channelId}/content-providers`, extras);
  }

  navigateToAddContentProviderPage(channelId: string, extras?: NavigationExtras): void {
    this.router.navigateByUrl(`channel/${channelId}/content-providers/add`, extras);
  }

  navigateToSubscriberListPage(channelId: string, extras?: NavigationExtras): void {
    this.router.navigateByUrl(`channel/${channelId}/subscribers`, extras);
  }

  navigateToUserProfile() {
    this.appNavigator.enterStackAndNavigate(WenRouteId.USERPROFILE_VIEW);
  }

  navigateToUserProfileRead(showUserProfileDashboard: boolean) {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_READ);
  }

  navigateToUserProfileEdit() {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_EDIT);
  }

  navigateToUserProfileLanguage() {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_LANGUAGE);
  }

  navigateToUserProfileSettings() {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_SETTINGS);
  }

  navigateToUserCredentialsMenu() {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_CREDENTIALS_MENU);
  }

  navigateToCredentialDetails(credentialType: WalletCredentialType) {
    if (!credentialType) {
      throw new Error('Could not navigate to credentialDetails. credentialType is missing.');
    }
    const queryParams: Params = { [CREDENTIAL_TYPE]: credentialType };
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_CREDENTIAL_DETAILS, null, { queryParams });
  }

  navigateToHelp() {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_HELP);
  }

  navigateToSettings() {
    this.appNavigator.navigateToRoute(WenRouteId.USER_SETTINGS);
  }

  navigateToFAQ() {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_FAQ);
  }

  navigateToMarkdown() {
    const queryParams: Params = { [FAQ_TYPE_QUERY_PARAM]: 'markdown' };
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_MARKDOWN, null, { queryParams });
  }

  navigateToHotline(): void {
    this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_HOTLINE);
  }

  navigateToInviteToNetwork(extras?: NavigationExtras): void {
    this.router.navigateByUrl('/invite/network', extras);
  }

  navigateToCrmHomeBackground(extras?: NavigationExtras): void {
    this.router.navigateByUrl('/crm/home', extras);
  }

  navigateToCrmInviteToNetwork(extras?: NavigationExtras): void {
    this.navigateToInviteToNetwork(extras);
  }

  navigateToCrmInviteToChannelSelector(extras?: NavigationExtras): void {
    this.router.navigateByUrl('/crm/invite/channel/selector', extras);
  }

  navigateToCrmInviteToChannel(extras?: NavigationExtras): void {
    this.router.navigateByUrl('/crm/invite/channel/invite', extras);
  }

  navigateToCrmCreateChat(extras?: NavigationExtras): void {
    this.router.navigateByUrl('/crm/chat/create', extras);
  }

  navigateToCrmActionSelector(extras?: NavigationExtras): void {
    this.router.navigateByUrl('/crm/action-selector', extras);
  }

  navigateToCrmDistributeChat(extras?: NavigationExtras): void {
    this.router.navigateByUrl('/crm/distribute-chat', extras);
  }

  navigateToCrmProfileReadOnlyView(extras?: NavigationExtras): void {
    this.router.navigateByUrl('/crm/logout', extras);
  }

  navigateToErrorPage(extras?: NavigationExtras) {
    this.router.navigate(['/error'], extras);
  }

  navigateToEmailSettings(extras?: NavigationExtras): void {
    this.router.navigateByUrl('/profile/email-settings', extras);
  }

  navigateToEmailEditPage(extras?: NavigationExtras): void {
    this.router.navigateByUrl('/profile/change-email', extras);
  }

  navigateToRegistrationStart(isFromApp?: boolean, inviteId?: string) {
    let queryParams = {};
    if (!isNullOrUndefined(isFromApp)) {
      queryParams = { ...queryParams, [REGISTRATION_FROM_APP_FLAG_QUERY_PARAM]: isFromApp };
    }
    if (!isNullOrUndefined(inviteId)) {
      queryParams = { ...queryParams, [INVITATION_ID_QUERY_PARAM]: inviteId };
    }
    this.appNavigator.navigateToRoute(WenRouteId.REGISTRATION_START, null, { queryParams });
  }

  navigateToSignIn(returnUrl?: string): void {
    const queryParams: Params = { [SIGNIN_RETURN_URL]: returnUrl };
    this.appNavigator.navigateToRoute(WenRouteId.SIGN_IN_START, null, { queryParams });
  }

  navigateToPopupSignIn(returnUrl?: string): void {
    const queryParams: Params = { [SIGNIN_RETURN_URL]: returnUrl };
    this.appNavigator.navigateToRoute(WenRouteId.SIGN_IN_POPUP, null, { queryParams });
  }

  navigateToInviteSignIn(inviteId: string): void {
    let queryParams = {};
    if (!isNullOrUndefined(inviteId)) {
      queryParams = { [INVITATION_ID_QUERY_PARAM]: inviteId };
    }
    this.appNavigator.navigateToRoute(WenRouteId.SIGN_IN_INVITE, null, { queryParams });
  }

  navigateToExclusiveInvite(redirectAfterCode?: string, extras?: NavigationExtras): void {
    const queryParams: Params = { [EXCLUSIVE_INVITE_REDIRECT_AFTER_CODE]: redirectAfterCode };
    this.router.navigate(['exclusive-invite/code'], { queryParams, ...extras });
  }

  navigateToOauthLogin(extras?: NavigationExtras) {
    this.router.navigate(['oauth/login'], extras);
  }

  navigateToWalletCreateCredential(type: WalletCredentialType, extras?: NavigationExtras) {
    const extrasWithoutLocationChange = { ...extras };
    this.router.navigate([`wallet/credential/create/${type}`], extrasWithoutLocationChange);
  }

  getWalletInitiateIssuanceRoute(returnUrl: string): string {
    return `/wallet/initiate-issuance?${returnUrlIdentifier}=${returnUrl}`;
  }

  navigateToInitiateIssuance(returnUrl: string, credentialOffer: string, extras?: NavigationExtras) {
    const extrasWithQueryParams = {
      ...extras,
      queryParams: {
        [returnUrlIdentifier]: returnUrl,
        [credentialOfferIdentifier]: credentialOffer
      }
    };
    this.router.navigate([ 'wallet/initiate-issuance' ], extrasWithQueryParams);
  }

  navigateToEventDiscoveryWithFilter(filterData: PassFilterData) {
    const queryParams: Params = { [FILTER_QUERY_PARAM]: filterData };
    this.router.navigateByUrl('/event/discovery', { queryParams } as any);
  }

  navigateToExternalUrl(url: string) {
    if (this.wenNativeApi.isReactNativeApp()) {
      this.openNativeUrl(url);
    } else {
      window.open(url, '_blank');
    }
  }

  navigateToReaderView(readerParams: ReaderParams, extras?: NavigationExtras) {
    if (!readerParams?.docId) {
      throw new Error('Could not navigate to reader view. docId is missing.');
    }

    const queryParams: Params = {
      [READER_DOC_ID_QUERY_PARAM]: readerParams.docId,
      [READER_START_PAGE_QUERY_PARAM]: readerParams.startPage
    };
    this.router.navigate(['reader'], { queryParams, ...extras });
  }

  navigateToTextView(readerParams: ReaderParams, extras?: NavigationExtras) {
    if (!readerParams?.articleId) {
      throw new Error('Could not navigate to text view. articleId is missing.');
    }

    this.router.navigate(['reader', 'textview', readerParams.articleId], { ...extras });
  }

  getNavigateToHomeLink() {
    return '/home';
  }

  getNavigateToEventListLink() {
    return '/event';
  }

  getNavigateToChatListLink() {
    return '/chat';
  }

  getNavigateToAppListLink() {
    return `/apps`;
  }

  getNavigateToChannelListLink() {
    return `/channel/list`;
  }

  doUserChannelListBackNavigation() {
    this.navigateToChannelList({ replaceUrl: true });
  }

  doUserAppListBackNavigation() {
    this.appNavigator.navigateToRouteCompressed(WenRouteId.APP_LIST);
  }

  doUserChatListBackNavigation() {
    this.navigateToChatList({ replaceUrl: true });
  }

}
