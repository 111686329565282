import { NgModule } from '@angular/core';
import { ContentBoundaryDirective } from './content-boundary.directive';

@NgModule({
  declarations: [
    ContentBoundaryDirective,
  ],
  exports: [
    ContentBoundaryDirective
  ]
})
export class ContentBoundaryModule { }
