import { ActivatedRouteSnapshot } from '@angular/router';
import { WenRouterOutlet } from '@portal/wen-components';
import { WenRouteId, WenRouteWithId } from '../../../../frame/routing/types';

export interface OutletIds {
  primaryId: WenRouteId;
  sidebarId?: WenRouteId;
  dialogId?: WenRouteId;
}

export interface OutletDatas {
  primaryData: WenRouteWithId;
  sidebarData?: WenRouteWithId;
  dialogData?: WenRouteWithId;
}

const findOutlet = (
  route: ActivatedRouteSnapshot
) => {
  if (!route) {
    return null;
  }
  let target = route;
  while (target.firstChild) {
    target = target.firstChild;
  }
  return target;
};

/**
 * Angular router doesn't merge route data for nested routes only for the primary outlet
 * In order to have the nested route data for auxiliary routes as well merge it manually
 */
const mergeOutletData = (
  route: ActivatedRouteSnapshot
) => {
  if (!route) {
    return null;
  }
  let target = route;
  let data: WenRouteWithId = {} as WenRouteWithId;
  while (target.firstChild) {
    data = { ...data, ...target.data };
    target = target.firstChild;
  }
  data = { ...data, ...target.data };
  return data;
};

const findOutletRoot = (
  route: ActivatedRouteSnapshot,
  outlet: WenRouterOutlet
): ActivatedRouteSnapshot => {
  if (!route) {
    return null;
  }
  if (route.outlet === outlet) {
    return route;
  }
  const children = route.children || [];
  const target = children
    .map(child => findOutletRoot(child, outlet))
    .find(found => found);
  return target;
};

export const extractOutlets = (snapshot: ActivatedRouteSnapshot) => {
  const primaryOutlet = findOutletRoot(snapshot, WenRouterOutlet.PRIMARY);
  const sidebarOutlet = findOutletRoot(snapshot, WenRouterOutlet.SIDEBAR);
  const dialogOutlet = findOutletRoot(snapshot, WenRouterOutlet.DIALOG);
  const primary = findOutlet(primaryOutlet);
  const sidebar = findOutlet(sidebarOutlet);
  const dialog = findOutlet(dialogOutlet);
  return {
    primary, sidebar, dialog
  };
};

export const extractOutletIds = (snapshot: ActivatedRouteSnapshot): OutletIds => {
  const outlets = extractOutlets(snapshot);
  return {
    primaryId: outlets?.primary?.data?.routeId,
    sidebarId: outlets?.sidebar?.data?.routeId,
    dialogId: outlets?.dialog?.data?.routeId
  };
};

export const extractOutletDatas = (snapshot: ActivatedRouteSnapshot): OutletDatas => {
  const primaryOutlet = findOutletRoot(snapshot, WenRouterOutlet.PRIMARY);
  const sidebarOutlet = findOutletRoot(snapshot, WenRouterOutlet.SIDEBAR);
  const dialogOutlet = findOutletRoot(snapshot, WenRouterOutlet.DIALOG);
  const primaryData = mergeOutletData(primaryOutlet);
  const sidebarData = mergeOutletData(sidebarOutlet);
  const dialogData = mergeOutletData(dialogOutlet);
  return {
    primaryData,
    sidebarData,
    dialogData
  };
};
