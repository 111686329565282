import { DefaultProjectorFn, MemoizedSelector, select } from '@ngrx/store';
import { map, Observable } from 'rxjs';

export const selectorWithParam = <P extends any[], R>(
  selector: MemoizedSelector<any, (...selectorArgs: P) => R, DefaultProjectorFn<(...selectorArgs: P) => R>>,
  ...params: P
) => <T>(source: Observable<T>) => {
  return source.pipe(
    select(selector),
    map(projector => projector(...params))
  );
};
