import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { filter, first, map, Observable, startWith, withLatestFrom } from 'rxjs';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { TrimFieldValueAdapter } from '../../../../../core/common/util/field-adapters/trim-field-value-adapter';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { selectCurrentUserData } from '../../../../../core/store/auth/auth.selectors';
import { removeRoom } from '../../../../../core/store/chat/chat.actions';
import { ChatRoomEntity } from '../../../../../core/store/chat/chat.state';
import { isCurrentUserAnOwner } from '../../../../../core/store/chat/utils/chat-room-utils';
import { RootState } from '../../../../../core/store/root/public-api';
import { FormValueConverter } from '../../../../../shared/form-store/form-store.providers';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { ChatFormDataProvider, CHAT_EDIT_FORM_DATA_PROVIDER } from './providers/chat-form-data-provider';
import { ChatInfoEditFormValueConverter } from './providers/chat-info-edit-form-value-converter';

@Component({
  selector: 'wen-chat-info-edit',
  templateUrl: './chat-info-edit.component.html',
  styleUrls: ['./chat-info-edit.component.scss'],
  providers: [
    CHAT_EDIT_FORM_DATA_PROVIDER,
    { provide: FormValueConverter, useClass: ChatInfoEditFormValueConverter }
  ]
})
export class ChatInfoEditComponent implements OnInit {

  fieldValueAdapter = new TrimFieldValueAdapter();

  currentChat$: Observable<ChatRoomEntity>;
  canDeleteChat$: Observable<boolean>;

  chatInfoFormGroup = new FormGroup({
    icon: new FormControl(''),
    title: new FormControl('', {
      validators: [
        Validators.required
      ],
      updateOn: 'blur'
    }),
    description: new FormControl(''),
  });

  private currentUser$ = this.store.pipe(select(selectCurrentUserData));

  get imageFallbackText() {
    return this.chatInfoFormGroup.controls.title.value;
  }

  constructor(
    private formStoreService: FormStoreService,
    private formDataProvider: ChatFormDataProvider,
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper
  ) { }

  ngOnInit() {
    this.currentChat$ = this.formDataProvider.chat$;
    this.currentChat$.pipe(
      firstExisty()
    ).subscribe((roomEntity) => {
      this.formStoreService.initializeForm(roomEntity);
    });
    this.canDeleteChat$ = this.currentChat$.pipe(
      firstExisty(),
      withLatestFrom(this.currentUser$),
      filter(([{ members }]) => members?.length > 0),
      map(([{ members }, currentUser]) => {
        return isCurrentUserAnOwner(members, currentUser);
      }),
      startWith(false)
    );
  }

  deleteChat(chatId: string) {
    if (!chatId) {
      return;
    }
    const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
      header: this.translateService.instant('GROUP_CHAT_DELETE_CONFIRM_DIALOG_TITLE'),
      content: this.translateService.instant('GROUP_CHAT_DELETE_CONFIRM_DIALOG_TEXT'),
      okLabel: this.translateService.instant('GROUP_CHAT_DELETE_BUTTON_LABEL'),
      dismissLabel: this.translateService.instant('CANCEL_BUTTON_LABEL'),
    });
    dialog.afterClosed().pipe(
      first(),
      filter(data => data?.result === 'ok')
    ).subscribe(() => {
      this.store.dispatch(removeRoom({ roomId: chatId }));
      this.formStoreService.clearFormData();
      this.navigationHelper.doUserChatListBackNavigation();
    });
  }

}
