import { EntityState } from '@ngrx/entity';
import { GetReactionsResponse, ReactionUpdateResponse } from '@portal/wen-backend-api';
import { reactionAdapter, ReactionEntity, ReactionsState } from '../reaction.state';

export const reactionHelper = {
  setReactionSummaries: (
    response: GetReactionsResponse,
    reactionsState: ReactionsState
  ): EntityState<ReactionEntity> => {
    const reactions: EntityState<ReactionEntity> = reactionsState.reactions;
    const mappedReactionEntities: ReactionEntity[] = Object.entries(response).map(([referenceId, reactionSummary]) => {
      return {
        referenceId,
        reactionSummary
      };
    });

    return reactionAdapter.upsertMany(mappedReactionEntities, reactions);
  },

  updateReactionSummaryForMessage: (
    reactionUpdate: ReactionUpdateResponse,
    reactionsState: ReactionsState
  ) => {
    const reactions: EntityState<ReactionEntity> = reactionsState.reactions;
    const updatedReactions: ReactionEntity[] = Object.entries(reactionUpdate).map(([referenceId, summary]) => {
      const matchingReaction = reactionsState.reactions.entities[referenceId];
      return {
        referenceId,
        reactionSummary: {
          ...matchingReaction?.reactionSummary,
          ...summary
        }
      };
    });
    return reactionAdapter.upsertMany(updatedReactions, reactions);
  },

  deleteReactions: (referenceIds: string[], reactions: EntityState<ReactionEntity>) => {
    return reactionAdapter.removeMany(referenceIds, reactions);
  }

};
