import { LocationStrategy } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { WenHistoryState, WenPathLocationStrategy } from '@portal/wen-components';
import { WenNativeApi } from '@portal/wen-native-api';
import { first, tap } from 'rxjs/operators';

@Injectable()
export class NavigationEffects {

  setupFallbackDeepLinkHistory$ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigationAction),
    first(),
    tap(() => {
      if (this.nativeApi.isReactNativeApp()) {
        return;
      }
      const path = this.location.path();
      if (path !== '/') {
        let newState: WenHistoryState = { fromDeepLink: true };
        const previousHistoryStack = this.location.getState()?.historyStack;
        if (previousHistoryStack?.length) {
          newState = { ...newState, historyStack: previousHistoryStack };
        }
        this.router.navigateByUrl(path, { state: newState, replaceUrl: true });
      }
    })
  ), { dispatch: false });

  constructor(
    @Inject(LocationStrategy) private readonly location: WenPathLocationStrategy,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly nativeApi: WenNativeApi,
  ) {
  }

}
