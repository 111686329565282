import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MassDataActionsFactory, MassDataOperation } from '../mass-data.actions';
import { MassDataType } from '../types';

export interface MassDataUpdatesPlugin<ENTITY> {
  updates$?: Observable<ENTITY>;
  removes$?: Observable<ENTITY>;
  upserts$?: Observable<ENTITY>;
}

@Injectable()
/* eslint-disable-next-line unused-imports/no-unused-vars */
export abstract class MassDataUpdatesPlugin<ENTITY> {

  private massDataActions = inject(MassDataActionsFactory);
  private store = inject(Store);

  // TODO: handle disconnect
  connect(massDataType: MassDataType) {
    if (this.updates$) {
      this.updates$.subscribe((updates) => {
        const action = this.massDataActions.createAction({
          massDataType,
          massDataOperation: MassDataOperation.STORE_UPDATE_MANY,
          massDataActionData: updates
        });
        this.store.dispatch(action);
      });
    }
    if (this.removes$) {
      this.removes$.subscribe((removes) => {
        const action = this.massDataActions.createAction({
          massDataType,
          massDataOperation: MassDataOperation.STORE_REMOVE_MANY,
          massDataActionData: removes
        });
        this.store.dispatch(action);
      });
    }
    if (this.upserts$) {
      this.upserts$.subscribe((removes) => {
        const action = this.massDataActions.createAction({
          massDataType,
          massDataOperation: MassDataOperation.STORE_UPSERT_MANY,
          massDataActionData: removes
        });
        this.store.dispatch(action);
      });
    }
  }

}
