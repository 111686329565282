import { Injectable } from '@angular/core';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MassDataStateAdapter } from '@portal/wen-data-core';
import { ChatMemberEditSearchEntity } from './chat-member-edit-state-types';

@Injectable()
export class ChatMemberEditSearchMassDataAdapter extends MassDataStateAdapter<ChatMemberEditSearchEntity> {

  private chatSearchEntityAdapter: EntityAdapter<ChatMemberEditSearchEntity>;

  getEntityAdapter() {
    if (!this.chatSearchEntityAdapter) {
      this.chatSearchEntityAdapter = createEntityAdapter<ChatMemberEditSearchEntity>({
        selectId: (model) => model.listItemEntity.id
      });
    }
    return this.chatSearchEntityAdapter;
  }

}
