import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectUserAppIds } from '../../../../core/store/apps/apps.selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { SubscribedToSource } from '../../../../shared/directives/subscibed-to/subscribed-to-source';

@Injectable()
export class AppSubscribedToSource extends SubscribedToSource {

  subscribedIds$: Observable<string[]> = this.store.pipe(
    select(selectUserAppIds)
  );

  constructor(
    private store: Store<RootState>
  ) {
    super();
  }
}
