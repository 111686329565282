import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommentListModule } from '../../../shared/components/comments/comment-list.module';
import { CommentMessageSendModule } from '../../../shared/components/comments/comment-message-send-field/comment-message-send-field.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelMessagesViewModule } from '../common/components/channel-messages-view/channel-messages-view.module';
import { ChannelMessageDetailComponent } from './channel-message-detail.component';
import { ChannelMessageErrorModule } from './components/message-error/message-error.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    ChannelMessagesViewModule,
    CommentListModule,
    ChannelMessageErrorModule,
    CommentMessageSendModule,
  ],
  declarations: [
    ChannelMessageDetailComponent,
  ],
  exports: [
    ChannelMessageDetailComponent
  ]
})
export class ChannelMessageDetailModule { }
