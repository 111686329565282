import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '@portal/wen-components';
import { VirtualKeyboardModule } from '../../directives/virtual-keyboard/virtual-keyboard.module';
import { InputWithChipsComponent } from './input-with-chips.component';


@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatChipsModule,
    WenIconModule,
    TranslateModule.forChild(),
    VirtualKeyboardModule
  ],
  exports: [InputWithChipsComponent],
  declarations: [InputWithChipsComponent],
})
export class InputWithChipsModule { }
