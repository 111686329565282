import { Injectable } from '@angular/core';
import { MessageSeparatorData, SeparatorFactory } from '../../../directives/directives/separator-factory';
import { BaseMessageModel } from '../../message-box/models/base-message-box.model';
import { BaseWeFeedItem, WeFeedItemExtraProps, WeFeedItemInternal, WeFeedItemType, WeFeedSeparatorItem } from '../components/we-feed/we-feed-model';

@Injectable()
export class FeedLayoutItemFactory {

  constructor(
    public separatorFactory: SeparatorFactory,
  ) { }

  private hasSameAuthor(itemA: BaseWeFeedItem<BaseMessageModel>, itemB: BaseWeFeedItem<BaseMessageModel>) {
    if (!itemA || !itemB) {
      return false;
    }
    const valueA = itemA.value;
    const valueB = itemB.value;
    if (!valueA || !valueB || !('authorId' in valueA) || !('authorId' in valueB) || !valueA.authorId || !valueB.authorId) {
      return false;
    }
    return valueA.authorId === valueB.authorId;
  }

  createFeedItems<T extends BaseWeFeedItem<BaseMessageModel>>(
    messageItems: T[],
    newMessageLineItemId: string,
    ignoreFirstSeparator: boolean
  ) {
    let newMessageLineKey: string = null;
    const feedItems = messageItems.reduce((acc, current, i) => {
      const { messageId, timestamp } = current.value;
      const isNewMessageLine = newMessageLineItemId === messageId;
      const separatorData: MessageSeparatorData = {
        current: timestamp,
        previous: messageItems[i - 1]?.value?.timestamp,
        newMessageLine: isNewMessageLine,
      };
      const separatorModel = i === 0 && ignoreFirstSeparator ? null : this.separatorFactory.getSeparatorData(separatorData);
      if (separatorModel) {
        const key = `SEPARATOR_${messageId}`;
        if (isNewMessageLine) {
          newMessageLineKey = key;
        }
        const separatorItem: WeFeedSeparatorItem = {
          key,
          type: WeFeedItemType.SEPARATOR,
          value: {
            id: key,
            ...separatorModel
          }
        };
        const newAcc: WeFeedItemInternal[] = [...acc, separatorItem, current];
        return newAcc;
      }
      return [...acc, current];
    }, [] as WeFeedItemInternal[]).map((current, i, array) => {
      const extraProps: WeFeedItemExtraProps = {
        hasSameAuthorAsPrevious: this.hasSameAuthor(current, array[i-1]),
        hasSameAuthorAsNext: this.hasSameAuthor(current, array[i+1]),
      };

      const newItem = {...current, extraProps };
      return newItem;
    });
    return {
      feedItems, newMessageLineKey
    };
  }
}
