import { VideoThumbnailGenerator } from 'browser-video-thumbnail-generator';
import { Directive, ElementRef, forwardRef, Input, Renderer2, OnInit, OnDestroy } from '@angular/core';
import { MediaType, WeLocalImageUrlTransformator } from '@portal/wen-backend-api';
import { Dimensions2D, smartDistinctUntilChanged } from '@portal/wen-components';
import { fromEvent, map, Observable, ReplaySubject, Subject, switchMap, takeUntil, from, of, catchError, merge } from 'rxjs';
import { WenNativeApi } from '@portal/wen-native-api';
import { ContentBoundaryProvider } from '../../../../../../directives/content-boundary/content-boundary.directive';
import { FitType, ScaleHelper, ScaleResult } from '../../../../../../directives/util/scale-helper';
import { MediaEmbedThumbnail, MediaSize, MediaViewParams } from '../models/models';
import { isNullOrUndefined } from '../../../../../../../core/common/operators/null-check-util';

const MAX_MEDIA_HEIGHT = 456;
const MIN_MEDIA_HEIGHT = 90;

const AUDIO_PLAYER_WIDTH = 332;
const AUDIO_PLAYER_HEIGHT = 54;
const DEAFULT_MEDIA_WIDTH = 16;
const DEFAULT_MEDIA_HEIGHT = 9;
const DOCUMENT_WIDTH = 332;
const DOCUMENT_HEIGHT = 70;

export abstract class ThumbnailProvider {
  thumbnail$: Observable<MediaEmbedThumbnail>;
}
@Directive({
  selector: '[thumbnail-evaluator]',
  providers: [{
    provide: ThumbnailProvider,
    useExisting: forwardRef(() => ThumbnailEvaluatorDirective)
  }]
})
export class ThumbnailEvaluatorDirective extends ThumbnailProvider implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private viewParams$ = new ReplaySubject<MediaViewParams>(1);

  readonly thumbnail$ = this.viewParams$.pipe(
    switchMap((params) => {
      return this.readDimensions(params).pipe(
        switchMap(({ url, width, height, imageElement, forbidScaling }) => {
          return this.contentBoundaryProvider.contentBoundary$.pipe(
            map(({ width: boundaryWidth }) => {
              let scaledDimensions = { width: boundaryWidth, height, fitType: FitType.FIT } as ScaleResult;
              let usedDimensions = { width: boundaryWidth, height };
              let horizontalOffset = 0;

              if (!forbidScaling) {
                scaledDimensions = ScaleHelper.computeScale(width, height, boundaryWidth, MAX_MEDIA_HEIGHT, MIN_MEDIA_HEIGHT);
                const recalculationResult = this.recalculateImageDimensions(width, height, scaledDimensions);
                usedDimensions = recalculationResult.usedDimensions;
                horizontalOffset = recalculationResult.horizontalOffset;
              }

              const properUrl = this.getProperThumbnailUrl(params, scaledDimensions, url);

              return {
                url: properUrl,
                scaledDimensions,
                usedDimensions,
                horizontalOffset,
                imageElement
              };
            })
          );
        })
      );
    })
  );

  @Input('thumbnail-evaluator') set viewParams(value: MediaViewParams) {
    this.viewParams$.next(value);
  }

  constructor(
    private element: ElementRef<HTMLElement>,
    private welocalImageUrlTransformator: WeLocalImageUrlTransformator,
    private contentBoundaryProvider: ContentBoundaryProvider,
    private renderer: Renderer2,
    private wenNativeApi: WenNativeApi
  ) {
    super();
  }

  ngOnInit() {
    this.thumbnail$.pipe(
      smartDistinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(({ scaledDimensions: { width, height } }) => {
        this.renderer.setStyle(this.element.nativeElement, 'height', `${height}px`);
        this.renderer.setStyle(this.element.nativeElement, 'width', `${width}px`);
    });
  }

  private getProperThumbnailUrl(params: MediaViewParams, scaledDimensions: Dimensions2D & { fitType: FitType }, url: string) {
    if (this.welocalImageUrlTransformator.hasPlaceholder(params.thumbnailUrl)) {
      if (scaledDimensions.fitType === FitType.TOO_WIDE) {
        url = this.welocalImageUrlTransformator.transform(params.thumbnailUrl, { height: scaledDimensions.height });
      } else {
        url = this.welocalImageUrlTransformator.transform(params.thumbnailUrl, { width: scaledDimensions.width });
      }
    }
    return url;
  }

  private readDimensions(params: MediaViewParams): Observable<MediaSize> {
    const fallback = this.createFallbackSize(params);
    if (params.mediaType === MediaType.AUDIO) {
      return this.audioDimensions();
    }
    if (params.mediaType === MediaType.DOCUMENT) {
      return this.documentDimensions();
    }
    if (params.mediaType === MediaType.VIDEO && !params.thumbnailUrl) {
      return this.readVideoThumbnail(params.sourceUrl, fallback);
    }
    if (params?.dimensions?.width && params?.dimensions?.width) {
      return of({
        width: params.dimensions.width,
        height: params.dimensions.height,
        url: params?.thumbnailUrl || null
      });
    }
    return this.readImage(params.thumbnailUrl, fallback);

  }

  private readImage(url: string, fallback: Observable<MediaSize>): Observable<MediaSize> {
    const img = new Image();
    img.src = url;
    return merge(
      fromEvent(img, 'load').pipe(
        map(event => {
          const currentTarget = event.currentTarget as HTMLImageElement;
          return {
            url,
            width: currentTarget.naturalWidth,
            height: currentTarget.naturalHeight,
            imageElement: currentTarget
          };
        })
      ),
      fromEvent(img, 'error') .pipe(
        switchMap(() => fallback)
      )
    );
  }

  private readVideoThumbnail(url: string, fallback: Observable<MediaSize>): Observable<MediaSize> {
    if (isNullOrUndefined(url)) {
      return fallback;
    }
    url = (!url.startsWith('blob:') && this.wenNativeApi.isIOS()) ? url.concat('t=0.001') : url;
    const generator = new VideoThumbnailGenerator(url);
    return from(generator.getThumbnail('start')).pipe(
      map(({ thumbnail, width, height }) => {
        return {
          url: thumbnail,
          width,
          height
        };
      }),
      catchError(() => fallback)
    );
  }

  private audioDimensions(): Observable<MediaSize> {
    return of({
      width: AUDIO_PLAYER_WIDTH,
      height: AUDIO_PLAYER_HEIGHT,
      url: null,
      forbidScaling: true
    });
  }

  private documentDimensions(): Observable<MediaSize> {
    return of({
      width: DOCUMENT_WIDTH,
      height: DOCUMENT_HEIGHT,
      url: null,
      forbidScaling: true
    });
  }

  private createFallbackSize(params: MediaViewParams): Observable<MediaSize> {
    if (params?.dimensions?.height && params?.dimensions?.width) {
      return of({
        url: params.thumbnailUrl,
        width: params.dimensions.width,
        height: params.dimensions.height
      });
    }

    return of({
      width: DEAFULT_MEDIA_WIDTH,
      height: DEFAULT_MEDIA_HEIGHT,
      url: null
    });
  }

  private recalculateImageDimensions(width: number, height: number, scaledDimensions: ScaleResult) {
    let usedHeightFromImage = height;
    let usedWidthFromImage = width;
    let horizontalOffset = 0;

    if (scaledDimensions?.fitType === FitType.TOO_TALL) {
      const scaleToWidth = width / scaledDimensions.width;
      usedHeightFromImage = Math.round(scaledDimensions.height * scaleToWidth);
    } else if (scaledDimensions?.fitType === FitType.TOO_WIDE) {
      const scaleToHeight = height / scaledDimensions.height;
      usedWidthFromImage = Math.floor(scaledDimensions.width * scaleToHeight);
      const imageOffset = ((width - usedWidthFromImage) / 2);
      horizontalOffset = Math.floor(imageOffset);
    }
    return {
      usedDimensions: {
        width: usedWidthFromImage, height: usedHeightFromImage
      },
      horizontalOffset
    };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
