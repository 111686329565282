import { DataContext } from '../../../core/common/types/data-context';
import { WenRouteId, WenRoutes } from '../../../frame/routing/types';
import { AdaptiveBackgroundComponent } from '../../../shared/components/adaptive-background/adaptive-background.component';
import { signInInviteRoute, signInStartRoute } from './signin.routes';

export const multiOutletSignInRoutes: WenRoutes = [
  signInStartRoute,
  signInInviteRoute,
  {
    path: 'home',
    component: AdaptiveBackgroundComponent,
    data: {
      routeId: WenRouteId.SIGN_IN_HOME,
      dataContext: DataContext.PROFILE
    }
  },
  {
    path: '**',
    redirectTo: 'start'
  }
];
