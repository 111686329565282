import { Injectable } from '@angular/core';
import { OlmDeviceModel } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { InboundGroupSessionModel, OlmAccountModel, SessionModel } from './data-models';

export interface GetDeviceParams {
  userId: string;
}

export interface GetSessionParams {
  curve25519: string;
  sessionId: string;
}

export interface GetSessionsForDeviceParams {
  curve25519: string;
}

export interface GetInboundGroupSessionParams {
  senderCurve25519: string;
  sessionId: string;
}

@Injectable()
export abstract class CryptoStorage {

  abstract init(): Observable<boolean>;
  abstract clearDb(): Observable<void>;
  abstract getAccount(): Observable<OlmAccountModel>;
  abstract storeAccount(account: OlmAccountModel): Observable<string>;

  abstract getDevices(params: GetDeviceParams): Observable<OlmDeviceModel[]>;
  abstract storeDevice(update: OlmDeviceModel): Observable<string>;
  abstract storeDevices(update: OlmDeviceModel[]): Observable<string>;

  abstract getSession(params: GetSessionParams): Observable<SessionModel>;
  abstract getSessionsForDevice(params: GetSessionsForDeviceParams): Observable<SessionModel[]>;
  abstract storeSession(update: SessionModel): Observable<string>;

  abstract getInboundGroupSession(params: GetInboundGroupSessionParams): Observable<InboundGroupSessionModel>;
  abstract getInboundGroupSessions(params: GetInboundGroupSessionParams[]): Observable<InboundGroupSessionModel[]>;
  abstract getAllInboundGroupSessions(): Observable<InboundGroupSessionModel[]>;
  abstract storeInboundGroupSession(update: InboundGroupSessionModel): Observable<string>;

}
