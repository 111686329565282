import { Inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthApi, AuthConfig, AuthParams, AuthResult, UserInfo } from '@portal/wen-embed-api';
import { Observable, first, firstValueFrom, of, switchMap, throwError } from 'rxjs';
import { takeUntilAppChanged } from '../../../../core/common/operators/take-until-app-changed';
import { PermissionLevel } from '../../../../core/services/user-management/permission-level';
import { WenOAuthService } from '../../../../core/services/user-management/wen-oauth.service';
import { loginCurrentEmbeddedApp, loginCurrentEmbeddedAppResult } from '../../../../core/store/embedded-api/embedded-api.actions';
import { RootState } from '../../../../core/store/root/public-api';
import { AUTH_CLIENT_CONFIG, AuthClientConfig, NATIVE_CONFIG, NativeConfig } from '../../../api/tokens';

@Injectable()
export class AuthApiPart implements AuthApi {

  constructor(
    @Inject(NATIVE_CONFIG) private nativeConfig: NativeConfig,
    @Inject(AUTH_CLIENT_CONFIG) private authClientConfig: AuthClientConfig,
    private oAuthService: WenOAuthService,
    private store: Store<RootState>,
    private action$: Actions,
  ) { }

  isSsoDialogEnabled() {
    return this.nativeConfig.useNativeDialog;
  }

  getUserInfo(): UserInfo {
    const { userId, permissionLevel } = this.oAuthService.getUserData();
    return {
      userId,
      isAnonymous: permissionLevel === PermissionLevel.ANONYMOUS
    };
  }

  getAuthConfig(): AuthConfig {
    const { issuer } = this.authClientConfig;
    return { issuer };
  }

  tryAuth(params: AuthParams) {
    const { clientId } = params;
    this.store.dispatch(loginCurrentEmbeddedApp({ clientId }));
    const codeFlowResult$: Observable<AuthResult> = this.action$.pipe(
      ofType(loginCurrentEmbeddedAppResult),
      first(),
      switchMap(({ result }) => {
        if (result.error) {
          return throwError(() => result);
        }
        return of(result);
      }),
      takeUntilAppChanged(this.store)
    );
    return firstValueFrom(codeFlowResult$);
  }
}
