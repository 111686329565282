import { createFeature, createReducer, on } from '@ngrx/store';
import { smartDesignFeatureKey } from './constants';
import { setCurrentCrmRootRoute, updateSmartDesignState, upsertImportedSelectionData, upsertWenUsers } from './smartdesign.actions';
import { SmartDesignState, importedSelectionEntityAdapter } from './smartdesign.state';

export const smartDesignInitialState: SmartDesignState = {
  isFromSmartDesign: false,
  sdHostParam: null,
  importedSelection: importedSelectionEntityAdapter.getInitialState(),
  weNetworkUsers: null,
  currentRootRoute: null,
  backendType: null,
};

export const smartDesignFeature = createFeature({
  name: smartDesignFeatureKey,
  reducer: createReducer(
    smartDesignInitialState,
    on(updateSmartDesignState, (state, { newState }) => {
      return {
        ...state,
        ...newState
      };
    }),
    on(upsertImportedSelectionData, (state, { newEntities }) => {
      const newSelection = importedSelectionEntityAdapter.upsertMany(newEntities, state.importedSelection);
      return {
        ...state,
        importedSelection: newSelection
      };
    }),
    on(upsertWenUsers, (state, { users }) => {
      return {
        ...state,
        weNetworkUsers: users
      };
    }),
    on(setCurrentCrmRootRoute, (state, { routeId }) => {
      return {
        ...state,
        currentRootRoute: routeId
      };
    }),
  ),
});
