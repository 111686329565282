import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { HomeComponent } from './components/home/home.component';
import { GenericSectionComponent } from './components/generic-section/generic-section.component';

@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    SharedModule,
    GenericSectionComponent,
  ],
  providers: []
})
export class HomeModule { }
