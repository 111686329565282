import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WeatherComponent } from './weather.component';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { HourlyForecastComponent } from './components/hourly-forecast/hourly-forecast.component';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule,
    TranslateModule.forChild(),
  ],
  declarations: [WeatherComponent, HourlyForecastComponent],
  exports: [WeatherComponent],
})
export class WeatherModule { }
