import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { combineLatest, first, map, Observable, of, switchMap } from 'rxjs';
import { ContextMenuData, CONTEXT_MENU_DATA } from '../context-menu/context-menu-data';
import { ContextMenuRef, CONTEXT_MENU_REF } from '../context-menu/context-menu-ref';
import { ActionMenuItem } from './public-api';

interface ActionMenuData<D> {
  actions: Observable<ActionMenuItem[]>;
  data: Observable<D>;
}

@Component({
  selector: 'wen-action-menu',
  templateUrl: './action-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent<D> implements OnInit {

  private contextMenuData: ContextMenuData<ActionMenuData<D>> = inject(CONTEXT_MENU_DATA, { optional: true });
  private contextMenuRef: ContextMenuRef = inject(CONTEXT_MENU_REF, { optional: true });
  menuInfo$: Observable<{ actions: ActionMenuItem[]; data: D }>;
  @HostBinding('class.wen-action-menu-content') className = true;

  @ViewChildren('actionMenuItem', { read: ElementRef }) actionMenuItems: QueryList<ElementRef>;

  @Input() actions: ActionMenuItem[] = [];
  @Input() data: D;

  ngOnInit() {
    this.menuInfo$ = of(this.contextMenuData).pipe(
      switchMap((ctxMenuData) => {
        const { actions, data } = this.setMenuOrInputData(ctxMenuData);
        return combineLatest([actions, data]).pipe(
          map(([renderableActions, executableData]) => {
            return {
              actions: renderableActions,
              data: executableData
            };
          }),
          first()
        );
      })
    );
  }

  tryCloseMenu(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.contextMenuRef) {
      this.contextMenuRef.close();
    }
  }

  private setMenuOrInputData(ctxMenuData: ContextMenuData<ActionMenuData<D>>): ActionMenuData<D> {
    const menuData = ctxMenuData?.data;
    let actions = menuData?.actions ?? of(this.actions);
    let data = menuData?.data ?? of(this.data);
    if (!(data instanceof Observable)) {
      data = of(data);
    }
    if (!(actions instanceof Observable)) {
      actions = of(actions);
    }
    return {
      actions,
      data
    };
  }

}
