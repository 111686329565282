import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WenSnackbarOpener } from '@portal/wen-components';
import { toggleMuteChannel } from '../../../core/store/channel/channel.actions';
import { RootState } from '../../../core/store/root/public-api';

@Injectable()
export class ChannelMuteService {

  constructor(
    private readonly store: Store<RootState>,
    private readonly wenSnackbarOpener: WenSnackbarOpener,
    private readonly translateService: TranslateService
  ) { }

  public setMuteForChannel(channelId: string, mute: boolean, withNotification: boolean = false) {
    this.store.dispatch(toggleMuteChannel({ channelId, mute }));
    if (withNotification) {
      const notificationText = this.translateService.instant(mute ? 'CHANNEL_MUTE_BUTTON_LABEL' : 'CHANNEL_UNMUTE_BUTTON_LABEL');
      const notificationIcon = mute ? 'mute' : 'unmute';
      this.wenSnackbarOpener.openNotificationSnackbar({ notificationIcon, notificationText });
    }
  }

}
