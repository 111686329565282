import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { selectCurrentChannel, selectMessageDetailById } from '../../../../core/store/channel/channel.selectors';
import { combineLatest, first, map, Observable, of, switchMap } from 'rxjs';
import { SocketIoService, UserProfileResponse } from '@portal/wen-backend-api';
import { ChallengeData } from './challenge-user-data-info';
import { ActionMenuItemProvider } from '../../../../core/services/util/action-menu-item.provider';
import { TranslateService } from '@ngx-translate/core';
import { challengeContextMenuId } from '../actions/challenge-action-menu';
import { GreyPalette } from '../../../directives/colorize/palette-provider';
import { ChallengeAskAuthorAction } from '../actions/challenge-ask-author.action';
import { ActionMenuComponent, OverlayManager } from '@portal/wen-components';

@Injectable()
export class ChallengeContextMenuDatasource {

  constructor(
    private store: Store,
    private socketIoService: SocketIoService,
    private actionMenuItemProvider: ActionMenuItemProvider,
    private translateService: TranslateService,
    private overlayManager: OverlayManager
  ) { }

  openMenu(messageId: string) {
    this.getUserProfileByMessageId(messageId).pipe(
      first(),
      switchMap(userData => {
        return combineLatest([
          of(userData),
          this.actionMenuItemProvider.selectActions(challengeContextMenuId)
        ]
        );
      }),
      map(([userData, selectedActions]) => {
        selectedActions.forEach(action => {
          if (!userData.canDirectMessage && action instanceof ChallengeAskAuthorAction) {
            action.label = this.translateService.instant('CHALLENGE_ASK_AUTHOR_UNREACHABLE');
            action.isDisabled = true;
            action.iconColor = GreyPalette.GREY5;
          }
        });
        return {
          actions: selectedActions,
          data: userData
        };
      })
    ).subscribe((({ actions, data }) => this.overlayManager.menu.open(ActionMenuComponent, { actions, data })));
  }

  private getUserProfileByMessageId(messageId: string): Observable<ChallengeData> {
    return this.store.pipe(
      selectorWithParam(selectMessageDetailById, messageId),
      first(),
      switchMap(message => {
        let profileData$: Observable<Partial<UserProfileResponse>>;
        if (message.authorId) {
          this.socketIoService.user.profile.emit({ userId: message.authorId });
          profileData$ = this.socketIoService.user.profile.listen.pipe(first());
        } else {
          profileData$ = of({displayname: message.userId, enableChatViaProfile: false});
        }

        return combineLatest([
          of(message),
          this.store.pipe(select(selectCurrentChannel)).pipe(first()),
          profileData$
        ]);
      }),
      map(([message, channelData, profileData]) => {
        return {
          messageId: message.id,
          canDirectMessage: profileData.enableChatViaProfile,
          authorUserId: profileData.userId,
          authorName: profileData.displayname,
          channelName: channelData.title
        };
      })
    );
  }
}
