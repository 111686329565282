import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OverlayManager } from '@portal/wen-components';
import { filter, switchMap } from 'rxjs';
import { firstExisty } from '../../../core/common/operators/first-existy';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { WenStorageService } from '../../../core/services/storage/wen-storage.service';
import { PermissionLevel } from '../../../core/services/user-management/permission-level';
import { WenOAuthService } from '../../../core/services/user-management/wen-oauth.service';
import { selectCurrentUserData } from '../../../core/store/auth/auth.selectors';
import { RootState } from '../../../core/store/root/public-api';
import { AgbDataProtectionDialogComponent } from './agb-data-protection-dialog/agb-data-protection-dialog.component';
import { SimpleDataProtectionDialogComponent } from './simple-data-protection-dialog/simple-data-protection-dialog.component';

@Injectable()
export class DataProtectionDialogOpener {

  constructor(
    private store: Store<RootState>,
    private oAuthService: WenOAuthService,
    private storageService: WenStorageService,
    private translateService: TranslateService,
    private overlayManager: OverlayManager,
    private featureEnablementService: FeatureEnablementService,
  ) { }

  openDialogIfNeeded() {
    this.store.pipe(
      select(selectCurrentUserData),
      firstExisty(),
      filter(({ permissionLevel }) => {
        const { needsDataProtectionDialog } = this.oAuthService.getState() || {};
        const agbAndRegistered = this.featureEnablementService.isAgbEnabled()
          && permissionLevel === PermissionLevel.REGISTERED_USER;
        if (!agbAndRegistered && needsDataProtectionDialog) {
          this.storageService.setDataProtectionNeeded();
        }
        return this.storageService.isDataProtectionNeeded();
      }),
      switchMap(() => {
        if (this.featureEnablementService.isAgbEnabled()) {
          return this.overlayManager.dialog.openConfirmationDialog(AgbDataProtectionDialogComponent, {
            header: this.translateService.instant('AGB_DATA_PROTECTION_DIALOG_TITLE'),
            headerImage: '/assets/wen-widget/image/pic-Terms_of_use-120.svg',
          }, { disableClose: true, closeOnNavigation: false }).afterClosed();
        } else {
          return this.overlayManager.dialog.openConfirmationDialog(SimpleDataProtectionDialogComponent, {
            header: this.translateService.instant('DATA_PROTECTION_DIALOG_TITLE'),
            okLabel: this.translateService.instant('DATA_PROTECTION_DIALOG_ACCEPT_BUTTON_LABEL')
          }, { disableClose: true, closeOnNavigation: false }).afterClosed();
        }
      })
    ).subscribe((dialogResult) => {
      if (dialogResult?.result === 'ok') {
        this.storageService.setDataProtectionDone();
      }
    });
  }

}
