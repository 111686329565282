import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges, ViewChild } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { MatChipGrid } from '@angular/material/chips';
import { MatInput } from '@angular/material/input';
import { CVA_PROXY_PROVIDERS, ControlValueAccessorProxy } from '@portal/wen-components';
import { Observable, Subject, filter, first, map, startWith } from 'rxjs';

export interface InputWithChipModel {
  id: string;
  icon: string;
  text: string;
}

@Component({
  selector: 'wen-input-with-chips',
  templateUrl: './input-with-chips.component.html',
  styleUrls: ['./input-with-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ...CVA_PROXY_PROVIDERS
  ]
})
export class InputWithChipsComponent implements OnInit, AfterViewInit, OnChanges {

  private updateInputProps$ = new Subject<void>();

  placeholder$: Observable<string>;


  @Input() placeholderTranslation: string;
  @Input() prefixTranslation: string;
  @Input() maxNumberOfSelectableItems: number;
  @Input() selectedItems: InputWithChipModel[] = [];
  @Input() keepFocused = false;
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() itemRemove = new EventEmitter<InputWithChipModel>();

  @Input() itemCount: number;

  @ViewChild(MatChipGrid) private chipGrid: MatChipGrid;
  @ViewChild('chipInput') private inputField: ElementRef<MatInput>;

  @HostListener('click')
  onClick() {
    this.focusInput();
  }

  constructor(
    @Optional() @Self() private readonly formControlName: FormControlName,
    @Optional() private readonly cvaProxy: ControlValueAccessorProxy,
  ) { }

  ngOnInit() {
    this.placeholder$ = this.updateInputProps$.pipe(
      startWith(this.placeholderTranslation),
      map(() => {
        const selectedItemSize = this.selectedItems.length;
        return selectedItemSize > 0 ? '' : this.placeholderTranslation;
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.cvaProxy && this.formControlName) {
      this.trySetFormBasedValues();
      this.cvaProxy.initializeControl(this.chipGrid, this.formControlName);
    }
  }

  onChipGridChange() {
    const formControl = this.cvaProxy?.formControl;
    if (formControl) {
      formControl.markAsDirty();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedItems?.length >= this.maxNumberOfSelectableItems) {
      return;
    }
    if (this.selectedItems?.length) {
      this.updateInputProps$.next();
    }
  }

  onSearchChange(term: string) {
    this.searchTermChange.emit(term);
  }

  onItemRemoval(item: InputWithChipModel) {
    this.itemRemove.emit(item);
    this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
    this.updateInputProps$.next();
    this.focusInput();
    this.tryUpdateCvaProxy();
  }

  clearInput() {
    this.inputField.nativeElement.value = '';
  }

  focusInput() {
    this.inputField.nativeElement.focus();
  }

  private trySetFormBasedValues() {
    this.formControlName.valueChanges.pipe(
      first(),
      filter(v => Array.isArray(v)),
    ).subscribe((initialSelectedItems) => {
      this.selectedItems = initialSelectedItems;
      this.updateInputProps$.next();
    });
  }

  private tryUpdateCvaProxy() {
    if (this.cvaProxy && this.formControlName) {
      const valueArr = Array.from(this.selectedItems.values());
      this.cvaProxy.formControl.markAsDirty();
      this.cvaProxy.formControl.setValue(valueArr);
    }
  }
}
