import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { distinctUntilChanged, map } from 'rxjs';
import { removeUserFromCurrentChannel } from '../../../../core/store/channel/channel.actions';
import { selectCurrentChannelPermission } from '../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { AdminListActionPayload } from '../../channel-admin-list/components/channel-admin-list/channel-admin-list.component';

@Injectable()
export class RemoveUserFromChannelAction implements ActionMenuItem {

  label: string;
  icon = 'remove_person';

  isVisible$ = () => this.store.pipe(
    select(selectCurrentChannelPermission),
    map(permission => permission?.canRemoveSubscriber),
    distinctUntilChanged()
  );

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>
  ) {
    this.label = this.translateService.instant('CHANNEL_REMOVE_CHANNEL_MEMBER');
  }

  execute(payload?: string | AdminListActionPayload) {
    let userIdToRemove: string;
    if (!Boolean(payload)) {
      userIdToRemove = null;
    } else if (typeof payload === 'string') {
      userIdToRemove = payload;
    } else {
      userIdToRemove = payload.userId;
    }
    this.store.dispatch(removeUserFromCurrentChannel({ userIdToRemove }));
  }
}
