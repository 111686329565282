import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Observable, Subject, filter, first, takeUntil } from 'rxjs';
import { ConnectionMonitorService } from '../../../../core/services/connection-monitors/connection-monitor.service';
import { AppNavigator } from '../../../../core/services/navigation/app-navigator';

@Component({
  selector: 'wen-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit, OnDestroy {

  lottieOptions: AnimationOptions = {
    path: '/assets/wen-widget/error-page/InfoPageAnimation.json',
    autoplay: true,
    loop: true,
  };

  private lottieAnimation?: AnimationItem;
  private onDestroy$ = new Subject<void>();
  showClientError: Observable<boolean>;

  constructor(
    private readonly navigator: AppNavigator,
    private readonly connectionMonitorService: ConnectionMonitorService,
  ) { }

  onAnimationCreated(animationItem: AnimationItem) {
    this.lottieAnimation = animationItem;
    this.lottieAnimation.setSpeed(1);
  }

  ngOnInit(): void {
    const { isOnline$, showClientError$ } = this.connectionMonitorService.attach();
    this.showClientError = showClientError$;

    isOnline$.pipe(
      filter(isOnline => isOnline),
      first(),
      takeUntil(this.onDestroy$),
    ).subscribe(() => {
      this.navigator.leaveStackAndNavigateBack();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
