import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmbeddedLinkDTO } from '@portal/wen-backend-api';
import { distinctUntilChanged, map, merge, Observable, tap } from 'rxjs';
import { ChannelMediaPreviewHandler } from '../../../../../core/services/util/channel-media-preview-handler';
import { MediaPreviewHandler } from '../../../../../core/services/util/media-preview-handler';
import { MessageSendFieldDataSource } from '../../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { CHANNEL_MESSAGE_TYPE_SELECTOR_MENU_ID } from '../../../../../shared/components/message-type-selector/constants/type-selector-ids';

@Component({
  selector: 'wen-channel-message-send-field',
  templateUrl: './channel-message-send-field.component.html',
  styleUrls: ['./channel-message-send-field.component.scss'],
  providers: [{
    provide: MediaPreviewHandler,
    useClass: ChannelMediaPreviewHandler
  }]
})
export class ChannelMessageSendFieldComponent implements OnInit {

  @Input() hasControls: boolean;
  @Input() inputPlaceholder: string;

  @Output() focused = new EventEmitter<FocusEvent>();
  @Output() messageSent = new EventEmitter();

  channelId$: Observable<string>;
  hasEmbed$: Observable<boolean>;
  hasMediaEmbed$: Observable<boolean>;
  hasPollEmbed$: Observable<boolean>;
  isEdit$: Observable<boolean>;
  linkPreviewContent$: Observable<string>;

  messageSelectorId: string = CHANNEL_MESSAGE_TYPE_SELECTOR_MENU_ID;

  constructor(
    private readonly dataSource: MessageSendFieldDataSource,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.channelId$ = this.dataSource.contextId$;
    this.hasMediaEmbed$ = this.dataSource.draftMessage$.pipe(
      map(message => message?.fileEmbed),
      map(url => Boolean(url)),
      tap(() => this.cdr.detectChanges()),
      distinctUntilChanged(),
    );

    this.hasPollEmbed$ = this.dataSource.draftMessage$.pipe(
      map((message) => Boolean(message?.pollEmbed)),
      tap(() => this.cdr.detectChanges()),
      distinctUntilChanged(),
    );

    this.hasEmbed$ = merge(
      this.hasMediaEmbed$,
      this.hasPollEmbed$
    ).pipe(
      map(hasMediaEmbedOrPollEmbed => {
        return hasMediaEmbedOrPollEmbed;
      })
    );

    this.isEdit$ = this.dataSource.draftMessage$.pipe(
      map(message => Boolean(message?.id)),
      distinctUntilChanged(),
    );

    this.linkPreviewContent$ = this.dataSource.draftMessage$.pipe(
      map(draftMessage => draftMessage?.content),
      distinctUntilChanged()
    );

  }

  onMessageSent() {
    this.dataSource.sendMessage();
    this.messageSent.emit();
  }

  onFocused(event: FocusEvent) {
    this.focused.emit(event);
  }

  stopEditingMessage() {
    this.dataSource.discardDraftMessage();
  }

  updateDraftMessageLinkEmbed(embed: EmbeddedLinkDTO) {
    this.dataSource.updateDraftMessage({ linkEmbed: embed });
  }

}
