import { InjectionToken } from '@angular/core';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { appsInitialState } from '../apps/apps.reducers';
import { AppsState } from '../apps/apps.state';
import { appsFeatureKey } from '../apps/constants';
import { clearStoreData } from '../auth/auth.actions';
import { authInitialState } from '../auth/auth.reducers';
import { AuthState } from '../auth/auth.state';
import { authFeatureKey } from '../auth/constants';
import { channelInitialState } from '../channel/channel.reducers';
import { ChannelState } from '../channel/channel.state';
import { channelFeatureKey } from '../channel/constants';
import { chatInitialState } from '../chat/chat.reducers';
import { ChatState } from '../chat/chat.state';
import { chatFeatureKey } from '../chat/constants';
import { christmasInitialState } from '../christmas/christmas.reducers';
import { ChristmasState } from '../christmas/christmas.state';
import { christmasFeatureKey } from '../christmas/constants';
import { eventsFeatureKey } from '../events/constants';
import { eventInitialState } from '../events/event.reducers';
import { EventState } from '../events/event.state';
import { headerFeatureKey } from '../header/constants';
import { headerInitialState } from '../header/header.reducers';
import { HeaderState } from '../header/header.state';
import { navigationBarFeatureKey } from '../navigation-bar/constants';
import { navigationBarInitialState } from '../navigation-bar/navigation-bar.reducers';
import { NavigationBarState } from '../navigation-bar/navigation-bar.state';
import { reactionsFeatureKey } from '../reactions/constants';
import { reactionInitialState } from '../reactions/reaction.reducers';
import { ReactionsState } from '../reactions/reaction.state';
import { smartDesignFeatureKey } from '../smartdesign/constants';
import { smartDesignInitialState } from '../smartdesign/smartdesign.reducers';
import { SmartDesignState } from '../smartdesign/smartdesign.state';
import { uiFeatureKey } from '../ui/constants';
import { uiInitialState } from '../ui/ui.reducers';
import { UiState } from '../ui/ui.state';
import { userFeatureKey } from '../user/constants';
import { userInitialState } from '../user/user.reducers';
import { UserState } from '../user/user.state';
import { bulkAction } from './root.actions';
import { NotificationState } from '../notification/notification.state';
import { notificationFeatureKey } from '../notification/constants';

export interface RootState {
  router: RouterReducerState<any>;
  [channelFeatureKey]: ChannelState;
  [notificationFeatureKey]: NotificationState;
  [headerFeatureKey]: HeaderState;
  [navigationBarFeatureKey]: NavigationBarState;
  [authFeatureKey]: AuthState;
  [appsFeatureKey]: AppsState;
  [userFeatureKey]: UserState;
  [reactionsFeatureKey]: ReactionsState;
  [eventsFeatureKey]: EventState;
  [uiFeatureKey]: UiState;
  [smartDesignFeatureKey]: SmartDesignState;
  [christmasFeatureKey]: ChristmasState;
  [chatFeatureKey]: ChatState;
}

const rootInitialState: Partial<RootState> = {
  [channelFeatureKey]: channelInitialState,
  [headerFeatureKey]: headerInitialState,
  [navigationBarFeatureKey]: navigationBarInitialState,
  [authFeatureKey]: authInitialState,
  [appsFeatureKey]: appsInitialState,
  [userFeatureKey]: userInitialState,
  [reactionsFeatureKey]: reactionInitialState,
  [eventsFeatureKey]: eventInitialState,
  [uiFeatureKey]: uiInitialState,
  [smartDesignFeatureKey]: smartDesignInitialState,
  [christmasFeatureKey]: christmasInitialState,
  [chatFeatureKey]: chatInitialState
};

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<Partial<RootState>, Action>
>('Root reducers token', {
  factory: () => ({ router: routerReducer }),
});

export const WEN_STORE_META_REDUCER = (): MetaReducer<RootState> => (reducer: ActionReducer<RootState>): ActionReducer<RootState> => {
  return function(state: RootState, action: Action): RootState {
    if (action.type === clearStoreData().type) {
      state = {
        ...state,
        ...rootInitialState
      };
    } else if (action.type === bulkAction({ targetActions: [] }).type) {
      const { targetActions } = action as ReturnType<typeof bulkAction>;
      const reducedState = targetActions.reduce((acc, targetAction) => {
        const result = reducer(acc, targetAction);
        return {
          ...acc,
          ...result
        };
      }, state);
      return reducedState;
    }
    return reducer(state, action);
  };
};
