import { Injectable } from '@angular/core';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MassDataStateAdapter } from '@portal/wen-data-core';
import { ContactListEntity } from '../contacts.state';

@Injectable()
export class ContactsSearchMassDataAdapter extends MassDataStateAdapter<ContactListEntity> {

  private contactsEntityAdapter: EntityAdapter<ContactListEntity>;

  getEntityAdapter() {
    if (!this.contactsEntityAdapter) {
      this.contactsEntityAdapter = createEntityAdapter<ContactListEntity>({
        selectId: (model) => model.user.id
      });
    }
    return this.contactsEntityAdapter;
  }

}
