import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { UserProfileResponse } from '@portal/wen-backend-api';
import { WenRouterOutlet } from '@portal/wen-components';
import { Observable, Subject, filter, first, map, merge, shareReplay, switchMap, takeUntil } from 'rxjs';
import { WenRouteUrlBuilder } from '../../../../core/services/navigation/outlet-specific/wen-route-url-builder';
import { logout } from '../../../../core/store/auth/auth.actions';
import { RootState } from '../../../../core/store/root/public-api';
import { selectCurrentCRMRootRoute } from '../../../../core/store/smartdesign/smartdesign.selectors';
import { selectProfileImageState, selectUserProfile } from '../../../../core/store/user/user.selectors';
import { ProfileImageState } from '../../../../core/store/user/user.state';
import { DetailHeaderModel } from '../../../../shared/components/detail-header/detail-header.component';

@Component({
  selector: 'wen-profile-read-only-view',
  templateUrl: './profile-read-only-view.html',
  styleUrls: ['./profile-read-only-view.scss']
})
export class ProfileReadOnlyViewComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  @HostBinding('class.wen-profile-read-only-view') className = true;
  lottieOptions = {
    path: '/assets/wen-widget/lottie/PhotoLoading.json',
  };
  headerData$: Observable<DetailHeaderModel>;
  userProfile$: Observable<UserProfileResponse>;
  pendingProfileImageText$: Observable<string>;

  constructor(
    private store: Store<RootState>,
    private routeUrlBuilder: WenRouteUrlBuilder
  ) { }

  ngOnInit(): void {
    this.userProfile$ = this.store.pipe(
      select(selectUserProfile),
      shareReplay(1),
    );
    const profileImageState$ = this.store.pipe(
      select(selectProfileImageState),
      shareReplay(1),
    );

    const headerDataOnProfileChange$ = this.userProfile$.pipe(
      switchMap((profile) => profileImageState$.pipe(
        first(),
        map(profileImageState => {
          return {
            title: profile?.displayname,
            imageUrl: profile?.avatarUrl,
            pendingImage: profileImageState === ProfileImageState.PENDING_SAFE || profileImageState === ProfileImageState.PENDING_UNSAFE
          };
        })
      )),
      takeUntil(this.onDestroy$)
    );

    const headerDataOnProfileImageError$ = profileImageState$.pipe(
      filter(profileImageState => profileImageState === ProfileImageState.ERROR),
      switchMap(() => this.userProfile$.pipe(
        first(),
        map(profile => {
          return {
            title: profile?.displayname,
            imageUrl: profile?.avatarUrl,
            pendingImage: false
          };
        })
      )),
      takeUntil(this.onDestroy$)
    );

    this.headerData$ = merge(
      headerDataOnProfileChange$,
      headerDataOnProfileImageError$
    );

    this.pendingProfileImageText$ = profileImageState$.pipe(
      map(profileImageState => {
        return profileImageState === ProfileImageState.PENDING_SAFE ? 'SAFE_PENDING_MEDIA_UPLOAD_TEXT' : 'UNSAFE_PENDING_MEDIA_UPLOAD_TEXT';
      })
    );
  }

  logout() {
    this.store.pipe(
      select(selectCurrentCRMRootRoute),
      first()
    ).subscribe(routeId => {
      // The url needs to be added to the whitelist on the BE
      const postLogoutRedirectUri = this.routeUrlBuilder.buildUrlsForSingleRoute(routeId, {}, WenRouterOutlet.DIALOG).dialog;
      this.store.dispatch(logout({ isByUserInteraction: false, postLogoutRedirectUri }));
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
