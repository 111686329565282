import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { loginRoutes } from './login.routes';
import { LoginComponent } from './components/login/login.component';
import { OauthCallbackComponent } from './components/oauth-callback/oauth-callback.component';
import { LogoutComponent } from './components/logout/logout.component';
import { SilentLogoutComponent } from './components/silent-logout/silent-logout.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(loginRoutes)
  ],
  declarations: [
    LoginComponent,
    OauthCallbackComponent,
    LogoutComponent,
    SilentLogoutComponent
  ],
  exports: [
    SilentLogoutComponent
  ]
})
export class LoginModule { }
