import { inject } from '@angular/core';
import { MassDataRegistry } from '../config/mass-data-module-utils';
import { MassDataAction, MassDataActions, MassDataOperation } from '../mass-data.actions';
import { MassDataEntityState, MassDataStateType, MassDataUpdate } from '../types';

const createMassDataReducer = (
  massDataRegistry: MassDataRegistry
) => <ENTITY>(state: MassDataStateType<ENTITY>, action: MassDataAction<MassDataActions>) => {
  const { payload } = action;
  if (!payload) {
    return state;
  }
  const { massDataOperation, massDataType, massDataActionData } = payload;
  if (!massDataOperation || !massDataType) {
    return state;
  }
  const massDataInjector = massDataRegistry.for(massDataType);
  const massDataAdapter = massDataInjector.getStoreAdapter();
  const massDataContainer = massDataInjector.getContainer();
  const stateSlice = state[massDataType] || massDataAdapter.getInitialState();
  let result: MassDataEntityState<ENTITY>;
  switch (massDataOperation) {
    case MassDataOperation.CLEAR_ALL:
      result = massDataAdapter.removeAll(stateSlice);
      break;
    case MassDataOperation.STORE_SET_ALL:
      result = massDataAdapter.setAll(stateSlice, massDataActionData);
      break;
    case MassDataOperation.STORE_UPSERT_MANY:
      const upsertManyEntities: ENTITY[] = Array.isArray(massDataActionData) ? massDataActionData : [massDataActionData];
      result = massDataAdapter.upsertMany(stateSlice, upsertManyEntities);
      break;
    case MassDataOperation.STORE_UPDATE_MANY:
      const updateManyEntities: ENTITY[] = Array.isArray(massDataActionData) ? massDataActionData : [massDataActionData];
      const updateManyUpdates = updateManyEntities.map(entity => {
        const id = massDataContainer.selectors.selectId(entity);
        const update: MassDataUpdate<ENTITY> = { changes: entity, id };
        return update;
      });
      result = massDataAdapter.updateMany(stateSlice, updateManyUpdates);
      break;
    case MassDataOperation.STORE_REMOVE_MANY:
      const removeManyEntities: ENTITY[] = Array.isArray(massDataActionData) ? massDataActionData : [massDataActionData];
      const removemanyUpdates = removeManyEntities.map(entity => {
        const id = massDataContainer.selectors.selectId(entity);
        return id;
      });
      result = massDataAdapter.removeMany(stateSlice, removemanyUpdates);
      break;
    case MassDataOperation.STORE_SET_LOADING_PROGRESS:
      const loadingProgress = massDataActionData;
      result = { ...stateSlice, loadingProgress };
      break;
    default:
      break;
  }
  if (!result) {
    return state;
  }
  return {
    ...state, [massDataType]: result
  };
};

export const massDataReducerFactory = () => {
  const massDataRegistry = inject(MassDataRegistry);
  return createMassDataReducer(massDataRegistry);
};
