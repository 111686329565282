import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { combineLatest, filter, first, map, of } from 'rxjs';
import { toObservable } from '../../../common/operators/observable-utils';
import { switchMapFirst } from '../../../common/operators/switch-map-first';
import { RootState } from '../../../store/root/public-api';
import { selectOutletDatas, selectOutletIds } from '../../../store/root/root.selectors';
import { BackNavigationBehavior } from '../back-navigation/back-navigation-behaviors/back-navigation-behavior';
import { DraftEditLeaveConfirmationBackNavigation } from '../back-navigation/back-navigation-behaviors/draft-edit-leave-confirmation-behavior';
import { FormEditLeaveConfirmationBackNavigation } from '../back-navigation/back-navigation-behaviors/form-edit-leave-confirmation-behavior';
import { injectBehavior } from '../back-navigation/back-navigation-utils';

@Injectable()
export class DeepLinkNavigator {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<RootState>,
    private injector: Injector,
  ) { }

  runGuarded(callback: () => void) {
    combineLatest([
      this.store.pipe(select(selectOutletIds)),
      this.store.pipe(select(selectOutletDatas))
    ]).pipe(
      first(),
      switchMapFirst(([outletIds, data]) => {
        const activeElement = this.document.activeElement as HTMLElement;
        if (activeElement) {
          // Make sure the focus is dropped so the form field changes are applied
          activeElement.blur();
        }
        const topLevelOutletId = outletIds?.dialogId || outletIds?.primaryId;
        const mainData = data?.dialogData || data?.primaryData;
        const backNavigationBehavior = mainData?.backNavigationBehavior;
        const behavior: BackNavigationBehavior = backNavigationBehavior && injectBehavior(backNavigationBehavior, this.injector);
        if (!this.shouldCheck(behavior)) {
          return of(true);
        }
        return toObservable(behavior.execute({topLevelOutletId }, mainData)).pipe(
          map((result) => {
            if (result.canHandle) {
              return false;
            }
            return true;
          })
        );
      }),
      filter((canNavigate) => canNavigate)
    ).subscribe(() => {
      callback();
    });
  }

  private shouldCheck(behavior: BackNavigationBehavior) {
    if (behavior instanceof DraftEditLeaveConfirmationBackNavigation || behavior instanceof FormEditLeaveConfirmationBackNavigation) {
      return true;
    }
    return false;
  }
}
