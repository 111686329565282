import { Directive } from '@angular/core';

@Directive({
  selector: 'wen-horizontal-card-image, [wen-horizontal-card-image]'
})
export class WenHorizontalCardImageDirective { }

@Directive({
  selector: 'wen-horizontal-card-title, [wen-horizontal-card-title]'
})
export class WenHorizontalCardTitleDirective { }

@Directive({
  selector: 'wen-horizontal-card-description, [wen-horizontal-card-description]'
})
export class WenHorizontalDescriptionDirective { }

@Directive({
  selector: 'wen-horizontal-card-link, [wen-horizontal-card-link]'
})
export class WenHorizontalCardLinkDirective { }
