import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { WenSlideToggleComponent } from '@portal/wen-components';
import { FormStoreModule } from '../../../shared/form-store/form-store.module';
import { SharedModule } from '../../../shared/shared.module';
import { PrivacySettingsViewComponent } from './components/privacy-view/privacy-settings-view.component';

@NgModule({
  imports: [
    SharedModule,
    WenSlideToggleComponent,
    FormStoreModule,
    ReactiveFormsModule,
  ],
  declarations: [
    PrivacySettingsViewComponent
  ],
  exports: [
    PrivacySettingsViewComponent
  ]
})
export class PrivacySettingsModule { }
