import { WenRouteId, WenRoutes } from '../../frame/routing/types';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { OauthCallbackComponent } from './components/oauth-callback/oauth-callback.component';

export const loginRoutes: WenRoutes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      routeId: WenRouteId.LOGIN
    }
  },
  {
    path: 'callback',
    component: OauthCallbackComponent,
    data: {
      routeId: WenRouteId.OAUTH_CALLBACK
    }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      routeId: WenRouteId.LOGOUT
    }
  }
];
