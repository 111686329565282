import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChannelDTO, ChannelMessagesDetailError } from '@portal/wen-backend-api';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';

interface ErrorModel {
  titleTranslationKey: string;
  descriptionTranslationKey?: string;
  imagePath: string;
  centered?: boolean;
}

@Component({
  selector: 'wen-message-error',
  templateUrl: './message-error.component.html',
  styleUrls: ['./message-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageErrorComponent implements OnInit {

  @Input() errorType: ChannelMessagesDetailError;
  @Input() channelData: ChannelDTO;

  get showNavigateToWelcome() {
    return !this.channelData || this.channelData.deleted;
  }

  get showNavigateToChannel() {
    return this.channelData && !this.channelData.deleted;
  }

  get errorModel(): ErrorModel {
    switch (this.errorType) {
      case ChannelMessagesDetailError.ERROR_MESSAGE_DELETED:
        return {
          titleTranslationKey: 'CHANNEL_ERROR_MESSAGE_DELETED_TITLE',
          descriptionTranslationKey: 'CHANNEL_ERROR_MESSAGE_DELETED_DESCRIPTION',
          imagePath: '/assets/wen-widget/image/pic_404_post.svg'
        };
      case ChannelMessagesDetailError.ERROR_CHANNEL_DELETED:
      case ChannelMessagesDetailError.ERROR_NOT_FOUND:
        return {
          titleTranslationKey: 'CHANNEL_ERROR_CHANNEL_DELETED_TITLE',
          imagePath: '/assets/wen-widget/image/pic_404_channel.svg',
          centered: true
        };
      case ChannelMessagesDetailError.ERROR_CHANNEL_RESTRICTED:
        return {
          titleTranslationKey: 'CHANNEL_ERROR_CHANNEL_RESTRICTED_TITLE',
          descriptionTranslationKey: 'CHANNEL_ERROR_CHANNEL_RESTRICTED_DESCRIPTION',
          imagePath: '/assets/wen-widget/image/pic-restricted_channel_3.svg'
        };
      default:
        return null;
    }
  }

  constructor(
    private readonly navigationHelper: WenNavigationHelper
  ) { }

  ngOnInit() {
  }

  navigateToChannel(channelId: string) {
    this.navigationHelper.navigateToChannelView(channelId);
  }

  navigateToWelcome() {
    this.navigationHelper.navigateToWelcomeScreen();
  }
}
