import { createAction, props } from '@ngrx/store';
import { PagingHistoryFlags, PagingReplayDirection } from '@portal/wen-backend-api';
import { ChatMessageEntity } from '../chat.state';

export const fetchHistoricalMessagesForRoom = createAction(
  '[Chat] Fetch historical messages for current room',
  (payload: { roomId: string; timestamp: string; direction: PagingReplayDirection }) => payload
);

export const upsertRoomMessagesHistory = createAction(
  '[Chat] Upsert room messages history',
  props<{ roomId: string; messages: ChatMessageEntity[]; flags: PagingHistoryFlags }>()
);

export const updateRoomMessagesHistory = createAction(
  '[Chat] Update the provided room messages anywhere in the history or realtime messages',
  props<{ roomId: string; messages: ChatMessageEntity[]; flags: PagingHistoryFlags }>()
);

export const clearRoomMessagesHistory = createAction(
  '[Chat] Clear room history',
  props<{ roomId: string }>()
);

export const replaceChatMessagesHistory = createAction(
  '[Chat] Replace chat messages history',
  props<{ roomId: string; messages: ChatMessageEntity[]; flags: PagingHistoryFlags }>()
);
