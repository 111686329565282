@if (channel$ | async; as channel) {
  <form wenFormStore
    class="wen-form"
    [formGroup]="channelSettingsFormGroup"
    wenIosKeyboardSticky>
    <div class="wen-form-group wen-single-group">
      <div class="wen-form-field-wrapper">
        <div portal-caption class="wen-form-field-label">{{ "CHANNEL_RESTRICTION_FIELD_LABEL" | translate }}</div>
        <wen-selection-list
          [formControlName]="'restrictions'"
          [selectionItems]="restrictionSelectionItems$ | async"
          [selectionModel]="restrictionSelectionModel$ | async"
          [selectionOptionPosition]="'postfix'"
          [selectionOptionBorderType]="'borderless'"
          [allowEmptyList]="false"
        ></wen-selection-list>
      </div>
      @if (hasNoChannelId$ | async) {
        <wen-translate-with-icon class="wen-form-group-hint" [translationKey]="'CHANNEL_RESTRICTION_SETTINGS_FORM_GROUP_HINT'" [iconName]="'lock'" portal-subheading-2></wen-translate-with-icon>
      }
      @if (passwordInputVisible$ | async) {
        <div class="wen-form-field-wrapper">
          <mat-form-field>
            <mat-label>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_LABEL' | translate }}</mat-label>
            <input #passwordInput [formControlName]="'password'" matInput autocomplete="new-password" type="password"
              wenIosKeyboardStickyTarget [required]="true" />
              <mat-error portal-caption>{{ 'CHANNEL_RESTRICTION_SETTINGS_FORM_EMPTY_PASSWORD_ERROR' | translate }}</mat-error>
              @if (passwordValue) {
                <wen-password-visibility-toggle class="wen-password-visibility" matSuffix [for]="passwordInput">
                </wen-password-visibility-toggle>
              }
            </mat-form-field>
          </div>
          <div class="wen-form-field-wrapper">
            <mat-form-field>
              <mat-label>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_REPEAT_LABEL' | translate }}</mat-label>
              <input #passwordRepeatInput [formControlName]="'passwordRepeat'"
                [errorStateMatcher]="comparePasswordErrorMatcher" matInput autocomplete="new-password" type="password"
                wenIosKeyboardStickyTarget />
                <mat-error portal-caption>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_REPEAT_ERROR' | translate }}</mat-error>
                @if (passwordRepeatValue) {
                  <wen-password-visibility-toggle class="wen-password-visibility" matSuffix [for]="passwordRepeatInput">
                  </wen-password-visibility-toggle>
                }
              </mat-form-field>
            </div>
          }
        </div>
      </form>
    }

