<div class="wen-user-credentials-container">
  <div class="wen-credentials-menu-entries">
    @if (unitedKioskCredential$ | async; as credential) {
      <wen-menu-entry
        class="wen-user-credentials-menu-entries"
        (click)="navigateToCredentialDetails(credential.type)"
        >
        <wen-icon class="wen-credentials-menu-icon wen-icon-medium"
        [iconName]="'book'" [fillColor]="'#1467BA'" [color]="'white'" menuEntryIcon></wen-icon>
        <span menuEntryTitle>{{ "CREDENTIALS_MENU_UNITED_KIOSK_FLAT" | translate }}</span>
        <span menuEntryDescription>{{ credential.active ? ('CREDENTIALS_MENU_ACTIVE' | translate) : ('CREDENTIALS_MENU_INACTIVE' | translate) }}</span>
      </wen-menu-entry>
    }
  </div>
</div>
