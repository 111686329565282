import { Component, HostBinding, Input } from '@angular/core';

type HeaderHeight = 'static' | 'dynamic';

@Component({
  selector: 'wen-desktop-sub-header',
  templateUrl: './desktop-sub-header.component.html',
  styleUrls: ['./desktop-sub-header.component.scss']
})
export class DesktopSubHeaderComponent {

  @HostBinding('class.static-header-height') heightClassName = false;
  @Input() set height(value: HeaderHeight) {
    this.heightClassName = value === 'static';
  }

}
