import { createAction, props } from '@ngrx/store';
import { WeatherState } from './weather.state';

export const fetchCurrentWeatherData = createAction(
  '[Weather] Get current weather data from API',
  props<{ countryCode: string; location: string }>()
);

export const weatherDataRetrieved = createAction(
  '[weather] Retrieved weather data',
  props<{ newState: Partial<WeatherState> }>()
);
