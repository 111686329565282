import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, map } from 'rxjs';
import { selectHeaderState } from '../../../../../core/store/header/header.selectors';
import { RootState } from '../../../../../core/store/root/public-api';

@Component({
  selector: 'wen-chat-creation-desktop',
  templateUrl: './chat-creation-desktop.component.html',
  styleUrls: ['./chat-creation-desktop.component.scss']
})
export class ChatCreationDesktopComponent {

  titleTranslationKey$ = this.store.pipe(
    select(selectHeaderState),
    map(({ headerData }) => headerData.title),
    distinctUntilChanged()
  );

  constructor(private store: Store<RootState>) { }

}
