import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { first, map, merge, switchMap, tap } from 'rxjs';
import { mapWithFirstFrom } from '../../../../common/operators/map-with-first-from';
import { selectCurrentUserData } from '../../../auth/auth.selectors';
import { RootState } from '../../../root/public-api';
import { cleanUpInviteToChannelRequestChannel, handleInviteToChannelRequest, subscribeToJoinRequestChannelUpdates } from '../../channel.actions';

@Injectable()
export class ChannelInviteToChannelEffects {

  private readonly currentUserData$ = this.store.pipe(
    select(selectCurrentUserData)
  );

  inviteToChannelDecisionEffect$ = createEffect(() => this.actions$.pipe(
    ofType(handleInviteToChannelRequest),
    mapWithFirstFrom(() => this.currentUserData$),
    tap(([{ inviteId, accepted }, { userId }]) => {
      if (accepted) {
        this.socketIoService.channelInvitation.inviteToChannelAccept.emit({ userId, inviteId });
      } else {
        this.socketIoService.channelInvitation.inviteToChannelDeny.emit({ userId, inviteId });
      }
    })
  ), { dispatch: false });

  inviteToChannelCleanupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToJoinRequestChannelUpdates),
    first(),
    switchMap(() => merge(
      this.socketIoService.channelInvitation.inviteToChannelAccept.listen,
      this.socketIoService.channelInvitation.inviteToChannelDeny.listen,
    ).pipe(
      map(({ channelId }) => cleanUpInviteToChannelRequestChannel({ channelId }))
    ))
  ));

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
  ) { }
}
