import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService, updateToRestrictionDTO } from '@portal/wen-backend-api';
import { OverlayManager, WenSnackbarOpener } from '@portal/wen-components';
import { merge } from 'rxjs';
import { filter, first, map, share, switchMap } from 'rxjs/operators';
import { DateUtil } from '../../common/date/date-util';
import { selectorWithParam } from '../../common/util/selector-with-param';
import { ChannelEventOrchestrator } from '../../services/socket-io/helpers/channel-event-orchestrator';
import { selectCurrentUserData } from '../auth/auth.selectors';
import { RootState } from '../root/public-api';
import { selectCurrentChannel, selectLastReadTimestamp } from './channel.selectors';
import { createChannelFeaturedDataEffect } from './effects/channel-featured-data.effect';
import { createChannelHistoricalMessageEffect } from './effects/channel-historical-messages.effect';
import { createAcknowledgeMessagesOnCurrentDeviceEffect, createBroadcastCurrentAcknowledgeStateEffect, createBroadcastCurrentAcknowledgeStateForAutoAcknowledgeEffect, createLastReadTimestampUpdateEffect, createNotificationUpdaterEffect } from './effects/channel-message-acknowledge.effect';
import { createChannelExtraMessagesEffect, createChannelMessageEffect, createChannelMessageModificationsEffect } from './effects/channel-message.effect';
import { createChannelScheduledMessageEffect, createChannelScheduledMessageModifiedEffect, createChannelScheduledMessagesCountEffect, createChannelScheduledMessagesCountUpdateEffect, createChannelScheduledMessagesReplayEffect, createChannelScheduledMessagesUpdateEffect, createNotifySuccessfulScheduleEffect, createScheduledMessageOnPublishEffect, createScheduledMessageOnPublisWhileOfflineEffect } from './effects/channel-scheduled-messages.effect';
import { createChannelSetMuteEffect } from './effects/channel-set-mute.effect';
import { createChannelDeleteAcknowledgeEffect, createChannelUpdateEffect, createCommentCleanerEffect, createReactionCleanerEffect } from './effects/channel-updates.effect';
import { createDeleteChannelMessageEffect } from './effects/delete-channel-message-effect';
import { createAutoAcknowledgeForMessageSendingEffect } from './effects/message-auto-acknowledge.effect';
import { createResolveSubscriptionRestrictionEffect, createSetChannelByIdSubscriptionEffect, createSetChannelSubscriptionEffect } from './effects/set-channel-subscription.effect';
import { togglePinChannelEffect } from './effects/toggle-pin-channel.effect';
import { updateChannelMessagesDetail } from './channel.actions';
import { createChannelRelevantMessageHandlerEffect } from './effects/channel-relevant-message-handler.effect';

@Injectable()
export class ChannelEffects {

  private readonly channelDetails$ = this.socketIoService.channel.details.listen.pipe(
    share()
  );
  private readonly channelUpdate$ = merge(
    this.socketIoService.channel.update.listen.pipe(
      map((update) => {
        return {
          ...update,
          restrictions: update.restrictions?.map(restriction => updateToRestrictionDTO(restriction))
        };
      })
    ),
    this.socketIoService.channel.delete.listen
  );
  private readonly channelMessagesAcknowledge$ = this.socketIoService.channel.messagesAcknowledge.listen.pipe(
    switchMap((response) => this.store.pipe(
      selectorWithParam(selectLastReadTimestamp, response.channelId),
      first(),
      filter(lastReadTimestamp => !Boolean(lastReadTimestamp) || DateUtil.compare(lastReadTimestamp, response.lastAckTimestamp) > 0),
      map(() => response)
    )),
    share()
  );

  private readonly channelMessages$ = this.socketIoService.channel.messages;
  private readonly channelMessagesDetail$ = this.actions$.pipe(
    ofType(updateChannelMessagesDetail),
    map(action => action.payload),
    filter(payload => payload.ok)
  );
  private readonly featuredMessages$ = this.socketIoService.featured.items.listen.pipe(
    map((items) => items.filter(item => item.type === 'message'))
  );
  private readonly channelMessageModifications$ = this.socketIoService.channel.messageModifications.listen;
  private readonly currentUserData$ = this.store.pipe(
    select(selectCurrentUserData)
  );
  private readonly currentChannel$ = this.store.pipe(
    select(selectCurrentChannel)
  );

  channelUpdates$ = createChannelUpdateEffect(this.actions$, this.channelUpdate$, this.channelDetails$);
  channelMessagesUpdates$ = createChannelMessageEffect(this.actions$, this.channelMessages$);
  createChannelExtraMessageEffect$ = createChannelExtraMessagesEffect(this.actions$, this.channelMessagesDetail$);
  channelMessagesHistoryUpdates$ = createChannelRelevantMessageHandlerEffect();
  channelMessagesModifications$ = createChannelMessageModificationsEffect(this.actions$, this.channelMessageModifications$);
  fetchHistoricalMessagesChannel$ = createChannelHistoricalMessageEffect(this.store, this.actions$, this.currentUserData$,
    this.socketIoService);
  fetchScheduledMessageEffect$ = createChannelScheduledMessageEffect(this.actions$, this.currentUserData$,
    this.socketIoService);
  scheduledMessageOnPublishEffect$ = createScheduledMessageOnPublishEffect(this.channelMessages$, this.store);
  createScheduledMessageOnPublisWhileOfflineEffect$ = createScheduledMessageOnPublisWhileOfflineEffect(this.actions$,
    this.channelEventOrchestrator.bufferedRelevant$);
  setChannelSubscriptionEffect$ = createSetChannelSubscriptionEffect(this.store, this.actions$, this.socketIoService);
  setChannelByIdSubscriptionEffect$ = createSetChannelByIdSubscriptionEffect(this.store, this.actions$, this.socketIoService);
  channelSetMuteEffect$ = createChannelSetMuteEffect(this.store, this.actions$, this.socketIoService);
  autoAcknowledgeForMessageSendingEffect$ = createAutoAcknowledgeForMessageSendingEffect(this.actions$);
  listenScheduledChannelMessagesReplayEffect$ = createChannelScheduledMessagesReplayEffect(this.socketIoService);
  listenScheduledChannelMessagesUpdateEffect$ = createChannelScheduledMessagesUpdateEffect(this.socketIoService);
  listenScheduledMessageModifiedEffect$ = createChannelScheduledMessageModifiedEffect(this.socketIoService);
  listenScheduledMessagesCountEffect$ = createChannelScheduledMessagesCountUpdateEffect(this.socketIoService, this.currentChannel$);
  fetchScheduledMessagesCountEffect$ = createChannelScheduledMessagesCountEffect(this.actions$, this.currentUserData$,
    this.socketIoService);
  resolveSubscriptionRestrictionEffect$ = createResolveSubscriptionRestrictionEffect(
    this.store, this.actions$, this.overlayManager, this.translateService
  );
  reactionCleanerEffect$ = createReactionCleanerEffect(this.actions$, this.channelDetails$, this.store);
  commentCountCleanerEffect$ = createCommentCleanerEffect(this.actions$, this.channelDetails$, this.store);
  channelDeleteAcknowledgeEffect$ = createChannelDeleteAcknowledgeEffect(this.actions$, this.store, this.socketIoService);
  channelFeaturedDataEffect$ = createChannelFeaturedDataEffect(this.actions$, this.featuredMessages$);
  deleteChannelMessageEffect$ = createDeleteChannelMessageEffect(
    this.actions$, this.socketIoService, this.translateService, this.overlayManager
  );
  lastReadTimestampUpdateEffect$ = createLastReadTimestampUpdateEffect(this.actions$, this.channelEventOrchestrator.bufferedRelevant$,
    this.channelMessagesAcknowledge$);
  acknowledgeMessagesOnCurrentDeviceEffect$ = createAcknowledgeMessagesOnCurrentDeviceEffect(this.actions$, this.store);
  broadcastCurrentAcknowledgeStateEffect$ = createBroadcastCurrentAcknowledgeStateEffect(this.actions$, this.store,
    this.currentUserData$, this.socketIoService);
  notificationUpdaterEffect$ = createNotificationUpdaterEffect(this.actions$, this.channelMessagesAcknowledge$);
  broadcastCurrentAcknowledgeStateForAutoAcknowledgeEffect$ = createBroadcastCurrentAcknowledgeStateForAutoAcknowledgeEffect(this.actions$,
    this.store, this.currentUserData$, this.socketIoService);
  togglePinChannelEffect$ = togglePinChannelEffect(this.actions$, this.socketIoService);
  notifySuccessfulScheduleEffect$ = createNotifySuccessfulScheduleEffect(this.actions$, this.wenSnackbar, this.translateService);

  constructor(
    private readonly actions$: Actions,
    private readonly socketIoService: SocketIoService,
    private readonly store: Store<RootState>,
    private readonly overlayManager: OverlayManager,
    private readonly translateService: TranslateService,
    private readonly wenSnackbar: WenSnackbarOpener,
    private readonly channelEventOrchestrator: ChannelEventOrchestrator,
  ) { }

}
