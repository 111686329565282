import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { firstExisty } from '../../../../core/common/operators/first-existy';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { PageRequestEvent, WeFeedDataset } from '../components/we-feed/we-feed-model';

@Injectable()
export abstract class FeedDatasource {
  /**
   * Connect the feed to the given observable
   *  - The items streamed in this observable are rendered
   *  - Make sure to prepare sufficient items for the viewport in the first emit for the best results
   * Params:
   *  - items: The items to be shown in the message box or the configured template
   *  - scrollReferenceItemId: The items which should be at the top of the visible viewport
   *                            in the next rendering (similar to scrolIntoView, but the item will be at the top)
   *  - newMessageLineItemId: The item id for which the new message line should be visible
   *                            If no scrollReferenceItemId provided the viewport will scroll to this item
   *                            If scrollReferenceItemId is provided it has precedence over this regarding scrolling
   *  - hasMoreOlder: If there are still items to be loaded at the "top"
   */
  abstract bindToSource(): Observable<WeFeedDataset>;
  /**
   * This callback indicated that a pagination is requested in the given direction
   *  The items should be streamed through the bindToSource return value
   */
  abstract loadNextPage(event: PageRequestEvent): Observable<{ hasResult: boolean }>;
  /**
   * The page at the very bottom is requested
   *  Usually this happens when the scroll to bottom button is clicked
   */
  abstract loadFirstPage(): Observable<{ hasResult: boolean }>;
  disableEmojiReaction$?: Observable<boolean>;
  disableFooterFunctions$ = of(false);
  protected currentUser$ = this.store.pipe(
    select(selectCurrentUserData),
    firstExisty(),
  );

  constructor(protected store: Store<RootState>) { }

}
