<div class="wen-profile-email-setting-page" cdkScrollable>
  <div class="wen-profile-email-setting-page-entries">
    <div class="wen-profile-email-setting-page-entry-groups">
      @if ((userProfile$ | async) || true; as userProfile) {
        <div class="wen-profile-email-setting-page-entry-group"
          [wen-test-id]="'wen-email-page-group'">
          @if (userProfile?.email) {
            <div class="wen-profile-email-setting-page-email-info">
              <div class="wen-profile-email-setting-page-email-info-label" portal-caption>{{
              "USER_PROFILE_EMAIL_CHANGE_EMAIL_FIELD_LABEL" | translate }}</div>
              <div> {{ userProfile?.email }} </div>
              @if (featureEnablementService.featureFlagMethods.isEnableEmailVerification()) {
                <div class="wen-profile-email-setting-page-email-info-description-wrapper"
                  >
                  @if (userProfile && !userProfile.emailVerified) {
                    <wen-icon class="wen-icon-small" iconName="error_icon">
                    </wen-icon>
                  }
                  @if (userProfile?.emailVerified) {
                    <div class="wen-profile-email-setting-page-email-info-verified-email-text"
                      portal-caption>{{ "USER_PROFILE_EMAIL_SETTINGS_VERIFIED_INFO_TEXT" |
                    translate }}</div>
                  }
                  @if (userProfile && !userProfile.emailVerified) {
                    <div class="wen-profile-email-setting-page-email-info-unverified-email-text"
                      portal-caption>{{
                    "USER_PROFILE_EMAIL_SETTINGS_UNVERIFIED_ERROR_TEXT" | translate }}</div>
                  }
                </div>
              }
            </div>
          } @else {
            <div class="wen-profile-email-setting-email-placeholder-wrapper">
              <wen-icon class="wen-profile-email-setting-email-placeholder wen-icon-flex wen-icon-colorful" [iconName]="'icon_mail2'"></wen-icon>
            </div>
          }
          @if (featureEnablementService.featureFlagMethods.isEnableEmailVerification() && userProfile && !userProfile.emailVerified && userProfile.email) {
            <wen-interact-button class="wen-interact-button-menu-style"
              (click)="verifyEmailAddress(userProfile)" [wen-test-id]="'wen-email-button-resend'">
              <span>{{ "USER_PROFILE_EMAIL_SETTINGS_VERIFY_EMAIL_BUTTON_LABEL" | translate }}</span>
            </wen-interact-button>
          }
          <wen-interact-button class="wen-interact-button-menu-style" (click)="changeEmailAddress()"
            [wen-test-id]="'wen-email-button-' + (userProfile.email ? 'edit' : 'add')">
            <span>{{ changeEmailAddressButtonLabel$ | async }}</span>
          </wen-interact-button>
        </div>
      }
    </div>
  </div>
</div>

