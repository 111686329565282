import { Directive } from '@angular/core';

@Directive({
  selector: 'wen-detail-header-quick-actions, [wen-detail-header-quick-actions]'
})
export class DetailHeaderQuickActionsDirective { }

@Directive({
  selector: 'wen-detail-header-additional-content, [wen-detail-header-additional-content]'
})
export class DetailHeaderAdditionalContentDirective { }
