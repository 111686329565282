import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ReactionSummary } from '@portal/wen-backend-api';

export interface ReactionEntity {
  referenceId: string;
  reactionSummary: ReactionSummary;
}


export interface ReactionsState {
  reactions: EntityState<ReactionEntity>;
}

export const reactionAdapter: EntityAdapter<ReactionEntity> = createEntityAdapter<ReactionEntity>({
  selectId: (reaction: ReactionEntity) => reaction?.referenceId || null
});
