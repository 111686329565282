import { mergeError } from '@portal/wen-common';

export const LoginError = (error: Error) => {
  return mergeError(error, { errType: 'LoginError' });
};

export interface CodeFlowErrorParams {
  redirectUrl?: string;
}

export const CodeFlowError = (error: Error, params: CodeFlowErrorParams) => {
  return mergeError(error, { ...params, errType: 'CodeFlowError' });
};
