import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ChannelType, SocketIoService } from '@portal/wen-backend-api';
import { distinctUntilChanged, first, map, ReplaySubject, shareReplay, switchMap } from 'rxjs';
import { firstExisty } from '../../../../core/common/operators/first-existy';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { fetchConfigurationForChannel, fetchPermissionsForChannel, updateChannelDetail } from '../../../../core/store/channel/channel.actions';
import { isChannelMessagesHistoryExists, selectChannelPermission, selectCurrentChannel, selectCurrentChannelPermission } from '../../../../core/store/channel/channel.selectors';
import { ChannelEntity } from '../../../../core/store/channel/channel.state';
import { RootState } from '../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../core/store/root/root.selectors';
import { ChannelPermission } from '../../../../core/store/user/models/Permission';
import { channelViewIdentifier } from '../../tokens';

@Injectable()
export class ChannelViewPreloaderGuard {

  private store = inject(Store<RootState>);
  private socketIoService = inject(SocketIoService);

  isChannelHistoryLoaded$ = this.store.pipe(
    select(selectRouteParam(channelViewIdentifier)),
    switchMap((channelId: string) => {
      return this.store.pipe(
        selectorWithParam(isChannelMessagesHistoryExists, channelId),
        distinctUntilChanged(),
      );
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isChannelDetailsLoaded$ = this.store.pipe(
    select(selectCurrentChannel),
    map((currentChannel) => {
      return Boolean(currentChannel?.id);
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isChannelPermissionssLoaded$ = this.store.pipe(
    select(selectCurrentChannelPermission),
    map((channelPermission) => {
      return Boolean(channelPermission);
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  ensureCurrentChannelDetailsLoaded() {
    const details$ = new ReplaySubject<ChannelEntity>(1);
    this.store.pipe(
      select(selectRouteParam(channelViewIdentifier)),
      first(),
      switchMap(channelId => this.socketIoService.channel.details.acknowledgement$({ id: channelId })),
    ).subscribe(channel => {
      this.store.dispatch(updateChannelDetail({ channel }));
      const isPersonalChannel = channel.channelType === ChannelType.PERSONAL;
      if (isPersonalChannel) {
        this.store.dispatch(fetchConfigurationForChannel());
      }
      details$.next(channel);
    });
    return details$.pipe(first());
  }

  ensureCurrentChannelPermissionsLoaded() {
    const permissions$ = new ReplaySubject<ChannelPermission>(1);
    this.store.pipe(
      select(selectCurrentChannel),
      firstExisty(),
      switchMap((channel) => {
        const channelId = channel.id;
        this.store.dispatch(fetchPermissionsForChannel({ channelId, useCache: true }));
        return this.store.pipe(
          selectorWithParam(selectChannelPermission, channelId),
        );
      }),
      firstExisty()
    ).subscribe(permissions => permissions$.next(permissions));
    return permissions$.pipe(first());
  }

}
