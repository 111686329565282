import { CommonModule } from '@angular/common';
import { ActiveItemImageViewComponent } from './active-item-image-view.component';
import { NgModule } from '@angular/core';
import { ACTIVE_ITEM_IMAGE_COMMAND_TOKEN } from './active-item-image-view-providers';
import { ChannelIndicatorComponent, CircleImageViewModule, CoreComponentsModule, WenIconModule } from '@portal/wen-components';

@NgModule({
  imports: [
    CommonModule,
    CircleImageViewModule,
    WenIconModule,
    CoreComponentsModule,
    ChannelIndicatorComponent,
  ],
  exports: [ActiveItemImageViewComponent],
  declarations: [ActiveItemImageViewComponent],
  providers: [
    {
      provide: ACTIVE_ITEM_IMAGE_COMMAND_TOKEN,
      useValue: ActiveItemImageViewComponent,
    },
  ]
})
export class ActiveItemImageViewModule { }
