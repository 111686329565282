import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShareMenuData } from '@portal/react-native-api';
import { updateDraftChatMessage } from '../../../store/chat/chat.actions';
import { RootState } from '../../../store/root/public-api';
import { FeatureEnablementService } from '../../configuration/feature-enablement';
import { WenNativeApi } from '@portal/wen-native-api';
import { DraftChatMediaPreviewHandler } from '../../util/draft-chat-media-preview-handler';
import { base64ToFile } from '../../util/file-utils';
import { WenNavigationHelper } from '../types';

@Injectable()
export class ShareMenuHandler {

  constructor(
    private nativeApi: WenNativeApi,
    private featureEnablementService: FeatureEnablementService,
    private mediaPreviewHandler: DraftChatMediaPreviewHandler,
    private navigationHelper: WenNavigationHelper,
    private store: Store<RootState>,
    private ngZone: NgZone,
  ) { }

  processShareData(menuData: ShareMenuData) {
    if (!this.featureEnablementService.isEnableShareMenu() || !this.featureEnablementService.isChatEnabled()) {
      return false;
    }
    const { mimeType, data } = menuData;
    if (mimeType.includes('image')) {
      let dataPath = typeof data === 'string' ? data : data[0]; // TODO: support multiple shares
      if (this.nativeApi.isIOS()) {
        dataPath = dataPath.replace(/^file\:\/\//, '');
      }
      this.nativeApi.getFileByPath(dataPath, 'base64').subscribe((fileInBase64: string) => {
        const file = base64ToFile(fileInBase64);
        this.ngZone.run(() =>
          this.mediaPreviewHandler.handleMediaPreview(file)
        );
      });
    } else {
      const content = typeof data === 'string' ? data : data[0]; // TODO: support multiple shares
      this.ngZone.run(() =>
        this.store.dispatch(updateDraftChatMessage({ message: { contextId: null, content, } }))
      );
    }
    this.ngZone.run(() =>
      this.navigationHelper.navigateToDialogChatCreationFromShare()
    );
    return true;
  }

}
