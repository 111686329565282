import { Injectable } from '@angular/core';
import { EncryptionAlgorithm, EncryptionType, ExchangeRoomKeyRequestEventDTO, ExchangeRoomKeyResponseContent, ExchangeRoomKeyResponseEventDTO, SendToUsersEventPayloadData, ToUsersEventType } from '@portal/wen-backend-api';
import { GetInboundGroupSessionParams, OlmDevice, OlmEncryptionResult, WenChatClient } from '@portal/wen-chat-client';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WenOAuthService } from '../../user-management/wen-oauth.service';

export class EncryptedExchangeSessionKeysEventResult {

  constructor(
    public readonly encryptionResult: OlmEncryptionResult,
    public readonly originalEvent: ExchangeRoomKeyRequestEventDTO,
    private readonly senderCurve25519: string,
    private readonly senderUserId: string,
  ) { }

  asSendToUsersPayload() {
    if (!this.encryptionResult) {
      return null;
    }
    const { requestingDeviceId } = this.originalEvent.content;
    const senderKey = this.senderCurve25519;
    const content: ExchangeRoomKeyResponseContent<true> = {
      algorithm: EncryptionAlgorithm.Olm,
      ciphertext: this.encryptionResult.encrypted,
      senderKey
    };
    const exchangeKeyResponse: ExchangeRoomKeyResponseEventDTO<true> = {
      type: EncryptionType.ENCRYPTED,
      eventType: ToUsersEventType.EXCHANGE_ROOM_KEY_RESPONSE,
      content,
      senderUserId: this.senderUserId,
    };
    const data: SendToUsersEventPayloadData = {
      payload: exchangeKeyResponse,
      deviceId: requestingDeviceId,
      userId: this.originalEvent.senderUserId
    };
    return data;
  }
}

@Injectable()
export class ExchangeSessionKeysEncryptor {

  constructor(
    private chatClient: WenChatClient,
    private olmDevice: OlmDevice,
    private oAuthService: WenOAuthService,
  ) { }

  encryptSessionKeysForExchange(event: ExchangeRoomKeyRequestEventDTO) {
    const { content, senderUserId } = event;
    const { requestingDeviceId } = content;
    const params = event.content.sessions.map(session => {
      const param: GetInboundGroupSessionParams = {
        senderCurve25519: session.senderKey,
        sessionId: session.sessionId
      };
      return param;
    });
    const result$ = this.chatClient.exportInboundGroupSessions(params).pipe(
      switchMap((exportedSession) => {
        if (!exportedSession?.length) {
          return of(null as OlmEncryptionResult);
        }
        return this.chatClient.encryptMessageOlm(senderUserId, JSON.stringify(exportedSession), requestingDeviceId).pipe(
          map((encryptionResults => encryptionResults[0])),
          catchError((error) => {
            return of(null as OlmEncryptionResult);
          })
        );
      }),
      map((encryptionResult) => {
        const { userId } = this.oAuthService.getUserData();
        const e2eKeys = this.olmDevice.getIdentityKeys();
        return new EncryptedExchangeSessionKeysEventResult(
          encryptionResult, event, e2eKeys.curve25519, userId
        );
      })
    );
    return result$;
  }

}
