import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, first, map, ReplaySubject, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { loadRoomDetails } from '../../../../core/store/chat/chat.actions';
import { selectCurrentRoom, selectRoomById, selectUserChatListIsLoaded } from '../../../../core/store/chat/chat.selectors';
import { ChatRoomEntity } from '../../../../core/store/chat/chat.state';
import { RootState } from '../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../core/store/root/root.selectors';
import { chatViewIdentifier } from '../../tokens';
import { LoadingState } from '../../../../core/common/types/store-loading-state';

@Injectable()
export class ChatViewPreloaderGuard {

  private store = inject(Store<RootState>);
  private actions$ = inject(Actions);
  private navigationHelper = inject(WenNavigationHelper);

  isChatHistoryLoaded$ = this.store.pipe(
    select(selectRouteParam(chatViewIdentifier)),
    distinctUntilChanged(),
    switchMap((roomId: string) => {
      return this.store.pipe(
        selectorWithParam(selectRoomById, roomId),
        map((roomEntity) => Boolean(roomEntity?.history)),
        filter(hasHistory => hasHistory),
      );
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isChatDetailsLoaded$ = this.store.pipe(
    select(selectCurrentRoom),
    map((room) => {
      return Boolean(room?.id);
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  ensureCurrentChatDetailsLoaded() {
    const details$ = new ReplaySubject<ChatRoomEntity>(1);
    this.store.pipe(
      withLatestFrom(this.store.pipe(select(selectUserChatListIsLoaded))),
      filter(([_, isLoaded]) => isLoaded),
      withLatestFrom(this.store.pipe(select(selectRouteParam(chatViewIdentifier)))),
      first(([_, roomId]) => Boolean(roomId)),
      switchMap(([_, roomId]) => {
        this.store.dispatch(loadRoomDetails({ roomId }));
        return this.store.pipe(
          selectorWithParam(selectRoomById, roomId),
          tap(room => {
            if (!room) {
              this.navigationHelper.navigateToChatList();
            }
          }),
          filter(room => room && room.loadingState === LoadingState.LOADED),
          first()
        );
      }),
      first()
    ).subscribe((room) => {
      details$.next(room);
    });
    return details$.pipe(first());
  }

}
