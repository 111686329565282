import { Injectable, OnDestroy } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { filterBy } from '../../../../../core/common/operators/fitler-by';
import { createTimer } from '../../../../../core/common/util/create-timer';

const REGISTRATION_CODE_COOLDOWN_SEC = 60;

@Injectable()
export class RegistrationCodeResendService implements OnDestroy {

  private onDestroy = new Subject<void>();
  private disable = new Subject<void>();
  private resend = new Subject<string>();

  public readonly remaining$ = this.disable.pipe(
    switchMap(() => createTimer(REGISTRATION_CODE_COOLDOWN_SEC)),
    startWith(0),
    shareReplay(1)
  );
  public readonly isDisabled$ = this.remaining$.pipe(
    map((remaining) => remaining > 0),
    shareReplay(1)
  );

  constructor(
    private socketIoService: SocketIoService,
  ) {
    this.resend.pipe(
      filterBy(() => this.isDisabled$, false),
      takeUntil(this.onDestroy)
    ).subscribe((phoneNumber) => {
      this.socketIoService.user.requestCode.emit({ identifier: phoneNumber });
      this.disable.next();
    });
  }

  tryResend(phoneNumber: string) {
    this.resend.next(phoneNumber);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}
