import { Injectable, NgZone, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { OverlayManager } from '@portal/wen-components';
import { WenNativeApi, WenNativeApiType } from '@portal/wen-native-api';
import { Remote } from 'comlink';
import { concatMap, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { doBackNavigation } from '../../store/header/header.actions';
import { RootState } from '../../store/root/public-api';
import { NativeConfigurationService } from '../configuration/native-configuration';
import { NativeNotificationHandler } from '../native-notification/native-notification-handler';
import { DeepLinkHandler } from '../navigation/deep-link/deep-link-handler';
import { ShareMenuHandler } from '../navigation/share-menu/share-menu-handler';
import { ReactNativeWrapper } from './react-native-wrapper';

@Injectable()
export class NativeConnector {

  constructor(
    private store: Store<RootState>,
    private wenNativeApi: WenNativeApi,
    private notificationHandler: NativeNotificationHandler,
    private shareMenuHandler: ShareMenuHandler,
    private deepLinkHander: DeepLinkHandler,
    private ngZone: NgZone,
    private overlayManager: OverlayManager,
    private nativeConfiguration: NativeConfigurationService,
    @Optional() private reactNativeWrapper: ReactNativeWrapper,
  ) { }

  connect() {
    if (this.reactNativeWrapper) {
      this.reactNativeWrapper.backPress$.subscribe(() => {
        this.ngZone.run(() => {
          if (this.overlayManager.hasOpenDialogs()) {
            this.overlayManager.closeAllDialogs();
            return;
          }
          this.store.dispatch(doBackNavigation());
        });
      });
      this.reactNativeWrapper.notificationReceived$.subscribe((rawNotificationData) => {
        this.notificationHandler.handleNotification(rawNotificationData);
      });
      this.reactNativeWrapper.shareMenuDataReceived$.subscribe(data => {
        this.shareMenuHandler.processShareData(data);
      });
      this.reactNativeWrapper.deepLinkChange$.pipe(
        concatMap((deepLink) => {
          if (this.nativeConfiguration.isSSoIos()) {
            /**
             * Wait between deep link changes on IOS because the SSO browser might be still open
             *  and in that state the app in the background is not able to respond to any navigation
             */
            return of(deepLink).pipe(
              delay(500)
            );
          }
          return of(deepLink);
        })
      ).subscribe((deepLinkChange) => {
        this.deepLinkHander.handleDeepLink(deepLinkChange);
      });
      return this.wenNativeApi.init(
        this.reactNativeWrapper.bridge.getNativeApi() as unknown as Remote<WenNativeApiType>,
        this.reactNativeWrapper.keyboardDidShow$,
        this.reactNativeWrapper.keyboardDidHide$,
        this.reactNativeWrapper.connectivityChange$
      );
    }
  }
}
