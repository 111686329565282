import { createAction, props } from '@ngrx/store';
import { AuthErrorResult, AuthErrorResultType } from '@portal/wen-embed-api';
import { AuthResult } from '@portal/wen-embed-api';

export const loginCurrentEmbeddedApp = createAction(
  '[EmbeddedApi.Auth] Initiate sso login process for current embedded app',
  props<{ clientId: string }>()
);

export const handleLoginCallbackEmbeddedApp = createAction(
  '[EmbeddedApi.Auth] The sso login process callback arrived for an embedded app',
  props<{ appId: string; code: string; error?: AuthErrorResultType }>()
);

export const loginCurrentEmbeddedAppResult = createAction(
  '[EmbeddedApi.Auth] Result of the sso login process for the latest open embedded app',
  props<{ result: AuthResult; error?: AuthErrorResult }>()
);
