import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { dialogOutletProviders } from '../../../core/services/navigation/outlet-specific/outlet-providers';
import { WenDesktopRouteDataResolver } from '../../../core/services/navigation/route-data/desktop-route-data-resolver';
import { HeaderCommand } from '../../../core/store/header/models/HeaderCommand';
import { SearchConfig } from '../../../core/store/header/models/HeaderData';
import { RootState } from '../../../core/store/root/public-api';
import { selectOutletIds } from '../../../core/store/root/root.selectors';

@Component({
  selector: 'wen-route-dialog',
  templateUrl: './route-dialog.component.html',
  styleUrls: ['./route-dialog.component.scss'],
  providers: [
    ...dialogOutletProviders
  ]
})
export class RouteDialogComponent implements OnInit {

  headerGravity$: Observable<string>;
  isHeaderVisible$: Observable<boolean>;
  isFooterVisible$: Observable<boolean>;
  headerLeftCommand$: Observable<HeaderCommand>;
  headerRightCommand$: Observable<HeaderCommand>;
  headerTitle$: Observable<string>;
  headerSubTitle$: Observable<string>;
  footerLeftCommand$: Observable<HeaderCommand>;
  footerRightCommand$: Observable<HeaderCommand>;
  footerTitle$: Observable<string>;
  footerSubTitle$: Observable<string>;
  searchConfig$: Observable<SearchConfig>;

  constructor(
    private store: Store<RootState>,
    private readonly desktopRouteDataResolver: WenDesktopRouteDataResolver,
  ) { }

  ngOnInit(): void {
    const data$ = this.store.pipe(
      select(selectOutletIds),
      map((outletIds) => {
        const { dialogId } = outletIds;
        if (!dialogId) {
          return null;
        }
        return this.desktopRouteDataResolver.getDesktopDialogHeaderData(dialogId);
      }),
      filter(data => Boolean(data)),
      shareReplay(1)
    );

    this.isHeaderVisible$ = data$.pipe(map(data => Boolean(data.headerData)));
    this.isFooterVisible$ = data$.pipe(map(data => Boolean(data.footerData)));

    this.headerLeftCommand$ = data$.pipe(
      map(data => data.headerData?.leftCommand)
    );
    this.headerRightCommand$ = data$.pipe(
      map(data => data.headerData?.rightCommand)
    );
    this.headerTitle$ = data$.pipe(
      map(data => data.headerData?.title)
    );
    this.headerSubTitle$ = data$.pipe(
      map(data => data.headerData?.subTitle)
    );
    this.searchConfig$ = data$.pipe(
      map(data => data.headerData?.searchConfig)
    );

    this.footerLeftCommand$ = data$.pipe(
      map(data => data.footerData?.leftCommand)
    );
    this.footerRightCommand$ = data$.pipe(
      map(data => data.footerData?.rightCommand)
    );
    this.footerTitle$ = data$.pipe(
      map(data => data.footerData?.title)
    );
    this.footerSubTitle$ = data$.pipe(
      map(data => data.footerData?.subTitle)
    );
  }

}
