import { ANIMATION_MODULE_TYPE, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule } from '@angular/material/checkbox';
import { ListMassDataViewerModule } from '@portal/wen-components';
import { MassDataContainer } from '@portal/wen-data-core';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { ListMassDataViewerContainerConnector } from '../../../../../core/services/mass-data/list-mass-data-viewer-container-connector';
import { SharedModule } from '../../../../../shared/shared.module';
import { ToUserListItemPipe } from './pipes/to-user-list-item.pipe';
import { ChatCreationListItemModel } from './providers/chat-selection-list-container-connector';
import { ChatSelectionListFilterProvider } from './providers/chat-selection-list-providers';
import { ChatSelectionListService } from './providers/chat-selection-list-separators';

@Component({
  selector: 'wen-chat-selection-list',
  templateUrl: './chat-selection-list.component.html',
  styleUrls: ['./chat-selection-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ChatSelectionListService,
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        clickAction: 'noop'
      } as MatCheckboxDefaultOptions
    },
    {
      provide: ANIMATION_MODULE_TYPE,
      useValue: 'NoopAnimations'
    }
  ],
  standalone: true,
  imports: [
    SharedModule,
    ListMassDataViewerModule,
    MatCheckboxModule,
    ToUserListItemPipe,
  ]
})
export class ChatSelectionListComponent<T extends MassDataContainer<any>> implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  @Input() selectionModeEnabled = false;
  @Input() alwaysDisabledIds: string[] = [];

  @Output() listItemClicked = new EventEmitter<ChatCreationListItemModel>();

  constructor(
    public listDatasource: ListMassDataViewerContainerConnector<T>,
    public listService: ChatSelectionListService,
    public filterProvider: ChatSelectionListFilterProvider,
  ) { }

  ngOnInit(): void {
    this.filterProvider.searchTerm$.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.listDatasource.fetchInitialPage();
    });
  }

  handleListItemClick(item: ChatCreationListItemModel) {
    this.listItemClicked.emit(item);
  }

  isItemDisabled(item: ChatCreationListItemModel) {
    return this.alwaysDisabledIds.includes(item.listItemEntity.id) || !this.selectionModeEnabled && item.selected;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
