import { Injectable } from '@angular/core';
import { ActionMenuItem } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { createAndNavigateToDialogChat } from '../../../../core/store/chat/chat.actions';
import { Store } from '@ngrx/store';
import { ChallengeData } from '../challenge-context-menu/challenge-user-data-info';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { PermissionLevel } from '../../../../core/services/user-management/permission-level';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';

@Injectable()
export class ChallengeAskAuthorAction implements ActionMenuItem {
  label: string;
  icon = 'contact_author';
  isDisabled: boolean;
  iconColor: string;

  constructor(
    private translateService: TranslateService,
    private store: Store,
    private featureEnablementService: FeatureEnablementService)
  {
    this.label = this.translateService.instant('CHALLENGE_ASK_AUTHOR');
  }

  isVisible$ = (() => {
    return this.store.select(selectCurrentUserData).pipe(
      map(userData =>
        userData.permissionLevel === PermissionLevel.REGISTERED_USER && this.featureEnablementService.isChatEnabled()
      )
    );
  });

  execute(challengeData: ChallengeData): void {
    this.store.dispatch(createAndNavigateToDialogChat({userId: challengeData.authorUserId}));
  }
}
