import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FormStoreKey } from '../../../shared/form-store/form-store-types';
import { RootState } from '../root/public-api';
import { formFeatureKey } from './constants';
import { FormState } from './form.state';

export interface WithFeatureState extends RootState {
  [formFeatureKey]: FormState;
}

export const selectFormState = createFeatureSelector<FormState>(
  formFeatureKey
);

export const selectAllEditForms = createSelector(
  selectFormState,
  (state) => {
    const entities = state?.editedForm.entities;
    return entities;
  }
);

export const selectEditFormById = (id: FormStoreKey) => createSelector(
  selectAllEditForms,
  (entities) => {
    const entity = entities && entities[id];
    return entity;
  }
);

export const selectEditFormChangedValues = (id: FormStoreKey) => createSelector(
  selectFormState,
  (state) => {
    return state?.editedForm?.entities[id]?.changedValues;
  }
);

export const selectEditFormValues = createSelector(
  selectAllEditForms,
  (entities) => (id: FormStoreKey) => {
    const entity = entities && entities[id];
    if (!entity) {
      return null;
    }
    const { changedValues, initialValues } = entity;
    return { changedValues, initialValues };
  }
);
