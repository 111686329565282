import { Directive, ElementRef, HostListener, Input } from '@angular/core';

const MAX_RETRIES = 3;
const WAIT_BETWEEN_IMAGE_RETRY_ATTEMPTS = 500;

@Directive({
  selector: '[retry-image-load]',
})
export class RetryImageLoadDirective {
  private retry = 0;
  private originalDisplay: string;
  @Input('retry-image-load') errorCallback = () => {};

  constructor(
    private imageElement: ElementRef<HTMLImageElement>
  ){
    this.originalDisplay = imageElement.nativeElement.style.display;
  }

  @HostListener('error')
  onError() {
    const imageElement = this.imageElement.nativeElement;
    imageElement.style.display = 'none';
    if (this.retry < MAX_RETRIES) {
      setTimeout(() => {
        this.retry++;
        imageElement.src = imageElement.src;
      },
        WAIT_BETWEEN_IMAGE_RETRY_ATTEMPTS);
    } else {
      this.onFinalError();
    }
  }

  onFinalError() {
    if (this.errorCallback) {
      this.errorCallback();
    }
  }

  @HostListener('load')
  onSuccessfullLoad() {
    const imageElement = this.imageElement.nativeElement;
    imageElement.style.display = this.originalDisplay;
  }
}
