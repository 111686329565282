import { Inject, Injectable } from '@angular/core';
import { IDBPDatabase, deleteDB, openDB } from '@tempfix/idb';
import { Subject, animationFrameScheduler, filter, from, map, observeOn, switchMap, withLatestFrom } from 'rxjs';
import { STORE_PERSISTENCE_CONFIG, StorePersistenceConfig } from '../store-persistence.tokens';

@Injectable()
export class PersistenceStorage {

  private IDB_DB_NAME = 'wen-persistence:store';
  private IDB_OBJECT_STORE_NAME = 'app_data';
  private IDB_DATA_KEY = 'stored_data';

  private saveDataSubject = new Subject<any>();

  private openDbPromise: Promise<IDBPDatabase>;

  constructor(
    @Inject(STORE_PERSISTENCE_CONFIG) private readonly config: StorePersistenceConfig
  ) {
    this.openDbPromise = openDB(this.IDB_DB_NAME, this.config.version, {
      upgrade: (database, oldVersion, newVersion) => {
        if (newVersion !== oldVersion) {
          this.purgeObjectStores(database);
        }
        this.createObjectStore(database, this.IDB_OBJECT_STORE_NAME);
      }
    });
    this.saveDataSubject.pipe(
      observeOn(animationFrameScheduler),
      filter(() => Boolean(this.openDbPromise)),
      withLatestFrom(from(this.openDbPromise))
    ).subscribe(([data, database]) => {
      this.saveStateSlice(database, data, this.IDB_OBJECT_STORE_NAME);
    });
  }

  private rehydrateInitialState(database: IDBPDatabase, objectStoreKey: string) {
    const store = database
      .transaction(objectStoreKey, 'readonly')
      .objectStore(objectStoreKey);
    const result = store.get(this.IDB_DATA_KEY);
    return from(result);
  }

  private createObjectStore(database: IDBPDatabase, objectStoreKey: string) {
    if (!database.objectStoreNames.contains(objectStoreKey)) {
      database.createObjectStore(objectStoreKey);
    }
  }

  private saveStateSlice(database: IDBPDatabase, newState: any, objectStoreKey: string) {
    const store = database
      .transaction(objectStoreKey, 'readwrite')
      .objectStore(objectStoreKey);
    const result = store.put(newState, this.IDB_DATA_KEY);
    return result;
  }

  private purgeObjectStores(database: IDBPDatabase) {
    while (database.objectStoreNames.length > 0) {
      const objectStore = database.objectStoreNames.item(0);
      database.deleteObjectStore(objectStore);
    }
  }

  restoreData() {
    const result$ = from(this.openDbPromise).pipe(
      switchMap((database: any) => {
        const storedDataEntries$ = this.rehydrateInitialState(database, this.IDB_OBJECT_STORE_NAME);
        return storedDataEntries$;
      }),
      map(storedState => {
        if (!storedState) {
          return null;
        }
        return storedState;
      })
    );
    return result$;
  }

  scheduleStateBackup(data: any) {
    this.saveDataSubject.next(data);
  }

  clearData() {
    return from(this.openDbPromise).pipe(
      switchMap(database => {
        database.close(); // TODO: concurrency, handle saves after close etc.
        this.openDbPromise = null;
        return from(deleteDB(this.IDB_DB_NAME));
      })
    );
  }

}
