import { Injectable } from '@angular/core';
import { ActionMenuItem, WenSnackbarOpener } from '@portal/wen-components';
import { ChatListItemModel } from '../chat-list-item.component';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../../../core/store/root/public-api';
import { pinItem, unPinItem } from '../../../../../../core/store/pin/pin.actions';
import { DataContext } from '../../../../../../core/common/types/data-context';

@Injectable()
export class ChatPinAction implements ActionMenuItem {

  private isPinned: boolean;

  label: string;
  icon: string;

  execute(chatListItemModel: ChatListItemModel): void {
    const action = !!chatListItemModel.pinTimestamp ?
      unPinItem({ id: chatListItemModel.id }) : pinItem({ contextId: chatListItemModel.id, contextType: DataContext.CHAT });
    this.store.dispatch(action);
    this.showSnackbarMessage();
  }

  isVisible$ = (chatListItemModel: ChatListItemModel) => {
    this.isPinned = !!chatListItemModel.pinTimestamp;
    this.icon = this.isPinned ? 'unpin' : 'pin';
    this.label = this.translateService.instant(
      this.isPinned ? 'UNPIN_BUTTON_LABEL' : 'PIN_BUTTON_LABEL'
    );
    return of(true);
  };

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>,
    private wenSnackbarOpener: WenSnackbarOpener
  ) { }

  private showSnackbarMessage() {
    const notificationText = this.translateService.instant(this.isPinned ? 'CHAT_UNPIN_NOTIFICATION' : 'CHAT_PIN_NOTIFICATION');
    const notificationIcon = this.isPinned ? 'unpin': 'pin';
    this.wenSnackbarOpener.openNotificationSnackbar({ notificationIcon, notificationText });
  }
}
