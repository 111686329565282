import { Injectable } from '@angular/core';
import { channelViewIdentifier } from '../../../../../views/channel/tokens';
import { matchFirstRegExpGroup } from '../../../../common/util/match-first-regexp';
import { channelMatcher } from '../deep-link-regexes';
import { DeepLinkable } from '../deep-linkable';

@Injectable()
export class ChannelDeepLinkable extends DeepLinkable {

  isMatchForUrl(link: string) {
    return Boolean(this.extractRouteLink(link));
  }

  extractRouteLink(link: string) {
    const match = matchFirstRegExpGroup(link, channelMatcher());
    return match;
  }

  getDeepLink() {
    const outletParam = this.extractOutletParam(channelViewIdentifier);
    if (!outletParam) {
      return null;
    }
    return `${this.rootUrl}/channel/${outletParam}`;
  }

  getOpenerTranslation(): string {
    return 'EMBED_OPEN_CHANNEL_LINK';
  }

}
