/**
 * Return 2 set of items:
 *  - which fulfill the given condition
 *  - which doesnt fulfill the given condition
 */
export const partitionArray = <T>(arr: T[], condition: (item: T) => boolean): [T[], T[]] => {
    const fulfill: T[] = [];
    const notFulfill = arr.filter(item => {
      const isFulfill = condition(item);
      if (isFulfill) {
        fulfill.push(item);
        return false;
      }
      return true;
    });
    return [fulfill, notFulfill];
  };

export const uniqueArrayByKey = <T>(array: T[], key: string): T[] => {
  return [...new Map(array.map(item => [item[key], item])).values()];
};
