import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { AppDetailModule } from './app-detail/app-detail.module';
import { AppEditModule } from './app-detail/components/app-edit/app-edit.module';
import { AppExploreModule } from './app-explore/app-explore.module';
import { AppListModule } from './app-list/app-list.module';

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    AppListModule,
    AppDetailModule,
    AppEditModule,
    AppExploreModule
  ]
})
export class AppsModule { }
