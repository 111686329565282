
import { Injectable } from '@angular/core';
import { ListEventRequestParameters } from '@portal/wen-backend-api';
import { DateTime } from 'luxon';
import { Filterable, hasFilterString } from '../../../../shared/components/filter-view/models/filter-item';
import { groupBy } from '../../../common/util/group-by';
import { SearchFilterId } from '../../header/models/HeaderData';
import { Filter, FilterType } from '../models/filter';
import { createRSQLFilterString } from '../utils/rsql-utils';
import { DateRangeConverter } from './date-range-converter.service';

@Injectable({ providedIn: 'root' })
export class FilterHelperService {

  constructor(private dateRangeConverter: DateRangeConverter) { }

  /**
   * Currently, on desktop it can happen that there are search fields available while the context still remains.
   * Therefore these values should be filtered out as well.
   */
  removeUserSearchFilters(activeFilters: Filter[]) {
    return activeFilters.filter(f => {
      return f.filterId !== SearchFilterId.ADMIN_SEARCH && f.filterId !== SearchFilterId.USER_SEARCH;
    });
  }

  convertFiltersToRequestParameters(activeFilters: Filter[]): ListEventRequestParameters {
    if (!activeFilters?.length) {
      return null;
    }
    const groups = groupBy(activeFilters, (fE) => hasFilterString(fE.filterValue) || typeof fE.filterValue === 'string');
    const requestParams = Array.from(groups).reduce((allRequestParams, [isRSQLCompatibleFilters, filters]) => {
      let currentFilter: ListEventRequestParameters;
      if (isRSQLCompatibleFilters) {
        currentFilter = { filter: createRSQLFilterString(filters) };
      } else {
        currentFilter = this.convertDataOf(filters);
      }
      return { ...allRequestParams, ...currentFilter };
    }, {} as ListEventRequestParameters);
    return requestParams;
  }

  private convertDataOf(filters: Filter[]): Partial<ListEventRequestParameters> {
    return filters.reduce((dataBasedRequestProps, currentFilter) => {
      let params = {};
      switch (currentFilter.filterType) {
        case FilterType.SELECT:
          const data = (currentFilter.filterValue as Filterable<DateTime[]>)?.data;
          if (Array.isArray(data)) {
            params = this.dateRangeConverter.convertRangeToRequestParameters(data);
          } else {
            params = currentFilter?.filterValue.data;
          }
          break;
        default:
          return params;
      }
      return { ...dataBasedRequestProps, ...params };
    }, {} as ListEventRequestParameters);
  }

}
