<ng-container>
  <div class="wen-explore-header">
    <div class="wen-explore-header-label">
      {{ 'EVENT_EXPLORE_LABEL' | translate }}
      <span portal-subheading-1 class="wen-title-suffix">{{'EVENT_EXPLORE_TITLE_SUFFIX' | translate }}</span>
    </div>
    <div class="wen-explore-header-event-list-user-events-button" portal-body-2 wen-primary-color
      (click)="navigateToUserEventList()" [wen-test-id]="'event-explore-user-list-button'">
      <wen-icon [iconName]="'favorite_active'" class="wen-icon-small"></wen-icon>
      <div>{{ 'USER_EVENTS_BUTTON_LABEL' | translate }}</div>
    </div>
  </div>
</ng-container>

<div class="quick-filters">
  <wen-rounded-button transparent border (click)="navigateToEventDiscovery('clearFilter')" section-content
  [wen-test-id]="'event-explore-discover-list-button'">{{"SHOW_ALL_EVENTS_LABEL" | translate}}</wen-rounded-button>
</div>
<cdk-virtual-scroll-viewport class="wen-section-wrapper" itemSize="375" scrollStateHandler>
  @for (section of sections$ | async; track section) {
    <wen-event-section [section]="section"
    (showAll)="navigateToEventDiscovery($event)"></wen-event-section>
  }
</cdk-virtual-scroll-viewport>