import { Injectable } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { Observable, of, switchMap } from 'rxjs';

@Injectable()
export class ChatAvailabilityChecker {

  constructor(
    private socketIoService: SocketIoService,
  ) { }

  checkChatAvailabilityWithUsers(
    userIds: string[],
  ): Observable<string[]> {
    return this.socketIoService.chat.createChatRoomValidateUsers.acknowledgement$({ userIds }).pipe(
      switchMap(responses => {
        const unavailableUserIds = Object.keys(responses);
        if (unavailableUserIds?.length) {
          return of(unavailableUserIds);
        }
        return of([]);
      })
    );
  }

}
