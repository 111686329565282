<div class="wen-profile-scroller" cdkScrollable>
  @if (headerData$ | async; as headerData) {
    <div class="wen-profile-header">
      <wen-detail-header [model]="headerData" [coverImageStyle]="'circle'" [allowLoadingIndicator]="true"
        [shouldAnimate]="false">
        <div wen-detail-header-title>{{ headerData?.title }}</div>
        @if (showHint$ | async) {
          <div wen-detail-header-hint>
            {{ "CONTACTS_ACCEPT_RECEIVED_HINT_LABEL" | translate: {displayName: headerData?.title} }}
          </div>
        }
        @if (showActions$ | async) {
          <div wen-detail-header-additional-content>
            <ng-container *ngTemplateOutlet="connectionActions; context: { $implicit: profileData.profile$ | async }"></ng-container>
          </div>
        }
      </wen-detail-header>
    </div>
  }
  @if (additionalInformation$ | async; as additionalInformation) {
    <div class="wen-profile-additional-information-box">
      <div portal-caption>{{ "PROFILE_FORM_ABOUT_ME_LABEL" | translate }}</div>
      <wen-expandable-container maxHeight="100">
        <wen-markdown-text portal-body [content]="additionalInformation"></wen-markdown-text>
      </wen-expandable-container>
    </div>
  }
</div>

<ng-template #connectionActions let-profile>
  <div class="wen-other-profile-connection-additional-actions-wrapper">
    @if (enableContacts$ | async) {
      <wen-contact-response-action [profileData]="responseActionData$ | async"></wen-contact-response-action>
    }
    @if (conditions.canInitiateChat$ | async) {
      <wen-button class="wen-button-rounded" wenVariant="secondary"
        iconName="message_inactive" iconSize="huge" (click)="actions.initiateChatWithUser(profile)">
        <span portal-title>{{ "CONTACTS_ACTION_SEND_MESSAGE" | translate }}</span>
      </wen-button>
    }
  </div>
</ng-template>