import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { FormStoreModule } from '../../../shared/form-store/form-store.module';
import { SharedModule } from '../../../shared/shared.module';
import { InviteCommonModule } from '../common/invite-common.module';
import { InviteToChannelSelectorComponent } from './components/invite-to-channel-selector/invite-to-channel-selector.component';
import { InviteToChannelComponent } from './components/invite-to-channel/invite-to-channel.component';
import { InviteToChannelResolver } from './guards/invite-to-channel.resolver';

@NgModule({
  imports: [
    SharedModule,
    PortalFieldModule,
    FormStoreModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    InviteCommonModule,
  ],
  declarations: [
    InviteToChannelSelectorComponent,
    InviteToChannelComponent,
  ],
  providers: [
    InviteToChannelResolver
  ],
  exports: [
    InviteToChannelSelectorComponent,
    InviteToChannelComponent,
  ]
})
export class InviteToChannelModule { }
