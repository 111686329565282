@if (isWeLocalPlayerNeeded) {
  <iframe [wenLazyload]="sourceUrl" allowfullscreen></iframe>
} @else {
  @if (canUseNewVideoPlayer) {
    <div class="wen-embed-overlay" (tap)="onMediaClick()">
      <wen-button class="wen-button-round" wenVariant="icon" iconName="play" iconSize="huge"></wen-button>
    </div>
    <img #image [wenLazyload]="thumbnail$ | async" retry-image-load/>
  } @else {
    <video [poster]="thumbnail$ | async" preload="metadata" controls playsInline>
      <source [src]="sourceUrl" />
    </video>
  }
}


