import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { combineLatest, EMPTY, filter, map, Observable, of, shareReplay, switchMap, zip } from 'rxjs';
import { DateUtil } from '../../../../../core/common/date/date-util';
import { LoadResultMode } from '../../../../../core/common/types/misc';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { selectAllRooms, selectUserChatListIsLoaded } from '../../../../../core/store/chat/chat.selectors';
import { selectSearchTerm } from '../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../core/store/filter/models/filter';
import { selectLatestMessageForChat } from '../../../../../core/store/notification/notification.selectors';
import { selectPinItem } from '../../../../../core/store/pin/pin.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { ChatLoadResult } from '../../models/chat-load-result';
import { ChatListItemModel } from '../chat-list-item/chat-list-item.component';

@Injectable()
export class UserChatListDataSource {

  public loadResult$: Observable<ChatLoadResult> = EMPTY;

  constructor(private store: Store<RootState>) {
    const chatList$: Observable<Partial<ChatListItemModel[]>> = this.store.pipe(
      select(selectUserChatListIsLoaded),
      filter(isLoaded => isLoaded),
      switchMap(() => this.store.pipe(
        select(selectAllRooms),
        smartDistinctUntilChanged(),
        shareReplay(1),
      )),
      map(rooms => rooms.map(room => {
        return {
          id: room.id,
          title: room.details.title,
          icon: room.details.icon,
          roomType: room.details.type,
          timestamp: room.details.timestamp,
          lastReadTimestamp: room.lastReadTimestamp,
          lastReceivedTimestamp: room.lastReceivedTimestamp,
          muteIcon: room.details.isMuted ? 'mute' : null,
          isMuted: room.details.isMuted
        };
      }))
    );

    const searchTerm$ = this.store.pipe(
      selectorWithParam(selectSearchTerm, FilterEntityType.CHAT_LISTS),
      shareReplay(1)
    );

    const onLoadedContent$ = searchTerm$.pipe(
      switchMap((searchTerm) => chatList$.pipe(
        map((chatListItems) => this.filterChats(chatListItems, searchTerm)),
        switchMap(rooms => {
          const lastMessages$ = rooms.map(room => {
            return combineLatest([
              this.store.pipe(selectorWithParam(selectLatestMessageForChat, room.id)),
              this.store.pipe(selectorWithParam(selectPinItem, room.id)),
            ]).pipe(
              map(([lastMessage, pinItem]) => {
                return {
                  ...room,
                  ...!!lastMessage && {
                    lastMessage: {
                      decryptionError: lastMessage?.decryptionError,
                      eventId: lastMessage.id,
                      messageContent: lastMessage.decryptedEvent.message,
                    }
                  },
                  pinTimestamp: pinItem?.timestamp
                };
              })
            );
          });
          if (lastMessages$.length < 1) {
            return of(rooms);
          }
          return zip(lastMessages$).pipe(
            map(roomsWithLastMessage => {
              return roomsWithLastMessage.sort((item1, item2) => {
                const room1Timestamp = item1.lastMessage?.messageContent.timestamp || item1?.timestamp;
                const room2Timestamp = item2.lastMessage?.messageContent.timestamp || item2?.timestamp;
                return DateUtil.compareNullSafe(item1.pinTimestamp, item2.pinTimestamp) ||
                  DateUtil.compareNullSafe(room1Timestamp, room2Timestamp);
              });
            })
          );
        }),
        map((chatRooms) => {
          return {
            mode: searchTerm?.length ? LoadResultMode.SEARCH : LoadResultMode.NORMAL,
            data: chatRooms
          };
        })
      ))
    );
    this.loadResult$ = this.store.pipe(
      select(selectUserChatListIsLoaded),
      switchMap((isLoaded) => {
        if (isLoaded) {
          return onLoadedContent$;
        }
        return of({
          mode: LoadResultMode.NORMAL,
          data: []
        });
      })
    );
  }

  private filterChats(chats: ChatListItemModel[], searchTerm: string): ChatListItemModel[] {
    if (!searchTerm) {
      return chats;
    }
    return chats.filter(chat => chat?.title?.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase()));
  }

}
