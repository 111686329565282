import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { filter, first, of, switchMap } from 'rxjs';
import { SocketIoService } from '@portal/wen-backend-api';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { DataObjectType } from '../../common/data-objects';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { clearFormValues } from '../form.actions';
import { selectEditFormById } from '../form.selectors';
import { ChatReplyEditFormChangedValues } from '../types/chat-reply-edit.types';

@Injectable()
export class AutoReplyEditFormEffects {

    addGroupChatMembers$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
        filter(action => action.dataObjectType === DataObjectType.AUTO_REPLY),
        switchMap(({ formId }) => this.store.pipe(
            select(selectEditFormById(formId)),
            first()
        )),
        switchMap((editForm) => {
            const { formId, initialValues, changedValues } = editForm;
            const actions: Action[] = [];

            const initValuesTyped = initialValues as ChatReplyEditFormChangedValues;
            const changedValuesTyped = changedValues as ChatReplyEditFormChangedValues;
            const mergedValues = { ...initValuesTyped, ...changedValuesTyped };
            const enabled = mergedValues.enabled;
            const replyMessage = mergedValues.replyMessage;
            this.socketIoService.chat.updateAutoReply.emit({ enabled, replyMessage });
            actions.push(clearFormValues({ formId }));
            actions.push(doBackNavigation());
            return actions;
        }),
    ));

    constructor(
        private formStoreMediator: FormStoreMediator,
        private store: Store<RootState>,
        private socketIoService: SocketIoService
    ) { }
}
