import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';

export enum FilterViewType {
  DATE_RANGE = 'date_range'
}

export interface CustomFilterView {
  type: FilterViewType;
  view: ComponentType<any>;
}

export const CUSTOM_FILTER_VIEW = new InjectionToken<CustomFilterView>('CUSTOM_FILTER_VIEW');
