import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { selectCurrentEvent } from '../../../../../../core/store/events/event.selectors';
import { OccurenceEntity } from '../../../../../../core/store/events/event.state';
import { RootState } from '../../../../../../core/store/root/public-api';

@Component({
  selector: 'wen-event-list-item',
  templateUrl: './event-list-item.component.html',
  styleUrls: ['./event-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventListItemComponent {
  location: string;
  isSelected$: Observable<boolean>;
  private innerEvent: OccurenceEntity;

  @Input()
  set event(v: OccurenceEntity) {
    this.innerEvent = v;
    this.location = this.getLocation();
    this.isSelected$ = this.store.select(selectCurrentEvent).pipe(
      map((currentEvent) => {
        if (!currentEvent || !this.innerEvent) {
          return false;
        }

        return currentEvent.id === this.innerEvent.eventId;
      }),
      distinctUntilChanged()
    );
  }

  get event(): OccurenceEntity {
    return this.innerEvent;
  }

  private getLocation(): string {
    if (this.event.locationContact) {
      return this.event.locationContact.name;
    }

    return this.event.location;
  }

  constructor(private store: Store<RootState>) { }

}
