import { ChangeDetectionStrategy, Component, HostBinding, Injectable, Input } from '@angular/core';
import { VariantConfig } from '../../../directives/styling/variant-config';

export enum ButtonVariant {
  /**
   * (figma: btn-pri)
   * A primary button with filled style
   *  - Primary color background
   *  - White label
   */
  primary = 'primary',
  /**
   * (figma: btn-secondary)
   * A secondary button with a border and without fill
   *  - Transparent background
   *  - Primary color border
   *  - Primary color text
   */
  secondary = 'secondary',
  /**
   * (figma: btn-sec)
   * A button without border and without fill
   *  - Transparent background
   *  - No border
   *  - Primary color text
   */
  primaryBackground = 'primaryBackground',
  /**
   * (figma: btn-ic-txt)
   * A button withonly grey text
   *  - Transparent background
   *  - Grey text color
   *  - No border
   */
  iconText = 'iconText',
  /**
   * (figma: btn-icon)
   * A button with only an icon
   *  - Transparent background
   *  - No border
   */
  icon = 'icon',
  /**
   * (figma: btn-icon-primary)
   * A primary button with only an icon
   *  - Primary background
   *  - No border
   *  - White icon color
   */
  iconPrimary = 'iconPrimary'
}

type IconSize = 'tiny' | 'small' | 'medium' | 'big' | 'very-big' | 'large';
type ButtonOrientation = 'horizontal' | 'vertical';

@Injectable()
class ButtonVariantConfig extends VariantConfig {
  possibleVariants = ButtonVariant;
  defaultVariant = 'iconText';
}

@Component({
  selector: 'wen-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [
    { provide: VariantConfig, useClass: ButtonVariantConfig }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {

  @Input() iconName: string;
  @Input() iconSize: IconSize = 'small';
  @Input() isDisabled: boolean;
  @Input() iconColor: string;
  @Input() orientation: ButtonOrientation = 'horizontal';

  @HostBinding('class.wen-button-disabled') get disabledClass() {
    return this.isDisabled === true;
  }

}
