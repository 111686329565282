import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface EmbeddedAppData {
  url: string;
  embeddedApiSupported?: boolean;
}

@Injectable()
export abstract class EmbeddedUrlResolver {
  abstract resolveEmbeddedData(): Observable<EmbeddedAppData>;
}

@Injectable()
export class DefaultEmbeddedUrlResolver extends EmbeddedUrlResolver {

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  resolveEmbeddedData(): Observable<EmbeddedAppData> {
    return this.activatedRoute.queryParamMap.pipe(
      map((queryParams) => {
        return {
          url: queryParams.get('url')
        };
      })
    );
  }

}
