import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '@portal/wen-components';
import { InteractButtonModule } from '../interact-button/interact-button.module';
import { AppBannerVisibility } from './app-banner-visibility';
import { AppBannerComponent } from './app-banner.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    WenIconModule,
    InteractButtonModule,
  ],
  declarations: [
    AppBannerComponent
  ],
  providers: [
    AppBannerVisibility,
  ],
  exports: [
    AppBannerComponent
  ]
})
export class AppBannerModule { }
