import { Directive, HostBinding, Input } from '@angular/core';
import { PaletteProvider } from './palette-provider';

@Directive({
  selector: '[wenTextColorizer]'
})
export class TextColorizerDirective {

  @Input('wenTextColorizer') set seed(newSeed: string) {
    this.color = this.wenPaletteProvider.getColorFor(newSeed);
  }
  @HostBinding('style.color') color: string;

  constructor(
    private wenPaletteProvider: PaletteProvider
  ) { }

}
