<div class="wen-search-bar-desktop-container">
  <wen-search-input [inputValue]="searchTerm$ | async" (changed)="onChange($event)" (cleared)="onClear()"
  [placeholder]="searchBarPlaceholder | translate" [disabled]="disabled" (focused)="onSearchInputFocused()"></wen-search-input>
  @if (canCreateChannel$ | async) {
    <wen-icon [iconName]="'add'" (click)="navigateToChannelAdd()"></wen-icon>
  }
  @if (canCreateApp$ | async) {
    <wen-icon [iconName]="'add'" (click)="navigateToAppAdd()"></wen-icon>
  }
  @if (canCreateChat$ | async) {
    <div class="wen-chat-create-wrapper">
      @if (hasGroupChatEnabled) {
        <wen-header-command [commandData]="rightCommand"></wen-header-command>
      } @else {
        <wen-icon [iconName]="'add'" (click)="navigateToNewChat()"></wen-icon>
      }
    </div>
  }
</div>