import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WenRouteId } from '../../../frame/routing/types';
import { RootState } from '../root/public-api';
import { uiFeatureKey } from './constants';
import { uiFeature } from './ui.reducers';
import { UiState } from './ui.state';

export const {
  selectChannelMessageOriginTransferData
} = uiFeature;

export interface WithFeatureState extends RootState {
  [uiFeatureKey]: UiState;
}

export const selectUiState = createFeatureSelector<UiState>(
  uiFeatureKey
);

export const selectScrollStates = createSelector(
  selectUiState,
  (state) => state.storedScrollState
);

export const selectScrollStateFor = createSelector(
  selectUiState,
  (state) => (scrollContextId: WenRouteId) => {
    return state.storedScrollState.entities[scrollContextId];
  }
);

export const selectProgressState = createSelector(
  selectUiState,
  (state) => {
    return state.progressState;
  }
);

export const selectFeaturesConfigState = createSelector(
  selectUiState,
  (state) => {
    return state.featureConfig;
  }
);
