import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogActionHandler } from './confirmation-dialog-action-handler';

export interface ConfirmationDialogData {
  header?: string;
  headerImage?: string;
  content?: string;
  okLabel?: string;
  neutralLabel?: string;
  dismissLabel?: string;
  reasonFieldLabel?: string;
  largeReasonField?: boolean;
}

export interface ConfirmationDialogResult {
  result: 'ok' | 'dismiss' | 'neutral';
  reason?: string;
}

@Component({
  selector: 'wen-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent {


  confirmationFormGroup = new FormGroup({ reason: new FormControl('') });

  get header() {
    return this.dialogData.header;
  }

  get headerImage() {
    return this.dialogData.headerImage;
  }

  get content() {
    return this.dialogData.content;
  }

  get isTextContent() {
    return typeof this.content === 'string';
  }

  get okLabel() {
    return this.dialogData.okLabel;
  }

  get neutralLabel() {
    return this.dialogData.neutralLabel;
  }

  get dismissLabel() {
    return this.dialogData.dismissLabel;
  }

  get reasonFieldLabel() {
    return this.dialogData.reasonFieldLabel;
  }

  get reasonFieldValue() {
    return this.confirmationFormGroup.controls.reason.value;
  }

  get largeReasonField() {
    return this.dialogData.largeReasonField;
  }

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent, ConfirmationDialogResult>,
    @Inject(MAT_DIALOG_DATA) private dialogData: ConfirmationDialogData = {},
    @Optional() private actionHandler: ConfirmationDialogActionHandler,
  ) { }

  onAccept() {
    if (this.actionHandler?.onAccept) {
      this.actionHandler.onAccept(this.reasonFieldValue);
      return;
    }
    this.dialogRef.close({
      result: 'ok',
      reason: this.reasonFieldValue
    });
  }

  onNeutral() {
    if (this.actionHandler?.onNeutral) {
      this.actionHandler.onNeutral(this.reasonFieldValue);
      return;
    }
    this.dialogRef.close({
      result: 'neutral',
      reason: this.reasonFieldValue
    });
  }

  onDismiss() {
    if (this.actionHandler?.onDismiss) {
      this.actionHandler?.onDismiss();
      return;
    }
    this.dialogRef.close({
      result: 'dismiss'
    });
  }

}
