import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

export const filterBy = <T>(
  projector: () => Observable<boolean>,
  checkValue: boolean = true
) => (source: Observable<T>): Observable<T> => {
  return source.pipe(
    switchMap((originalValue) => {
      return projector().pipe(
        filter((condition) => condition === checkValue),
        first(null, !checkValue),
        map(() => originalValue),
      );
    })
  );
};
