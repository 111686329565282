import { AfterViewInit, Directive, ElementRef, forwardRef, Input, OnDestroy } from '@angular/core';
import { ResizeHandlerProvider } from '@portal/wen-components';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

export abstract class ContentBoundaryProvider {
  abstract maxWidth: number;
  abstract contentBoundary$: Observable<DOMRect>;
}

export interface MessageContentBoundaryConfig {
  maxWidth: number;
  /**
   * Provide a padding value for the content to be substracted from the available width
   */
  paddingCorrection: number;
}

@Directive({
  selector: 'wenContentBoundary, [wenContentBoundary]',
  providers: [
    {
      provide: ContentBoundaryProvider,
      useExisting: forwardRef(() => ContentBoundaryDirective)
    },
    ResizeHandlerProvider
  ]
})
export class ContentBoundaryDirective extends ContentBoundaryProvider implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private contentBoundary = new ReplaySubject<DOMRect>(1);

  @Input('wenContentBoundary') config: MessageContentBoundaryConfig;

  get maxWidth() {
    return this.config.maxWidth;
  }

  readonly contentBoundary$ = this.contentBoundary.pipe(
    map((boundary) => {
      const { maxWidth, paddingCorrection = 0 } = this.config || {};
      const minWidth = maxWidth ? Math.min(boundary.width, maxWidth) : boundary.width;
      return {
        ...boundary,
        width: minWidth - paddingCorrection
      };
    }),
    shareReplay(1),
  );

  constructor(
    private elementRef: ElementRef,
    private resizeHandlerProvider: ResizeHandlerProvider,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.updateContentBoundary();

    const resizeHandler = this.resizeHandlerProvider
      .create(this.elementRef.nativeElement);

    resizeHandler.onResize$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.updateContentBoundary());
  }

  private updateContentBoundary() {
    const element: HTMLElement = this.elementRef.nativeElement;
    const contentArea = element.getBoundingClientRect();
    this.contentBoundary.next(contentArea);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.resizeHandlerProvider.detach();
  }

}
