import { EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { findEntityById, WithMessageProps, WithId } from '../../common/entity-state-helpers/state-adapter-utils';

export type WithMessages<TMessage> = {
  id: string;
  messages?: EntityState<TMessage>;
};

export const createStateWithMessagesAdapter = <TMessageEntity extends WithId & WithMessageProps>({
  entityAdapter,
  messageEntityAdapter,
}: {
  entityAdapter: EntityAdapter<WithMessages<TMessageEntity>>;
  messageEntityAdapter: EntityAdapter<TMessageEntity>;
}) => {

  const ensureEntity = (
    entityId: string,
    entityState: EntityState<WithMessages<TMessageEntity>>,
  ) => {
    let targetEntity = findEntityById(entityId, entityState);
    if (!targetEntity) {
      targetEntity = { id: entityId, messages: messageEntityAdapter.getInitialState() };
    } else if (!targetEntity.messages) {
      targetEntity = { ...targetEntity, messages: messageEntityAdapter.getInitialState() };
    }
    return targetEntity;
  };

  return {
    upsertMany: (
      entityId: string,
      messages: TMessageEntity[],
      entityState: EntityState<WithMessages<TMessageEntity>>,
    ) => {
      const targetEntity = ensureEntity(entityId, entityState);
      const newMessages = messageEntityAdapter.upsertMany(messages, targetEntity.messages);
      const newEntities = entityAdapter.upsertOne({ ...targetEntity, messages: newMessages }, entityState);
      return newEntities;
    },

    updateMany: (
      entityId: string,
      messages: TMessageEntity[],
      entityState: EntityState<WithMessages<TMessageEntity>>,
    ) => {
      const targetEntity = ensureEntity(entityId, entityState);
      const updates: Update<TMessageEntity>[] = messages.map((message) => ({ id: message.id, changes: message }));
      const newMessages = messageEntityAdapter.updateMany(updates, targetEntity.messages);
      const newEntityState = entityAdapter.upsertOne({ ...targetEntity, messages: newMessages }, entityState);
      return newEntityState;
    },
  };
};
