import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { FeaturesConfig } from '@portal/wen-backend-api';
import { WenRouteId } from '../../../frame/routing/types';
import { DataContext } from '../../common/types/data-context';

export interface ScrollStateEntity {
  scrollContextId: WenRouteId;
  scrollDataContext: DataContext;
  scrollTopPosition: number;
}

export interface ChannelMessageOriginTransferData {
  channelId: string;
  messageId: string;
  messageTimestamp: string;
}

export enum ProgressProcessType {
  FOREGROUND = 'FOREGROUND',
  BACKGROUND = 'BACKGROUND',
}

interface ProgressStateOn {
  hasProgress: boolean;
  progressType: ProgressProcessType;
}

interface ProgressStateOff {
  hasProgress: false;
}

export type ProgressState = ProgressStateOn | ProgressStateOff;

export interface UiState {
  storedScrollState: EntityState<ScrollStateEntity>;
  /**
   * Information to carry over through navigations about the context origin
   * TODO: Make this more reusable once other use-cases are known
   */
  channelMessageOriginTransferData: ChannelMessageOriginTransferData;
  progressState: ProgressState;
  featureConfig: FeaturesConfig;
}

export const scrollStateEntityAdapter = createEntityAdapter<ScrollStateEntity>({
  selectId: (entity) => entity.scrollContextId
});
