import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { combineLatest, map, Observable, Subject } from 'rxjs';
import { SECTION_LIMIT, SectionService } from '../../../../../core/store/sections/sections.facade';
import { SectionEntity } from '../../../../../core/store/sections/sections.state';
import { SectionProps } from '../../../../../core/common/util/section.model';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { selectUserAppIds } from '../../../../../core/store/apps/apps.selectors';
import { select, Store } from '@ngrx/store';
import { RootState } from '../../../../../core/store/root/public-api';
import { AppListData } from '../../../app-list/models/AppLoadResult';
import { setSubscriptionToApp } from '../../../../../core/store/apps/apps.actions';
import { WenNativeApi } from '@portal/wen-native-api';
import { DataContext } from '../../../../../core/common/types/data-context';

@Component({
  selector: 'wen-app-section',
  templateUrl: './app-section.component.html',
  styleUrls: ['./app-section.component.scss']
})
export class AppSectionComponent implements OnDestroy {
  private onDestroy$ = new Subject<void>();

  private sectionProbs: SectionProps;

  sectionName: string;
  sectionItems$: Observable<AppListData[]>;
  imageSize = { height: 110 };
  sectionLimit = SECTION_LIMIT;

  @Input() set section(section: SectionEntity) {
    this.sectionName = section.name;
    this.sectionProbs = {
      filterRef: section.filterRef,
      filterEntityType: section.filterType,
      filterId: section.filterId
    };
    this.sectionItems$ = combineLatest([
      this.store.pipe(select(selectUserAppIds)),
      this.sectionService.loadSectionContent(section.id, section.filterRef, DataContext.APP)
    ]).pipe(
      map(([userAppIds, apps]) => {
          const appItems = apps?.map((app) => {
            return {
              ...app,
              isSubscribed: userAppIds?.includes(app.id)
            };
          });
          return appItems;
      })
    );
  }

  @Output() showAll = new EventEmitter<SectionProps>();

  isNativeApp = this.wenNativeApi.isReactNativeApp();

  constructor(
    private sectionService: SectionService,
    private navigationHelper: WenNavigationHelper,
    private store: Store<RootState>,
    private wenNativeApi: WenNativeApi
  ) { }

  navigateToAppDetail(app: AppListData) {
     if (app.isSubscribed) {
        this.navigationHelper.navigateToApp(app);
     } else {
        this.navigationHelper.navigateToAppDetail(app.id);
     }
  }

  showAllElements() {
    this.showAll.emit(this.sectionProbs);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toggleAppSubscription(id: string, setSubscribed: boolean) {
    this.store.dispatch(setSubscriptionToApp({ id, setSubscribed }));
  }
}
