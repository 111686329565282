import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DialogLayoutModule, WenSelectionListModule, WenTestIdModule } from '@portal/wen-components';
import { DateRangePickerComponent } from '../custom-filter-views/date-range-picker/date-range-picker.component';
import { CUSTOM_FILTER_VIEW, FilterViewType } from './custom-filter-view';
import { FilterSelectorComponent } from './filter-selector.component';
import { FilterSelectorOpener } from './filter-selector-opener';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WenSelectionListModule,
    TranslateModule.forChild(),
    MatDialogModule,
    DialogLayoutModule,
    WenTestIdModule
  ],
  exports: [FilterSelectorComponent],
  declarations: [FilterSelectorComponent],
  providers: [
    {
      provide: CUSTOM_FILTER_VIEW,
      useValue: {
        type: FilterViewType.DATE_RANGE,
        view: DateRangePickerComponent,
      },
      multi: true
    },
    FilterSelectorOpener,
  ],
})
export class FilterSelectorModule { }
