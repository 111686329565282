import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { distinctUntilChanged, map } from 'rxjs';
import { deleteContentProviderFromCurrentChannel } from '../../../../../core/store/channel/channel.actions';
import { selectCurrentChannelPermission } from '../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../core/store/root/public-api';

@Injectable()
export class DeleteContentProviderStatusAction implements ActionMenuItem<string, void> {

  label: string;
  icon = 'author_off';
  isVisible$ = () => this.store.pipe(
    select(selectCurrentChannelPermission),
    map(permission => permission?.canDeleteContentProviders),
    distinctUntilChanged()
  );

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>
  ) {
    this.label = this.translateService.instant('CHANNEL_CONTENT_PROVIDER_REMOVE_STATUS_LABEL');
  }

  execute(userId: string) {
    this.store.dispatch(deleteContentProviderFromCurrentChannel({ userId }));
  }
}
