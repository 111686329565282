import { Injectable } from '@angular/core';
import { MassDataContainer } from '@portal/wen-data-core';
import { pendingContactsMassDataIdentifier } from '../constants';
import { PendingContactEntity } from '../contacts.state';

@Injectable()
export class PendingContactsMassDataContainer extends MassDataContainer<PendingContactEntity> {

  getIdentifier() {
    return pendingContactsMassDataIdentifier;
  }

}
