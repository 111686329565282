import { AfterViewInit, Directive, Inject, OnDestroy, ViewContainerRef } from '@angular/core';
import { WenNativeApi } from '@portal/wen-native-api';
import { DebugOptions, DEBUG_OPTIONS_CONFIG } from '../../../frame/api/tokens';

@Directive({ selector: '[nativeBridgeDebugTarget]' })
export class NativeBridgeDebugTargetDirective implements AfterViewInit, OnDestroy {

  private manager: HammerManager;

  constructor(
    private vcRef: ViewContainerRef,
    private nativeApi: WenNativeApi,
    @Inject(DEBUG_OPTIONS_CONFIG) private debugOptions: DebugOptions
  ) { }

  ngAfterViewInit() {
    if (!this.nativeApi.isReactNativeApp() || !this.debugOptions?.enableNativeBridgeDebug) {
      return;
    }
    const targetElement = this.vcRef?.element?.nativeElement;
    if (targetElement) {
      this.manager = new Hammer.Manager(targetElement);
      this.manager.add(new Hammer.Tap());
      this.manager.add(new Hammer.Tap({ event: 'quadrupletap', taps: 6 }));
      this.manager.get('quadrupletap').recognizeWith('tap');
      this.manager.on('quadrupletap', () => {
        const newHost = prompt('Please enter a new host:', location.href);
        if (!newHost) {
          return;
        }
        this.nativeApi.storeSet('WENAPPDEBUGCLIENTURL', newHost);
      });
    }
  }

  ngOnDestroy() {
    if (this.manager) {
      this.manager.destroy();
      this.manager = null;
    }
  }

}
