import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { FormStoreKey } from '../../../shared/form-store/form-store-types';
import { FormStatus } from '../../common/util/forms';

export type FormChangedValue = Record<string, any>;

export interface FormDataObject {
  id?: string;
  [k: string]: any;
}

export interface EditFormEntity<C extends FormChangedValue = FormChangedValue> {
  formId: FormStoreKey;
  changedValues?: C;
  initialValues?: FormDataObject;
  dirty: boolean;
  status: FormStatus;
}

export interface FormState {
  editedForm: EntityState<EditFormEntity>;
}

export const editedFormEntityAdapter = createEntityAdapter<EditFormEntity>({
  selectId: (entity) => entity.formId
});
