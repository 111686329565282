import { inject, Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs';
import { existy } from '../../../../../core/common/operators/existy';
import { UserProfileService } from '../../../user-settings/user-profile.service';

@Injectable()
export class UserChangePasswordVerifyCodeDatasource {

  private userService = inject(UserProfileService);

  public readonly phoneNumber$ = this.userService.currentUser$.pipe(
    existy(),
    map((currentUser) => currentUser.identifier),
    shareReplay(1)
  );

}
