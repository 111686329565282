import { FactoryProvider, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { selectCurrentAppDetail } from '../../../../../../core/store/apps/apps.selectors';
import { AppEntity } from '../../../../../../core/store/apps/apps.state';
import { RootState } from '../../../../../../core/store/root/public-api';

@Injectable()
export abstract class AppFormDataProvider {
  abstract app$: Observable<AppEntity>;
}

@Injectable()
export class CreateAppFormDataProvider extends AppFormDataProvider {
  app$: Observable<AppEntity> = of({} as AppEntity);
}

@Injectable()
export class EditAppFormDataProvider extends AppFormDataProvider {
  app$: Observable<AppEntity> = this.store.pipe(select(selectCurrentAppDetail), shareReplay(1));

  constructor(
    private readonly store: Store<RootState>
  ) {
    super();
  }

}

export function appEditFormDataProviderFactoryFn(
  route: ActivatedRoute,
  store: Store<RootState>
): AppFormDataProvider {
  return route.snapshot.params.appDetailId ? new EditAppFormDataProvider(store) : new CreateAppFormDataProvider();
}

export const APP_EDIT_FORM_DATA_PROVIDER: FactoryProvider = {
  provide: AppFormDataProvider,
  useFactory: appEditFormDataProviderFactoryFn,
  deps: [ActivatedRoute, Store]
};
