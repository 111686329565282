import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { FocusFirstInputModule } from '@portal/wen-components';
import { UserChangePasswordFormChangedValuesStep2 } from '../../../../../core/store/form/types/user-change-password-types';
import { createCodeVerificationControl } from '../../../../../shared/components/forms/form-controls/code-verification-field/code-verification-field-utils';
import { CodeVerificationFieldModule } from '../../../../../shared/components/forms/form-controls/code-verification-field/code-verification-field.module';
import { FormStoreModule } from '../../../../../shared/form-store/form-store.module';
import { UserChangePasswordVerifyCodeDatasource } from './user-change-password-verify-code.datasource';

@Component({
  selector: 'wen-user-change-password-verify-code',
  templateUrl: './user-change-password-verify-code.component.html',
  styleUrls: ['./user-change-password-verify-code.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CodeVerificationFieldModule,
    FormStoreModule,
    FocusFirstInputModule,
  ],
  providers: [
    UserChangePasswordVerifyCodeDatasource
  ]
})
export class UserChangePasswordVerifyCodeComponent {

  private readonly datasource = inject(UserChangePasswordVerifyCodeDatasource);

  phoneNumber$ = this.datasource.phoneNumber$;
  userChangePasswordVerifyCodeFormGroup = new FormGroup<UserChangePasswordFormChangedValuesStep2>({
    verificationCode: createCodeVerificationControl(),
  });

}
