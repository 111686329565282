import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { ActionMenuComponent, ActionMenuItem, ActionMenuItemCreator, ButtonVariant, OverlayManager } from '@portal/wen-components';
import { Observable, map, startWith } from 'rxjs';
import { ContactResponseActionsHandler } from '../../../core/services/contact/contact-response-actions-handler';
import { ContactResponseActionDataWrapper, ContactResponseButtonProps, ContactResponseResponseActionData } from './contact-response-action.types';
import { ContactRequestUiState, ContactResponseActionsConditions } from './providers/contact-response-actions-conditions';
import { ContactResponseConnectionService } from './providers/contact-response-connection-data.service';
import { ProfileContextMenuAcceptContactAction, ProfileContextMenuDeleteContactAction, ProfileContextMenuDenyContactAction } from './providers/contact-response-context-menu-actions';
import { ForbiddenForAnonymousUserDialogService } from '../../services/forbidden-for-anonymous-user-dialog.service';

@Component({
  selector: 'wen-contact-response-action',
  templateUrl: './contact-response-action.component.html',
  styleUrls: ['./contact-response-action.component.scss'],
  providers: [
    ContactResponseActionDataWrapper,
    ContactResponseActionsHandler,
    ContactResponseActionsConditions,
    ContactResponseConnectionService,
    ProfileContextMenuAcceptContactAction,
    ProfileContextMenuDenyContactAction,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactResponseActionComponent implements OnInit {

  @Input() iconOnly = false;

  @Input() set profileData(value: ContactResponseResponseActionData) {
    this.contactResponseActionData.setProfileData(value);
  }

  constructor(
    private injector: Injector,
    private contactResponseActionData: ContactResponseActionDataWrapper,
    public actions: ContactResponseActionsHandler,
    public conditions: ContactResponseActionsConditions,
    private dialogOpener: ForbiddenForAnonymousUserDialogService,
    private overlayManager: OverlayManager
  ) { }

  contextMenuActionsHandleReceived: ActionMenuItem[];
  contextMenuActionsHandleDelete: ActionMenuItem[];
  contactButtonProps$: Observable<ContactResponseButtonProps>;
  data$: Observable<ContactResponseResponseActionData>;

  ngOnInit() {
    this.data$ = this.contactResponseActionData.profile$;
    this.contactButtonProps$ = this.conditions.contactRequestUiState$.pipe(
      map((contactRequestButtonState) => {
        let wenVariant: ButtonVariant;
        let iconName: string;
        let translationKey: string;
        let contextMenu: 'received' | 'delete';
        let action: ContactResponseButtonProps['action'];
        switch (contactRequestButtonState) {
          case ContactRequestUiState.CAN_WITHDRAW:
            wenVariant = this.calculateVariant(ButtonVariant.secondary);
            iconName = 'contact_sent_pending_request';
            translationKey = 'CONTACTS_ACTION_SENT_PENDING_REQUEST';
            action = this.actions.withdrawSentContactRequest.bind(this.actions);
            break;
          case ContactRequestUiState.CAN_HANDLE_RECEIVED:
            wenVariant = this.calculateVariant(ButtonVariant.primary);
            iconName = 'contact_received_pending_request';
            translationKey = 'CONTACTS_ACTION_RECEIVED_PENDING_REQUEST';
            contextMenu = 'received';
            break;
          case ContactRequestUiState.CAN_REMOVE_CONTACT:
            wenVariant = this.calculateVariant(ButtonVariant.secondary);
            iconName = 'contact_connected';
            translationKey = 'CONTACTS_ACTION_CONNECTED';
            contextMenu = 'delete';
            break;
          case ContactRequestUiState.CAN_SEND:
            wenVariant = this.calculateVariant(ButtonVariant.primary);
            iconName = 'contact_send_request';
            translationKey = 'CONTACTS_ACTION_SEND_REQUEST';
            action = this.actions.sendContactRequest.bind(this.actions);
            break;
          default:
            return null;
        }
        return { wenVariant, iconName, translationKey, action, contextMenu };
      }),
      startWith(null)
    );
    this.contextMenuActionsHandleReceived = ActionMenuItemCreator.createActions([
      ProfileContextMenuAcceptContactAction,
      ProfileContextMenuDenyContactAction
    ], this.injector);
    this.contextMenuActionsHandleDelete = ActionMenuItemCreator.createActions([
      ProfileContextMenuDeleteContactAction,
    ], this.injector);
  }

  isDeleteContextMenu(prop: ContactResponseButtonProps) {
    return prop.contextMenu === 'delete';
  }

  openContextMenu(contactButtonProps: ContactResponseButtonProps, data: ContactResponseResponseActionData) {
    const actions = this.isDeleteContextMenu(contactButtonProps) ?
      this.contextMenuActionsHandleDelete : this.contextMenuActionsHandleReceived;
    this.overlayManager.menu.open(ActionMenuComponent, { actions, data });
  }

  handleAction(event: Event, handler: ContactResponseButtonProps['action'], data: ContactResponseResponseActionData) {
    event.stopPropagation();
    if (!this.dialogOpener.isAnonymous) {
      handler(data.userId);
    } else {
      this.dialogOpener.openProfileInteractionForbiddenWhenAnonymous().subscribe();
    }
  }

  private calculateVariant(fallback: ButtonVariant) {
    return this.iconOnly ? ButtonVariant.icon : fallback;
  }

}
