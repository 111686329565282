import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PollModel, PollOption } from '@portal/wen-backend-api';
import { merge, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { PollService } from '../../../../../../core/services/polling/poll-service';
import { EmbeddedPoll } from '../../../../../../core/store/channel/channel.state';

@Component({
  selector: 'wen-embed-poll',
  templateUrl: './embed-poll.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedPollComponent {

  constructor(private pollService: PollService) {}

  private pollModel: PollModel;

  pollModel$: Observable<PollModel>;

  @Input() set model(value: EmbeddedPoll) {
    this.pollService.getPoll({ id: value.pollId });
    const getPollResponse$ = this.pollService.getPollResponse$;
    const hasOptionSelection$ = this.pollService.chooseOptionResponse$;

    const pollModelResponse$ = merge(
      getPollResponse$,
      hasOptionSelection$
    ).pipe(
      filter(poll => poll.id && poll.id === value.pollId),
    );

    const updatePoll$ = this.pollService.updatePollResponse$.pipe(
      filter(poll => poll.id && poll.id === value.pollId),
      map((updatesOfPoll: PollModel) => this.updatePoll(updatesOfPoll))
    );

    this.pollModel$ = merge(
      pollModelResponse$,
      updatePoll$
    ).pipe(
      tap(poll => this.pollModel = poll)
    );
  }

  private updatePoll(updatesOfPoll: PollModel): PollModel {
    const { options: updatedOptions, ...updatedPollWithoutOptions } = updatesOfPoll;
    const updatedPoll: PollModel = {
      ...this.pollModel,
      ...updatedPollWithoutOptions
    };
    updatedPoll.options = updatedPoll.options.map((option) => this.updateOptionIfNecessary(option, updatedOptions));

    return updatedPoll;
  }

  private updateOptionIfNecessary(option: PollOption, updatedOptions: PollOption[]): PollOption {
    const updatedOption = updatedOptions.find((changedOption) => changedOption.id === option.id);
    return { ...option, ...updatedOption };
  }
}
