
import { GeoWeatherFeature, GeoWeatherHourly, GeoWeatherType } from '@portal/wen-backend-api';
import { gradientConfig, foregroundConfig } from './config';
import { HourlyForecastData, WeatherModel } from './weather.model';

const WEATHER_ICONS_PATH = '/assets/wen-widget/image/weather/widget';

export const toWeatherModel = (weather: GeoWeatherFeature, assetPath?: string): WeatherModel => {
  if (!weather) {
    return;
  }
  const { currently, address, hourly = null, hourlySummary = null } = weather.properties;
  const currentTemperature = hourlySummary?.temp2Meters || currently.temperature2Meters;
  const location = address.city;
  const weatherType = hourlySummary?.weather?.type || currently.weather.type;
  const { background, descriptionKey, icon } = getWeatherInfo(weatherType, assetPath);

  return {
    temperature: currentTemperature,
    location,
    icon,
    background,
    descriptionKey,
    ...hourly?.length > 0 && { forecasts: getHourlyForecasts(hourly, assetPath) }
  };
};

const getWeatherInfo = (type: GeoWeatherType, iconPath?: string) => {
  // usage of nighttime config???
  const typeAsNumber = +`${type}`;
  const config = Object.entries(gradientConfig).filter(([key]) => key === 'daylight').map(([_, value]) => value.config).flat();
  const matchingGradient = config.find(item => item.weatherTypes.includes(typeAsNumber)).gradient;
  const matchingForeground = foregroundConfig.find(item => item.weatherTypes.includes(typeAsNumber)).config;
  if (!matchingGradient && !matchingForeground) {
    console.error('unsupported weather type: \'' + type + '\'');
    return null;
  }

  const { icon, descriptionKey } = matchingForeground;
  const weatherIconPath = iconPath ?? WEATHER_ICONS_PATH;
  return {
    background: {
      gradient: matchingGradient,
      iconUrl: `${WEATHER_ICONS_PATH}/ic_day_${icon}_background.svg`
    },
    descriptionKey,
    icon: `${weatherIconPath}/ic_day_${icon}.svg`
  };
};

const getHourlyForecasts = (hourly: GeoWeatherHourly[], assetPath?: string): HourlyForecastData[] => {
  return hourly.map(hourlyForecast => {
    return {
      icon: getWeatherInfo(hourlyForecast.weather.type, assetPath).icon,
      temperature: Math.round(hourlyForecast.temperature2Meters),
      time: hourlyForecast.timestamp
    };
  });
};

export const getHourlyForecast = (hourly: GeoWeatherHourly,  assetPath?: string): HourlyForecastData => {
  return {
    icon: getWeatherInfo(hourly.weather.type, assetPath).icon,
    temperature: Math.round(hourly.temperature2Meters),
    time: hourly.timestamp
  };
};
