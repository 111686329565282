import { Injectable } from '@angular/core';
import { MassDataContainer } from '@portal/wen-data-core';
import { contactsSearchMassDataIdentifier } from '../constants';
import { ContactFilterListEntity } from '../contacts.state';

@Injectable()
export class ContactsSearchMassDataContainer extends MassDataContainer<ContactFilterListEntity> {

  getIdentifier() {
    return contactsSearchMassDataIdentifier;
  }

}
