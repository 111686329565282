import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PassFilterData, WenNavigationHelper } from '../../../../core/services/navigation/types';
import { DataContext } from '../../../../core/common/types/data-context';
import { SectionEntity } from '../../../../core/store/sections/sections.state';
import { SectionService } from '../../../../core/store/sections/sections.facade';
import { Observable } from 'rxjs';
import { FeaturedSectionConfig } from '@portal/wen-backend-api';
import { SharedModule } from '../../../../shared/shared.module';
import { ChannelSectionComponent } from '../../../channel/channel-explore/components/channel-section/channel-section.component';
import { AppExploreModule } from '../../../apps/app-explore/app-explore.module';
import { EventSectionModule } from '../../../events/event-explore/components/event-section/event-section.module';

@Component({
  selector: 'wen-generic-section',
  templateUrl: './generic-section.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    SharedModule,
    ChannelSectionComponent,
    AppExploreModule,
    EventSectionModule,
  ],
})
export class GenericSectionComponent implements OnInit {
  @Input() config?: FeaturedSectionConfig;

  protected section$: Observable<SectionEntity | null>;
  protected readonly DataContext = DataContext;

  constructor(
    private readonly navigationHelper: WenNavigationHelper,
    private readonly sectionService: SectionService,
  ) {}

  ngOnInit(): void {
   this.section$ = this.sectionService.getSectionWithDifferentTitle(this.config.sectionTextKey, this.config?.id);
  }

  protected navigateToChannelDiscovery(filterData?: PassFilterData): void {
    this.navigationHelper.navigateToChannelDiscovery(filterData);
  }

  protected navigateToAppDiscovery(filterData?: PassFilterData): void {
    this.navigationHelper.navigateToAppDiscoverWithFilter(filterData);
  }

  protected navigateToEventDiscovery(filterData?: PassFilterData): void {
    this.navigationHelper.navigateToEventDiscoveryWithFilter(filterData);
  }
}
