import { Component } from '@angular/core';
import { EMBEDDED_URL_RESOLVER } from '../../../../../shared/components/embedded-page-viewer/providers/tokens';
import { AppEmbeddedUrlResolver } from './app-embedded-url-resolver';

@Component({
  selector: 'wen-app-view',
  templateUrl: './app-view.component.html',
  styleUrls: ['./app-view.component.scss'],
  viewProviders: [
    {
      provide: EMBEDDED_URL_RESOLVER,
      useClass: AppEmbeddedUrlResolver
    }
  ]
})
export class AppViewComponent { }
