import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EmojiReactionsModule, TouchEndListenerModule, WenIconModule, CircleImageViewModule, ColorizeModule, WenTestIdModule, CircleInitialViewModule, SwipeableComponent } from '@portal/wen-components';
import { UserNavigationModule } from '../../directives/user-navigation/user-navigation.module';
import { CommentListModule } from '../comments/comment-list.module';
import { MessageBoxContentTemplateDirective } from './directives/message-box.directives';
import { MessageBoxComponent } from './message-box.component';
import { MessageStatusIndicatorModule } from '../message-status-indicator/message-status-indicator.module';
import { WenPipesModule } from '../../pipes/wen-pipes.module';
import { ContentBoundaryModule } from '../../directives/content-boundary/content-boundary.module';
import { TextToSpeechModule } from '../text-to-speech/text-to-speech.module';
import { ChallengeModule } from '../challenge/challenge.module';
import { DefaultMessageBoxActionsHandler } from './providers/default-message-box-actions-handler';
import { MessageBoxContentModule } from './components/content/message-box-content.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ColorizeModule,
    TouchEndListenerModule,
    WenIconModule,
    EmojiReactionsModule,
    CircleImageViewModule,
    CommentListModule,
    UserNavigationModule,
    WenTestIdModule,
    MessageStatusIndicatorModule,
    WenPipesModule,
    ContentBoundaryModule,
    TextToSpeechModule,
    TouchEndListenerModule,
    WenIconModule,
    ChallengeModule,
    CircleInitialViewModule,
    MessageBoxContentModule,
    SwipeableComponent
  ],
  providers: [
    DefaultMessageBoxActionsHandler
  ],
  declarations: [
    MessageBoxComponent,
    MessageBoxContentTemplateDirective,
  ],
  exports: [
    MessageBoxComponent,
    MessageBoxContentTemplateDirective,
    ContentBoundaryModule,
  ]
})
export class MessageBoxModule { }
