import { Injectable } from '@angular/core';
import { ChannelMessagesSummaryResponse, ChannelRelevantMessagesResponse, SocketIoService } from '@portal/wen-backend-api';
import { Observable, map, filter } from 'rxjs';
import { CachedItemLoader } from './create-event-cacher';

@Injectable()
export class ChannelEventOrchestrator {

  private relevantCachedItemLoader = new CachedItemLoader(
    (channelId) => this.socketIoService.channel.relevantMessages.acknowledgement$({ channelId }).pipe(
      filter((response: any) => !response?.denied),
      map((response: any) => response as ChannelRelevantMessagesResponse)
    ),
    (item) => item.channelId,
  );

  private summaryCachedItemLoader = new CachedItemLoader(
    (channelId) => this.socketIoService.channel.messagesSummary.acknowledgement$({ channelId }),
    (item) => item.channelId,
  );

  public bufferedRelevant$: Observable<ChannelRelevantMessagesResponse[]>;
  public bufferedSummary$: Observable<ChannelMessagesSummaryResponse[]>;

  constructor(
    private readonly socketIoService: SocketIoService,
  ) {
    this.bufferedRelevant$ = this.relevantCachedItemLoader.items$.pipe(
      map((data) => Array.from(data.values()))
    );

    this.bufferedSummary$ = this.summaryCachedItemLoader.items$.pipe(
      map((data) => Array.from(data.values()))
    );
  }

  ensureRelevantMessagesLoaded(channelId: string) {
    return this.relevantCachedItemLoader.getOrLoadItem(channelId);
  }

  ensureRelevantMessagesSynced() {
    return this.relevantCachedItemLoader.reloadCache();
  }

  ensureRelevantMessagesReplace(messages: ChannelRelevantMessagesResponse) {
    return this.relevantCachedItemLoader.replaceCache(messages.channelId, [messages]);
  }

  ensureSummaryMessagesLoaded(channelIds: string[]) {
    return this.summaryCachedItemLoader.getOrLoadItem(channelIds);
  }

  ensureSummaryMessagesSynced() {
    return this.summaryCachedItemLoader.reloadCache();
  }

  ensureSummaryMessagesReplace(summaries: ChannelMessagesSummaryResponse) {
    return this.summaryCachedItemLoader.replaceCache(summaries.channelId, [summaries]);
  }

}
