<form wenFormStore class="wen-form" [formGroup]="channelSettingsFormGroup">
  @if (settingsSchema$ | async; as schema) {
    <ng-container *ngTemplateOutlet="settingsTemplate; context: { formGroup: channelSettingsFormGroup, schema: schema }"></ng-container>
  }
</form>

<ng-template #settingsTemplate let-formGroup="formGroup" let-schema="schema">
  <ng-container [formGroup]="formGroup">
    @for (group of schema; track group) {
      <ng-container *ngComponentOutlet="group.componentType; inputs: { context: group.context }"></ng-container>
    }
  </ng-container>
</ng-template>
