import { WenRoutes } from '../../../frame/routing/types';
import { onboardingStartRoute } from './onboarding.routes';

export const multiOutletonboardingRoutes: WenRoutes = [
  onboardingStartRoute,
  {
    path: '**',
    redirectTo: 'start'
  }
];
