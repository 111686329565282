export const createResizeObserver = (callback: ResizeObserverCallback): ResizeObserver => {
  if (ResizeObserver) {
    return new ResizeObserver(callback);
  }
  return new class DummyResizeObserver implements ResizeObserver {
    disconnect(): void { }
    observe(target: Element, options?: ResizeObserverOptions): void { }
    unobserve(target: Element): void { }
  }();
};
