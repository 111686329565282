import { Injectable } from '@angular/core';

@Injectable()
export class BrowserInfo {

  browserIsIos() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !(window as any).MSStream) {
      return true;
    }
    return false;
  }

  browserIsAndroid() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return true;
    }
    return false;
  }

}
