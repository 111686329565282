import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { map, share, throttleTime } from 'rxjs';
import { RootState } from '../root/public-api';
import { setParentIdForComment, updateTimestampOfPageVisit } from './comments.actions';
import { createCommentDeleteResponseEffect, createDeleteCommentEffect } from './effects/delete-comment.effect';
import { fetchCommentCountForMessagesEffect, fetchCommentCountForSingleParentEffect } from './effects/fetch-comment-count.effect';
import { createFetchCommentsEffect } from './effects/fetch-comments.effect';
import { createCommentPagingFetchListenerEffect } from './effects/paging-of-comments';
import { createSendCommentEffect } from './effects/send-comment.effect';
import { createUpdateCommentsEffect, refreshAfterCommentCreationEffect, refreshAfterCommentDeleteEffect } from './effects/update-comments.effect';
import { updateTimestampOnNewCommentCreationEffect } from './effects/update-current-timestamp.effect';


@Injectable()
export class CommentsEffects {
  commentList$ = this.socketIoService.comment.list.listen.pipe(
    throttleTime(200),
    share()
  );
  newCommentCreated$ = this.socketIoService.comment.create.listen.pipe(share());

  commentDeleted$ = this.socketIoService.comment.delete.listen.pipe(share());

  currentParentId$ = this.action$.pipe(
    ofType(setParentIdForComment),
    map(({ parentId }) => parentId),
    share()
  );

  currentTimestamp$ = this.action$.pipe(
    ofType(updateTimestampOfPageVisit),
    map(({ timestamp }) => timestamp),
    share()
  );

  fetchCommentsForMessage$ = createFetchCommentsEffect(this.action$, this.socketIoService);
  setLoadedComments$ = createUpdateCommentsEffect(this.action$, this.commentList$, this.currentParentId$);
  createComment$ = createSendCommentEffect(this.action$, this.store, this.socketIoService);
  refreshComments$ = refreshAfterCommentCreationEffect(this.newCommentCreated$, this.currentParentId$, this.currentTimestamp$, this.store);
  refreshCommentsAfterDelete$ = refreshAfterCommentDeleteEffect(
    this.commentDeleted$,
    this.currentParentId$,
    this.currentTimestamp$,
    this.store
  );

  commentPaging$ = createCommentPagingFetchListenerEffect(this.action$, this.store, this.socketIoService);
  updateCurrentTimestamp$ = updateTimestampOnNewCommentCreationEffect(this.newCommentCreated$);

  commentDeletion$ = createDeleteCommentEffect(this.action$, this.socketIoService);
  commentDeleteResponse$ = createCommentDeleteResponseEffect(this.socketIoService);

  fetchCommentCountEffect$ = fetchCommentCountForMessagesEffect(this.action$, this.socketIoService);
  fetchCommentCountForSingleParent$ = fetchCommentCountForSingleParentEffect(this.action$, this.socketIoService);


  constructor(
    private action$: Actions,
    private socketIoService: SocketIoService,
    private store: Store<RootState>
  ) { }
}
