import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { map, switchMap } from 'rxjs';
import { fetchCurrentWeatherData, weatherDataRetrieved } from '../weather.actions';
import { WeatherState } from '../weather.state';

@Injectable()
export class WeatherEffects {

  fetchCurrentWeatherData$ = createEffect(() => this.actions$.pipe(
    ofType(fetchCurrentWeatherData),
    switchMap( actionData => {
      return this.socketIoService.geo.weather.getCurrentData.acknowledgment$({
        countryCode: actionData.countryCode,
        singleSlot: actionData.location
      });
    }),
    map(weatherResponse => {
      const newState: WeatherState = { currentWeather: weatherResponse };
      return weatherDataRetrieved({ newState });
    })
  ));

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
  ) {
  }

}
