import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCurrentRoom } from '../../../../../../core/store/chat/chat.selectors';
import { ChatRoomEntity } from '../../../../../../core/store/chat/chat.state';
import { createBackNavigationCommand } from '../../../../../../core/store/header/models/base-commands';
import { HeaderCommandType } from '../../../../../../core/store/header/models/HeaderCommand';
import { RootState } from '../../../../../../core/store/root/public-api';

@Component({
  selector: 'wen-chat-schedules-desktop-header',
  templateUrl: './chat-schedules-desktop-header.component.html',
  styleUrls: ['./chat-schedules-desktop-header.component.scss']
})
export class ChatSchedulesDesktopHeaderComponent implements OnInit {

  @HostBinding('class.wen-chat-schedules-desktop-header') className = true;

  leftCommand = createBackNavigationCommand();
  titleTranslationKey = 'CHAT_SCHEDULED_MESSAGES_TITLE';
  rightCommand = { title: '', type: HeaderCommandType.BACK_NAVIGATION };

  currentChatRoom$: Observable<ChatRoomEntity>;

  constructor(
    private store: Store<RootState>
  ) { }

  ngOnInit(): void {
    this.currentChatRoom$ = this.store.pipe(
      select(selectCurrentRoom)
    );
  }
}
