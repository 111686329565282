import { GeoFeatureEntityType } from '../geo/autocomplete';

export enum ContentHandlerType {
  HINT = 'HINT',
  GROUP = 'GROUP',
  MULTISELECT = 'MULTISELECT',
  TIME = 'TIME',
  LOCATION = 'LOCATION'
}

type Labelable = {
  label: string;
};

export type PossibleValueProps = Labelable & {
  value: string;
};

export type BaseControlProps = Partial<Labelable> & {
  name: string;
};

export type HintControlProps = ContentHandlerDTO<ContentHandlerType.HINT> & BaseControlProps;

export type TimeControlProps = ContentHandlerDTO<ContentHandlerType.TIME> & BaseControlProps;

export type LocationControlProps = ContentHandlerDTO<ContentHandlerType.LOCATION> & BaseControlProps & {
  restrictions: GeoFeatureEntityType[];
};

export type MultiSelectControlProps = ContentHandlerDTO<ContentHandlerType.MULTISELECT> & BaseControlProps & {
  possibleValues: PossibleValueProps[];
  multiValue: boolean;
};

export type ContentSchema = HintControlProps | MultiSelectControlProps | TimeControlProps | LocationControlProps;

export type GroupSchema = ContentHandlerDTO<ContentHandlerType.GROUP> & {
  content: ContentSchema[];
};

export type ConfigSchema = {
  groups: GroupSchema[];
};

type ContentHandlerDTO<T extends ContentHandlerType> = {
  type: T;
};
