import { NgModule, StaticProvider } from '@angular/core';
import { MASS_DATA_CONFIG, MassDataConfigEntry, MassDataModule, OffsetBasedMassDataPagingAdapter } from '@portal/wen-data-core';
import { addChatUsersSearchMassDataIdentifier } from '../../constants';
import { ChatMemberEditSearchMassDataAdapter } from '../providers/chat-member-edit-search-adapter';
import { AddChatUsersSearchMassDataContainer } from './add-chat-users-search-mass-data-container';
import { AddChatUsersSearchMassDataRepository } from './add-chat-users-search-mass-data-repository';

const addChatUsersSearchConfig: MassDataConfigEntry = {
  identifier: addChatUsersSearchMassDataIdentifier,
  container: AddChatUsersSearchMassDataContainer,
  adapter: ChatMemberEditSearchMassDataAdapter,
  repository: AddChatUsersSearchMassDataRepository,
  pagingAdapter: OffsetBasedMassDataPagingAdapter,
};

const ADD_CHAT_USERS_SEARCH_CONTAINER_CONFIG: StaticProvider = {
  provide: MASS_DATA_CONFIG,
  multi: true,
  useValue: addChatUsersSearchConfig
};

@NgModule({
  imports: [
    MassDataModule,
  ],
  providers: [
    AddChatUsersSearchMassDataContainer,
    AddChatUsersSearchMassDataRepository,
    ADD_CHAT_USERS_SEARCH_CONTAINER_CONFIG,
  ]
})
export class AddChatUsersSearchMassDataModule { }
