@if (count$ | async; as count) {
  <wen-icon iconName="comment_active" [class.disabled]="disabled"></wen-icon>
  <div class="wen-create-comment-button-text" [class.disabled]="disabled">
    {{ count }}
  </div>
} @else {
  <wen-icon [class.disabled]="disabled || deactivated" iconName="comment_inactive"></wen-icon>
  <div [class.disabled]="disabled || deactivated" class="wen-create-comment-button-text">
    {{ (deactivated ? "ADD_COMMENTS_DEACTIVATED_LABEL" : "ADD_COMMENTS_LABEL") | translate }}
  </div>
}

