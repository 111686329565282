import { inject, Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { OlmDevice } from '../device/olm-device';
import { MegolmSessionNotFoundError } from '../error-types';
import { CryptoStorage } from '../persistence/crypto-storage';

@Injectable()
export class MegOlmDecryptor {

  private olmDevice = inject(OlmDevice);
  private dataStore = inject(CryptoStorage);

  createInboundGroupSession(roomId: string, senderCurve25519: string, sessionKey: string) {
    const { session, sessionId } = this.olmDevice.createInboundGroupSession(sessionKey);
    return this.dataStore.storeInboundGroupSession({
      roomId, senderCurve25519, session, sessionId
    });
  }

  decryptMessage(sessionId: string, senderCurve25519: string, message: string) {
    const inboundSession$ = this.dataStore.getInboundGroupSession({
      sessionId, senderCurve25519
    });
    return inboundSession$.pipe(
      first(),
      map((inboundGroupSession) => {
        if (!inboundGroupSession) {
          throw new MegolmSessionNotFoundError({ sessionId, senderCurve25519 });
        }
        const decrypted = this.olmDevice.decryptGroupMessage(inboundGroupSession.session, message);
        return decrypted;
      })
    );
  }

}
