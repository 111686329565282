import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { PersistenceStorage } from '@portal/wen-store-persistence';
import { merge, of } from 'rxjs';
import { catchError, delay, filter, first, mergeAll, switchMap, tap } from 'rxjs/operators';
import { switchMapFirst } from '../../common/operators/switch-map-first';
import { FeatureEnablementService } from '../../services/configuration/feature-enablement';
import { NativeConfigurationService } from '../../services/configuration/native-configuration';
import { FeatureFlagHandler } from '../../services/feature-flags/feature-flag-handler';
import { MediaSettingsService } from '../../services/media/media-settings.service';
import { NativeNotificationHandler } from '../../services/native-notification/native-notification-handler';
import { DeepLinkHandler } from '../../services/navigation/deep-link/deep-link-handler';
import { WenNavigationHelper } from '../../services/navigation/types';
import { WenNativeApi } from '@portal/wen-native-api';
import { WenStorageService } from '../../services/storage/wen-storage.service';
import { PermissionLevel } from '../../services/user-management/permission-level';
import { WenOAuthService } from '../../services/user-management/wen-oauth.service';
import { ExclusiveInviteHelper } from '../../services/util/exclusive-invite-helper';
import { initializeAppFilters, subscribeToAppUpdates, subscribeToDiscoverAppsUpdates, subscribeToUserAppsUpdates } from '../apps/apps.actions';
import { initializeChannelFilters, subscribeToChannelJoinRequestWithdrawUpdates, subscribeToChannelMessageModifications, subscribeToChannelRestrictionUpdates, subscribeToChannelRoleUpdates, subscribeToChannelUpdates, subscribeToDiscoverChannelUpdates, subscribeToFeaturedItemUpdates, subscribeToJoinRequestChannelUpdates, subscribeToUserChannelUpdates } from '../channel/channel.actions';
import { subscribeChatMessageAcknowledgeUpdates, subscribeChatUpdates } from '../chat/chat.actions';
import { subscribeToContactsUpdates } from '../contacts/contacts.actions';
import { initializeEventFilters, subscribeToUserEventUpdates } from '../events/event.actions';
import { handleNativeNotificationRegistration, subscribeToMediaUploadNotification, subscribeToUserChannelNotificationUpdates } from '../notification/notification.actions';
import { subscribeToGetMessageReactions } from '../reactions/reaction.actions';
import { fetchSystemPrivileges, subscribeToPermissionUpdates, subscribeToProfileUpdates } from '../user/user.actions';
import { fetchWalletCredentials } from '../wallet/wallet.actions';
import { authenticate, clearStoreData, initiateAppForUser, logout, tryLogin } from './auth.actions';

@Injectable()
export class AuthEffects {

  authenticate$ = createEffect(() => this.actions$.pipe(
    ofType(authenticate),
    switchMap((params) => {
      const hasValidTokens = this.wenOAuthService.hasValidTokens();
      const userData = this.wenOAuthService.getUserData();
      if (userData?.userId !== this.storageService.getExclusiveInviteCodeValue()) {
        this.storageService.clearExclusiveInviteData();
      }
      if (hasValidTokens && userData) {
        const { redirectAfterLogin } = params;
        const isCodeDialogNeeded = this.exclusiveInviteHelper.isCodeDialogNeeded();
        if (isCodeDialogNeeded) {
          return of(initiateAppForUser({ userData, redirectToExclusiveInvite: true }));
        }
        if (redirectAfterLogin) {
          return of(initiateAppForUser({ userData, redirectUrl: redirectAfterLogin }));
        }
        return of(initiateAppForUser({ userData, redirectToWelcome: true }));
      }
      const result$ = this.wenOAuthService.initCodeFlow(params).pipe(
        tap(() => {
          if (this.nativeConfiguration.isSSoDialogEnabled()) {
            this.navigationHelper.navigateToSignIn();
          }
        }),
        switchMap(() => []),
        catchError(() => {
          this.navigationHelper.navigateToErrorPage();
          return of([]);
        })
      );
      if (this.nativeConfiguration.isSSoIos()) {
        /**
         * After registration with SSO the app needs to open 2 external (inapp browsers):
         *  - One for logout
         *  - One for the new login
         * Wait for opening a new one for the login to make sure the previous one is closed properly
         */
        return of(null).pipe(
          delay(500),
          switchMap(() => result$)
        );
      }
      return result$;
    })
  ));

  tryLogin$ = createEffect(() => this.actions$.pipe(
    ofType(tryLogin),
    switchMap(() => {
      return this.wenOAuthService.tryLogin({ preventClearHashAfterLogin: true }).pipe(
        tap(() => {
          this.navigationHelper.navigateToOauthLogin();
        }),
        catchError(() => {
          return of(logout());
        })
      );
    })
  ), { dispatch: false });

  initiateAppForUser$ = createEffect(() => this.actions$.pipe(
    ofType(initiateAppForUser),
    tap((action) => {
      const redirectUrl = this.wenOAuthService.getRedirectAfterLoginUrl();
      if (action.redirectToExclusiveInvite) {
        this.navigationHelper.navigateToExclusiveInvite(redirectUrl || action.redirectUrl);
      } else if (redirectUrl) {
        const { canHandle, didNavigate } = this.deepLinkHandler.handleDeepLink(redirectUrl);
        if (canHandle && !didNavigate) {
          this.navigationHelper.navigateToWelcomeScreen();
        } else if (!didNavigate) {
          this.router.navigateByUrl(redirectUrl);
        }
      } else if (action.redirectToWelcome) {
        this.navigationHelper.navigateToWelcomeScreen();
      } else if (action.redirectUrl) {
        this.router.navigateByUrl(action.redirectUrl);
      }
    }),
    switchMapFirst(() => {
      return this.socketIoService.doInitialHandshake().pipe(
        switchMapFirst(() => this.featureFlagHandler.initialize())
      );
    }),
    switchMap(() => merge(
      of([
        subscribeToChannelUpdates(),
        subscribeToUserChannelUpdates(),
        subscribeToUserChannelNotificationUpdates(),
        subscribeToDiscoverChannelUpdates(),
        subscribeToAppUpdates(),
        subscribeToUserAppsUpdates(),
        subscribeToDiscoverAppsUpdates(),
        subscribeToProfileUpdates(),
        subscribeToMediaUploadNotification(),
        subscribeToChannelMessageModifications(),
        subscribeToGetMessageReactions(),
        subscribeToChannelRestrictionUpdates(),
        fetchSystemPrivileges(),
        subscribeToPermissionUpdates(),
        subscribeToUserEventUpdates(),
        subscribeToJoinRequestChannelUpdates(),
        subscribeToChannelJoinRequestWithdrawUpdates(),
        subscribeToFeaturedItemUpdates(),
        subscribeChatUpdates(),
        subscribeChatMessageAcknowledgeUpdates(),
        initializeEventFilters(),
        initializeAppFilters(),
        initializeChannelFilters(),
        subscribeToChannelRoleUpdates(),
        subscribeToContactsUpdates(),
        ...this.fetchWalletCredentials()
      ] as Action[]).pipe(
        mergeAll()
      ),
      this.socketIoService.isConnected$.pipe(
        filter((isConnected) => isConnected),
        first(),
        switchMap(() => this.nativeNotificationHandler.registerToNotifications().pipe(first())),
        switchMap((registrationData => {
          const actions: Action[] = [];
          if (registrationData) {
            actions.push(handleNativeNotificationRegistration({ payload: registrationData }));
          }
          this.mediaSettingsService.getSettingsFromServer();
          return actions;
        }))
      )
    ))
  ));

  fetchWalletCredentials(): Action<string>[] {
    return this.featureEnablementService.featureFlagMethods.isEnableWallet() ? [fetchWalletCredentials()] : [];
  }

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(logout),
    switchMap((params) => {
      const userData = this.wenOAuthService.getUserData();
      const isRegisteredUser = userData.permissionLevel === PermissionLevel.REGISTERED_USER;
      if (isRegisteredUser) {
        this.storageService.clearDeviceId();
      }
      this.storageService.clearExclusiveInviteData();
      return this.wenOAuthService.logout(params);
    }),
    switchMap(() => {
      if (this.persistenceStorage) {
        return this.persistenceStorage.clearData();
      }
      return of(null);
    }),
    switchMap(() => {
      if (this.nativeConfiguration.isSSoDialogEnabled()) {
        this.socketIoService.invalidateHandshake();
        return [clearStoreData()];
      }
      return [];
    })
  ));

  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly socketIoService: SocketIoService,
    private readonly wenOAuthService: WenOAuthService,
    private readonly nativeNotificationHandler: NativeNotificationHandler,
    private readonly deepLinkHandler: DeepLinkHandler,
    private readonly mediaSettingsService: MediaSettingsService,
    private readonly nativeApi: WenNativeApi,
    private readonly nativeConfiguration: NativeConfigurationService,
    private readonly storageService: WenStorageService,
    private readonly exclusiveInviteHelper: ExclusiveInviteHelper,
    private readonly featureEnablementService: FeatureEnablementService,
    private readonly featureFlagHandler: FeatureFlagHandler,
    @Optional() private readonly persistenceStorage: PersistenceStorage,
  ) {
  }

}
