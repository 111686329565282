import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ChatSelectionListFilterProvider } from '../../../../../views/chat/chat-creation/components/chat-selection-list/providers/chat-selection-list-providers';

@Injectable()
export class EditChatSearchFilterService extends ChatSelectionListFilterProvider {

  private readonly searchTerm = new BehaviorSubject<null | string>(null);
  public readonly searchTerm$ = this.searchTerm.pipe(
    distinctUntilChanged(),
    debounceTime(200),
  );

  setSearchTerm(value: string) {
    this.searchTerm.next(value);
  }

  resetSearchTerm() {
    this.searchTerm.next(null);
  }

}
