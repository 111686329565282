import { NgModule } from '@angular/core';
import { PortalProgressBarModule } from '@portal/ui-kit/progress-bar';
import { FormProgressComponent } from './form-progress.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    PortalProgressBarModule,
  ],
  declarations: [
    FormProgressComponent,
  ],
  exports: [
    FormProgressComponent,
  ]
})
export class FormProgressModule { }
