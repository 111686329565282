import { NgModule } from '@angular/core';
import { AvatarUrlPipe } from './avatar-url.pipe';
import { ClientDateFormatterPipe } from './client-date-formatter.pipe';
import { ContentHighlighterPipe } from './content-highlighter.pipe';
import { EventDatePipe } from './event-date-formatter.pipe';
import { LocationDistanceFormatterPipe } from './location-distance-formatter.pipe';
import { LocationFormatterPipe, LocationIconClassifierPipe } from './location-formatter.pipe';
import { LocationFormatter } from './location-formatter.service';

@NgModule({
  declarations: [
    EventDatePipe,
    LocationFormatterPipe,
    LocationDistanceFormatterPipe,
    LocationIconClassifierPipe,
    ContentHighlighterPipe,
    ClientDateFormatterPipe,
    AvatarUrlPipe
  ],
  exports: [
    EventDatePipe,
    LocationFormatterPipe,
    LocationIconClassifierPipe,
    ContentHighlighterPipe,
    ClientDateFormatterPipe,
    AvatarUrlPipe,
    LocationDistanceFormatterPipe
  ],
  providers: [
    LocationFormatter,
  ]
})
export class WenPipesModule { }
