import { DraftMessageData } from '../api-extensions/public-api';
import { AppDTO, AppSubscriptionDTO } from '../data-types/app/app';
import { ChannelDTO, ChannelSubscriptionDTO } from '../data-types/channel/channel';
import { ChannelMessageDTO } from '../data-types/channel/channelMessage';
import { PasswordRestrictionResolveObject, RestrictionContextType, RestrictionDTO, RestrictionResolveObjectType, RestrictionUpdateResponseDTO } from '../data-types/channel/channelRestriction';
import { ChatMessageAcknowledgeDTO, RoomAcknowledgeDTO } from '../data-types/chat/acknowledge';
import { ClaimOneTimeKeysPayload, CreateRoomPayload, DownloadDeviceKeysPayload, RoomMessagesReplayPayload, RoomMessagesSummaryPayload, RoomType, RoomUpdatePayload } from '../data-types/chat/ncrypted-types';
import { EditScheduledSendToRoomEventPayload, ScheduledSendToRoomEventPayload, SendToRoomEventPayload } from '../data-types/chat/send-to-room/payload';
import { SendToUsersEventPayload } from '../data-types/chat/send-to-users/payload';
import { WenComment, WenCommentCreate } from '../data-types/comment/comment';
import { ContactRequestCreatePayload, ContactRequestPayload, ContactRequestStatus } from '../data-types/contacts/contact';
import { FurtherUserContactListPayload, UserContactListPayload, UserPendingContactListPayload } from '../data-types/contacts/contact-paging';
import { EmbedDTOTypes, EmbeddedLinkDTO, EmbeddedMediaDTO, GetMediaPayload } from '../data-types/embed/embed';
import { EventListItemDTO } from '../data-types/event/event';
import { EventPaging } from '../data-types/event/event-paging';
import { CategoriesDTO } from '../data-types/filtering/filtering';
import { GeoAutocompletePayload, GeoLocationUpdatePayload, GeoReversePayload } from '../data-types/geo/autocomplete';
import { GeoWeatherPayload } from '../data-types/geo/weather';
import { InvitePayload } from '../data-types/invite/invite';
import { CopyMediaPayload } from '../data-types/media/media';
import { NetworkSubscribePayload } from '../data-types/network/network';
import { Paging, PagingReplayDirection } from '../data-types/paging/paging';
import { PinRequest } from '../data-types/pin/pin';
import { ChosenOptionRequest, GetPollRequest, SavePollRequest } from '../data-types/poll/poll';
import { BroadcastInformation, Emoji, ReactionContext, ReactionSummary } from '../data-types/reaction/reaction';
import { ReadingTextViewPayload, ReadingTextViewPreviewPayload } from '../data-types/reader/textview';
import { UserChatSearchPayload } from '../data-types/user/chat-search';
import { PrivilegeDTO } from '../data-types/user/privilege';
import { UserRequestPasswordChangePayload } from '../data-types/user/user-password-change';
import { BaseUserInfo, UserMetaDataSettingsDTO, UserMetaDataSettingsResponse, UserProfileSettings } from '../data-types/user/user-settings';
import { WalletCreateCredentialPayload, WalletRemoveCredentialPayload } from '../data-types/wallet/wallet';

export const RPC_EVENT_PREFIX = 'rpc.';

export type CoreEvents = 'connect' | 'reconnect' | 'disconnect' | 'connect_error';

export enum SOCKET_EVENTS {
  CONNECT = 'connect',
  CHANNEL_SEND = 'channel.send',
  CHANNEL_SEND_SCHEDULED = 'channel.sendScheduled',
  CHANNEL_EDIT_MESSAGE = 'channel.editMessage',
  CHANNEL_DELETE_MESSAGE = 'channel.deleteMessage',
  CHANNEL_MESSAGE_MODIFICATIONS = 'channel.message.modifications',
  CHANNEL_SCHEDULED_MESSAGES = 'channel.scheduledMessages',
  CHANNEL_SCHEDULED_MESSAGE_MODIFICATION = 'channel.scheduledMessage.modifications',
  AUTH_WELCOME = 'auth.welcome',
  AUTH_HELLO = 'auth.hello',
  CHANNEL_SEARCH = 'channel.search',
  CHANNEL_LIST = 'channel.list',
  CHANNEL_GET_CATEGORIES = 'channel.getCategories',
  CHANNEL_LISTMY = 'channel.listMy',
  CHANNEL_DETAIL = 'channel.details',
  CHANNEL_CREATE = 'channel.create',
  CHANNEL_UPDATE = 'channel.update',
  CHANNEL_DELETE = 'channel.delete',
  CHANNEL_DELETE_ACKNOWLEDGE = 'channel.delete.ack',
  CHANNEL_SUBSCRIBE = 'channel.subscribe',
  CHANNEL_UNSUBSCRIBE = 'channel.unsubscribe',
  CHANNEL_MUTE = 'channel.mute',
  CHANNEL_PIN = 'channel.pin',
  CHANNEL_CONFIGURE = 'channel.configure',
  CHANNEL_CONFIGURE_DETAIL = 'channel.configure.get',
  CHANNEL_MESSAGES = 'channel.messages',
  CHANNEL_MESSAGES_PREVIEW = 'channel.messages.preview',
  CHANNEL_MESSAGES_ACKNOWLEDGE = 'channel.messages.ack',
  CHANNEL_MESSAGES_REPLAY = 'channel.messages.replay',
  CHANNEL_MESSAGES_PEEK = 'channel.messages.peek',
  CHANNEL_MESSAGES_SUMMARY = 'channel.messages.summary',
  CHANNEL_MESSAGE_DETAILS = 'channel.message.details',
  CHANNEL_RELEVANT_MESSAGES = 'channel.relevantMessages',
  CHANNEL_SCHEDULED_MESSAGES_REPLAY = 'channel.scheduledMessages.replay',
  CHANNEL_SCHEDULED_MESSAGES_COUNT = 'channel.scheduledMessage.count',
  CHANNEL_SEND_JOIN_REQUEST = 'channel.invite.request',
  CHANNEL_WITHDRAW_JOIN_REQUEST = 'channel.invite.withdraw',
  CHANNEL_ACKNOWLEDGE_JOIN_REQUEST_DENIAL = 'channel.invite.ack',
  CHANNEL_INVITE_LISTMY = 'channel.invite.listMy',
  CHANNEL_INVITE_USER_ACCEPT = 'channel.invite.user.accept',
  CHANNEL_INVITE_USER_DENY = 'channel.invite.user.deny',
  CHANNEL_JOIN_FETCH_REQUEST_LIST = 'channel.invite.listPending',
  CHANNEL_JOIN_REQUEST_LIST = 'channel.invite.pendingList',
  CHANNEL_JOIN_REQUEST_ACCEPT = 'channel.invite.accept',
  CHANNEL_JOIN_REQUEST_DENY = 'channel.invite.deny',
  CHANNEL_REMOVE_SUBSCRIBER = 'channel.removeSubscriber',
  CHANNEL_LIST_INVITABLE = 'channel.listInvitable',
  CHANNEL_GEOFILTER_LISTMY = 'channel.geoFilter.listMy',
  CHANNEL_GEOFILTER_UPDATE = 'channel.filter.GeoChannel',
  CHANNEL_REPORT_VIOLATION = 'channel.reportViolation',
  APP_LISTMY = 'app.listMy',
  APP_LIST = 'app.list',
  APP_CREATE = 'app.create',
  APP_UPDATE = 'app.update',
  APP_DETAIL = 'app.details',
  APP_DELETE = 'app.delete',
  APP_DELETE_ACKNOWLEDGE = 'app.delete.ack',
  APP_GET_CATEGORIES = 'app.getCategories',
  APP_SUBSCRIBE = 'app.subscribe',
  APP_UNSUBSCRIBE = 'app.unsubscribe',
  USER_CONTACT_LISTMY = 'user.contactListMy',
  USER_CONTACT_LIST_FURTHER = 'user.contactFurtherPersonList',
  USER_PENDING_RECEIVED_CONTACT_LISTMY = 'user.contactOpenRequest',
  USER_PENDING_SENT_CONTACT_LISTMY = 'user.contactMyOpenRequest',
  USER_CONTACT_CREATE_REQUEST = 'user.contactRequest',
  USER_CONTACT_WITHDRAW_SENT_REQUEST = 'user.contactWithdraw',
  USER_CONTACT_ACCEPT_RECEIVED_REQUEST = 'user.contactAccept',
  USER_CONTACT_DENY_RECEIVED_REQUEST = 'user.contactDeny',
  USER_CONTACT_DELETE_EXISTING = 'user.contactDelete',
  USER_CONTACT_REALTIME_UPDATES = 'user.contactUpdated',
  USER_CONTACT_OPEN_REQUEST_COUNT = 'user.contactOpenRequestCount',
  USER_LANGUAGE_PRIMARY = 'user.language.primary',
  SYSTEM_CHANNEL_POLICY = 'system.channel.policy',
  SYSTEM_APP_POLICY = 'system.app.policy',
  SYSTEM_LIST_PRIVILEGES = 'system.list.privileges',
  CHANNEL_ROLE_LIST = 'channel.roleList',
  CHANNEL_CHANGE_ROLE = 'channel.changeToMemberRole',
  SYSTEM_LIST_SUBSCRIBERS = 'system.list.subscribers',
  USER_REQUESTCODE = 'user.requestCode',
  USER_VERIFYCODE = 'user.verifyCode',
  USER_VERIFYEMAIL = 'user.verifyEmail',
  USER_REQUESTVERIFYEMAIL = 'user.requestEmailVerification',
  USER_REQUEST_PASSWORD_CHANGE = 'user.requestPasswordChange',
  USER_CHANGE_PASSWORD = 'user.changePassword',
  USER_REGISTER = 'user.register',
  USER_UPDATE = 'user.update',
  USER_DELETE = 'user.delete',
  USER_PROFILE = 'user.profile',
  USER_LOOKUP = 'user.lookup',
  USER_SEARCH = 'user.search',
  USER_VERIFY_INVITATION_CODE = 'user.verifyInvitationCode',
  USER_REQUEST_INVITATION_CODE = 'user.requestInvitationCode',
  USER_CHAT_SEARCH = 'user.chatSearch',
  USER_META_DATA_SETTINGS = 'user.metaData',
  NOTIFICATION_OPT_IN_OUT = 'notification.optInOut',
  EMBED_TYPE_GET_POLL = 'poll.get',
  EMBED_TYPE_SAVE_POLL = 'poll.save',
  EMBED_TYPE_CHOOSE_POLL_ANSWER = 'poll.choose',
  EMBED_TYPE_UPDATE_POLL = 'poll.update',
  MEDIA_UPLOAD_STATUS_INFO = 'media.upload',
  MEDIA_SETTINGS = 'media.settings',
  MEDIA_GET = 'media.get',
  MEDIA_COPY = 'media.copy',
  REACTIONS_GET_EMOJIS = 'reactions.emojis',
  CHANNEL_MESSAGES_REACTIONS = 'reactions.get',
  REACTIONS_SEND_REACTION = 'reactions.react',
  REACTIONS_UPDATE = 'reactions.update',
  RESTRICTIONS_EVALUATE = 'restriction.get',
  RESTRICTIONS_DETAILS = 'restriction.details',
  EVENT_LIST = 'events.list',
  EVENT_LIST_MY = 'events.listMy',
  EVENT_DETAIL = 'events.detail',
  EVENT_SUBSCRIBE = 'events.subscribe',
  EVENT_UNSUBSCRIBE = 'events.unsubscribe',
  FEATURED_ITEMS = 'featured.items',
  FEATURE_FLAGS_GET = 'feature.flag.get',
  SYSTEM_ERROR = 'system.error',
  HEALTH_STATUS = 'system.healthStatus',
  FILTER_GET_CATEGORIES = 'events.getCategories',
  COMMENT_LIST = 'comment.list',
  COMMENT_CREATE = 'comment.create',
  COMMENT_DELETE = 'comment.delete',
  COMMENT_COUNT = 'comment.count',
  CHAT_UPLOAD_KEYS = 'chat.uploadKeys',
  CHAT_DOWNLOAD_DEVICE_KEYS = 'chat.downloadDeviceKeys',
  CHAT_CLAIM_ONE_TIME_KEYS = 'chat.claimOneTimeKeys',
  CHAT_CRATE_ROOM = 'chat.createRoom',
  CHAT_NEW_ROOM_CREATED = 'chat.newRoomCreated',
  CHAT_UPDATE_ROOM = 'chat.updateRoom',
  CHAT_SEND_TO_ROOM = 'chat.sendToRoom',
  CHAT_SEND_TO_USERS = 'chat.sendToUsers',
  CHAT_MESSAGES = 'chat.messages',
  CHAT_MESSAGES_REPLAY = 'chat.messages.replay',
  CHAT_RELEVANT_MESSAGES = 'chat.relevantMessages',
  CHAT_MESSAGES_SUMMARY = 'chat.messages.summary',
  CHAT_LIST_USER_ROOMS = 'chat.listMy',
  CHAT_DETAILS = 'chat.details',
  CHAT_INBOX_REPLAY = 'chat.inbox.replay',
  CHAT_INBOX_ACK = 'chat.inbox.ack',
  CHAT_ONE_TIME_KEYS_STATUS = 'chat.oneTimeKeysStatus',
  CHAT_MESSAGES_ACKNOWLEDGE = 'chat.messages.ack',
  CHAT_MESSAGES_ACKNOWLEDGE_REPLAY = 'chat.messages.ack.replay',
  CHAT_BULK_MESSAGES_ACKNOWLEDGE_REPLAY = 'chat.messages.bulk.ack.replay',
  CHAT_MESSAGE_REDACT = 'chat.redact',
  CHAT_GET_DIALOG_ID = 'chat.getDialogId',
  CHAT_CREATE_ROOM_VALIDATE_USERS = 'chat.validateUsers',
  CHAT_REMOVE_ROOM = 'chat.remove',
  CHAT_LIST_MEMBERS = 'chat.memberList',
  CHAT_GRANT_OWNERSHIP = 'chat.grantOwnership',
  CHAT_REVOKE_OWNERSHIP = 'chat.revokeOwnership',
  CHAT_LEAVE_ROOM = 'chat.leaveRoom',
  CHAT_ADD_MEMBERS = 'chat.addMember',
  CHAT_MUTE_ROOM = 'chat.mute',
  CHAT_SEARCH = 'chat.search',
  CHAT_GET_AUTO_REPLY = 'chat.getAutoReply',
  CHAT_UPDATE_AUTO_REPLY = 'chat.updateAutoReply',
  CHAT_SCHEDULED_FOR_ROOM = 'chat.scheduleForRoom',
  CHAT_EDIT_SCHEDULED_FOR_ROOM = 'chat.editScheduledForRoom',
  CHAT_SCHEDULED_EVENTS_REPLAY = 'chat.scheduledEvents.replay',
  CHAT_SCHEDULED_EVENT_DELETE = 'chat.deleteScheduledForRoom',
  CHAT_SCHEDULED_EVENT_COUNT = 'chat.countScheduledForRoom',
  INVITE_TO_NETWORK = 'network.invites.create',
  INVITE_CONFIRM_TO_NETWORK = 'network.invites.confirminstallation',
  INVITE_PREFILL_HINT = 'network.invites.prefillHint',
  SUBSCRIBE_TO_NETWORK = 'network.subscribe',
  GEO_AUTOCOMPLETE = 'geo.autocomplete',
  GEO_RESTRICTED_AUTOCOMPLETE = 'geo.countryRestrictedAutocomplete',
  GEO_GEOCODING_REVERSE = 'geo.geocoding.reverse',
  GEO_LOCATION_HISTORY_LIST = 'geo.locationHistory.listMy',
  GEO_LOCATION_HISTORY_UPDATE = 'geo.locationHistory.update',
  GEO_GET_WEATHER_LOCATION = 'geo.weather.location',
  READING_TEXTVIEW = 'reading.textview',
  READING_TEXTVIEW_PREVIEW = 'reading.textview.preview',
  USER_LIST = 'user.list.byId',
  PIN_SUMMARY = 'pin.summary',
  PIN_PIN = 'pin.pin',
  PIN_UNPIN = 'pin.unpin',
  WALLET_CREDENTIAL_DETAILS = 'wallet.credential.details',
  WALLET_CREDENTIAL_CREATE = 'wallet.credential.create',
  WALLET_CREDENTIAL_REMOVE = 'wallet.credential.remove'
}

//use ONLY for e2e cleanup, not for production
export enum E2E_SOCKET_EVENTS {
  CHANNEL_REMOVE = 'channel.remove',
  APP_REMOVE = 'app.remove'
}

export type RPC_SOCKET_EVENTS = `${typeof RPC_EVENT_PREFIX}${SOCKET_EVENTS}`;

export type ALL_SOCKET_EVENTS = SOCKET_EVENTS | RPC_SOCKET_EVENTS | E2E_SOCKET_EVENTS;

export type AuthHelloOptions = {
  disableChatSummaryAutoEmit?: boolean;
  disableChatRelevantAutoEmit?: boolean;
  disableChannelSummaryAutoEmit?: boolean;
  disableChannelRelevantAutoEmit?: boolean;
};

export type AuthHelloPayload = {
  content: string;
  device: string;
  network: string;
  options?: AuthHelloOptions;
};
export type ChannelMessagesReplayPayload = {
  userId: string;
  channelId: string;
  timestamp?: string;
  direction?: PagingReplayDirection;
};
export type ChannelScheduledMessagesCountPayload = { channelId: string; userId: string };
export type ChannelSubscribePayload = { userId?: string; channelId: string; restrictions?: PasswordRestrictionResolveObject };
export type ChannelUnSubscribePayload = { userId: string; channelId: string };
export type SystemListSubscribersPayload = { channelId: string };
export type ChannelMutePayload = { userId: string; channelId: string; isMuted: boolean };
export type ChannelPinPayload = { channelId: string; isPinned: boolean };
export type ChannelListMyPayload = { userId?: string };
export type ChannelListPayload = Partial<Pick<Paging, 'page' | 'size' | 'filter'>>;
export type ChannelDetailPayload = { id: string };
export type ChannelUpdatePayload = Pick<ChannelDTO, 'id'> & Partial<ChannelDTO> &
{
  userId: string;
  restrictions?: RestrictionResolveObjectType[];
};
export type AppUpdatePayload = Pick<AppDTO, 'id'> & Partial<AppDTO> & { userId: string };
export type ChannelCreatePayload = Pick<ChannelDTO, 'title'> & Partial<ChannelDTO> &
{
  userId?: string;
  restrictions?: RestrictionResolveObjectType[];
};
export type ChannelDeletePayload = { id: string; deletedReason?: string };
export type ChannelDeleteAcknowledgePayload = { userId?: string; channelId: string };
export type ChannelMessageAcknowledgePayload = { userId: string; channelId: string; lastAckTimestamp: string; unreadCountDiff?: number };
export type ChannelSearchPayload = { userId: string; query: string };
export type ChannelSendPayload = {
  id?: string; userId?: string; channelId: string;
  timestamp: string; content?: string; embeds?: EmbedDTOTypes[];
} & Partial<DraftMessageData>;
export type ChannelEditMessagePayload = {
  id: string; userId: string; content?: string; embeds?: EmbedDTOTypes[]; scheduled?: boolean; timestamp?: string;
};

export type ChannelDeleteMessagePayload = { id: string; reason?: string };
export type ChannelMessagePreviewPayload = { userId?: string; content: string; timestamp: string };
export type ChannelMessageDetailPayload = { messageId: string };
export type ChannelPostReportPayload = { messageId: string; authorName: string; channelName: string; postText?: string; notice: string };
export type AppCreatePayload = Pick<AppDTO, 'title' | 'uri'> & Partial<AppDTO> &
{
  userId?: string;
};
export type AppDeletePayload = { id: string };
export type AppDeleteAcknowledgePayload = { appId: string; userId?: string };
export type AppRemovePayload = { appId: string };

export type ChannelRemoveSubscriberPayload = { userId: string; channelId: string };
export type AppDetailPayload = { id: string };
export type AppListMyPayload = { userId?: string };
export type AppListPayload = Partial<Pick<Paging, 'page' | 'size' | 'filter'>>;
export type AppSearchPayload = { userId: string; query: string };
export type AppSubscribePayload = { userId?: string; appId: string };
export type AppUnSubscribePayload = { userId?: string; appId: string };
export type SystemChannelPolicyPayload = { userId: string; channelId?: string };
export type SystemAppPolicyPayload = { userId: string; appId?: string };
export type ChannelListRolesPayload = { channelId: string };
export type ChannelChangeRolePayload = { userId: string; channelId: string; targetRole: ChannelMemberRoles };
export enum ChannelMemberRoles {
  ADMIN = 'ADMIN',
  CONTENT_PROVIDER = 'CONTENT_PROVIDER',
  SUBSCRIBER = 'SUBSCRIBER'
}
export type ChannelRestrictionsPayload = { contextId: string; contextType: RestrictionContextType };
export type UserRequestCodePayload = { identifier: string };
export type UserVerifyCodePayload = { identifier: string; code: string };
export type UserVerifyEmailPayload = { userId: string; code: string };
export type UserRequestVerifyEmailPayload = { userId: string };
export type UserRegisterPayload = {
  identifier: string;
  code: string;
  christianName: string;
  lastName: string;
  email: string;
  password: string;
  avatarUrl: string;
  discoverable: boolean;
};
export type UserUpdatePayload =
  & BaseUserInfo
  & Partial<UserProfileSettings>
  & Partial<UserMetaDataSettingsDTO>;
export type UserProfilePayload = { userId: string };
export type UserDeletePayload = { userId: string };
export type UserLookUpPayload = { phone: string };
export type UserSearchPayload = { search: string; pageSize?: number; alreadyFetchUserCount?: number };
export type UserVerifyInvitationCodePayload = { code: string };
export type UserListPayload = { ids: string[] };
export type UserLanguagePrimaryPayload = { languageCode: string };
export type NotificationOptInOutPayload = {
  userId: string; deviceId: string;
  registrationId: string; platform: string; isEnabled: boolean;
};
export type GetReactionsPayload = {
  /**
   * @deprecated messageIds is compatible with the old get reactions API
   * Use parentIds instead
   */
  messageIds?: string[];
  parentIds?: string[];
};
export type SendReactionPayload = {
  emojiId: number;
  parentId: string;
  parentType: ReactionContext;
  broadcastInformation?: BroadcastInformation;
};
export type EventSubscriptionPayload = {
  id: string;
};
export type EventSubscriptionResponse = {
  id: string;
  subscribed: boolean;
};
export type MediaUploadPayload = {
  src: string | ArrayBuffer;
  name: string;
  extension: string;
  channelId: string;
  contextType?: MediaContextTypes;
};

export type ChannelMessagesResponse = ChannelMessageDTO[];
export type ChannelScheduledMessagesCountResponse = { count: number; channelId: string };
export type ChannelMessagesModificationsResponse = ChannelMessageDTO;
export type ChannelMessagePreviewResponse = { requestId: number; embed: EmbeddedLinkDTO };
export enum ChannelMessagesDetailError {
  ERROR_NOT_FOUND = 'message_not_found',
  ERROR_MESSAGE_DELETED = 'message_deleted',
  ERROR_CHANNEL_DELETED = 'channel_not_found',
  ERROR_CHANNEL_RESTRICTED = 'channel_restricted'
}
export type ChannelMessagesDetailResponse = {
  ok: boolean;
  error: ChannelMessagesDetailError;
  message: ChannelMessageDTO;
  channel: ChannelDTO;
};
export type ChannelReportViolationResponse = {
  ok: boolean;
  message?: string;
};
export type ChannelSubscribeResponse = { result: { allow: boolean } };
export type SystemListSubscribersResponse = { channelId: string; anonymousCount: number; users: (SimpleUserDTO & UserRole)[] };
export type ChannelDiscoverListResponse = { content: ChannelDTO[] } & Paging;
export type ChannelUserListResponse = ChannelSubscriptionDTO[];
export type ChannelDetailResponse = ChannelDTO;
export type ChannelUpdateResponse = ChannelDTO & { restrictions?: RestrictionUpdateResponseDTO[] };
export type ChannelCreateResponse = ChannelDTO;
export type ChannelDeleteResponse = ChannelDTO;
export type AppDetailResponse = AppDTO;
export type AppCreateResponse = AppDTO;
export type AppUpdateResponse = AppDTO;
export type UserAppListResponse = AppSubscriptionDTO[];
export type DiscoverAppListResponse = {
  content: AppDTO[];
} & Paging;
export type ChannelMessageAcknowledgeResponse = ChannelMessageAcknowledgePayload;
export type SystemChannelPolicyResponse = {
  result: {
    send: boolean;
    create: boolean;
    update: boolean;
    remove: boolean;
    invites: boolean;
    listAdmin: boolean;
    listContentProvider: boolean;
    listSubscribers: boolean;
    addAdmin: boolean;
    addManagedAdmin: boolean;
    addContentProvider: boolean;
    deleteAdmin: boolean;
    deleteManagedAdmin: boolean;
    deleteContentProvider: boolean;
    deleteComment: boolean;
    deleteMessage: boolean;
    deleteOwnMessage: boolean;
    editMessage: boolean;
    editOwnMessage: boolean;
    manage: boolean;
    updateRestriction: boolean;
    unsubscribe: boolean;
    removeSubscriber: boolean;
  };
  channelId: string;
};
export type SystemAppPolicyResponse = {
  result: { create: boolean; update: boolean; remove: boolean }; appId: string;
};
export type SystemPrivilegeListResponse = PrivilegeDTO[];
export type ChannelRoleListResponse = { channelId: string; contentProviders: SimpleUserDTO[]; admins: SimpleUserDTO[] };
export type ChannelRestrictionResponse = { contextId: string; contextType: RestrictionContextType; restrictionTypes: RestrictionDTO[] };
export type ChannelRestrictionDetailsResponse = {
  contextId: string;
  contextType: RestrictionContextType;
  restrictions: RestrictionUpdateResponseDTO[];
};
export type UserVerifyCodeResponse = { flow: 'registration' | 'resetpassword'; valid: boolean };
export type UserVerifyEmailResponse = { code: string; valid: boolean };
export type UserRegisterResponse = { identifier: string; ok: boolean; errors?: { email?: 'Email' } };
export type UserDeleteResponse = { userId: string; ok: boolean };
export type UserVerifyInvitationCodeResponse = { ok: boolean };
export type UserRequestInvitationCodeResponse = { code: string };
export type UserListResponse = { users: SimpleUserDTO[] };
export type UserPrimaryLanguageResponse = { primaryLanguage: string };
export type UserProfileResponse = {
  userId: string;
  christianName: string;
  lastName: string;
  displayname: string;
  avatarUrl: string;
  emailVerified: boolean;
  email: string;
  identifier: string;
  ok: boolean;
  additionalInformation: string;
  primaryLanguage: string;
} & UserProfileSettings;
export type PublicUserProfileResponse = Pick<UserProfileResponse, 'userId' | 'displayname' | 'avatarUrl' | 'additionalInformation'> & {
  enableChatViaProfile?: boolean;
  requestStatus: ContactRequestStatus;
};
export type UserProfileResponseTypes = UserProfileResponse | PublicUserProfileResponse;
export type UserRole = {
  roleId: string;
};
export type SimpleUserDTO = {
  userId: string;
  displayName: string;
  avatarUrl: string;
  isDeleted?: boolean;
};
export type SubscribersDTO = {
  subscribedUsers: (SimpleUserDTO & UserRole)[];
  anonymousUsers: number;
};
export type EmojisResponse = Emoji[];
export type GetReactionsResponse = { [id: string]: ReactionSummary };
export type ReactionUpdateResponse = GetReactionsResponse;
export type EventListResponse = {
  content: EventListItemDTO[];
} & EventPaging;
export type MediaUploadResponse = {
  uploadId: string;
  channelId?: string;
  uploadSucceeded: boolean;
  mediaProcessed: boolean;
  error: boolean;
  errorCode?: string;
  errorMessage?: string;
  /**
   * @deprecated Use dbMediaMetadata instead
   */
  mediaMetadata?: MediaMetadata;
  dbMediaMetadata?: EmbeddedMediaDTO;
  userId?: string;
  contexts?: MediaContext[];
};
export type MediaSettings = {
  maxFileSizeInMiB: number;
  maxImageDimension: number;
};
export type SystemErrorResponse = {
  errorMessage: string;
  errorType: string;
  originalMessage: any;
  topic: SOCKET_EVENTS;
};

export type SystemHealthStatusResponse = {
  systemCoreUp: boolean;
  systemSecondaryUp: boolean;
};

export type MediaMetadata = {
  mediaUrl: string;
};

export enum MediaContextTypes {
  PROFILE = 'PROFILE',
  CHANNEL_MESSAGE = 'CHANNEL_MESSAGE',
  REGISTRATION = 'REGISTRATION',
  CHANNEL = 'CHANNEL',
  APP = 'APP',
  CHAT = 'CHAT',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  PREVIEW = 'PREVIEW',
}

export type MediaContext = {
  type: MediaContextTypes;
  id: string;
  parent?: MediaContext;
  compositeId?: string;
};

export enum MediaUseCases {
  PROFILE = 'profile',
  MESSAGE = 'message'
}

export type ListEventRequestProps = EventPaging & {
  limit?: number;
};

export type ListEventRequestParameters = Partial<Pick<Paging, 'filter'> & ListEventRequestProps>;

export type FeaturedItemsResponse = {
  type: 'message';
  message: ChannelMessageDTO;
  channel: ChannelDTO;
};

export type CategoryResponse = CategoriesDTO;

export type CommentListRequest = {
  parentId: string;
} & Partial<Pick<Paging, 'page' | 'size'>> | Sort;

export type Sort = {
  sortDirection: 'ASC' | 'DESC';
  sortBy: string;
};

export type CommentListResponse = {
  content: WenComment[];
} & Paging;

export type GetCommentCountPayload = {
  parentIds: string[];
};

export type CommentCreateRequest = WenCommentCreate;
export type CommentCreateResponse = WenComment;

export type CommentDeletePayload = {
  id: string;
};

export type CommentDeleteResponse = {
  id: string;
  parentId: string;
  idsOfDeletedComments: string[];
  success: boolean;
};

export type CommentCountResponse = {
  commentCountByParentId: { [key: string]: number };
};

export type ChatMessageAcknowledgePayload = ChatMessageAcknowledgeDTO;
export type ChatMessageAcknowledgeReplayPayload = { roomId: string };
export type ChatBulkMessageAcknowledgeReplayPayload = { roomIds: string[] };
export type ChatMessageAcknowledgeReplayResponse = RoomAcknowledgeDTO;
export type ChatMuteRoomPayload = { roomId: string; isMuted: boolean };
export type ChatMuteRoomResponse = ChatMuteRoomPayload;
export type ChatSearchPayload = { search: string; lastMessage: string; roomType?: RoomType; pagesize?: number };
export type ChatGetAutoReplyResponse = { enabled: boolean; active: boolean; activeFrom: string; activeUntil: string; replyMessage: string };
export type ChatUpdateAutoReplyPayload =
  { enabled: boolean; active?: boolean; activeFrom?: string; activeUntil?: string; replyMessage: string };
export type SimpleChatDTO = {
  id: string;
  icon: string;
  description: string;
  title: string;
  type: RoomType;
  userId?: string;
  lastMessage: string;
};
export type ChatSearchResponse = { chats: SimpleChatDTO[] };

export type SocketPayload = { userId?: string } & (AuthHelloPayload
  | ChannelMessagesReplayPayload
  | ChannelScheduledMessagesCountPayload
  | ChannelMessageDetailPayload
  | ChannelSubscribePayload
  | ChannelUnSubscribePayload
  | SystemListSubscribersPayload
  | ChannelMutePayload
  | ChannelListPayload
  | ChannelListMyPayload
  | ChannelMessageAcknowledgePayload
  | ChannelSearchPayload
  | ChannelChangeRolePayload
  | UserContactListPayload
  | FurtherUserContactListPayload
  | UserPendingContactListPayload
  | ContactRequestPayload
  | ContactRequestCreatePayload
  | SavePollRequest
  | GetPollRequest
  | ChosenOptionRequest
  | MediaUploadPayload
  | ChannelRestrictionsPayload
  | GetReactionsPayload
  | SendReactionPayload
  | AppListPayload
  | AppSubscribePayload
  | ListEventRequestParameters
  | CommentListRequest
  | CommentCreateRequest
  | CommentDeletePayload
  | CommentCountResponse
  | GetCommentCountPayload
  | UserSearchPayload
  | UserLookUpPayload
  | UserLanguagePrimaryPayload
  | UserVerifyInvitationCodePayload
  | DownloadDeviceKeysPayload
  | ClaimOneTimeKeysPayload
  | CreateRoomPayload
  | RoomMessagesReplayPayload
  | RoomMessagesSummaryPayload
  | SendToRoomEventPayload
  | ScheduledSendToRoomEventPayload
  | EditScheduledSendToRoomEventPayload
  | SendToUsersEventPayload
  | ChatMessageAcknowledgePayload
  | ChatMessageAcknowledgeReplayPayload
  | ChatBulkMessageAcknowledgeReplayPayload
  | InvitePayload
  | RoomUpdatePayload
  | GeoAutocompletePayload
  | GeoReversePayload
  | GeoLocationUpdatePayload
  | GeoWeatherPayload
  | NetworkSubscribePayload
  | ChatMuteRoomPayload
  | UserListPayload
  | PinRequest
  | ChannelListRolesPayload
  | ChatUpdateAutoReplyPayload
  | GetMediaPayload
  | UserChatSearchPayload
  | ReadingTextViewPayload
  | CopyMediaPayload
  | WalletCreateCredentialPayload
  | WalletRemoveCredentialPayload
  | ReadingTextViewPreviewPayload
  | UserMetaDataSettingsResponse
  | UserRequestCodePayload
  | UserRequestPasswordChangePayload
  | UserRequestPasswordChangePayload
);
