import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ChannelMessagesReplayPayload, SocketIoService } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { UserData } from '../../auth/models/UserData';
import { RootState } from '../../root/public-api';
import { fetchHistoricalMessagesForCurrentChannel, updateChannelList } from '../channel.actions';
import { selectCurrentChannel, selectIsChannelSubscribed } from '../channel.selectors';

export const createChannelHistoricalMessageEffect = (
  store: Store<RootState>,
  actions$: Actions,
  currentUser$: Observable<UserData>,
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(fetchHistoricalMessagesForCurrentChannel),
    withLatestFrom(store.pipe(select(selectCurrentChannel))),
    withLatestFrom(currentUser$),
    mapWithFirstFrom(([[_, { id }], __]) => store.pipe(
      select(selectIsChannelSubscribed),
      map((isChannelSubscribedFn) => isChannelSubscribedFn(id)),
    )),
    map(([[[payload, channel], { userId }], isSubscribed]) => {
      const params: ChannelMessagesReplayPayload = {
        userId, channelId: channel.id
      };
      if (payload.timestamp) {
        params.timestamp = payload.timestamp;
      }
      if (payload.direction) {
        params.direction = payload.direction;
      }
      if (isSubscribed) {
        socketIoService.channel.messagesReplay.emit(params);
      } else {
        socketIoService.channel.peek.emit(params);
      }
      return updateChannelList({ channels: [{ ...channel }] });
    })
  ));
};
