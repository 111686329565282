import { initiateAppForUser } from './auth.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from './auth.state';

export const authInitialState: AuthState = {
  userData: null,
};

const authReducer = createReducer(
  authInitialState,
  on(initiateAppForUser, (state, { userData }) => {
    return { ...state, userData };
  })
);

export function authReducerFactory(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
