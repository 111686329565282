import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogModule } from '@portal/wen-components';
import { FormProgressModule } from '../../../shared/components/form-progress/form-progress.module';
import { InviteesDisplayComponent } from './invitees-display/invitees-display.component';
import { InviteCounterDescriptionDirective, InviteCounterLabelDirective } from './invitees-display/invitees-display.directives';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ConfirmationDialogModule,
    MatDialogModule,
    FormProgressModule,
  ],
  declarations: [
    InviteesDisplayComponent,
    InviteCounterLabelDirective,
    InviteCounterDescriptionDirective,
  ],
  exports: [
    InviteesDisplayComponent,
    InviteCounterLabelDirective,
    InviteCounterDescriptionDirective,
    FormProgressModule,
  ]
})
export class InviteCommonModule { }
