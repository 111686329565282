import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OutletHelper } from '../../core/services/navigation/outlet-specific/outlet-helper';
import { DataObjectType } from '../../core/store/common/data-objects';
import { clearAllFormValues, clearFormValues, initEditForm, saveEditForm, setEditFormChangedValue } from '../../core/store/form/form.actions';
import { selectEditFormById } from '../../core/store/form/form.selectors';
import { FormDataObject } from '../../core/store/form/form.state';
import { RootState } from '../../core/store/root/public-api';

@Injectable()
export class FormStoreService<T extends FormDataObject = FormDataObject> {

  constructor(
    private readonly store: Store<RootState>,
    private readonly outletHelper: OutletHelper,
  ) { }

  initializeForm(newValue?: T | Record<string, unknown>) {
    const formId = this.outletHelper.resolveFormStoreKey();
    const initialValues = newValue || {};
    this.store.dispatch(initEditForm({ formId, initialValues }));
  }

  saveForm(dataObjectType: DataObjectType) {
    const formId = this.outletHelper.resolveFormStoreKey();
    this.store.dispatch(saveEditForm({ formId, dataObjectType }));
  }

  clearFormData() {
    const formId = this.outletHelper.resolveFormStoreKey();
    this.store.dispatch(clearFormValues({ formId }));
  }

  clearAllFormData() {
    this.store.dispatch(clearAllFormValues());
  }

  getFormData() {
    const formId = this.outletHelper.resolveFormStoreKey();
    return this.store.pipe(
      select(selectEditFormById(formId))
    );
  }

  patchFormValue(newValues: Partial<T>) {
    const formId = this.outletHelper.resolveFormStoreKey();
    this.store.dispatch(setEditFormChangedValue({ formId, changedValues: newValues }));
  }

}
