<wen-swipeable [swipeDirection]="'right'" (swipeGesture)="onSwipe()" [enableSwipe]="canSwipe()">
  @if (canSwipe()) {
    <wen-icon class="wen-reply-icon" iconName="reply" swipeableLeftIndicator></wen-icon>
  }
  <div class="wen-message-wrapper" [wen-test-id]="'wen-message-box'" swipeableContent>
    @if (isChatMessageBox) {
      <div class="wen-message-box-parts">
        @if (!highlighted && sender) {
          <wen-circle-image-view [imageUrl]="getAvatarUrl()" [imageSize]="{height: 32}" [placeholderText]="sender" class="wen-message-box-tail-user" (click)="onSenderClicked()"></wen-circle-image-view>
        }
        <div class="wen-message-box-tail-wrapper">
          <div class="wen-message-box-tail" [class.wen-auto-reply-tail]="isAutoReply"></div>
        </div>
        <ng-container *ngTemplateOutlet="messageBoxWrapper"></ng-container>
      </div>
    } @else {
      <ng-container *ngTemplateOutlet="messageBoxWrapper"></ng-container>
      @if (footerFunctionsEnabled && commentsVisible && commentsEnabled) {
        <wen-comment-list [messageId]="messageId"></wen-comment-list>
      }
    }

    <ng-template #messageBoxWrapper>
      <div class="wen-message-box-wrapper" [style.max-width]="maxContentWidth">
        @if (isAutoReply && isChatMessageBox) {
          <div portal-caption class="wen-message-box-auto-reply-header" [ngClass]="{ 'wen-current-user': highlighted }">
            <wen-icon class="wen-icon-small" iconName="auto_reply" color="{{ replyIconColor }}"></wen-icon>
            {{ 'USER_PROFILE_AUTO_REPLY' | translate }}
          </div>
        }
        <div class="wen-message-box-content-wrapper" [ngClass]="{ 'wen-current-user': highlighted, 'wen-auto-reply-message': isAutoReply }" [wen-test-id]="'message-box-content-wrapper'" (press)="onMessageContentClicked()"
          [class.wen-message-box-has-content]="content">
          @if (sender) {
            <div class="wen-message-box-header" (click)="onSenderClicked()">
              <wen-circle-image-view class="wen-message-box-sender-image" [imageUrl]="getAvatarUrl()" [placeholderText]="sender">
              </wen-circle-image-view>
              <div class="wen-message-box-info">
                <div portal-caption class="wen-message-box-sender" [wenTextColorizer]="sender" #senderElement>
                  {{ sender }}
                </div>
                <div class="wen-message-box-meta">
                  @if (formattedTimeStamp) {
                    <div class="wen-message-box-timestamp">{{ formattedTimeStamp }}</div>
                  }
                  @if (isEdited) {
                    <div class="wen-message-box-edited-label">{{ 'MESSAGE_BOX_EDITED_INFO_LABEL' | translate }}</div>
                  }
                </div>
              </div>
            </div>
          }

          @if (isChatMessageBox) {
            <div class="wen-message-box-inline-content-wrapper">
              <ng-container *ngTemplateOutlet="messageBoxContent"></ng-container>
              <ng-container *ngTemplateOutlet="messageBoxFooter"></ng-container>
            </div>
          } @else {
            <ng-container *ngTemplateOutlet="messageBoxContent"></ng-container>
          }

          <ng-template #messageBoxContent>
            @if (isValidMessage) {
              <wen-message-box-content [message]="message" [textBelowEmbeds]="isChatMessageBox" [ngClass]="{ 'wen-highlighted-content': highlighted }"></wen-message-box-content>
            } @else {
              <ng-content select="wen-message-box-content-template, [wen-message-box-content-template]"></ng-content>
            }
          </ng-template>

          <div class="wen-message-box-footer-container">
            @if (isChatMessageBox) {
              <ng-container [ngTemplateOutlet]="messageBoxFooter"></ng-container>
            }

            <ng-template #messageBoxFooter>
              <div class="wen-message-box-footer">
                @if (isEdited) {
                  <div class="wen-message-box-edited-label">{{ 'MESSAGE_BOX_EDITED_INFO_LABEL' | translate }}</div>
                }
                @if (formattedTimeStamp) {
                  <div class="wen-message-box-timestamp">{{ formattedTimeStamp }}</div>
                }
                @if (status && highlighted) {
                  <wen-message-status-indicator [messageStatus]="status" [iconClassName]="'wen-icon-tiny'" class="wen-message-box-status-indicator"></wen-message-status-indicator>
                }
              </div>
            </ng-template>

            @if (footerFunctionsEnabled && !disableEmojiReaction) {
              <wen-emoji-reactions
                [referenceId]="referenceId"
                class="wen-message-box-emoji-reaction"
                [disabled]="!isValidMessage || scheduled"
                [ngClass]="{ 'wen-current-user': highlighted }"
                [showPlaceholder]="!hideEmojiReactionPlaceholder"
                [wen-test-id]="'wen-reactions-button'"
                [reactionContext]="reactionContext"
                (addReaction)="onEmojiReactionsClicked()">
              </wen-emoji-reactions>
            }

            @if (footerFunctionsEnabled && isChannelMessageBox) {
              <div class="wen-message-box-footer-container-comment-section">
                <wen-open-comment-button
                  class="wen-message-box-open-comment-button"
                  [class.disabled]="!isValidMessage || scheduled || !commentsEnabled || isDraft"
                  (click)="onOpenCommentsClicked()"
                  [deactivated]="!commentsEnabled"
                  [disabled]="!isValidMessage || scheduled || isDraft"
                  [wen-test-id]="'wen-comment-button'"
                  [messageId]="messageId"
                ></wen-open-comment-button>
                @if (textToSpeechButtonVisible) {
                  <wen-text-to-speech-button
                    class="wen-message-box-footer-button"
                    [payload]="{ metadata: {context: messageContext, author: sender, timestamp: rawTimeStamp }, contextId: messageId, content: content }"
                  ></wen-text-to-speech-button>
                }
                @if (challengeVisible) {
                  <wen-icon
                    (click)="onChallengeClicked()"
                    class="wen-message-box-footer-button"
                    [iconName]="'challenge'"
                  ></wen-icon>
                }
                @if (isForwardable) {
                  <wen-icon
                    class="wen-message-box-footer-button"
                    iconName="forward"
                    (click)="forwardDeepLink()"
                  ></wen-icon>
                } @else if (isSharable) {
                  <wen-icon
                    class="wen-message-box-footer-button"
                    iconName="share"
                    (click)="shareDeepLink()"
                  ></wen-icon>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</wen-swipeable>
