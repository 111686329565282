import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EmbeddedLinkDTO } from '@portal/wen-backend-api';
import { distinctUntilChanged, map, Observable, shareReplay, tap } from 'rxjs';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../../core/store/root/root.selectors';
import { MessageSendFieldDataSource } from '../../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { CHANNEL_MESSAGE_TYPE_SELECTOR_MENU_ID } from '../../../../../shared/components/message-type-selector/constants/type-selector-ids';
import { channelViewIdentifier } from '../../../tokens';

@Component({
  selector: 'wen-channel-schedule-message-send-field',
  templateUrl: './channel-schedule-message-send-field.component.html',
  styleUrls: ['./channel-schedule-message-send-field.component.scss'],
})
export class ChannelScheduledMessageSendFieldComponent implements OnInit {

  channelId$: Observable<string>;
  hasMediaEmbed$: Observable<boolean>;
  isEdit$: Observable<boolean>;
  linkPreviewContent$: Observable<string>;

  messageSelectorId: string = CHANNEL_MESSAGE_TYPE_SELECTOR_MENU_ID;

  constructor(
    private readonly store: Store<RootState>,
    private readonly dataSource: MessageSendFieldDataSource,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.channelId$ = this.store.pipe(
      select(selectRouteParam(channelViewIdentifier)),
      firstExisty(),
      shareReplay(1)
    );

    this.hasMediaEmbed$ = this.dataSource.draftMessage$.pipe(
      map(message => message?.fileEmbed),
      map(url => Boolean(url)),
      tap(() => this.cdr.detectChanges()),
      distinctUntilChanged(),
    );

    this.isEdit$ = this.dataSource.draftMessage$.pipe(
      map(message => Boolean(message?.id)),
      distinctUntilChanged(),
    );

    this.linkPreviewContent$ = this.dataSource.draftMessage$.pipe(
      map(draftMessage => draftMessage?.content),
      distinctUntilChanged()
    );
  }

  onMessageSent() {
    this.dataSource.sendMessage();
  }

  stopEditingMessage() {
    this.dataSource.discardDraftMessage();
  }

  updateDraftMessageLinkEmbed(embed: EmbeddedLinkDTO) {
    this.dataSource.updateDraftMessage({ linkEmbed: embed });
  }

}
