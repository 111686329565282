import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, merge, shareReplay } from 'rxjs';
import { cancelChatMessagesDistribution, finishChatMessagesDistribution } from '../../../store/chat/chat.actions';
import { RootState } from '../../../store/root/public-api';
import { updateProgressState } from '../../../store/ui/ui.actions';
import { ProgressProcessType } from '../../../store/ui/ui.state';
import { DistributionChatAttachmentLoader } from '../../smartdesign/chat-distribution/distribution-chat-attachment-loader';
import { EncryptedChatMessageSender } from './encrypted-chat-message-sender';
import { MessageLoaderStrategyFactory } from './use-cases/message-loader';
import { ChatMessageComposerTransaction } from '@portal/wen-chat-client';
import { ConfirmationDialogData, OverlayManager } from '@portal/wen-components';

@Injectable()
export class CrmChatMessageSender {

  private currentTransaction: ChatMessageComposerTransaction = null;

  private readonly dialogData: ConfirmationDialogData = {
    content: 'CRM_DISTRIBUTE_CHAT_ABORT_SENDING_DIALOG_CONTENT',
    dismissLabel: 'CRM_DISTRIBUTE_CHAT_ABORT_SENDING_DIALOG_DISMISS_LABEL',
    okLabel: 'CRM_DISTRIBUTE_CHAT_ABORT_SENDING_DIALOG_OKAY_LABEL'
  };

  constructor(
    private readonly store: Store<RootState>,
    private readonly messageLoaderStrategyFactory: MessageLoaderStrategyFactory,
    private readonly attachmentLoader: DistributionChatAttachmentLoader,
    private readonly chatMessageSender: EncryptedChatMessageSender,
    private readonly overlayManager: OverlayManager,
  ) { }

  distributeCRMChatMessages() {
    this.store.dispatch(updateProgressState({ newState: { hasProgress: true, progressType: ProgressProcessType.BACKGROUND } }));
    const messageLoader = this.messageLoaderStrategyFactory.createForDistribution();
    this.currentTransaction = this.chatMessageSender.sendMessages(messageLoader);
    const allMessageSendFinished$ = this.currentTransaction.allMessageSendFinished$;
    allMessageSendFinished$.subscribe((result) => {

      const succeeded = result.getSuccessfulMessageSendResults();
      const failed = result.getErrorMessageSendResults();
      const succeededRecipientIds = succeeded.map(succ => succ.transactionData.dialogUserId);
      const failedRecipientIds = failed.map(fail => fail.transactionData.dialogUserId);

      this.attachmentLoader.invalidateAttachmentDataCache();
      this.store.dispatch(finishChatMessagesDistribution({ succeededRecipientIds, failedRecipientIds }));
    });
    const singleMessageSendFinished$ = merge(
      this.currentTransaction.singleMessageSendFinished$, this.currentTransaction.singleMessageSendError$
    ).pipe(
      shareReplay(1)
    );
    this.currentTransaction.execute();
    return {
      singleMessageSendFinished$
    };
  }

  pauseMessageSending() {
    if (!this.currentTransaction) {
      this.store.dispatch(cancelChatMessagesDistribution());
      return;
    }

    this.currentTransaction.pause();
    const dialog = this.overlayManager.dialog.openConfirmationDialogWithTranslationKeys(this.dialogData);
    dialog.afterClosed().pipe(
      first(),
    ).subscribe(dialogResult => {
      if (dialogResult?.result === 'ok') {
        this.currentTransaction.abort();
      } else {
        this.currentTransaction.resume();
      }
    });
  }

}
