import { AnimationEvent } from '@angular/animations';
import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { filter, first, Observable, Subject } from 'rxjs';
import { ContextMenuComponent } from './context-menu/context-menu.component';

export const CONTEXT_MENU_ID = 'CONTEXT_MENU_ID';

export const CONTEXT_MENU_REF = new InjectionToken<ContextMenuRef>('CONTEXT_MENU_REF');


interface ContextMenuNotifications<T = any> {
  afterOpened(): Observable<void>;
  beforeClosed(): Observable<void>;
  afterClosed(): Observable<T>;
  backdropClick(): Observable<Event>;
}

export interface ContextMenuConfig extends OverlayConfig {
  /** ID for the context menu */
  id: string;
}

export class ContextMenuRef<R = any> implements ContextMenuNotifications<R> {
  /** Unique ID for the context menu */
  readonly id: string;

  readonly menuRef: OverlayRef;

  closed$: Observable<R> = new Subject<R>();

  containerInstance: ContextMenuComponent;

  constructor(
    private readonly overlayRef: OverlayRef,
    private readonly config: ContextMenuConfig
  ) {
    this.id = config.id;
    this.menuRef = overlayRef;
  }

  backdropClick() {
    return this.menuRef.backdropClick();
  }

  afterOpened(): Observable<void> {
    return this.menuRef.attachments();
  }

  beforeClosed(): Observable<void> {
    return this.menuRef.detachments();
  }

  afterClosed(): Observable<R> {
    return this.closed$;
  }

  close(result?: R) {
    this.menuRef.detach();
    const closedSubject = this.closed$ as Subject<R>;
    this.containerInstance.animationStateChanged.pipe(
      filter((animationState: AnimationEvent) => animationState.phaseName === 'done' && animationState.toState === 'void'),
      first()
    ).subscribe(() => {
      this.menuRef.dispose();
      closedSubject.next(result);
      closedSubject.complete();
      this.containerInstance = null;
    });
  }
}
