import { AnimationControls, animate } from 'motion';
import { ScrollerWrapper } from '../../../../core/services/scrolling/scroller-wrapper';

export enum AnimatedScrollerBehavior {
  IMMEDIATE, ANIMATED
}

export class AnimatedScroller {

  private activeAnimation: AnimationControls;
  private behavior: AnimatedScrollerBehavior;

  constructor(
    private scrollerWrapper: ScrollerWrapper
  ) { }

  setBehavior(newValue: AnimatedScrollerBehavior) {
    this.behavior = newValue;
  }

  isInProgress() {
    return Boolean(this.activeAnimation);
  }

  scrollToBottom(targetElement: HTMLElement) {
    this.activeAnimation?.stop();
    switch (this.behavior) {
      case AnimatedScrollerBehavior.IMMEDIATE:
        this.scrollToBottomImmediately();
        return;
      case AnimatedScrollerBehavior.ANIMATED:
        this.scrollToBottomAnimated(targetElement);
        return;
      default:
        throw new Error('Unsupported animation behavior!');
    }
  }

  private scrollToBottomImmediately() {
    this.scrollerWrapper.scrollTo({ bottom: 0 });
  }

  private scrollToBottomAnimated(targetElement: HTMLElement) {
    const targetScrollTop = targetElement.scrollHeight - targetElement.clientHeight;
    const currentScrollTop = targetElement.scrollTop;
    const scrollableDistance = targetScrollTop - currentScrollTop;
    this.activeAnimation = animate(
      (progress) => {
        targetElement.scrollTop = currentScrollTop + scrollableDistance * progress;
      },
      { duration: 0.1, easing: 'ease-in' }
    );
    const currentControls = this.activeAnimation;
    this.activeAnimation.finished.then(() => {
      setTimeout(() => {
        if (this.activeAnimation === currentControls) {
          this.activeAnimation = null;
        }
      }, 1000);
    });
  }

}
