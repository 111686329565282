import { NgModule } from '@angular/core';
import { EmbedCreator } from './message-composer/core/embeds/embed-creator';
import { EmbedImageUploader } from './message-composer/core/embeds/embed-image-uploader';
import { EmbedMediaReplicator } from './message-composer/core/embeds/embed-media-replicator';
import { EmbedPollCreator } from './message-composer/core/embeds/embed-poll-creator';
import { EmbeddedForwardUploadFactory } from './message-composer/core/embeds/embed-type-handlers/embedded-forward-handler';
import { EmbeddedMediaUploaderFactory } from './message-composer/core/embeds/embed-type-handlers/embedded-media-handler';
import { ChannelMessageComposer } from './message-composer/object-types/channel/channel-message-composer';
import { ChannelMessageComposerItem } from './message-composer/object-types/channel/channel-message-composer-item';
import { ChatMessageComposer } from './message-composer/object-types/chat/chat-message-composer';
import { ChatMessageComposerItem } from './message-composer/object-types/chat/chat-message-composer-item';
import { WeLocalImageUrlTransformator } from './we-local/we-local-image-url-transformator';

@NgModule({
  providers: [
    EmbedImageUploader,
    EmbedPollCreator,
    EmbedMediaReplicator,
    EmbedCreator,
    ChannelMessageComposer,
    EmbeddedMediaUploaderFactory,
    EmbeddedForwardUploadFactory,
    ChannelMessageComposerItem,
    ChatMessageComposerItem,
    ChatMessageComposer,
    WeLocalImageUrlTransformator,
  ]
})
export class SocketApiExtensionsModule { }
