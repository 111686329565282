import { createEffect } from '@ngrx/effects';
import { map, Observable } from 'rxjs';
import { WenComment } from '../../../../shared/components/comments/types';
import { DateUtil } from '../../../common/date/date-util';
import { updateTimestampOfPageVisit } from '../comments.actions';

export const updateTimestampOnNewCommentCreationEffect = (
  commentCreated$: Observable<WenComment>
) => {
  return createEffect(() =>
    commentCreated$.pipe(
      map(() => {
        return updateTimestampOfPageVisit({ timestamp: DateUtil.toSQLDateFilter(new Date().toISOString()) });
      })
    )
  );
};
