import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { first, map } from 'rxjs';
import { ChatViewPreloaderGuard } from '../providers/chat-view-preloader.guard';

export const chatDetailGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const chatViewPreloaderGuard = inject(ChatViewPreloaderGuard);
  return chatViewPreloaderGuard.ensureCurrentChatDetailsLoaded().pipe(
    first(),
    map((room) => Boolean(room))
  );
};
