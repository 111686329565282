import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenComponentsModule } from '@portal/wen-components';
import { InviteChatCreationDialogOpener } from './services/invite-chat-creation-dialog-opener';
import { InviteChatCreationDialogComponent } from './components/invite-chat-creation-dialog/invite-chat-creation-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormProgressModule } from '../../../shared/components/form-progress/form-progress.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    WenComponentsModule,
    ReactiveFormsModule,
    PortalFieldModule,
    MatFormFieldModule,
    FormProgressModule,
  ],
  declarations: [
    InviteChatCreationDialogComponent
  ],
  providers: [
    InviteChatCreationDialogOpener
  ]
})
export class CrmChatModule { }
