import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, map, of, switchMap, throwError } from 'rxjs';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../../core/services/navigation/types';
import { AuthStateValidator } from '../../../core/services/user-management/auth-state-validator.service';
import { PermissionLevel } from '../../../core/services/user-management/permission-level';
import { WenOAuthService } from '../../../core/services/user-management/wen-oauth.service';

export const exclusiveInviteGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(ExclusiveInviteGuard).canActivate(route, state);
};

@Injectable()
export class ExclusiveInviteGuard {

  constructor(
    private authStateValidator: AuthStateValidator,
    private navigationHelper: WenNavigationHelper,
    private oAuthService: WenOAuthService,
    private featureEnablement: FeatureEnablementService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.featureEnablement.isInviteOnlyNetworkEnabled()) {
      this.navigationHelper.navigateToWelcomeScreen();
      return of(false);
    }
    return this.authStateValidator.validateAuthState().pipe(
      switchMap(userData => {
        if (!userData) {
          return throwError(() => new Error());
        }
        if (userData.permissionLevel === PermissionLevel.ANONYMOUS) {
          return of(true);
        } else if (userData.permissionLevel === PermissionLevel.REGISTERED_USER) {
          this.navigationHelper.navigateToWelcomeScreen();
          return of(false);
        }
      }),
      catchError(() => {
        const redirectUrl = state.url;
        return this.oAuthService.initCodeFlow({
          redirectAfterLogin: redirectUrl,
          anonymousAutoLogin: true
        }).pipe(
          map(() => false),
          catchError(() => {
            this.navigationHelper.navigateToErrorPage();
            return of(false);
          })
        );
      })
    );
  }

}
