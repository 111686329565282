import { SchemaHandlerRecognizer } from './schema-handler';
import { Injectable, inject } from '@angular/core';
import { CONTENT_HANDLERS } from './schema-content-handlers';
import { ConfigSchema, ContentHandlerType, ContentSchema } from '@portal/wen-backend-api';
import { ProcessedGroupProps } from './models/model';
import { toProcessedGroup } from './utils';

@Injectable()
export class GroupHandler implements SchemaHandlerRecognizer {

  private contentHandlers = inject(CONTENT_HANDLERS, { optional: true });

  processSchema(schema: ConfigSchema): ProcessedGroupProps<ContentSchema[]>[] {
    if (!schema.groups || !schema?.groups?.length) {
      return null;
    }
    if (!Object.keys(this.contentHandlers).length) {
      throw new Error('There is no content handler provided');
    }
    const groups = schema.groups;
    return groups.map(group => toProcessedGroup(ContentHandlerType.GROUP, this.contentHandlers, group.content));
  }
}
