import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppDeletedGuard } from './app-deleted.guard';
import { AppDetailLoadedGuard } from './app-detail-loaded.guard';

export const canActivateAppViewGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const appDetailLoadedGuard = inject(AppDetailLoadedGuard);
  const appDeletedGuard = inject(AppDeletedGuard);
  return appDetailLoadedGuard.canActivate(route, state).pipe(
    switchMap((result) => {
      if (!result) {
        return of(false);
      }
      return appDeletedGuard.canActivate(route, state);
    })
  );
};
