@if (!commandComponent) {
  <div class="wen-header-command-button wen-header-command-button-{{getClassSuffix()}}" (click)="commandClicked()" [wen-test-id]="'wen-header-command-button-' + getClassSuffix()">
    @if (getIcon()) {
      <wen-icon [class.wen-icon-disabled]="isCommandDisabled" [iconName]="getIcon()"></wen-icon>
    }
    <span class="wen-header-command-button-label">{{ getTitle() | translate }}</span>
  </div>
} @else {
  <div (click)="commandClicked()" class="wen-header-command-button" [wen-test-id]="'wen-header-command-button-' + getClassSuffix()">
    <ng-template [cdkPortalOutlet]="commandComponent" (attached)="onComponentAttached($event)"></ng-template>
  </div>
}
