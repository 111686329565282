import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject, combineLatest, distinctUntilChanged, map, pairwise, startWith, takeUntil } from 'rxjs';
import { ScrollerWrapper } from '../../../../core/services/scrolling/scroller-wrapper';

const BOTTOM_VISIBILITY_OFFSET = 100;

@Injectable()
export class ScrollToBottomVisibility implements OnDestroy {

  private onDestroy$ = new Subject<void>();
  private readonly isVisible = new ReplaySubject<boolean>(1);
  public readonly isVisible$ = this.isVisible.asObservable();

  constructor(
    private scrollerWrapper: ScrollerWrapper,
  ) { }

  initialize(): void {
    const isScrollingUp$ = this.scrollerWrapper.scrollPosition$.pipe(
      startWith(0),
      pairwise(),
      map(([last, current]) => {
        const isAtTop = last <= 0;
        return last > current || isAtTop;
      }),
    );
    const isScrollPositionAtBottom$ = this.scrollerWrapper.scrollPosition$.pipe(
      map(() => this.scrollerWrapper.measureScrollOffsets().bottomOffset < BOTTOM_VISIBILITY_OFFSET),
    );

    combineLatest([
      isScrollingUp$.pipe(startWith(false)),
      isScrollPositionAtBottom$.pipe(startWith(true)),
    ]).pipe(
      map(([isUp, isScrollPositionAtBottom]) => {
        return (!isScrollPositionAtBottom && !isUp);
      }),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(this.isVisible);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
