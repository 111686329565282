import { Injectable, inject } from '@angular/core';
import { ContactRequestStatus } from '@portal/wen-backend-api';
import { Observable, map, shareReplay } from 'rxjs';
import { ListMassDataViewerContainerConnector } from '../../../../core/services/mass-data/list-mass-data-viewer-container-connector';
import { PendingContactsMassDataContainer } from '../../../../core/store/contacts/pending-contacts-mass-data-config/pending-contacts-mass-data-container';

export interface PendingContactListSeparatorPositions {
  incomingIndex: number;
  sentIndex: number;
}

@Injectable()
export class PendingContactListService {

  private datasource = inject<ListMassDataViewerContainerConnector<PendingContactsMassDataContainer>>(ListMassDataViewerContainerConnector);

  separatorPositions$: Observable<PendingContactListSeparatorPositions>;

  constructor() {
    this.separatorPositions$ = this.datasource.dataStream$.pipe(
      map(items => {
        if (!items?.length) {
          return null;
        }
        const getIndex = (status: ContactRequestStatus) => {
          const index = items.findIndex(item => item.requestStatus === status);
          return index < 0 ? null : index;
        };
        const incomingIndex = getIndex(ContactRequestStatus.RECIPIENT);
        const sentIndex = getIndex(ContactRequestStatus.REQUESTER);
        return { incomingIndex, sentIndex };
      }),
      shareReplay(1)
    );
  }
}
