import { Injectable } from '@angular/core';
import { OlmDeviceModel } from '@portal/wen-backend-api';
import { map, Observable } from 'rxjs';
import { CryptoStorage, GetDeviceParams, GetInboundGroupSessionParams, GetSessionParams, GetSessionsForDeviceParams } from '../crypto-storage';
import { InboundGroupSessionModel, OlmAccountModel, SessionModel } from '../data-models';
import { filterDuplicateDevices } from '../util';
import { ObjectStores, UNIQUE_STORE_KEY } from './indexed-db-schema';
import { OlmIndexedDb, TransactionMode } from './olm-indexed-db';

@Injectable()
export class IndexedDbDataStore extends CryptoStorage {

  constructor(
    private db: OlmIndexedDb
  ) {
    super();
  }

  init() {
    const ready$ = this.db.init();
    return ready$.pipe(map(() => true));
  }

  clearDb(): Observable<void> {
    return this.db.clearDb();
  }

  getAccount() {
    return this.db.openTransaction(
      TransactionMode.READWRITE,
      [ObjectStores.ACCOUNT],
      (tx) => {
        const store = tx.objectStore(ObjectStores.ACCOUNT);
        return store.get(UNIQUE_STORE_KEY);
      }
    );
  }

  storeAccount(account: OlmAccountModel) {
    return this.db.openTransaction(
      TransactionMode.READWRITE,
      [ObjectStores.ACCOUNT],
      (tx) => {
        const store = tx.objectStore(ObjectStores.ACCOUNT);
        return store.put(account, UNIQUE_STORE_KEY);
      }
    );
  }

  getDevices(params: GetDeviceParams) {
    return this.db.openTransaction(
      TransactionMode.READONLY,
      [ObjectStores.DEVICES],
      async (tx) => {
        const store = tx.objectStore(ObjectStores.DEVICES);
        const data = (await store.get(UNIQUE_STORE_KEY));
        const devices = data?.devices || [];
        const foundDevices = devices.filter(device => {
          return device.userId === params.userId;
        });
        return foundDevices;
      }
    );
  }

  storeDevice(update: OlmDeviceModel) {
    return this.db.openTransaction(
      TransactionMode.READWRITE,
      [ObjectStores.DEVICES],
      async (tx) => {
        const store = tx.objectStore(ObjectStores.DEVICES);
        const data = (await store.get(UNIQUE_STORE_KEY));
        const devices = data?.devices || [];
        const newDevice = filterDuplicateDevices([update], devices);
        if (newDevice[0]) {
          devices.push(newDevice[0]);
        }
        return store.put({
          ...data,
          devices
        }, UNIQUE_STORE_KEY);
      }
    );
  }

  storeDevices(update: OlmDeviceModel[]): Observable<string> {
    return this.db.openTransaction(
      TransactionMode.READWRITE,
      [ObjectStores.DEVICES],
      async (tx) => {
        const store = tx.objectStore(ObjectStores.DEVICES);
        const data = (await store.get(UNIQUE_STORE_KEY));
        const devices = data?.devices || [];
        const newDevices = filterDuplicateDevices(update, devices);
        if (newDevices.length > 0) {
          devices.push(...newDevices);
        }
        return store.put({
          ...data,
          devices
        }, UNIQUE_STORE_KEY);
      }
    );
  }

  getSession(params: GetSessionParams): Observable<SessionModel> {
    return this.db.openTransaction(
      TransactionMode.READONLY,
      [ObjectStores.SESSIONS],
      async (tx) => {
        const store = tx.objectStore(ObjectStores.SESSIONS);
        const keyRange = IDBKeyRange.only([params.curve25519, params.sessionId]);
        const session = await store.get(keyRange);
        return session;
      }
    );
  }

  getSessionsForDevice(params: GetSessionsForDeviceParams): Observable<SessionModel[]> {
    return this.db.openTransaction(
      TransactionMode.READONLY,
      [ObjectStores.SESSIONS],
      async (tx) => {
        const store = tx.objectStore(ObjectStores.SESSIONS);
        const byIndex = store.index('byCurve25519');
        const keyRange = IDBKeyRange.only(params.curve25519);
        const session = await byIndex.getAll(keyRange);
        return session;
      }
    );
  }

  storeSession(update: SessionModel): Observable<string> {
    return this.db.openTransaction(
      TransactionMode.READWRITE,
      [ObjectStores.SESSIONS],
      async (tx) => {
        const store = tx.objectStore(ObjectStores.SESSIONS);
        return store.put(update);
      }
    );
  }

  getInboundGroupSession(params: GetInboundGroupSessionParams): Observable<InboundGroupSessionModel> {
    return this.db.openTransaction(
      TransactionMode.READONLY,
      [ObjectStores.INBOUND_GROUP_SESSIONS],
      async (tx) => {
        const store = tx.objectStore(ObjectStores.INBOUND_GROUP_SESSIONS);
        const keyRange = IDBKeyRange.only([params.senderCurve25519, params.sessionId]);
        const session = await store.get(keyRange);
        return session;
      }
    );
  }

  getInboundGroupSessions(params: GetInboundGroupSessionParams[]): Observable<InboundGroupSessionModel[]> {
    return this.db.openTransaction(
      TransactionMode.READONLY,
      [ObjectStores.INBOUND_GROUP_SESSIONS],
      async (tx) => {
        const store = tx.objectStore(ObjectStores.INBOUND_GROUP_SESSIONS);
        const allSessions = await store.getAll();
        const filteredSessions = allSessions.filter((session) => {
          const isNeeded = params.some(param => {
            return param.sessionId === session.sessionId && param.senderCurve25519 === session.senderCurve25519;
          });
          return isNeeded;
        });
        return filteredSessions;
      }
    );
  }

  getAllInboundGroupSessions(): Observable<InboundGroupSessionModel[]> {
    return this.db.openTransaction(
      TransactionMode.READONLY,
      [ObjectStores.INBOUND_GROUP_SESSIONS],
      async (tx) => {
        const store = tx.objectStore(ObjectStores.INBOUND_GROUP_SESSIONS);
        const allSessions = await store.getAll();
        return allSessions;
      }
    );
  }

  storeInboundGroupSession(update: InboundGroupSessionModel): Observable<string> {
    return this.db.openTransaction(
      TransactionMode.READWRITE,
      [ObjectStores.INBOUND_GROUP_SESSIONS],
      async (tx) => {
        const store = tx.objectStore(ObjectStores.INBOUND_GROUP_SESSIONS);
        return store.put(update);
      }
    );
  }

}
