import { createSelector } from '@ngrx/store';
import { isEncryptedSendMessageEvent } from '@portal/wen-backend-api';
import { selectAllRooms, selectChatState, selectCurrentRoom, selectRoomById } from '../chat.selectors';
import { ChatMessageEntity, ChatRoomEntity, chatMessageEntityAdapter } from '../chat.state';

const {
  selectAll: chatMessageSelectAll,
} = chatMessageEntityAdapter.getSelectors();

const mergeChatMessages = (room: ChatRoomEntity): ChatMessageEntity[] => {
  if (!room) {
    return [];
  }
  const { history, messages } = room;
  const realTimeMessages = messages ? chatMessageSelectAll(room.messages) : [];
  if (history) {
    const historyMessages = chatMessageSelectAll(room.history);
    const historyIds = history.ids as string[];
    const uniqueRealTimeMessages = realTimeMessages.filter((realTimeMessage) => {
      const isNotDuplicate = !historyIds.includes(realTimeMessage.eventId);
      return isNotDuplicate;
    });
    return [...historyMessages, ...uniqueRealTimeMessages];
  }
  return realTimeMessages;
};

export const selectRoomMessagesHistoryById = createSelector(
  selectRoomById,
  (roomSelectorFn) => (roomId: string) => {
    const currentRoom = roomSelectorFn(roomId);
    return mergeChatMessages(currentRoom);
  }
);

export const selectRoomMessageHistoryById = createSelector(
  selectCurrentRoom,
  (currentRoom) => (eventId: string) => {
    return mergeChatMessages(currentRoom).find(entity => entity.eventId === eventId);
  }
);

export const selectMessagesBySessionIdForRoom = createSelector(
  selectChatState,
  (state) => (roomId: string, sessionIds: string[]) => {
    const roomEntity = state.rooms.entities[roomId];
    if (!roomEntity) {
      return [];
    }
    const history = roomEntity.history ? chatMessageSelectAll(roomEntity.history) : [];
    const historyMessages = sessionIds.map((sessionId) => {
      return history.filter(message => {
        const messageSessionId = message?.encryptionData?.encryptedMessage?.content?.sessionId;
        return messageSessionId && messageSessionId === sessionId;
      });
    });
    return historyMessages
      .filter((message) => Boolean(message))
      .flat();
  }
);

export const selectMessagesBySessionId = createSelector(
  selectAllRooms,
  (chatRoomEntites) => (megolmSessionId: string) => {
    let targetMessageEntity: ChatMessageEntity = null;
    chatRoomEntites.some(chatRoomEntity => {
      const allMessages = mergeChatMessages(chatRoomEntity);
      if (!allMessages?.length) {
        return false;
      }
      const targetMessage = allMessages.find(eventEntitity => {
        const { encryptionData } = eventEntitity;
        const { originalEvent } = encryptionData || {};
        if (isEncryptedSendMessageEvent(originalEvent)) {
          const megolmMessageContent = originalEvent.payload.content;
          return megolmMessageContent.sessionId === megolmSessionId;
        }
        return false;
      });
      if (targetMessage) {
        targetMessageEntity = targetMessage;
      }
      return Boolean(targetMessage);
    });
    return targetMessageEntity;
  }
);

export const selectCurrentRoomMessagesHistory = createSelector(
  selectCurrentRoom,
  (currentRoom) => {
    return mergeChatMessages(currentRoom);
  }
);

export const selectChatMessageById = createSelector(
  selectChatState,
  (state) => (roomId: string, messageId: string) => {
    const roomEntity = state.rooms.entities[roomId];
    const historicalMessage = roomEntity.history?.entities[messageId];
    const realTimeMessage = roomEntity.messages?.entities[messageId];

    return historicalMessage ?? realTimeMessage;
  }
);
