import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CircleImageViewModule, WenCardModule, WenIconModule, WenSectionModule } from '@portal/wen-components';
import { SharedModule } from '../../../shared/shared.module';
import { AppExploreComponent } from './app-explore.component';
import { AppSectionComponent } from './components/app-section/app-section.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollStateModule } from '../../../shared/directives/scroll-state/scroll-state.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    TranslateModule.forChild(),
    WenIconModule,
    WenSectionModule,
    CircleImageViewModule,
    WenCardModule,
    ScrollingModule,
    ScrollStateModule
  ],
  exports: [AppExploreComponent, AppSectionComponent],
  declarations: [AppExploreComponent, AppSectionComponent],
})
export class AppExploreModule { }
