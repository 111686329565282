import { Injectable, OnDestroy, Provider, Type, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { MassDataContainer } from '@portal/wen-data-core';
import { Subject, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { selectActiveFiltersByEntityType } from '../../../store/filter/filter.selectors';
import { RootState } from '../../../store/root/public-api';
import { ListMassDataViewerContainerConnector } from '../list-mass-data-viewer-container-connector';
import { FILTERED_LIST_MASS_DATA_CONTAINER_CONFIG, FilteredListMassDataConnectorConfig } from '../tokens';

const createConnector = <T extends MassDataContainer<any>>(massDataContainer: Type<T>) => {
  const providerInstance = new ListMassDataViewerContainerConnector<T>(inject(massDataContainer));
  return providerInstance;
};

@Injectable()
export class FilteredListMDContainerConnector<DL extends MassDataContainer<any>, DF extends MassDataContainer<any>> implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  private config = inject<FilteredListMassDataConnectorConfig<DL, DF>>(FILTERED_LIST_MASS_DATA_CONTAINER_CONFIG);
  private store = inject(Store<RootState>);

  public activeDatasource: ListMassDataViewerContainerConnector<DL | DF>;
  public readonly listMassData: ListMassDataViewerContainerConnector<DL>;
  public readonly filteredListMassData: ListMassDataViewerContainerConnector<DF>;

  constructor() {
    this.listMassData = createConnector(this.config.list);
    this.filteredListMassData = createConnector(this.config.filterList);
    this.setupContainerSwitch();
    this.setupFilterReload();
  }

  /**
   * Returns true when the filtered datasource is the active one
   */
  isFilteredActive() {
    return this.activeDatasource === this.filteredListMassData;
  }

  /**
   * Whenever the filter is active or not use the normal or the filtered datasource
   */
  private setupContainerSwitch() {
    this.store.pipe(
      selectorWithParam(selectActiveFiltersByEntityType, this.config.relevantFilterEntity),
      map((filters) => this.config.selectRelevantFilter(filters)),
      map((relevantFilters) => relevantFilters?.length > 0),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(hasFilter => {
      if (!hasFilter) {
        this.activeDatasource = this.listMassData;
      } else {
        this.activeDatasource = this.filteredListMassData;
      }
    });
  }

  private setupFilterReload() {
    this.store.pipe(
      selectorWithParam(selectActiveFiltersByEntityType, this.config.relevantFilterEntity),
      map((filters) => this.config.selectRelevantFilter(filters)),
      filter((relevantFilters) => relevantFilters?.length > 0),
      smartDistinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.filteredListMassData.fetchInitialPage();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

export const provideFilteredMassDataContainerConnector = <
  DL extends MassDataContainer<any>, DF extends MassDataContainer<any>
>(config: FilteredListMassDataConnectorConfig<DL, DF>) => {
  const providers: Provider[] = [
    FilteredListMDContainerConnector<DL, DF>,
    config.list,
    config.filterList,
    {
      provide: FILTERED_LIST_MASS_DATA_CONTAINER_CONFIG,
      useValue: config
    },
  ];
  return providers;
};
