import { PortalFieldValueAdapter } from '@portal/ui-kit/field';
import { isNullOrUndefined } from '../../operators/null-check-util';

export class DecimalFieldValueAdapter implements PortalFieldValueAdapter<number> {

  parse(inputValue: any): number {
    const rawValue = typeof inputValue === 'number' ? inputValue.toString() : inputValue;
    if (typeof rawValue !== 'string' || !rawValue) {
      return null;
    }
    let formattedValue = '';
    const splitNumber = this.removeNegativeSign(rawValue).split('.');
    if (splitNumber.length === 1) {
      formattedValue = splitNumber[0].slice(0, 3);
    } else {
      formattedValue = `${splitNumber[0]}.${splitNumber[1].slice(0, 1)}`;
    }

    return Number(formattedValue);
  }

  format(parsedValue: number): string {
    return isNullOrUndefined(parsedValue) ? '' : this.removeNegativeSign(parsedValue.toString());
  }

  private removeNegativeSign(numberString: string): string {
    return numberString.replace('-', '');
  }

}
