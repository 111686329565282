import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { firstExisty } from '../../../../../../core/common/operators/first-existy';
import { switchMapFirstExisty } from '../../../../../../core/common/operators/switch-map-first';
import { selectorWithParam } from '../../../../../../core/common/util/selector-with-param';
import { WenNavigationHelper } from '../../../../../../core/services/navigation/types';
import { selectChannelPermission } from '../../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../../../core/store/root/root.selectors';
import { channelViewIdentifier } from '../../../../tokens';

export const channelListContentProvidersGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(ChannelListContentProvidersGuard).canActivate(route, state);
};

@Injectable()
export class ChannelListContentProvidersGuard {
  constructor(
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectRouteParam(channelViewIdentifier)),
      firstExisty(),
      switchMapFirstExisty((channelId) => this.store.pipe(
        selectorWithParam(selectChannelPermission, channelId),
        map(permission => permission.canListContentProviders),
        tap(canListContentProviders => {
          if (!canListContentProviders) {
            this.navigationHelper.navigateToChannelDetail(channelId);
          }
        })
      ))
    );
  }

}
