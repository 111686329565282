import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { filter, map, merge, of, skip, switchMap, withLatestFrom } from 'rxjs';
import { WenRouteId } from '../../../../frame/routing/types';
import { channelViewIdentifier } from '../../../../views/channel/tokens';
import { DataContextHelper } from '../../../services/util/data-context-helper';
import { RootState } from '../../root/public-api';
import { selectOutletIds, selectRouteParam } from '../../root/root.selectors';
import { resetChannelMessageOriginTransferData, resetScrollState } from '../ui.actions';
import { selectChannelMessageOriginTransferData } from '../ui.selectors';

@Injectable()
export class UiStateEffects {

  private readonly dataContextHelper = inject(DataContextHelper);
  private readonly store = inject(Store<RootState>);
  private readonly actions$ = inject(Actions);

  resetScrollState$ = createEffect(() => this.dataContextHelper.onDataContextChanges().pipe(
    map(({ fromContext }) => {
      return resetScrollState({
        scrollDataContext: fromContext
      });
    })
  ));

  resetContextDataForChannelMesageDetails$ = createEffect(() => this.store.pipe(
    select(selectChannelMessageOriginTransferData),
    smartDistinctUntilChanged(),
    switchMap((transferData) => {
      if (!transferData) {
        return of(false);
      }
      const outletChanges$ = this.actions$.pipe(
        ofType(routerNavigatedAction),
        switchMap(() => this.store.pipe(
          select(selectOutletIds)
        ))
      );
      const channelIdChanges$ = this.store.pipe(
        select(selectRouteParam(channelViewIdentifier))
      );
      const resetWhenOutOfContext = outletChanges$.pipe(
        skip(1), // Always keep the context data for the very first navigation after it was set
        withLatestFrom(channelIdChanges$),
        map(([{ primaryId }, channelId]) => {
          switch (primaryId) {
            case WenRouteId.CHANNEL_VIEW:
              return channelId !== transferData.channelId;
            case WenRouteId.READER_VIEW:
            case WenRouteId.TEXT_VIEW:
            case WenRouteId.CHANNEL_MESSAGE_DETAIL:
            case WenRouteId.OTHER_USERPROFILE_READ:
            case WenRouteId.USERPROFILE_READ:
              return false;
            default:
              return true;
          }
        })
      );
      const resetWhenChannelChanges = channelIdChanges$.pipe(
        filter((channelId) => Boolean(channelId)),
        map((channelId) => {
          return transferData.channelId !== channelId;
        })
      );
      return merge(resetWhenOutOfContext, resetWhenChannelChanges);
    }),
    filter(shouldReset => shouldReset),
    map(() => {
      return resetChannelMessageOriginTransferData();
    }),
  ));

}
