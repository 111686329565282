import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OlmDevice } from '../device/olm-device';
import { MegOlmEncryptionResult } from './crypto-results';

@Injectable()
export class MegOlmEncryptor {

  private olmDevice = inject(OlmDevice);

  // TODO: make sure the outbound is shared with the relevant devices?
  private readonly outboundSessions = new Map<string, ReturnType<OlmDevice['createOutboundGroupSession']>>();

  ensureOutboundGroupSession(roomId: string) {
    const existingOutbound = this.outboundSessions.get(roomId);
    if (existingOutbound) {
      return existingOutbound;
    }
    const sessionData = this.olmDevice.createOutboundGroupSession();
    if (roomId) {
      this.outboundSessions.set(roomId, sessionData);
    }
    return sessionData;
  }

  encryptMessage(roomId: string, content: string): Observable<MegOlmEncryptionResult> {
    const sessionData = this.ensureOutboundGroupSession(roomId);
    return of(this.olmDevice.encryptGroupMessage(sessionData.session, content));
  }

}
