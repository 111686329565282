import { Injectable } from '@angular/core';
import { StorePersistenceDataSerializer } from '@portal/wen-store-persistence';
import { RootState } from '../../store/root/public-api';
import { PartialDeep } from 'type-fest';
import { ChannelEntity } from '../../store/channel/channel.state';
import { EntityState } from '@ngrx/entity';

@Injectable()
export class AppStorePersistenceDataSerializer extends StorePersistenceDataSerializer<RootState> {

  private filterChannels(
    channelId: string, userChannelIds: string[]
  ) {
    return channelId && userChannelIds?.length && userChannelIds.includes(channelId);
  }

  private serializeChannelRelatedSlice(data: RootState) {
    const {
      channels,
      channelMeta,
      discoverChannelIds,
      userChannelIds,
      userChannelsLoading,
      discoverChannelsLoading,
      userJoinRequestChannelIds,
      inviteToChannelRequestChannelIds,
      featuredData
    } = data.channel;
    const partialChannels = Object.entries(channels.entities)
      .filter(([idx]) => this.filterChannels(idx, userChannelIds))
      .reduce((acc, [idx, current]) => {
        const {
          id,
          title,
          icon,
          description,
        } = current;
        const newEntity = {
          [idx]: {
            id, title, icon, description
          }
        };
        return {
          ids: [...acc.ids, idx],
          entities: {
            ...acc.entities,
            ...newEntity
          }
        };
      }, { ids: [], entities: {} }) as EntityState<ChannelEntity>;
    const {
      channelNotifications
    } = data.notification;
    return {
      notification: {
        channelNotifications
      },
      channel: {
        channels: partialChannels,
        channelMeta,
        discoverChannelIds,
        userChannelIds,
        userChannelsLoading,
        discoverChannelsLoading,
        userJoinRequestChannelIds,
        inviteToChannelRequestChannelIds,
        featuredData
      }
    };
  }

  serialize(data: RootState): PartialDeep<RootState> {
    if (!data?.channel || !data.notification) {
      return null;
    }
    return this.serializeChannelRelatedSlice(data);
  }

}
