import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordFieldComponent } from './password-field.component';
import { PasswordVisibilityToggleModule } from '../../../password-visibility-toggle/password-visibility-toggle.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    PasswordVisibilityToggleModule,
  ],
  declarations: [
    PasswordFieldComponent,
  ],
  exports: [
    PasswordFieldComponent,
  ],
})
export class PasswordFieldModule { }
