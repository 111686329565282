import { isGuidOnlyRoute } from '../../../core/common/util/routing-helpers';
import { AlwaysHomeBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/always-home-back-navigation';
import { createDeepLinkedBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/deep-linked-back-navigation-behavior';
import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { EventDetailComponent } from '../event-detail/components/event-detail/event-detail.component';
import { eventDetailDataResolverFn } from '../event-detail/components/event-detail/providers/event-detail-data-resolver';
import { ExploreEventComponent } from '../event-explore/explore-event.component';
import { DiscoveryEventListComponent } from '../event-list/components/discovery-event-list/discovery-event-list.component';
import { UserEventListComponent } from '../event-list/components/user-event-list/user-event-list.component';
import { discoveryEventListDataResolverFn } from '../event-list/providers/discovery-event-list-data.resolver';
import { exploreEventDataResolverFn } from '../event-list/providers/explore-event.resolver';
import { userEventListDataResolverFn } from '../event-list/providers/user-event-list-data.resolver';
import { eventViewIdentifier } from '../tokens';

export const eventExploreRoute: WenRoute = {
  path: 'explore',
  component: ExploreEventComponent,
  data: {
    routeId: WenRouteId.EVENT_EXPLORE,
    routeTransitionEnabled: true,
    backNavigationBehavior: AlwaysHomeBackNavigation,
  },
  resolve: {
    ExploreEventDataResolver: exploreEventDataResolverFn,
  }
};

export const eventDiscoveryRoute: WenRoute = {
  path: 'discovery',
  component: DiscoveryEventListComponent,
  data: {
    routeId: WenRouteId.EVENT_DISCOVERY,
    routeTransitionEnabled: true,
  },
  resolve: {
    DiscoveryEventListDataResolver: discoveryEventListDataResolverFn,
  }
};

export const eventListRoute: WenRoute = {
  path: 'list',
  component: UserEventListComponent,
  data: {
    routeId: WenRouteId.EVENT_LIST,
    routeTransitionEnabled: true,
  },
  resolve: {
    UserEventListDataResolver: userEventListDataResolverFn,
  }
};

export const eventDetailRoute: WenRoute = {
  path: `:${eventViewIdentifier}`,
  canMatch: [isGuidOnlyRoute],
  component: EventDetailComponent,
  data: {
    routeId: WenRouteId.EVENT_DETAIL,
    backNavigationBehavior: createDeepLinkedBackNavigation({
      mobileTransition: {
        primary: WenRouteId.EVENT_LIST,
      },
      desktopTransition: {
        primary: WenRouteId.EVENT_LIST
      }
    })
  },
  resolve: {
    EventDetailDataResolver: eventDetailDataResolverFn,
  }
};
