import { Injectable, InjectionToken } from '@angular/core';
import { PartialDeep } from 'type-fest';

@Injectable()
export abstract class StorePersistenceDataSerializer<T> {
  abstract serialize(data: T): PartialDeep<T>;
}

export interface StorePersistenceConfig {
  version: number;
}

export const STORE_PERSISTENCE_CONFIG = new InjectionToken<StorePersistenceConfig>('STORE_PERSISTENCE_CONFIG');
