import { NgModule } from '@angular/core';
import { UserSearchListModule } from '../../../shared/components/user-search-list/user-search-list.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelAddAdminComponent } from './channel-add-admin.component';

@NgModule({
  imports: [
    SharedModule,
    UserSearchListModule
  ],
  declarations: [ChannelAddAdminComponent]
})
export class ChannelAddAdminModule { }
