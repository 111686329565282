import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OverlayManager } from '@portal/wen-components';
import { filter, first, map, Observable, switchMap } from 'rxjs';
import { firstExisty } from '../../core/common/operators/first-existy';
import { selectDraftChatState } from '../../core/store/chat/chat.selectors';
import { RootState } from '../../core/store/root/public-api';
import { WenRouteId } from '../../frame/routing/types';

@Injectable()
export class UnsavedChangesListener {

  constructor(
    private store: Store<RootState>,
    private overlayManager: OverlayManager
  ) { }

  withConfirmation(afterLeave: () => void, routeId: WenRouteId) {
    this.hasUnsavedEvaluationBy(routeId).pipe(
      filter((hasChange) => {
        if (!hasChange) {
          afterLeave();
        }
        return hasChange;
      }),
      switchMap(() => {
        return this.overlayManager.dialog.openLeaveConfirmationDialog(undefined, 'LEAVE_UNSAVED_VIEW_CONTENT_LABEL').afterClosed().pipe(
          first(),
          map(result => {
            return result?.result === 'ok';
          }),
          filter(isAccepted => isAccepted)
        );
      })
    ).subscribe(() => afterLeave());

  }

  private hasUnsavedEvaluationBy(routeId: WenRouteId): Observable<boolean> {
    switch (routeId) {
      case WenRouteId.CHAT_CREATION:
        return this.store.pipe(
          select(selectDraftChatState),
          firstExisty(),
          map((chatState) => {
            const hasSelectedUsers = !!chatState.users.ids.length;
            const hasDraftMessage = chatState?.message && Object.entries(chatState?.message).some(([_, value]) => !!value);
            return hasSelectedUsers || hasDraftMessage;
          })
        );
      default:
        break;
    }
  }
}
