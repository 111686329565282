import { PagingReplayDirection, RelevantMessagesHistoryFlags } from '../paging/paging';
import { MessageEvent, MessageEventResponses } from './messages/response';
import { OlmDeviceModel, OlmOneTimeKeys } from './olm/olm';
import { SendToUsersEventResponse } from './send-to-users/response';

export type UploadKeysPayload = {
  userId: string;
  deviceKeys: OlmDeviceModel[];
  oneTimeKeys?: OlmOneTimeKeys;
};

export type DownloadDeviceKeysPayload = {
  userIds: string[];
};

export type DownloadDeviceKeysResponse = {
  deviceKeys: OlmDeviceModel[];
};

export type ClaimOneTimeKeysPayload = {
  userIds: string[];
};

export type ClaimOneTimeKeysResponse = {
  oneTimeKeys: OlmOneTimeKeys[];
};

export type OneTimeKeysStatusResponse = {
  deviceOneTimeKeysCount: number;
};

export enum RoomType {
  DIALOG = 'DIALOG',
  GROUP = 'GROUP'
}

export enum MemberState {
  OWNER = 'OWNER',
  JOINED = 'JOINED',
  LEFT = 'LEFT'
}

export type CreateRoomPayload = {
  userIds: string[];
  title?: string;
  description?: string;
  roomType: RoomType;
  networks?: string[];
};

export type RoomUpdatePayload = {
  title?: string;
  description?: string;
  icon?: string;
  roomId: string;
};

export type RemoveRoomPayload = {
  roomId: string;
};

export type RoomUpdateDto = {
  roomId: string;
} & RoomUpdateDataDTO;

export type RoomUpdateDataDTO = {
  title?: string;
  description?: string;
  icon?: string;
};

export type RoomUpdateResponse = RoomUpdateDto;

export type RemoveRoomResponse = {
  roomId: string;
};

export type CreateRoomResponse = {
  id: string;
};

export type NewRoomCreatedResponse = RoomDTO;

export type RoomDTO = {
  customTitle: string;
  customIcon: string;
  id: string;
  type: RoomType;
  version: number;
  title: string;
  description: string;
  icon: string;
  isMuted: boolean;
} & MemberMetaData;

export type MemberMetaData = {
  memberSince?: string;
  memberState?: MemberState;
};

export type RoomMembers = {
  userId: string;
  state: MemberState;
  memberSince: string;
};

export type RoomDetailsPayload = {
  id: string;
};

export type GetDialogId = {
  userId: string;
};

export type RoomDetailsResponse = {
  details: RoomDTO;
  members: RoomMembers[];
};

/**
 * Payload of a replay request for messages
 *
 * @see {@link SOCKET_EVENTS.CHAT_MESSAGES_REPLAY}
 */
export type RoomMessagesReplayPayload = {
  roomId: string;
  userId: string;
  timestamp?: string;
  direction?: PagingReplayDirection;
};

/**
 * The event data of a replay response in a given room
 *
 * @see {@link SOCKET_EVENTS.CHAT_MESSAGES_REPLAY}
 */
export type RoomEventsReplayResponse = {
  roomId: string;
  events: MessageEvent<MessageEventResponses>[];
  direction: PagingReplayDirection;
  more: boolean;
};

/**
 * The event data of a replay response in a given room
 *
 * @see {@link SOCKET_EVENTS.CHAT_RELEVANT_MESSAGES}
 */
export type RoomEventsRelevantReplayResponse = {
  roomId: string;
  events: MessageEvent<MessageEventResponses>[];
} & RelevantMessagesHistoryFlags;

/**
 * Payload of a relevant messages replay request
 *
 * @see {@link SOCKET_EVENTS.CHAT_RELEVANT_MESSAGES}
 */
export type RoomRelevantMessagesPayload = {
  roomId: string;
};

/**
 * Payload of a summary request for messages
 *
 * @see {@link SOCKET_EVENTS.CHAT_MESSAGES_SUMMARY}
 */
export type RoomMessagesSummaryPayload = {
  roomId: string;
};

/**
 * The data of a summary response in a given room
 *
 * @see {@link SOCKET_EVENTS.CHAT_MESSAGES_SUMMARY}
 */
export type RoomMessagesSummaryResponse = {
  roomId: string;
  latestAck: RoomLatestAcknowledge;
  latestEvents: MessageEvent<MessageEventResponses>[];
  unreadCount: number;
};

/**
 * The event data of a replay of the inbox (unread events)
 *
 * @see {@link SOCKET_EVENTS.CHAT_INBOX_REPLAY}
 */
export type InboxReplayResponse = SendToUsersEventResponse[];

export enum RoomInboxAckType {
  RECEIVED = 'RECEIVED',
  READ = 'READ'
}

/**
 * The event data of a replay of the inbox (unread events)
 *
 * @see {@link SOCKET_EVENTS.CHAT_INBOX_ACK}
 */
export type RoomInboxAckPayload = {
  userId: string;
  deviceId: string;
  eventId: string;
  lastAckTimestamp: string;
  type: RoomInboxAckType;
};

export type RoomMemberUpdateResponse = {
  roomId: string;
  userId: string;
  newState: MemberState;
};

export type RoomMembersPayload = {
  roomId: string;
};

export type RoomMemberUpdatePayload = {
  roomId: string;
  userId: string;
};

export type RoomLatestAcknowledge = {
  contextId: string;
  contextType: string;
  lastAckTimestamp: string;
  userId: string;
};
