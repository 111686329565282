import { FormControl } from '@angular/forms';
import { ActionCreator } from '@ngrx/store';

export type ActionParamType<A extends ActionCreator, K extends keyof ReturnType<A>> = Parameters<A>[0][K];

export type ArrayType<T> = T extends Array<infer U> ? U : never;

export enum LoadResultMode {
  NORMAL = 'normal',
  SEARCH = 'search'
}

export interface LoadResult<D> {
  mode: LoadResultMode;
  data: D;
}

export type ObjectTypeFromArray<T extends ReadonlyArray<any>, K = any> = { [k in T[number]]: K };

export type SuffixProps<T, P extends string> = {
  [K in keyof T & string as `${K}${P}`]: T[K]
};

export type ToFormControls<T> = {
  [K in keyof T]: FormControl<T[K]>
};
