@if (currentChannel$ | async; as currentChannel) {
  <div class="wen-channel-view-desktop-header-content" (click)="openDetail(currentChannel)">
    <wen-active-item-image-view [overlaySize]="'medium'"></wen-active-item-image-view>
    <div class="wen-channel-view-desktop-header-info">
      <div class="wen-channel-view-desktop-header-info-title">
        @if (currentChannel.isPrivate && !currentChannel.subscribed) {
          <wen-icon class="wen-icon-small" [iconName]="'lock'" [color]="'#767676'"></wen-icon>
        }
        <div portal-title>{{ currentChannel.title }}</div>
        @if (currentChannel.isMuted) {
          <wen-icon class="wen-icon-small" iconName="mute" [color]="'#767676'"></wen-icon>
        }
      </div>
      @if (currentChannel.channelType !== channelType.PERSONAL) {
        <div portal-body-2>{{ currentChannel.subscriptions }} {{'SUBSCRIBER' | translate: {count: currentChannel.subscriptions} }}
        </div>
      }
    </div>
  </div>
}
