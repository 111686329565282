<div class="wen-swipeable-container" [class]="contentAlign">
  <div class="wen-swipeable-indicator left-indicator" #leftIndicator>
    <ng-content select="[swipeableLeftIndicator]"></ng-content>
  </div>
  <div class="wen-swipeable-indicator right-indicator" #rightIndicator>
    <ng-content select="[swipeableRightIndicator]"></ng-content>
  </div>
  <div class="wen-swipeable-content" #content>
    <ng-content select="[swipeableContent]"></ng-content>
  </div>
</div>