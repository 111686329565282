import { inject, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PasswordChangeRequestViolationErrorType, SocketIoService } from '@portal/wen-backend-api';
import { WenSnackbarOpener } from '@portal/wen-components';
import { map, Observable } from 'rxjs';
import { UserChangePasswordChangedValuesStep1 } from '../../../../../core/store/form/types/user-change-password-types';
import { FormAsyncValidator } from '../../../../../shared/form-store/form-store.providers';

@Injectable()
export class UserChangePasswordDatasource implements FormAsyncValidator<UserChangePasswordChangedValuesStep1> {

  private socketIoService = inject(SocketIoService);
  private snackbarOpener = inject(WenSnackbarOpener);
  private translateService = inject(TranslateService);

  private violationToErrorMap: Record<PasswordChangeRequestViolationErrorType, string> = {
    InvalidOldPassword: 'USER_PROFILE_FORM_CHANGE_PASSWORD_OLD_PASSWORD_MISMATCH_ERROR',
    UnchangedPassword: 'USER_PROFILE_FORM_CHANGE_PASSWORD_FORM_PASSWORD_MUST_NOT_EQUAL_ERROR',
  };

  validate(control: AbstractControl<UserChangePasswordChangedValuesStep1>): Observable<ValidationErrors> {
    const { newPassword, oldPassword } = control.value;
    return this.socketIoService.user.requestPasswordChange.acknowledgement$({
      oldPassword: btoa(oldPassword),
      newPassword: btoa(newPassword)
    }).pipe(
      map((result) => {
        const firstViolation = Array.isArray(result.violations) && result.violations[0];
        if (!firstViolation) {
          return null;
        }
        const errorMessage = this.violationToErrorMap[firstViolation.id];
        if (errorMessage) {
          this.snackbarOpener.openNotificationSnackbar({
            notificationIcon: 'error',
            notificationText: this.translateService.instant(errorMessage)
          });
        }
        const errors = result.violations.reduce((acc, violation) => {
          return { ...acc, [violation.id]: true };
        }, {});
        return errors;
      })
    );
  }

}
