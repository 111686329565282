import { WenRouteId, WenRoutes } from '../../../frame/routing/types';
import { AdaptiveBackgroundComponent } from '../../../shared/components/adaptive-background/adaptive-background.component';
import { signInInviteRoute, signInPopupRoute, signInStartRoute } from './signin.routes';

export const singleOutletSignInRoutes: WenRoutes = [
  signInStartRoute,
  signInPopupRoute,
  signInInviteRoute,
  {
    path: 'home',
    component: AdaptiveBackgroundComponent,
    data: {
      routeId: WenRouteId.SIGN_IN_HOME
    }
  },
  {
    path: '**',
    redirectTo: 'start'
  }
];
