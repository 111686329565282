
import { getMediaTypeData } from '../../../util/media-type-util';
import { EmbedDescription } from '../embed-description.component';
import { EmbedDTOTypes, isMediaEmbed } from '@portal/wen-backend-api';

export interface EmbedDescriptionHandler {
  getEmbedDescription(embed: EmbedDTOTypes): EmbedDescription;
}

export class WenEmbedDescriptionHandler implements EmbedDescriptionHandler {
  getEmbedDescription(embed: EmbedDTOTypes): EmbedDescription {
    if (isMediaEmbed(embed)) {
      const data = getMediaTypeData(embed.subType);
      return { icon: data.icon, translationKey: data.title };
    }
  }
}
