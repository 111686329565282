import { animate, style, transition, trigger } from '@angular/animations';

export const SLIDE_IN_OUT = 'slideInOut';
export const contextMenuAnimations = {
  slideInOut: trigger(SLIDE_IN_OUT, [
    transition(':enter', [
      style({ transform: 'translateY(100%)' }),
      animate('200ms ease-out', style({ transform: 'translateY(0%)' }))
    ]),
    transition(':leave', [
      animate('200ms ease-out', style({ transform: 'translateY(100%)' }))
    ])
  ])
};
