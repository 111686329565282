import { Directive, Host, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, scan, switchMap, takeUntil } from 'rxjs/operators';
import { IfSubscribedToContext } from './if-subscribed-to-context';
import { SubscribedToSource } from './subscribed-to-source';

@Directive({
  selector: '[ifSubscribedTo]'
})
export class IfSubscribedToDirective implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private context = new ReplaySubject<Partial<IfSubscribedToContext>>();

  @Input('ifSubscribedTo')
  set modelId(newValue: string) {
    this.context.next({ modelId: newValue });
  }

  @Input('ifSubscribedToExtraCondition')
  set extraCondition(newValue: boolean) {
    this.context.next({ extraCondition: newValue });
  }

  constructor(
    @Host() private subscriptionSource: SubscribedToSource,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {
  }

  ngOnInit() {
    this.context.pipe(
      scan((acc, newValue) => {
        return { ...acc, ...newValue };
      }, {} as IfSubscribedToContext),
      distinctUntilChanged(),
      switchMap(({ modelId, extraCondition = true }) => {
        return this.subscriptionSource.subscribedIds$.pipe(
          map(subscribedModelIds => subscribedModelIds.includes(modelId) && extraCondition)
        );
      }),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(isVisible => {
      this.updateView(isVisible);
    });
  }

  private updateView(isVisible: boolean) {
    if (isVisible) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
