import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { CoreComponentsModule, WenIconModule, WenTestIdModule } from '@portal/wen-components';
import { WenPipesModule } from '../../pipes/wen-pipes.module';
import { ListItemModule } from '../list-item/list-item.module';
import { LocationSelectorComponent } from './location-selector.component';
import { LocationListItemComponent } from './components/location-list-item/location-list-item.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ListItemModule,
    WenIconModule,
    WenPipesModule,
    TranslateModule,
    CoreComponentsModule,
    WenTestIdModule
  ],
  declarations: [
    LocationSelectorComponent,
    LocationListItemComponent,
  ],
  exports: [
    LocationSelectorComponent
  ]
})
export class LocationSelectorModule { }
