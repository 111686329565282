import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'wen-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements AfterViewInit {

  modelValue = '';
  inputPlaceholder = '';

  @Input() set disabled(value: boolean) {
    if (value) {
      this.handleClear();
      this.searchInput?.nativeElement.blur();
    }
  }

  @Input() set placeholder(newValue: string) {
    this.inputPlaceholder = newValue;
    const inputWidth = this.modelValue ? '100%' : null;
    this.updateSearchBarInputWidth('none', inputWidth);
  }

  @Input() set inputValue(newValue: string) {
    this.modelValue = newValue;
  }

  get clearButtonVisibility() {
    return !this.modelValue ? 'none' : 'block';
  }

  @Output() focused = new EventEmitter<void>();
  @Output() changed = new EventEmitter<string>();
  @Output() cleared = new EventEmitter<void>();

  @ViewChild('searchInput') searchInput: ElementRef<HTMLElement>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
  ) { }

  ngAfterViewInit() {
    this.initialize();
  }

  handleFocus() {
    if (!this.searchInput) {
      return;
    }
    this.focused.emit();
    if (this.modelValue) {
      this.updateSearchBarInputWidth(null, '100%');
    } else {
      this.updateSearchBarInputWidth('width 200ms ease-in-out', '100%');
    }
  }

  handleBlur() {
    if (!this.modelValue) {
      this.updateSearchBarInputWidth('width 200ms ease-in-out', null);
    }
  }

  handleChange(searchTerm: string) {
    this.changed.emit(searchTerm);
  }

  handleClear() {
    this.modelValue = '';
    this.cleared.emit();
  }

  private initialize() {
    if (this.modelValue) {
      this.searchInput.nativeElement.focus();
      this.cdr.detectChanges();
    } else {
      this.updateSearchBarInputWidth();
    }
  }

  private updateSearchBarInputWidth(transition: string = null, widthOverride: string = null) {
    if (this.searchInput) {
      const matFormFieldInfixElement = this.searchInput.nativeElement.parentElement;
      const width = widthOverride || `${this.inputPlaceholder.length * 10}px`;
      this.renderer.setStyle(matFormFieldInfixElement, 'width', width);
      if (transition) {
        this.renderer.setStyle(matFormFieldInfixElement, 'transition', transition);
      } else {
        this.renderer.setStyle(matFormFieldInfixElement, 'transition', 'none');
      }
    }
  }

}
