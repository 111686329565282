<div class="wen-help-view-content">
  @if (config.helpViewEntries$ | async; as helpViewEntries) {
    <div menuEntryLayoutGroup>
      @if (helpViewEntries.length > 0) {
        @for (helpViewEntry of helpViewEntries; track helpViewEntry) {
          <wen-menu-entry class="wen-user-view-profile-entry-primary"
            (click)="handleEntryClick(helpViewEntry.entryId)" [attr.entry-id]="helpViewEntry.entryId">
            <wen-icon class="wen-icon-medium" [fillColor]="helpViewEntry.fillColor" color="white"
              [iconName]="helpViewEntry.iconName" menuEntryIcon>
            </wen-icon>
            <span portal-body-1 menuEntryTitle>{{helpViewEntry.translationKey | translate}}</span>
          </wen-menu-entry>
        }
      }
    </div>
  }
  @if (config.helpRightsEntries$ | async; as helpRightsEntries) {
    <div menuEntryLayoutGroup>
      @if (helpRightsEntries.length > 0) {
        @for (helpRightEntry of helpRightsEntries; track helpRightEntry) {
          <wen-menu-entry class="wen-user-view-profile-entry-primary"
            (click)="handleEntryClick(helpRightEntry.entryId)" [attr.entry-id]="helpRightEntry.entryId">
            <wen-icon class="wen-icon-medium" [fillColor]="helpRightEntry.fillColor" color="white"
              [iconName]="helpRightEntry.iconName" menuEntryIcon>
            </wen-icon>
            <span portal-body-1 menuEntryTitle>{{helpRightEntry.translationKey | translate}}</span>
          </wen-menu-entry>
        }
      }
    </div>
  }

</div>