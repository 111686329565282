export const isNullOrUndefined = (value: any): boolean => {
  return value === undefined || value === null;
};

export const hasProperty = <T extends Record<string, any>>(
  obj: T,
  key: keyof T
) => {
  if (!obj) {
    return false;
  }
  const propNames = Object.getOwnPropertyNames(obj);
  return propNames.includes(key as string);
};

export const equalsIgnoreCase = (val1: string, val2: string) => {
  return val1?.toLowerCase() === val2?.toLowerCase();
};
