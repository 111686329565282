import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormStoreModule } from '../../../shared/form-store/form-store.module';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { UserVisibilityModule } from './components/user-visibility/user-visibility.module';


@NgModule({
  imports: [
    CommonModule,
    UserVisibilityModule,
    ReactiveFormsModule,
    FormStoreModule
  ],
  declarations: [
    UserSettingsComponent
  ]
})
export class UserSettingsModule { }
