import { inject, Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService, UserChangePasswordPayload } from '@portal/wen-backend-api';
import { WenSnackbarOpener } from '@portal/wen-components';
import { merge, Observable, of } from 'rxjs';
import { filter, mergeAll, switchMap, tap } from 'rxjs/operators';
import { WenRouteId } from '../../../../frame/routing/types';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { switchMapFirst } from '../../../common/operators/switch-map-first';
import { AppNavigator } from '../../../services/navigation/app-navigator';
import { DataObjectType } from '../../common/data-objects';
import { RootState } from '../../root/public-api';
import { clearFormValues } from '../form.actions';
import { selectEditFormById } from '../form.selectors';
import { EditFormEntity } from '../form.state';
import { UserChangePasswordChangedValues } from '../types/user-change-password-types';

@Injectable()
export class ChangePasswordFormEffects {

  private store = inject(Store<RootState>);
  private socketIoService = inject(SocketIoService);
  private formStoreMediator = inject(FormStoreMediator);
  private appNavigator = inject(AppNavigator);
  private snackbarOpener = inject(WenSnackbarOpener);
  private translateService = inject(TranslateService);

  saveChangePasswordForm$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.USER_PASSWORD),
    switchMapFirst(({ formId }) => this.store.pipe(select(selectEditFormById(formId)))),
    switchMap((editForm: EditFormEntity<UserChangePasswordChangedValues>) => {
      const { formId, changedValues } = editForm;
      const { oldPassword, newPassword, verificationCode } = changedValues;
      const update: UserChangePasswordPayload = {
        oldPassword: btoa(oldPassword),
        newPassword: btoa(newPassword),
        code: verificationCode
      };

      const afterSaveActions: Observable<Action>[] = [of([
        clearFormValues({ formId }),
      ]).pipe(mergeAll())];
      const result$ = merge(
        afterSaveActions
      ).pipe(mergeAll());
      return this.socketIoService.user.changePassword.acknowledgement$(update).pipe(
        filter((result) => {
          const { ok } = result;
          if (ok) {
            this.snackbarOpener.openNotificationSnackbar({
              notificationIcon: 'success',
              notificationText: this.translateService.instant('USER_PROFILE_FORM_CHANGE_PASSWORD_SUCCESS_TOAST')
            });
          } else {
            this.snackbarOpener.openNotificationSnackbar({
              notificationIcon: 'error',
              notificationText: this.translateService.instant('USER_PROFILE_FORM_CHANGE_PASSWORD_CODE_ERROR_TOAST')
            });
          }
          return ok;
        }),
        switchMap(() => result$),
        tap(() => {
          this.appNavigator.navigateBackInStack(WenRouteId.USERPROFILE_PERSONAL_ACCOUNT_SETTINGS);
        })
      );
    })
  ));

}
