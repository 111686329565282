import { isGuidOnlyRoute } from '../../../core/common/util/routing-helpers';
import { AlwaysHomeBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/always-home-back-navigation';
import { EmbeddedAppBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/embedded-app-back-navigation-behavior';
import { FormEditLeaveConfirmationBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/form-edit-leave-confirmation-behavior';
import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { CreateWelcomeComponent } from '../../../shared/components/create-welcome/create-welcome.component';
import { AppDetailComponent } from '../app-detail/components/app-detail/app-detail.component';
import { AppInfoEditComponent } from '../app-detail/components/app-edit/components/app-info-edit/app-info-edit.component';
import { appCreateGuardFn } from '../app-detail/components/app-edit/guards/app-create.guard';
import { canActivateAppViewGuard } from '../app-detail/components/app-edit/guards/can-activate-app.guard';
import { AppViewComponent } from '../app-detail/components/app-view/app-view.component';
import { AppExploreComponent } from '../app-explore/app-explore.component';
import { DiscoverAppListComponent } from '../app-list/components/discover-app-list/discover-app-list.component';
import { UserAppListComponent } from '../app-list/components/user-app-list/user-app-list.component';
import { discoverAppListDataResolverFn } from '../app-list/providers/discovery-app-list-data.resolver';
import { appEditGuardFn } from '../common/guards/app-edit.guard';
import { appDetailIdentifier, appViewIdentifier } from '../tokens';

export const appListRoute: WenRoute = {
  path: 'list',
  component: UserAppListComponent,
  data: {
    routeId: WenRouteId.APP_LIST
  }
};

export const appDiscoveryRoute: WenRoute = {
  path: 'discovery',
  component: DiscoverAppListComponent,
  data: {
    routeId: WenRouteId.APP_DISCOVERY,
    routeTransitionEnabled: true
  },
  resolve: {
    DiscoverAppListDataResolver: discoverAppListDataResolverFn,
  }
};

export const appDetailRoute: WenRoute = {
  path: `:${appDetailIdentifier}/detail`,
  canActivate: [canActivateAppViewGuard],
  component: AppDetailComponent,
  data: {
    routeId: WenRouteId.APP_DETAIL
  }
};

export const appEditRoute: WenRoute = {
  path: `:${appDetailIdentifier}/edit`,
  component: AppInfoEditComponent,
  canActivate: [appEditGuardFn],
  data: {
    routeId: WenRouteId.APP_INFO_EDIT,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const appAddWelcomeRoute: WenRoute = {
  path: 'add-welcome',
  component: CreateWelcomeComponent,
  data: {
    routeId: WenRouteId.APP_ADD_WELCOME
  }
};

export const addAppInfoRoute: WenRoute = {
  path: 'add-app-info',
  component: AppInfoEditComponent,
  canActivate: [appCreateGuardFn],
  data: {
    routeId: WenRouteId.ADD_APP_INFO,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const appExploreRoute: WenRoute = {
  path: 'explore',
  component: AppExploreComponent,
  data: {
    routeId: WenRouteId.APP_EXPLORE,
    backNavigationBehavior: AlwaysHomeBackNavigation,
  }
};

export const appViewRoute: WenRoute = {
  path: `:${appViewIdentifier}`,
  canMatch: [isGuidOnlyRoute],
  canActivate: [canActivateAppViewGuard],
  component: AppViewComponent,
  data: {
    routeId: WenRouteId.APP_VIEW,
    backNavigationBehavior: EmbeddedAppBackNavigation
  }
};
