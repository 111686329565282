import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { tap } from 'rxjs/operators';
import { togglePinChannel } from '../channel.actions';

export const togglePinChannelEffect = (
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(togglePinChannel),
    tap((action) => {
      socketIoService.channel.togglePin.emit({ channelId: action.channelId, isPinned: action.isPinned });
    }),
  ), { dispatch: false });
};
