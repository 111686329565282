import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Dimensions2D } from '../../util/size';

@Injectable()
export abstract class ImageUrlTransformator {
  abstract transform(value: string, dimensions?: Dimensions2D): string;
}

@Pipe({
  name: 'wenImageUrlTransform',
})
export class ImageUrlTransformPipe implements PipeTransform {

  constructor(
    private imageUrlTransformator: ImageUrlTransformator
  ) { }

  transform(value: string, dimensions?: Dimensions2D) {
    return this.imageUrlTransformator.transform(value, dimensions);
  }
}
