<wen-progressive-slot class="wen-channel-view-content-slot-wrapper" [visibilityCondition]="isChannelDataReady$ | async">
  <div class="wen-channel-view-content" portalDragOverlay [wen-test-id]="'wen-channel-view-content'"
    [overlayContent]="overlayContent" (fileDropped)="onFilesDropped($event)"
    [dragOverlayEnabled]="(dragOverlayEnabled$ | async)">
    @if (filteringEnabled$ | async) {
      <wen-filter-view [formGroup]="geo.filterGroup">
        <wen-filter-select-component [formControlName]="geo.geoLocationControlName" [defaultFilter]="geo.defaultGeoFilter"
          filter-element [filterEntityType]="geo.geoFilterEntityType"
        (click)="openGeoFilterSelector()"></wen-filter-select-component>
      </wen-filter-view>
    }
    <ng-container
      *ifNotSubscribedTo="datasource.currentChannelId$ | async; extraCondition: datasource.isContentBlocked$ | async, else noRestrictions">
      <div class="wen-channel-view-channel-restriction-wrapper">
        <wen-channel-content-curtain [restrictions]="datasource.unfulfilledRestrictions$ | async"
        [inviteData]="datasource.inviteData$ | async"></wen-channel-content-curtain>
      </div>
    </ng-container>
    <ng-template #noRestrictions>
      <wen-progressive-slot [visibilityCondition]="isChannelHistoryLoaded$ | async">
        <wen-channel-messages-view
          [class.wen-channel-messages-view-has-filter]="filteringEnabled$ | async"
        [channelId]="currentChannelId$ | async"></wen-channel-messages-view>
      </wen-progressive-slot>
    </ng-template>
  </div>
  <div class="channel-view-bottom-controls" [wen-test-id]="'channel-bottom-control'">
    <ng-container *ifNotSubscribedTo="datasource.currentChannelId$ | async">
      <wen-channel-subscription-controls [channelId]="datasource.currentChannelId$ | async"
        [restrictions]="datasource.restrictions$ | async"
      [inviteData]="datasource.inviteData$ | async"></wen-channel-subscription-controls>
    </ng-container>
    <div class="wen-new-message-creation-wrapper" [wen-test-id]="'wen-new-message-creation'">
      @if (datasource.canSend$ | async) {
        <wen-channel-message-send-field [hasControls]="(datasource.isGeoChannel$ | async) === false"
          [inputPlaceholder]="((datasource.isGeoChannel$ | async) ? 'SEND_GEO_CHANNEL_MESSAGE_INPUT_PLACEHOLDER' : 'SEND_MESSAGE_INPUT_PLACEHOLDER') | translate"
          (messageSent)="onSend()" (focused)="onFocusSendField()" (click)="onClickSendField()">
        </wen-channel-message-send-field>
      } @else {
        <ng-container *ifSubscribedTo="datasource.currentChannelId$ | async">
          @if (datasource.isMuted$ | async) {
            <wen-interact-button class="channel-view-unmute-button" (click)="unMuteToChannel()">{{
            "CHANNEL_UNMUTE_BUTTON_LABEL" | translate }}</wen-interact-button>
          } @else {
            <wen-interact-button class="channel-view-mute-button" (click)="muteToChannel()">{{
            "CHANNEL_MUTE_BUTTON_LABEL" | translate }}</wen-interact-button>
          }
        </ng-container>
      }
    </div>
  </div>
</wen-progressive-slot>
