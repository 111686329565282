import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

export type RangeData = {
  start: Date;
  end: Date;
};

@Injectable()
export abstract class RangeFormatter {
  abstract format(range: RangeData);
}

@Injectable()
export class LuxonFormatter extends RangeFormatter {
  format(range: RangeData): DateTime[] {
    const mappedRange = Object.entries(range).map(([key, value]) => {
      return DateTime.fromJSDate(value);
    });

    const end = mappedRange[1];
    if (end) {
      mappedRange[1] = end.endOf('day');
    }

    return mappedRange;
  }
}


