import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { NavigationStateTracker } from '@portal/wen-components';
import { from, of, Subject, timeout } from 'rxjs';
import { EmbeddedAppUrlChangeEmitter } from '../../../../shared/components/embedded-page-viewer/types/embedded-app-types';
import { EmbeddedAppContext } from '../state/embedded-app-context';

@Injectable()
export class EmbeddedAppNavigator implements EmbeddedAppUrlChangeEmitter {

  private location = inject(Location);

  private initialHistoryLength: number = null;
  private tracker = new NavigationStateTracker();

  private onBackNavigation = new Subject<string>();
  onUrlChanged$ = this.onBackNavigation.asObservable();

  constructor(
    private readonly embeddedAppContext: EmbeddedAppContext,
  ) { }

  initNavigator(initialSrc: string) {
    this.initialHistoryLength = history.length;
    this.tracker = new NavigationStateTracker();
    this.tracker.onPathChanged(initialSrc);
  }

  private getBacknavigationStepCount() {
    const currentHistoryPosition = history.length;
    const relativePosition = this.initialHistoryLength - currentHistoryPosition - 1;
    return relativePosition;
  }

  isCompressedNavigationMode() {
    const backNavigationMode = this.embeddedAppContext.getContext()?.backNavigationMode;
    return backNavigationMode === 'COMPRESSED';
  }

  onPathChanged(url: string) {
    this.tracker.onPathChanged(url);
  }

  canNavigateBack() {
    const interceptorFn = this.embeddedAppContext.getContext()?.interceptorFn;
    if (!interceptorFn) {
      return of(true);
    }
    return from(interceptorFn()).pipe(
      timeout({
        each: 1000,
        with: () => of(true)
      })
    );
  }

  backNavigate() {
    this.tracker.popLastPath();
    if (this.tracker.isEmpty()) {
      this.exitEmbeddedApp();
    } else {
      const url = this.tracker.getLastPathInHistory();
      this.onBackNavigation.next(url);
    }
  }

  exitEmbeddedApp() {
    const numm = this.getBacknavigationStepCount();
    this.location.historyGo(numm);
  }
}
