import { inject, Injectable } from '@angular/core';
import { ChatMessageTransactionData } from '@portal/wen-chat-client';
import { DistributionChatAttachmentLoader } from '../../../smartdesign/chat-distribution/distribution-chat-attachment-loader';
import { DistributionChatMessageProcessor } from '../../../smartdesign/chat-distribution/distribution-chat-message-processor';
import { DistributionDataImporter } from '../../../smartdesign/chat-distribution/distribution-data-importer';
import { DistributionMessageLoader } from './crm/distributon-message-loader';
import { SimpleMessageLoader } from './draft/simple-message-loader';

@Injectable()
export class MessageLoaderStrategyFactory {

  private dataImporter = inject(DistributionDataImporter);
  private attachmentLoader = inject(DistributionChatAttachmentLoader);
  private distributionChatMessageProcessor = inject(DistributionChatMessageProcessor);

  createForDirectSending(dialogRoomDatas: ChatMessageTransactionData[]) {
    return new SimpleMessageLoader(dialogRoomDatas);
  }

  createForDistribution() {
    return new DistributionMessageLoader(
      this.dataImporter,
      this.attachmentLoader,
      this.distributionChatMessageProcessor
    );
  }

}
