
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { Observable, of } from 'rxjs';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { AppEntity } from '../../../../../core/store/apps/apps.state';

@Injectable()
export class AppDetailAction implements ActionMenuItem {

  label: string;
  icon = 'info';

  constructor(
    private translateService: TranslateService,
    private navigationHelper: WenNavigationHelper
  ) {
    this.label = this.translateService.instant('APP_CONTEXT_MENU_LABEL_OPEN_DETAIL');
  }


  isVisible$: (context?: any) => Observable<boolean> = () => of(true);

  execute(app: AppEntity) {
    this.navigationHelper.navigateToAppDetail(app.id);
  }
}
