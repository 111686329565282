import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { generateId, ListEventRequestParameters, SocketIoService } from '@portal/wen-backend-api';
import { catchError, distinctUntilChanged, filter, first, map, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { WenRouteId } from '../../../../frame/routing/types';
import { DateUtil } from '../../../common/date/date-util';
import { isNullOrUndefined } from '../../../common/operators/null-check-util';
import { LoadingState } from '../../../common/types/store-loading-state';
import { RootState } from '../../root/public-api';
import { appendUserListOccurences, extractSubscription, fetchUserEvents, onUserEventListResult, updateUserEventsLoadingState, updateuserEventsPaging, updateUserListOccurences } from '../event.actions';
import { selectUserEventsIsLoaded, selectUserListPaging } from '../event.selectors';

export const setNotLoadedStateForUserEventListOnFilterChange = (
  currentFilters$: Observable<ListEventRequestParameters>
) => {
  return createEffect(() =>
    currentFilters$.pipe(
      map(() => updateUserEventsLoadingState({ loadingState: LoadingState.NOT_LOADED }))
    )
  );
};

export const resetUserEventListPagingOnFilterChange = (
  currentFilters$: Observable<ListEventRequestParameters>
) => {
  return createEffect(() =>
    currentFilters$.pipe(
      map(() => updateuserEventsPaging({ userEventsPaging: null }))
    )
  );
};

export const createUserEventListNavigationEffect = (
  actions$: Actions,
  carriedOverFilterString$: Observable<ListEventRequestParameters>,
  socketIoService: Pick<SocketIoService, 'events'>,
  store: Store<RootState>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(fetchUserEvents),
      concatLatestFrom(() => store.pipe(
        select(selectUserEventsIsLoaded)
      )),
      filter(([_, hasLoadedContent]) => !hasLoadedContent),
      concatLatestFrom(() => carriedOverFilterString$.pipe(first())),
      map(([_, eventFilters]) => {
        return isNullOrUndefined(eventFilters) ? {} : eventFilters;
      }),
      switchMap((eventFilters) => {
        return socketIoService.events.listForUser.acknowledgement$(eventFilters).pipe(
          map((eventListResult) => onUserEventListResult(eventListResult)),
        );
      })
    )
  );
};

export const createUserEventListFilterUpdateEffect = (
  currentFilters$: Observable<ListEventRequestParameters>,
  currentPage$: Observable<WenRouteId>,
  socketIoService: Pick<SocketIoService, 'events'>
) => {
  return createEffect(() => {
    return currentFilters$.pipe(
      withLatestFrom(currentPage$),
      filter(([_, currentPage]) => currentPage === WenRouteId.EVENT_LIST),
      distinctUntilChanged(([prevFilters], [newFilters]) => {
        return JSON.stringify(prevFilters) === JSON.stringify(newFilters);
      }),
      map(([eventFilters]) => {
        return isNullOrUndefined(eventFilters) ? {} : eventFilters;
      }),
      switchMap((eventFilters) => {
        return socketIoService.events.listForUser.acknowledgement$(eventFilters).pipe(
          map((eventListResult) => onUserEventListResult(eventListResult)),
        );
      })
    );
  }
  );
};

export const createUserEventListEffect = (
  action$: Actions,
  store: Store<RootState>
) => {
  return createEffect(() =>
    action$.pipe(
      ofType(onUserEventListResult),
      withLatestFrom(store.pipe(select(selectUserListPaging))),
      switchMap(([userEvents, userListPaging]) => {
        const { content, ...paging } = userEvents;
        if (content && paging) {
          const contentWithOccurenceId = content.map(ev => ({ ...ev, id: ev.recurring ? generateId() : ev.id, eventId: ev.id }));
          const eventVisibilityAction = DateUtil.compare(userListPaging?.endDate, paging.startDate) === 0
            ? appendUserListOccurences({ data: contentWithOccurenceId })
            : updateUserListOccurences({ data: contentWithOccurenceId });

          return [
            updateUserEventsLoadingState({ loadingState: LoadingState.LOADED }),
            updateuserEventsPaging({ userEventsPaging: paging }),
            extractSubscription({ data: contentWithOccurenceId }),
            eventVisibilityAction
          ];
        }
        return of(updateUserEventsLoadingState({ loadingState: LoadingState.LOADED }));
      }),
      catchError((e) => of(updateUserEventsLoadingState({ loadingState: LoadingState.ERROR })))
    )
  );
};
