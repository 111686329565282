import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '@portal/wen-components';
import { EmbedForwardComponent } from './embed-forward.component';
import { ForwardContentDirective } from './forward.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    WenIconModule
  ],
  declarations: [EmbedForwardComponent, ForwardContentDirective],
  exports: [EmbedForwardComponent, ForwardContentDirective],
})
export class EmbedForwardModule { }
