import { Injectable } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { OverlayManager } from '@portal/wen-components';
import { filter, first, map } from 'rxjs';
import { GeoFilterEntityValue } from '../../../../../../core/store/filter/models/filter';
import { GeoFilterSelectComponent, GeoFilterSelectDialogData, GeoFilterSelectDialogResponse } from '../../../../../../shared/components/filter-view/components/geo-filter-select/geo-filter-select.component';

export type GeoFilterValueModel = GeoFilterEntityValue;

@Injectable()
export class GeoLocationFilterDataSource {

  constructor(
    private socketIoService: SocketIoService,
    private overlayManager: OverlayManager,
  ) { }

  loadGeoFilter(channelId: string) {
    return this.socketIoService.channel.geoFilterListForUser.listen.pipe(
      map((geoFilterResponse) => {
        return geoFilterResponse.find(geoFilter => geoFilter.id.channelId === channelId);
      })
    );
  }

  openFilterSelector(activeGeoFilter: GeoFilterValueModel) {
    const dialogData: GeoFilterSelectDialogData = {
      activeGeoFilter: activeGeoFilter?.data
    };
    const dialogRef = this.overlayManager.dialog.openFilterSelectorDialog<
      GeoFilterSelectComponent, GeoFilterSelectDialogData, GeoFilterSelectDialogResponse
    >(GeoFilterSelectComponent, dialogData);
    return dialogRef.beforeClosed().pipe(
      first(),
      filter(dialogResult => Boolean(dialogResult))
    );
  }

}
