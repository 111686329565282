import { MediaSettingsService } from '../../../../../../../../core/services/media/media-settings.service';
import { Component, Input } from '@angular/core';
import { MediaViewParams } from '../../models/models';

@Component({
  selector: 'wen-embed-error',
  templateUrl: './embed-error.component.html',
  styleUrls: ['./embed-error.component.scss'],
})
export class EmbedErrorComponent {
  errorMessage = 'ERROR_MEDIA_UPLOAD_UNKNOWN';
  messageParams = {};
  @Input() set config(params: MediaViewParams) {
    switch (params.errorCode) {
      case 'UPLOAD_INTERRUPTED':
      case 'CONNECTION_PROBLEM':
        this.errorMessage = 'ERROR_MEDIA_UPLOAD_CONNECTION_PROBLEM';
        break;
      case 'SIZE_TOO_BIG':
        this.errorMessage = 'ERROR_MEDIA_UPLOAD_SIZE_TOO_BIG';
        this.messageParams = { maxFileSize: this.mediaSettingsService.getMaxFileSizeInMiB().toString() };
        break;
      case 'DIMENSIONS_TOO_BIG':
        this.errorMessage = 'ERROR_MEDIA_UPLOAD_DIMENSIONS_TOO_BIG';
        this.messageParams = { maxDimensions: this.mediaSettingsService.getMaxImagePixelDimensions().toString() };
        break;
      default:
        this.errorMessage = 'ERROR_MEDIA_UPLOAD_UNKNOWN';
        break;
    }
  }

  constructor(
    private mediaSettingsService: MediaSettingsService
  ) {}
}
