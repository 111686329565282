import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { shareReplay } from 'rxjs';
import { RootState } from '../../../core/store/root/public-api';
import { selectUserProfile } from '../../../core/store/user/user.selectors';

@Injectable()
export class UserProfileService {

  currentUser$ = this.store.pipe(
    select(selectUserProfile),
    shareReplay(1)
  );

  constructor(private store: Store<RootState>) { }

}
