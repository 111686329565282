import { BehaviorSubject, map, Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaViewParams, MediaEventOptions } from '../../models/models';
import { ThumbnailProvider } from '../../directives/thumbnail-evaluator.directive';
import { LazyLoadParams } from '../../../../../../../directives/lazy-load/lazy-load.directive';

@Component({
  selector: 'wen-embed-image',
  templateUrl: './embed-image.component.html',
  styleUrls: ['./embed-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedImageComponent implements OnDestroy {

  private params: MediaViewParams;
  private isMediaClickable = false;
  thumbnail$: Observable<LazyLoadParams> = this.thumbnailProvider.thumbnail$.pipe(
    map(({ url, scaledDimensions: { width, height } }) => {
      return {
        src: url,
        originalWidth: width,
        originalHeight: height
      };
    })
  );
  error = false;
  showLoading = true;

  embedView$ = new BehaviorSubject<'normal' | 'error'>('normal');

  @HostBinding('class.wen-image-embed') className = true;

  @ViewChild('image') imageElement: ElementRef<HTMLImageElement>;

  @Input() set config(params: MediaViewParams) {
    this.params = params;
  }

  @Output() mediaClick = new EventEmitter<MediaEventOptions>();

  constructor(private thumbnailProvider: ThumbnailProvider) { }

  onMediaClick(event: Event, thumbnail: LazyLoadParams) {
    if (!this.isMediaClickable) {
      event.preventDefault();
      return;
    }
    this.mediaClick.next({ mediaId: this.params.id, thumbnailUrl: thumbnail.src });
  }

  ngOnDestroy() {
    this.params = null;
  }

  handleImageError() {
    this.isMediaClickable = false;
  }

  onFinalError = () => {
    this.error = true;
    this.showLoading = false;
  };

  hadleImageLoaded() {
    this.error = false;
    this.isMediaClickable = true;
    this.showLoading = false;
  }

  reloadImage() {
    this.error = false;
    const imageElement = this.imageElement.nativeElement;
    imageElement.src = imageElement.src;
    this.showLoading = true;
  }
}
