import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenSectionModule } from '@portal/wen-components';
import { ExploreEventDataResolver } from '../event-list/providers/explore-event.resolver';
import { EventSectionModule } from './components/event-section/event-section.module';
import { ExploreEventComponent } from './explore-event.component';
import { SharedModule } from '../../../shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollStateModule } from '../../../shared/directives/scroll-state/scroll-state.module';

@NgModule({
  imports: [CommonModule, SharedModule, EventSectionModule, WenSectionModule, ScrollingModule, ScrollStateModule],
  exports: [],
  declarations: [ExploreEventComponent],
  providers: [ExploreEventDataResolver],
})
export class ExploreEventModule { }
