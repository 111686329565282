import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { subscribeForEvent, unsubscribeFromEvent } from '../../../../../core/store/events/event.actions';
import { selectSubscriptionForEvent } from '../../../../../core/store/events/event.selectors';
import { SubscriptionEntity } from '../../../../../core/store/events/event.state';
import { RootState } from '../../../../../core/store/root/public-api';

@Component({
  selector: 'wen-quick-subscription-panel',
  templateUrl: './quick-subscription-panel.component.html',
})
export class QuickSubscriptionPanelComponent {
  subscription$: Observable<SubscriptionEntity>;
  id: string;

  @Input()
  set eventId(id: string) {
    this.id = id;
    this.subscription$ = this.store.pipe(
      selectorWithParam(selectSubscriptionForEvent, id)
    );
  }

  constructor(
    private store: Store<RootState>
  ) { }

  onSubscriptionCountClick(subscribed: boolean) {
    if (subscribed) {
      this.store.dispatch(unsubscribeFromEvent(this.id));
    } else {
      this.store.dispatch(subscribeForEvent(this.id));
    }
  }
}
