import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EventPaging, ListEventRequestParameters, SocketIoService } from '@portal/wen-backend-api';
import { filter, map, Observable, switchMap, withLatestFrom } from 'rxjs';
import { DateUtil } from '../../../common/date/date-util';
import { RootState } from '../../root/public-api';
import { fetchDiscoveryEventsNextPage, fetchNextPageEvents, fetchUserEventNextPage, onDiscoveryEventListResult, onUserEventListResult } from '../event.actions';
import { selectDiscoveryListPaging, selectUserListPaging } from '../event.selectors';

export const listenForUserEventNextPage = (
  store: Store<RootState>,
  actions$: Actions,
  socketIoService: SocketIoService
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(fetchUserEventNextPage),
      withLatestFrom(store.select(selectUserListPaging)),
      filter(([_, pagingParams]) => pagingParams?.hasLaterEvent),
      map(([_, pagingParams]) => {
        return fetchNextPageEvents({
          emitter: socketIoService.events.listForUser.acknowledgement$,
          pagingParams,
          onSuccess: onUserEventListResult
        });
      })
    )
  );
};

export const listenForDiscoverEventNextPage = (
  store: Store<RootState>,
  actions$: Actions,
  socketIoService: SocketIoService
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(fetchDiscoveryEventsNextPage),
      withLatestFrom(store.select(selectDiscoveryListPaging)),
      filter(([_, pagingParams]) => pagingParams?.hasLaterEvent),
      map(([_, pagingParams]) => {
        return fetchNextPageEvents({
          emitter: socketIoService.events.listForDiscover.acknowledgement$,
          pagingParams,
          onSuccess: onDiscoveryEventListResult
        });
      })
    )
  );
};


export const fetchNextPageEvent = (
  store: Store<RootState>,
  actions$: Actions,
  activeFilters$: Observable<ListEventRequestParameters>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(fetchNextPageEvents),
      withLatestFrom(activeFilters$),
      filter(([{ pagingParams }, eventFilters]) =>
        !!DateUtil.compare(pagingParams?.startDate, pagingParams?.endDate)
        && !eventFilters?.endDate
      ),
      switchMap(([{ emitter, pagingParams, onSuccess }, eventFilters]) => {
        const nextStartDate = eventFilters?.endDate ? eventFilters.endDate : pagingParams.endDate;
        eventFilters = { ...eventFilters, ...getNextPagingParams(nextStartDate) };
        return emitter(eventFilters).pipe(
          map(eventListResponse => onSuccess(eventListResponse))
        );
      })
    )
  );
};

const getNextPagingParams = (startDate: string): EventPaging => {
  return { startDate };
};
