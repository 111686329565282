import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { WenNavigationHelper } from '../../../core/services/navigation/types';
import { FeaturedEntity } from '../../../core/store/channel/channel.state';
import { CommentMessageSendFieldDataSource } from '../../../shared/components/comments/comment-message-send-field/comment-message-send-field-data-source';
import { CommentListPagingHelper } from '../../../shared/components/comments/providers/comment-list-paging-helper';
import { FeedDatasource } from '../../../shared/components/feed/providers/feed-datasource';
import { FeedLayoutMediator } from '../../../shared/components/feed/providers/feed-layout-mediator';
import { MessageSendFieldDataSource } from '../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { MessageSeparatorDateFormatter, PastMessageSeparatorDateFormatter } from '../../../shared/directives/directives/message-separator-formatters';
import { FeaturedChannelMessagesDatasource } from './providers/featured-channel-messages.datasource';
import { MessageBoxActionsHandler } from '../../../shared/components/message-box/providers/message-box-tokens';
import { ChannelMessageBoxActionsHandler } from '../common/message-box-actions/channel-message-box-actions-handler';

@Component({
  selector: 'wen-channel-featured-view',
  templateUrl: './channel-featured-view.component.html',
  styleUrls: ['./channel-featured-view.component.scss'],
  providers: [
    CommentListPagingHelper,
    { provide: MessageSendFieldDataSource, useClass: CommentMessageSendFieldDataSource },
    {
      provide: FeedDatasource,
      useClass: FeaturedChannelMessagesDatasource
    },
    FeedLayoutMediator,
    {
      provide: MessageBoxActionsHandler,
      useClass: ChannelMessageBoxActionsHandler
    },
    {
      provide: MessageSeparatorDateFormatter,
      useClass: PastMessageSeparatorDateFormatter
    }
  ]
})
export class ChannelFeaturedViewComponent implements OnInit, AfterViewInit {

  commentsEnabled$: Observable<boolean>;
  featuredData$: Observable<FeaturedEntity>;
  showCommentButton$: Observable<boolean>;

  constructor(
    private readonly navigationHelper: WenNavigationHelper,
    @Inject(FeedDatasource) private readonly datasource: FeaturedChannelMessagesDatasource,
  ) { }

  ngOnInit(): void {
    this.featuredData$ = this.datasource.featuredData$;
    this.commentsEnabled$ = this.datasource.commentsEnabled$;
  }

  ngAfterViewInit() {
    this.showCommentButton$ = combineLatest([
      this.featuredData$,
      this.datasource.commentsEnabled$
    ]).pipe(
      map(([featureData, commentsEnabled]) => featureData?.message && commentsEnabled)
    );
  }

  navigateToChannel(channelId: string) {
    this.navigationHelper.navigateToChannelView(channelId);
  }
}
