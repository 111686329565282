import { InjectionToken, Type } from '@angular/core';
import { Filter, FilterEntityType } from '../../store/filter/models/filter';

export interface FilteredListMassDataConnectorConfig<DL, DF> {
  list: Type<DL>;
  filterList: Type<DF>;
  relevantFilterEntity: FilterEntityType;
  selectRelevantFilter: (filter: Filter[]) => Filter[];
}
export const FILTERED_LIST_MASS_DATA_CONTAINER_CONFIG = new InjectionToken<FilteredListMassDataConnectorConfig<any, any>>(
  'FILTERED_LIST_MASS_DATA_CONTAINER_CONFIG'
);
