import { Injectable } from '@angular/core';
import { EntityAdapter, EntityState } from '@ngrx/entity';
import { MassDataOperations } from './data/mass-data-operations';
import { MassDataEntityStateProps, MassDataLoadingProgress, MassDataUpdates } from './types';

@Injectable()
export abstract class MassDataStateAdapter<ENTITY> {

  private operations = new MassDataOperations<ENTITY>(this.getEntityAdapter());
  abstract getEntityAdapter(): EntityAdapter<ENTITY>;

  getInitialState() {
    const additionalState: MassDataEntityStateProps = {
      loadingProgress: MassDataLoadingProgress.IDLE
    };
    return this.getEntityAdapter().getInitialState(additionalState);
  }

  setAll(state: EntityState<ENTITY>, data: ENTITY[]) {
    return this.operations.setAll(
      state, data
    );
  }

  updateMany(state: EntityState<ENTITY>, data: MassDataUpdates<ENTITY>) {
    return this.operations.updateMany(state, data);
  }

  removeMany(state: EntityState<ENTITY>, data: string[]) {
    return this.operations.removeMany(state, data);
  }

  upsertMany(state: EntityState<ENTITY>, data: ENTITY[]) {
    return this.operations.upsertMany(
      state, data
    );
  }

  removeAll(state: EntityState<ENTITY>) {
    return this.operations.removeAll(state);
  }


}
