import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenStylingModule } from '../../directives/styling/styling.module';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { ButtonComponent } from './button/button.component';

@NgModule({
  imports: [WenIconModule, CommonModule, WenStylingModule],
  exports: [ButtonComponent, WenStylingModule],
  declarations: [ButtonComponent],
})
export class CoreComponentsModule { }
