import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: 'menuEntryIcon, [menuEntryIcon]'
})
export class MenuEntryIconDirective { }

@Directive({
  selector: 'menuEntryTitle, [menuEntryTitle]'
})
export class MenuEntryTitleDirective {

  @HostBinding('class.wen-menu-entry-title-content') className = true;

}

@Directive({
  selector: 'menuEntryDescription, [menuEntryDescription]'
})
export class MenuEntryDescriptionDirective { }

@Directive({
  selector: 'menuEntryLayoutGroup, [menuEntryLayoutGroup]'
})
export class MenuEntryLayoutGroupDirective {

  @HostBinding('class.wen-menu-entry-layout-group') className = true;

}
