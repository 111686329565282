import { createAction, props } from '@ngrx/store';
import { NetworkEntity } from './network.state';

export const subscribeToNetwork = createAction(
  '[Network] Subscribe to a network',
  props<{ networkId: string }>()
);

export const setActiveNetwork = createAction(
  '[Network] Set the active network',
  props<{ networkId: string }>()
);

export const upsertKnownNetworks = createAction(
  '[Network] Upsert the currently known networks',
  props<{ networks: NetworkEntity[] }>()
);
