export interface GeoAutocompletePayload {
  query: string;
}

export interface GeoFeatureGeometry {
  type: 'Point';
  /**
   * Coordinate position in the order of: [longitude, latitude]
   */
  coordinates: [number, number];
}

export enum GeoFeatureEntityType {
  UNDEFINED = 'UNDEFINED',
  AIRPORT = 'AIRPORT',
  CITY = 'CITY',
  CITY_WITH_ZIP = 'CITY_WITH_ZIP',
  CITYPART = 'CITYPART',
  CITYPART_WITH_ZIP = 'CITYPART_WITH_ZIP',
  COUNTY = 'COUNTY',
  COUNTRY = 'COUNTRY',
  DISTRICT = 'DISTRICT',
  NEIGHBOURHOOD = 'NEIGHBOURHOOD',
  STATE = 'STATE',
  STREET = 'STREET',
  STREET_WITHOUT_CITY = 'STREET_WITHOUT_CITY',
  TRAIN_STATION = 'TRAIN_STATION',
  ZIP = 'ZIP',
  ISLAND = 'ISLAND',
  VILLAGE = 'VILLAGE',
  ATTRACTION = 'ATTRACTION',
}

export interface GeoFeatureProperties {
  geoEntityType: GeoFeatureEntityType;
  countryLongName: string;
  country: string;
  state: string;
  neighbourhood: string;
  district: string;
  county: string;
  city: string;
  cityPart: string;
  zip: string;
  street: string;
  village: string;
  houseNo: string;
  poi: string;
  displayValue: string;
}

export interface GeoAutocompleteFeature {
  type: 'Feature';
  geometry: GeoFeatureGeometry;
  properties: GeoFeatureProperties;
}

export interface GeoAutocompleteResponse {
  type: 'FeatureCollection';
  features: GeoAutocompleteFeature[];
}

export interface GeoReversePayload {
  coordinates: [number, number];
}

export type GeoReverseResponse = GeoAutocompleteResponse;

export type GeoLocationUpdatePayload = {
  geoJson: GeoAutocompleteFeature;
};

export type GeoLocationHistoryEntry = {
  geoFeature: GeoAutocompleteFeature;
  timestamp: string;
};
