import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { ChannelMessageComposer, ChannelMessageComposerData, ChannelMessageComposerExtras, DraftMessageData, EmbedDTOTypes, generateId } from '@portal/wen-backend-api';
import { deepEqual } from '@portal/wen-components';
import { combineLatest, merge, of, race } from 'rxjs';
import { filter, first, mergeAll, switchMap } from 'rxjs/operators';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { nonNullArray } from '../../../common/operators/array-utils';
import { FilterId } from '../../../common/types/filter-id';
import { createServerTimeout } from '../../../common/util/create-timer';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { FeatureEnablementService } from '../../../services/configuration/feature-enablement';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { removePendingChannelMessage, updateGeoFilterForChannel } from '../../channel/channel.actions';
import { selectCurrentChannel } from '../../channel/channel.selectors';
import { createPendingMessageAction } from '../../channel/util/pending-message-util';
import { DataObjectType } from '../../common/data-objects';
import { selectActiveFilterById } from '../../filter/filter.selectors';
import { FilterEntityType, isGeoFilter } from '../../filter/models/filter';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { clearFormValues } from '../form.actions';
import { selectEditFormById } from '../form.selectors';
import { ChannelPostEditorChangedValues } from '../types/channel-post-editor.types';

@Injectable()
export class ChannelPostEditorFormEffects {

  saveChannelPostEditorForm$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.CHANNEL_POST),
    switchMap(({ formId }) => combineLatest([
      this.store.pipe(select(selectEditFormById(formId))),
      this.store.pipe(select(selectCurrentUserData)),
      this.store.pipe(select(selectCurrentChannel)),
      this.store.pipe(selectorWithParam(
        selectActiveFilterById,
        FilterEntityType.CHANNEL_MESSAGE_FILTER,
        FilterId.CHANNEL_MESSAGE_GEO_LOCATION
      )),
    ]).pipe(first())),
    switchMap(([editForm, userData, channelEntity, geoFilter]) => {
      const { id: channelId } = channelEntity;
      const { userId } = userData;
      const { formId, changedValues, initialValues } = editForm;
      const { id: messageId } = initialValues as ChannelPostEditorChangedValues;
      const { postContent: { textContent, mediaEmbed, linkEmbed, embedLocation } } = changedValues as ChannelPostEditorChangedValues;
      const sendMessageExtras: ChannelMessageComposerExtras = {
        embeds: {
          mediaEmbeds: nonNullArray([mediaEmbed]),
          linkEmbeds: nonNullArray([linkEmbed]),
          locationEmbeds: nonNullArray([embedLocation])
        }
      };
      const embeds: EmbedDTOTypes[] = nonNullArray([linkEmbed, embedLocation, mediaEmbed]);
      const draftMessageParts: DraftMessageData = {
        draft: true
      };
      const data: ChannelMessageComposerData = {
        id: messageId,
        channelId,
        content: textContent,
        userId,
        ...this.featureEnablementService.featureFlagMethods.isEnableDraftMessageSending() && mediaEmbed?.file && draftMessageParts
      };
      const sendMessage = this.channelMessageComposer.sendMessage(data, sendMessageExtras);

      const beforeSaveActions: Action[] = [];
      const afterSaveActions: Action[] = [];
      if (!sendMessage?.immediate && !data?.draft) {
        const uploadId = generateId();
        beforeSaveActions.push(createPendingMessageAction({
          id: null, channelId, content: textContent, timestamp: null
        }, userData, uploadId, embeds));
        afterSaveActions.push(removePendingChannelMessage({ uploadId, channelId }));
      }
      beforeSaveActions.push(clearFormValues({ formId }));
      beforeSaveActions.push(doBackNavigation());

      if (channelEntity.geo) {
        const currentGeoFilterData = isGeoFilter(geoFilter) ? geoFilter.filterValue.data : null;
        const currentFilterCoords = currentGeoFilterData?.geoJson?.geometry?.coordinates;
        const newCoords = embedLocation.locationData.geometry.coordinates;
        if (currentFilterCoords && !deepEqual(newCoords, currentFilterCoords)) {
          beforeSaveActions.push(updateGeoFilterForChannel({ channelId, distance: 0, geoJson: null }));
        }
      }

      const saveTimeout$ = createServerTimeout();
      const save$ = merge(
        race(sendMessage.result$, saveTimeout$).pipe(
          switchMap(() => afterSaveActions)
        ),
        of(beforeSaveActions).pipe(
          mergeAll()
        )
      );
      return save$;
    })
  ));

  constructor(
    private store: Store<RootState>,
    private formStoreMediator: FormStoreMediator,
    private channelMessageComposer: ChannelMessageComposer,
    private featureEnablementService: FeatureEnablementService
  ) {
  }

}
