import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { WenBreakpointObserver } from '@portal/wen-components';

@Component({
  selector: 'wen-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {

  @HostBinding('attr.tabIndex') tabIndex = -1;
  @HostBinding('class.wen-header') className = true;

  isDesktopStyleDevice = this.breakpointObserver.isDesktopStyleDevice;

  constructor(
    private breakpointObserver: WenBreakpointObserver,
  ) { }

}
