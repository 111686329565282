import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { RootState } from '../../../../core/store/root/public-api';

@Injectable()
export class RegistrationUtils {

  constructor(
    private store: Store<RootState>
  ) { }

  withCurrentUser() {
    return this.store.pipe(
      select(selectCurrentUserData),
      map((data) => data.userId)
    );
  }

  // Allow 9638 secret code somehow
  workaroundSecretcode(code: string) {
    return code?.trim();
  }
}
