import { inject, Injectable } from '@angular/core';
import { combineLatest, filter, map, pairwise, startWith, switchMap, timer } from 'rxjs';
import { mapAllBool } from '../../common/operators/map-all-bool';
import { CONNECTION_MONITORS } from './tokens';
import { ConnectionMonitor } from './types';

@Injectable()
export class ConnectionMonitorService {

  private readonly connectionMonitors = inject<ConnectionMonitor[]>(CONNECTION_MONITORS);

  attach() {
    const isOnlineStates = combineLatest(this.connectionMonitors.map(monitor => monitor.isOnline$));
    const isOnline$ = isOnlineStates.pipe(
      mapAllBool(),
    );
    const isClientOffline$ = isOnlineStates.pipe(
      map(connectionStates => !connectionStates[0]),
    );
    const isUnstable$ = isClientOffline$.pipe(
      pairwise(),
      filter(([wasOffline, isOfflineNow]) => wasOffline && !isOfflineNow),
      switchMap(() => timer(5000).pipe(
        map(() => false),
        startWith(true)
      )),
    );

    const showClientError$ = isUnstable$.pipe(
      startWith(false),
      switchMap((isUnstable) => {
        return isClientOffline$.pipe(
          map((isClientOffline) => isClientOffline || isUnstable)
        );
      }),
    );
    return {
      isOnline$,
      showClientError$,
    };
  }

}
