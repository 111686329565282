import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { tap } from 'rxjs';
import { mapWithFirstFrom } from '../../../../common/operators/map-with-first-from';
import { selectCurrentUserData } from '../../../auth/auth.selectors';
import { RootState } from '../../../root/public-api';
import { updateGeoFilterForChannel } from '../../channel.actions';

@Injectable()
export class GeoChannelffects {

  onUpdateGeoFilterForCurrentChannel$ = createEffect(() => this.actions$.pipe(
    ofType(updateGeoFilterForChannel),
    mapWithFirstFrom(() => this.store.pipe(select(selectCurrentUserData))),
    tap(([payload, userData]) => {
      const { geoJson = null, distance, channelId } = payload;
      this.socketIoService.channel.updateGeoFilterForChannel.emit({
        userId: userData.userId,
        channelId,
        geoJson,
        distance
      });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
  ) { }
}
