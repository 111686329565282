import { Inject, Injectable, Optional } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MemberState } from '@portal/wen-backend-api';
import { ActionMenuItem, POPPER_DATA } from '@portal/wen-components';
import { map } from 'rxjs';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { promoteRoomMemberToAdmin } from '../../../../core/store/chat/actions/chat-member.actions';
import { ChatUserDTO } from '../../../../core/store/chat/chat.state';
import { RootState } from '../../../../core/store/root/public-api';

@Injectable()
export class PromoteToGroupAdminAction implements ActionMenuItem<string, ChatUserDTO, void> {

  label: string;
  icon = 'user_shield_on';
  isVisible$ = (context: ChatUserDTO) => this.store.pipe(select(selectCurrentUserData)).pipe(
    map((currentUserData) => currentUserData.userId !== context.userId && context.state === MemberState.JOINED),
  );

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>,
    @Optional() @Inject(POPPER_DATA) private popperData
  ) {
    this.label = this.translateService.instant('CHAT_GROUP_MEMBER_ADMIN_PROMOTION');
  }

  execute(userId: string) {
    this.store.dispatch(promoteRoomMemberToAdmin({ userId }));
  }

}
