import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FeaturedItemsResponse } from '@portal/wen-backend-api';
import { filter, map, Observable, switchMap } from 'rxjs';
import { setFeaturedData, subscribeToFeaturedItemUpdates } from '../channel.actions';

export const createChannelFeaturedDataEffect = (
  actions$: Actions,
  featuredItems$: Observable<FeaturedItemsResponse[]>,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToFeaturedItemUpdates),
    switchMap(() => featuredItems$),
    filter((items) => items?.length > 0),
    map((items) => {
      const firstItem = items[0];
      return setFeaturedData({
        featuredData: firstItem
      });
    })
  ));
};
