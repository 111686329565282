@if (channel) {
  <wen-list-item [showAfter]="showAfterElement" #wenListItem [selected]="isSelected$ | async"
    wen-test-id="wen-channel-list-item" (press)="enableContextMenu && openContextMenu(channel)">
    <wen-circle-image-view wen-list-item-icon [imageUrl]="channel.imageUrl" [placeholderText]="channel.title">
      @if (channel.channelIndicator) {
        <wen-channel-indicator [indicatorType]="channel.channelIndicator"
        wen-circle-image-overlay positionOffset="39px" rightOffset="18px"></wen-channel-indicator>
      }
    </wen-circle-image-view>
    @if (channel.lockIcon; as lockIcon) {
      <wen-icon class="wen-icon-small" wen-list-item-title-prefixicon
      [iconName]="lockIcon" [color]="'#767676'"></wen-icon>
    }
    <span wen-list-item-title [wen-test-id]="'wen-channel-list-item-title'">{{ channel.title }}</span>
    @if (channel.muteIcon; as muteIcon) {
      <wen-icon wen-list-item-title-suffixicon
      class="wen-channel-list-item-title-icon wen-icon-small" [iconName]="muteIcon" [color]="'#767676'"></wen-icon>
    }
    @if (channel.pinTimestamp) {
      <wen-icon wen-list-item-title-suffixicon
        class="wen-channel-list-item-title-icon wen-icon-small" iconName="pin" color="#767676"
      [wen-test-id]="'wen-channel-list-item-pin-icon'"></wen-icon>
    }
    @if (channel?.description; as description) {
      <div wen-list-item-description
        [wen-test-id]="'wen-channel-list-item-description'">
        @if (description?.icon) {
          <wen-icon [iconName]="description?.icon" class="wen-icon-small"></wen-icon>
        }
        @if (description?.text) {
          <span class="wen-channel-list-item-description"
          [innerHtml]="description.text | translate | removeMarkdown : markdownEnabled"></span>
        }
      </div>
    }
    @if (channel | wenHasTrueProperty: 'subscribed') {
      <span wen-list-item-subtitle
        [wen-test-id]="'subscribed-channel-subtitle'">
        <wen-icon class="wen-icon-tiny" iconName="save_active"></wen-icon>
        {{ 'CHANNEL_LIST_SUBSCRIBED' | translate }}
      </span>
    }
    @if (channel | wenHasTrueProperty: 'pendingJoinRequest') {
      <span wen-list-item-subtitle>
        <wen-icon class="wen-icon-tiny" [iconName]="'help'"></wen-icon>
        {{ 'JOIN_REQUEST_SENT_DISCOVERY_STATUS_LABEL' | translate }}
      </span>
    }
    <ng-container wen-list-item-after>
      @if (channel.timestamp) {
        <div portal-subheading-1 class="wen-channel-list-item-timestamp">{{ channel.timestamp | messageDateFormatter }}
        </div>
      }
      @if (channel.notifications; as notification) {
        @if (notification.count > 0) {
          <wen-notification-count class="wen-notification-count-big"
            [value]="notification.count" [disabled]="notification.isMuted"
          [wen-test-id]="'channel-list-notification-count'"></wen-notification-count>
        } @else {
          @if (notification.badgeStyle) {
            <wen-notification-count class="wen-notification-count-big"
            [value]="'INVITE_CHANNEL_NOTIFICATION_COUNT_LABEL' | translate"></wen-notification-count>
          }
        }
      }
    </ng-container>
  </wen-list-item>
}
