import type { SmartDesign } from '@smartdesign/web-api';
import { SelectionProvider } from '../selection-provider';
import { OpenDataObject, openDataObjectFactory, OPEN_RELEVANT_FIELDS } from './open-data-object';

export class OpenSelectionProvider extends SelectionProvider<OpenDataObject> {

  protected relevantFields = OPEN_RELEVANT_FIELDS;

  constructor(protected api: SmartDesign.IAPI<any>) {
    super(openDataObjectFactory);
  }

}
