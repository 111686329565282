import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { UserSearchEmptyListDirective } from './user-search-empty-list.directive';
import { UserSearchListComponent } from './user-search-list.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    UserSearchListComponent,
    UserSearchEmptyListDirective
  ],
  exports: [
    UserSearchListComponent,
    UserSearchEmptyListDirective
  ],
  providers: [],
})
export class UserSearchListModule { }
