import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionOption } from '@portal/wen-components';
import { Contactability } from '@portal/wen-backend-api';

@Component({
  selector: 'wen-user-visibility-selection-list',
  templateUrl: './user-visibility-selection-list.component.html',
  styleUrls: ['./user-visibility-selection-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserVisibilitySelectionListComponent {

  @Input() selectionModel: SelectionModel<SelectionOption<Contactability>>;
  @Input() selectionItems: SelectionOption<Contactability>[];

  @Output() activeSelection = new EventEmitter<string>();

  onSelectionChanged(data: SelectionOption['id'][]) {
    this.activeSelection.emit(data[0]);
  }
}
