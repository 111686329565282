import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { EmbedDescriptionHandler, WenEmbedDescriptionHandler } from './providers/embed-description';
import { EmbedDTOTypes } from '@portal/wen-backend-api';

export interface EmbedDescription {
  translationKey: string;
  icon?: string;
}

@Component({
  selector: 'wen-embed-description',
  templateUrl: './embed-description.component.html',
  styleUrls: ['./embed-description.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [WenIconModule, TranslateModule]
})
export class EmbedDescriptionComponent {

  private embedDescriptionHandler: EmbedDescriptionHandler = new WenEmbedDescriptionHandler();
  embedDescription: EmbedDescription;

  @HostBinding('class.wen-embed-description') className = true;

  @Input() set embed(value: EmbedDTOTypes) {
    this.embedDescription = this.embedDescriptionHandler.getEmbedDescription(value);
  }

}
