import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RestrictionContextType, SocketIoService, updateToRestrictionDTO } from '@portal/wen-backend-api';
import { merge } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { subscribeToChannelRestrictionUpdates, updateChannelRestrictions } from './channel.actions';

@Injectable()
export class ChannelRestrictionEffects {

  private readonly channelRestrictionUpdates$ = merge(
    this.socketIoService.restrictions.evaluate.listen.pipe(
      filter((restriction) => restriction.contextType === RestrictionContextType.Channel),
    ),
    this.socketIoService.restrictions.details.listen.pipe(
      filter((restrictionDetails) => restrictionDetails.contextType === RestrictionContextType.Channel),
      map(({ contextId, restrictions }) => {
        return {
          contextId,
          restrictionTypes: restrictions.map(restrictionUpdate => updateToRestrictionDTO(restrictionUpdate))
        };
      })
    )
  );

  subscribeToRestrictionUpdates$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToChannelRestrictionUpdates),
    first(),
    switchMap(() => {
      return this.channelRestrictionUpdates$.pipe(
        map(({ contextId, restrictionTypes }) => {
          return updateChannelRestrictions(
            {
              channelId: contextId,
              restrictions: restrictionTypes
            });
        })
      );
    })
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly socketIoService: SocketIoService,
  ) {
  }

}
