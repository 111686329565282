<div class="wen-section-header">
  <ng-content select="section-header-title, [section-header-title]"></ng-content>
  <ng-content select="section-header-action, [section-header-action]"></ng-content>
</div>
<div class="wen-section-content" #sectionContent [wen-test-id]="'explore-section'">
  @if (isScrollersVisible$ | async) {
    @if (scrollVisibility$ | async; as visibility) {
      @if (visibility.left) {
        <wen-scroll-to-button class="left" (scrolledTo)="scrollLeft()"></wen-scroll-to-button>
      }
      @if (visibility.right) {
        <wen-scroll-to-button class="right" (scrolledTo)="scrollRight()"></wen-scroll-to-button>
      }
    }
  }
  <div class="wen-section-content-wrapper" #contentWrapper>
    <ng-content select="section-content, [section-content]"></ng-content>
  </div>
</div>