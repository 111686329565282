import { ActionMenuConfig } from '@portal/wen-components';
import { RemoveUserFromChannelAction } from '../../../common/action-menu/remove-user-from-channel-action';
import { DeleteContentProviderStatusAction } from './delete-content-provider-status.action';
import { PromoteToAdminAction } from './promote-to-admin.action';

export const channelContentProviderMenuId = 'channel-content-provider-menu-id';

export const CHANNEL_CONTENT_PROVIDER_ACTION_MENU: ActionMenuConfig = {
  menuId: channelContentProviderMenuId,
  menuItems: [DeleteContentProviderStatusAction, PromoteToAdminAction, RemoveUserFromChannelAction]
};
