import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { catchError, map, Observable, of, forkJoin } from 'rxjs';
import { WenTranslationKeys } from '../models/types';
import deTranslations from '../../assets/i18n/de/wenetwork.json';
import enTranslations from '../../assets/i18n/en/wenetwork.json';
import { deepMerge } from '../util/deepmerge';
import { FAQ, FaqType, WenLanguage } from '@portal/wen-backend-api';
import { TRANSLATION_CONFIG } from '../tokens/translation.token';

type SupportedTranslatableFiles = {
  fileName: string;
  translationId: null | FaqType;
};

/** Only the matching file names are considered to be requested from the assets directory */
const SUPPORTED_TRANSLATABLE_FILES: SupportedTranslatableFiles[] = [
  { fileName: 'wenetwork', translationId: null },
  { fileName: 'links', translationId: null},
  { fileName: 'faq', translationId: 'faq' },
  { fileName: 'markdown', translationId: 'markdown'},
];

type AppTranslationResult = Record<WenTranslationKeys, string | string[]> & { [K in FaqType]: FAQ };

let NETWORK_TRANSLATIONS;

@Injectable()
export class WenTranslateLoader implements TranslateLoader {
  /**
   * @deprecated
   * THIS IS A TEMPORARY SOLUTION!
   * Please do not build on it. Itstead find a proper solution to set network translations!
   */
  setNetworkTranslations(networkTranslations) {
    NETWORK_TRANSLATIONS = networkTranslations;
  }

  private getNetworkTranslations(language: WenLanguage) {
    return NETWORK_TRANSLATIONS?.[language] || {};
  }

  private http = inject(HttpClient);
  private translationConfig = inject(TRANSLATION_CONFIG);

  private readonly libTranslationMap = new Map<WenLanguage, any>([
    [WenLanguage.GERMAN, deTranslations],
    [WenLanguage.ENGLISH, enTranslations]
  ]);

  getTranslation(
    language: WenLanguage
  ): Observable<AppTranslationResult> {
    const supportedLanguage = this.libTranslationMap.get(language);
    if (!supportedLanguage) {
      return of(null);
    }
    const libTranslations = this.getLibTranslations(language);
    const appTranslations$ = this.getAppTranslations(language);
    const networkTranslations = this.getNetworkTranslations(language);
    return appTranslations$.pipe(
      map((appTranslations) => deepMerge(deepMerge(libTranslations, appTranslations), networkTranslations))
    );
  }

  private getAppTranslations(
    language: WenLanguage,
    prefix: string = '/assets/i18n'
  ): Observable<AppTranslationResult> {
    const requests$ = SUPPORTED_TRANSLATABLE_FILES.map(({ fileName, translationId }) => {
      const projectPrefix = this.translationConfig?.projectPrefix ? this.translationConfig.projectPrefix + '_' : '';
      const projectAwareFileName = `${projectPrefix}${fileName}.json`;
      const resourceName = `${prefix}/${language}/${projectAwareFileName}`;
      return this.http.get<Record<WenTranslationKeys, string | string[]> | FAQ>(resourceName).pipe(
        map(response => ({ [translationId]: response })),
        catchError(() => {
          return of(null);
        })
      );
    });
    return forkJoin(requests$).pipe(
      map((responses) => {
        return responses.filter(response => !!response).reduce((allTranslations, currentTranslation) => {
          const transformedTranslation = this.transform(currentTranslation);
          allTranslations = { ...allTranslations, ...transformedTranslation };
          return allTranslations;
        }, {} as AppTranslationResult);
      })
    );
  }

  private getLibTranslations(language: WenLanguage) {
    const baseTranslations = this.libTranslationMap.get(language);
    return baseTranslations || {};
  }

  private transform(translation: { [x: string]: Record<Uppercase<string>, string | string[] > | FAQ }) {
    const entries = Object.entries(translation);
    const [key, value] = entries[0];
    return key === 'null' ? { ...value } : { [key]: { ...value } };
  }
}
