
import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChannelMessageDTO, MessageModificationState } from '@portal/wen-backend-api';
import { channelViewIdentifier } from '../../../views/channel/tokens';
import { LoadingState } from '../../common/types/store-loading-state';
import { selectActiveNetwork } from '../network/network.selectors';
import { RootState } from '../root/public-api';
import { selectRouteParam } from '../root/root.selectors';
import { ChannelMessageEntity, ChannelState, channelAdapter, channelMessagesAdapter, channelMetaAdapter, channelNetworkEntityHelper } from './channel.state';
import { channelFeatureKey } from './constants';

export interface WithFeatureState extends RootState {
  [channelFeatureKey]: ChannelState;
}

export const selectChannelState = createFeatureSelector<ChannelState>(
  channelFeatureKey
);

export const selectChannelEntityState = createSelector(
  selectChannelState,
  (state) => state.channels
);

export const selectChannelMetas = createSelector(
  selectChannelState,
  (state) => {
    const channelMetas = channelMetaAdapter.getSelectors().selectAll(state.channelMeta);
    return channelMetas;
  }
);

export const selectSubscribedChannelIds = createSelector(
  selectChannelState,
  selectChannelMetas,
  selectActiveNetwork,
  (state, channelMetas, activeNetwork) => {
    const { userChannelIds = [] } = state;
    const filteredForNetwork = channelNetworkEntityHelper.filterActiveInNetwork(channelMetas, userChannelIds, activeNetwork);
    return filteredForNetwork;
  }
);

export const selectJoinRequestChannelIds = createSelector(
  selectChannelState,
  (state) => state.userJoinRequestChannelIds || []
);

export const selectDiscoverChannelIds = createSelector(
  selectChannelState,
  (state) => state.discoverChannelIds || []
);

export const selectUserChannelIds = createSelector(
  selectChannelState,
  selectActiveNetwork,
  selectChannelMetas,
  (state, activeNetwork, channelMetas) => {
    const {
      userChannelIds = [],
      userJoinRequestChannelIds = [],
      inviteToChannelRequestChannelIds = [],
    } = state;
    const filteredForNetwork = channelNetworkEntityHelper.filterActiveInNetwork(channelMetas, userChannelIds, activeNetwork);
    return [...filteredForNetwork, ...userJoinRequestChannelIds, ...inviteToChannelRequestChannelIds];
  }
);

export const {
  selectAll: selectAllChannels,
  selectEntities: selectChannelEntities
} = channelAdapter.getSelectors(selectChannelEntityState);

const {
  selectAll: selectAllChannelMessages,
  selectIds: selectAllChannelMessageIds
} = channelMessagesAdapter.getSelectors();

const filterValidChannelMessages = (
  messages: ChannelMessageDTO[]
) => {
  if (!messages) {
    return [];
  }
  return messages.filter(message => message.type !== MessageModificationState.DELETED);
};

const filterValidChannelMessagesState = (
  state: EntityState<ChannelMessageDTO>
) => {
  if (!state) {
    return [];
  }
  const allMessages = selectAllChannelMessages(state);
  if (!allMessages) {
    return allMessages;
  }
  return filterValidChannelMessages(allMessages);
};

export const selectChannelMessageIds = selectAllChannelMessageIds;

export const selectChannels = createSelector(
  selectAllChannels,
  channels => channels
);

export const selectChannelById = createSelector(
  selectChannels,
  (channelEntities) => (channelId: string) => {
    return channelEntities.find((channelEntity) => {
      const id = channelAdapter.selectId(channelEntity);
      return id === channelId;
    });
  }
);

export const selectCurrentChannel = createSelector(
  selectChannels,
  selectRouteParam(channelViewIdentifier),
  (channelEntities, channelId) => {
    return channelEntities.find((channelEntity) => {
      const id = channelAdapter.selectId(channelEntity);
      return id === channelId;
    });
  }
);

export const selectChannelPins = createSelector(
  selectChannelMetas,
  selectActiveNetwork,
  (channelMetas, activeNetwork) => {
    type PinData = { channelId: string; pinTimestamp: string };
    type PinDataMap = Record<string, PinData>;
    const pinnedChannelDatas = channelMetas.reduce((acc, channelMeta) => {
      if (!Array.isArray(channelMeta.networkMetas)) {
        return acc;
      }
      const pinNetworkData = channelMeta.networkMetas.find((subscribedNetwork) => {
        const sameNetwork = subscribedNetwork.id === activeNetwork.id;
        const hasPin = subscribedNetwork.pinTimestamp;
        return sameNetwork && hasPin;
      });
      if (!pinNetworkData?.pinTimestamp) {
        return acc;
      }
      const pinData: PinData = {
        channelId: channelMeta.entityId,
        pinTimestamp: pinNetworkData.pinTimestamp
      };

      return {
        ...acc,
        [channelMeta.entityId]: pinData
      };
    }, {} as PinDataMap);
    return pinnedChannelDatas;
  }
);

export const selectPinForChannel = createSelector(
  selectChannelPins,
  (channelPins) => (channelId: string) => {
    return channelPins[channelId]?.pinTimestamp;
  }
);

export const selectCurrentChannelTitle = createSelector(
  selectCurrentChannel,
  (channel) => {
    return channel?.title;
  }
);

export const selectCurrentAutoAcknowledgeChannelId = createSelector(
  selectChannelState,
  (state) => {
    return state.currentAutoAcknowledgeChannelId;
  }
);

export const selectUserChannels = createSelector(
  selectChannels,
  selectUserChannelIds,
  (channelEntities, channelIds) => {
    const userChannelEntities = channelEntities.filter((channelEntity) => {
      const id = channelAdapter.selectId(channelEntity)?.toString();
      return channelIds.includes(id);
    });
    return userChannelEntities;
  }
);

export const selectDiscoverChannels = createSelector(
  selectChannelEntities,
  selectDiscoverChannelIds,
  (channelEntities, channelIds: string[]) => {
    return [...channelIds].map(id => channelEntities[id]).filter(channel => Boolean(channel));
  }
);

export const selectCurrentChannelPendingMessages = createSelector(
  selectCurrentChannel,
  (currentChannel) => (messageFilterFn: (message: ChannelMessageDTO) => boolean) => {
    if (currentChannel?.pendingMessages) {
      return currentChannel.pendingMessages.filter(messageFilterFn);
    }
    return [];
  }
);

export const selectMessageDetailById = createSelector(
  selectChannels,
  (channels) => (messageId: string, channelId?: string) => {
    let targetMessage: ChannelMessageEntity;
    let targetChannel = channels;
    if (channelId) {
      targetChannel = channels.filter(({ id }) => id === channelId);
    }
    Object.values(targetChannel).some((channel) => {
      const { extraMessages, history, messages, scheduledMessages } = channel;
      const source1 = extraMessages?.entities[messageId];
      const source2 = history?.entities[messageId];
      const source3 = messages?.entities[messageId];
      const source4 = scheduledMessages?.entities[messageId];
      targetMessage = source1 || source2 || source3 || source4;
      return targetMessage;
    });
    return targetMessage;
  }
);

export const selectCurrentChannelMessagesHistoryFlags = createSelector(
  selectCurrentChannel,
  (currentChannel) => {
    const history = currentChannel?.history;
    if (history) {
      const { hasMoreOlder, hasMoreNewer } = history;
      return { hasMoreOlder, hasMoreNewer };
    }
    return null;
  }
);

export const selectChannelMessagesHistory = createSelector(
  selectChannels,
  selectCurrentAutoAcknowledgeChannelId,
  (channelEntities, currentAutoAcknowledgeChannelId) => (channelId: string) => {
    const channel = channelEntities.find(ch => ch.id === channelId);
    const history = channel?.history;
    const messages = channel?.messages;
    if (history) {
      const entities = filterValidChannelMessagesState(history);
      const latestHistoryMsg = entities[entities.length - 1];
      const bottomOfHistory = history.hasMoreNewer === false;
      const isAckEnabled = currentAutoAcknowledgeChannelId && channel.id === currentAutoAcknowledgeChannelId;
      if (messages && (bottomOfHistory || isAckEnabled)) {
        const historyIds = history.ids as string[];
        const filteredMessages = Object.values(messages.entities).filter(message => {
          const isNotDuplicate = !historyIds.includes(message.id);
          const isAfterKnownHistory = latestHistoryMsg ? new Date(latestHistoryMsg.timestamp) < new Date(message.timestamp) : true;
          return isNotDuplicate && isAfterKnownHistory;
        });
        const validMessages = filterValidChannelMessages(filteredMessages);
        entities.push(...validMessages);
      }
      return entities;
    }
    return [];
  }
);

export const selectChannelMessages = selectChannelMessagesHistory;

export const selectCurrentChannelMessagesHistory = createSelector(
  selectCurrentChannel,
  selectChannelMessagesHistory,
  (currentChannel, historySelector) => {
    if (!currentChannel) {
      return [];
    }
    return historySelector(currentChannel.id);
  }
);

export const isChannelMessagesHistoryExists = createSelector(
  selectChannels,
  (channelEntities) => (channelId: string) => {
    const channel = channelEntities.find(ch => ch.id === channelId);
    return Boolean(channel?.history);
  }
);

export const selectCurrentChannelScheduledMessages = createSelector(
  selectChannels,
  selectCurrentChannel,
  (channels, currentChannel) => {
    const matchingScheduledMessages = channels.find(c => c.id === currentChannel?.id)?.scheduledMessages;
    if (matchingScheduledMessages) {

      const scheduledMessages = Object.values(matchingScheduledMessages.entities);
      return filterValidChannelMessages(scheduledMessages);
    }
    return [];
  }
);

export const selectCurrentChannelHasMore = createSelector(
  selectCurrentChannel,
  (channel) => {
    return channel.scheduledMessagesHasMore;
  }
);

export const selectCurrentChannelScheduledMessageById = createSelector(
  selectCurrentChannelScheduledMessages,
  (scheduledMessages) => (messageId: string) => {
    if (!scheduledMessages?.length) {
      return null;
    }
    return scheduledMessages.find(message => message.id === messageId);
  }
);

export const selectUserChannelIsLoaded = createSelector(
  selectChannelState,
  (state) => state.userChannelsLoading !== LoadingState.NOT_LOADED
);

export const selectDiscoverChannelIsLoaded = createSelector(
  selectChannelState,
  (state) => state.discoverChannelsLoading !== LoadingState.NOT_LOADED
);

export const selectIsChannelSubscribed = createSelector(
  selectSubscribedChannelIds,
  (subscribedChannelIds) => (channelId: string) => channelId ? subscribedChannelIds.includes(channelId) : undefined
);

export const selectIsJoinRequestChannel = createSelector(
  selectJoinRequestChannelIds,
  (channelIds) => (channelId: string) => channelId ? channelIds.includes(channelId) : undefined
);

export const selectChannelPermission = createSelector(
  selectChannels,
  (channels) => (channelId: string) => {
    const channelEntity = channels.find((channel) => {
      const id = channelAdapter.selectId(channel);
      return id === channelId;
    });

    return channelEntity?.permission;
  }
);

export const selectCurrentChannelPermission = createSelector(
  selectCurrentChannel,
  (currentChannel) => {
    return currentChannel?.permission;
  }
);

export const selectChannelRestrictions = createSelector(
  selectChannels,
  (channels) => (channelId: string) => {
    const channelEntity = channels.find((channel) => {
      const id = channelAdapter.selectId(channel);
      return id === channelId;
    });

    return channelEntity?.restrictions;
  }
);

export const selectIsJoinRequestManagerForCurrentChannel = createSelector(
  selectCurrentChannel,
  (currentChannel) => {
    return currentChannel?.permission?.canHandleJoinRequests;
  }
);

export const selectIsJoinRequestManager = createSelector(
  selectChannels,
  (channels) => (channelId: string) => {
    const channelEntity = channels.find((channel) => {
      const id = channelAdapter.selectId(channel);
      return id === channelId;
    });

    return channelEntity?.permission?.canHandleJoinRequests;
  }
);

export const selectFeaturedData = createSelector(
  selectChannelState,
  (state) => {
    return state.featuredData;
  }
);

export const selectDraftChannelMessageById = createSelector(
  selectChannels,
  (channels) => (channelId: string) => {
    const channelEntity = channels.find((channel) => {
      const id = channelAdapter.selectId(channel);
      return id === channelId;
    });
    return channelEntity?.draftMessage;
  }
);

export const selectCurrentChannelDraftMessage = createSelector(
  selectCurrentChannel,
  (currentChannel) => {
    return currentChannel?.draftMessage;
  }
);

export const selectCurrentChannelContentProviders = createSelector(
  selectCurrentChannel,
  (channel) => channel?.contentProviders ?? []
);

export const selectCurrentChannelAdmins = createSelector(
  selectCurrentChannel,
  channel => channel?.admins ?? []
);

export const selectIsCurrentChannelGeoChannel = createSelector(
  selectCurrentChannel,
  channel => Boolean(channel?.geo)
);

export const selectLastReadTimestamp = createSelector(
  selectAllChannels,
  (channelEntities) => (channelId: string) => {
    return channelEntities.find((channelEntity) => {
      const id = channelAdapter.selectId(channelEntity);
      return id === channelId;
    })?.lastReadTimestamp;
  }
);

export const selectCurrentChannelConfiguration = createSelector(
  selectCurrentChannel,
  currentChannel => currentChannel?.configuration
);
