import { Directive, Host, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { IfSubscribedToContext } from './if-subscribed-to-context';
import { SubscribedToSource } from './subscribed-to-source';

@Directive({
  selector: '[ifNotSubscribedTo]'
})
export class IfNotSubscribedToDirective implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private contextUpdated = new ReplaySubject<void>(1);
  private context: Partial<IfSubscribedToContext>;

  @Input('ifNotSubscribedTo')
  set modelId(newValue: string) {
    this.context = { ...this.context, modelId: newValue };
    this.contextUpdated.next();
  }

  @Input('ifNotSubscribedToExtraCondition')
  set extraCondition(newValue: boolean) {
    this.context = { ...this.context, extraCondition: newValue };
    this.contextUpdated.next();
  }

  @Input('ifNotSubscribedToElse')
  set else(newValue: TemplateRef<any>) {
    this.context = { ...this.context, elseTemplateRef: newValue };
    this.contextUpdated.next();
  }

  constructor(
    @Host() private subscriptionSource: SubscribedToSource,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) { }

  ngOnInit() {
    this.contextUpdated.pipe(
      switchMap(() => this.subscriptionSource.subscribedIds$.pipe(
        map(subscribedModelIds => {
          const { modelId, extraCondition = true } = this.context;
          return !subscribedModelIds.includes(modelId) && extraCondition;
        })
      )),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(isVisible => {
      this.updateView(isVisible);
    });
  }

  private updateView(isVisible: boolean) {
    if (isVisible) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.context.elseTemplateRef) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.context.elseTemplateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
