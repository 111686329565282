import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { registrationDataActivatorFn, registrationDataResolverFn } from '../common/guards/registration.guard';
import { RegistrationCodeVerificationComponent } from '../registration-code-verification/components/registration-code-verification/registration-code-verification.component';
import { RegistrationProfileComponent } from '../registration-profile/components/registration-profile/registration-profile.component';
import { RegistrationStartComponent } from '../registration-start/components/registration-start/registration-start.component';

export const registrationStartRoute: WenRoute = {
  path: 'start',
  component: RegistrationStartComponent,
  data: {
    routeId: WenRouteId.REGISTRATION_START
  }
};

export const registrationCodeVerificationRoute: WenRoute = {
  path: 'verify-code',
  component: RegistrationCodeVerificationComponent,
  canActivate: [registrationDataActivatorFn],
  resolve: {
    registrationData: registrationDataResolverFn,
  },
  data: {
    routeId: WenRouteId.REGISTRATION_CODE_VERIFICATION
  }
};

export const registrationProfileRoute: WenRoute = {
  path: 'profile',
  component: RegistrationProfileComponent,
  canActivate: [registrationDataActivatorFn],
  resolve: {
    registrationData: registrationDataResolverFn,
  },
  data: {
    routeId: WenRouteId.REGISTRATION_PROFILE
  }
};
