import { NgModule } from '@angular/core';
import { RoundedButtonComponent } from './rounded-button.component';

@NgModule({
  declarations: [
    RoundedButtonComponent
  ],
  exports: [
    RoundedButtonComponent
  ]
})
export class RoundedButtonModule { }
