import { Directive, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { delay, filter, merge, Subject, takeUntil } from 'rxjs';
import { BrowserInfo } from '../../../core/services/util/browser-info';
import { isNotOnTheEdge } from './ios-swipe-helper';

export interface SwipeDetectorEvent {
  type: 'start' | 'end';
}

@Directive({
  selector: 'wenIosSwipeDetector, [wenIosSwipeDetector]'
})
export class IosSwiperDetectorDirective implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  private onTouch = new Subject<SwipeDetectorEvent>();

  @Output() swipeEvent = new EventEmitter<SwipeDetectorEvent>();

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: Touch) {
    if (isNotOnTheEdge(event)) {
      return;
    }
    this.onTouch.next({ type: 'start' });
  }

  @HostListener('touchend')
  onTouchEnd() {
    this.onTouch.next({ type: 'end' });
  }

  constructor(
    private browserInfo: BrowserInfo
  ) { }

  ngOnInit() {
    if (!this.browserInfo.browserIsIos()) {
      return;
    }
    const touchStart$ = this.onTouch.pipe(
      filter(event => event.type === 'start')
    );
    const touchEnd$ = this.onTouch.pipe(
      filter(event => event.type === 'end'),
      delay(300)
    );
    merge(touchStart$, touchEnd$).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(this.swipeEvent);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
