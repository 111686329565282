import { createAction, props } from '@ngrx/store';

export const scheduleSaveAppState = createAction(
  '[StorePersistence] Schedule a state save into the storage'
);

export const rehydrateAppState = createAction(
  '[StorePersistence] Restore the app state from the persistent storage',
  props<{ restoredState: any }>()
);

export const rehydrateSkipped = createAction(
  '[StorePersistence] It was not possible to restore the state'
);
