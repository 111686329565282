import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule, WenSectionModule, WenTestIdModule } from '@portal/wen-components';
import { FilterElementModule } from '../../directives/filter-element/filter-element.module';
import { FilterSelectorModule } from './components/filter-selector/filter-selector.module';
import { FilterSelectComponent } from './components/filter-select/filter-select.component';
import { FilterViewComponent } from './filter-view.component';
import { WenPipesModule } from '../../pipes/wen-pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { GeoFilterSelectModule } from './components/geo-filter-select/geo-filter-select.module';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule,
    WenPipesModule,
    TranslateModule,
    FilterSelectorModule,
    FilterElementModule,
    ReactiveFormsModule,
    GeoFilterSelectModule,
    WenSectionModule,
    WenTestIdModule
  ],
  exports: [
    FilterViewComponent,
    FilterSelectComponent,
    FilterElementModule,
    ReactiveFormsModule,
    GeoFilterSelectModule,
  ],
  declarations: [
    FilterViewComponent,
    FilterSelectComponent
  ],
  providers: [],
})
export class FilterViewModule { }
