import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { PagingReplayDirection } from '@portal/wen-backend-api';
import { distinctUntilKeyChanged, filter, map, tap } from 'rxjs';
import { fetchScheduledChatMessages, removeAllScheduledChatMessages } from '../../../../core/store/chat/actions/chat-scheduled-messages.actions';
import { selectCurrentRoom } from '../../../../core/store/chat/chat.selectors';
import { RootState } from '../../../../core/store/root/public-api';

export const canActivateScheduledChatMessagesView: CanActivateFn = () => {
  const store = inject(Store<RootState>);
  const preLoadFirstPage$ = store.pipe(
    select(selectCurrentRoom),
    filter((currentRoom) => Boolean(currentRoom?.id)),
    distinctUntilKeyChanged('id'),
    tap((currentRoom) => {
      store.dispatch(removeAllScheduledChatMessages({ roomId: currentRoom.id }));
      store.dispatch(fetchScheduledChatMessages({
        payload: {
          roomId: currentRoom.id,
          direction: PagingReplayDirection.Down
        }
      }));
    })
  );
  return preLoadFirstPage$.pipe(
    map(() => true)
  );
};
