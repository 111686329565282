import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { MediaType } from '@portal/wen-backend-api';
import { AnimationOptions } from 'ngx-lottie';
import { ReplaySubject } from 'rxjs';

const BASE_LOTTIE_PATH = '/assets/wen-widget/lottie/';

@Component({
  selector: 'wen-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingIndicatorComponent implements OnInit {
  private innerSize = 'normal';
  private innerColor = 'normal';
  private innerVariant = 'default';
  @Input()
  set size(newSize: 'normal' | 'icon-medium' | 'icon-small') {
    this.innerSize = newSize;
    this.classes$.next(this.getClasses());
  }
  @Input()
  set color(newColor: 'normal' | 'light') {
    this.innerColor = newColor;
    this.classes$.next(this.getClasses());
  }
  @Input()
  set variant(variant: MediaType | 'default') {
    this.innerVariant = variant;
    this.mapMediaTypeToLottie();
  }

  lottieOptions$ = new ReplaySubject<AnimationOptions>(1);

  classes$ = new ReplaySubject<string>();

  constructor() {
    this.classes$.next(this.getClasses());
  }

  ngOnInit() {
    this.mapMediaTypeToLottie();
  }

  private mapMediaTypeToLottie() {
    let fileNamePart: string;
    switch (this.innerVariant) {
      case MediaType.AUDIO:
        fileNamePart = 'Audio';
        break;
      case MediaType.VIDEO:
        fileNamePart = 'Video';
        break;
      case MediaType.PICTURE:
        fileNamePart = 'Photo';
        break;
      case MediaType.DOCUMENT:
        fileNamePart = 'Document';
        break;
      default:
        fileNamePart = 'Generic';
        break;
    }
    const fullPath = `${BASE_LOTTIE_PATH}${fileNamePart}Loading.json`;
    this.lottieOptions$.next({
      path: fullPath,
      autoplay: true,
      loop: true
    });
  }

  getClasses() {
    return`color-${this.innerColor} size-${this.innerSize}`;
  }
}
