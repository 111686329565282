import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';
import { ActionMenuConfig } from '@portal/wen-components';
import { ChatCreationMenuComponent } from '../../../../views/chat/chat-creation/chat-creation-menu/chat-creation-menu.component';
import { GroupChatAction } from './group-chat.action';
import { SingleChatAction } from './single-chat.action';

export const startNewChatMenuId = 'new-chat-menu-id';

export const NEW_CHAT_ACTION_MENU: ActionMenuConfig = {
  menuId: startNewChatMenuId,
  menuItems: [SingleChatAction, GroupChatAction]
};


export const CHAT_MENU_COMPONENT = new InjectionToken<ComponentType<ChatCreationMenuComponent>>('MenuComponent');
