import { ChangeDetectionStrategy, Component, HostBinding, Input, Renderer2, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ImageFitType, PortalImageComponent, PortalImageLoadEvent } from '@portal/ui-kit/image';
import { Dimensions2D } from '../../util/size';
import { InitialExtractorFn } from '../circle-initial-view/circle-initial-view.component';

@Component({
  selector: 'wen-circle-image-view',
  templateUrl: './circle-image-view.component.html',
  styleUrls: ['./circle-image-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircleImageViewComponent implements AfterViewInit {

  constructor(private renderer: Renderer2) { }

  @HostBinding('class.wen-circle-image-view') className = true;

  private imageSrcUrl: string;

  @ViewChild(PortalImageComponent, { read: ElementRef }) portalImage: ElementRef<PortalImageComponent>;

  @Input() shape: 'circle' | 'square' = 'circle';

  @Input() imageFit: 'contain' | 'cover' = 'cover';

  @Input() imageSize: Dimensions2D = { height: 60 };

  @Input() set imageUrl(val: string) {
    this.imageSrcUrl = val;
    if (this.portalImage) {
      // This workaround is needed until support for resetting the image loading state in the PortalImageComponent is added
      (this.portalImage as unknown as any).imageLoadingState = undefined;
    }
  }
  @Input() placeholderText: string;
  @Input() initialExtractorFn: InitialExtractorFn;
  @Input() fontSize: number;
  @Input() draggable = false;
  @HostBinding('class.interactive') @Input() interactive = true;

  get srcUrl() {
    return this.imageSrcUrl || null;
  }

  get imageFitType() {
    return this.imageFit === 'contain' ? ImageFitType.CONTAIN : ImageFitType.COVER;
  }

  private isCircular() {
    return this.shape === 'circle';
  }

  ngAfterViewInit(): void {
    this.renderer.setStyle(this.portalImage.nativeElement, 'border-radius', this.isCircular() ? '50%' : '4px');
  }

  onImgLoad(event: PortalImageLoadEvent) {
    if (event.type === 'load') {
      const loadedImg = event.event.target as HTMLImageElement;
      loadedImg.draggable = this.draggable;
    }
  }

}
