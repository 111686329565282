@if (hourlyViewModel) {
  <div class="wen-weather-temperature">
    <div>
      {{ hourlyViewModel.time | date: 'HH:mm' }}
      <img [src]="hourlyViewModel.icon"/>
    </div>
    <span>{{ hourlyViewModel.temperature }}<span class="wen-forecast-unit" portal-subheading-1>°</span></span>
  </div>
  <div>{{ hourlyOtherViewProps.rainTotal }} <span class="wen-forecast-unit with-padding" portal-subheading-1>mm/h</span></div>
  <div>{{ hourlyOtherViewProps.wind }} <span class="wen-forecast-unit with-padding" portal-subheading-1>km/h</span></div>
}