import { Component, Directive, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { IOS_SWIPE_WINDOW_THRESHOLD } from './ios-swipe-helper';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Component({
  selector: 'wen-prevent-touch-event',
  template: '',
  styles: [`
    :host {
      width: 100%;
      height: 100%;
    }
  `],
})
class PreventTouchEventComponent {
  @HostListener('touchstart', ['$event']) preventTouch(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }
}

@Directive({
  selector: 'wenIosSwipeSuppressor, [wenIosSwipeSuppressor]'
})
export class IosSwipeSuppressorDirective implements OnInit, OnDestroy, OnChanges {
  constructor(
    private overlay: Overlay,
    private positionBuilder: OverlayPositionBuilder,
  ) { }

  @Input() preventSwipe = true;

  private leftOverlayRef: OverlayRef;
  private rightOverlayRef: OverlayRef;

  private initializeOverlays() {
    if (!this.leftOverlayRef?.hasAttached()) {
      this.leftOverlayRef = this.overlay.create({
        positionStrategy: this.positionBuilder.global().top('0').left('0'),
        width: IOS_SWIPE_WINDOW_THRESHOLD,
        height: '100%'
      });
      this.leftOverlayRef.attach(new ComponentPortal(PreventTouchEventComponent));
    }
    if (!this.rightOverlayRef?.hasAttached()) {
      this.rightOverlayRef = this.overlay.create({
        positionStrategy: this.positionBuilder.global().top('0').right('0'),
        width: IOS_SWIPE_WINDOW_THRESHOLD,
        height: '100%'
      });
      this.rightOverlayRef.attach(new ComponentPortal(PreventTouchEventComponent));
    }
  }

  private detachOverlays() {
    this.leftOverlayRef.detach();
    this.rightOverlayRef.detach();
  }

  ngOnInit(): void {
    if (this.preventSwipe) {
      this.initializeOverlays();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.preventSwipe) {
      if (this.preventSwipe) {
        this.initializeOverlays();
      } else {
        this.detachOverlays();
      }
    }
  }

  ngOnDestroy(): void {
    this.detachOverlays();
  }
}
