export class NavigationStateTracker {

  public pathCache: string[] = [];

  onPathChanged(newPath: string) {
    if (newPath === '' || newPath === '/home') {
      this.pathCache = [];
    } else {
      const compressIndex = this.indexOf(newPath);
      if (compressIndex > -1) {
        this.pathCache = this.pathCache.slice(0, compressIndex + 1);
      } else {
        this.pathCache = [...this.pathCache, newPath];
      }
    }
  }

  indexOf(path: string) {
    let foundIndex: number;
    this.pathCache.some((cachedPath, cachedIndex) => {
      const ismatch = path === cachedPath || `/${path}` === cachedPath;
      if (ismatch) {
        foundIndex = cachedIndex;
      }
      return ismatch;
    });
    return foundIndex;
  }

  indexOfReversed(path: string) {
    const foundIndex = this.indexOf(path);
    if (foundIndex > -1) {
      return this.pathCache.length - foundIndex - 1;
    }
    return foundIndex;
  }

  popLastPath() {
    return this.pathCache.pop();
  }

  getLastPathInHistory() {
    if (this.pathCache.length < 1) {
      return null;
    }
    return this.pathCache[this.pathCache.length - 1];
  }

  isEmpty() {
    return Boolean(this.pathCache.length < 1);
  }
}
