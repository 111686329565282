import { Injectable, inject } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { ListMassDataViewerContainerConnector } from '../../../../../../core/services/mass-data/list-mass-data-viewer-container-connector';
import { CreateChatSearchMassDataContainer } from '../../../../../../core/store/chat/mass-data/create-chat-search-mass-data-config/create-chat-search-mass-data-container';
import { ChatMemberEditSearchEntityType } from '../../../../../../core/store/chat/mass-data/providers/chat-member-edit-state-types';

export interface SeparatorPositions {
  existingIndex?: number;
  groupIndex?: number;
  furtherIndex?: number;
}

@Injectable()
export class ChatSelectionListService {

  private datasource = inject<ListMassDataViewerContainerConnector<CreateChatSearchMassDataContainer>>(
    ListMassDataViewerContainerConnector
  );

  separatorPositions$: Observable<SeparatorPositions>;

  constructor() {
    this.separatorPositions$ = this.datasource.dataStream$.pipe(
      map(items => {
        if (!items?.length) {
          return {};
        }
        const getIndex = (entityType: ChatMemberEditSearchEntityType) => {
          const index = items.findIndex(item => item.entityType === entityType);
          return index < 0 ? null : index;
        };
        const existingIndex = getIndex(ChatMemberEditSearchEntityType.EXISTING_CONTACT);
        const groupIndex = getIndex(ChatMemberEditSearchEntityType.GROUP_CHATROOM);
        const furtherIndex = getIndex(ChatMemberEditSearchEntityType.FURTHER_USER_RESULT);
        return { existingIndex, groupIndex, furtherIndex };
      }),
      shareReplay(1)
    );
  }
}
