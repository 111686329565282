import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Injectable, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { of, ReplaySubject } from 'rxjs';
import { catchError, map, mapTo, shareReplay, switchMap } from 'rxjs/operators';
import { VariantConfig } from '../../directives/styling/variant-config';

enum IconVariant {
  primaryFlat = 'primaryFlat',
  secondaryFlat = 'secondaryFlat',
  secondaryInlined = 'secondaryInlined',
  assistiveInlined = 'assistiveInlined',
}

@Injectable()
class IconVariantConfig extends VariantConfig {
  possibleVariants = IconVariant;
  defaultVariant = null;
}

@Component({
  selector: 'wen-icon',
  templateUrl: './wen-icon.component.html',
  styleUrls: ['./wen-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: VariantConfig, useClass: IconVariantConfig }
  ],
})
export class WenIconComponent implements OnInit {

  private setIcon = new ReplaySubject<string>(1);

  @HostBinding('class.wen-icon') className = true;
  @HostBinding('class.wen-icon-filled') get filledClassName() {
    return this.fillColor;
  }

  @Input() color = null;
  @Input() fillColor: string = null;
  @Input() set iconName(iconName: string) {
    this.setIcon.next(iconName);
  }

  @HostBinding('class.wen-icon-disabled')
  @Input() disabled: boolean;

  icon$ = this.setIcon.pipe(
    switchMap((iconName) => {
      return this.matIconRegistry.getNamedSvgIcon(iconName).pipe(
        mapTo(true),
        catchError(() => of(false)),
        map(isSvgIcon => {
          return {
            svgIcon: isSvgIcon ? iconName : null,
            fontIcon: !isSvgIcon ? iconName : null
          };
        })
      );
    }),
    shareReplay(1)
  );

  constructor(
    private matIconRegistry: MatIconRegistry,
    public readonly element: ElementRef
  ) { }

  ngOnInit(): void {
  }

}
