export interface ReactionModel {
  emojiId: string;
}

export interface ReactionCount {
  emojiId: number;
  lastAddedDateTime: Date;
  count: number;
}

export interface ReactionSummary {
  emojiCounts: ReactionCount[];
  currentUsersSelection: number;
}

export interface Emoji {
  id: number;
  image: string;
  animation: string;
}

export enum ReactionContext {
  CHANNEL_MESSAGE = 'CHANNEL_MESSAGE',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  COMMENT = 'COMMENT',
  CHANNEL = 'CHANNEL'
}

export interface BroadcastInformation {
  id: string;
  type: ReactionContext;
}
