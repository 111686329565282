import { FormControl } from '@angular/forms';
import { deepCopy, deepEqual } from '@portal/wen-components';
import { FormChangedValue, FormDataObject } from '../../store/form/form.state';
import { isNullOrUndefined } from '../operators/null-check-util';

export type FormStatus = 'INVALID' | 'DISABLED' | 'PENDING' | 'VALID';

export type FormControlFor<T extends FormChangedValue> = { [K in keyof T]: FormControl<T[K]> };

const isFalsy = (value: any) => {
  return isNullOrUndefined(value) || value === '' || value === false || (Array.isArray(value) && !value.length);
};

const equals = (initial: any, changed: any) => {
  // eslint-disable-next-line eqeqeq
  if (initial == changed) {
    return true;
  }
  if (isFalsy(initial) && isFalsy(changed)) {
    return true;
  }
  if (typeof initial !== 'object' || typeof changed !== 'object') {
    return false;
  }
  if (Array.isArray(initial) && Array.isArray(changed)) {
    return deepEqual(initial, changed);
  }
  const changedKeys = Object.getOwnPropertyNames(changed || {});
  if (!changedKeys.length) {
    return initial === changed;
  }
  const areEqual = changedKeys.every((changedKey) => {
    const changedProp = changed[changedKey];
    const initialProp = isFalsy(initial) ? null : initial[changedKey] ?? null;
    return equals(initialProp, changedProp);
  });
  return areEqual;
};

export const hasChanges = (
  initialValues: FormDataObject,
  changedValues: FormChangedValue
) => {
  if (!changedValues || Object.keys(changedValues).length === 0) {
    return false;
  }
  const changesCopy = deepCopy(changedValues);
  return !equals(initialValues, changesCopy);
};
