import { CdkScrollable, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Directive, OnDestroy, Optional, ViewContainerRef } from '@angular/core';
import { filter, first, of, Subject, takeUntil, timeout } from 'rxjs';
import { ScrollStateManager } from './scroll-state-manager';
import { ResizeHandler, ResizeHandlerProvider } from '@portal/wen-components';

@Directive({
  selector: 'scrollStateHandler, [scrollStateHandler]',
  providers: [
    ScrollStateManager,
    ResizeHandlerProvider
  ]
})
export class ScrollStateHandlerDirective implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private resizeHandler: ResizeHandler;

  constructor(
    resizeHandlerProvider: ResizeHandlerProvider,
    private vcref: ViewContainerRef,
    private scrollStateManager: ScrollStateManager,
    private cdkScrollable: CdkScrollable,
    @Optional() private cdkVirtualScrollViewport: CdkVirtualScrollViewport,
  ) {
    this.resizeHandler = resizeHandlerProvider.create(this.getContainer(), { debounceTime: null });
  }

  ngAfterViewInit() {
    this.scrollStateManager.connect(this.cdkScrollable);
    this.onLayoutReady().subscribe(() => {
      this.scrollStateManager.restore();
    });
  }

  private getContainer() {
    if (this.cdkVirtualScrollViewport) {
      return this.cdkVirtualScrollViewport.elementRef.nativeElement;
    }
    return this.vcref.element.nativeElement as HTMLElement;
  }

  private onLayoutReady() {
    return this.resizeHandler.onResize$.pipe(
      filter((rect) => rect.height > 0),
      first(),
      timeout({
        first: 1000,
        with: () => of(null)
      }),
      takeUntil(this.onDestroy$)
    );
  }

  ngOnDestroy() {
    this.resizeHandler.detach();
    this.onDestroy$.next();
    this.onDestroy$.unsubscribe();
  }

}
