import { PortalFieldValueAdapter } from '@portal/ui-kit/field';
import { isNullOrUndefined } from '../../operators/null-check-util';

export class TrimFieldValueAdapter implements PortalFieldValueAdapter<string> {

  parse(rawValue: any): string {
    return rawValue?.toString().trim() ?? '';
  }

  format(parsedValue: string): string {
    return isNullOrUndefined(parsedValue) ? '' : parsedValue.trim();
  }
}
