@if (app$ | async; as app) {
  <form wenFormStore class="wen-form" [formGroup]="appEditFormGroup">
    <div class="wen-form-group">
      <div class="wen-form-field-wrapper wen-image-upload">
        <wen-image-upload [formControlName]="'icon'" [fallbackText]="imageFallbackText"
        [imageShape]="'square'"></wen-image-upload>
      </div>
      <div class="wen-form-field-wrapper">
        <mat-form-field>
          <mat-label>{{ 'APP_EDIT_FORM_APP_NAME_LABEL' | translate }}</mat-label>
          <portal-field [autocomplete]="'off'" [valueAdapter]="fieldValueAdapter" [required]="true"
            [formControlName]="'title'" [wen-test-id]="'app-info-appname'"
          wenIosKeyboardStickyTarget></portal-field>
          <mat-error portal-caption>{{ "APP_EDIT_FORM_APP_NAME_ERROR" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="wen-form-field-wrapper">
        <mat-form-field>
          <mat-label>{{ 'APP_EDIT_FORM_APP_DESCRIPTION_LABEL' | translate }}</mat-label>
          <textarea [formControlName]="'description'" matInput autocomplete="off" cdkTextareaAutosize
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" wenIosKeyboardStickyTarget></textarea>
        </mat-form-field>
      </div>
      <div class="wen-form-group-hint" portal-subheading-2>{{ "APP_EDIT_FORM_APP_DESCRIPTION_HINT" | translate }}</div>
    </div>
    <div class="wen-form-group">
      <div class="wen-form-field-wrapper">
        <mat-form-field>
          <mat-label class="wen-form-field-label">{{ 'APP_EDIT_FORM_APP_NATIVE_URI_LABEL' | translate }}</mat-label>
          <portal-field [formControlName]="'nativeUri'" [autocomplete]="'off'" [valueAdapter]="fieldValueAdapter"
          wenIosKeyboardStickyTarget></portal-field>
          <mat-error portal-caption>{{ 'APP_EDIT_FORM_APP_NATIVE_URI_ERROR' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="wen-form-field-wrapper">
        <mat-form-field>
          <mat-label class="wen-form-field-label">{{ 'APP_EDIT_FORM_APP_URI_LABEL' | translate }}</mat-label>
          <portal-field [formControlName]="'uri'" [required]="true" [autocomplete]="'off'"
            [valueAdapter]="fieldValueAdapter" wenIosKeyboardStickyTarget
          [wen-test-id]="'app-info-appuri'"></portal-field>
          <mat-error portal-caption>{{ "APP_EDIT_FORM_APP_URI_ERROR" | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="wen-form-group">
      <div class="wen-form-field-wrapper">
        <mat-form-field>
          <mat-label>{{ 'APP_EDIT_FORM_APP_PLAYSTORE_ID_LABEL' | translate }}</mat-label>
          <portal-field [formControlName]="'androidPlayStoreId'" [autocomplete]="'off'" [valueAdapter]="fieldValueAdapter"
          wenIosKeyboardStickyTarget></portal-field>
        </mat-form-field>
      </div>
      <div class="wen-form-field-wrapper">
        <mat-form-field>
          <mat-label>{{ 'APP_EDIT_FORM_APP_APPSTORE_ID_LABEL' | translate }}</mat-label>
          <portal-field [formControlName]="'iosAppStoreId'" [autocomplete]="'off'" [valueAdapter]="fieldValueAdapter"
          wenIosKeyboardStickyTarget></portal-field>
        </mat-form-field>
      </div>
      <div class="wen-form-field-wrapper">
        <mat-form-field>
          <mat-label>{{ 'APP_EDIT_FORM_APP_APPSTORE_NAME_LABEL' | translate }}</mat-label>
          <portal-field [formControlName]="'iosAppName'" autocomplete="'off'" [valueAdapter]="fieldValueAdapter"
          wenIosKeyboardStickyTarget></portal-field>
        </mat-form-field>
      </div>
    </div>
    @if (canOpenCategorySelector$ | async) {
      <div class="wen-form-group wen-select-wrapper">
        <wen-select [formControlName]="'categories'" [possibleValues]="allCategories$ | async" [mainLabel]="selectMainLabel"
          [bottomLabel]="selectBottomLabel">
        </wen-select>
      </div>
    }
    @if (canDelete$ | async) {
      <div class="wen-form-actions">
        <wen-interact-button class="wen-interact-button-menu-style" (click)="deleteApp(app.id)" [wen-test-id]="'wen-app-info-delete-app-button'">{{ "APP_DELETE_BUTTON" |
        translate }}</wen-interact-button>
      </div>
    }
  </form>
}