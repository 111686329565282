import { RoomMemberData } from '@portal/wen-backend-api';
import { ChatMessageTransactionData } from './chat-transaction-data';

export enum TransactionResultState {
  SUCCESSFUL, ERROR, ABORTED
}

export interface ChatMessageTransactionResult {
  getResultState(): TransactionResultState;
  getResults(): SingleChatMessageSendResult[];
  getErrorMessageSendResults(): ChatMessageErrorSendResult[];
  getSuccessfulMessageSendResults(): ChatMessageSuccesfulSendResult[];
}

export class ChatMessageSuccesfulSendResult {

  constructor(
    public readonly eventId: string,
    public readonly roomMemberData: RoomMemberData,
    public readonly transactionData: ChatMessageTransactionData,
  ) { }

}

export class ChatMessageErrorSendResult {

  constructor(
    public readonly transactionData: ChatMessageTransactionData,
  ) { }

}

export type SingleChatMessageSendResult = ChatMessageSuccesfulSendResult | ChatMessageErrorSendResult;

export class ChatMessageTransactionState implements ChatMessageTransactionResult {

  private transactionResultState: TransactionResultState;

  private originalDatas: ChatMessageTransactionData[] = [];
  private successfulMessageSendResults: ChatMessageSuccesfulSendResult[] = [];
  private errorMessageSendResults: ChatMessageErrorSendResult[] = [];

  addDatas(datas: ChatMessageTransactionData[]) {
    this.originalDatas.push(...datas);
  }

  addResults(
    results: SingleChatMessageSendResult[]
  ) {
    results.forEach(result => this.addResult(result));
  }

  addResult(
    result: SingleChatMessageSendResult
  ) {
    if (result instanceof ChatMessageSuccesfulSendResult) {
      this.successfulMessageSendResults.push(result);
    } else {
      this.errorMessageSendResults.push(result);
    }
  }

  getResults(): SingleChatMessageSendResult[] {
    return [...this.getSuccessfulMessageSendResults(), ...this.getErrorMessageSendResults()];
  }

  getSuccessfulMessageSendResults(): ChatMessageSuccesfulSendResult[] {
    return this.successfulMessageSendResults;
  }

  getErrorMessageSendResults(): ChatMessageErrorSendResult[] {
    return this.errorMessageSendResults;
  }

  setResultState(transactionResultState: TransactionResultState) {
    this.transactionResultState = transactionResultState;
  }

  getResultState(): TransactionResultState {
    return this.transactionResultState;
  }

}
