import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SimpleUserDTO } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { selectCurrentChannelAdmins } from '../../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';

@Injectable()
export class ChannelAdminListDatasource {

  admins$: Observable<SimpleUserDTO[]> = this.store.pipe(
    select(selectCurrentChannelAdmins)
  );

  constructor(private store: Store<RootState>) { }

}
