import { NgModule } from '@angular/core';
import { VirtualKeyboardInputDirective } from './vk-input.directive';

@NgModule({
  declarations: [
    VirtualKeyboardInputDirective
  ],
  exports: [
    VirtualKeyboardInputDirective
  ]
})
export class VirtualKeyboardModule { }
