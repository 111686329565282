import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ChannelDTO, ChannelInviteDataDTO, RestrictionDTO } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, first, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { firstExisty } from '../../../../../../core/common/operators/first-existy';
import { switchMapFirst } from '../../../../../../core/common/operators/switch-map-first';
import { selectorWithParam } from '../../../../../../core/common/util/selector-with-param';
import { clearHistoricalMessagesForChannel, disableAutoAcknowledge } from '../../../../../../core/store/channel/channel.actions';
import { selectChannelPermission, selectChannelRestrictions, selectCurrentChannel, selectIsChannelSubscribed, selectIsCurrentChannelGeoChannel } from '../../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';
import { selectChannelMessageOriginTransferData } from '../../../../../../core/store/ui/ui.selectors';

export type ChannelDeletionInfo = Pick<ChannelDTO, 'deleted'> & Pick<ChannelDTO, 'deletedReason'>;

@Injectable()
export class ChannelViewDataSource implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  public currentChannelId$: Observable<string>;
  public canSend$: Observable<boolean>;
  public restrictions$: Observable<RestrictionDTO[]>;
  public unfulfilledRestrictions$: Observable<RestrictionDTO[]>;
  public hasRestrictions$: Observable<boolean>;
  public isContentBlocked$: Observable<boolean>;
  public isSubscribedToCurrentChannel$: Observable<boolean>;
  public isMuted$: Observable<boolean>;
  public inviteData$: Observable<ChannelInviteDataDTO>;
  public isGeoChannel$: Observable<boolean>;

  constructor(
    private store: Store<RootState>,
  ) { }

  initialize() {
    const currentChannel$ = this.store.pipe(
      select(selectCurrentChannel),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );
    this.currentChannelId$ = currentChannel$.pipe(
      map((currentChannel) => currentChannel?.id),
      firstExisty(),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );

    const transferData$ = this.store.pipe(
      select(selectChannelMessageOriginTransferData),
      smartDistinctUntilChanged(),
      shareReplay(1)
    );

    this.onDestroy$.pipe(
      first(),
      switchMapFirst(() => {
        return combineLatest([this.currentChannelId$, transferData$]);
      })
    ).subscribe(([currentChannelId, transferData]) => {
      const keepMessageId = transferData?.channelId === currentChannelId ? transferData.messageId : null;
      this.store.dispatch(clearHistoricalMessagesForChannel({ channelId: currentChannelId, keepMessageId }));
      this.store.dispatch(disableAutoAcknowledge());
    });

    this.isSubscribedToCurrentChannel$ = this.currentChannelId$.pipe(
      switchMap((channelId) => this.store.pipe(
        selectorWithParam(selectIsChannelSubscribed, channelId),
      )),
      shareReplay(1),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    );

    this.canSend$ = this.currentChannelId$.pipe(
      switchMap((channelId) => this.store.pipe(
        selectorWithParam(selectChannelPermission, channelId),
      )),
      map(permission => permission?.canSend),
      shareReplay(1),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$),
    );

    this.restrictions$ = this.currentChannelId$.pipe(
      switchMap((channelId) => this.store.pipe(
        selectorWithParam(selectChannelRestrictions, channelId),
      )),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    );

    this.unfulfilledRestrictions$ = this.restrictions$.pipe(
      map(restrictions => restrictions?.filter(restriction => !restriction.fulfilled))
    );

    this.hasRestrictions$ = this.restrictions$.pipe(
      map(restrictions => restrictions && restrictions.length > 0),
      shareReplay(1),
      distinctUntilChanged(),
    );

    this.inviteData$ = currentChannel$.pipe(
      map(channelEntity => channelEntity?.invite),
      distinctUntilChanged(),
    );

    this.isContentBlocked$ = this.restrictions$.pipe(
      map((restrictions) => {
        const hasRestrictions = restrictions && restrictions.length > 0;
        if (hasRestrictions) {
          return restrictions.some((restriction) => {
            return !restriction.fulfilled;
          });
        }
        return false;
      }),
      distinctUntilChanged()
    );

    this.isMuted$ = currentChannel$.pipe(
      map(channelEntity => channelEntity?.isMuted),
      distinctUntilChanged(),
    );

    this.isGeoChannel$ = this.store.pipe(
      select(selectIsCurrentChannelGeoChannel),
      shareReplay(1),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$),
    );

  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
