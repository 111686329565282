import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCurrentChannel } from '../../../../../../core/store/channel/channel.selectors';
import { ChannelEntity } from '../../../../../../core/store/channel/channel.state';
import { createBackNavigationCommand } from '../../../../../../core/store/header/models/base-commands';
import { HeaderCommandType } from '../../../../../../core/store/header/models/HeaderCommand';
import { RootState } from '../../../../../../core/store/root/public-api';

@Component({
  selector: 'wen-channel-schedules-desktop-header',
  templateUrl: './channel-schedules-desktop-header.component.html',
  styleUrls: ['./channel-schedules-desktop-header.component.scss']
})
export class ChannelSchedulesDesktopHeaderComponent implements OnInit {

  @HostBinding('class.wen-channel-schedules-desktop-header') className = true;

  leftCommand = createBackNavigationCommand();
  titleTranslationKey = 'CHANNEL_SCHEDULED_MESSAGES_TITLE';
  rightCommand = { title: '', type: HeaderCommandType.BACK_NAVIGATION };

  currentChannel$: Observable<ChannelEntity>;

  constructor(
    private store: Store<RootState>
  ) { }

  ngOnInit(): void {
    this.currentChannel$ = this.store.pipe(
      select(selectCurrentChannel)
    );
  }
}
