import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';
import { extractSubscription, setEventSubscriptions } from '../event.actions';
import { extractEntitiesFromEventList } from '../utils/event-utils';

export const createSubscriptionExtractEffect = (
  actions$: Actions
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(extractSubscription),
      switchMap(({ data }) => {
        const extractedData = extractEntitiesFromEventList(data);
        const subscriptions = extractedData.map(d => d.subscription);
        return [
          setEventSubscriptions({ subscriptions })
        ];
      })
    )
  );
};
