import { createFeature, createReducer, on } from '@ngrx/store';
import { filterFeatureKey } from './constants';
import { removeFilter, replaceActiveFilter, upsertActiveFilter } from './filter.actions';
import { ActiveFilterMap, FilterEntity, FilterState, filterEntityAdapter } from './filter.state';

export const filterInitialState: FilterState = {
  filterEntities: filterEntityAdapter.getInitialState()
};

export const filterFeature = createFeature({
  name: filterFeatureKey,
  reducer: createReducer(
    filterInitialState,
    on(upsertActiveFilter, (state, { filterEntityType, filter }) => {
      const entities = filterEntityAdapter.getSelectors().selectEntities(state.filterEntities);
      const currentFilters = { ...entities[filterEntityType]?.activeFilters };
      const { filterId, filterValue } = filter;
      const update: FilterEntity = {
        filterEntityType,
        activeFilters: {
          ...currentFilters,
          [filterId]: filter
        }
      };
      if (!filterValue) {
        delete update.activeFilters[filterId];
      }
      const newEntities = filterEntityAdapter.upsertOne(update, state.filterEntities);
      return {
        ...state,
        filterEntities: newEntities
      };
    }),
    on(replaceActiveFilter, (state, { filterEntityType, filter }) => {
      const { filterId, filterValue } = filter;
      const update: FilterEntity = {
        filterEntityType,
        activeFilters: {
          [filterId]: filter
        } as ActiveFilterMap
      };
      if (!filterValue) {
        delete update.activeFilters[filterId];
      }
      const newEntities = filterEntityAdapter.upsertOne(update, state.filterEntities);
      return {
        ...state,
        filterEntities: newEntities
      };
    }),
    on(removeFilter, (state, { filterEntityType }) => {
      const newEntities = filterEntityAdapter.removeOne(filterEntityType, state.filterEntities);
      return {
        ...state,
        filterEntities: newEntities
      };
    }),
  )
});
