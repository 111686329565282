import { NgModule } from '@angular/core';
import { CircleImageViewModule } from '@portal/wen-components';
import { ListItemModule } from '../list-item/list-item.module';
import { UserListItemComponent } from './user-list-item.component';

@NgModule({
  imports: [ListItemModule, CircleImageViewModule],
  exports: [UserListItemComponent],
  declarations: [UserListItemComponent],
})
export class UserListItemModule {}
