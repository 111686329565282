import { Injectable, Provider, Type } from '@angular/core';
import { MassDataContainer } from '@portal/wen-data-core';
import { Observable } from 'rxjs';
import { provideSimpleMassDataContainerConnector } from '../../../../../../core/services/mass-data/list-mass-data-viewer-container-connector';
import { ChatMemberEditSearchEntity } from '../../../../../../core/store/chat/mass-data/providers/chat-member-edit-state-types';
import { ChatSelectionListContainerConnector } from './chat-selection-list-container-connector';

export interface ChatSelectionListItemModel extends ChatMemberEditSearchEntity {
  selected: boolean;
}

@Injectable()
export abstract class ChatSelectionListSelectionProvider {

  public selectedIds$: Observable<string[]>;

}

@Injectable()
export abstract class ChatSelectionListFilterProvider {

  public searchTerm$: Observable<string>;

}

export const createChatSelectionListProviders = (
  container: Type<MassDataContainer<ChatMemberEditSearchEntity>>,
  selectionProvider: Type<ChatSelectionListSelectionProvider>,
  filterProvider: Type<ChatSelectionListFilterProvider>,
): Provider[] => {
  return [
    {
      provide: ChatSelectionListSelectionProvider,
      useClass: selectionProvider
    },
    {
      provide: ChatSelectionListFilterProvider,
      useExisting: filterProvider
    },
    ...provideSimpleMassDataContainerConnector(container, ChatSelectionListContainerConnector)
  ];
};
