import { NgModule } from '@angular/core';
import { SearchEventsHandlerDirective } from './search-events-handler.directive';

@NgModule({
  declarations: [
    SearchEventsHandlerDirective
  ],
  exports: [
    SearchEventsHandlerDirective
  ],
})
export class SearchDirectivesModule { }
