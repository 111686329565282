@if (!remaining) {
  <button headerLeft portal-button inlined (click)="onClick($event)" [disabled]="disabled" type="button">
    <span portal-title>{{ "REGISTRATION_CODE_VERIFICATION_RESEND_LABEL" | translate }}</span>
  </button>
}
@if (remaining) {
  <div class="wen-registration-code-remaining">{{ remaining }}s</div>
}
@if (disabled) {
  <div class="wen-registration-code-resend-sent-text">
    {{ "REGISTRATION_CODE_VERIFICATION_RESEND_SENT_TEXT" | translate }}
  </div>
}