import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WenRouteId } from '../../../../frame/routing/types';
import { CustomFormStoreKey } from '../../../../shared/form-store/form-store-types';
import { extractOutletDatas, extractOutletIds } from './outlet-utils';

@Injectable()
export abstract class OutletHelper {

  get outletIds() {
    return extractOutletIds(this.router.routerState.snapshot?.root);
  }

  get outletDatas() {
    return extractOutletDatas(this.router.routerState.snapshot?.root);
  }

  constructor(
    protected router: Router
  ) { }

  abstract resolveClosestId(): WenRouteId;
  abstract resolveFormStoreKey(): CustomFormStoreKey | WenRouteId;
}

@Injectable()
export class DefaultOutletHelper extends OutletHelper {

  resolveClosestId() {
    return this.outletIds.primaryId;
  }

  resolveFormStoreKey() {
    const customKey = this.outletDatas?.primaryData.formStoreStoreKey;
    return customKey || this.resolveClosestId();
  }

}

@Injectable()
export class DialogOutletHelper extends OutletHelper {

  resolveClosestId() {
    return this.outletIds.dialogId;
  }

  resolveFormStoreKey() {
    const customKey = this.outletDatas?.dialogData.formStoreStoreKey;
    return customKey || this.resolveClosestId();
  }

}
