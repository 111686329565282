import { Injectable, inject } from '@angular/core';
import { EntityAdapter } from '@ngrx/entity';
import { Store, createFeatureSelector, select } from '@ngrx/store';
import { map } from 'rxjs';
import { MassDataRegistry } from '../config/mass-data-module-utils';
import { MASSDATA_STORE_FEATURE_NAME } from '../tokens';
import { MassDataStateType, MassDataType } from '../types';

export class MassDataSelectors<ENTITY> {

  private featureSelector = createFeatureSelector<MassDataStateType>(MASSDATA_STORE_FEATURE_NAME);

  constructor(
    private massDataType: MassDataType,
    private entityAdapter: EntityAdapter<ENTITY>,
    private store: Store
  ) { }

  private selectFeature() {
    return this.store.pipe(
      select(this.featureSelector),
      map((featureState) => {
        const entityState = featureState && featureState[this.massDataType];
        return entityState;
      })
    );
  }

  selectId(entity: ENTITY) {
    return String(this.entityAdapter.selectId(entity));
  }

  selectAll() {
    return this.selectFeature().pipe(
      map((entityState) => {
        if (!entityState?.entities) {
          return [];
        }
        const entities: ENTITY[] = this.entityAdapter.getSelectors().selectAll(entityState);
        return entities;
      })
    );
  }

  selectLoading() {
    return this.selectFeature().pipe(
      map((entityState) => {
        return entityState?.loadingProgress;
      })
    );
  }
}

@Injectable()
export class MassDataSelectorsFactory<ENTITY> {

  create(massDataType: MassDataType) {
    const store = inject(Store);
    const registry = inject(MassDataRegistry);
    const massDataStoreAdapter = registry.for(massDataType).getStoreAdapter();
    const entityAdapter = massDataStoreAdapter.getEntityAdapter();
    return new MassDataSelectors<ENTITY>(massDataType, entityAdapter, store);
  }

}
