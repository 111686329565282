import { SafeResourceUrl } from '@angular/platform-browser';
import { BroadcastInformation, ReactionContext, ReactionSummary } from '@portal/wen-backend-api';
import { Observable, Subject } from 'rxjs';

export abstract class ReactionHandler {
  abstract getEmoji(id: number): Observable<SafeResourceUrl>;
  abstract getAnimation(id: number): Observable<string>;
  abstract getAvailableEmojiIds(): Observable<number[]>;
  abstract sendUserReaction(
    emojiId: number,
    parentId: string | number,
    parentType: ReactionContext,
    broadcastInformation?: BroadcastInformation,
    possibleBlockingAncestor?: AncestorReference
  ): void;
  abstract getReactionSummaryFor(referenceId: string): Observable<ReactionSummary | null>;
  reactionsFromUser$: Subject<{ emojiId: number; referenceId: string }>;
}

export interface AncestorReference {
  id: string;
  type: ReactionContext;
}
