import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { SocketIoService, UserAppListResponse } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Observable, merge, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { LastArrayElement } from 'type-fest';
import { LoadingState } from '../../../common/types/store-loading-state';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { RootState } from '../../root/public-api';
import { subscribeToUserAppsUpdates, updateUserApps, updateUserAppsLoadingState, upsertAppMeta } from '../apps.actions';
import { AppMeta } from '../apps.state';

const createAppMetadata = (app: LastArrayElement<UserAppListResponse>) => {
  if (app.network) {
    const appMetaOldApi: AppMeta = {
      entityId: app.id,
      networkMetas: [{
        id: app.network
      }]
    };
    return appMetaOldApi;
  }
  const appMeta: AppMeta = {
    entityId: app.id,
    networkMetas: app.networks
  };
  return appMeta;
};

export const createAppsListForUserEffect = (
  store: Store<RootState>,
  actions$: Actions,
  userApps$: Observable<UserAppListResponse>,
  socketIoService: Pick<SocketIoService, 'app'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToUserAppsUpdates),
    first(),
    switchMap(() => store.pipe(
      select(selectCurrentUserData),
      smartDistinctUntilChanged()
    )),
    switchMap((userData) => {
      socketIoService.app.listForUser.emit({ userId: userData.userId });
      return merge(
        userApps$.pipe(
          switchMap((userApps) => {
            const appMetas = userApps.map((app) => {
              return createAppMetadata(app);
            });
            return [
              updateUserApps({ apps: userApps }),
              upsertAppMeta({ appMetas }),
              updateUserAppsLoadingState({ loadingState: LoadingState.LOADED })
            ];
          })
        ),
      ).pipe(
        catchError(() => of(updateUserAppsLoadingState({ loadingState: LoadingState.ERROR })))
      );
    })
  ));
};
