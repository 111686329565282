import { Injectable, inject } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { first, map, switchMap } from 'rxjs';
import { MassDataRegistry } from '../config/mass-data-module-utils';
import { MassDataActionsFactory, MassDataOperation } from '../mass-data.actions';
import { ofMassDataOpType, withLoadingProgress } from './effects-utils';

@Injectable()
export class MassDataEffects {

  private actions$ = inject(Actions);
  private massDataRegistry = inject(MassDataRegistry);
  private massDataActions = inject(MassDataActionsFactory);

  remoteFetchInitial$ = createEffect(() => this.actions$.pipe(
    ofMassDataOpType(MassDataOperation.REMOTE_FETCH_INITIAL_PAGE),
    switchMap((payload) => {
      const { massDataType } = payload;
      const massDataInjector = this.massDataRegistry.for(massDataType);
      const repository = massDataInjector.getRepository();
      const paging = massDataInjector.getPagingAdapter();
      const loading$ = repository.fetchPage({
        paging: paging.getInitialPageParams()
      }).pipe(
        map((response) => {
          const action = this.massDataActions.createAction({
            massDataType,
            massDataOperation: MassDataOperation.STORE_SET_ALL,
            massDataActionData: response.data
          });
          return action;
        })
      );
      return withLoadingProgress(massDataType, loading$, this.massDataActions);
    })
  ));

  remoteFetchNext$ = createEffect(() => this.actions$.pipe(
    ofMassDataOpType(MassDataOperation.REMOTE_FETCH_NEXT_PAGE),
    switchMap((payload) => {
      const { massDataType } = payload;
      const massDataInjector = this.massDataRegistry.for(massDataType);
      const massDataContainer = massDataInjector.getContainer();
      return massDataContainer.selectors.selectAll().pipe(
        first(),
        switchMap((data) => {
          const repository = massDataInjector.getRepository();
          const paging = massDataInjector.getPagingAdapter();
          const loading$ = repository.fetchPage({
            paging: paging.getNextPageParams(data.length - 1)
          }).pipe(
            map((response) => {
              const action = this.massDataActions.createAction({
                massDataType,
                massDataOperation: MassDataOperation.STORE_UPSERT_MANY,
                massDataActionData: response.data
              });
              return action;
            })
          );
          return withLoadingProgress(massDataType, loading$, this.massDataActions);
        })
      );
    })
  ));

}
