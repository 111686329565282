@if (sectionItems$ | async; as sectionItems) {
  @if (sectionItems.length) {
    <wen-section [isNativeApp]="isNativeApp">
      @if (sectionName) {
        <div section-header-title>{{ sectionName | translate }}</div>
      }
      <div class="wen-section-show-all-title" section-header-action (click)="showAllElements()">{{ 'SHOW_ALL_LABEL' | translate }}</div>
      @for (item of sectionItems; track item) {
        <wen-card [direction]="'column'" [elevate]="true"
          (click)="navigateToDetail(item.id)" section-content>
          <wen-circle-image-view [imageUrl]="item.imageSmall" shape="square" [placeholderText]="item.caption"
          card-image></wen-circle-image-view>
          <div card-title>{{ item.caption }}</div>
          <div card-description>{{ item.start | eventDate: item?.end }}</div>
          <wen-quick-subscription-panel card-floating-panel class="wen-event-card-subscription-counter"
          [eventId]="item.id"></wen-quick-subscription-panel>
        </wen-card>
      }
      @if (sectionItems.length === sectionLimit) {
        <wen-card class="last-card" [elevate]="true" section-content (click)="showAllElements()">
          <div card-title>
            <span wen-primary-color>{{ 'SHOW_ALL_LABEL' | translate }}</span>
          </div>
        </wen-card>
      }
    </wen-section>
  }
}
