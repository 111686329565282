import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EmbedDTOType } from '@portal/wen-backend-api';
import { QuoteData } from '@portal/wen-components';
import { first, map, Observable, switchMap } from 'rxjs';
import { chatViewIdentifier } from '../../../views/chat/tokens';
import { selectorWithParam } from '../../common/util/selector-with-param';
import { updateChatDraftMessage } from '../../store/chat/chat.actions';
import { ChatMessageEntity } from '../../store/chat/chat.state';
import { selectChatMessageById } from '../../store/chat/selectors/chat-message-selectors';
import { RootState } from '../../store/root/public-api';
import { selectRouteParam } from '../../store/root/root.selectors';
import { QuoteHandler } from './quote-handler';
import { lastItem } from '../../common/operators/array-utils';

@Injectable()
export class ChatQuoteHandler extends QuoteHandler {

  private store = inject(Store<RootState>);

  retrieveQuote(quotedItemId: string): Observable<QuoteData> {
    return this.store.pipe(
      select(selectRouteParam(chatViewIdentifier)),
      switchMap((roomId) => this.store.pipe(selectorWithParam(selectChatMessageById, roomId, quotedItemId))),
      first(),
      map((chatMessage: ChatMessageEntity) => {
        const quoteData: QuoteData = {
          id: quotedItemId,
          author: chatMessage?.insertUser?.name || '',
          text: chatMessage.messageContent.content,
          ...!!chatMessage.messageContent?.embeds?.length && { embed: lastItem(chatMessage.messageContent.embeds) }
        };
        return quoteData;
      })
    );
  }

  initiateQuote(quotableId: string): void {
    this.store.pipe(
      select(selectRouteParam(chatViewIdentifier))
    ).subscribe((contextId) => {
      this.store.dispatch(updateChatDraftMessage({
        message: {
          contextId,
          quote: {
            quotedItemId: quotableId,
            type: EmbedDTOType.QUOTE
          }
        }
      }));
    });
  }

}
