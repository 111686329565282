import { ComponentType } from '@angular/cdk/portal';
import { EmbedDTOType } from '@portal/wen-backend-api';
import { EmbedDocumentComponent } from '../embed-document/embed-document.component';
import { EmbedExpandableComponent } from '../embed-expandable/embed-expandable.component';
import { EmbedForwardComponent } from '../embed-forward/embed-forward.component';
import { EmbedLinkComponent } from '../embed-link/embed-link.component';
import { EmbedLocationComponent } from '../embed-location/embed-location.component';
import { EmbedMediaComponent } from '../embed-media/embed-media.component';
import { EmbedNotificationComponent } from '../embed-notification/embed-notification.component';
import { EmbedPollComponent } from '../embed-poll/embed-poll.component';
import { EmbedQuoteComponent } from '../embed-quote/embed-quote.comonent';
import { EmbedWeatherComponent } from '../embed-weather/embed-weather.component';

export const componentTypeForEmbeds: Record<EmbedDTOType, ComponentType<any>> = {
  [EmbedDTOType.DOCUMENT]: EmbedDocumentComponent,
  [EmbedDTOType.EXPANDABLE]: EmbedExpandableComponent,
  [EmbedDTOType.FORWARD]: EmbedForwardComponent,
  [EmbedDTOType.LINK]: EmbedLinkComponent,
  [EmbedDTOType.LOCATION]: EmbedLocationComponent,
  [EmbedDTOType.MEDIA]: EmbedMediaComponent,
  [EmbedDTOType.NOTIFICATION]: EmbedNotificationComponent,
  [EmbedDTOType.POLL]: EmbedPollComponent,
  [EmbedDTOType.WEATHER]: EmbedWeatherComponent,
  [EmbedDTOType.QUOTE]: EmbedQuoteComponent
};
