import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { firstExisty } from '../../../../../../core/common/operators/first-existy';
import { RootState } from '../../../../../../core/store/root/public-api';
import { selectUserChannelPermission } from '../../../../../../core/store/user/user.selectors';

export const channelCreateGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(ChannelCreateGuard).canActivate(route, state);
};

@Injectable()
export class ChannelCreateGuard  {
  constructor(
    private store: Store<RootState>,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectUserChannelPermission),
      firstExisty(),
      map(permission => permission.canCreate)
    );
  }

}
