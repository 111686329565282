import { NgModule } from '@angular/core';
import { WenIconModule } from '@portal/wen-components';
import { SharedModule } from '../../../../../shared/shared.module';
import { MessageDebugButtonComponent } from './message-debug-button/message-debug-button.component';
import { MessageDebugInfoComponent } from './message-debug-info/message-debug-info.component';

@NgModule({
  imports: [
    SharedModule,
    WenIconModule,
  ],
  declarations: [
    MessageDebugInfoComponent,
    MessageDebugButtonComponent,
  ],
  exports: [
    MessageDebugInfoComponent,
    MessageDebugButtonComponent,
  ],
})
export class MessageDebugModule { }
