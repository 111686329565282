import { Inject, Injectable, Provider, Type, inject } from '@angular/core';
import { ListMassDataViewerDatasource, smartDistinctUntilChanged } from '@portal/wen-components';
import { ExtractEntityType, MassDataContainer, MassDataLoadingProgress } from '@portal/wen-data-core';
import { Observable, debounceTime, filter, first, map, of, shareReplay, startWith, switchMap } from 'rxjs';

@Injectable()
export class ListMassDataViewerContainerConnector<C extends MassDataContainer<any>, ItemType = ExtractEntityType<C>>
  extends ListMassDataViewerDatasource<ItemType> {

  public readonly dataStream$: Observable<ItemType[]>;
  private readonly isEmpty$: Observable<boolean>;
  private readonly isLoading$: Observable<boolean>;

  constructor(
    @Inject(MassDataContainer<any>) protected dataContainer: C
  ) {
    super();
    this.dataStream$ = this.dataContainer.selectors.selectAll().pipe(
      smartDistinctUntilChanged(),
      switchMap(items => this.decorateItems(items))
    );
    this.isLoading$ = this.dataContainer.selectors.selectLoading().pipe(
      map(loadingState => loadingState === MassDataLoadingProgress.IN_PROGRESS),
      shareReplay(1)
    );
    this.isEmpty$ = this.isLoading().pipe(
      filter((isLoading) => isLoading),
      first(),
      switchMap(() => {
        return this.isLoading().pipe(
          filter((isLoading) => !isLoading),
          first(),
          switchMap(() => {
            return this.dataStream$.pipe(
              map((data) => {
                return data?.length === 0;
              })
            );
          })
        );
      }),
      debounceTime(200),
      startWith(false),
      shareReplay(1)
    );
  }

  selectItemId(model: ItemType): string {
    return this.dataContainer.selectors.selectId(model);
  }

  /**
   * Extension point to optionally decorate the items loaded by the container
   *  The returned type will be used as the list item model instead of the entity
   */
  protected decorateItems(items: ExtractEntityType<C>[]): Observable<ItemType[]> {
    return of(items);
  }

  isLoading(): Observable<boolean> {
    return this.isLoading$;
  }

  isEmpty(): Observable<boolean> {
    return this.isEmpty$;
  }

  protected onConnect(): void {
    this.fetchInitialPage();
  }

  protected onDisconnect(): void {
    this.dataContainer.clearData();
  }

  fetchInitialPage() {
    this.dataContainer.fetchInitialPage();
  }

  fetchNextPage() {
    this.dataContainer.fetchNextPage();
  }

}

export const provideSimpleMassDataContainerConnector = <
  T extends Type<MassDataContainer<any>>,
  CN extends Type<ListMassDataViewerContainerConnector<any>>,
>(
  containerCls: T,
  connectorCls?: CN
) => {
  const providers: Provider[] = [
    containerCls,
    {
      provide: ListMassDataViewerContainerConnector,
      useFactory: () => {
        const container = inject(containerCls);
        if (connectorCls) {
          return new connectorCls(container);
        }
        return new ListMassDataViewerContainerConnector(container);
      }
    },
  ];
  return providers;
};
