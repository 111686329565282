import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from '../root/public-api';
import { networkFeatureKey } from './constants';
import { NetworkState, networkEntityAdapter } from './network.state';

export interface WithFeatureState extends RootState {
  [networkFeatureKey]: NetworkState;
}

export const selectNetworkState = createFeatureSelector<NetworkState>(
  networkFeatureKey
);

const {
  selectEntities: selectAllNetworkEntities
} = networkEntityAdapter.getSelectors();

export const selectActiveNetwork = createSelector(
  selectNetworkState,
  (state) => {
    const activeId = state.activeNetworkId;
    if (!activeId) {
      return null;
    }
    const networks = selectAllNetworkEntities(state.knownNetworks);
    return networks[activeId];
  }
);

export const selectHasActiveNetwork = createSelector(
  selectActiveNetwork,
  (activeNetwork) => Boolean(activeNetwork)
);
