import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, pairwise, takeUntil } from 'rxjs/operators';
import { selectCurrentAppView } from '../../store/apps/apps.selectors';
import { RootState } from '../../store/root/public-api';

export const takeUntilAppChanged = <T>(store: Store<RootState>) => (source: Observable<T>) => {
  return source.pipe(
    takeUntil(store.pipe(
      select(selectCurrentAppView),
      map(app => app?.id),
      distinctUntilChanged(),
      pairwise(),
      filter(([prev, curr]) => prev !== curr),
      first()
    ))
  );
};
