import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { combineLatest, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { RootState } from '../../root/public-api';
import { toggleMuteChannel } from '../channel.actions';

export const createChannelSetMuteEffect = (
  store: Store<RootState>,
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(toggleMuteChannel),
    switchMap((action) => combineLatest([
      of(action),
      store.pipe(select(selectCurrentUserData), first()),
    ])),
    tap(([action, userData]) => {
      socketIoService.channel.mute.emit({ userId: userData.userId, channelId: action.channelId, isMuted: action.mute });
    }),
  ), { dispatch: false });
};
