import { EnvironmentInjector, Injectable, createEnvironmentInjector, inject } from '@angular/core';
import { MassDataType } from '../types';
import { MASS_DATA_CONFIG, MassDataConfigEntry } from './config-tokens';

export type MassDataInjector = ReturnType<MassDataRegistry['for']>;

@Injectable()
export class MassDataRegistry {

  private eInjector = inject(EnvironmentInjector);
  private configs = inject<MassDataConfigEntry[]>(MASS_DATA_CONFIG);

  private cache = new Map<MassDataType, EnvironmentInjector>();

  for(targetIdentifier: MassDataType) {
    let containerInjectors = this.cache.get(targetIdentifier);
    const entry = this.configs.find(({ identifier }) => {
      return identifier === targetIdentifier;
    });
    if (!containerInjectors) {
      const providers = Object.keys(entry).reduce((acc, key) => {
        const keyTyped = key as keyof MassDataConfigEntry;
        if (keyTyped !== 'identifier') {
          return [...acc, entry[key]];
        }
        return acc;
      }, []);
      containerInjectors = createEnvironmentInjector(providers, this.eInjector);
      this.cache.set(targetIdentifier, containerInjectors);
    }
    return {
      getRepository: () => {
        return containerInjectors.get(entry.repository);
      },
      getContainer: () => {
        return containerInjectors.get(entry.container);
      },
      getStoreAdapter: () => {
        return containerInjectors.get(entry.adapter);
      },
      getPagingAdapter: () => {
        return containerInjectors.get(entry.pagingAdapter);
      },
      getUpdatesAdapter: () => {
        const updatesAdapter = entry?.updatesAdapter;
        if (!updatesAdapter) {
          return null;
        }
        return containerInjectors.get(updatesAdapter);
      },
    };
  }
}
