import { NgModule } from '@angular/core';
import { ContactResponseActionComponent } from './contact-response-action.component';
import { ActionMenuModule, ContextMenuComponent, ContextMenuTriggerDirective, CoreComponentsModule } from '@portal/wen-components';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreComponentsModule,
    ContextMenuTriggerDirective,
    ContextMenuComponent,
    ActionMenuModule,
  ],
  declarations: [
    ContactResponseActionComponent
  ],
  exports: [
    ContactResponseActionComponent
  ]
})
export class ContactResponseActionModule { }
