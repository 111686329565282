import { Component, OnInit } from '@angular/core';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { ColorPalette } from '../../../../../shared/directives/colorize/palette-provider';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';

@Component({
  selector: 'wen-action-selector',
  templateUrl: './action-selector.component.html',
  styleUrls: ['./action-selector.component.scss']
})
export class ActionSelectorComponent implements OnInit {

  readonly inviteToNetworkConfig = {
    iconName: 'person_add',
    color: ColorPalette.WHITE,
    fillColor: ColorPalette.LIGHT_GREEN,
    titleTranslationKey: 'CRM_ACTION_SELECTOR_INVITE_NETWORK_CARD_TITLE',
    descriptionTranslationKey: 'CRM_ACTION_SELECTOR_INVITE_NETWORK_CARD_DESCRIPTION'
  };

  readonly inviteToChannelConfig = {
    iconName: 'terms_of_use',
    color: ColorPalette.WHITE,
    fillColor: ColorPalette.RED,
    titleTranslationKey: 'CRM_ACTION_SELECTOR_INVITE_CHANNEL_CARD_TITLE',
    getDescriptionTranslationKey: (inviteEnabled: boolean) => {
      return inviteEnabled ?
        'CRM_ACTION_SELECTOR_INVITE_CHANNEL_CARD_DESCRIPTION' : 'CRM_ACTION_SELECTOR_INVITE_CHANNEL_CARD_NO_EMAIL_DESCRIPTION';
    }
  };

  readonly sendChatConfig = {
    iconName: 'chats_inactive',
    color: ColorPalette.WHITE,
    fillColor: ColorPalette.GOLD,
    titleTranslationKey: 'CRM_ACTION_SELECTOR_SEND_CHAT_CARD_TITLE',
    getDescriptionTranslationKey: (inviteEnabled: boolean) => {
      return inviteEnabled ?
        'CRM_ACTION_SELECTOR_SEND_CHAT_CARD_DESCRIPTION' : 'CRM_ACTION_SELECTOR_SEND_CHAT_CARD_NO_EMAIL_DESCRIPTION';
    }
  };

  public readonly isInviteEnabled$ = this.featureEnablementService.featureConfigObs.enableCRMEmailInviteToNetwork$;

  constructor(
    private navigationHelper: WenNavigationHelper,
    private formStoreService: FormStoreService,
    private featureEnablementService: FeatureEnablementService,
  ) { }

  ngOnInit(): void {
    this.formStoreService.clearAllFormData();
  }

  navigateToInviteToNetwork() {
    this.navigationHelper.navigateToCrmInviteToNetwork();
  }

  navigateToInviteToChannel() {
    this.navigationHelper.navigateToCrmInviteToChannelSelector();
  }

  navigateToSendChat() {
    this.navigationHelper.navigateToCrmCreateChat();
  }

}
