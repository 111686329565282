import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { fetchUserEvents } from '../../../../core/store/events/event.actions';
import { RootState } from '../../../../core/store/root/public-api';

export const userEventListDataResolverFn = (route: ActivatedRouteSnapshot) => {
  inject(UserEventListDataResolver).resolve(route);
};

@Injectable()
export class UserEventListDataResolver  {

  constructor(private store: Store<RootState>) { }

  resolve(route: ActivatedRouteSnapshot): void {
    this.store.dispatch(fetchUserEvents({}));
  }
}

