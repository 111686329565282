import { UrlHandlingStrategy, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { WenBreakpointObserver } from '@portal/wen-components';

// List of special routes where the sidebar must be hidden
const noSidebarRoutes = [
  'oauth', 'error', 'register', 'onboarding', 'crm', 'signin', 'exclusive-invite'
];

@Injectable()
export class DynamicOuletUrlHandlingStrategy implements UrlHandlingStrategy {

  private breakpointObserver = inject(WenBreakpointObserver);
  private singleOutlet = !this.breakpointObserver.isDesktopStyleDevice;

  shouldProcessUrl(url: UrlTree): boolean {
    return true;
  }

  extract(url: UrlTree): UrlTree {
    const primaryOutlet = url.root.children?.primary;
    if (!primaryOutlet) {
      return url;
    }
    if (this.singleOutlet) {
      delete url.root.children.sidebar;
    } else {
      const primaryUrl = primaryOutlet.toString();
      const hasSidebar = !noSidebarRoutes.find(noSidebarRoute => primaryUrl.startsWith(noSidebarRoute));
      if (hasSidebar) {
        if (!url.root.children.sidebar) {
          url.root.children.sidebar = new UrlSegmentGroup([
            new UrlSegment('home', {})
          ], {});
        }
      }
    }
    return url;
  }

  merge(newUrlPart: UrlTree, wholeUrl: UrlTree): UrlTree {
    return newUrlPart;
  }
}
