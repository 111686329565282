import { EditMessageEventDTO, MessageEvent, RedactionDTO, RedactMessageEventDTO, ScheduledMessageEvent, SendMessageEventDTO, ToRoomEventType } from '@portal/wen-backend-api';

export const isScheduledEvent = (event: MessageEvent): event is ScheduledMessageEvent => {
  return !!(event as ScheduledMessageEvent)?.scheduledFor;
};

export const isEditEvent = (event: MessageEvent): event is MessageEvent<EditMessageEventDTO> => {
  return !!event?.relations?.replace;
};

export const isValidSendEvent = (event: MessageEvent): event is MessageEvent<SendMessageEventDTO<true>> => {
  return hasContent(event) || isRealtimeUpdate(event) || isRealtimeQuoteEvent(event) || isQuoteEvent(event);
};

export const isRedactEvent = (event: MessageEvent): event is MessageEvent<RedactMessageEventDTO> => {
  return !!(event.payload as RedactMessageEventDTO)?.redacted;
};

const hasContent = (event: MessageEvent): event is MessageEvent<SendMessageEventDTO<true>> => {
  return Object.keys((event.payload as SendMessageEventDTO<true>)?.content).length > 0 && !isRealtimeUpdate(event);
};

export const isRealtimeUpdate = (event: MessageEvent): boolean => {
  return isRealtimeRedactEvent(event) || isEditEvent(event);
};

export const isRealtimeRedactEvent = (event: MessageEvent): boolean => {
  return !!(event.payload as RedactionDTO)?.redacts;
};

export const isRealtimeEditEvent = (event: MessageEvent): boolean => {
  return event.payload.eventType === ToRoomEventType.EDIT_MESSAGE;
};

export const isAutoReplyEvent = (event: MessageEvent): boolean => {
  return event.payload?.eventType === ToRoomEventType.AUTO_REPLY;
};

export const isRealtimeQuoteEvent = (event: MessageEvent): boolean => {
  return event.payload.eventType === ToRoomEventType.QUOTE_MESSAGE;
};

export const isQuoteEvent = (event: MessageEvent): boolean => {
  return !!event?.relations?.quote;
};
