import { EmbedDTOTypes } from '../embed/embed';

export interface ChannelMessageDTO {
  id: string;
  userId: string;
  authorId: string;
  content?: string;
  embeds?: EmbedDTOTypes[];
  timestamp: string;
  updateTimestamp?: string;
  updateUserId?: string;
  updateUser?: string;
  formattedUpdateUserId?: string;
  channelId: string;
  new: boolean;
  type: MessageModificationState;
  scheduled?: boolean;
  draft?: boolean;
}

export enum MessageModificationState {
  ORIGINAL = 'original',
  EDITED = 'edit',
  DELETED = 'delete',
  EDITED_SCHEDULE = 'editScheduled',
  ERROR = 'error'
}
