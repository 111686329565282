import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FilterElementModule } from '../../../shared/directives/filter-element/filter-element.module';
import { SharedModule } from '../../../shared/shared.module';
import { SearchBarDesktopModule } from '../search/search-bar-desktop/search-bar-desktop.module';
import { WenWidgetComponent } from './wen-widget.component';

@NgModule({
  imports: [
    SharedModule,
    SearchBarDesktopModule,
    RouterModule.forChild([]),
    FilterElementModule
  ],
  declarations: [
    WenWidgetComponent
  ],
  exports: [
    WenWidgetComponent,
  ]
})
export class WenWidgetComponentModule { }
