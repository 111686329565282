import { EntityAdapter, EntityState } from '@ngrx/entity';
import { UpdateStr } from '@ngrx/entity/src/models';
import { Opaque } from 'type-fest';
import { MassDataContainer } from './mass-data-container';

export type ExtractEntityType<D extends MassDataContainer<any>> = D extends MassDataContainer<infer R> ? R : never;

export enum MassDataLoadingProgress {
  IN_PROGRESS = 'IN_PROGRESS',
  IDLE = 'IDLE',
}

export interface MassDataEntityStateProps {
  loadingProgress?: MassDataLoadingProgress;
}

export type MassDataType = Opaque<string>;

export type MassDataEntityState<T = any> = EntityState<T> & MassDataEntityStateProps;
export type MassDataStateType<T = any> = { [k: MassDataType]: MassDataEntityState<T> };

export type MassDataEntityAdapter<T> = EntityAdapter<MassDataEntityState<T>>;

export type MassDataUpdate<ENTITY> = UpdateStr<ENTITY>;
export type MassDataUpdates<ENTITY> = MassDataUpdate<ENTITY>[];
