import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListItemAfterDirective, ListItemDescriptionDirective, ListItemIconDirective, ListItemSubtitleDirective, ListItemTitleDirective, ListItemTitleSuffixIconDirective } from './list-item-directives';
import { ListItemComponent } from './list-item.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ListItemComponent,
    ListItemIconDirective,
    ListItemTitleDirective,
    ListItemTitleSuffixIconDirective,
    ListItemDescriptionDirective,
    ListItemSubtitleDirective,
    ListItemAfterDirective
  ],
  exports: [
    ListItemComponent,
    ListItemIconDirective,
    ListItemTitleDirective,
    ListItemTitleSuffixIconDirective,
    ListItemDescriptionDirective,
    ListItemSubtitleDirective,
    ListItemAfterDirective
  ]
})
export class ListItemModule { }
