import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { emailverificationUtil } from '../../../../shared/feature-specific/email-verification/email-verification-util';
import { EmailVerificationService } from '../../../../shared/feature-specific/email-verification/email-verification.service';
import { WenNativeApi } from '@portal/wen-native-api';
import { SsoDeepLinkHandler } from '../sso-deep-link-handler';
import { DeepLinkNavigator } from './deep-link-navigator';
import { DeepLinkableUtils } from './deep-linkable';

export interface DeepLinkHandleResult {
  canHandle: boolean;
  didNavigate: boolean;
}

@Injectable()
export class DeepLinkHandler {

  constructor(
    private zone: NgZone,
    private nativeApi: WenNativeApi,
    private emailVerificationService: EmailVerificationService,
    private router: Router,
    private deepLinkableUtils: DeepLinkableUtils,
    private ssoDeepLinkHandler: SsoDeepLinkHandler,
    private deepLinkNavigator: DeepLinkNavigator,
  ) { }

  handleDeepLink(link: string): DeepLinkHandleResult {
    if (!this.nativeApi.isReactNativeApp()) {
      return { canHandle: false, didNavigate: false };
    }
    const emailVerificationCode = emailverificationUtil.getCode(link);
    if (emailVerificationCode) {
      this.zone.run(() => {
        this.emailVerificationService.verifyCode(emailVerificationCode);
      });
      return { canHandle: true, didNavigate: false };
    }
    const didHandle = this.handleDeepLinks(link);
    if (didHandle) {
      return { canHandle: true, didNavigate: true };
    }
    const ssoDeepLinkResult = this.ssoDeepLinkHandler.tryHandleDeepLink(link);
    if (ssoDeepLinkResult) {
      return ssoDeepLinkResult;
    }
    return { canHandle: false, didNavigate: false };
  }

  private handleDeepLinks(link: string) {
    const routeLink = this.deepLinkableUtils.getRoutePathForUrl(link);
    if (routeLink) {
      this.zone.run(() => {
        this.deepLinkNavigator.runGuarded(() => {
          this.router.navigateByUrl(routeLink, { state: { fromDeepLink: true } });
        });
      });
      return true;
    }
    return false;
  }

}
