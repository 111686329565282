import { QuickActionPanelComponent } from './quick-action-panel/quick-action-panel.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardComponent } from './card.component';
import { CardDescriptionDirective, CardImageDirective, CardIndicatorDirective, CardTitleDirective } from './card.directives';
import { WenIconModule } from '../../wen-icon/wen-icon.module';
import { WenTestIdModule } from '../../../directives/public-api';

@NgModule({
  imports: [CommonModule, WenIconModule, WenTestIdModule],
  exports: [
    CardComponent,
    CardTitleDirective,
    CardDescriptionDirective,
    CardImageDirective,
    QuickActionPanelComponent,
    CardIndicatorDirective
  ],
  declarations: [
    CardComponent,
    CardTitleDirective,
    CardDescriptionDirective,
    CardImageDirective,
    QuickActionPanelComponent,
    CardIndicatorDirective
  ],
})
export class WenCardModule { }
