import { OlmDeviceModel } from '@portal/wen-backend-api';
import { DBSchema } from '@tempfix/idb';
import { InboundGroupSessionModel, OlmAccountModel, SessionModel } from '../data-models';

export const UNIQUE_STORE_KEY = '-';

export enum ObjectStores {
  ACCOUNT = 'account',
  DEVICES = 'devices',
  SESSIONS = 'sessions',
  INBOUND_GROUP_SESSIONS = 'inbound_group_sessions',
}

export interface ChatCryptoDB extends DBSchema {
  [ObjectStores.ACCOUNT]: {
    key: string;
    value: OlmAccountModel;
  };
  [ObjectStores.DEVICES]: {
    key: string;
    value: {
      devices: OlmDeviceModel[];
    };
  };
  [ObjectStores.SESSIONS]: {
    key: string;
    value: SessionModel;
    indexes: { 'byCurve25519': string };
  };
  [ObjectStores.INBOUND_GROUP_SESSIONS]: {
    key: string;
    value: InboundGroupSessionModel;
  };
}
