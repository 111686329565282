import { AfterViewInit, Directive, HostListener, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { OverlayManager } from '@portal/wen-components';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { WenNativeApi } from '@portal/wen-native-api';

@Directive({
  selector: 'wenVkInput, [wenVkInput]'
})
export class VirtualKeyboardInputDirective implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  private keyboardOpen = false;
  private dialogOpen = false;
  private enableKeepFocused = false;

  private get shouldKeepFocus() {
    return this.keyboardOpen && this.enableKeepFocused && !this.dialogOpen;
  }

  get domElement() {
    return this.vcRef.element.nativeElement as HTMLElement;
  }

  @Input('wenVkInputKeepFocused') set keepFocused(isEnabled: boolean) {
    if (!this.nativeApi.isReactNativeApp()) {
      return;
    }
    this.enableKeepFocused = isEnabled;
    this.reactImmediately(isEnabled);
  }

  @HostListener('blur')
  onBlur() {
    if (!this.shouldKeepFocus) {
      return;
    }
    this.domElement.focus();
  }

  @HostListener('focus')
  onFocus() {
    if (this.enableKeepFocused && this.dialogOpen) {
      this.domElement.blur();
    }
  }

  constructor(
    private vcRef: ViewContainerRef,
    private nativeApi: WenNativeApi,
    private overlayManager: OverlayManager,
  ) { }

  ngAfterViewInit() {
    if (!this.nativeApi.isReactNativeApp()) {
      return;
    }
    this.nativeApi.keyboardDidShow$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.keyboardOpen = true;
    });
    this.nativeApi.keyboardDidHide$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.keyboardOpen = false;
    });
    const hasOpenOverlay$ = this.overlayManager.hasOpenOverlay$.pipe(
      distinctUntilChanged()
    );
    hasOpenOverlay$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((dialogOpen) => {
      this.dialogOpen = dialogOpen;
      if (this.dialogOpen) {
        this.domElement.blur();
      }
    });
  }

  private reactImmediately(isEnabled: boolean) {
    if (isEnabled) {
      this.domElement.focus();
    } else {
      this.domElement.blur();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
