import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MessageSendFieldModule } from '../../message-send-field/message-send-field.module';
import { CommentMessageSendFieldComponent } from './comment-message-send-field.component';

@NgModule({
  imports: [
    CommonModule,
    MessageSendFieldModule,
    TranslateModule
  ],
  declarations: [
    CommentMessageSendFieldComponent,
  ],
  exports: [
    CommentMessageSendFieldComponent,
  ]
})
export class CommentMessageSendModule { }
