import { EntityState } from '@ngrx/entity';
import { EmbeddedMediaDTO } from '@portal/wen-backend-api';
import { PendingEmbedType } from '../../common/models';
import { channelAdapter, ChannelEntity, ChannelMessageEntity } from '../channel.state';
import { findChannelById } from './state-adapter-utils';

/**
 * @deprecated There are no pending messages anymore. Server draft messages are used instead.
 * This is only applied for channels currently
 */
export const channelPendingMessagesHelper = {

  addPendingMessage: (
    pendingMessage: Partial<ChannelMessageEntity>,
    channels: EntityState<ChannelEntity>
  ): EntityState<ChannelEntity> => {
    let targetChannel = findChannelById(pendingMessage.channelId, channels);
    if (!targetChannel?.pendingMessages) {
      targetChannel = { ...targetChannel, pendingMessages: [] };
    }
    const pendingMessages = [...targetChannel.pendingMessages, pendingMessage as ChannelMessageEntity];
    return channelAdapter.upsertOne({ ...targetChannel, pendingMessages }, channels);
  },

  removePendingMessage: (
    uploadId: string,
    channelId: string,
    channels: EntityState<ChannelEntity>
  ): EntityState<ChannelEntity> => {
    let targetChannel = findChannelById(channelId, channels);
    if (targetChannel?.pendingMessages) {
      const pendingMessages = [...targetChannel.pendingMessages];
      const matchingIndex = pendingMessages.findIndex((message) => message.id === uploadId);
      pendingMessages.splice(matchingIndex, 1);
      targetChannel = { ...targetChannel, pendingMessages };
      return channelAdapter.upsertOne(targetChannel, channels);
    }
    return channels;
  },

  updatePendingMessage: (
    uploadId: string,
    channelId: string,
    channels: EntityState<ChannelEntity>
  ): EntityState<ChannelEntity> => {
    let targetChannel = findChannelById(channelId, channels);
    if (targetChannel?.pendingMessages) {
      const updatedMessages = targetChannel.pendingMessages.map((message) => {
        if (message.id === uploadId) {
          const pendingEmbed = message.embeds[0] as EmbeddedMediaDTO;
          const updatedEmbed = { ...pendingEmbed, pendingType: PendingEmbedType.SAFE };
          return {
            ...message,
            embeds: [updatedEmbed]
          };
        } else {
          return message;
        }
      });
      targetChannel = { ...targetChannel, pendingMessages: updatedMessages };
      return channelAdapter.upsertOne(targetChannel, channels);
    }
    return channels;
  }

};
