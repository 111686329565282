import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AUTH_ERROR_RESULT_CODES, AuthErrorResult, AuthErrorResultType } from '@portal/wen-embed-api';
import { OAuthErrorEvent } from 'angular-oauth2-oidc';
import { EMPTY, catchError, filter, first, map, of, race, shareReplay, switchMap, takeUntil, throwError, withLatestFrom } from 'rxjs';
import { switchMapFirst } from '../../../common/operators/switch-map-first';
import { takeUntilAppChanged } from '../../../common/operators/take-until-app-changed';
import { WenNativeApi } from '@portal/wen-native-api';
import { EmbeddedAppOauthService } from '../../../services/user-management/embedded-app-oauth-service';
import { OauthUrlOpener } from '../../../services/user-management/oauth-url-opener';
import { selectCurrentAppView } from '../../apps/apps.selectors';
import { RootState } from '../../root/public-api';
import { handleLoginCallbackEmbeddedApp, loginCurrentEmbeddedApp, loginCurrentEmbeddedAppResult } from '../embedded-api.actions';

@Injectable()
export class EmbeddedApiAuthEffects {

  loginEmbeddedApp$ = createEffect(() => this.actions$.pipe(
    ofType(loginCurrentEmbeddedApp),
    withLatestFrom(this.store.pipe(select(selectCurrentAppView))),
    filter(([_, currentApp]) => Boolean(currentApp?.id)),
    switchMap(([action, currentApp]) => {
      const { clientId } = action;
      const { id } = currentApp;
      this.embeddedOAuthService.configure({
        clientId,
        disablePKCE: true
      });
      const startNativeFlow = () => this.createNativeFlow(id);
      const startDesktopFlow = () => this.createBrowserFlow();
      const result$ = this.nativeApi.isReactNativeApp() ? startNativeFlow() : startDesktopFlow();
      return result$.pipe(
        map((authCode) => {
          return loginCurrentEmbeddedAppResult({ result: { code: authCode } });
        }),
        catchError((errorEvent: OAuthErrorEvent) => {
          let errorType: AuthErrorResultType = 'unknown_error';
          const originalError = errorEvent?.type as any;
          if (AUTH_ERROR_RESULT_CODES.includes(originalError)) {
            errorType = originalError;
          }
          const errorResult: AuthErrorResult = {
            type: errorType
          };
          return of(loginCurrentEmbeddedAppResult({ result: { code: null, error: errorResult } }));
        }),
        first(),
        takeUntilAppChanged(this.store)
      );
    })
  ));

  private createNativeFlow(targetAppId: string) {
    const successResult$ = this.actions$.pipe(
      ofType(handleLoginCallbackEmbeddedApp),
      first(),
      shareReplay(1)
    );
    const cancelResult$ = this.oauthUrlOpener.nativeBrowserEvents$.pipe(
      switchMap((result) => {
        if (result?.type === 'cancel') {
          return throwError(() => {
            const authError: AuthErrorResult = {
              type: 'popup_closed'
            };
            return authError;
          });
        }
        return EMPTY;
      }),
      takeUntil(successResult$)
    );
    return this.embeddedOAuthService.initCodeFlow({ embeddedSsoAppId: targetAppId }).pipe(
      first(),
      switchMapFirst(() => race(successResult$, cancelResult$).pipe(
        first(),
        filter(({ appId }) => appId === targetAppId),
        switchMap(({ code, error }) => {
          if (error) {
            return throwError(() => {
              const authError: AuthErrorResult = {
                type: error
              };
              return authError;
            });
          }
          return of(code);
        })
      ))
    );
  }

  private createBrowserFlow() {
    return this.embeddedOAuthService.initLoginFlowInPopup().pipe(
      map(({ authCode }) => authCode)
    );
  }

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly embeddedOAuthService: EmbeddedAppOauthService,
    private readonly nativeApi: WenNativeApi,
    private readonly oauthUrlOpener: OauthUrlOpener,
  ) {
  }

}
