import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, map, Observable } from 'rxjs';
import { existy } from '../../../../../core/common/operators/existy';
import { PrivacySettingsChangedValues } from '../../../../../core/store/form/types/privacy-settings.types';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectUserMetaDataSettings } from '../../../../../core/store/user/user.selectors';

@Injectable()
export class PrivacySettingsViewDatasource {

  private store = inject(Store<RootState>);

  public readonly formInitialValue$: Observable<PrivacySettingsChangedValues> = this.store.pipe(
    select(selectUserMetaDataSettings),
    existy(),
    first(),
    map(({ traceReportsEnabled }) => {
      return {
        traceReportsEnabled
      };
    })
  );
}
