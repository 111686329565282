import { ChatMessageBatchLoader, ChatMessageTransactionData } from '@portal/wen-chat-client';
import { Observable, of } from 'rxjs';

export class SimpleMessageLoader extends ChatMessageBatchLoader {

  private hasMoreItems = true;

  constructor(
    private dialogRoomDatas: ChatMessageTransactionData[],
  ) {
    super();
  }

  loadNext(): Observable<ChatMessageTransactionData[]> {
    this.hasMoreItems = false;
    return of(this.dialogRoomDatas);
  }

  hasMore() {
    return this.hasMoreItems;
  }

}
