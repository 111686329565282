import { select } from '@ngrx/store';
import { filter, first, Observable } from 'rxjs';
import { selectIsChatInitialized } from '../../../store/chat/chat.selectors';
import { RootState } from '../../../store/root/public-api';

export const onChatInitialized = (source: Observable<RootState>) => {
  return source.pipe(
    select(selectIsChatInitialized),
    filter(isChatInitialized => isChatInitialized),
    first()
  );
};
