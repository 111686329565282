import { ErrorHandler, NgModule } from '@angular/core';
import { Tracer } from './sentry/logger/tracer';
import { SentryLoader } from './services/sentry-loader';
import { TracerErrorHandler } from './services/tracer-error-handler';
import { TracerInitializer } from './services/tracer-initializer';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    Tracer,
    SentryLoader,
    TracerErrorHandler,
    TracerInitializer,
    {
      provide: ErrorHandler,
      useClass: TracerErrorHandler,
    },
  ],
  exports: []
})
export class WenTracerModule { }
