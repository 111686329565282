import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '@portal/wen-components';
import { ChatMessageErrorComponent } from './chat-message-error.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    WenIconModule,
  ],
  declarations: [
    ChatMessageErrorComponent,
  ],
  exports: [
    ChatMessageErrorComponent,
  ],
})
export class ChatMessageErrorModule { }
