import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActionMenuItem, ActionMenuModule, ContextMenuData, CONTEXT_MENU_DATA, EmojiReactionsModule, ReactionSelectorData, WenTestIdModule } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { ActionMenuItemProvider } from '../../../core/services/util/action-menu-item.provider';
import { BaseMessageModel } from '../message-box/models/base-message-box.model';

export type MessageBoxMenuData<T = BaseMessageModel> = {
  message: T;
  reactionSelectorData: ReactionSelectorData;
  menuId: string;
};

@Component({
  selector: 'wen-message-box-menu',
  templateUrl: './message-box-menu.component.html',
  standalone: true,
  imports: [
    ActionMenuModule,
    AsyncPipe,
    EmojiReactionsModule,
    WenTestIdModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBoxMenuComponent implements OnInit {

  private actionMenuItemProvider = inject(ActionMenuItemProvider);
  private contextMenuData: ContextMenuData<MessageBoxMenuData> = inject(CONTEXT_MENU_DATA, { optional: true });
  private menuId: string;
  reactionSelectorData: ReactionSelectorData;
  message: BaseMessageModel = this.contextMenuData?.data?.message;
  menuActions$: Observable<ActionMenuItem[]>;

  ngOnInit() {
    this.reactionSelectorData = this.contextMenuData?.data?.reactionSelectorData;
    this.menuId = this.contextMenuData?.data?.menuId;
    this.menuActions$ = this.actionMenuItemProvider.selectActions(this.menuId, this.message);
  }
}
