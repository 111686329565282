<div class="wen-detail-header">
  <div class="wen-header-image-wrapper">
    @if (model.pendingImage && allowLoadingIndicator) {
      <div class="wen-detail-header-pending-image-wrapper">
        <ng-content select="[wen-detail-header-pending-image]"></ng-content>
      </div>
    } @else {
      @if (showImage) {
        <div class="wen-detail-header-cover-wrapper">
          <img class="wen-detail-header-cover-image" [src]="model.imageUrl | wenImageUrlTransform: (coverImageLoadedSize$ | async)"
            (load)="onImageLoaded()" (error)="onImageError()" [style.object-fit]="coverImageFitStyle" />
          </div>
        } @else {
          <wen-circle-initial-view class="wen-detail-header-default-image" [text]="model.title" [shape]="coverImageStyle">
          </wen-circle-initial-view>
        }
      }
    </div>
    <div class="wen-detail-header-subheader" [ngClass]="{ 'wen-default-subheader-flow': !showImage }">
      <div #summaryWrapper class="wen-detail-header-summary">
        <div class="wen-detail-header-title">
          <ng-content select="[wen-detail-header-title]"></ng-content>
          <ng-content select="[wen-detail-header-hint]"></ng-content>
        </div>
        @if (model.description) {
          <div portal-body-2 class="wen-detail-header-description"> {{ model.description }}</div>
        }
      </div>
      <div class="wen-detail-header-quick-actions">
        <ng-content select="[wen-detail-header-quick-actions]"></ng-content>
      </div>
    </div>
    @if (headerAdditionalContent) {
      <div class="wen-detail-header-additional-subheader">
        <ng-content select="[wen-detail-header-additional-content], wen-detail-header-additional-content"></ng-content>
      </div>
    }
  </div>
