import { Observable } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';

export const mapWithFirstFrom = <T, R>(projector: (val: T) => Observable<R>) => (source: Observable<T>): Observable<[T, R]> => {
  return source.pipe(
    mergeMap(outerVal =>
      projector(outerVal).pipe(
        map(innerVal => [outerVal, innerVal] as [T, R]),
        first()
      )
    )
  );
};
