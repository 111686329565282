import { EditMessageEventDTO, EncryptedMessageEventResponses, MessageEvent } from '@portal/wen-backend-api';

export const asEncryptedEditEvent = (event: MessageEvent): MessageEvent<EncryptedMessageEventResponses> => {
  const originalEventId = event?.relations?.replace?.relatesTo?.replace.eventId
      || (event.payload as EditMessageEventDTO)?.relatesTo?.replace.eventId;
  const newPayload = event?.relations?.replace || (event?.payload as EditMessageEventDTO);
  const editEvent: MessageEvent<EditMessageEventDTO> = {
    eventId: originalEventId,
    insertTimestamp: event.insertTimestamp,
    insertUser: event.insertUser,
    // TODO: Revisit this on the BE?
    new: false,
    payload: newPayload,
    roomId: event.roomId,
    relations: event.relations
  };
  return editEvent;
};
