import { CdkScrollable } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Paging, PagingReplayDirection } from '@portal/wen-backend-api';
import { animationFrameScheduler, filter, map, Observable, observeOn, pairwise, sample } from 'rxjs';
import { loadNextChunkOfComments } from '../../../../core/store/comments/comments.actions';
import { selectCommentsForMessage } from '../../../../core/store/comments/comments.selectors';
import { CommentEntity } from '../../../../core/store/comments/comments.state';
import { RootState } from '../../../../core/store/root/public-api';

@Injectable()
export class CommentListPagingHelper {

  currentPagingInformation$: Observable<Paging>;

  constructor(
    private store: Store<RootState>
  ) { }

  initialize(parentId: string) {
    this.currentPagingInformation$ = this.store.pipe(
      select(selectCommentsForMessage),
      map((projectorFn) => projectorFn(parentId)),
      map((commentEntity: CommentEntity) => commentEntity?.pagingInformation),
      filter((data) => !Array.isArray(data))
    );
  }

  connect(scroller: CdkScrollable) {
    const scrollInfo$ = scroller.elementScrolled().pipe(
      map(() => {
        const topOffset = scroller.measureScrollOffset('top');
        const bottomOffset = scroller.measureScrollOffset('bottom');
        return {
          topOffset, bottomOffset
        };
      }),
      pairwise(),
      map(([prevOffsets, currOffsets]) => {
        const isUp = prevOffsets.topOffset > currOffsets.topOffset;
        const direction = isUp ? PagingReplayDirection.Up : PagingReplayDirection.Down;
        return { prevOffsets, currOffsets, direction };
      })
    );

    this.scrollOnPageLoad(scrollInfo$, scroller);
  }

  loadNextChunkOfComments(parentId) {
    this.store.dispatch(loadNextChunkOfComments({ parentId }));
  }

  // TODO: Give type to the scrollInfo observable
  private scrollOnPageLoad(scrollInfo$: Observable<any>, scroller: CdkScrollable) {
    scrollInfo$.pipe(
      sample(this.currentPagingInformation$),
      map(({ currOffsets }) => currOffsets.topOffset),
      observeOn(animationFrameScheduler)
    ).subscribe(offsetValue => scroller.scrollTo({ top: offsetValue }));
  }

}
