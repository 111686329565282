<wen-message-discard-edit-button></wen-message-discard-edit-button>
<wen-media-embed-preview previewSize="fit"></wen-media-embed-preview>
<wen-quote-embed-preview></wen-quote-embed-preview>
<wen-message-send-field
  [inputPlaceholder]="'SEND_MESSAGE_INPUT_PLACEHOLDER' | translate"
  [contextId]="roomId$ | async"
  [keepInputFocused]="keepInputFocused"
  (messageSent)="onMessageSent()"
  (focused)="onFocused()"
  [isSendingAllowed]="isSendingAllowed$ | async"
  >
  <wen-message-type-selector
    wen-message-type-selector
    (tap)="onTap()"
    [menuId]="messageSelectorId"
    [disabled]="isMessageTypeSelectorDisabled$ | async">
  </wen-message-type-selector>
  @if (isChatScheduledSendingAllowed$ | async) {
    <wen-chat-send-scheduled-message
      wen-message-left-button-action
    ></wen-chat-send-scheduled-message>
  }
  @if (isChatScheduledNavigationAllowed$ | async) {
    <wen-navigate-chat-scheduled-messages-view-button
      [roomId]="roomId$ | async"
      wen-message-right-button-action
    ></wen-navigate-chat-scheduled-messages-view-button>
  }
</wen-message-send-field>
