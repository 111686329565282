import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { MassDataRepository, MultitronicMassDataRepository, PageRequestDescriptor } from '@portal/wen-data-core';
import { Observable, first, map, switchMap } from 'rxjs';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { selectActiveFiltersByEntityType } from '../../filter/filter.selectors';
import { FilterEntityType, FilterType } from '../../filter/models/filter';
import { RootState } from '../../root/public-api';
import { ContactsSearchMassDataContainer } from './contacts-search-mass-data-container';

const selectContactSearchFilter = () => (source$: Observable<RootState>) => {
  return source$.pipe(
    selectorWithParam(selectActiveFiltersByEntityType, FilterEntityType.CONTACTS_USER_LIST_FILTER),
    first(),
    map(filters => {
      const searchFilter = filters.find(filter => filter.filterType === FilterType.SEARCH);
      return searchFilter.filterValue as string;
    })
  );
};

@Injectable()
export class ExistingContactsSearchMassDataRepository extends MassDataRepository<ContactsSearchMassDataContainer> {

  private socketIoService = inject(SocketIoService);
  private store = inject(Store<RootState>);

  fetchPage(pagingParams: PageRequestDescriptor) {
    return this.store.pipe(
      selectContactSearchFilter(),
      switchMap((searchTerm) => {
        return this.socketIoService.contact.listForUser.acknowledgement$({
          ...pagingParams, search: searchTerm
        }).pipe(
          map((response) => {
            return { data: response.contacts };
          })
        );
      })
    );
  }

}

@Injectable()
export class FurtherContactsSearchMassDataRepository extends MassDataRepository<ContactsSearchMassDataContainer> {

  private socketIoService = inject(SocketIoService);
  private store = inject(Store<RootState>);

  fetchPage(pagingParams: PageRequestDescriptor) {
    return this.store.pipe(
      selectContactSearchFilter(),
      switchMap((searchTerm) => {
        return this.socketIoService.contact.listForUserFurther.acknowledgement$({
          ...pagingParams, search: searchTerm
        }).pipe(
          map((response) => {
            return { data: response.contacts };
          })
        );
      })
    );
  }

}

@Injectable()
export class ContactsSearchMassDataRepository extends MultitronicMassDataRepository<ContactsSearchMassDataContainer> {

  constructor(
    pendingReceivedContactsMassDataRepository: ExistingContactsSearchMassDataRepository,
    pendingSentContactsMassDataRepository: FurtherContactsSearchMassDataRepository
  ) {
    super([pendingReceivedContactsMassDataRepository, pendingSentContactsMassDataRepository]);
  }

}
