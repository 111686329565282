import { NgModule } from '@angular/core';
import { PortalButtonModule } from '@portal/ui-kit/button';
import { PortalCardsModule } from '@portal/ui-kit/cards';
import { PortalCommonModule } from '@portal/ui-kit/common';
import { PortalImageUploadModule } from '@portal/ui-kit/image-upload';
import { PortalProgressBarModule } from '@portal/ui-kit/progress-bar';
import { PortalDragOverlayModule } from '@portal/ui-kit/drag-overlay';

@NgModule({
  imports: [
    PortalButtonModule,
    PortalCardsModule,
    PortalImageUploadModule,
    PortalCommonModule,
    PortalProgressBarModule,
    PortalDragOverlayModule
  ],
  exports: [
    PortalButtonModule,
    PortalCardsModule,
    PortalImageUploadModule,
    PortalCommonModule,
    PortalProgressBarModule,
    PortalDragOverlayModule
  ]
})
export class PortalUiKitModule { }
