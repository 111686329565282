import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicUserProfileResponse } from '@portal/wen-backend-api';
import { map, shareReplay } from 'rxjs';

@Injectable()
export class OtherProfileData {

  private activatedRoute = inject(ActivatedRoute);
  public profile$ = this.activatedRoute.data.pipe(
    map(data => data.profile as PublicUserProfileResponse),
    shareReplay(1),
  );

}
