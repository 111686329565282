import { Injectable } from '@angular/core';
import { ChatAvailabilityChecker } from '@portal/wen-chat-client';
import { ErrorDialogData, OverlayManager } from '@portal/wen-components';
import { Observable, map, of, switchMap } from 'rxjs';

@Injectable()
export class ChatAvailabilityCheckerWithOverlay {

  constructor(
    private chatAvailabilityChecker: ChatAvailabilityChecker,
    private overlayManager: OverlayManager,
  ) { }

  /**
   * Check if the chat is available for a list of users. Shows an error dialog if any of them are unavailable.
   *
   * @param userIds The target user ids
   * @returns List of userIds which does not have available keys or unavailable for some other reason
   */
  checkChatAvailabilityWithUsersWithErrorDialog(
    userIds: string[],
    errorDialogData: ErrorDialogData,
    dialogTitle: string
  ): Observable<string[]> {
    return this.chatAvailabilityChecker.checkChatAvailabilityWithUsers(userIds).pipe(
      switchMap((unavailableUserIds) => {
        if (!unavailableUserIds.length) {
          return of([]);
        }
        const errorDialog = this.overlayManager.dialog.openErrorDialog(
          errorDialogData,
          dialogTitle
        );
        return errorDialog.afterClosed().pipe(
          map(() => unavailableUserIds)
        );
      })
    );
  }

}
