import { Component, HostBinding, OnInit } from '@angular/core';
import { ActionMenuComponent, ActionMenuItem, OverlayManager, WenBreakpointObserver } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { ActionMenuItemProvider } from '../../../../core/services/util/action-menu-item.provider';
import { startNewChatMenuId } from '../../../../core/store/header/menu/constants';

@Component({
  selector: 'wen-chat-creation-menu',
  styleUrls: ['./chat-creation-menu.component.scss'],
  templateUrl: './chat-creation-menu.component.html'
})
export class ChatCreationMenuComponent implements OnInit {

  private actions$: Observable<ActionMenuItem[]>;

  @HostBinding('class.wen-chat-creation-menu-mobile') get mobileCreateMenuClass(): boolean {
    return !this.breakpointObserver.isDesktopStyleDevice;
  }

  @HostBinding('class.wen-chat-creation-menu-desktop') get desktopCreateMenuClass(): boolean {
    return this.breakpointObserver.isDesktopStyleDevice;
  }

  constructor(
    private actionMenuItemProvider: ActionMenuItemProvider,
    private readonly breakpointObserver: WenBreakpointObserver,
    private overlayManager: OverlayManager
  ) { }

  ngOnInit(): void {
    this.actions$ = this.actionMenuItemProvider.selectActions(startNewChatMenuId);
  }

  openContextMenu() {
    this.overlayManager.menu.open(ActionMenuComponent, { actions: this.actions$ });
  }
}
