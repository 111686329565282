import { HttpClient } from '@angular/common/http';
import { WenNativeApi } from '@portal/wen-native-api';
import { BrowserFileDownloader } from './browser-file-downloader.service';
import { NativeFileDownloader } from './native-file-downloader.service';
import { WenSnackbarOpener } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';

export const downloadServiceFactory = (
  nativeApi: WenNativeApi,
  httpClient: HttpClient,
  snackBar: WenSnackbarOpener,
  translateService: TranslateService,
) => {
  if (nativeApi.isReactNativeApp()) {
    return new NativeFileDownloader(nativeApi, snackBar, translateService);
  }
  return new BrowserFileDownloader(httpClient, snackBar, translateService);
};
