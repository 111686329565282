import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { ConfirmationDialogModule } from '@portal/wen-components';
import { FormStoreModule } from '../../../shared/form-store/form-store.module';
import { SharedModule } from '../../../shared/shared.module';
import { InviteCommonModule } from '../common/invite-common.module';
import { InviteToNetworkViewComponent } from './components/invite-to-network/invite-to-network-view.component';
import { InviteToNetworkEnabledGuard } from './guards/invite-to-network-enabled.guard';

@NgModule({
  imports: [
    SharedModule,
    InviteCommonModule,
    FormStoreModule,
    MatFormFieldModule,
    PortalFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatChipsModule,
    ConfirmationDialogModule,
  ],
  declarations: [
    InviteToNetworkViewComponent,
  ],
  exports: [
    InviteToNetworkViewComponent,
  ],
  providers: [
    InviteToNetworkEnabledGuard,
  ]
})
export class InviteViewModule { }
