import { Modifier } from '@popperjs/core';

export const createOffsetModifier = (xOffset: number, yOffset: number): Omit<Modifier<string, any>, 'fn'> => ({
  name: 'offset',
  options: {
    offset: [xOffset, yOffset]
  },
  enabled: true,
  phase: 'main',
  requires: ['popperOffsets']
});
