import { ActionMenuConfig } from '@portal/wen-components';
import { AppDeleteAction } from './app-delete.action';
import { AppDetailAction } from './app-detail.action';

export const appContextMenuId = 'app-context-menu-id';

export const APP_CONTEXT_MENU_ITEMS: ActionMenuConfig = {
  menuId: appContextMenuId,
  menuItems: [AppDetailAction, AppDeleteAction]
};
