import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { EditScheduledSendToRoomEventPayload, ScheduledSendToRoomEventPayload, SendToRoomEventPayload } from '../../../../data-types/chat/send-to-room/payload';
import { SocketIoService } from '../../../api-providers/socket-io-service';
import { ChatMessageComposerData, ChatMessageComposerExtras, ChatMessagePreprocessorResult } from './types';

@Injectable()
export class ChatMessageComposerItem {

  constructor(
    private socketIoService: SocketIoService
  ) { }

  upsertMessage(
    data: ChatMessageComposerData,
    extras: ChatMessageComposerExtras,
  ) {
    const { originalEventId, scheduledFor } = data;
    const { prepareMessage } = extras;
    const result$ = prepareMessage(data).pipe(
      switchMap((preparedResult) => {
        const preparedResultPayload = preparedResult.getPayload();
        if (scheduledFor) {
          if (originalEventId) {
            const editScheduledPayload: EditScheduledSendToRoomEventPayload = {
              payload: preparedResultPayload.payload, eventId: originalEventId, scheduledFor
            };
            return this.editScheduledMessage(preparedResult, editScheduledPayload);
          } else {
            const createScheduledPayload: ScheduledSendToRoomEventPayload = {
              payload: preparedResultPayload.payload, roomId: preparedResultPayload.roomId, scheduledFor
            };
            return this.sendScheduledMessage(preparedResult, createScheduledPayload);
          }
        }
        return this.sendMessage(preparedResult, preparedResultPayload);
      })
    );
    return result$;
  }

  private sendMessage(preparedResult: ChatMessagePreprocessorResult, payload: SendToRoomEventPayload) {
    return this.socketIoService.chat.room.send.acknowledgement$(payload).pipe(
      map(({ id: eventId }) => {
        return { result: preparedResult, eventId };
      })
    );
  }

  private sendScheduledMessage(preparedResult: ChatMessagePreprocessorResult, payload: ScheduledSendToRoomEventPayload) {
    return this.socketIoService.chat.room.schedules.chatScheduledForRoom.acknowledgement$(payload).pipe(
      map(({ eventId }) => {
        return { result: preparedResult, eventId };
      })
    );
  }

  private editScheduledMessage(preparedResult: ChatMessagePreprocessorResult, payload: EditScheduledSendToRoomEventPayload) {
    return this.socketIoService.chat.room.schedules.editChatScheduledForRoom.acknowledgement$(payload).pipe(
      map(({ eventId }) => {
        return { result: preparedResult, eventId };
      })
    );
  }

}
