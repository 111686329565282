import { Injectable, Injector } from '@angular/core';
import { ReplaySubject, of, switchMap } from 'rxjs';
import { WenRouteId, WenRouteWithId } from '../../../../../frame/routing/types';
import { UnsavedChangesListener } from '../../../../../shared/services/unsaved-changes-listener';
import { toObservable } from '../../../../common/operators/observable-utils';
import { BackNavigationBehavior, BackNavigationBehaviorOutlets, BackNavigationBehaviorResult } from './back-navigation-behavior';
import { createDeepLinkedBackNavigation } from './deep-linked-back-navigation-behavior';

@Injectable()
export class DraftEditLeaveConfirmationBackNavigation implements BackNavigationBehavior {

  constructor(
    private unsavedChangesListener: UnsavedChangesListener,
    private injector: Injector,
  ) { }

  execute({ topLevelOutletId }: BackNavigationBehaviorOutlets, data: Partial<WenRouteWithId>) {
    const onResult = new ReplaySubject<BackNavigationBehaviorResult>(1);
    this.unsavedChangesListener.withConfirmation(() => {
      onResult.next({ canHandle: false });
      onResult.complete();
    }, topLevelOutletId);

    const deepLinkNavigationHandler = createDeepLinkedBackNavigation({
      desktopTransition: {
        primary: WenRouteId.DASHBOARD
      },
      mobileTransition: {
        primary: WenRouteId.HOME
      }
    })(this.injector);
    const deepLinkNavigationResult = toObservable(deepLinkNavigationHandler.execute({
      topLevelOutletId, sidebarOutletId: null
    }, data));
    return onResult.pipe(
      switchMap((result) => {
        if (!result.canHandle) {
          return deepLinkNavigationResult;
        }
        return of(result);
      })
    );
  }

}
