import { EntityState } from '@ngrx/entity';
import { AppPermission } from '../../user/models/Permission';
import { AppEntity, appsAdapter } from '../apps.state';
import { findAppById } from './state-adapter-utils';

const appPermissionHelper = {
  updatePermissionFor: (appId: string, permission: AppPermission, apps: EntityState<AppEntity>) => {
    let targetApp = findAppById(appId, apps);
    let newApps = apps;
    if (targetApp) {
      targetApp = { ...targetApp, permission };
      newApps = appsAdapter.upsertOne({ ...targetApp }, apps);
    }
    return newApps;
  }
};

export const appsHelper = {
  appPermission: appPermissionHelper,
};
