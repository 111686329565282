import { NgModule } from '@angular/core';
import { LoadingIndicatorComponent } from './loading-indicator.component';
import player from 'lottie-web';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { CommonModule } from '@angular/common';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    LottieComponent,
  ],
  exports: [LoadingIndicatorComponent],
  declarations: [LoadingIndicatorComponent],
  providers: [
    provideLottieOptions({
      player: playerFactory
    })
  ]
})
export class LoadingIndicatorModule { }
