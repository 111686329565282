import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ChatMessageDecryptionError } from '../../../../../core/services/chat/decryption/message-decryptor';
import { MessageBoxModel } from '../../../../../shared/components/message-box/models/message-box.models';

@Component({
  selector: 'wen-chat-message-error',
  templateUrl: './chat-message-error.component.html',
  styleUrls: ['./chat-message-error.component.scss']
})
export class ChatMessageErrorComponent implements OnInit {

  error$ = new ReplaySubject<ChatMessageDecryptionError>(1);

  @Input() set message(model: MessageBoxModel) {
    this.error$.next(model?.contentTemplateData);
  }

  @Output() requestShareKeyClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  errorShareKeyClicked(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.requestShareKeyClicked.emit();
  }

}
