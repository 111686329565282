import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { selectCurrentChannel } from '../../../../core/store/channel/channel.selectors';
import { ChannelEntity } from '../../../../core/store/channel/channel.state';
import { RootState } from '../../../../core/store/root/public-api';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';
import { ChannelType } from '@portal/wen-backend-api';


export interface ChannelFeedMessageData {
  isPersonalCurrentChannel: boolean;
  channelIcon?: string;
}

@Injectable()
export class ChannelConfigurationProvider {
  constructor(
    private store: Store<RootState>,
    private readonly featureEnablementService: FeatureEnablementService
  ) {}

  getCurrentChannelData(): Observable<ChannelFeedMessageData> {
    return this.store.pipe(
      select(selectCurrentChannel),
      map((channel) => {
        return {
          isPersonalCurrentChannel: this.getChannelIsPersonal(channel),
          channelIcon: channel?.icon
        };
      })
    );
  }

  getChannelIsPersonal(channel: ChannelEntity) {
    return channel?.channelType === ChannelType.PERSONAL;
  }

  getDisableEmojiReactionForCurrentChannel(): Observable<boolean> {
    return this.store.pipe(
      select(selectCurrentChannel),
      map((channel) => {
        return this.getDisableEmojiReactionForChannel(channel);
      })
    );
  }

  getDisableEmojiReactionForChannel(channel: ChannelEntity): boolean {
    return !channel?.emoji;
  }

  getCommentsEnabledForCurrentChannel(): Observable<boolean> {
    return this.store.pipe(
      select(selectCurrentChannel),
      map((channel) => {
        return this.getCommentsEnabledForChannel(channel);
      })
    );
  }

  getCommentsEnabledForChannel(channel: ChannelEntity): boolean {
    return channel?.comment &&
      this.featureEnablementService.featureFlagMethods.isEnableCommentReaction();
  }

  getIsTextToSpeechCurrentChannel(): Observable<boolean> {
    return this.store.pipe(
      select(selectCurrentChannel),
      map(channel => {
        return this.getIsTextToSpeechChannel(channel);
      })
    );
  }

  getIsTextToSpeechChannel(channel: ChannelEntity): boolean {
    return channel?.textToSpeech &&
      this.featureEnablementService.featureFlagMethods.isEnableTextToSpeech();
  }
}
