import { AfterViewInit, Component, HostBinding, Input, Optional, ViewContainerRef } from '@angular/core';
import { FeedRenderingRegistry } from '../feed/services/feed-rendering-registry';

export interface WenSeparatorModel {
  id?: string;
  centerText?: string;
  rightText?: string;
  coloring: 'background' | 'primary';
}

@Component({
  selector: 'wen-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss']
})
export class WenSeparatorComponent implements AfterViewInit {

  @HostBinding('class.wen-separator') className = true;
  @HostBinding('class.wen-separator-primary') get primaryClassName() {
    return this.displayData?.coloring === 'primary';
  }
  @HostBinding('class.wen-separator-background') get backgroundClassName() {
    return this.displayData?.coloring === 'background';
  }

  @Input() displayData: WenSeparatorModel;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    @Optional() private readonly feedRenderingRegistry: FeedRenderingRegistry,
  ) { }

  ngAfterViewInit() {
    if (this.feedRenderingRegistry && this.displayData.id) {
      const element = this.viewContainerRef.element.nativeElement;
      this.feedRenderingRegistry.clearRenderingProgress(this.displayData.id, element);
    }
  }

}
