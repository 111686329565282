import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { MassDataSelectors, MassDataSelectorsFactory } from './selector/mass-data-selector';
import { MassDataInjector, MassDataRegistry } from './config/mass-data-module-utils';
import { MassDataActionsFactory, MassDataOperation } from './mass-data.actions';
import { MassDataType } from './types';

@Injectable()
export abstract class MassDataContainer<ENTITY> {

  abstract getIdentifier(): MassDataType;

  public selectors: MassDataSelectors<ENTITY>;

  private store = inject(Store);
  private massDataActions = inject(MassDataActionsFactory);
  private massDataInjector: MassDataInjector;

  constructor() {
    const registry = inject(MassDataRegistry);
    const massDataSelectorsFactory = inject(MassDataSelectorsFactory);
    this.selectors = massDataSelectorsFactory.create(this.getIdentifier());
    this.massDataInjector = registry.for(this.getIdentifier());
    this.setupPlugins();
  }

  private setupPlugins() {
    const updatesPlugin = this.massDataInjector.getUpdatesAdapter();
    if (updatesPlugin) {
      updatesPlugin.connect(this.getIdentifier());
    }
  }

  /**
   * Delete all entities from the container
   */
  clearData() {
    const action = this.massDataActions.createAction({
      massDataType: this.getIdentifier(),
      massDataOperation: MassDataOperation.CLEAR_ALL,
    });
    this.store.dispatch(action);
  }

  /**
   * Fetch the initial page for the container
   * Resets all the existing data currently in the cache
   */
  fetchInitialPage() {
    const action = this.massDataActions.createAction({
      massDataType: this.getIdentifier(),
      massDataOperation: MassDataOperation.REMOTE_FETCH_INITIAL_PAGE,
    });
    this.store.dispatch(action);
  }

  /**
   * Fetch the next page based on the available data
   * Upserts the existing data with the new page
   */
  fetchNextPage() {
    const action = this.massDataActions.createAction({
      massDataType: this.getIdentifier(),
      massDataOperation: MassDataOperation.REMOTE_FETCH_NEXT_PAGE,
    });
    this.store.dispatch(action);
  }

}
