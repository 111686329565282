<wen-adaptive-background>
  <wen-dialog-layout wen-adaptive-background-content [headerGravity]="headerGravity">
    <wen-dialog-layout-header [inline]="true" wenIosKeyboardSticky>
      <button headerLeft portal-button inlined (click)="onCancel()" tabindex="-1">
        <span portal-title>{{ "REGISTRATION_BACK_LABEL" | translate }}</span>
      </button>
      <button headerRight portal-button inlined (click)="onSubmit()">
        <span portal-title>{{ "REGISTRATION_PROFILE_REGISTER" | translate }}</span>
      </button>
    </wen-dialog-layout-header>
    <div class="wen-registration-profile-content" dialogLayoutContent>
      <form class="wen-registration-profile-form wen-form" [formGroup]="registrationProfileFormGroup" wenFocusFirstInput>
        <div class="wen-registration-profile-group wen-form-group">
          @if (isPendingProfileImage$ | async) {
            <div class="wen-registration-profile-pending-image">
              <ng-lottie [options]="lottieOptions"></ng-lottie>
            </div>
            <div class="wen-registration-profile-pending-text">{{ pendingProfileImageText$ | async | translate }}</div>
          } @else {
            <wen-image-upload [formControlName]="'avatarUrl'" [fallbackText]="initialText"></wen-image-upload>
          }
          <div class="wen-registration-profile-description"> {{ 'REGISTRATION_PROFILE_DESCRIPTION' | translate }} </div>
          @if (registrationProfileFormGroup.errors?.globalerror) {
            <mat-error class="wen-registration-profile-group-global-error" portal-subheading-2
              >{{ "REGISTRATION_PROFILE_FORM_GLOBAL_ERROR" |
              translate }} <a portal-subheading-2>{{ supportEmail }}</a></mat-error>
            }

            <div class="wen-form-field-wrapper">
              <mat-form-field>
                <mat-label>{{ 'PROFILE_FORM_CHRISTIANNAME_LABEL' | translate }}</mat-label>
                <portal-field
                  [formControlName]="'christianName'"
                  [autocomplete]="'off'"
                  [valueAdapter]="fieldValueAdapter"
                  [required]="true"
                wenIosKeyboardStickyTarget></portal-field>
                <mat-error portal-caption>{{ "PROFILE_FORM_CHRISTIANNAME_ERROR" | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="wen-form-field-wrapper">
              <mat-form-field>
                <mat-label>{{ 'PROFILE_FORM_LASTNAME_LABEL' | translate }}</mat-label>
                <portal-field
                  [formControlName]="'lastName'"
                  [autocomplete]="'off'"
                  [valueAdapter]="fieldValueAdapter"
                  [required]="true"
                wenIosKeyboardStickyTarget></portal-field>
                <mat-error portal-caption>{{ "PROFILE_FORM_LASTNAME_ERROR" | translate }}</mat-error>
              </mat-form-field>
            </div>

            <div class="wen-form-field-wrapper">
              <mat-form-field>
                <mat-label>{{ 'REGISTRATION_PROFILE_FORM_EMAIL_LABEL' | translate }}</mat-label>
                <portal-field [formControlName]="'email'" [autocomplete]="'off'" wenIosKeyboardStickyTarget></portal-field>
                <mat-error portal-caption>{{ "PROFILE_FORM_EMAIL_ERROR" | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="wen-registration-profile-group wen-form-group">
            <div class="wen-form-field-wrapper">
              <mat-form-field>
                <mat-label>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_LABEL' | translate }}</mat-label>
                <input #passwordInput [formControlName]="'password'" matInput autocomplete="new-password" type="password"
                  wenIosKeyboardStickyTarget />
                  @if (showGenericPasswordError()) {
                    <mat-error portal-caption>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_ERROR' | translate }}</mat-error>
                  }
                  @if (showMinLengthPasswordError()) {
                    <mat-error portal-caption>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_LENGTH_ERROR' | translate: { length: 5 } }}</mat-error>
                  }
                  @if (passwordValue) {
                    <wen-password-visibility-toggle matSuffix [for]="passwordInput">
                    </wen-password-visibility-toggle>
                  }
                </mat-form-field>
              </div>

              <div class="wen-form-field-wrapper">
                <mat-form-field>
                  <mat-label>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_REPEAT_LABEL' | translate }}</mat-label>
                  <input #passwordRepeatInput [formControlName]="'passwordRepeat'"
                    [errorStateMatcher]="comparePasswordErrorMatcher" matInput autocomplete="new-password" type="password"
                    wenIosKeyboardStickyTarget />
                    <mat-error portal-caption>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_REPEAT_ERROR' | translate }}</mat-error>
                    @if (passwordRepeatValue) {
                      <wen-password-visibility-toggle matSuffix [for]="passwordRepeatInput">
                      </wen-password-visibility-toggle>
                    }
                  </mat-form-field>
                </div>
              </div>
              <div class="wen-registration-profile-group wen-form-group">
                <mat-checkbox class="wen-registration-profile-checkbox-box" [formControlName]="'discoverable'" [disableRipple]="true">
                  {{ 'REGISTRATION_PROFILE_FORM_DISCOVERABILITY_LABEL' | translate }}
                </mat-checkbox>
                @if (isAgbEnabled) {
                  <div class="wen-registration-profile-checkbox-box" >
                    <mat-checkbox [formControlName]="'agbCheck'" [disableRipple]="'true'">
                      {{ 'AGB_DATA_PROTECTION_DIALOG_TEXT.0' | translate }}
                      <a [href]="termsOfUseLink" (click)="onLinkClicked($event, termsOfUseLink)">{{ 'AGB_DATA_PROTECTION_DIALOG_TEXT.1' | translate }}</a>
                    </mat-checkbox>
                    @if (showAgbUncheckedError()) {
                      <mat-error portal-caption>{{ 'REGISTRATION_AGB_UNCHECKED_ERROR' | translate }}</mat-error>
                    }
                  </div>
                }
              </div>
            </form>
          </div>
        </wen-dialog-layout>
      </wen-adaptive-background>
