<div class="empty-list-header-wrapper">
  <ng-content select="[wen-empty-list-header]"></ng-content>
</div>
<img [src]="src"/>
<h1 portal-title class="wen-empty-list-title">{{ titleLabel | translate }}</h1>
<div portal-body-2 class="wen-empty-list-description">{{ textLabel | translate }}</div>
@if (buttonLabel) {
  <wen-rounded-button (click)="buttonClicked.emit()">
    <span>{{ buttonLabel | translate }}</span>
  </wen-rounded-button>
}
