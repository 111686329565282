
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs';
import { ClientCustomizationProvider } from '../../../../../frame/network/tokens';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { selectCurrentUserData } from '../../../../../core/store/auth/auth.selectors';
import { deleteChannelMessage } from '../../../../../core/store/channel/channel.actions';
import { selectCurrentChannelPermission } from '../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { MessageBoxModel } from '../../models/message-box.models';

@Injectable()
export class ChannelMessageBoxDeleteAction implements ActionMenuItem {

  label: string;
  icon = 'delete';
  iconColor = this.clientCustomizationProvider.getDefaultStyleConfig().errorColor;
  labelColor = this.clientCustomizationProvider.getDefaultStyleConfig().errorColor;

  private currentUserId$ = this.store.pipe(
    select(selectCurrentUserData),
    firstExisty(),
    map(({ userId }) => userId),
  );

  private parentInfo$ = this.store.pipe(
    select(selectCurrentChannelPermission),
    withLatestFrom(this.currentUserId$)
  );

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>,
    private clientCustomizationProvider: ClientCustomizationProvider
  ) {
    this.label = this.translateService.instant('MESSAGE_BOX_CONTEXT_MENU_LABEL_DELETE');
  }

  isVisible$ = (data: MessageBoxModel) => {
    return this.parentInfo$.pipe(
      map(([permissions, currentUserId]) => {
        return permissions?.canDeleteAnyMessage || (currentUserId === data.authorId && permissions?.canDeleteOwnMessage);
      }),
      distinctUntilChanged()
    );
  };

  execute(data: MessageBoxModel) {
    this.store.dispatch(deleteChannelMessage({ messageId: data.messageId }));
  }

}
