/* eslint-disable no-underscore-dangle */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CircleImageViewComponent, WenIconComponent, highlightBackgroundAnimation } from '@portal/wen-components';
import { DateUtil } from '../../../../core/common/date/date-util';
import { IMAGE_PROXY_BASE_URL } from '../../../../frame/api/tokens';
import { OtherUserProfileNavigable, UserNavigable } from '../../../directives/user-navigation/model';
import { WenComment } from '../types';

@Component({
  selector: 'wen-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: UserNavigable, useExisting: CommentComponent
  }],
  animations: [highlightBackgroundAnimation('#c7e7ff')]
})
export class CommentComponent implements OtherUserProfileNavigable, OnInit {
  formattedTimeStamp: string;
  imageUrl: string;
  markdownEnabled: boolean;

  @ViewChild('commentBubble', { static: true }) readonly commentBubble: ElementRef<HTMLDivElement>;
  @ViewChild(CircleImageViewComponent, { read: ElementRef }) readonly imageElement: ElementRef<HTMLElement>;
  @ViewChild('senderName') readonly senderName: ElementRef<HTMLElement>;
  @ViewChild(WenIconComponent, { read: ElementRef }) readonly replyElement: ElementRef<HTMLElement>;

  @Output() swipeGesture = new EventEmitter<{ comment: CommentComponent }>();
  @Output() clickOnQuote = new EventEmitter<string>();

  private _isHighlighted = false;
  private innerComment: WenComment;

  @Input()
  set comment(c: WenComment) {
    this.innerComment = c;
    this.setFormattedTimestamp(c);
    this.setAvatarUrl(c);
  }

  get comment(): WenComment {
    return this.innerComment;
  }

  set isHighlighted(value: boolean) {
    this._isHighlighted = value;
    this.cdr.detectChanges();
  }

  get isHighlighted(): boolean {
    return this._isHighlighted;
  }

  private setFormattedTimestamp(c: WenComment) {
    const dateTime = DateUtil.fromIsoString(c.createTimestamp);
    this.formattedTimeStamp = DateUtil.toTimeClientFormat(dateTime);
  }

  private setAvatarUrl(comment: WenComment) {
    const baseUrl = this.imageProxyBaseUrl ?? location.origin;
    this.imageUrl = `${baseUrl}/redirect/avatar/${comment.userId}/{size}`;
  }

  constructor(
    @Inject(IMAGE_PROXY_BASE_URL) private imageProxyBaseUrl: string,
    private cdr: ChangeDetectorRef,
  ) {
    this.markdownEnabled = false;
  }

  getTargetElement() {
    return [this.senderName.nativeElement, this.imageElement.nativeElement];
  }

  onSwipe() {
    this.swipeGesture.emit({ comment: this });
  }

  ngOnInit(): void { }

}
