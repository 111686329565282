import { Injectable } from '@angular/core';
import { MassDataContainer } from '@portal/wen-data-core';
import { createChatSearchMassDataIdentifier } from '../../constants';
import { ChatMemberEditSearchEntity } from '../providers/chat-member-edit-state-types';

@Injectable()
export class CreateChatSearchMassDataContainer
  extends MassDataContainer<ChatMemberEditSearchEntity> {

  getIdentifier() {
    return createChatSearchMassDataIdentifier;
  }

}
