import { ModuleWithProviders, NgModule } from '@angular/core';
import { WenCardsModule } from './components/cards/wen-cards.module';
import { CircleImageViewModule } from './components/circle-image-view/circle-image-view.module';
import { CircleInitialViewModule } from './components/circle-initial-view/circle-initial-view.module';
import { ConfirmationDialogModule } from './components/confirmation-dialog/confirmation-dialog.component.module';
import { CoreComponentsModule } from './components/core/core-components.module';
import { DialogLayoutModule } from './components/dialog-layout/dialog-layout.module';
import { LoadingIndicatorModule } from './components/loading-indicator/loading-indicator.module';
import { LocationPreviewModule } from './components/location-preview/location-preview.module';
import { ListMassDataViewerModule } from './components/mass-data/public-api';
import { MediaPreviewModule } from './components/media-preview/media-preview.module';
import { PollingModule } from './components/poll/polling.module';
import { SelectModule } from './components/select/select.module';
import { WenSeparatorModule } from './components/separator/separator.module';
import { WenSnackbarModule } from './components/snackbar/wen-snackbar.module';
import { WeatherModule } from './components/weather/weather.module';
import { WenIconModule } from './components/wen-icon/wen-icon.module';
import { WenSelectionListModule } from './components/wen-selection-list/wen-selection-list.module';
import { ColorizeModule } from './directives/colorize/colorize.module';
import { WenStylingModule } from './directives/styling/styling.module';
import { HasPropertyModule } from './pipes/has-property/has-property.module';
import { ImageUrlTransformModule } from './pipes/image-url-transform/image-url-transform.module';
import { RemoveMarkdownModule } from './pipes/remove-markdown/remove-markdown.module';
import { ThousandFormatModule } from './pipes/thousand-format/thousand-format.module';
import { WenOverlayModule } from './services/overlays/wen-overlay.module';
import { WenBreakpointObserver } from './services/resize/wen-breakpoint-observer';
import { WenSnackbarOpener } from './services/snackbar/wen-snackbar-opener';

const PUBLIC_MODULES = [
  WenIconModule,
  ConfirmationDialogModule,
  WenOverlayModule,
  PollingModule,
  DialogLayoutModule,
  ThousandFormatModule,
  RemoveMarkdownModule,
  WenSnackbarModule,
  WenSelectionListModule,
  WenCardsModule,
  WenSeparatorModule,
  SelectModule,
  ImageUrlTransformModule,
  HasPropertyModule,
  ColorizeModule,
  CircleImageViewModule,
  CircleInitialViewModule,
  CoreComponentsModule,
  MediaPreviewModule,
  LocationPreviewModule,
  LoadingIndicatorModule,
  WenStylingModule,
  WeatherModule,
  ListMassDataViewerModule,
];
@NgModule({
  imports: [
    ...PUBLIC_MODULES
  ],
  exports: [
    ...PUBLIC_MODULES
  ]
})
export class WenComponentsModule {

  static forRoot(): ModuleWithProviders<WenComponentsModule> {
    return {
      ngModule: WenComponentsModule,
      providers: [
        WenBreakpointObserver,
        WenSnackbarOpener
      ]
    };
  }
}
