import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { MessageViewScrollHelper } from './message-view-scroll-helper';
import { MessagesDatasource } from './messages.datasource';

const BOTTOM_VISIBILITY_OFFSET = 100;

@Injectable()
export class LegacyScrollToBottomVisibility implements OnDestroy {

  private onDestroy$ = new Subject<void>();
  private readonly isVisible = new ReplaySubject<boolean>(1);
  public readonly isVisible$ = this.isVisible.asObservable();

  constructor(
    private messagesDataSource: MessagesDatasource,
  ) { }

  // TODO: migrate other components and remove this in favor of the new initialize
  initialize(scrollHelper: MessageViewScrollHelper) {
    const scroller = scrollHelper.scroller;
    const hasUnread$ = this.messagesDataSource?.hasUnreadMessages$ || EMPTY;
    const isScrollingUp$ = scrollHelper.scrollPosition$.pipe(
      startWith(0),
      pairwise(),
      map(([last, current]) => {
        const isAtTop = last <= 0;
        return last > current || isAtTop;
      }),
    );
    const isScrollPositionAtBottom$ = scrollHelper.scrollPosition$.pipe(
      map(() => scroller.measureScrollOffset('bottom') < BOTTOM_VISIBILITY_OFFSET),
    );

    combineLatest([
      isScrollingUp$.pipe(startWith(false)),
      hasUnread$.pipe(startWith(false)),
      isScrollPositionAtBottom$.pipe(startWith(true)),
    ]).pipe(
      map(([isUp, hasUnread, isScrollPositionAtBottom]) => {
        // If there are new messages, the buttons is always visible
        // otherwise only if not at bottom and not scrolling upwards.
        return hasUnread || (!isScrollPositionAtBottom && !isUp);
      }),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(this.isVisible);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
