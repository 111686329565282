import { createAction, props } from '@ngrx/store';
import { DataContext } from '../../common/types/data-context';
import { PinEntity } from './pin.state';

export const updatePinEntities = createAction(
  '[Pin] Update entities',
  props<{ entities: PinEntity[] }>()
);

export const pinItem = createAction(
  '[Pin] Pin current item',
  props<{ contextId: string; contextType: DataContext }>()
);

export const unPinItem = createAction(
  '[Pin] Un pin current item',
  props<{ id: string }>()
);

export const addPinItem = createAction(
  '[Pin] Add item as pinned',
  props<{ pinEntity: PinEntity }>()
);

export const removePinnedItem = createAction(
  '[Pin] Remove pinned item',
  props<{ id: string }>()
);
