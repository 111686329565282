
@if (groupedCommentList$ | async; as groupedComments) {
  @for (group of groupedComments; track group) {
    <div *messageSeparator="group.dateSeparator"></div>
    @for (item of group?.comments; track item) {
      <wen-comment #commentElement [userNavigable]="item.comment.userId" [comment]="item.comment" [wenIsCurrentUser]="item.comment.userId"
        (press)="onEmojiReactionClicked(item.comment)" (clickOnQuote)="scrollToQuoteOrigin($event)" (swipeGesture)="onSwipeQuoted($event)" [wen-test-id]="'wen-comment'">
        <wen-emoji-reactions
          [referenceId]="item.comment.id"
          comment-extension
          [wenIsCurrentUser]="item.comment.userId"
          [reactionContext]="ReactionContext.COMMENT"
          (addReaction)="onAddReactionClicked(item.comment)"
        ></wen-emoji-reactions>
      </wen-comment>
    }
  }
  @if (hasMoreToLoad$ | async) {
    <div class="wen-load-more-button" (click)="loadMoreComments()" portal-body-1>{{ 'LOAD_MORE' | translate }}</div>
  }
} @else {
  <div *messageSeparator="emptyCommentListSeparatorContext"></div>
}

