/**
 * Return true if arrayA is a superset of arrayB
 */
export const arrContainsAll = <T>(arrA: T[], arrB: T[]): boolean => {
  const containsAll = (arrrA: T[], arrrB: T[]) => arrrB.every(arrBItem => arrrA.includes(arrBItem));
  return containsAll(arrA, arrB);
};

/**
 * Return items from arrayB which are not contained in arrayA (A/B)
 */
export const arrDiffAB = <T>(arrA: T[], arrB: T[]): T[] => {
  return arrB.filter(arrBItem => {
    return !arrA.includes(arrBItem);
  });
};
