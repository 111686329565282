import { Injectable, inject } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { filter, map, merge, of, shareReplay, switchMap } from 'rxjs';
import { ContactResponseActionDataWrapper } from '../contact-response-action.types';

@Injectable()
export class ContactResponseConnectionService {

  private socketIoService = inject(SocketIoService);
  private profileData = inject(ContactResponseActionDataWrapper);

  public requestStatus$ = this.profileData.profile$.pipe(
    filter((profile) => Boolean(profile)),
    switchMap(({ userId, requestStatus: initialRequestStatus }) => {
      const updates$ = this.socketIoService.contact.contactUpdated.listen.pipe(
        filter(({ user }) => user.id === userId),
        map(({ requestStatus }) => requestStatus)
      );
      return merge(of(initialRequestStatus), updates$);
    }),
    shareReplay(1)
  );

}
