import { Injector } from '@angular/core';
import { BackNavigationBehaviorFactoryProvider, BackNavigationBehaviorProviderTypes } from '../../../../frame/routing/types';

const isBackNavFactoryProvider = (
  maybeFactoryProvider: BackNavigationBehaviorProviderTypes
): maybeFactoryProvider is BackNavigationBehaviorFactoryProvider => {
  return typeof maybeFactoryProvider === 'function' && !maybeFactoryProvider?.prototype;
};

export const injectBehavior = (backNavigationBehavior: BackNavigationBehaviorProviderTypes, injector: Injector) => {
  if (isBackNavFactoryProvider(backNavigationBehavior)) {
    return backNavigationBehavior(injector);
  } else {
    return injector.get(backNavigationBehavior);
  }
};
