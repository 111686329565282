import { Injectable } from '@angular/core';
import { BackNavigationMode, NavigationInterceptorFn } from '@portal/wen-embed-api';

@Injectable()
export class EmbeddedAppContext {

  private backNavigationMode: BackNavigationMode;
  private interceptorFn: NavigationInterceptorFn;

  setBackNavigationMode(mode: BackNavigationMode): void {
    this.backNavigationMode = mode;
  }

  setNavigationInterceptor(interceptorFn: NavigationInterceptorFn): void {
    this.interceptorFn = interceptorFn;
  }

  cleanContext() {
    this.backNavigationMode = null;
    this.interceptorFn = null;
  }

  getContext() {
    return {
      backNavigationMode: this.backNavigationMode,
      interceptorFn: this.interceptorFn,
    };
  }

}
