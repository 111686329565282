import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RestrictionType, SocketIoService } from '@portal/wen-backend-api';
import { OverlayManager } from '@portal/wen-components';
import { combineLatest } from 'rxjs';
import { first, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PasswordProtectedSubscriptionDialogComponent } from '../../../../views/channel/channel-view/components/password-protected-subscription-dialog/password-protected-subscription-dialog.component';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { RootState } from '../../root/public-api';
import { enableAutoAcknowledge, resolveSubscriptionRestriction, setSubscriptionToChannelById, setSubscriptionToCurrentChannel } from '../channel.actions';
import { selectChannelRestrictions, selectCurrentChannel } from '../channel.selectors';

export const createSetChannelSubscriptionEffect = (
  store: Store<RootState>,
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(setSubscriptionToCurrentChannel),
    mapWithFirstFrom(() => combineLatest([
      store.pipe(select(selectCurrentChannel), first()),
      store.pipe(select(selectCurrentUserData), first())
    ])),
    tap(([{ setSubscribed, restriction }, [channel, userData]]) => {
      if (setSubscribed) {
        socketIoService.channel.subscribe.emit({ userId: userData.userId, channelId: channel.id, restrictions: restriction });
      } else {
        socketIoService.channel.unsubscribe.emit({ userId: userData.userId, channelId: channel.id });
      }
    }),
  ), { dispatch: false });
};

export const createSetChannelByIdSubscriptionEffect = (
  store: Store<RootState>,
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'channel'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(setSubscriptionToChannelById),
    withLatestFrom(store.pipe(select(selectCurrentUserData))),
    tap(([{ channelId, setSubscribed, restriction }, userData]) => {
      if (setSubscribed) {
        socketIoService.channel.subscribe.emit({ userId: userData.userId, channelId, restrictions: restriction });
      } else {
        socketIoService.channel.unsubscribe.emit({ userId: userData.userId, channelId });
      }
    }),
  ), { dispatch: false });
};

export const createResolveSubscriptionRestrictionEffect = (
  store: Store<RootState>,
  actions$: Actions,
  overlayManager: OverlayManager,
  translateService: TranslateService,
) => {
  return createEffect(() => actions$.pipe(
    ofType(resolveSubscriptionRestriction),
    mapWithFirstFrom(({ channelId }) => store.pipe(
      select(selectChannelRestrictions),
      first(),
      map((channelRestrictionFn) => channelRestrictionFn(channelId)),
      shareReplay(1)
    )),
    switchMap(([{ channelId, autoAcknowledge }, restrictions]) => {
      const additionalActions: Action[] = autoAcknowledge ? [enableAutoAcknowledge({ channelId })] : [];
      if (!restrictions.length) {
        return [
          setSubscriptionToCurrentChannel({ setSubscribed: true }),
          ...additionalActions
        ];
      } else if (restrictions.every(restriction => restriction.fulfilled)) {
        return [
          setSubscriptionToCurrentChannel({ setSubscribed: true }),
          ...additionalActions
        ];
      } else if (restrictions.length > 1) {
        // Multiple restrictions are not handled
        return [] as Action[];
      } else if (restrictions[0].restrictionType === RestrictionType.PasswordRequired) {
        const dialog = overlayManager.dialog.openConfirmationDialog(PasswordProtectedSubscriptionDialogComponent, {
          header: translateService.instant('PASSWORD_PROTECTED_SUBSCRIPTION_DIALOG_REASON_FIELD_LABEL')
        });
        return dialog.afterClosed().pipe(
          first(),
          switchMap((data) => {
            if (data?.result === 'ok') {
              return [
                ...additionalActions
              ];
            } else {
              return [] as Action[];
            }
          }));
      } else {
        // Unhandled restriction type
        return [] as Action[];
      }
    })
  ));
};
