import { Component, Input } from '@angular/core';
import { Category } from '@portal/wen-backend-api';

@Component({
  selector: 'wen-read-categories-field',
  templateUrl: './read-categories-field.component.html',
  styleUrls: ['./read-categories-field.component.scss'],
})
export class ReadCategoriesFieldComponent {

  readCategories: string;

  @Input() fieldLabel: string;
  @Input() set categories(categories: Category[]) {
    this.readCategories = [...categories]
      .sort((a, b) => a?.name?.localeCompare(b?.name))
      .map(category => category.name)
      .join(', ');
  }
}
