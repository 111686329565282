import { Component, Inject, OnInit, Optional, AfterViewInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChannelGeoFilterDTO, GeoAutocompleteFeature } from '@portal/wen-backend-api';
import { OverlayManager } from '@portal/wen-components';
import { filter, first, takeUntil, Subject, map } from 'rxjs';
import { DecimalFieldValueAdapter } from '../../../../../core/common/util/field-adapters/decimal-field-value-adapter';
import { RadiusConfiguration } from '../../../../../core/services/configuration/radius-configuration';
import { LocationService } from '../../../../../core/services/location/location.service';
import { LocationFormatterPipe } from '../../../../pipes/location-formatter.pipe';
import { GeoFilterLocationSelectorComponent, GeoFilterLocationSelectorDialogData, GeoFilterLocationSelectorDialogResponse } from './components/geo-filter-location-selector/geo-filter-location-selector.component';
import { geoFilterAnimations } from './geo-filter-animations';

export interface GeoFilterSelectDialogData {
  activeGeoFilter: GeoFilterSelectModel;
}

export interface GeoFilterSelectDialogResponse {
  selectedGeoFilter: GeoFilterSelectModel;
}

export type GeoFilterSelectModel = Pick<ChannelGeoFilterDTO, 'geoJson' | 'distance'>;

@Component({
  selector: 'wen-geo-filter-select',
  templateUrl: './geo-filter-select.component.html',
  styleUrls: ['./geo-filter-select.component.scss'],
  providers: [
    LocationFormatterPipe
  ],
  animations: [geoFilterAnimations.collapse]
})
export class GeoFilterSelectComponent implements OnInit, AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  fieldValueAdapter = new DecimalFieldValueAdapter();
  sliderValues = this?.radiusConfiguration?.getCustomGeoRadius() ?? [];

  formGroup: FormGroup;

  get addressControl(): AbstractControl {
    return this.formGroup.controls.address;
  }

  get radiusControl(): AbstractControl {
    return this.formGroup.controls.radius;
  }

  get hasAddress(): boolean {
    return Boolean(this.addressControl.value);
  }

  get radiusControlValue(): number {
    return this.radiusControl.value;
  }

  constructor(
    private readonly locationFormatterPipe: LocationFormatterPipe,
    private readonly locationService: LocationService,
    private readonly overlayManager: OverlayManager,
    private readonly dialogRef: MatDialogRef<GeoFilterSelectComponent, GeoFilterSelectDialogResponse>,
    @Inject(MAT_DIALOG_DATA) private dialogData: GeoFilterSelectDialogData,
    @Optional() @Inject(RadiusConfiguration) private radiusConfiguration: RadiusConfiguration
  ) {
    const defaultRadius = this?.radiusConfiguration?.getDefaultRadius() ?? 2;
    this.formGroup = new FormGroup({
      address: new FormControl(),
      radius: new FormControl(defaultRadius),
    });
  }

  ngOnInit() {
    this.updateAddress(this.dialogData.activeGeoFilter?.geoJson);
    this.updateRadius(this.dialogData.activeGeoFilter?.distance);
  }

  ngAfterViewInit() {
    this.radiusControl.valueChanges.pipe(
      map((newRadius) => this.fieldValueAdapter.parse(newRadius)),
      takeUntil(this.onDestroy$)
    ).subscribe(newValue => this.updateRadius(newValue));
  }

  openLocationSelector() {
    const dialogData: GeoFilterLocationSelectorDialogData = {
      activeGeoFilter: this.dialogData.activeGeoFilter.geoJson
    };
    const dialogRef = this.overlayManager.dialog.openInputDialog<
      GeoFilterLocationSelectorComponent, GeoFilterLocationSelectorDialogData, GeoFilterLocationSelectorDialogResponse
    >(GeoFilterLocationSelectorComponent, dialogData);
    dialogRef.beforeClosed().pipe(
      first(),
      filter(dialogResult => Boolean(dialogResult))
    ).subscribe((dialogResult: GeoFilterLocationSelectorDialogResponse) => {
      this.updateAddress(dialogResult.selectedGeoFilter);
    });
  }

  clearAddress(event: Event) {
    event.stopPropagation();
    this.updateAddress(null);
  }

  useCurrentLocation() {
    this.locationService.requestLocation().subscribe((deviceLocation) => {
      const { devicePosition: { coords } } = deviceLocation;
      const properties = deviceLocation.reverseGeoData?.features[0]?.properties ?? null;
      const location: GeoAutocompleteFeature = {
        type: 'Feature',
        properties,
        geometry: {
          type: 'Point',
          coordinates: [coords.longitude, coords.latitude]
        }
      };
      this.updateAddress(location);
    });
  }

  onApply() {
    const selectedGeoFilter = {
      ...this.dialogData.activeGeoFilter,
      distance: this.formGroup.controls.radius.value
    };

    this.dialogRef.close({ selectedGeoFilter });
  }

  onCancel() {
    this.dialogRef.close();
  }

  private updateAddress(newValue: GeoAutocompleteFeature) {
    this.dialogData.activeGeoFilter = {
      ...this.dialogData.activeGeoFilter,
      geoJson: newValue
    };
    const addressValue = this.locationFormatterPipe.transform(newValue);
    this.formGroup.controls.address.setValue(addressValue);
  }

  private updateRadius(distance: number) {
    if (isNaN(distance) || this.radiusControlValue === distance) {
      return;
    }
    this.radiusControl.setValue(distance);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
