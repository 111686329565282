import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StylingModule } from '../../directives/public-api';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { LocationPreviewComponent } from './location-preview.component';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule,
    StylingModule,
  ],
  declarations: [
    LocationPreviewComponent
  ],
  exports: [
    LocationPreviewComponent,
    StylingModule,
  ],
})
export class LocationPreviewModule { }
