import { inject, Injectable } from '@angular/core';
import { mergeError } from '@portal/wen-common';
import { Tracer } from '@portal/wen-tracer';

@Injectable()
export class AuthTracer {

  private tracer = inject(Tracer);

  addAuthBreadcrumb(description: string, data?: Record<string, any>) {
    this.tracer.addBreadcrumb({
      level: 'log',
      category: `auth.${description}`,
      data
    });
  }

  captureException(
    error: Error,
    context: Record<string, string> = {},
  ) {
    this.tracer.captureException(mergeError(error, context, true));
  }

}
