import { NgModule } from '@angular/core';
import { BackgroundColorizerDirective } from './background-colorizer.directive';
import { TextColorizerDirective } from './text-colorizer.directive';

@NgModule({
  declarations: [
    TextColorizerDirective,
    BackgroundColorizerDirective
  ],
  exports: [
    TextColorizerDirective,
    BackgroundColorizerDirective
  ]
})
export class ColorizeModule { }
