import { Injectable } from '@angular/core';
import { EmbedDTOType, EmbedDTOTypes, MediaType, WeLocalImageHelper } from '@portal/wen-backend-api';
import { filter, first, interval, map, merge, Observable, of } from 'rxjs';
import { mergeIgnoreNullish } from '../../../core/common/util/deepmerge';
import { PollService } from '../../../core/services/polling/poll-service';
import { Thumbnail } from '../thumbnail-image/thumbnail-image.component';
import { MessagePreviewCardContext } from './message-preview-card.component';

export interface MessagePreviewData {
  image: string;
  imagePlaceholder: string;
  thumbnail?: Thumbnail;
  title: string;
  description: string;
}

@Injectable()
export class MessagePreviewGenerator {

  private readonly PREVIEW_WIDTH = 240;

  constructor(
    private weLocalImageHelper: WeLocalImageHelper,
    private pollService: PollService,
  ) { }

  private createPollRequest(pollId: string) {
    const getPollResponse$ = this.pollService.getPollResponse$;
    this.pollService.getPoll({ id: pollId });
    return merge(
      getPollResponse$.pipe(
        filter((response) => response.id === pollId)
      ),
      interval(2000).pipe(map(() => null))
    ).pipe(first());
  }

  private createFromEmbed(embeds: EmbedDTOTypes[]) {
    let data$: Observable<Partial<MessagePreviewData>> = of({});
    if(embeds.length === 0) {
      return data$;
    }

    const firstEmbed = embeds[0];
    switch (firstEmbed.type) {
      case EmbedDTOType.LINK:
        data$ = of({
          title: firstEmbed.title,
          description: firstEmbed.description,
          image: firstEmbed.thumbnail?.url
        });
        break;
      case EmbedDTOType.MEDIA:
        if (!this.getOrTrue(firstEmbed.playable)) {
          return this.createFromEmbed(embeds.slice(1, embeds.length));
        }
        if (firstEmbed.subType === MediaType.PICTURE) {
          const thumbUrl = firstEmbed.thumbnailUrl
            ? this.weLocalImageHelper.getImageInSize(firstEmbed.thumbnailUrl, this.PREVIEW_WIDTH)
            : this.weLocalImageHelper.getThumbnailUrl(firstEmbed.id, this.PREVIEW_WIDTH);
          data$ = of({
            thumbnail: {
              url: thumbUrl,
              width: firstEmbed.width,
              height: firstEmbed.height
            }
          });
        } else if (firstEmbed.subType === MediaType.VIDEO && firstEmbed.id) {

          const embedId = firstEmbed?.publicId ?? firstEmbed.id;
          const thumbUrl = this.weLocalImageHelper.getThumbnailUrl(embedId, this.PREVIEW_WIDTH);

          data$ = of({
            thumbnail: {
              url: thumbUrl,
              width: null,
              height: null
            }
          });
        }
        break;
      case EmbedDTOType.POLL:
        data$ = this.createPollRequest(firstEmbed.pollId).pipe(
          map((pollModel) => {
            if (!pollModel) {
              return {};
            }
            const data: Partial<MessagePreviewData> = {
              description: pollModel.question,
              image: `${location.origin}/assets/wen-widget/icons/chart.svg`
            };
            return data;
          })
        );
        break;
      default:
        break;
    }
    return data$;
  }

  private getOrTrue(flag?: boolean) {
    return flag === undefined || flag === null || flag;
  }

  createFrom(context: MessagePreviewCardContext): Observable<MessagePreviewData> {
    const { message, channel } = context;
    const fallbackData: MessagePreviewData = {
      title: channel.title,
      description: message.content || channel.description,
      image: this.weLocalImageHelper.getImageInSize(channel.icon, this.PREVIEW_WIDTH),
      imagePlaceholder: channel.title
    };
    if (message.embeds?.length) {
      return this.createFromEmbed(message.embeds as EmbedDTOTypes[]).pipe(
        map((embedData) => {
          return mergeIgnoreNullish(fallbackData, embedData) as MessagePreviewData;
        })
      );
    }
    return of(fallbackData);
  }

}
