import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ContactRequestStatus } from '@portal/wen-backend-api';
import { Observable, combineLatest, filter, map } from 'rxjs';
import { mapAnyTrue } from '../../../../../core/common/operators/map-all-bool';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { ContactResponseResponseActionData } from '../../../../../shared/components/contact-response-action/contact-response-action.types';
import { DetailHeaderModel } from '../../../../../shared/components/detail-header/detail-header.component';
import { ProfileActionsConditions } from './providers/profile-actions-conditions';
import { ProfileActionsHandler } from './providers/profile-actions-handler';
import { OtherProfileData } from './providers/profile-data.service';

@Component({
  selector: 'wen-other-profile-view',
  templateUrl: './other-profile-view.component.html',
  styleUrls: ['./other-profile-view.component.scss'],
  providers: [
    ProfileActionsHandler,
    ProfileActionsConditions,
    OtherProfileData,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherProfileViewComponent implements OnInit {

  constructor(
    private featureEnablementService: FeatureEnablementService,
    public profileData: OtherProfileData,
    public actions: ProfileActionsHandler,
    public conditions: ProfileActionsConditions,
  ) { }

  responseActionData$: Observable<ContactResponseResponseActionData>;
  headerData$: Observable<DetailHeaderModel>;
  additionalInformation$: Observable<string>;

  enableContacts$: Observable<boolean>;
  canInitiateChat$: Observable<boolean>;
  showActions$: Observable<boolean>;
  showHint$: Observable<boolean>;

  ngOnInit() {
    this.enableContacts$ = this.featureEnablementService.featureConfigObs.enableContacts$;
    this.canInitiateChat$ = this.conditions.canInitiateChat$;
    this.showActions$ = combineLatest([this.enableContacts$, this.canInitiateChat$]).pipe(mapAnyTrue());
    this.showHint$ = combineLatest([this.profileData.profile$, this.showActions$]).pipe(
      map(([profileData, showActions]) =>
        showActions && profileData.requestStatus === ContactRequestStatus.REQUESTER
      ),
    );

    this.responseActionData$ = this.profileData.profile$.pipe(
      map(profileData => {
        const { userId, requestStatus } = profileData;
        const data: ContactResponseResponseActionData = {
          userId, requestStatus
        };
        return data;
      })
    );
    this.headerData$ = this.profileData.profile$.pipe(
      map((response) => {
        const headerData: DetailHeaderModel = {
          title: response.displayname,
          imageUrl: response.avatarUrl,
        };
        return headerData;
      })
    );

    this.additionalInformation$ = this.profileData.profile$.pipe(
      filter(() => this.featureEnablementService.featureFlagMethods.isEnableAboutMeField()),
      filter(profile => Boolean(profile.additionalInformation)),
      map(profile => profile.additionalInformation)
    );

  }

}
