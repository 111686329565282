import { Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { WenRouterOutlet } from './types';

export interface RouterOutlerSchema<T> {
  [WenRouterOutlet.PRIMARY]?: T;
  [WenRouterOutlet.SIDEBAR]?: T;
  [WenRouterOutlet.DIALOG]?: T;
}
export type RouteTraverseResult = Route[];

export type WenRouteTransitionData<R> = RouterOutlerSchema<R>;
export type RouteTraverseResults = RouterOutlerSchema<RouteTraverseResult>;
export type RouteUrlBuilderResult = RouterOutlerSchema<string>;

export abstract class RoutesProvider {
  abstract getActiveRoutes(): Route[];
}

@Injectable()
export class RouteUrlBuilder<R> {

  constructor(
    private routesProvider: RoutesProvider,
  ) { }

  private traverseTo(
    routeId: R,
    rootroute: Route
  ): RouteTraverseResult {
    if (!routeId) {
      return null;
    }
    const currentRouteId = rootroute.data?.routeId;
    if (currentRouteId && currentRouteId === routeId) {
      return [rootroute];
    }
    const children = rootroute.children || [];
    for (const routeChild of children) {
      const result = this.traverseTo(routeId, routeChild);
      if (result) {
        return [rootroute, ...result];
      }
    }
    return null;
  }

  private buildFor(
    transitionData: WenRouteTransitionData<R>
  ): RouteTraverseResults {
    const wenRoutes = this.routesProvider.getActiveRoutes();
    const realRoutes = wenRoutes[1];
    let results: RouteTraverseResults = {};
    const primaryTransition = transitionData[WenRouterOutlet.PRIMARY];
    const sidebarTransition = transitionData[WenRouterOutlet.SIDEBAR];
    const dialogTransition = transitionData[WenRouterOutlet.DIALOG];

    const operate = (routeId: R, outlet: WenRouterOutlet, acc: RouteTraverseResults) => {
      if (routeId === null) {
        return { ...acc, [outlet]: null };
      } else {
        realRoutes.children.some(route => {
          if ((!route.outlet && outlet === WenRouterOutlet.PRIMARY) || route.outlet === outlet) {
            const traverseResult = this.traverseTo(routeId, route);
            if (traverseResult) {
              acc = { ...results, [outlet]: traverseResult };
            }
            return Boolean(traverseResult);
          }
          return false;
        });
        return acc;
      }
    };

    results = operate(primaryTransition, WenRouterOutlet.PRIMARY, results);
    results = operate(sidebarTransition, WenRouterOutlet.SIDEBAR, results);
    results = operate(dialogTransition, WenRouterOutlet.DIALOG, results);
    return results;
  }

  buildUrlsFor(
    transitionData: WenRouteTransitionData<R>,
    params: Record<string, string> = {}
  ): RouteUrlBuilderResult {
    const data = this.buildFor(transitionData);
    let result: RouteUrlBuilderResult = {};
    const { primary: primaryResult, sidebar: sidebarResult, dialog: dialogResult } = data;
    const operate = (traverseResult: RouteTraverseResult, outlet: WenRouterOutlet, acc: RouteUrlBuilderResult) => {
      if (traverseResult === null) {
        return { ...acc, [outlet]: null };
      } else if (!traverseResult) {
        return acc;
      }
      let plainUrl = traverseResult
        .map(value => value.path)
        .filter(value => Boolean(value))
        .join('/');
      Object.entries(params).forEach(([paramKey, paramValue]) => {
        plainUrl = plainUrl.replace(`:${paramKey}`, paramValue);
      });
      return { ...acc, [outlet]: plainUrl };
    };
    result = operate(primaryResult, WenRouterOutlet.PRIMARY, result);
    result = operate(sidebarResult, WenRouterOutlet.SIDEBAR, result);
    result = operate(dialogResult, WenRouterOutlet.DIALOG, result);
    return result;
  }

  buildUrlsForSingleRoute(routeId: R, params: Record<string, string> = {}, targetOutlet?: WenRouterOutlet) {
    const result = this.buildUrlsFor({
      primary: routeId, dialog: routeId, sidebar: routeId
    }, params);
    if (Object.keys(result).length > 1) {
      if (targetOutlet) {
        return { [targetOutlet]: result[targetOutlet] };
      } else {
        throw new Error(`Multiple outlets are found for a single route building route for: ${routeId}`);
      }
    }
    return result;
  }

  buildCommandsForSingleRoute(routeId: R, params: Record<string, string> = {}, targetOutlet?: WenRouterOutlet) {
    const outlets = this.buildUrlsForSingleRoute(routeId, params, targetOutlet);
    return [{ outlets }];
  }

}
