@if (isSubscriptionAllowed && !isHiddenSubscriptionLayout) {
  @if (isRestrictionLayout) {
    <ng-container *ngTemplateOutlet="restrictionLayout"></ng-container>
  } @else {
    <wen-interact-button (click)="handleInvite(true)">
      {{ "INVITE_CHANNEL_ACCEPT_BUTTON_LABEL" | translate }}
    </wen-interact-button>
    <wen-interact-button (click)="handleInvite(false)" class="wen-interact-button-secondary">
      {{ "INVITE_CHANNEL_DENY_BUTTON_LABEL" | translate }}
    </wen-interact-button>
  }
}

<ng-template #restrictionLayout>
  <wen-channel-subscribe-button [channelId]="channelId" [restrictions]="restrictions"></wen-channel-subscribe-button>
</ng-template>

