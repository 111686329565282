import { ActivatedRoute } from '@angular/router';
import { catchError, of } from 'rxjs';
import { AppConfigurationProvider } from '../../../core/services/configuration/app-confguration';
import { SIGNIN_RETURN_URL } from '../../../core/services/navigation/query-params';
import { WenNavigationHelper } from '../../../core/services/navigation/types';
import { WenOAuthService } from '../../../core/services/user-management/wen-oauth.service';
import { SignInActionsHandler, SignInViewConfig } from './types';
import { TranslateService } from '@ngx-translate/core';
import { hasTranslationFor } from '../../../shared/util/translation-util';

export class SignInDefaultHandler extends SignInActionsHandler {

  viewConfig: SignInViewConfig;

  get returnUrl() {
    return this.activatedRoute.snapshot.queryParams[SIGNIN_RETURN_URL] || '/';
  }

  constructor(
    private readonly navigationHelper: WenNavigationHelper,
    private readonly wenOAuthService: WenOAuthService,
    private readonly config: AppConfigurationProvider,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {
    super();
    this.viewConfig = {
      showLoginButton: true,
      showRegisterButton: true,
      showAnonLoginButton: true,
      showLoginPrompt: false,
      termsAndConditions:
        hasTranslationFor(translateService, 'PRIVACY_POLICY_URL')
        && hasTranslationFor(translateService,'TERMS_AND_CONDITIONS_URL'),
    };
  }

  goToRegister() {
    this.navigationHelper.navigateToRegistrationStart(false);
  }

  goToLogin() {
    this.wenOAuthService.initCodeFlow({ redirectAfterLogin: this.returnUrl }).pipe(
      catchError(() => {
        this.navigationHelper.navigateToErrorPage();
        return of(false);
      })
    ).subscribe();
  }

  goToAnonymousLogin() {
    this.wenOAuthService.initCodeFlow({ redirectAfterLogin: this.returnUrl, anonymousAutoLogin: true }).pipe(
      catchError(() => {
        this.navigationHelper.navigateToErrorPage();
        return of(false);
      })
    ).subscribe();
  }

  openTermsAndConditionsPage() {
    this.navigationHelper.navigateToUrlWithFallback(this.translateService.instant('TERMS_AND_CONDITIONS_URL'));
    return false;
  }

  openPrivacyPage() {
    this.navigationHelper.navigateToUrlWithFallback(this.translateService.instant('PRIVACY_POLICY_URL'));
    return false;
  }

}
