import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ACTIVE_ITEM_IMAGE_DATA_PROVIDER, ActiveItemImageData } from './active-item-image-view-providers';
import { Dimensions2D, IndicatorType } from '@portal/wen-components';


@Component({
  selector: 'wen-active-item-image-view',
  templateUrl: './active-item-image-view.component.html',
  styleUrls: ['./active-item-image-view.component.scss'],
  providers: [ACTIVE_ITEM_IMAGE_DATA_PROVIDER]
})
export class ActiveItemImageViewComponent implements OnInit {

  imageUrl$: Observable<string>;
  placeholder$: Observable<string>;
  overlayData$: Observable<IndicatorType>;

  @Input() overlaySize: 'medium' | 'tiny' = 'tiny';
  @Input() fontSize: number;
  @Input() imageSize: Dimensions2D = { height: 60 };
  @Input() disabled = false;

  constructor(
    private imageDataProvider: ActiveItemImageData,
  ) { }

  ngOnInit(): void {
    this.imageUrl$ = this.imageDataProvider.imageUrl$;
    this.placeholder$ = this.imageDataProvider.placeholder$;
    this.overlayData$ = this.imageDataProvider.overlayData$;
  }
}
