<mat-form-field (click)="openSelectorDialog()" >
  <mat-label>{{ mainLabel }}</mat-label>
  <mat-select [compareWith]="compareIds" multiple disableRipple disableOptionCentering>
    @for (possible of possibleValues; track possible) {
      <mat-option [value]="possible">{{ possible.value }}</mat-option>
    }
  </mat-select>
  <wen-icon [iconName]="'chevron_down'" wen-primary-color matIconSuffix></wen-icon>
</mat-form-field>
@if (bottomLabel) {
  <div class="wen-form-group-hint" portal-subheading-2>{{ bottomLabel }}</div>
}
