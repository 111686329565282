import { Update } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { sectionFeatureKey } from './constants';
import { updateAppExploreSectionLoadingState, updateChannelExploreSectionLoadingState, updateEventExploreSectionLoadingState, updateSection, updateSectionContent } from './sections.actions';
import { sectionAdapter, SectionEntity, SectionState } from './sections.state';
import { LoadingState } from '../../common/types/store-loading-state';

export const initialSectionState: SectionState = {
  sectionEntities: sectionAdapter.getInitialState({}),
  channelExploreSectionLoadingState: LoadingState.NOT_LOADED,
  eventExploreSectionLoadingState: LoadingState.NOT_LOADED,
  appExploreSectionLoadingState: LoadingState.NOT_LOADED
};

export const sectionFeature = createFeature({
  name: sectionFeatureKey,
  reducer: createReducer(
    initialSectionState,
    on(updateSection, (state, { sections }) => {
      const newEntities = sectionAdapter.upsertMany(sections, state.sectionEntities);
      return {
        ...state,
        sectionEntities: newEntities
      };
    }),
    on(updateSectionContent, (state, { data, sectionId }) => {
      const updateEntity: Update<SectionEntity> = {
        id: sectionId,
        changes: {
          content: data
        }
      };
      const newEntities = sectionAdapter.updateOne(updateEntity, state.sectionEntities);
      return {
        ...state,
        sectionEntities: newEntities
      };
    }),
    on(updateChannelExploreSectionLoadingState, (state, { loadingState }) => {
      return {
        ...state,
        channelExploreSectionLoadingState: loadingState
      };
    }),
    on(updateEventExploreSectionLoadingState, (state, { loadingState }) => {
      return {
        ...state,
        eventExploreSectionLoadingState: loadingState
      };
    }),
    on(updateAppExploreSectionLoadingState, (state, { loadingState }) => {
      return {
        ...state,
        appExploreSectionLoadingState: loadingState
      };
    })
  )
});
