  <div class="wen-form-field-wrapper">
    <div class="wen-form-field-label" portal-caption>
      {{ context.label }}
    </div>
    <div class="wen-weather-notification-time-wrapper" [formGroup]="parentGroup">
      <ngx-mat-timepicker
        [formControlName]="'frontendOnlyTimeDateControl'"
        [showSeconds]="false"
        class="strip-ripple">
      </ngx-mat-timepicker>
      {{ "HOUR" | translate }}
    </div>
  </div>
