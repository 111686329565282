import { equalsIgnoreCase } from '../../../../common/operators/null-check-util';
import { ObjectTypeFromArray } from '../../../../common/types/misc';
import { CRM_RELEVANT_FIELDS_META } from '../../data-objects';
import { bestMailFieldCalculator } from '../../util';
import { CRMDataObject, DataObjectFactory, ICRMDataObjectWithOriginalDO } from '../crm-data-object';

export const GW_RELEVANT_FIELDS_META = [
  ...CRM_RELEVANT_FIELDS_META,
  'GWSALESACTIVITYTYPEALLOWED',
  'CBMAIL',
] as const;

export const GW_RELEVANT_FIELDS = GW_RELEVANT_FIELDS_META as unknown as string[];

export type GWObjectType = ObjectTypeFromArray<typeof GW_RELEVANT_FIELDS_META>;

export class GWDataObject extends CRMDataObject<GWObjectType> implements ICRMDataObjectWithOriginalDO<GWObjectType> {

  private readonly EMAIL_ACTIVITY_TYPE_ALLOWED = 'E-Mail';

  get originalDO(): GWObjectType {
    return this.originalDataObject;
  }

  get email(): string {
    const mailIndex = this.valueMapper<number>(this.originalDataObject.CBMAIL);
    return bestMailFieldCalculator(this.originalDataObject, mailIndex);
  }

  get christianName(): string {
    return this.valueMapper(this.originalDataObject.CHRISTIANNAME);
  }

  get lastName(): string {
    return this.valueMapper(this.originalDataObject.NAME);
  }

  get gguid(): string {
    return this.valueMapper(this.originalDataObject.GGUID);
  }

  get companyName(): string {
    return this.valueMapper(this.originalDataObject.COMPNAME);
  }

  get weNetworkUserId(): string {
    return this.valueMapper(this.originalDataObject.WENETWORKID);
  }

  get isEmailActivityAllowed(): boolean {
    const activityTypeAllowedTypes = this.valueMapper<string[]>(this.originalDataObject.GWSALESACTIVITYTYPEALLOWED) || [];
    const hasEmailAllowed = activityTypeAllowedTypes
      .some(activityTypeAllowedType => equalsIgnoreCase(activityTypeAllowedType, this.EMAIL_ACTIVITY_TYPE_ALLOWED));
    return hasEmailAllowed;
  }

}

export const gwDataObjectFactory: DataObjectFactory<GWDataObject> = {
  toCrmDataObject: (originalDo: any, isFromMassData: boolean) => {
    return new GWDataObject(originalDo, isFromMassData);
  }
};
