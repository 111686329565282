import { Injectable } from '@angular/core';
import { GWObjectType } from '../providers/gw/gw-data-object';
import { OpenObjectType } from '../providers/open/open-data-object';

@Injectable()
export class DistributionChatMessageProcessor {

  processMessage(recipient: GWObjectType | OpenObjectType, message: string) {
    if (!recipient) {
      return message;
    }
    let processedMessage = message;
    Object.keys(recipient).forEach(property => {
      const regexp = new RegExp(`{${property}}`, 'g');
      processedMessage = processedMessage.replace(regexp, recipient[property]);
    });
    return processedMessage;
  }

}
