import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { WenNativeApi } from '@portal/wen-native-api';

@Component({
  selector: 'wen-filter-view',
  templateUrl: './filter-view.component.html',
  styleUrls: ['./filter-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterViewComponent {

  isNativeApp = this.wenNativeApi.isReactNativeApp();
  @HostBinding('class.wen-filter-view') className = true;

  constructor(private wenNativeApi: WenNativeApi) {}
}
