import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { ActionMenuItemProvider } from '../../../../../core/services/util/action-menu-item.provider';
import { ChannelListItemModel } from './channel-list-item/models/list-item-model';

@Component({
  selector: 'wen-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ActionMenuItemProvider]
})
export class ChannelListComponent {

  @HostBinding('class.wen-channel-list') className = true;

  @Input() channelList: ChannelListItemModel[];
  @Input() showAfterElement: boolean;
  @Input() enableContextMenu = false;
  markdownEnabled = true;

  constructor(
    private readonly navigationHelper: WenNavigationHelper,
  ) { }

  onClick(item: ChannelListItemModel) {
    this.navigationHelper.navigateToChannelView(item.id);
  }

  trackByFn(index: number, item: ChannelListItemModel) {
    return item.id;
  }
}
