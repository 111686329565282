import { NgModule } from '@angular/core';
import { ReaderComponent } from './components/reader/reader.component';
import { RouterModule } from '@angular/router';
import { readerRoute, textViewRoute } from './reader.routes';
import { CommonModule } from '@angular/common';
import { TextViewComponent } from './components/text-view/text-view.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingIndicatorModule, WenIconModule } from '@portal/wen-components';
import { DetailHeaderModule } from '../../shared/components/detail-header/detail-header.module';
import { MatButtonModule } from '@angular/material/button';
import { TextToSpeechModule } from '../../shared/components/text-to-speech/text-to-speech.module';
import { ChannelRestrictionComponent } from '../channel/channel-view/components/channel-content-curtain/channel-restriction/channel-restriction.component';
import { WalletService } from '../wallet/providers/wallet.service';
import { SharedModule } from '../../shared/shared.module';
import { ContentErrorComponent } from '../error-page/components/content-error/content-error.component';

@NgModule({
  declarations: [
    ReaderComponent,
    TextViewComponent
  ],
  imports: [
    RouterModule.forChild([readerRoute, textViewRoute]),
    CommonModule,
    TranslateModule,
    WenIconModule,
    DetailHeaderModule,
    MatButtonModule,
    TextToSpeechModule,
    ChannelRestrictionComponent,
    ContentErrorComponent,
    LoadingIndicatorModule,
    SharedModule
  ],
  exports: [
    ReaderComponent,
    TextViewComponent
  ],
  providers: [
    WalletService,
  ]
})
export class ReaderModule {

}

