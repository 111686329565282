
export type OlmDeviceModel = {
  algorithm: DeviceKeyAlgorithm;
  deviceId: string;
  userId: string;
  key: string;
};

export type OlmOneTimeKeys = {
  userId: string;
  deviceId: string;
  keys: {
    [id: string]: string;
  };
};

export enum EncryptionAlgorithm {
  Megolm = 'megolm.v1.aes-sha2',
  Olm = 'olm.v1.curve25519-aes-sha2',
}

export enum DeviceKeyAlgorithm {
  curve25519 = 'curve25519',
  ed25519 = 'ed25519'
}

export type OlmEncryptedMessage = {
  type: number;
  body: string;
};
