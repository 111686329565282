import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ForbiddenForAnonymousUserDialogService } from '../../../shared/services/forbidden-for-anonymous-user-dialog.service';
import { WenNavigationHelper } from '../../../core/services/navigation/types';
import { WalletCredentialType } from '@portal/wen-backend-api';

@Injectable()
export class WalletService {

  constructor(
    private router: Router,
    private dialogOpener: ForbiddenForAnonymousUserDialogService,
    private navigationHelper: WenNavigationHelper,
  ) {
  }

  public handleWalletRestrictionButton(credentialType: WalletCredentialType) {
    if (!this.dialogOpener.isAnonymous) {
      const queryParams: Params = {returnUrl: this.router.routerState.snapshot.url};
      this.navigationHelper.navigateToWalletCreateCredential(credentialType, {queryParams});
    } else {
      this.dialogOpener.openWalletCredentialCreationForbiddenWhenAnonymous().subscribe();
    }
  }
}

