import { createNgModule, ErrorHandler, inject, Injectable, Injector } from '@angular/core';
import { WenNativeApi } from '@portal/wen-native-api';
import { catchError, from, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { WEN_TRACER_CONFIG } from '../config-tokens';
import { TracerRuntimeParams, WenTracerConfig } from '../config-types';
import { initSentry } from '../sentry/init-sentry';
import { SentryModuleType } from '../sentry/sentry-utils';
import { ENSURE_SENTRY_LIB_LOADED, getSentryIfLoaded } from '../sentry/sentry-wrapper';
import { GET_LAZY_MODULE } from '../util/module-loaders';
import { TracerErrorHandler } from './tracer-error-handler';

@Injectable()
export class SentryLoader {

  private injector = inject(Injector);
  private tracerErrorHandler = inject(ErrorHandler);
  private nativeApi = inject(WenNativeApi);

  private detachCurrentSentryClient: () => Observable<boolean>;

  attach(runtimeParams?: TracerRuntimeParams) {
    const config = this.injector.get<WenTracerConfig>(WEN_TRACER_CONFIG);
    if (!config) {
      return of(false);
    }
    ENSURE_SENTRY_LIB_LOADED();
    const loadSentry$ = getSentryIfLoaded().pipe(
      switchMap((sentryModule) => {
        if (sentryModule.isInitialized()) {
          return this.reAttach(sentryModule);
        }
        return this.firstAttach();
      }),
      shareReplay(1)
    );
    loadSentry$.subscribe((sentryModule) => {
      this.detachCurrentSentryClient = () => {
        if (this.tracerErrorHandler instanceof TracerErrorHandler) {
          this.tracerErrorHandler.changeDelegate(new ErrorHandler());
        }
        this.disposeSensitiveData(sentryModule);
        return from(sentryModule.close());
      };
      this.setupSensitiveDataSending(sentryModule, runtimeParams);
      const sentryErrorHandler = sentryModule.createErrorHandler({ showDialog: false });
      this.injector.get(sentryModule.TraceService);
      if (this.tracerErrorHandler instanceof TracerErrorHandler) {
        this.tracerErrorHandler.changeDelegate(sentryErrorHandler);
      }
    });
    return loadSentry$.pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  private firstAttach() {
    return from(GET_LAZY_MODULE.wenSentry()).pipe(
      switchMap((wenSentryModuleNs) => {
        const sentryModule = wenSentryModuleNs.WenSentryModule;
        const config = this.injector.get<WenTracerConfig>(WEN_TRACER_CONFIG);
        const sentryInit$ = from(initSentry(config, this.nativeApi));
        createNgModule(sentryModule, this.injector);
        return sentryInit$;
      })
    );
  }

  private reAttach(sentryModule: SentryModuleType) {
    const sentryClient = sentryModule.getClient();
    sentryClient.getOptions().enabled = true;
    return of(sentryModule);
  }

  private setupSensitiveDataSending(sentryModule: SentryModuleType, runtimeParams?: TracerRuntimeParams) {
    const config = this.injector.get<WenTracerConfig>(WEN_TRACER_CONFIG);
    const isEnabled = config.enableSensitiveDataSending;
    if (isEnabled) {
      const userId = runtimeParams?.userData?.userId;
      sentryModule.setUser({ id: userId });
    } else {
      this.disposeSensitiveData(sentryModule);
    }
  }

  private disposeSensitiveData(sentryModule: SentryModuleType) {
    sentryModule.setUser(null);
  }

  detach() {
    if (this.detachCurrentSentryClient) {
      return this.detachCurrentSentryClient().pipe(
        tap(() => this.detachCurrentSentryClient = null)
      );
    }
    return of(false);
  }

}
