import { createAction, props } from '@ngrx/store';
import { LoginParams, LogoutParams } from '../../services/user-management/wen-oauth.service';
import { UserData } from './models/UserData';

export const authenticate = createAction(
  '[Auth] Authenticate from JWT token or init oauth code flow',
  (params: LoginParams) => params
);

export const tryLogin = createAction(
  '[Auth] Process oauth code from url or storage and log in'
);

export const logout = createAction(
  '[Auth] Log out the current user',
  (params?: LogoutParams) => params
);

export const initiateAppForUser = createAction(
  '[Auth] Initiate app after login success for the specified user',
  props<{ userData: UserData; redirectToWelcome?: boolean; redirectToExclusiveInvite?: boolean; redirectUrl?: string }>()
);

export const clearStoreData = createAction(
  '[Auth] Clear store data of the current user',
  (params?: LogoutParams) => params
);
