import { NgModule, StaticProvider } from '@angular/core';
import { MASS_DATA_CONFIG, MassDataConfigEntry, MassDataModule, OffsetBasedMassDataPagingAdapter } from '@portal/wen-data-core';
import { contactsSearchMassDataIdentifier } from '../constants';
import { ContactsSearchMassDataAdapter } from './contacts-search-mass-data-adapter';
import { ContactsSearchMassDataContainer } from './contacts-search-mass-data-container';
import { ContactsSearchMassDataRepository, ExistingContactsSearchMassDataRepository, FurtherContactsSearchMassDataRepository } from './contacts-search-mass-data-repository';
import { ContactSearchMassDataUpdatesPlugin } from './contacts-search-mass-data-updates';

const contactsSearchConfig: MassDataConfigEntry = {
  identifier: contactsSearchMassDataIdentifier,
  container: ContactsSearchMassDataContainer,
  adapter: ContactsSearchMassDataAdapter,
  repository: ContactsSearchMassDataRepository,
  pagingAdapter: OffsetBasedMassDataPagingAdapter,
  updatesAdapter: ContactSearchMassDataUpdatesPlugin,
};

const CONTACTS_SEARCH_CONTAINER_CONFIG: StaticProvider = {
  provide: MASS_DATA_CONFIG,
  multi: true,
  useValue: contactsSearchConfig
};

@NgModule({
  imports: [
    MassDataModule,
  ],
  providers: [
    ContactsSearchMassDataContainer,
    ContactsSearchMassDataAdapter,
    ExistingContactsSearchMassDataRepository,
    FurtherContactsSearchMassDataRepository,
    ContactsSearchMassDataRepository,
    ContactSearchMassDataUpdatesPlugin,
    CONTACTS_SEARCH_CONTAINER_CONFIG
  ]
})
export class ContactsSearchMassDataModule { }
