import { WenRoutes } from '../../../frame/routing/types';
import { contactsListRoute, pendingContactsListRoute } from './contacts.routes';

export const dialogOutletContactsRoutes: WenRoutes = [
  contactsListRoute,
  pendingContactsListRoute,
  {
    path: '**',
    redirectTo: '/home/dashboard'
  }
];
