@if (mediaViewParams$ | async; as viewParams) {
  <div class="wen-media-wrapper" [thumbnail-evaluator]="viewParams">
    @switch (viewParams.embedType) {
      @case (mediaType.PICTURE) {
        <wen-embed-image [config]="viewParams" (mediaClick)="onMediaClick($event)"></wen-embed-image>
      }
      @case (mediaType.VIDEO) {
        <wen-embed-video [config]="viewParams" (mediaClick)="onMediaClick($event)"></wen-embed-video>
      }
      @case (mediaType.AUDIO) {
        <wen-embed-audio [config]="viewParams"></wen-embed-audio>
      }
      @case (mediaType.UPLOAD) {
        <wen-embed-upload [config]="viewParams"></wen-embed-upload>
      }
      @case (mediaType.PROCESS) {
        <wen-embed-upload [config]="viewParams"></wen-embed-upload>
      }
      @case (mediaType.ERROR) {
        <wen-embed-error [config]="viewParams"></wen-embed-error>
      }
      @case (mediaType.DOCUMENT) {
        <wen-embed-file [config]="viewParams"></wen-embed-file>
      }
    }
  </div>
}
