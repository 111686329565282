import { Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { OverlayManager } from '@portal/wen-components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PhoneAreaInfo, PhoneNumberValue } from '../../types/form-types';
import { isGermanAreaCode } from '../../util/phone-utils';
import { PhoneNumberAreaPickerComponent, PhoneNumberAreaPickerData, PhoneNumberAreaPickerResult } from './phone-number-area-picker/phone-number-area-picker.component';

export const WEN_PHONE_NUMBER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhoneNumberControlComponent),
  multi: true
};

@Component({
  selector: 'wen-phone-number-control',
  templateUrl: './phone-number-control.component.html',
  styleUrls: ['./phone-number-control.component.scss'],
  providers: [
    WEN_PHONE_NUMBER_CONTROL_VALUE_ACCESSOR
  ]
})
export class PhoneNumberControlComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private onDestroy = new Subject<void>();

  @HostBinding('class.wen-phone-number-control') className = true;

  @ViewChild('numberInput', { read: ElementRef }) numberInput: ElementRef<HTMLInputElement>;

  @Input() placeholder: string;
  @Input() availableCountries: PhoneAreaInfo[];

  onChanged: (value: any) => void = () => { };
  onTouched: () => any = () => { };

  modelValue: PhoneNumberValue;

  phoneNumberControl = new FormControl('', [
    Validators.required
  ]);

  constructor(
    private control: ControlContainer,
    private overlayManager: OverlayManager,
  ) {
  }

  ngOnInit(): void {
    const allValidators = Validators.compose([
      this.control.control.validator,
      () => this.phoneNumberControl.errors
    ]);
    this.control.control.setValidators(allValidators);
    this.control.statusChanges.pipe(
      takeUntil(this.onDestroy)
    ).subscribe(() => {
      this.phoneNumberControl.updateValueAndValidity();
      if (this.phoneNumberControl.errors) {
        this.phoneNumberControl.markAsTouched();
      }
    });
  }

  updateModel() {
    let phoneNumber = this.numberInput.nativeElement.value;
    if (phoneNumber && phoneNumber.startsWith('0') && isGermanAreaCode(this.modelValue.areaInfo)) {
      phoneNumber = phoneNumber.slice(1);
      this.phoneNumberControl.setValue(phoneNumber, { emitEvent: false, onlySelf: true });
    }
    const newModel: PhoneNumberValue = {
      phoneNumber,
      areaInfo: this.modelValue.areaInfo
    };
    this.onChanged(newModel);
    this.modelValue = newModel;
  }

  openAreaPicker() {
    const data: PhoneNumberAreaPickerData = {
      selected: this.modelValue.areaInfo,
      availableCountries: this.availableCountries
    };
    this.overlayManager.dialog.openInputDialog<PhoneNumberAreaPickerComponent, PhoneNumberAreaPickerData, PhoneNumberAreaPickerResult>(
      PhoneNumberAreaPickerComponent, data
    ).afterClosed().subscribe(result => {
      if (!result?.selected) {
        return;
      }
      this.modelValue.areaInfo = result.selected;
    });
  }

  writeValue(value: PhoneNumberValue): void {
    this.modelValue = value;
    this.phoneNumberControl.setValue(this.modelValue.phoneNumber, { emitEvent: false, onlySelf: true });
  }

  registerOnChange(fn: (value: PhoneNumberValue) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      throw new Error('Disabled state not implemented');
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
