import { CategoriesDTO } from '../filtering/filtering';
import { WithNetworkMeta } from '../network/network-meta';
import { ChannelInviteDataDTO } from './channel-invites';

export interface ChannelDTO {
  id: string;
  title: string;
  icon?: string;
  description: string;
  subscriptions: number;
  isMuted: boolean;
  discoverable?: boolean;
  availableForOthers?: boolean;
  emoji?: boolean;
  comment?: boolean;
  restricted: boolean;
  deleted?: boolean;
  deletedReason?: string;
  invite?: ChannelInviteDataDTO;
  categories?: CategoriesDTO;
  isPrivate?: boolean;
  geo?: boolean;
  readingContent?: boolean;
  /**
   * @deprecated
   * Use network.pinTimestamp instead
   */
  pinTimestamp?: string;
  /**
   * @deprecated
   * Use networks array instead
   */
  network?: string;
  imprint?: string;
  dataProtectionUrl?: string;
  /**
   * Channel can either be a normal channel or personal channel.
   * ! NOTE ! Right now only the weather channel will get get the PERSONAL type.
   * The notificiation channel will NOT get the personal type as long as we
   * not fully switch to the new personal channel concept!
   */
  channelType?: ChannelType;
}

export type ChannelSubscriptionDTO = ChannelDTO & WithNetworkMeta;

export enum ChannelType {
  NORMAL = 'NORMAL',
  PERSONAL = 'PERSONAL'
}
