import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { CodeVerificationFieldComponent } from './code-verification-field.component';
import { CodeVerificationResendButtonComponent } from './code-verification-resend-button/code-verification-resend-button.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
  ],
  declarations: [
    CodeVerificationFieldComponent,
    CodeVerificationResendButtonComponent,
  ],
  exports: [
    CodeVerificationFieldComponent,
  ]
})
export class CodeVerificationFieldModule { }
