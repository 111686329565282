@if (autoReplyEnable$ | async) {
  <div class="wen-auto-reply">
    <wen-icon
      iconName="auto_reply"
      class="wen-auto-reply-icon wen-icon-small"
      color="orange"
    ></wen-icon>
    <h3 class="wen-auto-reply-text" [innerHTML]="(autoReplyText$ | async) | removeMarkdown"></h3>
  </div>
}
