import { NgModule } from '@angular/core';
import { OnboardingStartModule } from './onboarding-start/onboarding-start.module';

@NgModule({
  imports: [
    OnboardingStartModule,
  ],
  exports: [
    OnboardingStartModule,
  ],
})
export class OnboardingModule { }
