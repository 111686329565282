<mat-form-field (tap)="datepicker.open()">
  <div class="wen-datepicker-wrapper">
    <input
      matInput
      [matDatepicker]="datepicker"
      [placeholder]="placeholder"
      [min]="min"
      [max]="max"
      [formControl]="formControl"
      (dateChange)="onDateChange($event)"
      readonly="true"
      />
      @if (showIcon) {
        <wen-icon [iconName]="iconName" [color]="iconColor"></wen-icon>
      }
      <mat-datepicker #datepicker></mat-datepicker>
    </div>
  </mat-form-field>
