import { NgModule } from '@angular/core';
import { ConfirmationDialogModule } from '@portal/wen-components';
import { ActionSelectorModule } from './action-selector/action-selector.module';
import { ChatPreviewModule } from './chat-preview/chat-preview.module';
import { CrmChatModule } from './chat/crm-chat.module';
import { CRMClosingDialogModule } from './crm-closing-dialog/crm-closing-dialog.module';
import { DistributeChatModule } from './distribute-chat/distribute-chat.module';
import { ProfileReadOnlyViewModule } from './logout/profile-read-only-view.module';

@NgModule({
  imports: [
    ActionSelectorModule,
    DistributeChatModule,
    CrmChatModule,
    ProfileReadOnlyViewModule,
    ConfirmationDialogModule,
    CRMClosingDialogModule,
    ChatPreviewModule,
  ],
  exports: [
    ActionSelectorModule,
    DistributeChatModule,
  ]
})
export class CrmViewModule { }
