import { NgModule } from '@angular/core';
import { MessageSeparatorDirective } from './message-separator.directive';
import { MessagesViewBeforeContentDirective } from './messages-view-before-content.directive';

@NgModule({
  declarations: [
    MessageSeparatorDirective,
    MessagesViewBeforeContentDirective
  ],
  exports: [
    MessageSeparatorDirective,
    MessagesViewBeforeContentDirective
  ]
})
export class MessageSeparatorModule { }
