import { Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { ConfirmationDialogActionHandler } from '@portal/wen-components';
import { Observable, Subject, distinctUntilChanged, first } from 'rxjs';
import { SmartDesignApi } from '../../../../../core/services/smartdesign/smartdesing-api';
import { RootState } from '../../../../../core/store/root/public-api';
import { inviteToNetworkFromSmartdesign, loadImportedSelectionData } from '../../../../../core/store/smartdesign/smartdesign.actions';
import { selectCreateChatSelection } from '../../../../../core/store/smartdesign/smartdesign.selectors';
import { selectProgressState } from '../../../../../core/store/ui/ui.selectors';
import { ProgressState } from '../../../../../core/store/ui/ui.state';

@Component({
  selector: 'wen-invite-chat-creation-dialog',
  templateUrl: './invite-chat-creation-dialog.component.html',
  styleUrls: ['./invite-chat-creation-dialog.component.scss'],
  providers: [
    {
      provide: ConfirmationDialogActionHandler,
      useExisting: forwardRef(() => InviteChatCreationDialogComponent)
    }
  ]
})
export class InviteChatCreationDialogComponent implements OnInit, OnDestroy, ConfirmationDialogActionHandler {

  private onDestroy$ = new Subject<void>();

  hasProgress$: Observable<ProgressState>;

  inviteFormGroup = new FormGroup({
    emailBody: new FormControl(''),
    importedInvitees: new FormControl([]),
  });

  onlyNetworkInvitees: boolean;

  get importedInviteesControl() {
    return this.inviteFormGroup.controls.importedInvitees;
  }

  get emailBodyControl() {
    return this.inviteFormGroup.controls.emailBody;
  }

  constructor(
    private store: Store<RootState>,
    private smartDesignApi: SmartDesignApi,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(loadImportedSelectionData());
    this.store.pipe(
      select(selectCreateChatSelection),
      first()
    ).subscribe((data) => {
      const { networkInviteableEntities, chatCreatableEntities } = data;
      this.onlyNetworkInvitees = !Boolean(chatCreatableEntities?.length);
      this.importedInviteesControl.setValue(networkInviteableEntities);
    });
    this.hasProgress$ = this.store.pipe(
      select(selectProgressState),
      distinctUntilChanged(),
    );
  }

  onAccept() {
    this.store.dispatch(inviteToNetworkFromSmartdesign({
      importedInvitees: this.importedInviteesControl.value,
      emailBody: this.emailBodyControl.value
    }));
  }

  onDismiss() {
    this.smartDesignApi.closeDialog();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
