@if (quote$ | async; as quote) {
  <div class="wen-quote-preview-container">
    <wen-legacy-quote class="wen-preview"
      [@slideInSlideOutTop]="'show'"
      [commentAuthor]="quote.quotedAuthor"
      [comment]="quote.quotedText"
      [showCloseIcon]=true
      (closed)="closeQuotePreview()">
    </wen-legacy-quote>
  </div>
}
