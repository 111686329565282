import { Injectable } from '@angular/core';
import { matchFirstRegExpGroup } from '../../../../common/util/match-first-regexp';
import { textViewMatcher } from '../deep-link-regexes';
import { DeepLinkable } from '../deep-linkable';
import { textViewIdentifier } from '../../../../../views/reader/tokens';

@Injectable()
export class TextViewDeepLinkable extends DeepLinkable {

  isMatchForUrl(link: string) {
    return Boolean(this.extractRouteLink(link));
  }

  extractRouteLink(link: string) {
    const match = matchFirstRegExpGroup(link, textViewMatcher());
    return match;
  }

  getDeepLink(params: Record<typeof textViewIdentifier, string>) {
    const idParam = params[textViewIdentifier];
    if (!idParam) {
      return null;
    }
    return `${this.rootUrl}/reader/textview/${idParam}`;
  }

  getOpenerTranslation(): string {
    return 'EMBED_OPEN_CHANNEL_ARTICLE';
  }
}
