import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogData, OverlayManager } from '@portal/wen-components';
import { type IUserInfo } from '@smartdesign/data-access';
import type { SmartDesign } from '@smartdesign/web-api';
import { BehaviorSubject, catchError, filter, first, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { CRMClosingDialogComponent, CRMClosingDialogData } from '../../../views/crm/crm-closing-dialog/crm-closing-dialog.component';
import { firstExisty } from '../../common/operators/first-existy';
import { switchMapFirst } from '../../common/operators/switch-map-first';
import { RootState } from '../../store/root/public-api';
import { selectSmartDesignState } from '../../store/smartdesign/smartdesign.selectors';
import { DistributionMessageDTO, DistributionRecipientDTO } from './chat-distribution/distribution-types';
import { CRMBackendType, crmBackendUtil } from './helpers/evaluate-backend-type';
import { GWDataObject, GWObjectType } from './providers/gw/gw-data-object';
import { GWSelectionProvider } from './providers/gw/gw-selection-provider';
import { OpenDataObject, OpenObjectType } from './providers/open/open-data-object';
import { OpenSelectionProvider } from './providers/open/open-selection-provider';

@Injectable()
export class SmartDesignApi {

  private isLoading = false;
  private sdApi = new BehaviorSubject<SmartDesign.IAPI<any>>(null);
  private crmBackendType: CRMBackendType;

  constructor(
    private store: Store<RootState>,
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
  ) { }

  createSelectionProvider() {
    switch (this.crmBackendType) {
      case CRMBackendType.GW:
        return new GWSelectionProvider(this.getApi());
      case CRMBackendType.OPEN:
        return new OpenSelectionProvider(this.getApi());
      default:
        this.showErrorDialog();
        throw new Error('Unknown crm');
    }
  }

  ensureApiLoaded() {
    if (this.sdApi.getValue() || this.isLoading) {
      return this.sdApi.pipe(
        firstExisty()
      );
    }
    this.isLoading = true;
    this.store.pipe(
      select(selectSmartDesignState),
      filter((smartDesignState) => smartDesignState?.isFromSmartDesign),
      first(),
      switchMap((smartDesignState) => {
        const { sdHostParam, backendType } = smartDesignState || {};
        return from(this.loadApi(sdHostParam)).pipe(map(sdApi => ({ sdApi, backendType })));
      }),
      catchError((e) => {
        console.error(e);
        return of(null);
      }),
      switchMapFirst(({ sdApi, backendType }) => {
        if (!backendType) {
          return from(crmBackendUtil.evaluateCrmBackendType(sdApi)).pipe(
            map(fallbackBackendType => ({ sdApi, backendType: fallbackBackendType }))
          );
        }
        return of({ sdApi, backendType });
      }),
      tap(({ sdApi, backendType }) => {
        if (!sdApi || !backendType) {
          this.showErrorDialog();
        }
      }),
    ).subscribe(({ sdApi, backendType }) => {
      this.crmBackendType = backendType;
      this.sdApi.next(sdApi);
      this.isLoading = false;
    });
    return this.sdApi.pipe(
      firstExisty(),
    );
  }

  private async loadApi(host: string) {
    const smartdesign = await import('@smartdesign/web-api');
    return await smartdesign.connect(host || '*');
  }

  private getApi() {
    return this.sdApi.getValue();
  }

  private showErrorDialog() {
    const data: CRMClosingDialogData = {
      image: '/assets/wen-widget/icons/error_phone.svg',
      title: this.translateService.instant('CRM_GENERAL_ERROR'),
      message: this.translateService.instant('CRM_GENERAL_ERROR_INFO_MESSAGE'),
      acceptLabel: this.translateService.instant('CRM_GENERAL_ERROR_LABEL'),
    };
    this.overlayManager.dialog.openConfirmationDialog(CRMClosingDialogComponent, data as ConfirmationDialogData);
  }

  loadDistributionMessage(guid: string): Observable<DistributionMessageDTO> {
    const message = this.getApi().fetch(`v7.0/wenetworkmailing/${guid}/metadata`)
      .then(response => response.json() as unknown as DistributionMessageDTO);

    return from(message);
  }

  loadDistributionMessageRecipient(guid: string, wenetworkId: string): Observable<DistributionRecipientDTO> {
    const recipients = this.getApi().fetch(`v7.0/wenetworkmailing/${guid}/recipients?wenetworkid=${wenetworkId}`)
      .then(response => response.json() as unknown as { recipients: (GWObjectType | OpenObjectType)[] })
      .then(response => response.recipients.length ? response.recipients[0] : null)
      .then(crmRecipient => Boolean(crmRecipient) ? this.convertToCRMDataObject(crmRecipient) : null);
    return from(recipients);
  }

  loadDistributionMessageRecipients(guid: string, page: number, pageSize: number): Observable<DistributionRecipientDTO[]> {
    const recipients = this.getApi().fetch(`v7.0/wenetworkmailing/${guid}/recipients?entries-per-page=${pageSize}&page=${page}`)
      .then(response => response.json() as unknown as { recipients: (GWObjectType | OpenObjectType)[] })
      .then(response => response?.recipients)
      .then(crmRecipients => Boolean(crmRecipients) ? crmRecipients.map(recipient => this.convertToCRMDataObject(recipient)) : null);
    return from(recipients);
  }

  loadDistributionMessageAttachment(mailGUID: string, attachmentGUID: string): Observable<{ blob: Blob; extension: string }> {
    let attachmentFetch$: Promise<Response>;
    if (this.crmBackendType === CRMBackendType.GW) {
      attachmentFetch$ = this.getApi().fetch(`v7.0/type/document/${attachmentGUID}/file`);
    } else {
      attachmentFetch$ = this.getApi().fetch(`v7.0/wenetworkmailing/${mailGUID}/attachment/${attachmentGUID}`);
    }
    const attachment$ = attachmentFetch$.then(response => {
      const extension = response.headers.get('CAS-FILE-EXTENSION') ?? 'unknown';
      return response.blob().then((blob => ({ blob, extension })));
    });

    return from(attachment$);
  }

  sendDistributeChatMessageSendResult(guid: string, result: { deliveredWeNetworkIds: string[]; failedWeNetworkIds: string[] }) {
    const postResult = this.getApi().fetch(`v7.0/wenetworkmailing/${guid}/result`, { method: 'POST', body: JSON.stringify(result) });
    return from(postResult);
  }

  getTenantId() {
    if (this.crmBackendType === CRMBackendType.GW) {
      const tenantIdGW = this.getApi().fetch('v6.0/user/self')
        .then(user => user.json() as unknown as IUserInfo)
        .then((userData => userData.fields.CLIENT));
      return from(tenantIdGW);
    }
    const tenantIdOpen = this.getApi().fetch('v6.0/user/self/tenant')
      .then(user => user.json() as unknown as { gguid: string })
      .then((userData => userData.gguid));
    return from(tenantIdOpen);
  }

  closeDialog() {
    this.getApi().Navigation.navigateBack();
  }

  private convertToCRMDataObject(dataObject: GWObjectType | OpenObjectType): DistributionRecipientDTO {
    if (this.crmBackendType === CRMBackendType.GW) {
      return new GWDataObject(dataObject as GWObjectType, true);
    } else {
      return new OpenDataObject(dataObject as OpenObjectType, true);
    }
  }

}
