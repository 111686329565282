import { Directive, HostBinding, Input } from '@angular/core';
import { PaletteProvider } from './palette-provider';

@Directive({
  selector: '[wenBackgroundColorizer]'
})
export class BackgroundColorizerDirective {

  @Input('wenBackgroundColorizer') set seed(newSeed: string) {
    this.gradient = this.wenPaletteProvider.getGradientFor(newSeed);
  }
  @HostBinding('style.background') gradient: string;

  constructor(
    private wenPaletteProvider: PaletteProvider
  ) { }

}
