import { CdkScrollable } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { share } from 'rxjs';

@Injectable()
export class DetailHeaderScroller {

  readonly scrollSub$ = this.scroller.elementScrolled().pipe(
    share()
  );
  readonly scrollElement = this.scroller.getElementRef().nativeElement;

  constructor(private scroller: CdkScrollable) { }

  getScrollOffset(direction: Parameters<CdkScrollable['measureScrollOffset']>[0]): number {
    return this.scroller.measureScrollOffset(direction);
  }
}
