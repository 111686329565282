export const memoizedResult = <T>(
  valueFn: () => T
) => {
  let cachedValue: T;
  return {
    get: () => {
      if (!cachedValue) {
        cachedValue = valueFn();
      }
      return cachedValue;
    }
  };
};
