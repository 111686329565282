import { NgModule } from '@angular/core';
import { InviteCommonModule } from './common/invite-common.module';
import { InviteToChannelModule } from './invite-to-channel/invite-to-channel.module';
import { InviteViewModule } from './invite-view/invite-view.module';

@NgModule({
  imports: [
    InviteCommonModule,
    InviteViewModule,
    InviteToChannelModule,
  ],
  exports: [
    InviteCommonModule,
    InviteViewModule,
    InviteToChannelModule,
  ],
})
export class InviteModule { }
