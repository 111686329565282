import { EncryptionAlgorithm, OlmEncryptedMessage } from './olm/olm';
import { SendMessageContent } from './send-to-room/send-message';
import { ExchangeRoomKeyRequestContent } from './send-to-users/exchange-room-key-request';
import { ExchangeRoomKeyResponseContent } from './send-to-users/exchange-room-key-response';
import { ShareKeyContent } from './send-to-users/share-key';

export type NEEDS_DECRYPTION = boolean;

export enum EncryptionType {
  ENCRYPTED = 'ROOM.ENCRYPTED',
  PLAIN = 'ROOM.PLAIN',
}

export enum ToRoomEventType {
  SEND_MESSAGE = 'ROOM.SEND_MESSAGE',
  UPDATED = 'ROOM.UPDATED',
  LEFT = 'ROOM.LEFT',
  JOINED = 'ROOM.JOINED',
  OWNERSHIP_GRANTED = 'ROOM.OWNERSHIP_GRANTED',
  OWNERSHIP_REVOKED = 'ROOM.OWNERSHIP_REVOKED',
  REDACTION = 'ROOM.REDACTION',
  AUTO_REPLY = 'ROOM.AUTO_REPLY',
  EDIT_MESSAGE = 'ROOM.EDIT_MESSAGE',
  QUOTE_MESSAGE = 'ROOM.QUOTE_MESSAGE'
}

export enum ToUsersEventType {
  /**
   * A MegOlm session key used to estabilish room conversations
   */
  SHARE_KEY = 'ROOM.SHARE_KEY',
  /**
   * A request to share specific session export between the devices
   */
  EXCHANGE_ROOM_KEY_REQUEST = 'ROOM.EXCHANGE_ROOM_KEY_REQUEST',
  /**
   * Response to {@link EXCHANGE_ROOM_KEY_REQUEST} containing the encrypted export of the session keys
   */
  EXCHANGE_ROOM_KEY_RESPONSE = 'ROOM.EXCHANGE_ROOM_KEY_RESPONSE',
}

export type PossibleEventTypes = ToUsersEventType | ToRoomEventType;

/**
 * Olm encrypted message
 */
export type OlmMessage = {
  algorithm: EncryptionAlgorithm.Olm;
  senderKey: string;
  ciphertext: OlmEncryptedMessage;
};

/**
 * MegOlm encrypted message
 */
export type MegOlmMessage = {
  algorithm: EncryptionAlgorithm.Megolm;
  senderKey: string;
  sessionId: string;
  deviceId: string;
  ciphertext: OlmEncryptedMessage;
};

export type PossibleToRoomMessageContent = SendMessageContent<any> | string;

/**
 * Base for a message
 */
export type ToRoomMessageDTO<E extends EncryptionType, T extends ToRoomEventType, C extends PossibleToRoomMessageContent> = {
  type: E;
  eventType: T;
  content: C;
  senderUserId: string;
  roomId: string;
};

export type PossibleToUserMessageContent = ShareKeyContent<any> | ExchangeRoomKeyResponseContent<any> | ExchangeRoomKeyRequestContent;

/**
 * Base for a message
 */
export type ToUserMessageDTO<E extends EncryptionType, T extends ToUsersEventType, C extends PossibleToUserMessageContent> = {
  type: E;
  eventType: T;
  content: C;
  senderUserId: string;
};
