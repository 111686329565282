<div class="wen-phone-number-control-content">
  @if (modelValue?.areaInfo; as areaInfo) {
    <div class="wen-registration-start-country-selector" matRipple
      (click)="openAreaPicker()">
      <div class="wen-registration-start-country-selector-name">{{ modelValue.areaInfo.name }}</div>
      <wen-icon class="wen-registration-start-country-arrow" [iconName]="'chevron_right'"></wen-icon>
    </div>
  }
  <div class="wen-phone-number-control-input-wrapper">
    @if (modelValue?.areaInfo; as areaInfo) {
      <div class="wen-phone-number-control-areainfo-wrapper">
        <div class="wen-phone-number-control-areainfo" matRipple (click)="openAreaPicker()">
          <div portal-caption class="wen-phone-number-control-country-sign">{{ areaInfo.countrySign }}</div>
          <div portal-body-2 class="wen-phone-number-control-country-areacode">{{ areaInfo.areaCode }}</div>
        </div>
      </div>
    }
    <mat-form-field>
      <mat-label>{{ 'REGISTRATION_START_PHONE_NUMBER_LABEL' | translate }}</mat-label>
      <input #numberInput class="wen-phone-number-control-input" matInput autocomplete="off" type="tel" pattern="[0-9][0-9]{4,20}"
        [formControl]="phoneNumberControl" [placeholder]="'REGISTRATION_START_PHONE_NUMBER_PLACEHOLDER' | translate"
        (change)="updateModel()" wenIosKeyboardStickyTarget />
        <mat-error portal-caption>{{ "REGISTRATION_START_PHONE_NUMBER_INVALID" | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>