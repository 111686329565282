import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EmbeddedLinkDTO, isDocumentEmbed, isLinkEmbed, SocketIoService } from '@portal/wen-backend-api';
import { debounceTime, distinctUntilChanged, filter, of, Subject, switchMap, takeUntil } from 'rxjs';
import { DateUtil } from '../../../core/common/date/date-util';
import { mapWithFirstFrom } from '../../../core/common/operators/map-with-first-from';
import { selectCurrentUserData } from '../../../core/store/auth/auth.selectors';
import { RootState } from '../../../core/store/root/public-api';

type InputChanges = Record<'disabled' | 'previewContent' | 'orientation', SimpleChange>;

type LinkPreviewInputType = string | EmbeddedLinkDTO;

@Component({
  selector: 'wen-link-embed-preview',
  templateUrl: './link-embed-preview.component.html',
  styleUrls: ['./link-embed-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkEmbedPreviewComponent implements OnInit, OnDestroy, OnChanges {

  private onDestroy$ = new Subject<void>();
  private fetchPreview$ = new Subject<string>();

  previewData: EmbeddedLinkDTO;

  @Input() orientation: 'vertical' | 'horizontal' = 'horizontal';
  @Input() disabled: boolean;
  @Input() previewContent: LinkPreviewInputType;

  @Output() previewChanged = new EventEmitter<EmbeddedLinkDTO>();

  @HostBinding('class.wen-link-embed-preview-horizontal') get hStyle() { return this.orientation === 'horizontal'; }
  @HostBinding('class.wen-link-embed-preview-vertical') get vStyle() { return this.orientation === 'vertical'; }
  @HostBinding('class.wen-link-embed-empty') get emptyStyle() { return !this.previewData; }

  get fixWidth() {
    return this.hStyle ? 75 : null;
  }

  get isFilledDismissIcon() {
    return Boolean(this.vStyle && this.previewData.thumbnail?.url);
  }

  constructor(
    private readonly store: Store<RootState>,
    private readonly socketService: SocketIoService,
  ) { }

  ngOnChanges(changes: InputChanges): void {
    if (changes.disabled?.currentValue) {
      this.dismissLinkPreview();
    }
    if (changes.previewContent && !this.disabled) {
      const currentValue: LinkPreviewInputType = changes.previewContent.currentValue;
      if (typeof currentValue === 'string') {
        this.fetchPreview$.next(currentValue);
      } else if (!currentValue || isLinkEmbed(currentValue) || isDocumentEmbed(currentValue)) {
        this.previewData = currentValue;
        this.fetchPreview$.next(currentValue?.url);
      }
    }
  }

  ngOnInit(): void {
    this.fetchPreview$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(() => !this.disabled),
      mapWithFirstFrom(() => this.store.pipe(select(selectCurrentUserData))),
      switchMap(([content, { userId }]) => {
        if (!content) {
          return of(null);
        }
        const payload = { userId, content, timestamp: DateUtil.currentDateString() };
        return this.socketService.channel.messagePreview.acknowledgement$(payload);
      }),
      takeUntil(this.onDestroy$),
    ).subscribe((previewData) => {
      const embed = previewData?.embed;
      this.previewData = embed;
      this.previewChanged.emit(embed);
    });
  }

  dismissLinkPreview() {
    this.previewData = null;
    this.previewChanged.emit(null);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
