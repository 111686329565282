<vg-controls (pointerdown)="onPointerDown($event)">
  <vg-time-display vgFor="lightbox-video"></vg-time-display>
  <vg-scrub-bar vgFor="lightbox-video" (pointerup)="onPointerUp($event)">
    <vg-scrub-bar-current-time [vgSlider]="true"></vg-scrub-bar-current-time>
    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
  </vg-scrub-bar>
  <vg-time-display vgProperty="left" class="time-left-display" vgFor="lightbox-video">
    <span>-</span>
  </vg-time-display>
  <wen-mute [videoRef]="videoRef"></wen-mute>
</vg-controls>