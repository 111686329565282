import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { errorPageRoutes } from './error-page.routes';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { HeaderModule } from '../../frame/components/header/header.module';
import { FormProgressModule } from '../../shared/components/form-progress/form-progress.module';
import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';
import { IosUtilsModule } from '../../shared/ios-util/ios-util.module';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(errorPageRoutes),
    HeaderModule,
    FormProgressModule,
    IosUtilsModule
  ],
  providers: [
    provideLottieOptions({
      player: playerFactory
    })
  ],
  declarations: [ErrorPageComponent],
  exports: []
})
export class ErrorPageModule { }
