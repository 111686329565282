import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { ACTION_MENU_ITEMS, ActionMenuModule, FocusFirstInputModule, WenIconModule, WenOverlayModule } from '@portal/wen-components';
import { NEW_CHAT_ACTION_MENU } from '../../../core/store/header/menu/constants';
import { HeaderModule } from '../../../frame/components/header/header.module';
import { FormProgressModule } from '../../../shared/components/form-progress/form-progress.module';
import { InputWithChipsModule } from '../../../shared/components/input-with-chips/input-with-chips.module';
import { CHAT_CREATION_MESSAGE_TYPE_SELECTOR_MENU_ID } from '../../../shared/components/message-type-selector/constants/type-selector-ids';
import { MessageTypeSelectorModule } from '../../../shared/components/message-type-selector/message-type-selector.module';
import { CHAT_CREATION_MESSAGE_TYPE_ACTIONS } from '../../../shared/components/message-type-selector/providers/message-type-actions-provider';
import { ProgressiveLoadingModule } from '../../../shared/components/progressive-slot/progressive-slot.module';
import { UserSearchListModule } from '../../../shared/components/user-search-list/user-search-list.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChatMessageSendFieldModule } from '../chat-view/components/chat-message-send-field/chat-message-send-field.module';
import { ChatCreationMenuComponent } from './chat-creation-menu/chat-creation-menu.component';
import { AddChatUsersComponent } from './components/add-chat-users/add-chat-users.component';
import { ChatCreationDesktopComponent } from './components/chat-creation-desktop/chat-creation-desktop.component';
import { ChatCreationComponent } from './components/chat-creation/chat-creation.component';
import { ChatInfoEditComponent } from './components/chat-info-edit/chat-info-edit.component';
import { ChatSelectionListComponent } from './components/chat-selection-list/chat-selection-list.component';
import { ChatEditCheckAvailabilityService } from './providers/chat-edit-check-availability-service';

@NgModule({
  imports: [
    FocusFirstInputModule,
    CommonModule,
    ScrollingModule,
    HeaderModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule,
    TranslateModule.forChild(),
    WenIconModule,
    ChatMessageSendFieldModule,
    InputWithChipsModule,
    ActionMenuModule,
    MessageTypeSelectorModule.forRoot(
      { menuId: CHAT_CREATION_MESSAGE_TYPE_SELECTOR_MENU_ID, menuItems: CHAT_CREATION_MESSAGE_TYPE_ACTIONS }
    ),
    UserSearchListModule,
    ReactiveFormsModule,
    PortalFieldModule,
    FormProgressModule,
    WenOverlayModule,
    ChatSelectionListComponent,
    ProgressiveLoadingModule
  ],
  declarations: [
    ChatCreationComponent,
    ChatCreationDesktopComponent,
    AddChatUsersComponent,
    ChatInfoEditComponent,
    ChatCreationMenuComponent
  ],
  exports: [],
  providers: [
    ChatEditCheckAvailabilityService,
    { provide: ACTION_MENU_ITEMS, useValue: NEW_CHAT_ACTION_MENU, multi: true }
  ]
})
export class ChatEditModeModule { }
