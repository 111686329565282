export enum DataObjectType {
  CHANNEL = 'channel',
  CHANNEL_POST = 'channel_post',
  CHANNEL_SETTING = 'channel_setting',
  APP = 'app',
  USER = 'user',
  USER_PASSWORD = 'user_password',
  INVITE_TO_NETWORK = 'invite_to_network',
  CHAT = 'chat',
  CHAT_MEMBERS = 'chat_members',
  PRIVACY_SETTINGS = 'privacy_settings',
  INVITE_TO_CHANNEL = 'invite_to_channel',
  AUTO_REPLY = 'auto_reply',
  LANGUAGE = 'language'
}
