import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { first, switchMap } from 'rxjs';
import { createFilterableFromCategory } from '../../../../shared/components/filter-view/models/category';
import { FilterId } from '../../../common/types/filter-id';
import { FilterEntityType } from '../../filter/models/filter';
import { updateSection } from '../../sections/sections.actions';
import { SectionEntity } from '../../sections/sections.state';
import { initializeChannelFilters, updateChannelCategories } from '../channel.actions';

@Injectable()
export class ChannelFiltersEffects {
  initializeChannelCategoryFilters$ = createEffect(() => this.action$.pipe(
    ofType(initializeChannelFilters),
    first(),
    switchMap(() => {
      return this.socketIoService.channel.getCategories.acknowledgement$();
    }),
    switchMap((categories) => {
      const channelCategoriesAsSections: SectionEntity[] = categories.map(category => {
        const categoryAsFilter = createFilterableFromCategory('categories', category);
        return {
          id: category.id,
          name: category.name,
          filterRef: categoryAsFilter,
          filterType: FilterEntityType.CHANNEL_LISTS,
          filterId: FilterId.CHANNEL_CATEGORIES
        };
      });
      return [
        updateChannelCategories({ categories }),
        updateSection({ sections: channelCategoriesAsSections })
      ];
    })
  ));

  constructor(
    private action$: Actions,
    private socketIoService: SocketIoService,
  ) { }

}
