import { CdkScrollable, ExtendedScrollToOptions } from '@angular/cdk/scrolling';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, Subject, takeUntil } from 'rxjs';

@Injectable()
export class ScrollerWrapper implements OnDestroy {

  protected readonly onDestroy$ = new Subject<void>();
  protected readonly scrollPosition = new BehaviorSubject<number>(0);
  public readonly scrollPosition$ = this.scrollPosition.asObservable();

  private cdkScrollable: CdkScrollable;

  public get scroller() { return this.cdkScrollable; }

  connect(cdkScrollable: CdkScrollable) {
    this.cdkScrollable = cdkScrollable;
    this.cdkScrollable.elementScrolled().pipe(
      map((event) => {
        const target = event.target as HTMLElement;
        return target.scrollTop;
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(this.scrollPosition);
  }

  scrollTo(options: ExtendedScrollToOptions) {
    this.scroller.scrollTo(options);
  }

  measureScrollOffsets() {
    if (!this.cdkScrollable) {
      return null;
    }
    const topOffset = this.cdkScrollable.measureScrollOffset('top');
    const bottomOffset = this.cdkScrollable.measureScrollOffset('bottom');
    return { topOffset, bottomOffset };
  }

  getElement() {
    const element: HTMLElement = this.scroller?.getElementRef()?.nativeElement;
    return element;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
