import { Injectable, inject } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { MassDataUpdatesPlugin } from '@portal/wen-data-core';
import { ContactFilterListEntity } from '../contacts.state';

@Injectable()
export class ContactSearchMassDataUpdatesPlugin extends MassDataUpdatesPlugin<ContactFilterListEntity> {

  private socketIoService = inject(SocketIoService);

  updates$ = this.socketIoService.contact.contactUpdated.listen;

}
