import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { PortalButtonModule } from '@portal/ui-kit/button';
import { WenComponentsModule } from '@portal/wen-components';
import { AdaptiveBackgroundModule } from '../../../shared/components/adaptive-background/adaptive-background.module';
import { FocusFirstInputModule } from '@portal/wen-components';
import { IosUtilsModule } from '../../../shared/ios-util/ios-util.module';
import { PhoneNumberAreaPickerComponent } from './components/phone-number-control/phone-number-area-picker/phone-number-area-picker.component';
import { PhoneNumberControlComponent } from './components/phone-number-control/phone-number-control.component';
import { RegistrationUtils } from './services/registration-workaround';
import { FormStoreModule } from '../../../shared/form-store/form-store.module';
import { RegistrationDataResolver } from './guards/registration.guard';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    WenComponentsModule,
    MatButtonModule,
    PortalButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatDialogModule,
    FocusFirstInputModule,
    IosUtilsModule,
    AdaptiveBackgroundModule,
    FormStoreModule,
  ],
  declarations: [
    PhoneNumberControlComponent,
    PhoneNumberAreaPickerComponent,
  ],
  providers: [
    RegistrationUtils,
    RegistrationDataResolver,
  ],
  exports: [
    CommonModule,
    MatInputModule,
    PortalButtonModule,
    WenComponentsModule,
    MatFormFieldModule,
    PhoneNumberControlComponent,
    ReactiveFormsModule,
    TranslateModule,
    FocusFirstInputModule,
    IosUtilsModule,
    AdaptiveBackgroundModule,
    FormStoreModule,
  ]
})
export class RegistrationCommonModule { }
