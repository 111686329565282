import {
  AfterViewChecked, Component, ElementRef, HostBinding,
  Input, OnDestroy, ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { fromEvent, Subscription } from 'rxjs';
import { MarkdownService } from '../../services/markdown/markdown.service';
import { LinkOpener } from './providers/default-link-opener';

@Component({
  selector: 'wen-markdown-text',
  styleUrls: ['./markdown-text.component.scss'],
  templateUrl: './markdown-text.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class MarkdownTextComponent implements AfterViewChecked, OnDestroy {
  @HostBinding('class.wen-markdown-text') className = true;

  private lastContentHandled = true;
  private linkSubscription: Subscription;
  private innerContent: string;
  private innerMarkdownEnabled = true;

  innerHtml: SafeHtml | string;

  @Input() set content(value: string) {
    this.innerContent = value;
    this.processContent();
  }

  @Input() set markdownEnabled(value: boolean) {
    this.innerMarkdownEnabled = value;
    this.processContent();
  }

  constructor(
    private readonly defaultLinkOpener: LinkOpener,
    private markdownService: MarkdownService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef
  ) { }

  private processContent(): void {
    if (this.innerContent) {
      const htmlText = this.markdownService.format(this.innerContent, this.innerMarkdownEnabled);

      this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(htmlText);
    } else {
      this.innerHtml = '';
    }

    this.lastContentHandled = false;
  }

  ngAfterViewChecked(): void {
    if (this.lastContentHandled) {
      return;
    }

    if (this.linkSubscription) {
      this.linkSubscription.unsubscribe();
    }

    const innerLinks = this.elementRef.nativeElement.getElementsByTagName('a');
    this.linkSubscription = fromEvent(innerLinks, 'click')
      .pipe()
      .subscribe((event: Event) => {
        event.preventDefault();
        const anchorElement = this.findParentATag(event.target as HTMLElement);
        if (anchorElement) {
          this.onInlineLinkClicked(event, anchorElement.getAttribute('href'));
        }
      });

    this.lastContentHandled = true;
  }

  findParentATag(element: HTMLElement) {
    if (element instanceof HTMLAnchorElement) {
      return element;
    } else if (element.parentElement) {
      return this.findParentATag(element.parentElement);
    }

    return null;
  }

  onInlineLinkClicked(event: Event, link: string) {
    event.preventDefault();
    this.defaultLinkOpener.openUrl(link);
  }

  ngOnDestroy() {
    this.linkSubscription?.unsubscribe();
  }
}
