import { Provider, inject } from '@angular/core';
import { DefaultMessageBoxActionsHandler } from './default-message-box-actions-handler';
import { MessageBoxActionsHandler } from './message-box-tokens';

// Provide the actions handler from the parent or a fallback
export const MESSAGE_BOX_ACTIONS_HANDLER_PROVIDER_WITH_FALLBACK: Provider = {
  provide: MessageBoxActionsHandler,
  useFactory: () => {
    const parentProvider = inject(MessageBoxActionsHandler, { skipSelf: true, optional: true });
    if (parentProvider) {
      return parentProvider;
    }
    return inject(DefaultMessageBoxActionsHandler);
  }
};
