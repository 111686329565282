@if (isDesktopStyleDevice) {
  <div class="wen-adaptive-background-desktop">
    @if (projectedContent) {
      <div class="wen-adaptive-background-content-wrapper" [style.width]="desktopWidthPx">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    }
  </div>
} @else {
  @if (projectedContent) {
    <div class="wen-adaptive-background-content-wrapper">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  }
}


<ng-template #content>
  <ng-content select="wen-adaptive-background-content, [wen-adaptive-background-content]"></ng-content>
</ng-template>