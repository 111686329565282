import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CoreComponentsModule, WenIconModule } from '@portal/wen-components';
import { HeaderModule } from '../../../frame/components/header/header.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChatMessageSendFieldModule } from '../chat-view/components/chat-message-send-field/chat-message-send-field.module';
import { ChatMessagesViewModule } from '../common/components/chat-messages-view/chat-messages-view.module';
import { ChatSendScheduledMessageModule } from '../common/components/chat-send-scheduled-message/chat-send-scheduled-message.module';
import { ChatSchedulesDesktopHeaderComponent } from './components/chat-schedules-desktop-view/chat-schedules-desktop-header/chat-schedules-desktop-header.component';
import { ChatSchedulesDesktopViewComponent } from './components/chat-schedules-desktop-view/chat-schedules-desktop-view.component';
import { ChatSchedulesViewComponent } from './components/chat-schedules-view/chat-schedules-view.component';

@NgModule({
  declarations: [
    ChatSchedulesViewComponent,
    ChatSchedulesDesktopViewComponent,
    ChatSchedulesDesktopHeaderComponent,
  ],
  imports: [
    SharedModule,
    CoreComponentsModule,
    CommonModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    ChatMessagesViewModule,
    ChatSendScheduledMessageModule,
    ChatMessageSendFieldModule,
    HeaderModule,
    WenIconModule,
  ]
})
export class ChatSchedulesModule { }
