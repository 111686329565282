import { inject, Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { first, map, switchMap } from 'rxjs';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { selectMessageDetailById } from '../../../../core/store/channel/channel.selectors';
import { ChannelPostEditorChangedValues } from '../../../../core/store/form/types/channel-post-editor.types';
import { RootState } from '../../../../core/store/root/public-api';
import { selectAllRouteParams } from '../../../../core/store/root/root.selectors';
import { channelPostEditorMessageIdentifier, channelViewIdentifier } from '../../tokens';
import { categorizeEmbeds } from '@portal/wen-backend-api';

export type ChannelPostEditorResolveData = {
  initialValue: ChannelPostEditorChangedValues;
};

export const channelPostEditorDataResolverFn = () => {
  return inject(ChannelPostEditorDataResolver).resolve();
};

@Injectable()
export class ChannelPostEditorDataResolver  {

  constructor(
    private store: Store<RootState>,
  ) { }

  resolve() {
    return this.store.pipe(
      select(selectAllRouteParams),
      first(),
      switchMap(params => {
        const {
          [channelViewIdentifier]: channelId,
          [channelPostEditorMessageIdentifier]: messageId
        } = params;
        return this.store.pipe(
          selectorWithParam(selectMessageDetailById, messageId, channelId),
          first(),
          map((message) => {
            if (!message) {
              return null;
            }
            const { id, content, embeds } = message;
            const { mediaEmbed, linkEmbed, locationEmbed } = categorizeEmbeds(embeds);
            const initialValue: ChannelPostEditorChangedValues = {
              id,
              postContent: {
                textContent: content,
                embedLocation: locationEmbed,
                linkEmbed,
                mediaEmbed
              }
            };
            return initialValue;
          })
        );
      }),
      first()
    );
  }

}
