import { Injectable } from '@angular/core';
import { WenNavigationHelper } from '../../types';
import { BackNavigationBehavior, BackNavigationBehaviorResult } from './back-navigation-behavior';

@Injectable()
export class AlwaysHomeBackNavigation implements BackNavigationBehavior {

  constructor(
    private navigationHelper: WenNavigationHelper
  ) { }

  execute(): BackNavigationBehaviorResult {
    this.navigationHelper.navigateToWelcomeScreen();
    return {
      canHandle: true
    };
  }

}
