import { EmbeddedMediaDTO, EmbedDTOTypes, generateId, RoomMessageDTO } from '@portal/wen-backend-api';
import { ChatMessageBatchLoader, ChatMessageTransactionData } from '@portal/wen-chat-client';
import { combineLatest, first, forkJoin, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { DateUtil } from '../../../../../common/date/date-util';
import { DistributionChatAttachmentLoader } from '../../../../smartdesign/chat-distribution/distribution-chat-attachment-loader';
import { DistributionDataImporter } from '../../../../smartdesign/chat-distribution/distribution-data-importer';
import { DistributionMessageDTO, DistributionRecipientDTO } from '../../../../smartdesign/chat-distribution/distribution-types';
import { DistributionChatMessageProcessor } from '../../../../smartdesign/chat-distribution/distribution-chat-message-processor';

export class DistributionMessageLoader extends ChatMessageBatchLoader {

  private hasMoreItems = true;
  private currentPageNr = 1;

  private distributionChatMessage$: Observable<DistributionMessageDTO>;
  private distributionChatAttachments$: Observable<EmbeddedMediaDTO[]>;

  constructor(
    private dataImporter: DistributionDataImporter,
    private attachmentLoader: DistributionChatAttachmentLoader,
    private chatMessageProcessor: DistributionChatMessageProcessor,
  ) {
    super();
  }

  loadNext(): Observable<ChatMessageTransactionData[]> {
    this.ensureDatas();
    const distributionPageData$ = combineLatest([
      this.distributionChatMessage$,
      this.loadNextPage(),
      this.distributionChatAttachments$
    ]).pipe(
      first(),
      map(([distributionChatMessage, recipients, attachments]) => {
        this.hasMoreItems = recipients?.length > 0;
        const datas: ChatMessageTransactionData[] = recipients.map((recipient) => {
          const { message: messageTemplate } = distributionChatMessage;
          const message = this.chatMessageProcessor.processMessage(recipient.originalDO, messageTemplate);
          const { weNetworkUserId } = recipient;
          const embeds: EmbedDTOTypes[] = [];
          if (attachments?.length) {
            embeds.push(...attachments);
          }
          const backwardCompatibleMessageWithId: RoomMessageDTO = {
            id: generateId(),
            content: message,
            embeds,
            timestamp: DateUtil.currentDateString()
          };
          const data: ChatMessageTransactionData = {
            dialogUserId: weNetworkUserId,
            roomMessage: {
              message: backwardCompatibleMessageWithId
            },
          };
          return data;
        });
        return datas;
      })
    );
    return distributionPageData$;
  }

  hasMore() {
    return this.hasMoreItems;
  }

  private ensureDatas() {
    if (!this.distributionChatMessage$) {
      this.distributionChatMessage$ = this.dataImporter.loadDistributionChatMessage().pipe(
        shareReplay(1)
      );
    }
    if (!this.distributionChatAttachments$) {
      this.distributionChatAttachments$ = this.distributionChatMessage$.pipe(
        switchMap((distributionChatMessage) => {
          const attachments = distributionChatMessage.attachments;
          if (!attachments?.length) {
            return of(null);
          }
          const embeds$ = attachments.map(attachment => this.attachmentLoader.loadAttachmentAsEmbed(attachment));
          return forkJoin(embeds$);
        }),
        shareReplay(1)
      );
    }
  }

  private loadNextPage(): Observable<DistributionRecipientDTO[]> {
    return this.dataImporter.loadDistributionChatRecipients(this.currentPageNr++).pipe(
      shareReplay(1)
    );
  }

}
