import { Injectable } from '@angular/core';
import { MarkdownService } from '@portal/wen-components';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable()
export class ClipboardService {

  constructor(private markdownService: MarkdownService, private clipboard: Clipboard) {
  }

  copy(text: string, removeMarkdown?: boolean): boolean {
    if (removeMarkdown) {
      text = this.markdownService.removeMarkdown(text);
    }

    return this.clipboard.copy(text);
  }
}
