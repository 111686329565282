import { Directive, Input, AfterViewInit, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LightboxImageData } from '../models/lightbox.models';
import { PhotoSwipeService } from '../providers/photoswipe.service';

@Directive({
  selector: '[photoSwipe]',
  providers: [PhotoSwipeService]
})
export class PhotoSwipeDirective implements AfterViewInit, OnDestroy {

  @Input('photoSwipe') set imageUrl(images: Array<LightboxImageData>) {
    images.forEach(image => {
      if (image.src) {
        this.photoSwipeService.registerGalleryItem(image);
      }
    });
  }

  @Output() pswpDestroy = new EventEmitter<void>();

  constructor(private photoSwipeService: PhotoSwipeService, private hostEl: ElementRef<HTMLElement>) {
    this.photoSwipeService.registerAppendToElement(this.hostEl.nativeElement);
  }

  ngAfterViewInit(): void {
    const photoSwipe = this.photoSwipeService.initPhotoSwipe();
    photoSwipe.on('destroy', () => {
      this.pswpDestroy.emit();
    });
  }

  ngOnDestroy(): void {
    this.photoSwipeService.destroyPhotoSwipe();
  }
}
