import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootState } from '../../../../../../../core/store/root/public-api';
import { selectProgressState } from '../../../../../../../core/store/ui/ui.selectors';
import { ProgressState } from '../../../../../../../core/store/ui/ui.state';

@Injectable()
export class ChatCreationListService {

  private store = inject(Store<RootState>);

  hasProgress$: Observable<ProgressState>;

  constructor() {
    this.hasProgress$ = this.store.pipe(
      select(selectProgressState)
    );
  }
}
