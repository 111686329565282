import { distinctUntilChanged, map, Observable, pairwise, startWith } from 'rxjs';

export const distinctIdChanges = () => (source: Observable<{ id: string }>) => {
  return source.pipe(
    startWith({ id: null }),
    map((data) => data?.id),
    distinctUntilChanged(),
    pairwise(),
  );
};
