import { NgModule } from '@angular/core';
import { PaginatedScrollviewDirective } from './paginated-scrollview.directive';

@NgModule({
  declarations: [
    PaginatedScrollviewDirective
  ],
  exports: [
    PaginatedScrollviewDirective
  ]
})
export class PaginatedScrollviewModule { }
