import { NgModule, StaticProvider } from '@angular/core';
import { MASS_DATA_CONFIG, MassDataConfigEntry, MassDataModule, OffsetBasedMassDataPagingAdapter } from '@portal/wen-data-core';
import { pendingContactsMassDataIdentifier } from '../constants';
import { PendingContactsMassDataAdapter } from './pending-contacts-mass-data-adapter';
import { PendingContactsMassDataContainer } from './pending-contacts-mass-data-container';
import { PendingContactsMassDataRepository, PendingReceivedContactsMassDataRepository, PendingSentContactsMassDataRepository } from './pending-contacts-mass-data-repository';
import { PendingContactsMassDataUpdatesPlugin } from './pending-contacts-mass-data-updates';

const pendingContactsConfig: MassDataConfigEntry = {
  identifier: pendingContactsMassDataIdentifier,
  container: PendingContactsMassDataContainer,
  adapter: PendingContactsMassDataAdapter,
  repository: PendingContactsMassDataRepository,
  pagingAdapter: OffsetBasedMassDataPagingAdapter,
  updatesAdapter: PendingContactsMassDataUpdatesPlugin,
};

const PENDING_CONTACTS_CONTAINER_CONFIG: StaticProvider = {
  provide: MASS_DATA_CONFIG,
  multi: true,
  useValue: pendingContactsConfig
};

@NgModule({
  imports: [
    MassDataModule,
  ],
  providers: [
    PendingContactsMassDataContainer,
    PendingContactsMassDataAdapter,
    PendingReceivedContactsMassDataRepository,
    PendingSentContactsMassDataRepository,
    PendingContactsMassDataRepository,
    PendingContactsMassDataUpdatesPlugin,
    PENDING_CONTACTS_CONTAINER_CONFIG
  ]
})
export class PendingContactsMassDataModule { }
