import { Injectable } from '@angular/core';
import { FileMeta } from '@portal/react-native-api';
import { DownloaderService, LightboxImageData, WenSnackbarOpener } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { WenNativeApi } from '@portal/wen-native-api';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NativeFileDownloader extends DownloaderService {

  constructor(
    private nativeApi: WenNativeApi,
    snackBar: WenSnackbarOpener,
    translateService: TranslateService,
  ) {
    super(snackBar, translateService);
  }

  canDownload(mediaData: LightboxImageData): boolean {
    return this.nativeApi.getPlatform() !== 'android' || !!mediaData.mimeType;
  }

  downloadFileImplementation(
      url: string, mimeType: string,
      fileType: FileMeta['fileType'] = 'document',
      fileName?: string
    ): Observable<boolean> {
      const downloadName = fileName ?? this.getFileNameFrom(url);
      return this.nativeApi.downloadFile(url, { fileName: downloadName, fileType, mimeType });
  }
}
