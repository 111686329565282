import { NgModule, StaticProvider } from '@angular/core';
import { MASS_DATA_CONFIG, MassDataConfigEntry, MassDataModule, OffsetBasedMassDataPagingAdapter } from '@portal/wen-data-core';
import { createChatSearchMassDataIdentifier } from '../../constants';
import { ChatMemberEditSearchMassDataAdapter } from '../providers/chat-member-edit-search-adapter';
import { ExistingContactsCreateChatSearchMassDataRepository, FurtherCreateChatSearchMassDataRepository, GroupRoomCreateChatSearchMassDataRepository } from '../providers/chat-member-edit-search-repositories';
import { EditChatSearchFilterService } from '../providers/edit-chat-search-filter-service';
import { CreateChatSearchMassDataContainer } from './create-chat-search-mass-data-container';
import { CreateChatSearchMassDataRepository } from './create-chat-search-mass-data-repository';

const createChatSearchConfig: MassDataConfigEntry = {
  identifier: createChatSearchMassDataIdentifier,
  container: CreateChatSearchMassDataContainer,
  adapter: ChatMemberEditSearchMassDataAdapter,
  repository: CreateChatSearchMassDataRepository,
  pagingAdapter: OffsetBasedMassDataPagingAdapter,
};

const CREATE_CHAT_SEARCH_CONTAINER_CONFIG: StaticProvider = {
  provide: MASS_DATA_CONFIG,
  multi: true,
  useValue: createChatSearchConfig
};

@NgModule({
  imports: [
    MassDataModule,
  ],
  providers: [
    CreateChatSearchMassDataContainer,
    ChatMemberEditSearchMassDataAdapter,
    ExistingContactsCreateChatSearchMassDataRepository,
    FurtherCreateChatSearchMassDataRepository,
    GroupRoomCreateChatSearchMassDataRepository,
    CreateChatSearchMassDataRepository,
    CREATE_CHAT_SEARCH_CONTAINER_CONFIG,
    EditChatSearchFilterService,
  ]
})
export class CreateChatSearchMassDataModule { }
