import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subject, map, merge } from 'rxjs';
import { auditTime, first, takeUntil } from 'rxjs/operators';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { EventEntity } from '../../../../../core/store/events/event.state';
import { WenRouteId } from '../../../../../frame/routing/types';
import { EventListDataSource } from '../../providers/event-list-datasource';
import { eventViewIdentifier } from '../../../tokens';

const SCROLL_SAFETY_THRESHOLD = 210; //To be sure scroll end event can be safely triggered

@Component({
  selector: 'wen-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  providers: [EventListDataSource]
})
export class EventListComponent implements OnInit, AfterViewInit, OnDestroy {

  private onClick$ = new Subject<void>();
  private onDestroy$ = new Subject<void>();
  private scheduleViewChange$ = new Subject<void>();
  visibleEvents: EventEntity[];

  @ViewChild(CdkVirtualScrollViewport, { static: false }) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  @Input() set events(events: EventEntity[]) {
    this.visibleEvents = events;
    this.scheduleViewChange$.next();
  }
  @Input() listItemAfterRef: TemplateRef<any>;
  @Output() endReached = new EventEmitter<void>();

  constructor(
    private readonly appNavigator: AppNavigator
  ) { }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit() {
    this.scheduleViewChange$.pipe(
      first()
    ).subscribe(() => this.cdkVirtualScrollViewport.checkViewportSize());
  }

  ngAfterViewInit() {
    merge(
      this.scheduleViewChange$.pipe(map(() => this.cdkVirtualScrollViewport.getRenderedRange())),
      this.cdkVirtualScrollViewport.renderedRangeStream
    ).pipe(
      auditTime(300),
      takeUntil(this.onDestroy$)
    ).subscribe((newOrCurrentRange) => {
      const viewportSize = this.cdkVirtualScrollViewport.getViewportSize();
      const currentRangeSize = this.cdkVirtualScrollViewport.measureRangeSize(newOrCurrentRange);

      // programatically try loading next page of data
      if (currentRangeSize < (viewportSize + SCROLL_SAFETY_THRESHOLD)) {
        this.endReached.emit();
      }
    });
  }

  onClick(eventId: string) {
    this.onClick$.next();
    this.appNavigator.navigateToRoute(WenRouteId.EVENT_DETAIL, { [eventViewIdentifier]: eventId });
  }

}
