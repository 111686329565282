import { CategoriesDTO } from '@portal/wen-backend-api';
import { createAction, props } from '@ngrx/store';
import { LoadingState } from '../../common/types/store-loading-state';
import { AppPermission } from '../user/models/Permission';
import { AppEntity, AppMeta } from './apps.state';

export const subscribeToUserAppsUpdates = createAction(
  `[Apps] Subscribe to app updates for the logged in user's app list`
);

export const subscribeToAppUpdates = createAction(
  '[Apps] Subscribe to app modification updates',
);

export const initializeAppFilters = createAction(
  '[Events] Initialize app filters'
);

export const updateApp = createAction(
  '[Apps] Update app',
  props<{ app: AppEntity }>()
);

export const updateApps = createAction(
  '[Apps] Update apps',
  props<{ apps: AppEntity[] }>()
);

export const upsertAppMeta = createAction(
  '[Apps] Update the app metadata',
  props<{ appMetas: AppMeta[] }>()
);

export const updateAppDetail = createAction(
  '[Apps] Update app detail',
  props<{ app: AppEntity }>()
);

export const updateUserApps = createAction(
  '[Apps] Update apps for the logged in user',
  props<{ apps: AppEntity[] }>()
);

export const updateUserAppsLoadingState = createAction(
  '[Apps] Update the loaded and error state of the user apps',
  props<{ loadingState: LoadingState }>()
);

export const reloadDiscoverAppList = createAction(
  '[Apps] Reload app list for discover apps'
);

export const subscribeToDiscoverAppsUpdates = createAction(
  '[Apps] Subscribe to app updates for discover apps'
);

export const updateDiscoverAppsList = createAction(
  '[Apps] Update app list for discover apps',
  props<{ apps: AppEntity[] }>()
);

export const updateDiscoverAppsLoadingState = createAction(
  '[Apps] Update the loaded and error state of the app list for discover apps',
  props<{ loadingState: LoadingState }>()
);

export const setSubscriptionToCurrentApp = createAction(
  '[Apps] Set subscription for current app',
  props<{ setSubscribed: boolean }>()
);

export const setSubscriptionToApp = createAction(
  '[Apps] Set subscription for app',
  props<{ id: string; setSubscribed: boolean }>()
);

export const updateAppPermission = createAction(
  '[Apps] Update the app permission',
  props<{ appId: string; permission: AppPermission }>()
);

export const acknowledgeDeletedApp = createAction(
  '[Apps] The users accept that the admin deleted the app',
  props<{ appId: string }>()
);

export const updateAppCategories = createAction(
  '[Apps] Update app categories',
  props<{ categories: CategoriesDTO }>()
);
