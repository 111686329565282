import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { DelayedOperationScheduler } from '@portal/wen-components';
import { ImportedSelectionEntity } from '../../../../core/store/smartdesign/smartdesign.state';

@Component({
  selector: 'wen-invitees-display',
  templateUrl: './invitees-display.component.html',
  styleUrls: ['./invitees-display.component.scss']
})
export class InviteesDisplayComponent implements AfterViewInit {

  private operationScheduler = new DelayedOperationScheduler();

  @Input() headerImage: string;
  @Input() importeeControlName: string;
  @Input() extraCount = 0;

  @Input() set inviteeData(data: ImportedSelectionEntity[]) {
    if (data) {
      this.operationScheduler.scheduleOrRun(() => {
        const silentOpts = {
          onlySelf: true,
          emitEvent: false
        };
        this.importeeControl.setValue(data, silentOpts);
        this.importeeControl.markAsDirty(silentOpts);
      });
    }
  }

  get inviteesCount(): number {
    return this.extraCount + this.importeeControl.value.length;
  }

  get importeeControl() {
    return this.formGroup.form.controls[this.importeeControlName];
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private formGroup: FormGroupDirective,
  ) { }

  ngAfterViewInit(): void {
    this.operationScheduler.setReady();
    this.operationScheduler.flush();
    this.cdr.detectChanges();
  }

}
