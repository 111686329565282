import { Injectable, Optional } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';
import { NetworkInitializationHandler } from '../../../frame/network/tokens';
import { setActiveNetwork, subscribeToNetwork, upsertKnownNetworks } from './network.actions';

@Injectable()
export class NetworkEffects {

  subscribeToNetwork$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToNetwork),
    switchMap(({ networkId }) => {
      return this.networkInitializationHandler.setupNetwork(networkId).pipe(
        switchMap((networkData) => {
          return [
            upsertKnownNetworks({ networks: [networkData] }),
            setActiveNetwork({ networkId: networkData.id }),
          ];
        })
      );
    })
  ));

  constructor(
    private readonly actions$: Actions,
    @Optional() private networkInitializationHandler: NetworkInitializationHandler,
  ) { }

}
