import { inject } from '@angular/core';
import { ReducerManager, combineReducers } from '@ngrx/store';
import { StoreFeature } from '@ngrx/store/src/models';
import { massDataReducerFactory } from '../reducer/mass-data-reducer';
import { MASSDATA_STORE_FEATURE_NAME } from '../tokens';
import { MassDataStateType } from '../types';

export const createMassDataFeature = () => {
  const reducerManager = inject(ReducerManager);
  const entityCacheFeature: StoreFeature<MassDataStateType> = {
    key: MASSDATA_STORE_FEATURE_NAME,
    reducers: massDataReducerFactory(),
    reducerFactory: combineReducers,
    initialState: {},
  };
  reducerManager.addFeature(entityCacheFeature);
};
