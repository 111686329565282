
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { PublicUserProfileResponse, SocketIoService } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';

export const UserProfileResolver: ResolveFn<Observable<PublicUserProfileResponse>> = (
  route: ActivatedRouteSnapshot
) => {
  const socketIoService = inject(SocketIoService);
  const userId = route.params.userProfileId;
  return socketIoService.user.profile.acknowledgement$<PublicUserProfileResponse>({ userId });
};
