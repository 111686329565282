import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { upsertHeaderData } from '../../../../core/store/header/header.actions';
import { createAppCreateCommand } from '../../../../core/store/header/models/base-commands';
import { RootState } from '../../../../core/store/root/public-api';
import { selectUserAppPermission } from '../../../../core/store/user/user.selectors';

@Injectable()
export class AppListHeaderDataUpdater implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<RootState>,
  ) { }

  initialize() {
    this.store.pipe(select(selectUserAppPermission)).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((permission) => {
      if (permission?.canCreate) {
        this.store.dispatch(upsertHeaderData({ headerData: { rightCommand: createAppCreateCommand() } }));
      } else {
        this.store.dispatch(upsertHeaderData({ headerData: { rightCommand: null } }));
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
