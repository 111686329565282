import { createSelector } from '@ngrx/store';
import { toWeatherModel } from '@portal/wen-components';
import { weatherFeature } from './weather.reducers';

export const {
  selectCurrentWeather
} = weatherFeature;


export const selectCurrentWeatherModel = createSelector(
  selectCurrentWeather,
  (response) => {
    return toWeatherModel(response?.features[0]);
  }
);
