import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { mapTo } from 'rxjs/operators';
import { firstExisty } from '../common/operators/first-existy';
import { RootState } from '../store/root/public-api';
import { selectUserProfile } from '../store/user/user.selectors';

export const userProfileLoadedGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(UserProfileLoadedGuard).canActivate(route, state);
};

@Injectable()
export class UserProfileLoadedGuard  {

  constructor(
    private store: Store<RootState>,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.pipe(
      select(selectUserProfile),
      firstExisty(),
      mapTo(true)
    );
  }
}
