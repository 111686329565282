import { CdkScrollable } from '@angular/cdk/scrolling';
import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { ResizeHandlerProvider, WenBreakpointObserver } from '@portal/wen-components';
import { race, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { addScrollableTarget, disablePageScroll, enablePageScroll, clearQueueScrollLocks } from 'scroll-lock';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { WenNativeApi } from '@portal/wen-native-api';
import { IosKeyboardLockScrollHelper } from './ios-keyboard-lock-scroll';

@Directive({
  selector: '[wenIosKeyboardLockTarget], wenIosKeyboardLockTarget',
  providers: [
    ResizeHandlerProvider
  ]
})
export class IosKeyboardLockTargetDirective implements OnDestroy {

  private onDestroy = new Subject<void>();
  private blur = new Subject<void>();
  private needsReset = false;

  @HostListener('focus')
  onFocus() {
    if (this.isDisabled() || !this.featureEnablement.featureFlagMethods.isEnableIosKeyboardUtils()) {
      return;
    }
    this.nativeApi.keyboardDidShow$.pipe(
      first(),
      takeUntil(
        race(
          this.blur.pipe(first()),
          this.onDestroy.pipe(first())
        )
      )
    ).subscribe(() => {
      this.fixAppSize();
    });
    this.resizeHandler.observeViewport().pipe(
      takeUntil(
        race(
          this.blur.pipe(first()),
          this.onDestroy.pipe(first())
        )
      )
    ).subscribe(() => {
      const element: HTMLInputElement = this.elementRef.nativeElement;
      this.resetAppSize();
      element.blur();
      this.keyboardLockScrollHelper.getScrollables().pop().scrollTo({ bottom: 0 });
    });
  }

  @HostListener('blur')
  onBlur() {
    if (this.isDisabled() || !this.featureEnablement.featureFlagMethods.isEnableIosKeyboardUtils()) {
      return;
    }
    this.blur.next();
    this.nativeApi.keyboardDidHide$.pipe(
      first(),
      takeUntil(
        race(
          this.nativeApi.keyboardDidShow$.pipe(
            first(),
          ),
          this.onDestroy.pipe(first())
        )
      )
    ).subscribe(() => {
      this.resetAppSize();
    });
  }

  constructor(
    private elementRef: ElementRef,
    private nativeApi: WenNativeApi,
    private breakpointObserver: WenBreakpointObserver,
    private resizeHandler: ResizeHandlerProvider,
    private keyboardLockScrollHelper: IosKeyboardLockScrollHelper,
    private featureEnablement: FeatureEnablementService
  ) {
  }

  private isDisabled() {
    const isDesktopStyle = this.breakpointObserver.isDesktopStyleDevice;
    const isNativeMobile = this.nativeApi.isReactNativeApp();
    const isIOS = this.nativeApi.isIOS();
    return isDesktopStyle || !(isNativeMobile && isIOS);
  }

  private getScrollableElement() {
    return this.keyboardLockScrollHelper.getScrollables().map(scroller => scroller.getElementRef().nativeElement);
  }

  private fixAppSize() {
    this.needsReset = true;
    const targetHeight = window.innerHeight;
    const htmlTag = document.getElementsByTagName('html')[0];
    document.body.style.height = targetHeight + 'px';
    htmlTag.style.height = targetHeight + 'px';
    htmlTag.scrollTo(0, 0);
    addScrollableTarget(this.elementRef.nativeElement);
    disablePageScroll(this.getScrollableElement());
  }

  private resetAppSize() {
    if (!this.needsReset) {
      return;
    }
    const htmlTag = document.getElementsByTagName('html')[0];
    htmlTag.style.height = 'auto';
    document.body.style.height = '100vh';
    clearQueueScrollLocks();
    enablePageScroll(this.keyboardLockScrollHelper.getScrollables());
    this.needsReset = false;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    if (this.isDisabled()) {
      return;
    }
    this.resetAppSize();
    this.keyboardLockScrollHelper.removeScrollables();
  }
}

@Directive({
  selector: '[wenIosKeyboardScrollable], wenIosKeyboardScrollable'
})
export class IosKeyboardScrollableDirective {

  constructor(
    cdkScrollable: CdkScrollable,
    keyboardLockScrollHelper: IosKeyboardLockScrollHelper,
  ) {
    keyboardLockScrollHelper.addScrollable(cdkScrollable);
  }

}
