@if (section$ | async; as section) {
  @switch (config?.context) {
    @case (DataContext.CHANNEL) {
      <wen-channel-section
        [section]="section"
        (showAll)="navigateToChannelDiscovery($event)">
      </wen-channel-section>
    }
    @case (DataContext.APP) {
      <wen-app-section
        [section]="section"
        (showAll)="navigateToAppDiscovery($event)">
      </wen-app-section>
    }
    @case (DataContext.EVENT) {
      <wen-event-section
        [section]="section"
        (showAll)="navigateToEventDiscovery($event)">
      </wen-event-section>
    }
  }
}
