import { CdkScrollable } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, first, withLatestFrom } from 'rxjs';
import { selectorWithParam } from '../../../core/common/util/selector-with-param';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { OutletHelper } from '../../../core/services/navigation/outlet-specific/outlet-helper';
import { DataContextHelper } from '../../../core/services/util/data-context-helper';
import { ScrollerWrapper } from '../../../core/services/scrolling/scroller-wrapper';
import { RootState } from '../../../core/store/root/public-api';
import { upsertScrollState } from '../../../core/store/ui/ui.actions';
import { selectScrollStateFor } from '../../../core/store/ui/ui.selectors';
import { removeFilter, upsertActiveFilter } from '../../../core/store/filter/filter.actions';

@Injectable()
export class ScrollStateManager extends ScrollerWrapper {

  private readonly onNavigationEvent$ = this.actions$.pipe(
    ofType(routerNavigationAction),
    withLatestFrom(this.scrollPosition$),
  );

  private readonly onFilterChange$ = this.actions$.pipe(
    ofType(upsertActiveFilter, removeFilter),
    first()
  );

  private getOutletId() {
    return this.outletHelper.outletIds.sidebarId || this.outletHelper.outletIds.primaryId;
  }

  constructor(
    private readonly store: Store<RootState>,
    private readonly actions$: Actions,
    private readonly outletHelper: OutletHelper,
    private readonly dataContextHelper: DataContextHelper,
    private readonly featureEnablementService: FeatureEnablementService,
  ) {
    super();
  }

  connect(cdkScrollable: CdkScrollable): void {
    if (!this.featureEnablementService.featureFlagMethods.isEnableSaveListScrollPositions()) {
      return;
    }
    super.connect(cdkScrollable);
    const scrollContextId = this.getOutletId();
    this.onNavigationEvent$.pipe(
      first(),
      withLatestFrom(this.dataContextHelper.selectCurrentDataContext()),
    ).subscribe(([[routerNavigated, scrollTop], dataContext]) => {
      this.store.dispatch(upsertScrollState({
        newState: {
          scrollContextId,
          scrollTopPosition: scrollTop,
          scrollDataContext: dataContext
        }
      }));
    });
  }

  restore() {
    if (!this.featureEnablementService.featureFlagMethods.isEnableSaveListScrollPositions()) {
      return;
    }
    const scrollContextId = this.getOutletId();
    this.store.pipe(
      selectorWithParam(selectScrollStateFor, scrollContextId),
      first(),
      filter(storedScrollState => Boolean(storedScrollState)),
    ).subscribe(({ scrollTopPosition }) => {
      this.scroller.scrollTo({ top: scrollTopPosition });
    });
    this.onFilterChange$.subscribe(() => this.scroller.scrollTo({ top: 0 }));
  }

}
