import { SendMessageContent } from '@portal/wen-backend-api';
import { ChatMessageEntity } from '../../../store/chat/chat.state';
import { ChatMessageDecryptionError, DecryptedMessageResultProps } from './message-decryptor';

export abstract class DecryptedMessageResultWrapper<T extends ChatMessageEntity> {
  abstract get props(): DecryptedMessageResultProps;

  abstract getDecryptedContent(): SendMessageContent<false>;
  abstract getDecryptionError(): ChatMessageDecryptionError;

  abstract asChatStoreEntity(): T;

}
