import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { first, map, switchMap, tap } from 'rxjs';
import { RootState } from '../../root/public-api';
import { loadNextChunkOfComments } from '../comments.actions';
import { selectCommentsForMessage } from '../comments.selectors';
import { CommentEntity } from '../comments.state';
import { COMMENT_LIST_SORTING } from '../constants/comment.constants';

export const createCommentPagingFetchListenerEffect = (
  actions$: Actions,
  store: Store<RootState>,
  socketIoService: Pick<SocketIoService, 'comment'>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(loadNextChunkOfComments),
      switchMap(({ parentId }) => store.pipe(
        select(selectCommentsForMessage),
        first(),
        map(projectorFn => projectorFn(parentId))
      )),
      tap(({ referenceId, pagingInformation }: CommentEntity) => {
        socketIoService.comment.list.emit({
          parentId: referenceId,
          ...COMMENT_LIST_SORTING,
          ...pagingInformation,
          page: pagingInformation.page + 1,
        });
      })
    ), { dispatch: false }
  );
};
