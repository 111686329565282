import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { FeatureEnablementService } from '../../../../../../core/services/configuration/feature-enablement';
import { OtherProfileData } from './profile-data.service';

export enum ContactRequestUiState {
  CAN_SEND = 'CAN_SEND',
  CAN_WITHDRAW = 'CAN_WITHDRAW',
  CAN_HANDLE_RECEIVED = 'CAN_HANDLE_RECEIVED',
  CAN_REMOVE_CONTACT = 'CAN_REMOVE_CONTACT',
}

@Injectable()
export class ProfileActionsConditions {

  private profileData = inject(OtherProfileData);
  private featureEnablementService = inject(FeatureEnablementService);

  public canInitiateChat$: Observable<boolean>;

  constructor() {
    this.canInitiateChat$ = this.profileData.profile$.pipe(
      map(response => this.featureEnablementService.isChatEnabled() && response.enableChatViaProfile)
    );
  }

}
