import { Injectable, Provider } from '@angular/core';
import { ControlValueAccessor, FormControlName, NG_VALUE_ACCESSOR } from '@angular/forms';

@Injectable()
export class ControlValueAccessorProxy implements ControlValueAccessor {

  private formControlName: FormControlName;
  private originalCva: ControlValueAccessor;

  private scheduledUpdates = new Map<keyof ControlValueAccessor, any>();

  get formControl() {
    return this.formControlName?.control;
  }

  initializeControl(originalCva: ControlValueAccessor, formControlName: FormControlName): void {
    this.formControlName = formControlName;
    this.originalCva = originalCva;
    this.scheduledUpdates.forEach((applyChange, key) => {
      applyChange(originalCva);
      this.scheduledUpdates.delete(key);
    });
  }

  writeValue(obj: any): void {
    this.applyChange('writeValue', obj);
  }

  registerOnChange(fn: any): void {
    this.applyChange('registerOnChange', fn);
  }

  registerOnTouched(fn: any): void {
    this.applyChange('registerOnTouched', fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.applyChange('setDisabledState', isDisabled);
  }

  private applyChange<K extends keyof ControlValueAccessor>(cvaMethod: K, ...args: Parameters<ControlValueAccessor[K]>) {
    if (this.originalCva) {
      this.originalCva[cvaMethod].apply(this.originalCva, args);
      return;
    }
    this.scheduledUpdates.set(cvaMethod, (originalCva: ControlValueAccessor) => originalCva[cvaMethod].apply(this.originalCva, args));
  }
}

export const CVA_PROXY_PROVIDERS: Provider[] = [
  {
    provide: NG_VALUE_ACCESSOR,
    useClass: ControlValueAccessorProxy,
    multi: true,
  },
  {
    provide: ControlValueAccessorProxy,
    useFactory: (valueAccessors: [ControlValueAccessorProxy]) => {
      return valueAccessors[0];
    },
    deps: [NG_VALUE_ACCESSOR]
  }
];
