import { Directive } from '@angular/core';

@Directive({
  selector: '[headerLeft]'
})
export class DialogLayoutHeaderLeftDirective { }

@Directive({
  selector: '[headerTitle]'
})
export class DialogLayoutHeaderTitleDirective { }

@Directive({
  selector: '[headerRight]'
})
export class DialogLayoutHeaderRightDirective { }
