export interface PhoneAreaInfo {
  name: string;
  countrySign: string;
  areaCode: string;
}

export interface PhoneNumberValue {
  phoneNumber: string;
  areaInfo?: PhoneAreaInfo;
}

export const phoneControlName = 'phoneControl';
export const verificationCodeControlName = 'verificationCodeControl';
