import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, ViewEncapsulation } from '@angular/core';
import { ColorizeModule } from '../../directives/colorize/colorize.module';
import { PaletteProvider } from '../../directives/public-api';
import { RemoveMarkdownModule } from '../../pipes/remove-markdown/remove-markdown.module';
import { QuoteData } from './models/models';
import { EmbedDescriptionComponent } from '../embed-description/embed-description.component';
import { MediaPreviewModule } from '../media-preview/media-preview.module';

@Component({
  selector: 'wen-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, RemoveMarkdownModule, ColorizeModule, EmbedDescriptionComponent, MediaPreviewModule]
})
export class QuoteComponent {

  private palette = inject(PaletteProvider);
  private quoteData: QuoteData;
  borderColor: string;

  @Input() set quote(value: QuoteData) {
    this.quoteData = value;
    this.borderColor = this.setBorderColor(value);
  }
  get quote() {
    return this.quoteData;
  }

  @HostBinding('class.wen-quote') className = true;

  private setBorderColor(quoteData: QuoteData) {
    return this.palette.getColorFor(quoteData.author);
  }

}
