import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenComponentsModule } from '@portal/wen-components';
import { ChatSyncDialogOpener } from './chat-sync-dialog-opener';
import { ChatSyncDialogComponent } from './chat-sync-dialog/chat-sync-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    WenComponentsModule
  ],
  declarations: [
    ChatSyncDialogComponent
  ],
  providers: [
    ChatSyncDialogOpener
  ],
  exports: [
    ChatSyncDialogComponent
  ]
})
export class ChatSyncModule { }
