import { WenRoutes } from '../../../frame/routing/types';
import { eventDetailRoute, eventDiscoveryRoute, eventExploreRoute, eventListRoute } from './events.routes';

export const singleOutletEventRoutes: WenRoutes = [
  eventExploreRoute,
  eventListRoute,
  eventDiscoveryRoute,
  eventDetailRoute,
  {
    path: '**',
    redirectTo: 'explore',
  }
];
