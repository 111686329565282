import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CompareErrorStateMatcher, isErrorVisible } from '@portal/ui-kit/core';
import { MediaContextTypes, MediaUseCases, SocketIoService, UserRegisterPayload, WeLocalImageHelper } from '@portal/wen-backend-api';
import { emailValidator, OverlayManager, WenBreakpointObserver, wenMustEqualValidator } from '@portal/wen-components';
import { merge, Observable, of, Subject } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { CapitalizeFieldValueAdapter } from '../../../../../core/common/util/field-adapters/capitalize-field-value-adapter';
import { AppConfigurationProvider } from '../../../../../core/services/configuration/app-confguration';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { RootState } from '../../../../../core/store/root/public-api';
import { updateProfileImageState } from '../../../../../core/store/user/user.actions';
import { selectProfileImageState } from '../../../../../core/store/user/user.selectors';
import { ProfileImageState } from '../../../../../core/store/user/user.state';
import { CONTACT_INFO_CONFIG, ContactInfoConfig } from '../../../../../frame/api/tokens';
import { WenRouteId } from '../../../../../frame/routing/types';
import { RegistrationResolvedData } from '../../../common/guards/registration.guard';
import { RegistrationUtils } from '../../../common/services/registration-workaround';
import { RegistrationSuccessComponent, RegistrationSuccessData } from '../registration-success/registration-success.component';

interface ProfileFormValue {
  christianName: string;
  lastName: string;
  email: string;
  password: string;
  avatarUrl: any;
  discoverable: boolean;
  agbCheck?: boolean;
}

@Component({
  selector: 'wen-registration-profile',
  templateUrl: './registration-profile.component.html',
  styleUrls: ['./registration-profile.component.scss']
})
export class RegistrationProfileComponent implements OnInit, OnDestroy {

  readonly PASSWORD_COMPARE_ERROR_KEY = 'passwordCompare';
  readonly comparePasswordErrorMatcher = new CompareErrorStateMatcher('password', this.PASSWORD_COMPARE_ERROR_KEY);
  readonly lottieOptions = { path: '/assets/wen-widget/lottie/PhotoLoading.json', };

  private onDestroy$ = new Subject<void>();

  fieldValueAdapter = new CapitalizeFieldValueAdapter();

  registrationProfileFormGroup = new FormGroup<{
    avatarUrl: FormControl<any>;
    christianName: FormControl<string>;
    lastName: FormControl<string>;
    email: FormControl<string>;
    password: FormControl<string>;
    passwordRepeat: FormControl<string>;
    discoverable: FormControl<boolean>;
    agbCheck?: FormControl<boolean>;
  }>({
    avatarUrl: new FormControl(),
    christianName: new FormControl('', {
      validators: [
        Validators.required,
        Validators.maxLength(255)
      ],
      updateOn: 'blur'
    }),
    lastName: new FormControl('', {
      validators: [
        Validators.required,
        Validators.maxLength(255)
      ],
      updateOn: 'blur'
    }),
    email: new FormControl('', [
      emailValidator()
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(5)
    ]),
    passwordRepeat: new FormControl('', [
      Validators.required,
      Validators.minLength(5)
    ]),
    discoverable: new FormControl(true),
  }, {
    validators: [
      wenMustEqualValidator('password', 'passwordRepeat', this.PASSWORD_COMPARE_ERROR_KEY, false)
    ]
  });

  headerGravity = this.breakpointObserver.isDesktopStyleDevice ? 'bottom' : 'top';

  profileImageState$: Observable<ProfileImageState>;
  isPendingProfileImage$: Observable<boolean>;
  pendingProfileImageText$: Observable<string>;
  termsOfUseLink = this.translateService.instant('TERMS_AND_CONDITIONS_URL');

  get initialText() {
    const { christianName, lastName } = this.formValues || {};
    if (!christianName || !lastName) {
      return null;
    }
    const fullName = `${christianName} ${lastName}`;
    return fullName;
  }

  get formValues(): Partial<ProfileFormValue> {
    return this.registrationProfileFormGroup.value;
  }

  get routeData() {
    return this.route.snapshot.data.registrationData as RegistrationResolvedData;
  }

  get phoneNumber() {
    return this.routeData.phoneNumber;
  }

  get code() {
    return this.routeData.code;
  }

  get prefillHint() {
    return this.routeData.prefillHint;
  }

  get passwordValue() {
    return this.registrationProfileFormGroup.controls.password.value;
  }

  get passwordRepeatValue() {
    return this.registrationProfileFormGroup.controls.passwordRepeat.value;
  }

  get avatarUrl() {
    return this.registrationProfileFormGroup.controls.avatarUrl.value || null;
  }

  get isAgbEnabled() {
    return this.featureEnablement.isAgbEnabled();
  }

  get supportEmail() {
    return this.contactInfo.supportEmail;
  }

  constructor(
    private route: ActivatedRoute,
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
    private overlayManager: OverlayManager,
    private socketIoService: SocketIoService,
    private registrationWorkaround: RegistrationUtils,
    private breakpointObserver: WenBreakpointObserver,
    private imageHelper: WeLocalImageHelper,
    private store: Store<RootState>,
    private featureEnablement: FeatureEnablementService,
    private config: AppConfigurationProvider,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    @Inject(CONTACT_INFO_CONFIG) private contactInfo: ContactInfoConfig,
  ) { }

  ngOnInit(): void {
    this.profileImageState$ = this.store.pipe(
      select(selectProfileImageState),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );
    this.isPendingProfileImage$ = this.profileImageState$.pipe(
      map(profileImageState => {
        return profileImageState === ProfileImageState.PENDING_SAFE || profileImageState === ProfileImageState.PENDING_UNSAFE;
      }),
    );
    this.pendingProfileImageText$ = this.profileImageState$.pipe(
      map(profileImageState => {
        return profileImageState === ProfileImageState.PENDING_SAFE ? 'SAFE_PENDING_MEDIA_UPLOAD_TEXT' : 'UNSAFE_PENDING_MEDIA_UPLOAD_TEXT';
      })
    );
    if (this.isAgbEnabled) {
      this.registrationProfileFormGroup.addControl('agbCheck', new FormControl(false, [
        Validators.required,
        Validators.requiredTrue
      ]));
    }
    if (this.prefillHint) {
      const { christianName, lastName, email } = this.prefillHint;
      if (christianName) {
        this.registrationProfileFormGroup.controls.christianName.setValue(christianName);
      }
      if (lastName) {
        this.registrationProfileFormGroup.controls.lastName.setValue(lastName);
      }
      if (email) {
        this.registrationProfileFormGroup.controls.email.setValue(email);
      }
      this.cdr.detectChanges();
    }
  }

  onSubmit() {
    this.registrationProfileFormGroup.updateValueAndValidity();
    this.registrationProfileFormGroup.markAllAsTouched();
    if (this.registrationProfileFormGroup.valid) {
      this.registrationProfileFormGroup.setErrors({});
      this.socketIoService.user.register.listen.pipe(
        first()
      ).subscribe(response => {
        if (response?.ok) {
          this.overlayManager.dialog.openInputDialog<RegistrationSuccessComponent, RegistrationSuccessData>(
            RegistrationSuccessComponent, { suggestedUsername: this.phoneNumber }
          );
        } else {
          this.registrationProfileFormGroup.setErrors({
            ...this.registrationProfileFormGroup.errors,
            globalerror: true
          });
          if (response.errors?.email) {
            const emailControl = this.registrationProfileFormGroup.get('email');
            emailControl.setErrors({ emailFormat: true });
          }
        }
      });
      this.registrationWorkaround.withCurrentUser().pipe(
        first(),
        switchMap(userId => {
          return of(this.avatarUrl).pipe(
            switchMap(avatarUrl => {
              if (!avatarUrl) {
                return of(null);
              }
              this.store.dispatch(updateProfileImageState({ state: ProfileImageState.PENDING_UNSAFE }));
              return merge(
                this.imageHelper.uploadImage(
                  avatarUrl,
                  [{ type: MediaContextTypes.REGISTRATION, id: userId }],
                  MediaUseCases.PROFILE,
                  this.code
                ).pipe(
                  first(),
                  map(result => result.thumbnailUrl)
                ),
                this.profileImageState$.pipe(
                  filter(profileImageState => profileImageState === ProfileImageState.ERROR),
                  first(),
                  map(() => ProfileImageState.ERROR)
                )
              );
            })
          );
        })
      ).subscribe((avatarUrl) => {
        delete this.formValues.agbCheck;
        let payload = {
          ...this.formValues, code: this.code, identifier: this.phoneNumber
        } as UserRegisterPayload;
        if (avatarUrl && avatarUrl !== ProfileImageState.ERROR) {
          payload = { ...payload, avatarUrl };
        } else {
          delete payload.avatarUrl;
        }
        if (Boolean(payload.christianName)) {
          payload.christianName = this.fieldValueAdapter.format(payload.christianName);
        }
        if (Boolean(payload.lastName)) {
          payload.lastName = this.fieldValueAdapter.format(payload.lastName);
        }
        this.socketIoService.user.register.emit(payload);
      });
    }
  }

  onCancel() {
    this.appNavigator.navigateToRoute(WenRouteId.REGISTRATION_CODE_VERIFICATION);
  }

  onLinkClicked(event: Event, link: string) {
    event.preventDefault();
    this.navigationHelper.navigateToUrlWithFallback(link);
  }

  showGenericPasswordError() {
    const control = this.registrationProfileFormGroup.get('password');
    return !this.showMinLengthPasswordError() && isErrorVisible(control);
  }

  showMinLengthPasswordError() {
    const control = this.registrationProfileFormGroup.get('password');
    return isErrorVisible(control, 'minlength');
  }

  showAgbUncheckedError() {
    const control = this.registrationProfileFormGroup.get('agbCheck');
    return isErrorVisible(control, 'required');
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
