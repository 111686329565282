import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandableContainerComponent } from './expandable-container.component';
import { PortalExpandableContainerModule } from '@portal/ui-kit/expandable-container';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '@portal/wen-components';


@NgModule({
  declarations: [
    ExpandableContainerComponent
  ],
  imports: [
    CommonModule,
    WenIconModule,
    PortalExpandableContainerModule,
    TranslateModule.forChild()
  ],
  exports: [
    ExpandableContainerComponent
  ]
})
export class ExpandableContainerModule { }
