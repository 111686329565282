import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { InvitePrefillHintDTO, SocketIoService } from '@portal/wen-backend-api';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, first, map, withLatestFrom } from 'rxjs/operators';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { selectEditFormById } from '../../../../core/store/form/form.selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { selectOutletIds } from '../../../../core/store/root/root.selectors';
import { WenRouteId } from '../../../../frame/routing/types';
import { PhoneNumberValue, phoneControlName, verificationCodeControlName } from '../types/form-types';
import { extractPhoneNumber } from '../util/phone-utils';

export interface RegistrationResolvedData {
  phoneNumber?: string;
  code?: string;
  prefillHint?: InvitePrefillHintDTO;
}

export const registrationDataActivatorFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(RegistrationDataResolver).canActivate(route, state);
};

export const registrationDataResolverFn: ResolveFn<RegistrationResolvedData> = () => {
  return inject(RegistrationDataResolver).resolve();
};

@Injectable()
export class RegistrationDataResolver  {

  constructor(
    private socketIoService: SocketIoService,
    private navigationHelper: WenNavigationHelper,
    private store: Store<RootState>
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.resolve().pipe(
      withLatestFrom(this.store.pipe(
        select(selectOutletIds)
      )),
      map(([data, outletIds]) => {
        let isValid = false;
        switch (outletIds.primaryId) {
          case WenRouteId.REGISTRATION_CODE_VERIFICATION:
            isValid = Boolean(data.phoneNumber);
            break;
          case WenRouteId.REGISTRATION_PROFILE:
            isValid = Boolean(data.phoneNumber) && Boolean(data.code);
            break;
          default:
            break;
        }
        if (isValid) {
          return true;
        }
        this.navigationHelper.navigateToRegistrationStart(true);
        return false;
      })
    );
  }

  resolve(): Observable<RegistrationResolvedData> {
    const phoneNumber$ = this.store.pipe(
      select(selectEditFormById(WenRouteId.REGISTRATION_START)),
      first(),
      map((startForm) => {
        const startFormValue: PhoneNumberValue = startForm.changedValues[phoneControlName];
        const phoneNumber = extractPhoneNumber(startFormValue);
        return phoneNumber;
      }),
      catchError(() => of(null))
    );
    const code$ = this.store.pipe(
      select(selectEditFormById(WenRouteId.REGISTRATION_CODE_VERIFICATION)),
      first(),
      map((codeForm) => {
        const code: string = codeForm.changedValues[verificationCodeControlName];
        return code;
      }),
      catchError(() => of(null))
    );
    const prefillHint$ = this.socketIoService.invites.prefillHint.listen.pipe(
      first(),
    );
    return combineLatest([
      phoneNumber$, code$, prefillHint$
    ]).pipe(
      map(([phoneNumber, code, prefillHint]) => {
        const result: RegistrationResolvedData = {
          phoneNumber, code, prefillHint
        };
        return result;
      })
    );
  }

}
