import { Injectable } from '@angular/core';
import { GeoPosition } from '@portal/react-native-api';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { SocketIoService } from '@portal/wen-backend-api';
import { WenNativeApi } from '@portal/wen-native-api';
import { ConfirmationDialogComponent, ConfirmationDialogData, OverlayManager, WenSnackbarOpener } from '@portal/wen-components';
import { LocationService } from './location.service';
import { TranslateService } from '@ngx-translate/core';
import { FeatureEnablementService } from '../configuration/feature-enablement';

@Injectable()
export class NativeLocationService extends LocationService {

  dialogData: ConfirmationDialogData;

  constructor(
    socketIoService: SocketIoService,
    snackBarOpener: WenSnackbarOpener,
    translateService: TranslateService,
    featureEnablementService: FeatureEnablementService,
    private overlayManager: OverlayManager,
    private nativeApi: WenNativeApi
  ) {
    super(socketIoService, snackBarOpener, translateService, featureEnablementService);
    this.dialogData = {
      header: translateService.instant('CHANNEL_GEO_LOCATION_PERMISSION_ERROR_HEADER'),
      content: translateService.instant('CHANNEL_GEO_LOCATION_PERMISSION_ERROR_CONTENT'),
      okLabel: translateService.instant('CHANNEL_GEO_LOCATION_PERMISSION_ERROR_SETTINGS_MOBILE'),
      dismissLabel: translateService.instant('CHANNEL_GEO_LOCATION_PERMISSION_ERROR_DISMISS_MOBILE')
    };
  }

  getLocation(): Observable<GeoPosition> {
    return this.nativeApi.requestLocation().pipe(
      catchError(err => {
        if (err.type !== 'GEO_PERMISSION_ERROR') {
          return throwError(() => err);
        }

        if (err.data === 'never_ask_again' || this.nativeApi.isIOS()) {
          const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, this.dialogData);
          return dialog.afterClosed().pipe(
            map((data) => {
              if (data?.result === 'ok') {
                this.nativeApi.openSettings();
              }
              return null;
            })
          );
        }

        return of(null);
      })
    );
  }

  getPermission(): Observable<string> {
    return this.nativeApi.checkPermissions(['locationInUse']).pipe(
      map(permissionData => permissionData.locationInUse)
    );
  }

}
