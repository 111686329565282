import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HeaderModule } from '../../../../../frame/components/header/header.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { ChannelInteractionSettingsComponent } from './channel-interaction-settings.component';

@NgModule({
  imports: [
    SharedModule,
    HeaderModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
  ],
  declarations: [
    ChannelInteractionSettingsComponent,
  ],
  exports: [
    ChannelInteractionSettingsComponent,
  ]
})
export class ChannelInteractionSettingsModule { }
