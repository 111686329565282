import { Injectable } from '@angular/core';
import { ImageUrlTransformator, Dimensions2D } from '@portal/wen-components';

export const SIZE_DEFINITION_PLACEHOLDER = '{size}';

@Injectable()
export class WeLocalImageUrlWithSizeTransformator extends ImageUrlTransformator {
  transform(value: string, dimensions?: Dimensions2D) {
    if (!dimensions || !value?.includes(SIZE_DEFINITION_PLACEHOLDER)) {
      return value;
    }

    if (value && dimensions?.width && dimensions?.height) {
      return value.replace(
        SIZE_DEFINITION_PLACEHOLDER,
        `${this.getPixelsForDimension(dimensions.width)}x${this.getPixelsForDimension(dimensions.height)}`
      );
    }

    if (value && dimensions?.height) {
      return value.replace(
        SIZE_DEFINITION_PLACEHOLDER,
        `x${this.getPixelsForDimension(dimensions.height)}`
      );
    }

    if (value && dimensions?.width) {
      return value.replace(
        SIZE_DEFINITION_PLACEHOLDER,
        `${this.getPixelsForDimension(dimensions.width)}x`
      );
    }

    return value;
  }

  private getPixelsForDimension(dimension: number): number {
    const pixelRatio = window.devicePixelRatio;

    return Math.round(dimension * pixelRatio);
  }
}
