import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { AcknowledgeType, EncryptedMessageEventResponses, MessageEvent, RoomMessagesSummaryResponse, SocketIoService, isEncryptedEvent, isEncryptedMessageEventResponse } from '@portal/wen-backend-api';
import { Observable, defaultIfEmpty, filter, first, forkJoin, map, merge, mergeAll, mergeMap, of, switchMap } from 'rxjs';
import { DateUtil } from '../../../common/date/date-util';
import { filterBy } from '../../../common/operators/fitler-by';
import { onChatInitialized } from '../../../common/util/operators/on-chat-initialized';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { DecryptedEventModifier } from '../../../services/chat/decryption/decrypted-event-modifier';
import { DecryptedMessageResult, MessageDecryptor } from '../../../services/chat/decryption/message-decryptor';
import { MessageEventDecryptor } from '../../../services/chat/decryption/message-event-decryptor';
import { ChatEventOrchestrator } from '../../../services/socket-io/helpers/chat-event-orchestrator';
import { clearChatMessageNewState, subscribeChatMessageAcknowledgeUpdates, subscribeChatUpdates } from '../../chat/chat.actions';
import { selectCurrentAutoReadAcknowledgeRoomId } from '../../chat/chat.selectors';
import { onExchangedSessionsReceived, retryEventDecryptionPossible } from '../../chat/key-actions';
import { RootState } from '../../root/public-api';
import { bulkAction } from '../../root/root.actions';
import { selectIsFromSmartDesign } from '../../smartdesign/smartdesign.selectors';
import { ChatMessagesNotificationUpdate, clearChatMessageNotifications, initChatMessagesNotifications, updateChatMessageNotificationsAcknowledgementState, updateChatMessagesNotifications, upsertChatMessagesNotification } from '../notification.actions';
import { selectNotificationMessagesBySessionId, selectNotificationMessagesBySessionIdForRoom } from '../notification.selectors';

@Injectable()
export class ChatNotificationEffects {

  private filterForEvent(data: MessageEvent) {
    const isTargetEvent = isEncryptedMessageEventResponse(data.payload);
    return isTargetEvent;
  }

  onSummaryReceived$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    filterBy(() => this.store.pipe(select(selectIsFromSmartDesign)), false),
    switchMap(() => this.store.pipe(onChatInitialized)),
    first(),
    switchMap(() => {
      const bufferedSummary$ = this.socketIoService.chat.room.list.listenWithReplay.pipe(
        first(),
        switchMap((listmyResponse) => {
          const channelIds = listmyResponse.map((listMyItem) => listMyItem.id);
          return this.chatEventOrchestrator.ensureSummaryMessagesLoaded(channelIds);
        }),
      );
      const syncChatSummaryResult$ = this.socketIoService.onReconnected$.pipe(
        switchMap(() => this.chatEventOrchestrator.ensureSummaryMessagesSynced().pipe(
          first()
        ))
      );
      const summaryReceived$ = merge(bufferedSummary$, syncChatSummaryResult$);
      const messagesSummary$: Observable<Action> = summaryReceived$.pipe(
        mergeMap((summaries) => {
          return this.convertSummaryResponseToNotificationAction(summaries);
        })
      );
      return messagesSummary$;
    })
  ));

  onClearChatMessageNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(clearChatMessageNotifications),
    map(({ roomId }) => clearChatMessageNewState({ roomId }))
  ));

  onNewMessageAutoAcknowledge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertChatMessagesNotification),
      switchMap(() => this.store.pipe(
        select(selectCurrentAutoReadAcknowledgeRoomId),
        first()
      )),
      filter(hasRoomId => !!hasRoomId),
      map(roomId => clearChatMessageNotifications({ roomId }))
    )
  );

  chatMessageAcknowledgeListenerEffect$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatMessageAcknowledgeUpdates),
    mergeMap(() => {
      return this.socketIoService.chat.room.messagesAcknowledge.listen.pipe(
        filter(({ type }) => type === AcknowledgeType.READ),
        switchMap(({ roomId, lastAckTimestamp }) => [
          updateChatMessageNotificationsAcknowledgementState({ roomId, lastAckTimestamp }),
          clearChatMessageNotifications({ roomId })
        ])
      );
    })
  ));

  onExchangedSessionsReceived$ = createEffect(() => this.actions$.pipe(
    ofType(onExchangedSessionsReceived),
    mergeMap(({ sessionsByRoom }) => {
      const exchangeActions$ = Object.entries(sessionsByRoom).map(([roomId, exchangeSessions]) => {
        const sessionIds = exchangeSessions.map(({ sessionId }) => sessionId);
        const decryptionsForRoom$ = this.store.pipe(
          selectorWithParam(selectNotificationMessagesBySessionIdForRoom, roomId, sessionIds),
          first(),
          mergeMap((eventEntitiesForRoom) => {
            const messagesDecryption$ = eventEntitiesForRoom.map(eventEntity => {
              const insertUser = eventEntity.insertUser;
              const { originalEvent } = eventEntity;
              const payload = originalEvent.payload;
              const { originalEvent: { eventId, insertTimestamp, new: isNew } } = eventEntity;
              return this.messageDecryptor.decryptExchangedRoomMessage({
                eventId,
                isNew,
                insertTimestamp,
                encryptedMessage: payload as EncryptedMessageEventResponses,
                originalEvent: originalEvent as MessageEvent<EncryptedMessageEventResponses>,
                insertUser
              });
            });
            return forkJoin(messagesDecryption$).pipe(
              defaultIfEmpty([] as DecryptedMessageResult[])
            );
          }),
          map((decryptionResults) => {
            if (!decryptionResults?.length) {
              return null;
            }
            const entities = decryptionResults.map(decryptionResult => {
              const update: ChatMessagesNotificationUpdate = {
                id: decryptionResult.props.eventId,
                decryptedEvent: decryptionResult.getDecryptedContent(),
                decryptionError: decryptionResult.getDecryptionError()
              };
              return update;
            });
            return updateChatMessagesNotifications({ roomId, eventEntities: entities });
          })
        );
        return decryptionsForRoom$.pipe(
          defaultIfEmpty([]),
        );
      });
      const exchangeUpdates$ = forkJoin(exchangeActions$).pipe(
        defaultIfEmpty([]),
        mergeAll(),
        filter(action => Boolean(action))
      );
      return exchangeUpdates$;
    })
  ));

  onRetryEventDecryptionPossible$ = createEffect(() => this.actions$.pipe(
    ofType(retryEventDecryptionPossible),
    filterBy(() => this.store.pipe(onChatInitialized)),
    mergeMap(({ megolmSessionId }) => {
      return this.store.pipe(
        selectorWithParam(selectNotificationMessagesBySessionId, megolmSessionId),
        first(),
        map((eventEntity) => eventEntity.originalEvent),
        filter(isEncryptedEvent),
        switchMap((originalEvent) => {
          return this.messageEventDecryptor.decryptMessageEvents([originalEvent]).pipe(
            map((decryptedResults) => {
              const notificationEntities = this.decryptedEventModifier.toNotificationEntities(decryptedResults);
              return upsertChatMessagesNotification({ eventEntity: notificationEntities[0] });
            })
          );
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private store: Store<RootState>,
    private messageDecryptor: MessageDecryptor,
    private chatEventOrchestrator: ChatEventOrchestrator,
    private messageEventDecryptor: MessageEventDecryptor,
    private decryptedEventModifier: DecryptedEventModifier
  ) {
  }

  private convertSummaryResponseToNotificationAction(summaries: RoomMessagesSummaryResponse[]) {
    const decryptionsByRoom$ = summaries.map((summary) => {
      const { latestEvents, roomId, latestAck, unreadCount } = summary;
      const validEvents = latestEvents
        .filter(event => this.filterForEvent(event))
        .sort((event1, event2) => {
          return DateUtil.compare(event1.insertTimestamp, event2.insertTimestamp);
        }) as MessageEvent<EncryptedMessageEventResponses>[];
      if (!validEvents.length) {
        return of(null);
      }

      const newEntity = {
        id: roomId, lastAckTimestamp: latestAck.lastAckTimestamp, unreadCount,
      };

      const decryptedEvents$ = this.messageEventDecryptor.decryptMessageEvents(validEvents);

      return decryptedEvents$.pipe(
        map((decryptedMessages) => {
          const notificationEntities = this.decryptedEventModifier.toNotificationEntities(decryptedMessages);
          return initChatMessagesNotifications({
            newEntity,
            eventEntities: notificationEntities
          });
        })
      );
    });
    const result$ = forkJoin(decryptionsByRoom$).pipe(
      map((actions) => {
        const targetActions = actions.filter((action) => Boolean(action));
        return bulkAction({ targetActions });
      })
    );
    return result$;
  }
}
