import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: 'wen-invite-counter-label, [wen-invite-counter-label]'
})
export class InviteCounterLabelDirective {
  @HostBinding('attr.portal-body-1') styleAttr = true;
}

@Directive({
  selector: 'wen-invite-counter-description, [wen-invite-counter-description]'
})
export class InviteCounterDescriptionDirective {
  @HostBinding('attr.portal-caption') styleAttr = true;
}
