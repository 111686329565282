import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { of } from 'rxjs';
import { ClientCustomizationProvider } from '../../../../frame/network/tokens';
import { ContactResponseActionsHandler } from '../../../../core/services/contact/contact-response-actions-handler';
import { ContactResponseResponseActionData } from '../contact-response-action.types';

@Injectable()
export class ProfileContextMenuAcceptContactAction implements ActionMenuItem<ContactResponseResponseActionData> {

  private profileActionsHandler = inject(ContactResponseActionsHandler);
  private translateService = inject(TranslateService);

  label = this.translateService.instant('CONTACTS_ACCEPT_RECEIVED_MENU_LABEL');

  icon = 'checkmark';

  execute(profile?: ContactResponseResponseActionData): void {
    this.profileActionsHandler.handleAcceptReceivedContactRequest(profile.userId);
  }

  isVisible$() {
    return of(true);
  }

}

@Injectable()
export class ProfileContextMenuDenyContactAction implements ActionMenuItem<ContactResponseResponseActionData> {

  private profileActionsHandler = inject(ContactResponseActionsHandler);
  private translateService = inject(TranslateService);
  private clientCustomizationProvider = inject(ClientCustomizationProvider);

  label = this.translateService.instant('CONTACTS_DENY_RECEIVED_MENU_LABEL');
  labelColor = this.clientCustomizationProvider.getDefaultStyleConfig().errorColor;

  icon = 'close';
  iconColor = this.clientCustomizationProvider.getDefaultStyleConfig().errorColor;

  execute(profile?: ContactResponseResponseActionData): void {
    this.profileActionsHandler.handleDenyReceivedContactRequest(profile.userId);
  }

  isVisible$() {
    return of(true);
  }

}

@Injectable()
export class ProfileContextMenuDeleteContactAction implements ActionMenuItem<ContactResponseResponseActionData> {

  private profileActionsHandler = inject(ContactResponseActionsHandler);
  private translateService = inject(TranslateService);
  private clientCustomizationProvider = inject(ClientCustomizationProvider);

  label = this.translateService.instant('CONTACTS_DELETE_RECEIVED_MENU_LABEL');
  labelColor = this.clientCustomizationProvider.getDefaultStyleConfig().errorColor;

  icon = 'contact_delete';
  iconColor = this.clientCustomizationProvider.getDefaultStyleConfig().errorColor;

  execute(profile?: ContactResponseResponseActionData): void {
    this.profileActionsHandler.deleteContact(profile.userId);
  }

  isVisible$() {
    return of(true);
  }

}
