import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { HourlyForecastData } from '../../weather.model';

@Component({
  selector: 'wen-hourly-forecast',
  templateUrl: './hourly-forecast.component.html',
  styleUrls: ['./hourly-forecast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HourlyForecastComponent {

  @Input() data: HourlyForecastData;

}
