import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class TracerErrorHandler implements ErrorHandler {

  private delegate = new ErrorHandler();

  changeDelegate(delegate: ErrorHandler) {
    this.delegate = delegate;
  }

  handleError(error: any): void {
    if (this.delegate) {
      this.delegate.handleError(error);
    }
  }

}
