import { Injectable } from '@angular/core';
import { OlmDeviceModel, OlmOneTimeKeys } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';

export type GetUserDataResult = {
  userId: string;
  deviceId: string;
};

export type GetOneTimekeyParams = {
  userId: string;
};

export type GetOneTimeKeyResult = OlmOneTimeKeys[];

export type UploadKeysParams = {
  userId: string;
  deviceKeys: OlmDeviceModel[];
  oneTimeKeys?: OlmOneTimeKeys;
};

export type UploadKeysResult = {
  success: boolean;
};

export type GetDeviceKeysParams = {
  userIds: string[];
};

export type GetDeviceKeysResult = {
  deviceKeys: OlmDeviceModel[];
};

@Injectable()
export abstract class ChatBackendApi {

  /**
   * Claim one time keys for the given user
   * Every one-time key can only be requested once
   *
   * @param params The target user id
   *
   * @returns Returns a one-time key to build up an OLM session for the given user's ALL known devices
   */
  abstract getOneTimeKey(params: GetOneTimekeyParams): Observable<GetOneTimeKeyResult>;

  /**
   * Upload e2e keys and/or one-time keys for a given user
   * There are 2 usages for this:
   *  - Upload the e2e (curve+ed) keys for the given user
   *  - Upload a new set of one-time keys when it is needed (when the one-time keys are low in count)
   *
   * @param params Device keys and one-time keys to upload
   *
   * @returns The success state
   */
  abstract uploadKeys(params: UploadKeysParams): Observable<UploadKeysResult>;

  /**
   * The the public keys of the all the target user's all devices
   *
   * @param params The target user Ids
   *
   * @returns The device keys for the given users
   */
  abstract getDeviceKeys(params: GetDeviceKeysParams): Observable<GetDeviceKeysResult>;

}

@Injectable()
export abstract class ChatUserApi {
  abstract getUserData(): Observable<GetUserDataResult>;
}
