import { WenRoutes } from '../../../frame/routing/types';
import { contactsListRoute, pendingContactsListRoute } from './contacts.routes';

export const singleOutletContactsRoutes: WenRoutes = [
  contactsListRoute,
  pendingContactsListRoute,
  {
    path: '**',
    redirectTo: 'list'
  }
];
