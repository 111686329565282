import { isGuidOnlyRoute } from '../../../core/common/util/routing-helpers';
import { AlwaysHomeBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/always-home-back-navigation';
import { createDeepLinkedBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/deep-linked-back-navigation-behavior';
import { DraftEditLeaveConfirmationBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/draft-edit-leave-confirmation-behavior';
import { FormEditLeaveConfirmationBackNavigation } from '../../../core/services/navigation/back-navigation/back-navigation-behaviors/form-edit-leave-confirmation-behavior';
import { paramBasedRouteReuseCheck } from '../../../core/services/navigation/route-reuse-strategy/wen-route-reuse-strategy';
import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { AutoReplyEditComponent } from '../../user-profile/auto-reply-edit/auto-reply-edit/auto-reply-edit.component';
import { AddChatUsersComponent } from '../chat-creation/components/add-chat-users/add-chat-users.component';
import { ChatCreationDesktopComponent } from '../chat-creation/components/chat-creation-desktop/chat-creation-desktop.component';
import { ChatCreationComponent } from '../chat-creation/components/chat-creation/chat-creation.component';
import { ChatInfoEditComponent } from '../chat-creation/components/chat-info-edit/chat-info-edit.component';
import { ChatDetailComponent } from '../chat-detail/chat-detail.component';
import { UserChatListComponent } from '../chat-list/components/user-chat-list/user-chat-list.component';
import { ChatSchedulesDesktopViewComponent } from '../chat-schedules/components/chat-schedules-desktop-view/chat-schedules-desktop-view.component';
import { ChatSchedulesViewComponent } from '../chat-schedules/components/chat-schedules-view/chat-schedules-view.component';
import { canActivateScheduledChatMessagesView } from '../chat-schedules/guards/chat-scheduled-messages.guard';
import { ChatViewComponent } from '../chat-view/chat-view.component';
import { ChatViewDesktopComponent } from '../chat-view/components/chat-view-desktop/chat-view-desktop.component';
import { chatDetailGuardFn } from '../chat-view/guards/chat-detail-guard';
import { chatViewGuardFn } from '../chat-view/guards/chat-view-guard';
import { chatViewIdentifier } from '../tokens';

export const chatCreationRoute: WenRoute = {
  path: `create`,
  component: ChatCreationComponent,
  data: {
    routeId: WenRouteId.CHAT_CREATION,
    backNavigationBehavior: DraftEditLeaveConfirmationBackNavigation,
  },
};

export const addChatInfoRoute: WenRoute = {
  path: `add-chat-info`,
  component: ChatInfoEditComponent,
  data: {
    routeId: WenRouteId.ADD_CHAT_INFO,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const addChatUsersRoute: WenRoute = {
  path: `add-chat-users`,
  component: AddChatUsersComponent,
  data: {
    routeId: WenRouteId.ADD_CHAT_USERS,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const addChatAutoReplyEditRoute: WenRoute = {
  path: `auto-reply-edit`,
  component: AutoReplyEditComponent,
  data: {
    routeId: WenRouteId.AUTO_REPLY,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const chatInfoEditRoute: WenRoute = {
  path: `:${chatViewIdentifier}/edit`,
  component: ChatInfoEditComponent,
  data: {
    routeId: WenRouteId.CHAT_INFO_EDIT,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};

export const chatCreationDesktopRoute: WenRoute = {
  path: 'create',
  component: ChatCreationDesktopComponent,
  data: {
    routeId: WenRouteId.CHAT_CREATION,
    backNavigationBehavior: DraftEditLeaveConfirmationBackNavigation,
  }
};

export const chatListRoute: WenRoute = {
  path: 'list',
  component: UserChatListComponent,
  data: {
    routeId: WenRouteId.CHAT_LIST,
    routeTransitionEnabled: true,
    backNavigationBehavior: AlwaysHomeBackNavigation,
  }
};

export const chatViewRoute: WenRoute = {
  path: `:${chatViewIdentifier}`,
  canMatch: [isGuidOnlyRoute],
  canActivate: [chatViewGuardFn],
  component: ChatViewComponent,
  data: {
    routeId: WenRouteId.CHAT_VIEW,
    backNavigationBehavior: createDeepLinkedBackNavigation({
      mobileTransition: {
        primary: WenRouteId.CHAT_LIST,
      },
      desktopTransition: {
        dialog: WenRouteId.DASHBOARD
      }
    })
  }
};

export const chatViewDesktopRoute: WenRoute = {
  path: `:${chatViewIdentifier}`,
  canMatch: [isGuidOnlyRoute],
  canActivate: [chatViewGuardFn],
  component: ChatViewDesktopComponent,
  data: {
    routeId: WenRouteId.CHAT_VIEW,
    customRouteReuseCheck: paramBasedRouteReuseCheck(chatViewIdentifier),
  }
};

export const chatGroupDetailRoute: WenRoute = {
  path: `:${chatViewIdentifier}/detail`,
  canActivate: [chatDetailGuardFn],
  component: ChatDetailComponent,
  data: {
    routeId: WenRouteId.CHAT_GROUP_DETAIL,
    backNavigationBehavior: createDeepLinkedBackNavigation({
      mobileTransition: {
        primary: WenRouteId.CHAT_VIEW,
      },
      desktopTransition: {
        dialog: null,
        primary: WenRouteId.CHAT_VIEW
      }
    })
  },
};

export const chatSchedulesRoute: WenRoute = {
  path: `:${chatViewIdentifier}/schedules`,
  component: ChatSchedulesViewComponent,
  data: {
    routeId: WenRouteId.CHAT_SCHEDULES
  },
  canActivate: [canActivateScheduledChatMessagesView]
};

export const chatSchedulesDesktopRoute: WenRoute = {
  path: `:${chatViewIdentifier}/schedules`,
  component: ChatSchedulesDesktopViewComponent,
  data: {
    routeId: WenRouteId.CHAT_SCHEDULES
  },
  canActivate: [canActivateScheduledChatMessagesView]
};

export const chatGroupAddMembers: WenRoute = {
  path: `:${chatViewIdentifier}/add-members`,
  component: AddChatUsersComponent,
  data: {
    routeId: WenRouteId.ADD_CHAT_GROUP_MEMBERS,
    backNavigationBehavior: FormEditLeaveConfirmationBackNavigation,
  }
};
