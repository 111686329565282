import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { PinDTO } from '@portal/wen-backend-api';

export type PinEntity = PinDTO;

export interface PinState {
  pinEntities: EntityState<PinEntity>;
}

export const pinEntityAdapter = createEntityAdapter<PinEntity>({
  selectId: (entity) => entity.id
});
