import { NgModule } from '@angular/core';
import { FocusFirstInputDirective } from './focus-first-input.directive';

@NgModule({
  providers: [
  ],
  declarations: [
    FocusFirstInputDirective
  ],
  exports: [
    FocusFirstInputDirective
  ]
})
export class FocusFirstInputModule { }
