import { Injectable } from '@angular/core';
import { OverlayManager } from '@portal/wen-components';
import { filter, first, map } from 'rxjs';
import { FilterSelectorResponse } from './filter-selector-response';
import { FilterSelectorComponent, FilterSelectorData } from './filter-selector.component';

@Injectable()
export class FilterSelectorOpener {

  constructor(
    private overlayManager: OverlayManager,
  ) { }

  openFilterSelector(dialogData: FilterSelectorData) {
    const dialogRef = this.overlayManager.dialog.openFilterSelectorDialog<
      FilterSelectorComponent,
      FilterSelectorData,
      FilterSelectorResponse
    >(FilterSelectorComponent, dialogData);
    return dialogRef.beforeClosed().pipe(
      first(),
      filter(dialogResult => Boolean(dialogResult)),
      map(({ selectedIds, customData }) => {
        const selectedId = selectedIds[0];
        let newActiveFilter = dialogData.visibleFilters.find(possibleValues => possibleValues.id === selectedId);
        if (customData) {
          newActiveFilter = { ...newActiveFilter, data: customData };
        }
        return newActiveFilter;
      })
    );
  }

}
