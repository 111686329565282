
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { GeoAutocompleteFeature, LocationControlProps, SocketIoService } from '@portal/wen-backend-api';
import { OverlayManager, WenIconModule, WenOverlayModule } from '@portal/wen-components';
import { filter, first } from 'rxjs';
import { GeoFilterLocationSelectorComponent, GeoFilterLocationSelectorDialogData, GeoFilterLocationSelectorDialogResponse } from '../../../../../shared/components/filter-view/components/geo-filter-select/components/geo-filter-location-selector/geo-filter-location-selector.component';
import { LocationFormatterPipe } from '../../../../../shared/pipes/location-formatter.pipe';
import { LocationService } from '../../../location/location.service';
import { CanCreateControl } from '../../form-handling/control-creation';
import { FormValueManager } from '../../form-handling/form-value-manager';

@Component({
  selector: 'wen-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    PortalFieldModule,
    ReactiveFormsModule,
    TranslateModule,
    WenIconModule,
    WenOverlayModule
],
  providers: [LocationFormatterPipe]
})
export class LocationComponent implements OnInit, CanCreateControl {

  private parentFormGroup = inject(FormGroupDirective);
  private formValueManager = inject(FormValueManager);
  private formBuilder = inject(FormBuilder);
  private locationFormatterPipe = inject(LocationFormatterPipe);
  private overlayManager = inject(OverlayManager);
  private locationService = inject(LocationService);
  private socketIoService = inject(SocketIoService);
  private innerContext: LocationControlProps;
  parentGroup: FormGroup;
  private locationControl: FormControl<GeoAutocompleteFeature>;
  addressControl = new FormControl<string>(null);

  @Input() set context(value: LocationControlProps) {
    this.innerContext = value;
  }

  get context() {
    return this.innerContext;
  }

  get hasAddress(): boolean {
    return Boolean(this.addressControl.value);
  }

  ngOnInit() {
    this.parentGroup = this.parentFormGroup.form;
    this.createControl(this.innerContext);
  }

  openLocationSelector() {
    const dialogData: GeoFilterLocationSelectorDialogData = {
      activeGeoFilter: this.locationControl.value ?? null,
      geoFilterType: this.innerContext.restrictions,
      customPlaceholder: this.innerContext.label,
      customEndpoint: this.socketIoService.geo.countryRestrictedAutocomplete
    };
    const dialogRef = this.overlayManager.dialog.openInputDialog<
      GeoFilterLocationSelectorComponent, GeoFilterLocationSelectorDialogData, GeoFilterLocationSelectorDialogResponse
    >(GeoFilterLocationSelectorComponent, dialogData);

    dialogRef.beforeClosed().pipe(
      first(),
      filter(dialogResult => Boolean(dialogResult))
    ).subscribe((dialogResult: GeoFilterLocationSelectorDialogResponse) => {
      this.updateLocation(dialogResult.selectedGeoFilter);
    });
  }

  useCurrentLocation() {
    this.locationService.requestLocation().subscribe((deviceLocation) => {
      const { devicePosition: { coords } } = deviceLocation;
      const properties = deviceLocation.reverseGeoData?.features[0]?.properties ?? null;
      const location: GeoAutocompleteFeature = {
        type: 'Feature',
        properties,
        geometry: {
          type: 'Point',
          coordinates: [coords.longitude, coords.latitude]
        }
      };
      this.updateLocation(location);
    });
  }

  clearLocation(event: Event) {
    event.stopPropagation();
    this.updateLocation(null);
  }

  createControl(context: LocationControlProps) {
    const formValue = this.formValueManager.getFormValue();
    const locationData: GeoAutocompleteFeature = formValue[context.name];
    this.locationControl = this.formBuilder.control({ value: locationData, disabled: false });
    this.updateAddress(locationData);
    this.parentGroup.addControl(context.name, this.locationControl);
    this.parentGroup.addControl('address', this.addressControl);
  }

  private updateLocation(newValue: GeoAutocompleteFeature) {
    this.locationControl.setValue(newValue);
    this.locationControl.markAsDirty();
    this.updateAddress(newValue);
  }

  private updateAddress(newValue: GeoAutocompleteFeature) {
    const addressValue = this.locationFormatterPipe.transform(newValue) as string;
    this.addressControl.setValue(addressValue);
  }

}
