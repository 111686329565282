import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { MenuEntryDescriptionDirective, MenuEntryIconDirective, MenuEntryLayoutGroupDirective, MenuEntryTitleDirective } from './menu-entry-directives';
import { MenuEntryComponent } from './menu-entry.component';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule,
  ],
  declarations: [
    MenuEntryComponent,
    MenuEntryTitleDirective,
    MenuEntryIconDirective,
    MenuEntryDescriptionDirective,
    MenuEntryLayoutGroupDirective,
  ],
  exports: [
    MenuEntryComponent,
    MenuEntryDescriptionDirective,
    MenuEntryLayoutGroupDirective,
  ]
})
export class MenuEntryModule { }
