import { Injectable } from '@angular/core';
import { ALL_SOCKET_EVENTS, EmitMetaData, SocketPayload, UpstreamGuard } from '@portal/wen-backend-api';
import { Observable, of } from 'rxjs';
import { WenOAuthService } from '../../user-management/wen-oauth.service';

@Injectable()
export class SocketIoUpstreamGuard implements UpstreamGuard {

  constructor(
    private oAuthService: WenOAuthService,
  ) { }

  onEmit(event: ALL_SOCKET_EVENTS, originalPayload?: SocketPayload): Observable<EmitMetaData> {
    const meta: EmitMetaData = {
      userId: originalPayload?.userId || this.oAuthService.userId,
    };
    return of(meta);
  }

}
