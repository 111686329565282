<div class="wen-quote-comment" [ngClass]="{'interactive': !isCommentDeleted}">
  <div class="wen-quote-comment-border" [ngStyle]="{'background-color': authorColor}"></div>
  <div class="wen-quote-text-section">
    <span portal-caption class="wen-quote-author" [ngStyle]="{'color': authorColor}">{{author}}</span>
    @if (!isCommentDeleted) {
      <span portal-subheading-1 class="wen-quote-message" [innerHTML]="comment | removeMarkdown"></span>
    } @else {
      <div class="wen-quote-deleted">
        <wen-icon class="wen-icon-small" [iconName]="'delete'"></wen-icon>
        <span portal-subheading-1>{{'QUOTE_DELETED_COMMENT' | translate}}</span>
      </div>
    }
  </div>
  @if (showCloseIcon) {
    <wen-icon class="wen-close-quote wen-icon-big" [iconName]="'close'" (click)="closeQuote()"></wen-icon>
  }
</div>

