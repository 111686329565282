import { Injectable } from '@angular/core';
import { MultitronicMassDataRepository } from '@portal/wen-data-core';
import { ExistingContactsCreateChatSearchMassDataRepository, FurtherCreateChatSearchMassDataRepository, GroupRoomCreateChatSearchMassDataRepository } from '../providers/chat-member-edit-search-repositories';
import { CreateChatSearchMassDataContainer } from './create-chat-search-mass-data-container';

@Injectable()
export class CreateChatSearchMassDataRepository extends MultitronicMassDataRepository<CreateChatSearchMassDataContainer> {

  constructor(
    existingContactsMassDataRepository: ExistingContactsCreateChatSearchMassDataRepository,
    groupCreateMassDataRepository: GroupRoomCreateChatSearchMassDataRepository,
    furtherCreateMassDataRepository: FurtherCreateChatSearchMassDataRepository,
  ) {
    super([existingContactsMassDataRepository, groupCreateMassDataRepository, furtherCreateMassDataRepository]);
  }

}
