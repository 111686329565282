<div class="wen-password-field-wrapper">
  <mat-form-field>
    <mat-label>{{ labels.passwordLabel }}</mat-label>
    <input #passwordInput [formControlName]="forFormControlName" matInput autocomplete="new-password" type="password"
      wenIosKeyboardStickyTarget />
      @if (showGenericPasswordError()) {
        <mat-error portal-caption>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_ERROR' |
        translate }}</mat-error>
      }
      @if (showMinLengthPasswordError()) {
        <mat-error portal-caption>{{ 'REGISTRATION_PROFILE_FORM_PASSWORD_LENGTH_ERROR'
        | translate: { length: 5 } }}</mat-error>
      }
      @if (passwordValue) {
        <wen-password-visibility-toggle matSuffix [for]="passwordInput">
        </wen-password-visibility-toggle>
      }
    </mat-form-field>
  </div>

  @if (withRepeatValue && passwordRepeatControl) {
    <div class="wen-password-field-repeat-wrapper">
      <mat-form-field>
        <mat-label>{{ labels.passwordRepeatLabel }}</mat-label>
        <input #passwordRepeatInput [formControlName]="PASSWORD_REPEAT_VIRTUAL_CONTROL_NAME"
          [errorStateMatcher]="comparePasswordErrorMatcher" matInput autocomplete="new-password" type="password"
          wenIosKeyboardStickyTarget />
          <mat-error portal-caption>{{ labels.passwordRepeatError }}</mat-error>
          @if (passwordRepeatValue) {
            <wen-password-visibility-toggle matSuffix [for]="passwordRepeatInput">
            </wen-password-visibility-toggle>
          }
        </mat-form-field>
      </div>
    }