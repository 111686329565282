import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RestrictionContextType, SocketIoService } from '@portal/wen-backend-api';
import { combineLatest } from 'rxjs';
import { filter, first, mapTo, switchMapTo, tap } from 'rxjs/operators';
import { channelViewIdentifier } from '../../views/channel/tokens';
import { isNullOrUndefined } from '../common/operators/null-check-util';
import { updateChannelRestrictions } from '../store/channel/channel.actions';
import { RootState } from '../store/root/public-api';
import { selectRouteParam } from '../store/root/root.selectors';
import { selectPrivileges } from '../store/user/user.selectors';

export const channelRestrictionsLoadedGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(ChannelRestrictionsLoadedGuard).canActivate(route, state);
};

@Injectable()
export class ChannelRestrictionsLoadedGuard  {

  constructor(
    private store: Store<RootState>,
    private actions$: Actions,
    private socketIoService: SocketIoService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const privilegesLoaded$ = this.store.pipe(
      select(selectPrivileges),
      filter((privileges) => !isNullOrUndefined(privileges)),
      first()
    );
    const restrictionsLoaded$ = this.store.pipe(
      select(selectRouteParam(channelViewIdentifier)),
      first(),
      tap((channelId) => {
        this.socketIoService.restrictions.evaluate.emit({ contextId: channelId, contextType: RestrictionContextType.Channel });
      }),
      switchMapTo(this.actions$.pipe(
        ofType(updateChannelRestrictions),
        first()
      ))
    );
    return combineLatest([
      restrictionsLoaded$, privilegesLoaded$
    ]).pipe(
      first(),
      mapTo(true)
    );
  }
}
