import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wen-dialog-layout-header, wen-dialog-layout-footer',
  templateUrl: './dialog-layout-header.component.html',
  styleUrls: ['./dialog-layout-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogLayoutHeaderComponent implements OnInit {

  @Input() inline: string;

  @HostBinding('class.wen-dialog-layout-header') className = true;
  @HostBinding('class.wen-dialog-layout-header-inline') get inlineClassName() {
    return coerceBooleanProperty(this.inline);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
