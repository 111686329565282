import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class EmbeddedOauthWrapper extends OAuthService {

  createLoginUrl(state?: string, loginHint?: string, customRedirectUri?: string, noPrompt?: boolean, params?: object): Promise<string> {
    return super.createLoginUrl(state, loginHint, customRedirectUri, noPrompt, params);
  }
  calculatePopupFeatures(options: { height?: number; width?: number }): string {
    return super.calculatePopupFeatures(options);
  }

  getPartsFromUrl(queryString: string): { code?: string; error?: string } {
    const that: any = this;
    const parts = that.getCodePartsFromUrl(queryString);
    return parts;
  }
}
