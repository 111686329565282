<div class="wen-refresh-banner-content">
    <div class="wen-refresh-banner-info">
        <div class="wen-refresh-banner-info-text">
            <h2>{{ "REFRESH_BANNER_INFO_HEADING" | translate }}</h2>
            <h3>{{ "REFRESH_BANNER_INFO_SUBHEADING" | translate }}</h3>
        </div>
    </div>
    <div class="wen-refresh-banner-buttons-wrapper">
        <wen-interact-button class="wen-refresh-banner-reload-button wen-interact-button-round"
            (click)="reloadBrowser()">
            <h2>{{ "REFRESH_BANNER_RELOAD_BROWSER" | translate }}</h2>
        </wen-interact-button>
    </div>
</div>