import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { WenBreakpointObserver } from '@portal/wen-components';
import { first, map } from 'rxjs';
import { AppConfigurationProvider } from '../../../../core/services/configuration/app-confguration';
import { EXCLUSIVE_INVITE_REDIRECT_AFTER_CODE } from '../../../../core/services/navigation/query-params';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { WenNativeApi } from '@portal/wen-native-api';
import { WenStorageService } from '../../../../core/services/storage/wen-storage.service';
import { WenOAuthService } from '../../../../core/services/user-management/wen-oauth.service';
import { logout } from '../../../../core/store/auth/auth.actions';

@Component({
  selector: 'wen-exclusive-invite',
  templateUrl: './exclusive-invite.component.html',
  styleUrls: ['./exclusive-invite.component.scss']
})
export class ExclusiveInviteComponent implements OnInit {

  headerGravity = this.breakpointObserver.isDesktopStyleDevice ? 'bottom' : 'top';

  formGroup = new FormGroup({
    code: new FormControl('', {
      validators: [
        Validators.required
      ],
      updateOn: 'change'
    }),
  });

  get codeControl() {
    return this.formGroup.controls.code;
  }

  get codeControlValue(): string {
    return this.codeControl.value || '';
  }

  get isSubmitDisabled() {
    const changed = this.codeControl.dirty || this.codeControl.touched;
    const hasValue = Boolean(this.codeControl.value);
    return !changed || !hasValue;
  }

  get waitlistUrl() {
    return this.appConfigurationProvider.contactInfoConfig.waitlistUrl;
  }

  codeFieldType: string;
  codeFieldPattern: string;

  constructor(
    private readonly breakpointObserver: WenBreakpointObserver,
    private readonly store: Store,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly storageService: WenStorageService,
    private readonly oAuthService: WenOAuthService,
    private readonly socketIOService: SocketIoService,
    private readonly appConfigurationProvider: AppConfigurationProvider,
    private readonly nativeApi: WenNativeApi,
  ) {
    if (this.nativeApi.isReactNativeApp()) {
      this.codeFieldType = 'number';
      this.codeFieldPattern = '\\d*';
    } else {
      this.codeFieldType = 'text';
      this.codeFieldPattern = '.*';
    }
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    if (!this.formGroup.valid) {
      return;
    }
    const code = this.codeControlValue.replace(/\s/g, '');

    this.socketIOService.user.verifyInvitationCode.acknowledgement$({ code }).pipe(
      first(),
      map(response => response.ok)
    ).subscribe(isValidCode => {
      if (!isValidCode) {
        this.codeControl.setErrors({
          notValid: true
        });
        return;
      }
      const userId = this.oAuthService.getUserData().userId;
      this.storageService.setExclusiveInviteCodeAdded(userId);
      const redirectUrl = this.route.snapshot.queryParamMap.get(EXCLUSIVE_INVITE_REDIRECT_AFTER_CODE);
      if (redirectUrl) {
        this.router.navigateByUrl(redirectUrl, { replaceUrl: true, state: { fromDeepLink: true } });
      } else {
        this.navigationHelper.navigateToWelcomeScreen({ replaceUrl: true });
      }
    });
  }

  navigateToWaitlist() {
    this.navigationHelper.navigateToUrlWithFallback(this.waitlistUrl);
  }

  onCancel() {
    this.store.dispatch(logout());
  }
}
