import { Injectable } from '@angular/core';
import { ContactRequestStatus } from '@portal/wen-backend-api';
import { ReplaySubject } from 'rxjs';

export interface ContactResponseButtonProps {
  wenVariant: string;
  iconName: string;
  translationKey: string;
  action: (userId: string) => void;
  contextMenu?: 'received' | 'delete';
}

export interface ContactResponseResponseActionData {
  userId: string;
  /**
   * The request status to be mapped to action buttons
   *  If undefined the fallback is that the user can send contact request
   */
  requestStatus: ContactRequestStatus;
}

@Injectable()
export class ContactResponseActionDataWrapper {

  private profile = new ReplaySubject<ContactResponseResponseActionData>(1);
  profile$ = this.profile.asObservable();

  setProfileData(value: ContactResponseResponseActionData) {
    this.profile.next(value);
  }
}
