import { Injectable } from '@angular/core';
import { WenNativeApi } from '@portal/wen-native-api';
import { ConnectionMonitor } from './types';
import { startWith, distinctUntilChanged, shareReplay, filter } from 'rxjs';

@Injectable()
export class NativeConnectionMonitor implements ConnectionMonitor {
  public readonly isOnline$ = this.nativeApi.connectivityChange$.pipe(
    filter(value => value !== null),
    startWith(navigator.onLine),
    distinctUntilChanged(),
    shareReplay(1),
  );
  constructor(private nativeApi: WenNativeApi) { }
}
