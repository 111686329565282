export const CRM_RELEVANT_FIELDS_META = [
  'MAILFIELDSTR1',
  'MAILFIELDSTR2',
  'MAILFIELDSTR3',
  'MAILFIELDSTR4',
  'MAILFIELDSTR5',
  'GGUID',
  'CHRISTIANNAME',
  'NAME',
  'COMPNAME',
  'WENETWORKID',
] as const;
