import type { IMassDataObjectContextItem, IMassDataResponse, SmartDesign } from '@smartdesign/web-api';
import { DataObjectFactory } from './crm-data-object';

export abstract class SelectionProvider<T = never> {

  protected api: SmartDesign.IAPI<any>;
  protected selection: Promise<T[]>;
  protected abstract relevantFields: string[];

  constructor(
    private objectFactory: DataObjectFactory<T>,
    private massDataPagingPageSize: number = 1000,
  ) { }

  async loadSelection(): Promise<T[]> {
    if (this.selection) {
      return this.selection;
    }
    const doData = this.loadPrimaryDataObject();
    const mdData = this.loadPrimaryMassData();
    this.selection = Promise.all([
      doData, mdData
    ]).then(([doResult, mdResult]) => {
      const doSelection = doResult ? [doResult] : [];
      const mdSelection = mdResult ? mdResult : [];
      return [
        ...doSelection, ...mdSelection
      ];
    });
    return this.selection;
  }

  async loadPrimaryDataObject() {
    try {
      const primaryDataContextItem = await this.api.Context.getPrimaryDataObject();
      const dataObject = await primaryDataContextItem.getFields(...this.relevantFields);
      if (!dataObject) {
        return null;
      }
      return this.objectFactory.toCrmDataObject(dataObject, false);
    } catch {
      return null;
    }
  }

  protected async loadPrimaryMassData() {
    try {
      if (!this.api.Context.primaryMassDataAccessorSupported) {
        return [];
      }
      const primaryMassDataContextItem = await this.api.Context.getPrimaryMassData();
      const data = await this.loadPrimaryMassDataPaged(primaryMassDataContextItem, this.relevantFields);
      if (!data.rows?.length) {
        return [];
      }
      return data.rows
        .map((dataObject) => this.objectFactory.toCrmDataObject(dataObject, true));
    } catch {
      return [];
    }
  }

  private async loadPrimaryMassDataPaged(contextItem: IMassDataObjectContextItem, fieldNames: string[], where?: string) {
    let fromIndex = 0;
    let lastResult: IMassDataResponse<any>;
    let result: IMassDataResponse<any>;
    do {
      lastResult = await contextItem.load(fieldNames, fromIndex, this.massDataPagingPageSize, where);
      fromIndex = fromIndex + this.massDataPagingPageSize;
      if (!result) {
        result = lastResult;
      } else {
        result.columns = { ...result.columns, ...lastResult.columns };
        result.rows = [...result.rows, ...lastResult.rows];
      }
      // If the last page is not complete there are no more items and an unnecessary request can be skipped
      if (result.rows.length < fromIndex) {
        return result;
      }
    } while (lastResult.rows.length > 0);
    return result;
  }

}
