import { NgModule } from '@angular/core';
import { AlwaysHomeBackNavigation } from './always-home-back-navigation';
import { DraftEditLeaveConfirmationBackNavigation } from './draft-edit-leave-confirmation-behavior';
import { EmbeddedAppBackNavigation } from './embedded-app-back-navigation-behavior';
import { FormEditLeaveConfirmationBackNavigation } from './form-edit-leave-confirmation-behavior';
import { MinimizeNativeAppBackNavigation } from './minimize-native-app-behavior';
import { ProfileSubMenuBackNavigationBehavior } from './profile-sub-menu-back-navigation-behavior';
import { SuppressBackNavigationOrMinimizeApp } from './suppress-back-navigation-or-minimize-app-behavior';
import { SuppressBackNavigation } from './suppress-back-navigation-behavior';


@NgModule({
  providers: [
    AlwaysHomeBackNavigation,
    SuppressBackNavigationOrMinimizeApp,
    SuppressBackNavigation,
    MinimizeNativeAppBackNavigation,
    FormEditLeaveConfirmationBackNavigation,
    DraftEditLeaveConfirmationBackNavigation,
    ProfileSubMenuBackNavigationBehavior,
    EmbeddedAppBackNavigation,
  ]
})
export class BackNavigationBehaviorsModule { }
