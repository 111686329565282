import { timer } from 'rxjs';
import { scan, takeWhile } from 'rxjs/operators';

export const createTimer = (from: number) => {
  return timer(0, 1000).pipe(
    scan(acc => --acc, from),
    takeWhile(x => x >= 0)
  );
};

export const createServerTimeout = () => {
  const MAX_SERVER_TRYOUT_TIME_IN_MILLIS = 8.5 * 60 * 1000;
  return timer(MAX_SERVER_TRYOUT_TIME_IN_MILLIS);
};
