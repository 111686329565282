import { ContentHandlerType, ContentSchema } from '@portal/wen-backend-api';
import { ProcessedGroupProps } from './models/model';
import { ContentHandlers } from './schema-content-handlers';

export const toProcessedGroup = <T = ContentSchema>(
  currentHandler: ContentHandlerType,
  contentHandlers: ContentHandlers,
  context: T
): ProcessedGroupProps<T> => {
  return {
    componentType: contentHandlers[currentHandler],
    context
  };
};
