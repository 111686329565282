import { Injectable, inject } from '@angular/core';
import { ContactRequestStatus } from '@portal/wen-backend-api';
import { Observable, combineLatest, map } from 'rxjs';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';
import { ContactResponseConnectionService } from './contact-response-connection-data.service';

export enum ContactRequestUiState {
  CAN_SEND = 'CAN_SEND',
  CAN_WITHDRAW = 'CAN_WITHDRAW',
  CAN_HANDLE_RECEIVED = 'CAN_HANDLE_RECEIVED',
  CAN_REMOVE_CONTACT = 'CAN_REMOVE_CONTACT',
}

@Injectable()
export class ContactResponseActionsConditions {

  private featureEnablementService = inject(FeatureEnablementService);
  private userContactConnectionService = inject(ContactResponseConnectionService);

  public contactRequestUiState$: Observable<ContactRequestUiState>;

  constructor() {
    const contactEnabled$ = this.featureEnablementService.featureConfigObs.enableContacts$;
    this.contactRequestUiState$ = combineLatest([
      this.userContactConnectionService.requestStatus$,
      contactEnabled$
    ]).pipe(
      map(([requestStatus, contactEnabled]) => {
        if (!contactEnabled) {
          return null;
        }
        switch (requestStatus) {
          case ContactRequestStatus.RECIPIENT:
            return ContactRequestUiState.CAN_WITHDRAW;
          case ContactRequestStatus.REQUESTER:
            return ContactRequestUiState.CAN_HANDLE_RECEIVED;
          case ContactRequestStatus.ACCEPTED:
            return ContactRequestUiState.CAN_REMOVE_CONTACT;
          default:
            return ContactRequestUiState.CAN_SEND;
        }
      })
    );
  }

}
