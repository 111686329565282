import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from '../root/public-api';
import { reactionsFeatureKey } from './constants';
import { reactionAdapter, ReactionsState } from './reaction.state';

export interface WithFeatureState extends RootState {
  [reactionsFeatureKey]: ReactionsState;
}

export const selectReactionsState = createFeatureSelector<ReactionsState>(
  reactionsFeatureKey
);

export const selectReactionsEntityState = createSelector(
  selectReactionsState,
  state => state.reactions
);

const {
  selectAll: selectReactions,
} = reactionAdapter.getSelectors(selectReactionsEntityState);


export const selectReactionSummaryFor = (referenceId: string) => createSelector(
  selectReactions,
  reactionEntities => {
    const matchingReaction = reactionEntities.length && reactionEntities.find(rE => rE?.referenceId === referenceId);
    return matchingReaction ? matchingReaction.reactionSummary : null;
  }
);
