import { EntryId, UserProfileMenuEntry } from '../../user-profile-view-types';
import { ColorPalette } from '../../../../../../../shared/directives/colorize/palette-provider';
import { PermissionLevel } from '../../../../../../../core/services/user-management/permission-level';

export const selectLanguage: UserProfileMenuEntry = {
  entryId: EntryId.SELECT_LANGUAGE,
  iconName: 'language', translationKey: 'USER_PROFILE_LANGUAGE',
  fillColor: ColorPalette.PINK, requiredPermission: PermissionLevel.ANONYMOUS, featureToggleBased: true
};

export const privacySettings: UserProfileMenuEntry = {
  entryId: EntryId.PRIVACY_SETTINGS,
  iconName: 'lock', translationKey: 'USER_PROFILE_PRIVACY_SETTINGS',
  fillColor: ColorPalette.DARK_BLUE, requiredPermission: PermissionLevel.ANONYMOUS,
  featureToggleBased: true
};
