import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Renderer2 } from '@angular/core';
import { generateId } from '@portal/wen-backend-api';
import { FeedRenderingRegistry } from '@portal/wen-components';
import LazySizes from 'lazysizes';
import { ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { ContentBoundaryProvider } from '../content-boundary/content-boundary.directive';
import { ScaleHelper } from '../util/scale-helper';

export interface LazyLoadParams {
  src: string;
  originalWidth?: number;
  originalHeight?: number;
  maxHeight?: number;
  minHeight?: number;
}

LazySizes.init();

@Directive({
  selector: '[wenLazyload]',
})
export class LazyLoadDirective implements OnInit, OnDestroy {

  private onDestroy = new Subject<void>();

  private params = new ReplaySubject<LazyLoadParams>(1);

  @HostBinding('class.wen-lazy-load') lazyLoadClass = true;

  @Input('wenLazyload')
  set lazyLoadParams(params: LazyLoadParams | string) {
    if (typeof params === 'string') {
      this.params.next({ src: params });
    } else {
      this.params.next(params);
    }
  }

  @Input('wenLazyloadFixWidth') fixWidth: number;
  @Input('wenLazyloadDisableScaling') disableScaling: boolean;

  private renderingProgressId = generateId();
  private scaler: ScaleHelper;

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    @Optional() private contentBoundaryProvider: ContentBoundaryProvider,
    @Optional() private feedRenderingRegistry: FeedRenderingRegistry,
  ) {
    this.scaler = new ScaleHelper(element, renderer);
  }

  private updateElement(params: LazyLoadParams) {
    const existingSrc = this.element.nativeElement.attributes.getNamedItem('data-src')?.value;
    if (existingSrc !== params.src) {
      this.renderer.addClass(this.element.nativeElement, 'lazyload');
      this.renderer.setAttribute(this.element.nativeElement, 'data-src', params.src);
      const image = this.element.nativeElement as HTMLImageElement;
      if (image?.src) {
        image.src = params.src;
      }
    }
  }

  ngOnInit() {
    if (this.feedRenderingRegistry) {
      this.feedRenderingRegistry.registerRenderingProgress(this.renderingProgressId);
    }
    const availableWidth$ = this.contentBoundaryProvider ? this.contentBoundaryProvider.contentBoundary$.pipe(
      map(({ width }) => width)
    ) : of(this.fixWidth);

    combineLatest([
      availableWidth$,
      this.params
    ]).pipe(
      filter(([_, params]) => !!params),
      distinctUntilChanged(([a, b], [aa, bb]) => {
        const sameSrc = b.src === bb.src;
        const sameSize = a === aa;
        return sameSrc && sameSize;
      }),
      tap(([availableWidth, params]) => {
        const { originalWidth, originalHeight, maxHeight, minHeight: minHeigth } = params || {};
        const hasKnownImageSize = originalWidth && originalHeight;
        if (this.disableScaling || !hasKnownImageSize) {
          this.scaler.setElementSize(availableWidth);
        } else if (availableWidth) {
          this.scaler.scaleElementToWidth(originalWidth, originalHeight, availableWidth, maxHeight, minHeigth);
        }
      }),
      takeUntil(this.onDestroy)
    ).subscribe(([_, params]) => {
      this.updateElement(params);
      if (this.feedRenderingRegistry) {
        this.feedRenderingRegistry.clearRenderingProgress(this.renderingProgressId);
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
