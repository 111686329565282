import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { WenHorizontalCardComponent } from './wen-horizontal-card/wen-horizontal-card.component';
import { WenHorizontalDescriptionDirective, WenHorizontalCardImageDirective, WenHorizontalCardLinkDirective, WenHorizontalCardTitleDirective } from './wen-horizontal-card/wen-horizontal-card.directives';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule
  ],
  declarations: [
    WenHorizontalCardComponent,
    WenHorizontalCardImageDirective,
    WenHorizontalCardTitleDirective,
    WenHorizontalDescriptionDirective,
    WenHorizontalCardLinkDirective,
  ],
  exports: [
    WenHorizontalCardComponent,
    WenHorizontalCardImageDirective,
    WenHorizontalCardTitleDirective,
    WenHorizontalDescriptionDirective,
    WenHorizontalCardLinkDirective,
  ]
})
export class WenCardsModule { }
