import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { AppConfigurationProvider } from '../../../../core/services/configuration/app-confguration';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { WenOAuthService } from '../../../../core/services/user-management/wen-oauth.service';
import { ProgressState } from '../../../../core/store/ui/ui.state';
import { WenRouteId } from '../../../../frame/routing/types';
import { SilentLogoutComponent } from '../../../login/components/silent-logout/silent-logout.component';
import { SignInDefaultHandler } from '../../services/sign-in-default-actions-handler';
import { SignInInviteActionsHandler } from '../../services/sign-in-invite-actions-handler';
import { SignInPopupActionsHandler } from '../../services/sign-in-popup-actions-handler';
import { SignInActionsHandler, SignInViewConfig } from '../../services/types';
import { TranslateService } from '@ngx-translate/core';

const signInActionsFactory = (
  navigationHelper: WenNavigationHelper,
  wenOAuthService: WenOAuthService,
  config: AppConfigurationProvider,
  router: Router,
  activatedRoute: ActivatedRoute,
  translateService: TranslateService
) => {
  switch (activatedRoute.snapshot.data.routeId) {
    case WenRouteId.SIGN_IN_POPUP:
      return new SignInPopupActionsHandler(navigationHelper, wenOAuthService, config, router, activatedRoute, translateService);
    case WenRouteId.SIGN_IN_INVITE:
      return new SignInInviteActionsHandler(navigationHelper, wenOAuthService, config, activatedRoute, translateService);
    default:
      return new SignInDefaultHandler(navigationHelper, wenOAuthService, config, activatedRoute, translateService);
  }
};

@Component({
  selector: 'wen-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [{
    provide: SignInActionsHandler, useFactory: signInActionsFactory,
    deps: [WenNavigationHelper, WenOAuthService, AppConfigurationProvider, Router, ActivatedRoute, TranslateService]
  }]
})
export class SignInComponent implements OnInit, AfterViewInit {

  config: SignInViewConfig;
  hasProgress$: Observable<ProgressState> = of({ hasProgress: false } as ProgressState);
  @ViewChild(SilentLogoutComponent) silentLogout: SilentLogoutComponent;

  constructor(
    private readonly actions: SignInActionsHandler
  ) { }

  ngAfterViewInit(): void {
    this.actions.init(this.silentLogout.logoutInProgress$);
    this.hasProgress$ = this.silentLogout.logoutInProgress$.pipe(
      map(inProgress => ({ hasProgress: inProgress } as ProgressState))
    );
  }

  ngOnInit(): void {
    this.config = this.actions.viewConfig;
  }

  goToRegister() {
    this.actions.goToRegister();
  }

  goToLogin() {
    this.actions.goToLogin();
  }

  goToAnonymousLogin() {
    this.actions.goToAnonymousLogin();
  }

  openTermsAndConditionsPage() {
    return this.actions.openTermsAndConditionsPage();
  }

  openPrivacyPage() {
    return this.actions.openPrivacyPage();
  }

}
