import { LocationStrategy } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { WenPathLocationStrategy } from '@portal/wen-components';
import { map, of } from 'rxjs';
import { WenRouteId, WenRouteWithId } from '../../../../frame/routing/types';
import { toObservable } from '../../../common/operators/observable-utils';
import { BackNavigationBehavior } from '../back-navigation/back-navigation-behaviors/back-navigation-behavior';
import { AlwaysHomeBackNavigation } from './back-navigation-behaviors/always-home-back-navigation';
import { injectBehavior } from './back-navigation-utils';

@Injectable()
export class BackNavigationHelper {

  constructor(
    @Inject(LocationStrategy) private readonly location: WenPathLocationStrategy,
    private router: Router,
    private injector: Injector,
  ) { }

  private doBackNavigate() {
    const locationState = this.location.getState();
    const historyStack = locationState?.historyStack;
    let navigationCommand: (...args: any) => any = () => this.location.back();
    if (historyStack?.length > 1) {
      const previousUrl = historyStack.pop();
      navigationCommand = () => this.router.navigateByUrl(previousUrl, { replaceUrl: true, state: { historyStack } });
    }
    navigationCommand();
    return of(null);
  }

  handleBackNavigation(topLevelOutletId: WenRouteId, sidebarOutletId: WenRouteId, data: Partial<WenRouteWithId>) {
    const locationState = this.location.getState();
    let backNavigationBehavior = data?.backNavigationBehavior;
    if (!backNavigationBehavior && locationState?.fromDeepLink) {
      backNavigationBehavior = AlwaysHomeBackNavigation;
    }
    if (backNavigationBehavior) {
      const behavior: BackNavigationBehavior = injectBehavior(backNavigationBehavior, this.injector);
      return toObservable(behavior.execute({ topLevelOutletId, sidebarOutletId }, data)).pipe(
        map((result) => {
          if (result.canHandle) {
            return of(null);
          }
          return this.doBackNavigate();
        })
      );
    }
    return this.doBackNavigate();
  }

}
