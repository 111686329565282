import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, Subject, merge, of, throwError } from 'rxjs';
import { first, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { existy } from '../../../core/common/operators/existy';
import { isAbsoluteUrl } from '@portal/wen-common';
import { EmbeddedAppNavigator } from '../../../frame/embedded-api/api-parts/navigation/embedded-app-navigator';
import { EmbeddedApiService } from '../../../frame/embedded-api/embedded-api-service';
import { EmbeddedAppData, EmbeddedUrlResolver } from './providers/embedded-url-resolver';
import { embeddedUrlResolverProvider } from './providers/tokens';
import { EmbeddedAppUrlChangeEmitter } from './types/embedded-app-types';

@Component({
  selector: 'wen-embedded-page-viewer',
  templateUrl: './embedded-page-viewer.component.html',
  styleUrls: ['./embedded-page-viewer.component.scss'],
  providers: [
    embeddedUrlResolverProvider,
    EmbeddedApiService
  ]
})
export class EmbeddedPageViewerComponent implements OnInit, AfterViewInit, OnDestroy {

  private onDestroy = new Subject<void>();

  appData$: Observable<EmbeddedAppData>;
  appUrl$: Observable<string>;
  frameUrl$: Observable<SafeUrl>;

  @ViewChildren('embeddedWindow') embeddedWindowQuery: QueryList<ElementRef>;

  constructor(
    private embeddedUrlResolver: EmbeddedUrlResolver,
    private sanitizer: DomSanitizer,
    private embeddedApiService: EmbeddedApiService,
    @Inject(EmbeddedAppNavigator) private embeddedAppUrlChangeEmitter: EmbeddedAppUrlChangeEmitter,
  ) { }

  ngOnInit() {
    this.appData$ = this.embeddedUrlResolver.resolveEmbeddedData().pipe(
      shareReplay(1)
    );
    this.appUrl$ = this.appData$.pipe(
      map(data => data?.url)
    );
    const changes$ = this.embeddedAppUrlChangeEmitter.onUrlChanged$;
    this.frameUrl$ = merge(this.appUrl$, changes$).pipe(
      switchMap((url) => {
        if (!isAbsoluteUrl(url)) {
          return throwError('Embedded page url must be absolute url!');
        }
        return of(this.sanitizer.bypassSecurityTrustResourceUrl(url));
      }),
      existy()
    );
  }

  ngAfterViewInit(): void {
    const initial$ = of(this.embeddedWindowQuery.first);
    const changes$ = this.embeddedWindowQuery.changes;
    const frameInit$ = merge(initial$, changes$).pipe(
      shareReplay(1)
    );
    this.appData$.pipe(
      switchMap((appData) => {
        return frameInit$.pipe(
          first(),
          map((change) => {
            const frameElement = change?.nativeElement as HTMLIFrameElement;
            return { frameElement, appData };
          })
        );
      }),
      takeUntil(this.onDestroy)
    ).subscribe(({ frameElement, appData }) => {
      const { embeddedApiSupported } = appData;
      this.embeddedApiService.dispose();
      if (frameElement && embeddedApiSupported) {
        this.embeddedApiService.init(frameElement);
        // TODO: implement proper change detection and cleanup mechanism
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}
