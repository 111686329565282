import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { AppNavigator } from '../../../../../../core/services/navigation/app-navigator';
import { selectCurrentChannel } from '../../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';
import { WenRouteId } from '../../../../../../frame/routing/types';
import { ChannelViewPreloaderGuard } from '../../../../common/providers/channel-view-preloader.guard';
import { channelViewIdentifier } from '../../../../tokens';

export const channelEditPermissionGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store<RootState>);
  const channelViewPreloaderGuard = inject(ChannelViewPreloaderGuard);
  const appNavigator = inject(AppNavigator);
  return channelViewPreloaderGuard.ensureCurrentChannelPermissionsLoaded().pipe(
    withLatestFrom(store.pipe(select(selectCurrentChannel))),
    first(),
    map(([permission, channel]) => {
      if (permission?.canEdit) {
        return true;
      }
      appNavigator.navigateToRoute(WenRouteId.CHANNEL_DETAIL, { [channelViewIdentifier]: channel.id });
      return false;
    })
  );
};

export const channelSendPermissionGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store<RootState>);
  const channelViewViewguard = inject(ChannelViewPreloaderGuard);
  const appNavigator = inject(AppNavigator);
  return channelViewViewguard.ensureCurrentChannelPermissionsLoaded().pipe(
    withLatestFrom(store.pipe(select(selectCurrentChannel))),
    first(),
    map(([permission, channel]) => {
      if (permission?.canSend) {
        return true;
      }
      appNavigator.navigateToRoute(WenRouteId.CHANNEL_DETAIL, { [channelViewIdentifier]: channel.id });
      return false;
    })
  );
};
