import { Injectable, inject } from '@angular/core';
import { MassDataContainer } from '@portal/wen-data-core';
import { map } from 'rxjs';
import { ListMassDataViewerContainerConnector } from '../../../../../../core/services/mass-data/list-mass-data-viewer-container-connector';
import { ChatMemberEditSearchEntity } from '../../../../../../core/store/chat/mass-data/providers/chat-member-edit-state-types';
import { ChatSelectionListItemModel, ChatSelectionListSelectionProvider } from './chat-selection-list-providers';

export interface ChatCreationListItemModel extends ChatMemberEditSearchEntity {
  selected: boolean;
}

@Injectable()
export class ChatSelectionListContainerConnector<T extends MassDataContainer<ChatMemberEditSearchEntity>>
  extends ListMassDataViewerContainerConnector<T, ChatSelectionListItemModel> {

  private selectionprovider = inject(ChatSelectionListSelectionProvider);

  protected override decorateItems(items: ChatMemberEditSearchEntity[]) {
    return this.selectionprovider.selectedIds$.pipe(
      map((selectedIds) => {
        return items.map((item) => {
          const listModel: ChatCreationListItemModel = {
            ...item,
            selected: selectedIds.includes(item.listItemEntity.id)
          };
          return listModel;
        });
      })
    );
  }

}
