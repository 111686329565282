import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoReplyTextComponent } from './components/auto-reply-text.component';
import { RemoveMarkdownModule, WenIconModule } from '@portal/wen-components';

@NgModule({
  declarations: [
    AutoReplyTextComponent
  ],
  imports: [
    CommonModule,
    WenIconModule,
    RemoveMarkdownModule
  ],
  exports: [
    AutoReplyTextComponent
  ]
})
export class AutoReplyTextModule { }
