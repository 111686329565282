import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoginModule } from '../../login/login.module';
import { RegistrationCommonModule } from '../common/registration-common.module';
import { RegistrationCodeResendComponent } from './components/registration-code-resend/registration-code-resend.component';
import { RegistrationCodeVerificationComponent } from './components/registration-code-verification/registration-code-verification.component';

@NgModule({
  imports: [
    RegistrationCommonModule,
    MatProgressSpinnerModule,
    LoginModule
  ],
  declarations: [
    RegistrationCodeVerificationComponent,
    RegistrationCodeResendComponent
  ],
  exports: [
    RegistrationCodeVerificationComponent
  ]
})
export class RegistrationCodeVerificationModule { }
