import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { selectorWithParam } from '../../../../../../core/common/util/selector-with-param';
import { selectLatestMessageForChat, selectNotificationCountForChat } from '../../../../../../core/store/notification/notification.selectors';
import { ChatNotificationEventEntity } from '../../../../../../core/store/notification/notification.state';
import { RootState } from '../../../../../../core/store/root/public-api';

export interface LatestMessageContentResponse {
  entity: ChatNotificationEventEntity;
}

@Injectable()
export class ChatListItemDatasource {

  constructor(
    private store: Store<RootState>,
  ) { }

  getLatestMessageContent(chatId: string): Observable<LatestMessageContentResponse> {
    return this.store.pipe(
      selectorWithParam(selectLatestMessageForChat, chatId),
      map((entity) => ({ entity }))
    );
  }

  getLatestNotificationFor(chatId: string): Observable<number> {
    return this.store.pipe(
      selectorWithParam(selectNotificationCountForChat, chatId),
    );
  }

}
