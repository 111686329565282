import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { first, tap } from 'rxjs/operators';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { RootState } from '../../root/public-api';
import { setSubscriptionToApp, setSubscriptionToCurrentApp } from '../apps.actions';
import { selectCurrentAppDetail } from '../apps.selectors';

export const createSetAppSubscriptionEffect = (
  store: Store<RootState>,
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'app'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(setSubscriptionToCurrentApp),
    mapWithFirstFrom(() => store.pipe(select(selectCurrentAppDetail), first())),
    tap(([{ setSubscribed }, app]) => {
      if (setSubscribed) {
        socketIoService.app.subscribe.emit({ appId: app.id });
      } else {
        socketIoService.app.unsubscribe.emit({ appId: app.id });
      }
    }),
  ), { dispatch: false });
};


export const createSetAppSubscriptionWithIdEffect = (
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'app'>
) => {
  return createEffect(() => actions$.pipe(
    ofType(setSubscriptionToApp),
    tap(({ id, setSubscribed }) => {
      if (setSubscribed) {
        socketIoService.app.subscribe.emit({ appId: id });
      } else {
        socketIoService.app.unsubscribe.emit({ appId: id });
      }
    }),
  ), { dispatch: false });
};
