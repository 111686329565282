import { Component, HostBinding, Input, inject, ViewEncapsulation, OnInit } from '@angular/core';
import { GeoWeatherFeature, GeoWeatherHourly } from '@portal/wen-backend-api';
import { ContextMenuData, CONTEXT_MENU_DATA, toWeatherModel, WeatherModel } from '@portal/wen-components';
import { DateUtil } from '../../../../../../../../core/common/date/date-util';

const EMBED_WEATHER_ICONS_PATH = '/assets/wen-widget/image/weather/embed';

@Component({
  selector: 'wen-weather-context-menu',
  templateUrl: './weather-context-menu.component.html',
  styleUrls: ['./weather-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WeatherContextMenuComponent implements OnInit {

  private contextMenuData: ContextMenuData<GeoWeatherFeature> = inject(CONTEXT_MENU_DATA, { optional: true });

  weatherViewModel: WeatherModel;
  weatherForecasts: GeoWeatherHourly[];
  weatherDate: string;

  @HostBinding('class.weather-context-menu') className = true;

  @Input() weatherFeature: GeoWeatherFeature;

  ngOnInit() {
    this.setMenuOrInputData(this.contextMenuData);
  }

  private setMenuOrInputData(ctxMenuData: ContextMenuData<GeoWeatherFeature>) {
    const value = ctxMenuData?.data ?? this.weatherFeature;
    this.weatherViewModel = toWeatherModel(value, EMBED_WEATHER_ICONS_PATH);
    this.weatherForecasts = value.properties.hourly;
    this.weatherDate = DateUtil.toDateMed(DateUtil.fromIsoString(this.weatherForecasts[0].timestamp));
  }
}
