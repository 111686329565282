import { WenRoutes } from '../../../frame/routing/types';
import { userProfileReadRoute, userProfileEditRoute, userProfileLanguageRoute, userProfileEmailSettingsRoute, userProfileChangeEmailRoute, userProfileSettingsRoute, userProfileCredentialsMenuRoute, otherProfileViewRoute, userProfileCredentialDetails, userProfilePrivacySettingsRoute, userProfileHelpRoute, userSettingsRoute, userProfileHotlineRoute, userProfileViewRoute, userProfileDashboardRoute, userProfileManifestRoute, userProfileFAQRoute, userProfileMarkdownFAQRoute, userProfileComingSoonRoute, licensesRoute, userPersonalAccountSettingsRoute, userChangePasswordRoute, userChangePasswordVerifyCodeRoute } from './user-profile.routes';

export const dialogOutletProfileRoutes: WenRoutes = [
  userProfileReadRoute,
  userProfileEditRoute,
  userProfileLanguageRoute,
  userProfileEmailSettingsRoute,
  userProfileChangeEmailRoute,
  userChangePasswordRoute,
  userChangePasswordVerifyCodeRoute,
  userProfileSettingsRoute,
  userPersonalAccountSettingsRoute,
  userProfileCredentialsMenuRoute,
  otherProfileViewRoute,
  userProfileCredentialDetails,
  userProfilePrivacySettingsRoute,
];

export const sidebarOutletUserProfileRoutes: WenRoutes = [
  userProfileHelpRoute,
  userSettingsRoute,
  userProfileHotlineRoute,
  userProfileViewRoute,
  {
    path: '**',
    redirectTo: ''
  }
];

export const primaryOutletUserProfileRoutes: WenRoutes = [
  userProfileDashboardRoute,
  userProfileManifestRoute,
  userProfileFAQRoute,
  userProfileMarkdownFAQRoute,
  userProfileComingSoonRoute,
  licensesRoute,
  {
    path: '**',
    redirectTo: '/home/dashboard(sidebar:profile)'
  }
];
