import type * as SentryTypes from '@sentry/angular';
import { EMPTY, first, from, Observable, shareReplay } from 'rxjs';

let SentryModule$: Observable<typeof SentryTypes>;

/**
 * Make sure that the angular sentry module is loaded
 * This will load the sentry source code and must only be used if the client enables it!
 */
export const ENSURE_SENTRY_LIB_LOADED = () => {
  if (SentryModule$) {
    return;
  }
  SentryModule$ = from(import('@sentry/angular')).pipe(
    first(),
    shareReplay(1)
  );
};

/**
 * Get the angular sentry module if it was loaded before
 * This utility function should be used instead of referencing sentry directly with an import
 *  to make sure the sentry source code is lazy loaded!
 */
export const getSentryIfLoaded = () => {
  if (!SentryModule$) {
    return EMPTY;
  }
  return SentryModule$.pipe(first());
};
