import { Injectable } from '@angular/core';
import { Actions, createEffect, OnInitEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, filter, map, throttleTime } from 'rxjs';
import { PersistenceStorage } from '../providers/persistence-storage';
import { rehydrateAppState, rehydrateSkipped, scheduleSaveAppState } from './persistence.actions';

const INIT_ACTION = '@ngrx/store/init';
const SAVE_STATE_INTERVAL = 20000;

@Injectable()
export class PersistenceEffects implements OnInitEffects {

  scheduleSaveAppState$ = createEffect(() => this.actions$.pipe(
    filter(action => action.type !== INIT_ACTION),
    debounceTime(500),
    throttleTime(SAVE_STATE_INTERVAL),
    map(() => scheduleSaveAppState())
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly persistenceStorage: PersistenceStorage,
  ) { }

  ngrxOnInitEffects() {
    this.persistenceStorage.restoreData().subscribe((restoredState) => {
      if (!restoredState) {
        this.store.dispatch(rehydrateSkipped());
      } else {
        this.store.dispatch(rehydrateAppState({ restoredState: { ...restoredState } }));
      }
    });
    return { type: '__PERSISTENCE_INIT_ACTION__' };
  }

}
