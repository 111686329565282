export enum RestrictionType {
  NoRestriction = 'no-restriction',
  PasswordRequired = 'password',
  RegistrationRequired = 'registered-user',
  PrivilegesRequired = 'has-privilege',
  RequestOnly = 'request-only',
  IsManaged = 'isManaged',
  WalletCredentialRequired = 'wallet-credential',
}

export enum RestrictionContextType {
  Channel = 'channel'
}

export interface RestrictionDTO {
  id: string;
  restrictionType: RestrictionType;
  fulfilled?: boolean;
}

interface RestrictionResolveObject<T extends RestrictionType> {
  privilege?: string;
  type?: T;
}

export interface RestrictionUpdateResponseDTO {
  id: string;
  type: RestrictionType;
  insertTimestamp: string;
  insertUser: string;
}

export interface PasswordRestrictionResolveObject extends RestrictionResolveObject<RestrictionType.PasswordRequired> {
  password: string;
}

export interface PrivilegeRestrictionResolveObject extends RestrictionResolveObject<RestrictionType.PrivilegesRequired> {
  privilege: string;
}

export type RestrictionResolveObjectType = RestrictionResolveObject<RestrictionType.NoRestriction>
  | RestrictionResolveObject<RestrictionType.RegistrationRequired>
  | RestrictionResolveObject<RestrictionType.RequestOnly>
  | RestrictionResolveObject<RestrictionType.WalletCredentialRequired>
  | PasswordRestrictionResolveObject
  | PrivilegeRestrictionResolveObject;

export const updateToRestrictionDTO = (restrictionUpdate: RestrictionUpdateResponseDTO) => {
  const convertedRestriction: RestrictionDTO = {
    id: restrictionUpdate.id,
    fulfilled: true,
    restrictionType: restrictionUpdate.type
  };
  return convertedRestriction;
};
