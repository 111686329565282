import { MediaContextTypes } from '../../events/events';
import { ForwardData } from '../forward/forward';
import { GeoAutocompleteFeature, GeoFeatureEntityType } from '../geo/autocomplete';
import { GeoWeatherFeature } from '../geo/weather';
import { NotificationData } from '../notification/notification';
import { PollModel } from '../poll/poll';

export enum EmbedDTOType {
  LINK = 'link',
  EXPANDABLE = 'expandable',
  POLL = 'poll',
  MEDIA = 'media',
  LOCATION = 'location',
  DOCUMENT = 'document',
  WEATHER = 'weather',
  NOTIFICATION = 'notification',
  FORWARD = 'forward',
  QUOTE = 'quote'
}

export interface EmbeddedDocumentDTO extends EmbedDTO<EmbedDTOType.DOCUMENT> {
  title: string;
  description?: string;
  docId: string;
  articleId?: string;
  startPage?: number;
  subType: DocumentType;
  thumbnail?: {
    url: string;
    width: number;
    height: number;
  };
}

export interface EmbeddedLinkDTO extends EmbedDTO<EmbedDTOType.LINK> {
  title: string;
  description?: string;
  url: string;
  thumbnail?: {
    url: string;
    width: number;
    height: number;
  };
}
export interface EmbeddedExpandableDTO extends EmbedDTO<EmbedDTOType.EXPANDABLE> {
  title: string;
  description: string;
}

export interface EmbeddedPollDTO extends EmbedDTO<EmbedDTOType.POLL> {
  pollId: string;
  poll: PollModel;

}

export type EmbeddedMediaDTO = EmbedDTO<EmbedDTOType.MEDIA> & {
  id: string;
  publicId?: string;
  subType: MediaType;
  width?: number;
  height?: number;
  file?: File;
  thumbnailUrl?: string;
  fullUrl?: string;
  uploadId?: string;
  rawUrl?: string;
  hlsUrl?: string;
  mimeType?: string;
  uploaded?: boolean;
  playable?: boolean;
  fullyProcessed?: boolean;
  error?: boolean;
  errorCode?: string;
  markedForRemove?: boolean;
  title?: string;
  sizeInBytes?: number;
} & Partial<{ contextId: string; contextType: MediaContextTypes }>
  & Partial<{ oldUploadId: string }>;

export interface EmbeddedLocationDTO extends EmbedDTO<EmbedDTOType.LOCATION> {
  locationData: GeoAutocompleteFeature;
  geoEntityTypeRestriction?: GeoFeatureEntityType[];
}

export interface EmbeddedWeatherDTO extends EmbedDTO<EmbedDTOType.WEATHER> {
  weatherData: GeoWeatherFeature;
}

export interface EmbeddedNotificationDTO extends EmbedDTO<EmbedDTOType.NOTIFICATION> {
  notificationData: NotificationData;
}

export interface EmbeddedForwardDTO extends EmbedDTO<EmbedDTOType.FORWARD> {
  forwardData: ForwardData;
}

export interface EmbeddedQuoteDTO extends EmbedDTO<EmbedDTOType.QUOTE> {
  quotedItemId: string;
}

export enum MediaType {
  VIDEO = 'video',
  PICTURE = 'picture',
  AUDIO = 'audio',
  DOCUMENT = 'document',
  UPLOAD = 'upload',
  PROCESS = 'process',
  ERROR = 'error'
}

export enum DocumentType {
  ISSUE = 'issue',
  ARTICLE = 'article',
}

export type GetMediaPayload = {
  uploadId: string;
  contextType: MediaContextTypes;
  contextId: string;
};

export const getMediaType = (file: File) => {
  if (!file) {
    return null;
  }
  let type: MediaType;
  if (file.type.includes('image/')) {
    type = MediaType.PICTURE;
  } else if (file.type.includes('video/')) {
    type = MediaType.VIDEO;
  } else if (file.type.includes('audio/')) {
    type = MediaType.AUDIO;
  } else {
    type = MediaType.DOCUMENT;
  }
  return type;
};

interface EmbedDTO<T extends EmbedDTOType> {
  type: T;
}

// Helper for switch case type infer
export type EmbedDTOTypes = EmbeddedLinkDTO | EmbeddedExpandableDTO | EmbeddedPollDTO | EmbeddedMediaDTO |
  EmbeddedLocationDTO | EmbeddedDocumentDTO | EmbeddedWeatherDTO | EmbeddedNotificationDTO | EmbeddedForwardDTO | EmbeddedQuoteDTO;
