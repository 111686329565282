import { AuthState } from './auth.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from '../root/public-api';
import { authFeatureKey } from './constants';

export interface WithFeatureState extends RootState {
  [authFeatureKey]: AuthState;
}

export const selectAuthState = createFeatureSelector<AuthState>(
  authFeatureKey
);

export const selectCurrentUserData = createSelector(
  selectAuthState,
  (state) => state.userData
);
