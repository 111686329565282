import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { OnboardingStartComponent } from '../onboarding-start/components/onboarding-start/onboarding-start.component';

export const onboardingStartRoute: WenRoute = {
  path: 'start',
  component: OnboardingStartComponent,
  data: {
    routeId: WenRouteId.ONBOARDING_START,
    routeTransitionEnabled: true
  }
};
