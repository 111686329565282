import { WenRoutes } from '../../../frame/routing/types';
import { registrationCodeVerificationRoute, registrationProfileRoute, registrationStartRoute } from './registration.routes';

export const primaryOutletRegistrationRoutes: WenRoutes = [
  registrationStartRoute,
  registrationCodeVerificationRoute,
  registrationProfileRoute,
  {
    path: '**',
    redirectTo: '/register/start'
  }
];
