import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarNotificationComponent, WenSnackbarNotificationData } from '../../components/snackbar/snackbar-notification/snackbar-notification.component';

@Injectable()
export class WenSnackbarOpener {

  private notificationConfig: MatSnackBarConfig = {
    panelClass: 'wen-snackbar-notification'
  };

  constructor(
    private snackbar: MatSnackBar
  ) { }

  openNotificationSnackbar(data: WenSnackbarNotificationData) {
    const snackBarConfig = { ...this.notificationConfig, data };
    if (data?.durationInMilliseconds) {
      snackBarConfig.duration = data.durationInMilliseconds;
    }

    this.snackbar.openFromComponent(SnackbarNotificationComponent, snackBarConfig);
  }

}
