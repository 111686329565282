import { Update } from '@ngrx/entity';
import { PagingHistoryFlags } from '@portal/wen-backend-api';
import { ChannelMessageEntity, ChannelState, channelAdapter, channelMessagesAdapter } from '../channel.state';
import { cleanupArray, findChannelById } from './state-adapter-utils';

const mergeFlags = (oldValues: PagingHistoryFlags, newValues: PagingHistoryFlags) => {
  const defaults = { hasMoreNewer: oldValues?.hasMoreNewer, hasMoreOlder: oldValues?.hasMoreOlder };
  return {
    ...defaults,
    ...newValues
  };
};

export const channelMessagesHistoryHelper = {

  upsertMany: (
    messages: ChannelMessageEntity[],
    channelId: string,
    channelState: ChannelState,
    flags: PagingHistoryFlags
  ) => {
    let newChannels = channelState.channels;
    let targetChannel = findChannelById(channelId, newChannels);
    const newFlags = mergeFlags(targetChannel?.history, flags);
    if (!targetChannel) {
      targetChannel = {
        id: channelId, history: channelMessagesAdapter.getInitialState(newFlags)
      };
    } else if (!targetChannel.history) {
      targetChannel = { ...targetChannel, history: channelMessagesAdapter.getInitialState(newFlags) };
    }

    const newChannelMessagesHistory = channelMessagesAdapter.upsertMany(messages, {
      ...targetChannel.history,
      ...newFlags
    });
    newChannels = channelAdapter.upsertOne({ ...targetChannel, history: newChannelMessagesHistory }, newChannels);
    return newChannels;
  },

  updateMany: (
    messages: ChannelMessageEntity[],
    channelId: string,
    channelState: ChannelState,
    flags: PagingHistoryFlags
  ) => {
    let newChannels = channelState.channels;
    let targetChannel = findChannelById(channelId, newChannels);
    const newFlags = mergeFlags(targetChannel?.history, flags);
    if (!targetChannel) {
      targetChannel = {
        id: channelId, history: channelMessagesAdapter.getInitialState(newFlags)
      };
    } else if (!targetChannel.history) {
      targetChannel = { ...targetChannel, history: channelMessagesAdapter.getInitialState(newFlags) };
    }
    const updates: Update<ChannelMessageEntity>[] = messages.map((message) => ({ id: message.id, changes: message }));
    const newChannelMessagesHistory = channelMessagesAdapter.updateMany(updates, {
      ...targetChannel.history,
      ...newFlags
    });
    newChannels = channelAdapter.upsertOne({ ...targetChannel, history: newChannelMessagesHistory }, newChannels);
    return newChannels;
  },

  clearHistory: (
    channelId: string,
    channelState: ChannelState,
  ) => {
    let newChannels = channelState.channels;
    const targetChannel = findChannelById(channelId, newChannels);
    if (!targetChannel?.history) {
      return channelState.channels;
    }
    const newChannelMessagesHistory = channelMessagesAdapter.setAll([], {
      ...targetChannel.history,
    });
    newChannels = channelAdapter.upsertOne({ ...targetChannel, history: newChannelMessagesHistory }, newChannels);
    return newChannels;
  },

  clearMessageHistory: (
    channelId: string,
    keepMessageId: string,
    channelState: ChannelState,
  ) => {
    let newChannels = channelState.channels;
    const targetChannel = findChannelById(channelId, newChannels);
    const selectors = channelMessagesAdapter.getSelectors();
    if (!targetChannel.history) {
      return newChannels;
    }
    const { hasMoreNewer, hasMoreOlder } = targetChannel.history;
    const historyMessages = selectors.selectAll(targetChannel.history);
    const hasNewInHistory = historyMessages.findIndex((historyMessage) => {
      return historyMessage?.new;
    });
    if (hasNewInHistory === -1) {
      const { result: cleanedHistory, didCutBefore } = cleanupArray(historyMessages, keepMessageId);
      const newChannelMessagesHistory = channelMessagesAdapter.setAll(cleanedHistory, {
        ...targetChannel.history,
        hasMoreOlder: didCutBefore ? didCutBefore : hasMoreOlder,
        hasMoreNewer
      });
      newChannels = channelAdapter.upsertOne({ ...targetChannel, history: newChannelMessagesHistory }, newChannels);
    }
    return newChannels;
  },

};
