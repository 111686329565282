import { Injectable, inject } from '@angular/core';
import { ScheduledMessageEvent, SendMessageContent } from '@portal/wen-backend-api';
import { map } from 'rxjs';
import { ChatScheduledMessageEntity } from '../../../store/chat/chat.state';
import { DecryptedMessageResultWrapper } from './decryption-result-base';
import { ChatMessageDecryptionError, DecryptedMessageResult, DecryptedMessageResultProps, MessageDecryptor } from './message-decryptor';

export class DecryptedScheduledMessageResult extends DecryptedMessageResultWrapper<ChatScheduledMessageEntity> {

  constructor(
    public readonly scheduledFor: string,
    public readonly originalResult: DecryptedMessageResult,
  ) {
    super();
  }

  get props(): DecryptedMessageResultProps {
    return this.originalResult.props;
  }

  getDecryptedContent(): SendMessageContent<false> {
    return this.originalResult.getDecryptedContent();
  }

  getDecryptionError(): ChatMessageDecryptionError {
    return this.originalResult.getDecryptionError();
  }

  asChatStoreEntity() {
    const storeEntity = this.originalResult.asChatStoreEntity();
    const scheduledStoreEntity = {
      ...storeEntity,
      scheduledFor: this.scheduledFor
    };
    return scheduledStoreEntity;
  }

}

@Injectable()
export class ScheduledMessageDecryptor {

  private messageDecryptor = inject(MessageDecryptor);

  decryptScheduledEvent(event: ScheduledMessageEvent) {
    const { scheduledFor, ...roomEventProperties } = event;
    return this.messageDecryptor.decryptRoomEventWithRetry(roomEventProperties).pipe(
      map((originalResult) => {
        return new DecryptedScheduledMessageResult(scheduledFor, originalResult);
      })
    );
  }

}
