import { NotificationRegistrationResult, ReceivedNotification } from '@portal/react-native-api';
import { ToRoomEventType } from '@portal/wen-backend-api';

export const NATIVE_NOTIFICATION_REGISTRATION_STORE_KEY = 'pushNotificationRegistrationToken';
export const NATIVE_NOTIFICATION_NEW_NOTIFICATION_STORE_KEY = 'pushNotificationNotificationData';

export enum NativeNotificationType {
  NEW_CHANNEL_MESSAGE = 'channel.message',
  NEW_CHANNEL_INVITE = 'invite.channel.new',
  NEW_CHANNEL_JOIN = 'invite.channel.accept',
  NEW_CHAT_MESSAGE = 'chat.event',
  /**
   * @deprecated in favor of personal.channel.message
   */
  NEW_CHANNEL_JOIN_REQUEST = 'invite.channel.request',
  NEW_PERSONAL_MESSAGE = 'personal.channel.message'
}

export type NativeNotificationDataTypes =
  | NewNativeChannelMessageNotificationData
  | NewNativeChatMessageNotificationData
  | NewNativeChannelJoinRequestNotificationData;

export interface NativeNotificationData<T extends NativeNotificationType = null> {
  notificationType: T;
}

export interface NewNativeChannelMessageNotificationData extends NativeNotificationData<NativeNotificationType.NEW_CHANNEL_MESSAGE> {
  channelId: string;
  messageId: string;
}

export interface NewNativeChatMessageNotificationData extends NativeNotificationData<NativeNotificationType.NEW_CHAT_MESSAGE> {
  roomId: string;
  eventId: string;
  eventType: ToRoomEventType;
}

export interface NewNativePersonalChannelNotificationData extends NativeNotificationData<NativeNotificationType.NEW_PERSONAL_MESSAGE> {
  channelId: string;
}

export interface NewNativeChannelJoinRequestNotificationData extends NativeNotificationData<
  NativeNotificationType.NEW_CHANNEL_JOIN_REQUEST
> {
  channelId: string;
  from: string;
}

export type NativeNotificationRegistrationPayload = NotificationRegistrationResult;

export type NativeNotificationPayload = ReceivedNotification<NativeNotificationData>;
