import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { WenNgxDatepickerDateAdapter } from './ngx-datepicker-date-adapter';

export interface WenDatepickerConfig {
  locale: string;
}

@NgModule({

})
export class WenDatepickerModule {

  static forRoot({
    locale
  }: WenDatepickerConfig): ModuleWithProviders<WenDatepickerModule> {
    return {
      ngModule: WenDatepickerModule,
      providers: [
        { provide: NgxMatDateAdapter, useClass: WenNgxDatepickerDateAdapter },
        { provide: MAT_DATE_LOCALE, useValue: locale }
      ]
    };
  }

}
