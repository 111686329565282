import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChannelInviteState } from '@portal/wen-backend-api';
import { ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { firstExisty } from '../../../../../../core/common/operators/first-existy';
import { acknowledgeChannelJoinDenial } from '../../../../../../core/store/channel/channel.actions';
import { RootState } from '../../../../../../core/store/root/public-api';
import { ChannelSelector } from '../providers/channel-selector';

export const joinRequestDeniedGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(JoinRequestDeniedGuard).canActivate(route, state);
};

@Injectable()
export class JoinRequestDeniedGuard  {
  constructor(
    private store: Store<RootState>,
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
    private channelSelector: ChannelSelector
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.channelSelector.currentChannel$.pipe(
      firstExisty(),
      switchMap((channel) => {
        if (channel?.invite?.state === ChannelInviteState.DENIED) {
          const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
            header: this.translateService.instant('CHANNEL_JOIN_REQUEST_DENIED_DIALOG_TITLE'),
            content: this.translateService.instant('CHANNEL_JOIN_REQUEST_DENIED_DIALOG_CONTENT'),
            okLabel: this.translateService.instant('EVERYTHING_IS_CLEAR_BUTTON_LABEL'),
          }, { disableClose: true });
          return dialog.afterClosed().pipe(
            first(),
            map(() => {
              this.store.dispatch(acknowledgeChannelJoinDenial({ requestId: channel.invite.id }));
              return false;
            })
          );
        } else {
          return of(true);
        }
      })
    );
  }

}
