<wen-time-picker
  #scheduleDatePicker
  placeholder="{{ 'SEND_MESSAGE_SCHEDULE_DATE_PLACEHOLDER' | translate }}"
  [onlyFuture]="true"
  [isClearable]="canClearDate"
  [disabled]="disabled$ | async"
  (dateChange)="scheduleDateChange($event)"
></wen-time-picker>
@if (canClearDate) {
  <div class="wen-message-scheduler-schedule-message-count" wen-primary-color>
    <span (click)="navigateToScheduledMessages()" [wen-test-id]="'scheduled-post-view-button'" with-colon>{{ 'CHANNEL_SCHEDULED_MESSAGES_NAVIGATION_LABEL' | translate }}</span>
    <span>{{ scheduledMessagesCount$ | async }}</span>
  </div>
}