import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PortalImageModule } from '@portal/ui-kit/image';
import { ImageUrlTransformModule } from '../../pipes/image-url-transform/image-url-transform.module';
import { CircleInitialViewModule } from '../circle-initial-view/circle-initial-view.module';
import { CircleImageViewComponent } from './circle-image-view.component';
import { CircleImageOverlayDirective } from './circle-image-view.directives';

@NgModule({
  imports: [
    CommonModule,
    PortalImageModule,
    CircleInitialViewModule,
    ImageUrlTransformModule,
  ],
  declarations: [
    CircleImageViewComponent,
    CircleImageOverlayDirective
  ],
  exports: [
    CircleImageViewComponent,
    CircleImageOverlayDirective
  ]
})
export class CircleImageViewModule { }
