import { EntityState } from '@ngrx/entity';
import { DateUtil } from '../../../common/date/date-util';
import { channelAdapter, ChannelEntity, ChannelMessageEntity, channelMessagesAdapter, ChannelState } from '../channel.state';
import { findChannelById } from './state-adapter-utils';

export const channelMessagesHelper = {

  upsertMany: (
    messages: ChannelMessageEntity[],
    channelState: ChannelState,
  ) => {
    let newChannels = channelState.channels;
    messages.forEach(message => {
      const { channelId } = message;
      let targetChannel = findChannelById(channelId, newChannels);
      if (!targetChannel) {
        targetChannel = { id: channelId, messages: channelMessagesAdapter.getInitialState() };
      } else if (!targetChannel.messages) {
        targetChannel = { ...targetChannel, messages: channelMessagesAdapter.getInitialState() };
      }

      const newChannelMessages = channelMessagesAdapter.upsertOne(message, targetChannel.messages);
      newChannels = channelAdapter.upsertOne({ ...targetChannel, messages: newChannelMessages }, newChannels);
    });
    return newChannels;
  },

  updateMany: (
    messages: ChannelMessageEntity[],
    channelState: ChannelState,
  ) => {
    let newChannels = channelState.channels;
    messages.forEach(message => {
      const { channelId } = message;
      let targetChannel = findChannelById(channelId, newChannels);
      if (!targetChannel) {
        targetChannel = { id: channelId, messages: channelMessagesAdapter.getInitialState() };
      } else if (!targetChannel.messages) {
        targetChannel = { ...targetChannel, messages: channelMessagesAdapter.getInitialState() };
      }

      const newChannelMessages = channelMessagesAdapter.updateOne({ id: message.id, changes: message }, targetChannel.messages);
      newChannels = channelAdapter.upsertOne({ ...targetChannel, messages: newChannelMessages }, newChannels);
    });
    return newChannels;
  },

  clearNewStateFromMessages: (
    channelId: string,
    channels: EntityState<ChannelEntity>,
    lastReadTimestamp?: string
  ) => {
    const targetChannel = findChannelById(channelId, channels);
    let newChannels = channels;
    if (targetChannel) {
      const newChannelMessages = targetChannel.messages ? channelMessagesAdapter.map(message => {
        if (message.new && DateUtil.compare(lastReadTimestamp, message.timestamp) < 1) {
          return ({ ...message, new: false });
        }
        return message;
      }, targetChannel.messages) : targetChannel.messages;
      const newHistoricalChannelMessages = targetChannel.history ? channelMessagesAdapter.map(message => {
        if (message.new && DateUtil.compare(lastReadTimestamp, message.timestamp) < 1) {
          return ({ ...message, new: false });
        }
        return message;
      }, targetChannel.history) : targetChannel.history;
      newChannels = channelAdapter.upsertOne({
        ...targetChannel, messages: newChannelMessages, history: newHistoricalChannelMessages
      }, channels);
    }
    return newChannels;
  },

  clearNewStateFromABatchOfMessage: (
    newMessages: ChannelMessageEntity[],
    channels: EntityState<ChannelEntity>
  ) => {
    const targetChannel = findChannelById(newMessages[0].channelId, channels);
    let newChannels = channels;
    if (!targetChannel) {
      return newChannels;
    }
    const messages = newMessages.filter(message => ((targetChannel.messages?.ids ?? []) as string[]).includes(message.id));
    const history = newMessages.filter(message => ((targetChannel.history?.ids ?? []) as string[]).includes(message.id));
    if (messages.length) {
      const readMessages = messages.map(message => ({ ...message, new: false }));
      const newChannelMessages = channelMessagesAdapter.upsertMany(readMessages, targetChannel.messages);
      newChannels = channelAdapter.upsertOne({
        ...targetChannel, messages: newChannelMessages
      }, channels);
    } else if (history.length) {
      const readMessages = history.map(message => ({ ...message, new: false }));
      const newHistoricalChannelMessages = channelMessagesAdapter.upsertMany(readMessages, targetChannel.history);
      newChannels = channelAdapter.upsertOne({
        ...targetChannel, history: newHistoricalChannelMessages
      }, channels);
    }
    return newChannels;
  },

  updateLastReadTimestamp: (
    channelId: string,
    lastReadTimestamp: string,
    channels: EntityState<ChannelEntity>
  ) => {
    const targetChannel = findChannelById(channelId, channels);
    let newChannels = channels;
    if (targetChannel && DateUtil.compareNullSafe(targetChannel.lastReadTimestamp, lastReadTimestamp) > 0) {
      newChannels = channelAdapter.upsertOne({ ...targetChannel, lastReadTimestamp }, channels);
    }
    return newChannels;
  }

};
