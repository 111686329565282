@if (resultList$ | async; as resultList) {
  <ng-content select="[wen-user-search-list-title]"></ng-content>
  @for (listItem of resultList; track listItem) {
    <wen-list-item
      [showAfter]="true"
      justifyInfoContent="center"
      (mousedown)="onUserSelected(listItem.user)"
      [wenIsCurrentUser]="listItem.user.userId"
      wenIsCurrentUserClassToAdd="wen-list-item-disabled"
      [disabled]="listItem.disabled"
      >
      <wen-circle-image-view
        wen-list-item-icon
        [imageUrl]="listItem.user.avatarUrl"
        [shape]="'circle'"
        [placeholderText]="listItem.user.displayName"
      ></wen-circle-image-view>
      <span wen-list-item-title>
        {{ listItem.user.displayName }}
        <span *isLoggedInUser="listItem.user.userId" in-brackets>{{ 'ME_INDICATOR' | translate }}</span>
      </span>
    </wen-list-item>
  }
} @else {
  <ng-content select="wen-user-search-empty-list, [wen-user-search-empty-list]"></ng-content>
  @if (!isEmptyListOverridden) {
    <wen-empty-list src="/assets/wen-widget/image/empty-user-list.svg"
      titleLabel="SEARCH_USER_LIST_EMPTY_TEXT" [wen-test-id]="'wen-empty-search-user-list'">
    </wen-empty-list>
  }
}

