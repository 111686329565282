import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { filter, first, switchMap } from 'rxjs';
import { RootState } from '../../store/root/public-api';
import { upsertFeatureState } from '../../store/ui/ui.actions';
import { selectFeaturesConfigState } from '../../store/ui/ui.selectors';

@Injectable()
export class FeatureFlagHandler {

  constructor(
    private store: Store<RootState>,
    private socketIoService: SocketIoService
  ) { }

  initialize() {
    return this.socketIoService.featureFlags.get.acknowledgement$().pipe(
      switchMap(flagConfig => {
        this.store.dispatch(upsertFeatureState({ newState: flagConfig }));
        return this.store.pipe(
          select(selectFeaturesConfigState),
          filter(config => Boolean(Object.keys(config).length)),
          first()
        );
      })
    );
  }
}
