import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, of, shareReplay } from 'rxjs';
import { actualizeGlobalDraftChat, updateDraftChatMessage } from '../../../../../../core/store/chat/chat.actions';
import { selectDraftChatMessage } from '../../../../../../core/store/chat/chat.selectors';
import { DraftMessageEntity } from '../../../../../../core/store/common/models';
import { RootState } from '../../../../../../core/store/root/public-api';
import { MessageSendFieldDataSource } from '../../../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { CHAT_CREATION_MESSAGE_TYPE_SELECTOR_MENU_ID } from '../../../../../../shared/components/message-type-selector/constants/type-selector-ids';

@Injectable()
export class ChatCreationMessageSendFieldDatasource extends MessageSendFieldDataSource {
  draftMessage$: Observable<DraftMessageEntity> = this.store.pipe(
    select(selectDraftChatMessage),
    distinctUntilChanged(),
    shareReplay(1)
  );

  contextId$ = of(null);

  messageTypeSelectorId = CHAT_CREATION_MESSAGE_TYPE_SELECTOR_MENU_ID;

  updateDraftMessage(message: DraftMessageEntity) {
    this.store.dispatch(updateDraftChatMessage({ message }));
  }

  sendMessage() {
    this.store.dispatch(actualizeGlobalDraftChat());
  }

  discardDraftMessage() {

  }

  initialize(contextId: string) { }

  constructor(
    private store: Store<RootState>
  ) {
    super();
  }

}
