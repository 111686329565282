import { createAction, props } from '@ngrx/store';
import { SimpleUserDTO } from '@portal/wen-backend-api';
import { WenRouteId } from '../../../frame/routing/types';
import { ImportedSelectionEntity, SmartDesignState } from './smartdesign.state';

export const updateSmartDesignState = createAction(
  '[SmartDesign] Update the state',
  props<{ newState: Partial<SmartDesignState> }>()
);

export const loadImportedSelectionData = createAction(
  '[SmartDesign] Load imported selection data'
);

export const upsertImportedSelectionData = createAction(
  '[SmartDesign] Upsert imported selection data',
  props<{ newEntities: ImportedSelectionEntity[] }>()
);

export const continueCRMChatFlow = createAction(
  '[SmartDesign] Chat message was successfully sent and the CRM flow can be continued'
);

export const haltCRMChatFlow = createAction(
  '[SmartDesign] Chat message sending failed and the CRM flow cannot be continued'
);

export const preloadWenUsersForCRM = createAction(
  '[SmartDesign] Preload the wenetwork users from the selection data'
);

export const upsertWenUsers = createAction(
  '[SmartDesign] Upsert converted wenetwork users',
  props<{ users: SimpleUserDTO[] }>()
);

export const inviteToNetworkFromSmartdesign = createAction(
  '[SmartDesign] Invite imported contacts to network',
  props<{ importedInvitees: ImportedSelectionEntity[]; emailBody: string }>()
);

export const setCurrentCrmRootRoute = createAction(
  '[SmartDesign] Set the current root route id for the CRM integration',
  props<{ routeId: WenRouteId }>()
);
