import { Injectable } from '@angular/core';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MassDataStateAdapter } from '@portal/wen-data-core';
import { PendingContactEntity } from '../contacts.state';

@Injectable()
export class PendingContactsMassDataAdapter extends MassDataStateAdapter<PendingContactEntity> {

  private contactsEntityAdapter: EntityAdapter<PendingContactEntity>;

  getEntityAdapter() {
    if (!this.contactsEntityAdapter) {
      this.contactsEntityAdapter = createEntityAdapter<PendingContactEntity>({
        selectId: (model) => model.user.id, sortComparer: (e1, e2) => {
          return e1.requestStatus < e2.requestStatus ? 1 : -1;
        }
      });
    }
    return this.contactsEntityAdapter;
  }

}
