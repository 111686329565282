import { ActionMenuConfig } from '@portal/wen-components';
import { PromoteToAdminAction } from '../../channel-content-provider/providers/action-menu/promote-to-admin.action';
import { RemoveUserFromChannelAction } from '../../common/action-menu/remove-user-from-channel-action';
import { PromoteToContentProviderAction } from './promote-to-content-provider.action';

export const channelSubscriberMenuId = 'channel-subscribers-menu-id';

export const CHANNEL_SUBSCRIBERS_ACTION_MENU: ActionMenuConfig = {
  menuId: channelSubscriberMenuId,
  menuItems: [PromoteToAdminAction, PromoteToContentProviderAction, RemoveUserFromChannelAction],
};

export const channelAnonymousSubscribersMenuId = 'channel-anonymous-subscribers-menu-id';

export const CHANNEL_ANONYMOUS_SUBSCRIBERS_ACTION_MENU: ActionMenuConfig = {
  menuId: channelAnonymousSubscribersMenuId,
  menuItems: [RemoveUserFromChannelAction],
};
