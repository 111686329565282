import { Pipe, PipeTransform } from '@angular/core';
import { ChatRoomEntity } from '../../../../core/store/chat/chat.state';
import { calculateChatMessageStatus, ChatMessageEntityForStatus } from '../util/chat-message-status-calculator';

@Pipe({
  name: 'calculateMessageStatus'
})
export class CalculateMessageStatusPipe implements PipeTransform {

  transform(
    messageEntity: ChatMessageEntityForStatus,
    room: Pick<ChatRoomEntity, 'lastReadTimestamp' | 'lastReceivedTimestamp'>
  ) {
    // If the timestamp info of the message is saved into the content
    // TODO: Revisit at WEN-6329
    messageEntity.insertTimestamp = messageEntity?.insertTimestamp || messageEntity.messageContent.timestamp;
    return calculateChatMessageStatus(messageEntity, room);
  }

}
