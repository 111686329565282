import { Pipe, PipeTransform } from '@angular/core';
import { UserListItemModel } from '../../../../../../shared/components/user-list-item/models/user-list-item.model';
import { ChatCreationListItemModel } from '../providers/chat-selection-list-container-connector';

@Pipe({
  name: 'toUserListItem',
  pure: true,
  standalone: true
})
export class ToUserListItemPipe implements PipeTransform {

  transform(value: ChatCreationListItemModel): UserListItemModel {
    if (!value?.listItemEntity) {
      return null;
    }
    const { avatarUrl, title } = value.listItemEntity;
    return { avatarUrl, displayName: title };
  }

}
