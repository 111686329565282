import { fromEvent, of } from 'rxjs';
import { first, map, shareReplay } from 'rxjs/operators';
import { Socket, io } from 'socket.io-client';
import { SocketIoConfig } from '../config';
import { CoreEvents, SOCKET_EVENTS } from '../events/events';

export class WenSocketIoClient {

  private readonly socketInstance: Socket = io(this.config.url, {
    ...this.config.socketIoOptions,
    transports: ['websocket', 'polling']
  });

  private isOpen(): boolean {
    return this.socketInstance.connected || this.socketInstance.io.engine.readyState === 'open';
  }

  public isConnected(): boolean {
    return this.isOpen() || this.socketInstance.io.engine.readyState === 'opening';
  }

  public isActive(): boolean {
    return this.socketInstance.active;
  }

  constructor(
    private config: SocketIoConfig,
  ) { }

  emit(...params: Parameters<Socket['emit']>) {
    return this.socketInstance.emit.apply(this.socketInstance, params);
  }

  disconnect(...params: Parameters<Socket['disconnect']>) {
    return this.socketInstance.disconnect.apply(this.socketInstance, params);
  }

  connect(...params: Parameters<Socket['connect']>) {
    return this.socketInstance.connect.apply(this.socketInstance, params);
  }

  fromEvent<T>(event: SOCKET_EVENTS | CoreEvents) {
    return fromEvent<T>(this.socketInstance, event);
  }

  private reconnect() {
    const reconnect$ = this.fromEvent('connect').pipe(
      shareReplay(1),
      first(),
      map(() => true)
    );
    this.socketInstance.disconnect();
    this.socketInstance.connect();
    return reconnect$;
  }

  reconnectIfConnected() {
    if (!this.isOpen()) {
      return of(false);
    }
    return this.reconnect();
  }

  reconnectIfDisconnected() {
    if (this.isOpen()) {
      return of(true);
    }
    return this.reconnect();
  }

}
