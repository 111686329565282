import { createAction, props } from '@ngrx/store';
import { FormStoreKey } from '../../../shared/form-store/form-store-types';
import { FormStatus } from '../../common/util/forms';
import { DataObjectType } from '../common/data-objects';
import { FormChangedValue, FormDataObject } from './form.state';

export const saveEditForm = createAction(
  '[Form] Save the edit form',
  props<{ formId: FormStoreKey; dataObjectType: DataObjectType }>()
);

export const requestLeaveEditMode = createAction(
  '[Form] Request leaving the edit mode',
  props<{ formId: FormStoreKey; clearAllForms?: boolean }>()
);

export const acceptLeaveEditMode = createAction(
  '[Form] The user accepted leaving the edit mode'
);

export const cancelLeaveEditMode = createAction(
  '[Form] The user cancelled leaving the edit mode'
);

export const initEditForm = createAction(
  '[Form] Init an edit form',
  props<{ formId: FormStoreKey; initialValues?: FormDataObject }>()
);

export const setEditFormChangedValue = createAction(
  '[Form] Set the changed values for an edit form',
  props<{ formId: FormStoreKey; changedValues: FormChangedValue }>()
);

export const setEditFormChangedState = createAction(
  '[Form] Set the changed statuses for an edit form',
  props<{ formId: FormStoreKey; status: FormStatus; dirty: boolean }>()
);

export const clearFormValues = createAction(
  '[Form] Clear an edit form',
  props<{ formId: FormStoreKey }>()
);

export const detectFormChanges = createAction(
  '[Form] Detect changes for all visible forms (status) with optional validation and sync with store',
  props<{ skipValidation: boolean }>()
);

export const clearAllFormValues = createAction(
  '[Form] Clear every edit form'
);

export const showSaveSuccessToast = createAction(
  '[Form] Show a success toast',
  props<{ notificationText: string }>()
);
