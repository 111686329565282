import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { ChannelEntity } from '../../../../../core/store/channel/channel.state';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { CHANNEL_EDIT_FORM_DATA_PROVIDER, ChannelFormDataProvider } from '../../../common/providers/channel-form-data-provider';

@Component({
  selector: 'wen-channel-interaction-settings',
  templateUrl: './channel-interaction-settings.component.html',
  styleUrls: ['./channel-interaction-settings.component.scss'],
  providers: [CHANNEL_EDIT_FORM_DATA_PROVIDER]
})
export class ChannelInteractionSettingsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  @HostBinding('class.wen-channel-interaction-settings') className = true;

  channelInteractionSettingsFormGroup = new FormGroup({
    emoji: new FormControl(''),
    comment: new FormControl(''),
  });

  channel$: Observable<ChannelEntity>;

  constructor(
    private readonly formStoreService: FormStoreService,
    private readonly channelFormDataProvider: ChannelFormDataProvider,
    readonly featureEnablementService: FeatureEnablementService
  ) { }

  ngOnInit() {
    this.channel$ = this.channelFormDataProvider.channel$;

    this.channel$.pipe(
      firstExisty(),
      takeUntil(this.onDestroy$)
    ).subscribe(data => {
      this.formStoreService.initializeForm(data);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
