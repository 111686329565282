import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { WenIconModule } from '@portal/wen-components';
import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { PersonalWeatherComponent } from './personal-weather.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    PortalFieldModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    WenIconModule,
    NgxMatTimepickerModule
  ],
  exports: [PersonalWeatherComponent],
  declarations: [PersonalWeatherComponent]
})
export class PersonalWeatherModule { }
