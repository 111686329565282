import { ChangeDetectionStrategy, Component, forwardRef, inject, Input } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { exhaustMap, ReplaySubject, shareReplay, startWith, tap } from 'rxjs';
import { createTimer } from '../../../../../core/common/util/create-timer';
import { resendValidator, VERIFICATION_SUBMIT_TIMER_SEC } from './code-verification-field-utils';
import { CodeVerificationResendService } from './code-verification-resend-button/code-verification-resend.service';

@Component({
  selector: 'wen-code-verification-field',
  templateUrl: './code-verification-field.component.html',
  styleUrls: ['./code-verification-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CodeVerificationFieldComponent),
    },
    CodeVerificationResendService,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeVerificationFieldComponent implements ControlValueAccessor {

  private formGroupDirective = inject(FormGroupDirective);
  private resendService = inject(CodeVerificationResendService);

  private submitted = new ReplaySubject<void>(1);
  resendDisabled$ = this.resendService.isDisabled$;
  remainingResend$ = this.resendService.remaining$;
  remainingResubmit$ = this.submitted.pipe(
    exhaustMap(() => createTimer(VERIFICATION_SUBMIT_TIMER_SEC)),
    tap(remaining => {
      if (remaining === 0) {
        resendValidator.setValid();
        this.codeControl.updateValueAndValidity();
      }
    }),
    startWith(0),
    shareReplay(),
  );

  /**
   * Identifier which identifies the user for the resend endpoint
   *
   * Usually this is the phone number
   */
  @Input() resendIdentifier: string;
  @Input() forFormControlName: string;
  @Input() codeFieldType: 'number' | 'text';

  get codeControl() {
    return this.formGroupDirective.control.get(this.forFormControlName);
  }

  hasError(errorKey: string | string[]) {
    const errorKeyArr = Array.isArray(errorKey) ? errorKey : [errorKey];
    const errors = this.codeControl.errors;
    return errors && errorKeyArr.some(key => Object.keys(errors).includes(key));
  }

  onResendCode() {
    if (!this.resendIdentifier) {
      throw new Error('CodeVerificationFieldComponent input: resendIdentifier must be defined!');
    }
    this.resendService.tryResend(this.resendIdentifier);
  }

  onChange = (value: boolean) => { };
  onTouched = () => { };
  writeValue(value: number): void {
    throw new Error('WriteValue is not yet supported by CodeVerificationFieldComponent and should be implemented!');
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(isDisabled: boolean): void { }

}
