@if (error$ | async; as error) {
  @if (error === 'NO_KEY_FOUND') {
    <h3 class="wen-chat-message-error-share-key" (click)="errorShareKeyClicked($event)">
      <span class="wen-chat-message-error-share-key-icon-wrapper">
        <wen-icon class="wen-icon-flex" iconName="encryption_key"></wen-icon>
      </span>
      <h3>{{ "CHAT_MESSAGE_DECRYPTION_ERROR" | translate }} </h3>
      <h3 class="wen-chat-message-error-action">{{ "CHAT_MESSAGE_DECRYPTION_ERROR_ACTION" | translate }}</h3>
    </h3>
  }
  @if (error === 'UNKNOWN') {
    <h3>{{ "CHAT_MESSAGE_DECRYPTION_ERROR" | translate }}</h3>
  }
}