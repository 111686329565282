import { EntityState } from '@ngrx/entity';
import { RestrictionDTO } from '@portal/wen-backend-api';
import { ChannelPermission } from '../../user/models/Permission';
import { channelAdapter, ChannelEntity, channelMessagesAdapter } from '../channel.state';
import { channelDraftMessagesHelper } from './draft-message-state-helper';
import { extraMessagesHelper } from './extra-message-state-helper';
import { featuredDataHelper } from './featured-data-state-helper';
import { channelMessagesHistoryHelper } from './history-message-state-helper';
import { channelPendingMessagesHelper } from './pending-message-state-helper';
import { channelMessagesHelper } from './realtime-message-state-helper';
import { channelScheduledMessagesHelper } from './scheduled-message-state-helper';
import { findChannelById } from './state-adapter-utils';

const channelPermissionHelper = {
  updatePermissionFor: (channelId: string, permission: ChannelPermission, channels: EntityState<ChannelEntity>) => {
    let targetChannel = findChannelById(channelId, channels);
    let newChannels = channels;
    if (targetChannel) {
      targetChannel = { ...targetChannel, permission };
      newChannels = channelAdapter.upsertOne({ ...targetChannel }, channels);
    }
    return newChannels;
  }
};

const channelRestrictionHelper = {
  updateRestrictionFor: (channelId: string, restrictions: RestrictionDTO[], channels: EntityState<ChannelEntity>) => {
    let targetChannel = findChannelById(channelId, channels);
    let newChannels = channels;
    if (targetChannel) {
      targetChannel = { ...targetChannel, restrictions };
      newChannels = channelAdapter.upsertOne({ ...targetChannel }, channels);
    }
    return newChannels;
  }
};

const channelJoinRequestHelper = {
  clearInviteFromChannel: (
    channelId: string,
    channels: EntityState<ChannelEntity>
  ) => {
    let targetChannel = findChannelById(channelId, channels);
    let newChannels = channels;
    if (targetChannel) {
      targetChannel = { ...targetChannel, invite: null };
      newChannels = channelAdapter.upsertOne({ ...targetChannel }, channels);
    }
    return newChannels;
  }
};

const channelCleanerHelper = {
  clearAllMessagesFromChannel: (
    channelId: string,
    channels: EntityState<ChannelEntity>
  ) => {
    let targetChannel = findChannelById(channelId, channels);
    let newChannels = channels;
    if (targetChannel) {
      targetChannel = {
        ...targetChannel,
        history: channelMessagesAdapter.getInitialState(),
        messages: channelMessagesAdapter.getInitialState(),
        extraMessages: channelMessagesAdapter.getInitialState()
      };
      newChannels = channelAdapter.upsertOne({ ...targetChannel }, channels);
    }
    return newChannels;
  }
};

export const channelsHelper = {
  channelMessages: channelMessagesHelper,
  channelScheduledMessages: channelScheduledMessagesHelper,
  channelMessagesHistory: channelMessagesHistoryHelper,
  channelDraftMessages: channelDraftMessagesHelper,
  channelPendingMessages: channelPendingMessagesHelper,
  channelExtraMessages: extraMessagesHelper,
  channelPermission: channelPermissionHelper,
  channelRestriction: channelRestrictionHelper,
  channelJoinRequests: channelJoinRequestHelper,
  channelCleaner: channelCleanerHelper,
  featuredData: featuredDataHelper
};
