import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'wen-empty-list',
    templateUrl: './empty-list.component.html',
    styleUrls: ['./empty-list.component.scss']
})
export class EmptyListComponent implements OnInit {

    @Input() src: string;
    @Input() titleLabel: string;
    @Input() textLabel: string;
    @Input() buttonLabel: string;

    @Output() buttonClicked = new EventEmitter<void>();

    constructor() { }

    ngOnInit() { }
}
