import { Action, createReducer, on } from '@ngrx/store';
import { updatePrivileges, updateProfileImageState, updateUserAppPermission, updateUserChannelPermission, updateUserPrimaryLanguage, updateUserProfile, upsertUserMetaDataSettings } from './user.actions';
import { ProfileImageState, UserState } from './user.state';
import { UserProfile } from './models/UserProfile';

export const userInitialState: UserState = {
  userProfile: null,
  privileges: null,
  channelPermission: null,
  appPermission: null,
  userMetaDataSettings: null,
  profileImageState: ProfileImageState.LOADED
};

const userReducer = createReducer(
  userInitialState,
  on(updateUserProfile, (state, { userProfile }) => {
    return { ...state, userProfile };
  }),
  on(updatePrivileges, (state, { privileges }) => {
    return { ...state, privileges };
  }),
  on(updateUserChannelPermission, (state, { permission }) => {
    return { ...state, channelPermission: permission };
  }),
  on(updateUserAppPermission, (state, { permission }) => {
    return { ...state, appPermission: permission };
  }),
  on(upsertUserMetaDataSettings, (state, { newSettings }) => {
    const currentValue = state.userMetaDataSettings;
    return {
      ...state,
      userMetaDataSettings: { ...currentValue, ...newSettings }
    };
  }),
  on(updateProfileImageState, (state, { state: imageState }) => {
    return { ...state, profileImageState: imageState };
  }),
  on(updateUserPrimaryLanguage, (state, { languageCode }) => {
    const newProfile: UserProfile = {
      ...state.userProfile,
      primaryLanguage: languageCode
    };
    return { ...state, userProfile: newProfile };
  })
);

export function userReducerFactory(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
