import { Provider } from '@angular/core';
import { DecryptedEventModifier } from '../decryption/decrypted-event-modifier';
import { EncryptedEventModifier } from '../decryption/encrypted-event-modifier';
import { MessageDecryptor } from '../decryption/message-decryptor';
import { MessageEventDecryptor } from '../decryption/message-event-decryptor';
import { ScheduledMessageDecryptor } from '../decryption/scheduled-message-decryptor';

export const MESSAGE_STORING_PROVIDERS: Provider[] = [
  MessageDecryptor,
  ScheduledMessageDecryptor,
  MessageEventDecryptor,
  EncryptedEventModifier,
  DecryptedEventModifier
];
