import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService, generateId } from '@portal/wen-backend-api';
import { distinctUntilKeyChanged, filter, first, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { DataContext } from '../../../common/types/data-context';
import { fetchSectionContent, updateEventExploreSectionLoadingState, updateSectionContent } from '../../sections/sections.actions';
import { extractSubscription, updateEventDetails } from '../event.actions';
import { OccurenceEntity } from '../event.state';
import { selectEventExploreIsLoaded } from '../../sections/sections.selectors';
import { Store, select } from '@ngrx/store';
import { RootState } from '../../root/public-api';
import { LoadingState } from '../../../common/types/store-loading-state';

@Injectable()
export class EventSectionEffects {
  private readonly eventSections$ = createEffect(() => this.actions$.pipe(
    ofType(fetchSectionContent),
    distinctUntilKeyChanged('sectionId'),
    filter(({ dataContext }) => dataContext === DataContext.EVENT),
    withLatestFrom(this.store.pipe(select(selectEventExploreIsLoaded))),
    filter(([_, isLoaded]) => !isLoaded),
    mergeMap(([{ params, sectionId }]) => {
      return this.socketIoService.events.listForDiscover.acknowledgement$(params).pipe(
        first(),
        switchMap(({ content }) => {
          const occurences: OccurenceEntity[] = content.map(ev => ({ ...ev, id: ev.recurring ? generateId() : ev.id, eventId: ev.id }));
          return [
            updateEventDetails({ events: content }),
            updateSectionContent({ data: content, sectionId }),
            extractSubscription({ data: occurences }),
            updateEventExploreSectionLoadingState({ loadingState: LoadingState.LOADED })
          ];
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private store: Store<RootState>
  ) { }

}
