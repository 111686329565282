import { updateNavBarVisibility } from './navigation-bar.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { NavigationBarState } from './navigation-bar.state';

export const navigationBarInitialState: NavigationBarState = {
  isNavBarVisible: true
};

const navigationBarReducer = createReducer(
  navigationBarInitialState,
  on(updateNavBarVisibility, (state, { isNavBarVisible }): NavigationBarState => {
    return {
      ...state,
      isNavBarVisible
    };
  })
);

export function navigationBarReducerFactory(state: NavigationBarState | undefined, action: Action) {
  return navigationBarReducer(state, action);
}
