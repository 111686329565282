import { FactoryProvider, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { selectCurrentChannel } from '../../../../core/store/channel/channel.selectors';
import { ChannelEntity } from '../../../../core/store/channel/channel.state';
import { RootState } from '../../../../core/store/root/public-api';

@Injectable()
export abstract class ChannelFormDataProvider {
  abstract channel$: Observable<ChannelEntity>;

}

@Injectable()
export class CreateChannelFormDataProvider extends ChannelFormDataProvider {
  channel$: Observable<ChannelEntity> = of({
    discoverable: true,
    emoji: true,
    comment: false,
    restrictions: []
  } as ChannelEntity);
}

@Injectable()
export class EditChannelFormDataProvider extends ChannelFormDataProvider {
  channel$: Observable<ChannelEntity> = this.store.pipe(select(selectCurrentChannel), shareReplay(1));

  constructor(
    private readonly store: Store<RootState>
  ) {
    super();
  }

}

export function channelEditFormDataProviderFactoryFn(
  route: ActivatedRoute,
  store: Store<RootState>
): ChannelFormDataProvider {
  return route.snapshot.params.channelId ? new EditChannelFormDataProvider(store) : new CreateChannelFormDataProvider();
}

export const CHANNEL_EDIT_FORM_DATA_PROVIDER: FactoryProvider = {
  provide: ChannelFormDataProvider,
  useFactory: channelEditFormDataProviderFactoryFn,
  deps: [ActivatedRoute, Store]
};
