import { Injectable, inject } from '@angular/core';
import { ContactRequestStatus, isConnectedStatus, isContactLikeWithRequestStatus } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Observable, map, shareReplay } from 'rxjs';
import { FilteredListMDContainerConnector } from '../../../../core/services/mass-data/filtering/filtered-list-mass-data-viewer-container-connector';
import { ContactListConnectorType } from './contact-list-types';

export interface PendingContactListSeparatorPositions {
  acceptedIndex: number;
  suggestedIndex: number;
}

@Injectable()
export class ContactListService {

  private datasource = inject<ContactListConnectorType>(FilteredListMDContainerConnector);

  separatorPositions$: Observable<PendingContactListSeparatorPositions>;

  constructor() {
    this.separatorPositions$ = this.datasource.filteredListMassData.dataStream$.pipe(
      map(items => {
        if (!items?.length) {
          return null;
        }
        const getFirstAcceptedIndex = () => {
          const firstItem = items?.length && isContactLikeWithRequestStatus(items[0]) ? items[0] : null;
          if (!firstItem || !isConnectedStatus(firstItem.requestStatus)) {
            return null;
          }
          return 0;
        };
        const getFirstNonAcceptedIndex = () => {
          const index = items.findIndex(item => {
            if (isContactLikeWithRequestStatus(item)) {
              return item.requestStatus !== ContactRequestStatus.ACCEPTED;
            }
            return true;
          });
          return index < 0 ? null : index;
        };
        const acceptedIndex = getFirstAcceptedIndex();
        const suggestedIndex = getFirstNonAcceptedIndex();
        return { acceptedIndex, suggestedIndex };
      }),
      smartDistinctUntilChanged(),
      shareReplay(1)
    );
  }
}
