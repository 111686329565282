import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { isNullOrUndefined } from './null-check-util';

export const firstExisty = <T>() => (source: Observable<T>) => {
  return source.pipe(
    filter(value => !isNullOrUndefined(value)),
    first()
  );
};
