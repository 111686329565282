import { ModuleWithProviders, NgModule } from '@angular/core';
import { GroupComponent } from './components/group/group.component';
import { HintComponent } from './components/hint/hint.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { FormValueManager } from './form-handling/form-value-manager';
import { GroupHandler } from './group-handler';
import { ContentHandlers, CONTENT_HANDLERS } from './schema-content-handlers';
import { SchemaHandler, SCHEMA_HANDLERS } from './schema-handler';
import { TimeComponent } from './components/time/time.component';
import { LocationComponent } from './components/location/location.component';

export interface SchemaProcessorProps {
  /**
   * Different handlers to manipulate what type of components should be displayed for the matching types
   *
   * - for testing purposes a default HINT handling is defined
   */
  contentHandlers: ContentHandlers;
}

export const defaultSchemaProcessorProps: SchemaProcessorProps = {
  contentHandlers: {
    HINT: HintComponent,
    GROUP: GroupComponent,
    MULTISELECT: MultiselectComponent,
    TIME: TimeComponent,
    LOCATION: LocationComponent
  },
};


@NgModule({
  providers: [
    SchemaHandler,
    {
      provide: SCHEMA_HANDLERS,
      useClass: GroupHandler,
      multi: true
    },
    FormValueManager
  ]
})
export class SchemaProcessorModule {

  static forRoot(config?: Partial<SchemaProcessorProps>): ModuleWithProviders<SchemaProcessorModule> {
    return {
      ngModule: SchemaProcessorModule,
      providers: [
        { provide: CONTENT_HANDLERS, useValue: config?.contentHandlers ?? defaultSchemaProcessorProps.contentHandlers }
      ]
    };
  }
}
