import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getAuthQueryParam } from '@portal/wen-common';
import { handleLoginCallbackEmbeddedApp } from '../../store/embedded-api/embedded-api.actions';
import { RootState } from '../../store/root/public-api';
import { NativeConfigurationService } from '../configuration/native-configuration';
import { EmbeddedAppOauthService } from '../user-management/embedded-app-oauth-service';
import { WenOAuthService } from '../user-management/wen-oauth.service';
import { SSO_DEEP_LINK_PATH } from './query-params';

export interface SsoDeepLinkData {
  routeUrl: string;
}

@Injectable()
export class SsoDeepLinkHandler {

  constructor(
    private store: Store<RootState>,
    private zone: NgZone,
    private router: Router,
    private oAuthService: WenOAuthService,
    private nativeConfiguration: NativeConfigurationService,
    private embeddedAppOauthService: EmbeddedAppOauthService,
  ) { }

  tryHandleDeepLink(link: string) {
    const ssoDeepLinkData = this.parseSsoDeepLink(link);
    if (ssoDeepLinkData) {
      const { code, error, embeddedSsoAppId, routeUrl } = ssoDeepLinkData;
      if (embeddedSsoAppId) {
        this.store.dispatch(handleLoginCallbackEmbeddedApp({
          appId: embeddedSsoAppId, code, error
        }));
      } else {
        this.zone.run(() => {
          this.router.navigateByUrl(routeUrl);
        });
      }
      return { canHandle: true, didNavigate: true };
    }
    return null;
  }

  private get ssoDeepLinkPattern() {
    return new RegExp(`(${this.nativeConfiguration.getNativeDeepLink()}://${SSO_DEEP_LINK_PATH})(/oauth/callback)(.*)`, 'g');
  }

  private parseSsoDeepLink(link: string) {
    const ssoDeepLinkMatch = this.ssoDeepLinkPattern.exec(link);
    if (ssoDeepLinkMatch?.length !== 4) {
      return null;
    }
    const fragmentWithRoute = ssoDeepLinkMatch[2];
    const fragmentWithCode = ssoDeepLinkMatch[3];
    const rawState = getAuthQueryParam(link, 'state');
    const { code, error } = this.embeddedAppOauthService.getAuthResultFromCodeFragment(fragmentWithCode);
    const embeddedSsoAppId = this.oAuthService.parseStateFromRaw(rawState)?.embeddedSsoAppId;
    return {
      code,
      error,
      embeddedSsoAppId,
      routeUrl: fragmentWithRoute + fragmentWithCode
    };
  }

}
