@if (config.settingEntries$ | async; as settingViewEntries) {
  <div class="wen-setting-view-container">
    @if (settingViewEntries.length > 0) {
      <div menuEntryLayoutGroup>
        @for (settingViewEntry of settingViewEntries; track settingViewEntry) {
          <wen-menu-entry class="wen-user-view-profile-entry-primary" (click)="handleEntryClick(settingViewEntry.entryId)" [attr.entry-id]="settingViewEntry.entryId">
            <wen-icon class="wen-icon-medium" [fillColor]="settingViewEntry.fillColor" color="white" [iconName]="settingViewEntry.iconName" menuEntryIcon>
            </wen-icon>
            <span portal-body-1 menuEntryTitle>{{settingViewEntry.translationKey | translate}}</span>
          </wen-menu-entry>
        }
      </div>
    }
  </div>
}