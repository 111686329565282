import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MediaType, WeLocalImageHelper } from '@portal/wen-backend-api';
import { LightboxImageData } from '@portal/wen-components';
import { filter, first, map, Subject, switchMap, withLatestFrom } from 'rxjs';
import { EmbeddedMedia } from '../../../../../../core/store/channel/channel.state';
import { ContentBoundaryProvider } from '../../../../../directives/content-boundary/content-boundary.directive';
import { MediaOverlayParams, MediaEventOptions } from './models/models';
import { LightboxService } from './providers/lightbox.service';
import { MediaProcessor } from './providers/media-processor.service';

@Component({
  selector: 'wen-embed-media',
  templateUrl: './embed-media.component.html',
  styleUrls: ['./embed-media.component.scss'],
  providers: [MediaProcessor],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class EmbedMediaComponent implements AfterViewInit {

  private mediaClick$ = new Subject<MediaEventOptions>();
  private overlayParams$ = this.mediaProcessor.processedMedia$.pipe(
    map(({ overlayParams }) => overlayParams)
  );
  mediaViewParams$ = this.mediaProcessor.processedMedia$.pipe(
    map(({ viewParams }) => viewParams)
  );
  mediaType = MediaType;

  @HostBinding('class.wen-embed-media') className = true;

  @Input() set model(media: EmbeddedMedia) {
    this.mediaProcessor.attach(media);
  }

  constructor(
    private mediaProcessor: MediaProcessor,
    private lightboxOpener: LightboxService,
    private welocalImageHelper: WeLocalImageHelper,
    private contentBoundaryProvider: ContentBoundaryProvider
  ) { }

  ngAfterViewInit() {
    this.mediaClick$.pipe(
      withLatestFrom(this.overlayParams$),
      filter(([{ mediaId }, overlayParams]) => overlayParams?.id === mediaId),
      switchMap(([{ thumbnailUrl }, overlayParams]) => this.contentBoundaryProvider.contentBoundary$.pipe(
        first(),
        map(({ width }) => {
          return {
            ...overlayParams,
            placeholderUrl: thumbnailUrl || this.welocalImageHelper.getImageInSize(overlayParams.placeholderUrl, width),
          };
        })
      )),
      switchMap((overlayParams) => this.openLightbox(overlayParams))
    ).subscribe(() => {
      this.lightboxOpener.updateNativeStyling();
    });
  }

  onMediaClick(mediaEventOptions: MediaEventOptions) {
    this.mediaClick$.next(mediaEventOptions);
  }

  private openLightbox(overlayParams: MediaOverlayParams) {
    const mappedParams = this.transform(overlayParams);
    const images: LightboxImageData[] = [mappedParams];
    return this.lightboxOpener.openLightbox(images);
  }

  private transform(overlayParams: MediaOverlayParams): LightboxImageData {
    return {
      ...overlayParams,
      src: overlayParams.srcUrl,
      msrc: overlayParams.placeholderUrl,
      type: this.mapToLightboxCompatible(overlayParams.fileType),
      fileName: overlayParams.fileName
    };
  }

  private mapToLightboxCompatible(subType: MediaType): string {
    if (subType === 'picture') {
      return 'image';
    }
    return subType.toString();
  }

}
