<div class="wen-profile-read-scroller" cdkScrollable>
  @if (headerData$ | async; as headerData) {
    <div class="wen-profile-read-form-header"
      [wen-test-id]="'wen-user-profile-read-header'">
      <wen-detail-header [model]="headerData" [coverImageStyle]="'circle'" [allowLoadingIndicator]="true"
        [shouldAnimate]="false">
        <div wen-detail-header-pending-image>
          <div class="wen-profile-read-pending-image">
            <ng-lottie [options]="lottieOptions"></ng-lottie>
          </div>
          <div class="wen-profile-read-pending-text">{{ pendingProfileImageText$ | async | translate }}</div>
        </div>
        <div wen-detail-header-title>{{ headerData.title }}</div>
        <button wen-detail-header-quick-actions mat-mini-fab color="primary" class="mat-elevation-z0"
          (click)="navigateToProfileEdit()">
          <wen-icon iconName="edit"></wen-icon>
        </button>
      </wen-detail-header>
    </div>
  }
  @if (userProfile$ | async; as userProfile) {
    <div>
      <div menuEntryLayoutGroup>
        @if (isAboutYouFieldEnabled$ | async) {
          @if (userProfile?.additionalInformation ; as additionalInformation) {
            <wen-menu-entry
              (click)="navigateToProfileEdit()">
              <span menuEntryTitle>{{ "PROFILE_FORM_ABOUT_ME_LABEL" | translate }}</span>
              <span menuEntryDescription [innerHTML]="additionalInformation | removeMarkdown"></span>
            </wen-menu-entry>
          }
        }
      </div>
      <div menuEntryLayoutGroup>
        <wen-menu-entry (click)="navigateToUserProfilePersonalAccountSettings()"
          [wen-test-id]="'wen-user-profile-read-PERSONAL_ACCOUNT_SETTINGS'"
          [attr.entry-id]="'USER_PROFILE_PERSONAL_ACCOUNT_SETTINGS'">
          <wen-icon class="wen-profile-settings-icon wen-icon-medium" [iconName]="userProfileSettingsIcon"
          [fillColor]="userProfileSettingsFillColor" [color]="'white'" menuEntryIcon></wen-icon>
          <span menuEntryTitle>{{ "USER_PROFILE_PERSONAL_ACCOUNT_SETTINGS" | translate }}</span>
        </wen-menu-entry>
        <wen-menu-entry (click)="navigateToUserProfileSettings()" [wen-test-id]="'wen-user-profile-read-SETTINGS'"
          [attr.entry-id]="'USER_PROFILE_SETTINGS'">
          <wen-icon class="wen-profile-settings-icon wen-icon-medium" [iconName]="userProfileSettingsIcon"
          [fillColor]="userProfileSettingsFillColor" [color]="'white'" menuEntryIcon></wen-icon>
          <span menuEntryTitle>{{ "USER_PROFILE_SETTINGS" | translate }}</span>
        </wen-menu-entry>
        @if (isAutoReplyEnabled$ | async) {
          <wen-menu-entry (click)="navigateToAutoReply()" [wen-test-id]="'wen-user-profile-read-AUTO_REPLY'" [attr.entry-id]="'AUTO_REPLY'">
            <wen-icon class="wen-profile-settings-icon wen-icon-medium" [iconName]="userAutoReplyIcon" [fillColor]="userAutoReplyFillColor" [color]="'white'" menuEntryIcon>
            </wen-icon>
            <span menuEntryTitle>{{ "USER_PROFILE_AUTO_REPLY" | translate }}</span>
          </wen-menu-entry>
        }
        @if (isWalletEnabled$ | async) {
          <wen-menu-entry (click)="navigateToWalletDetails()" [wen-test-id]="'wen-user-profile-wallet-DETAILS'">
            <wen-icon class="wen-profile-settings-icon wen-icon-medium" [iconName]="userSubscriptionsIcon" [fillColor]="userSubscriptionsFillColor" [color]="'white'" menuEntryIcon>
            </wen-icon>
            <span menuEntryTitle>{{ "USER_PROFILE_FORM_SUBSCRIPTIONS" | translate }}</span>
          </wen-menu-entry>
        }
      </div>
      <div menuEntryLayoutGroup class="wen-profile-read-from-logout-wrapper" [wen-test-id]="'wen-profile-read-logout'">
        <wen-interact-button class="wen-interact-button-menu-style wen-error-interact-button" (click)="logout()">{{
          "USER_PROFILE_LOGOUT" |
        translate }}</wen-interact-button>
      </div>
    </div>
  }
</div>