import { createFeatureSelector, createSelector } from '@ngrx/store';
import { chatViewIdentifier } from '../../../views/chat/tokens';
import { LoadingState } from '../../common/types/store-loading-state';
import { RootState } from '../root/public-api';
import { selectRouteParam } from '../root/root.selectors';
import { ChatState, chatRoomsEntityAdapter, chatSearchListItemEntityAdapter, pendingChatMessageEntityAdapter, userSearchListItemEntityAdapter } from './chat.state';
import { chatFeatureKey } from './constants';

export interface WithFeatureState extends RootState {
  [chatFeatureKey]: ChatState;
}

export const selectChatState = createFeatureSelector<ChatState>(
  chatFeatureKey
);

const {
  selectAll: roomSelectAll,
} = chatRoomsEntityAdapter.getSelectors();

const {
  selectAll: pendingChatMessageSelectAll,
} = pendingChatMessageEntityAdapter.getSelectors();

export const selectIsChatInitialized = createSelector(
  selectChatState,
  (state) => state?.chatInitialized
);

export const selectAllRooms = createSelector(
  selectChatState,
  state => {
    return roomSelectAll(state.rooms);
  }
);

export const selectUserChatListIsLoaded = createSelector(
  selectChatState,
  state => state.userChatListLoadingState !== LoadingState.NOT_LOADED
);

export const selectRoomById = createSelector(
  selectAllRooms,
  (roomEntities) => (roomId: string) => {
    return roomEntities.find((roomEntity) => {
      const id = chatRoomsEntityAdapter.selectId(roomEntity);
      return id === roomId;
    });
  }
);

export const selectCurrentRoom = createSelector(
  selectChatState,
  selectRouteParam(chatViewIdentifier),
  (state, roomId) => {
    const roomEntities = roomSelectAll(state.rooms);
    return roomEntities.find((roomEntity) => {
      const id = chatRoomsEntityAdapter.selectId(roomEntity);
      return id === roomId;
    });
  }
);

export const selectCurrentRoomMembers = createSelector(
  selectCurrentRoom,
  (currentRoom) => {
    return currentRoom?.members;
  }
);

export const selectCurrentRoomMessagesHistoryFlags = createSelector(
  selectCurrentRoom,
  (currentRoom) => {
    const history = currentRoom?.history;
    if (history) {
      const { hasMoreOlder, hasMoreNewer } = history;
      return { hasMoreOlder, hasMoreNewer };
    }
    return null;
  }
);

export const selectCurrentRoomPendingMessages = createSelector(
  selectCurrentRoom,
  (currentRoom) => {
    if (!currentRoom?.pendingMessages) {
      return [];
    }
    return pendingChatMessageSelectAll(currentRoom.pendingMessages);
  }
);

export const selectDraftChatMessageById = createSelector(
  selectChatState,
  (state) => (roomId: string) => {
    if (!state.rooms) {
      return null;
    }
    const roomEntity = state.rooms.entities[roomId];
    return roomEntity?.draftMessage;
  }
);

export const selectSharedInboundGroupSessions = createSelector(
  selectChatState,
  (state) => {
    return state.sharedInboundGroupSessions;
  }
);

export const selectCurrentAutoReadAcknowledgeRoomId = createSelector(
  selectChatState,
  (state) => {
    return state.currentAutoReadAcknowledgeRoomId;
  }
);

const {
  selectAll: draftChatMemberSelectAll
} = userSearchListItemEntityAdapter.getSelectors();

export const selectAllDraftChatMembers = createSelector(
  selectChatState,
  state => {
    return draftChatMemberSelectAll(state?.draft?.users) ?? [];
  }
);

const {
  selectAll: draftChatRoomSelectAll
} = chatSearchListItemEntityAdapter.getSelectors();

export const selectAllDraftChatRooms = createSelector(
  selectChatState,
  state => {
    return draftChatRoomSelectAll(state?.draft?.rooms) ?? [];
  }
);

export const selectDraftChatState = createSelector(
  selectChatState,
  state => state.draft
);

export const selectAllDraftChatMemberIds = createSelector(
  selectAllDraftChatMembers,
  multicastMembers => {
    return multicastMembers.map(({ id }) => id);
  }
);

export const selectAllDraftChatRoomIds = createSelector(
  selectAllDraftChatRooms,
  multicastRooms => {
    return multicastRooms.map(room => room.id);
  }
);

export const selectDraftChatMessage = createSelector(
  selectChatState,
  (state) => state.draft?.message
);

export const selectAllFileEmbedsCachesFromRooms = createSelector(
  selectAllRooms,
  rooms => {
    return rooms.filter(room => room.fileEmbedCache).map(room => room.fileEmbedCache);
  }
);

export const selectFileEmbed = createSelector(
  selectAllFileEmbedsCachesFromRooms,
  (fileEmbeds) => (uploadId: string) => {
    const existingFileEmbeds = fileEmbeds.find(fileEmbed => fileEmbed.hasOwnProperty(uploadId));
    return existingFileEmbeds ? existingFileEmbeds[uploadId] : null;
  }
);
