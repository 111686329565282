import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { EmbeddedAppNavigator } from '../../../../../frame/embedded-api/api-parts/navigation/embedded-app-navigator';
import { BackNavigationBehavior, BackNavigationBehaviorResult } from './back-navigation-behavior';

@Injectable()
export class EmbeddedAppBackNavigation implements BackNavigationBehavior {

  constructor(
    private embeddedAppNavigator: EmbeddedAppNavigator,
  ) { }

  execute(): Observable<BackNavigationBehaviorResult> {
    return this.embeddedAppNavigator.canNavigateBack().pipe(
      map((result) => {
        if (!result) {
          return {
            canHandle: true
          };
        }
        if (!this.embeddedAppNavigator.isCompressedNavigationMode()) {
          return {
            canHandle: false
          };
        }
        this.embeddedAppNavigator.backNavigate();
        return {
          canHandle: true
        };
      })
    );
  }

}
