import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FocusFirstInputModule } from '@portal/wen-components';
import { map } from 'rxjs';
import { WenOAuthService } from '../../../../../core/services/user-management/wen-oauth.service';
import { UserChangePasswordFormChangedValuesStep1 } from '../../../../../core/store/form/types/user-change-password-types';
import { createPasswordControl } from '../../../../../shared/components/forms/form-controls/password-field/password-field-utils';
import { PasswordFieldLabels } from '../../../../../shared/components/forms/form-controls/password-field/password-field.component';
import { PasswordFieldModule } from '../../../../../shared/components/forms/form-controls/password-field/password-field.module';
import { FormStoreModule } from '../../../../../shared/form-store/form-store.module';
import { FormAsyncValidator } from '../../../../../shared/form-store/form-store.providers';
import { UserChangePasswordDatasource } from './user-change-password.datasource';

@Component({
  selector: 'wen-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    PasswordFieldModule,
    FormStoreModule,
    FocusFirstInputModule,
  ],
  providers: [
    UserChangePasswordDatasource,
    {
      provide: FormAsyncValidator<UserChangePasswordFormChangedValuesStep1>,
      useClass: UserChangePasswordDatasource
    }
  ]
})
export class UserChangePasswordComponent {

  private translateService = inject(TranslateService);
  private oAuthService = inject(WenOAuthService);

  userChangePasswordFormGroup = new FormGroup<UserChangePasswordFormChangedValuesStep1>({
    oldPassword: createPasswordControl(),
    newPassword: createPasswordControl(),
  });

  oldLabels$ = this.translateService.stream(['USER_PROFILE_FORM_CHANGE_PASSWORD_CURRENT_PASSWORD_FIELD_LABEL']).pipe(
    map((result) => {
      const labels: PasswordFieldLabels = {
        passwordLabel: result.USER_PROFILE_FORM_CHANGE_PASSWORD_CURRENT_PASSWORD_FIELD_LABEL,
      };
      return labels;
    })
  );
  newLabels$ = this.translateService.stream([
    'USER_PROFILE_FORM_CHANGE_PASSWORD_NEW_PASSWORD_FIELD_LABEL',
    'USER_PROFILE_FORM_CHANGE_PASSWORD_NEW_PASSWORD_REPEAT_FIELD_LABEL',
    'USER_PROFILE_FORM_CHANGE_PASSWORD_NEW_PASSWORD_REPEAT_FIELD_ERROR'
  ]).pipe(
    map((result) => {
      const labels: PasswordFieldLabels = {
        passwordLabel: result.USER_PROFILE_FORM_CHANGE_PASSWORD_NEW_PASSWORD_FIELD_LABEL,
        passwordRepeatLabel: result.USER_PROFILE_FORM_CHANGE_PASSWORD_NEW_PASSWORD_REPEAT_FIELD_LABEL,
        passwordRepeatError: result.USER_PROFILE_FORM_CHANGE_PASSWORD_NEW_PASSWORD_REPEAT_FIELD_ERROR
      };
      return labels;
    })
  );

  navigateToPasswordReset() {
    this.oAuthService.navigateToPasswordReset();
  }

}
