import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem, WenSnackbarOpener } from '@portal/wen-components';
import { of } from 'rxjs';
import { CommentVisibility } from './comment-action-menu';
import { ClipboardService } from '../../../../core/services/util/clipboard.service';

@Injectable()
export class CommentCopyAction implements ActionMenuItem {

  label: string;
  icon = 'copy';

  constructor(
    private translateService: TranslateService,
    private clipboardService: ClipboardService,
    private wenSnackbarOpener: WenSnackbarOpener
  ) {
    this.label = this.translateService.instant('COMMENT_CONTEXT_MENU_LABEL_COPY');
  }

  isVisible$ = ({ comment }: CommentVisibility) => {
    return of(Boolean(comment.text));
  };

  execute({ comment }: CommentVisibility) {
    const isSuccess = this.clipboardService.copy(comment.text, true);
    if (isSuccess) {
      this.showSnackbarMessage();
    }
  }

  private showSnackbarMessage() {
    const notificationText = this.translateService.instant('SNACKBAR_LABEL_TEXT_COPY_SUCCESS');
    const notificationIcon = 'copy';
    this.wenSnackbarOpener.openNotificationSnackbar({ notificationIcon, notificationText });
  }
}
