import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { RootState } from '../../../../../../core/store/root/public-api';
import { first, switchMap, map } from 'rxjs';
import { selectSubscribedChannelIds } from '../../../../../../core/store/channel/channel.selectors';
import { selectRouteParam } from '../../../../../../core/store/root/root.selectors';
import { channelViewIdentifier } from '../../../../tokens';

export const ChannelSettingGuardFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const store = inject(Store<RootState>);
  return store.pipe(
    select(selectRouteParam(channelViewIdentifier)),
    first(),
    switchMap((channelId: string) => {
      return store.pipe(
        select(selectSubscribedChannelIds),
        map((subscribedChannelIds) => subscribedChannelIds.includes(channelId)),
        first()
      );
    })
  );
};
