import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem, WenSnackbarOpener } from '@portal/wen-components';
import { of } from 'rxjs';
import { togglePinChannel } from '../../../../../../../core/store/channel/channel.actions';
import { RootState } from '../../../../../../../core/store/root/public-api';
import { ChannelListItemModel } from '../models/list-item-model';

@Injectable()
export class ChannelPinAction implements ActionMenuItem {

  private isPinned: boolean;

  label: string;
  icon: string;

  execute(listItemModel: ChannelListItemModel): void {
    this.store.dispatch(togglePinChannel({ channelId: listItemModel.id, isPinned: !listItemModel.pinTimestamp }));
    this.showSnackbarMessage();
  }

  isVisible$ = (listItemModel: ChannelListItemModel) => {
    this.isPinned = !!listItemModel.pinTimestamp;
    this.icon = this.isPinned ? 'unpin' : 'pin';
    this.label = this.translateService.instant(
      this.isPinned ? 'UNPIN_BUTTON_LABEL' : 'PIN_BUTTON_LABEL'
    );
    return of(!!listItemModel);
  };

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>,
    private wenSnackbarOpener: WenSnackbarOpener
  ) { }

  private showSnackbarMessage() {
    const notificationText = this.translateService.instant(this.isPinned ? 'CHANNEL_UNPIN_NOTIFICATION' : 'CHANNEL_PIN_NOTIFICATION');
    const notificationIcon = this.isPinned ? 'unpin' : 'pin';
    this.wenSnackbarOpener.openNotificationSnackbar({ notificationIcon, notificationText });
  }

}
