import { Observable, distinctUntilChanged } from 'rxjs';
import { deepEqual } from '../object-utils';

export const smartDistinctUntilChanged = () => <T>(source$: Observable<T>) => {
  return source$.pipe(
    distinctUntilChanged((prev, curr) => {
      return deepEqual(prev, curr);
    })
  );
};
