import { Injectable } from '@angular/core';
import { PagingHistoryFlags } from '@portal/wen-backend-api';
import { clearRoomMessagesHistory, replaceChatMessagesHistory, updateRoomMessagesHistory, upsertRoomMessagesHistory } from '../actions/chat-history.actions';
import { storeChatMessage } from '../chat.actions';
import { ChatMessageEntity } from '../chat.state';

@Injectable()
export class ChatMessageOperations {

  setAll(
    roomId: string,
    data: ChatMessageEntity[],
    flags: PagingHistoryFlags
  ): ReturnType<typeof replaceChatMessagesHistory> {
    return replaceChatMessagesHistory({ roomId, messages: data, flags });
  }

  upsertOne(
    roomId: string,
    data: ChatMessageEntity
  ): ReturnType<typeof storeChatMessage> {
    return storeChatMessage({ roomId, message: data });
  }

  upsertMany(
    roomId: string,
    data: ChatMessageEntity[],
    flags: PagingHistoryFlags
  ): ReturnType<typeof upsertRoomMessagesHistory> {
    return upsertRoomMessagesHistory({ roomId, messages: data, flags });
  }

  updateMany(
    roomId: string,
    data: ChatMessageEntity[],
    flags: PagingHistoryFlags = null
  ): ReturnType<typeof updateRoomMessagesHistory> {
    return updateRoomMessagesHistory({ roomId, messages: data, flags });
  }

  removeAll(
    roomId: string
  ): ReturnType<typeof clearRoomMessagesHistory> {
    return clearRoomMessagesHistory({ roomId });
  }
}
