import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { MediaPreviewComponent } from './media-preview.component';
import { FileSizeModule } from '../../pipes/filesize/filesize.module';
import { MiddleEllipsizeModule } from '../../directives/middle-ellipsize/middle-ellipsize.module';

@NgModule({
  imports: [
    CommonModule,
    FileSizeModule,
    MiddleEllipsizeModule,
    WenIconModule,
  ],
  declarations: [
    MediaPreviewComponent
  ],
  exports: [
    MediaPreviewComponent
  ],
})
export class MediaPreviewModule { }
