import { InitialExtractorFn } from '../circle-initial-view.component';

export const spaceSeparatedInitialExtractor: InitialExtractorFn = (text: string) => {
  if (!text || !text[0]) {
    return '';
  }
  const splitted = text.split(' ');
  const first2Parts = splitted.length === 1 ? [splitted[0]] : [splitted[0], splitted[splitted.length - 1]];
  return first2Parts.map(part => {
    return emojiOrTextPart(part) || '';
  }).join('');
};

const emojiOrTextPart = (text: string): string => {
  const regexpPictographic = /(\p{Emoji_Presentation}){1}/gu;
  const result = text.match(regexpPictographic);
  return result ? result[0] : text[0];
};
