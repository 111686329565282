<div class="wen-weather-title">
  <div portal-headline>{{ weatherDate }}</div>
  <div>{{ 'WEATHER_CONTEXT_MENU_DAILY_DETAIL' | translate }}</div>
</div>
<div class="wen-weather-header">
  <img class="wen-weather-icon" [src]="weatherViewModel.icon">
  <div class="wen-weather-summary-header">
    <div class="wen-header-title">
      <div class="wen-weather-temperature">
        <ng-container *ngTemplateOutlet="weatherViewModel?.temperature?.max ? multiTemperature : singleTemperature; context: { $implicit: weatherViewModel.temperature }"></ng-container>
      </div>
      <div class="wen-weather-location">
        <wen-icon iconName="location_top_right" class="wen-icon-medium"></wen-icon>
        <span>{{ weatherViewModel.location }}</span>
      </div>
    </div>
    <div class="wen-header-description">
      {{ weatherViewModel.descriptionKey | translate }}
    </div>
  </div>
</div>

<div class="wen-weather-content">
  <div class="header">
    <span portal-subheading-1>{{ 'WEATHER_FORECAST_TEMPERATURE' | translate }}</span>
    <span portal-subheading-1>{{ 'WEATHER_FORECAST_PRECIPITATION' | translate }}</span>
    <span portal-subheading-1>{{ 'WEATHER_FORECAST_WIND' | translate }}</span>
  </div>
  <div cdkScrollable class="wen-weather-forecast-scroller">
    @for (model of weatherForecasts; track model) {
      <wen-detailed-forecast [model]="model"></wen-detailed-forecast>
    }
  </div>
</div>

<ng-template #singleTemperature let-temperature>
  <span portal-headline>{{ temperature | number: '1.0-0' }}°</span>
</ng-template>

<ng-template #multiTemperature let-temperature>
  <span portal-headline>{{temperature.max | number: '1.0-0' }}°</span>
  <span portal-headline>{{temperature.min | number: '1.0-0' }}°</span>
</ng-template>