import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenSeparatorComponent } from './separator.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [WenSeparatorComponent],
  exports: [WenSeparatorComponent],
})
export class WenSeparatorModule { }
