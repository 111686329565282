import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { filter, map, merge, switchMap, withLatestFrom } from 'rxjs';
import { firstExisty } from '../../../../core/common/operators/first-existy';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { selectChannelById, selectFeaturedData, selectMessageDetailById } from '../../../../core/store/channel/channel.selectors';
import { deleteComment } from '../../../../core/store/comments/comments.actions';
import { RootState } from '../../../../core/store/root/public-api';
import { ClientCustomizationProvider } from '../../../../frame/network/tokens';
import { CommentVisibility } from './comment-action-menu';

@Injectable()
export class CommentDeleteAction implements ActionMenuItem {

  label: string;
  icon = 'delete';
  iconColor = this.clientCustomizationProvider.getDefaultStyleConfig().errorColor;
  labelColor = this.clientCustomizationProvider.getDefaultStyleConfig().errorColor;

  private currentUserId$ = this.store.pipe(
    select(selectCurrentUserData),
    firstExisty(),
    map(({ userId }) => userId),
  );

  isVisible$ = ({ comment, messageId }: CommentVisibility) => {
    return merge(
      this.store.pipe(selectorWithParam(selectMessageDetailById, messageId)),
      this.store.pipe(select(selectFeaturedData), map((featuredData) => featuredData.message))
    ).pipe(
      firstExisty(),
      switchMap(channelOrFeaturedMessage => {
        return this.store.pipe(
          selectorWithParam(selectChannelById, channelOrFeaturedMessage.channelId),
          filter(channel => Boolean(channel.permission)),
          firstExisty(),
          map(({ permission }) => permission.canDeleteComment)
        );
      }),
      withLatestFrom(this.currentUserId$),
      map(([canDeleteComment, currentUserId]) => canDeleteComment || currentUserId === comment.userId)
    );
  };

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>,
    private clientCustomizationProvider: ClientCustomizationProvider
  ) {
    this.label = this.translateService.instant('COMMENT_CONTEXT_MENU_LABEL_DELETE');
  }

  execute({ comment }: CommentVisibility) {
    const { id } = comment;
    this.store.dispatch(deleteComment({ commentId: id }));
  }
}
