<div class="wen-user-list-item-wrapper">
  <div class="wen-user-list-item-icon-wrapper">
    <wen-circle-image-view [imageUrl]="user.avatarUrl" [shape]="'circle'"
      [placeholderText]="user.displayName"></wen-circle-image-view>
  </div>
  <div #userContent class="wen-user-list-item-content">
    <div class="wen-user-list-item-info">
      <div class="wen-user-list-item-title">
        <span class="wen-user-list-item-title-text">{{ user.displayName }}</span>
      </div>
      <div class="wen-user-list-item-description">
        <ng-content select="[wen-user-description]"></ng-content>
      </div>
    </div>
  </div>
  <div class="wen-user-list-item-after">
    <ng-content select="[wen-user-after]"></ng-content>
  </div>
</div>
