import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ThumbnailProvider } from '../../directives/thumbnail-evaluator.directive';
import { MediaViewParams } from '../../models/models';

@Component({
  selector: 'wen-embed-audio',
  templateUrl: './embed-audio.component.html',
  styleUrls: ['./embed-audio.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedAudioComponent implements OnDestroy {

  params: MediaViewParams;

  @HostBinding('class.wen-audio-embed') className = true;

  embedWidth$: Observable<number> = this.thumbnailProvider.thumbnail$.pipe(
    map(({ scaledDimensions: { width } }) => width)
  );

  @Input() set config(params: MediaViewParams) {
    this.params = params;
  }

  constructor(private thumbnailProvider: ThumbnailProvider) { }

  ngOnDestroy() {
    this.params = null;
  }

}
