import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SectionProps } from '../../../../../core/common/util/section.model';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { WenNativeApi } from '@portal/wen-native-api';
import { OccurenceEntity } from '../../../../../core/store/events/event.state';
import { SECTION_LIMIT, SectionService } from '../../../../../core/store/sections/sections.facade';
import { SectionEntity } from '../../../../../core/store/sections/sections.state';
import { WenRouteId } from '../../../../../frame/routing/types';
import { eventViewIdentifier } from '../../../tokens';
import { DataContext } from '../../../../../core/common/types/data-context';

@Component({
  selector: 'wen-event-section',
  templateUrl: './event-section.component.html',
  styleUrls: ['./event-section.component.scss']
})
export class EventSectionComponent {

  private sectionProps: SectionProps;

  sectionName: string;

  sectionItems$: Observable<OccurenceEntity[]>;

  isNativeApp = this.wenNativeApi.isReactNativeApp();

  sectionLimit = SECTION_LIMIT;

  @Input() set section(section: SectionEntity) {
    this.sectionName = section.name;
    this.sectionProps = {
      filterRef: section.filterRef,
      filterEntityType: section.filterType,
      filterId: section.filterId
    };
    this.sectionItems$ = this.sectionService.loadSectionContent(section.id, section.filterRef, DataContext.EVENT);
  }

  @Output() showAll = new EventEmitter<SectionProps>();

  constructor(
    private appNavigator: AppNavigator,
    private sectionService: SectionService,
    private wenNativeApi: WenNativeApi
  ) { }

  navigateToDetail(eventId: string) {
    this.appNavigator.navigateToRoute(WenRouteId.EVENT_DETAIL, { [eventViewIdentifier]: eventId });
  }

  showAllElements() {
    this.showAll.emit(this.sectionProps);
  }
}
