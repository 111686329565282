import { matchFirstRegExpGroup } from '../../../common/util/match-first-regexp';
import { channelDetailMatcher, channelPendingJoinRequestMatcher, chatMatcher, inviteMatcher, networkMatcher } from './deep-link-regexes';
import { DeepLinkRecognizer } from './deep-linkable';

export const simpleDeepLinkRecognizers: DeepLinkRecognizer[] = [
  {
    extractRouteLink: (link) => matchFirstRegExpGroup(link, inviteMatcher()),
    isMatchForUrl: (link) => Boolean(matchFirstRegExpGroup(link, inviteMatcher())),
  },
  {
    extractRouteLink: (link) => matchFirstRegExpGroup(link, networkMatcher()),
    isMatchForUrl: (link) => Boolean(matchFirstRegExpGroup(link, networkMatcher())),
  },
  {
    extractRouteLink: (link) => matchFirstRegExpGroup(link, channelPendingJoinRequestMatcher()),
    isMatchForUrl: (link) => Boolean(matchFirstRegExpGroup(link, channelPendingJoinRequestMatcher())),
  },
  {
    extractRouteLink: (link) => matchFirstRegExpGroup(link, chatMatcher()),
    isMatchForUrl: (link) => Boolean(matchFirstRegExpGroup(link, chatMatcher())),
  },
  {
    extractRouteLink: (link) => matchFirstRegExpGroup(link, channelDetailMatcher()),
    isMatchForUrl: (link) => Boolean(matchFirstRegExpGroup(link, channelDetailMatcher())),
  },
];
