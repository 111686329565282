import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICRMDataObject } from '../../services/smartdesign/providers/crm-data-object';
import { RootState } from '../root/public-api';
import { selectFeaturesConfigState } from '../ui/ui.selectors';
import { smartDesignFeatureKey } from './constants';
import { SmartDesignState, importedSelectionEntityAdapter } from './smartdesign.state';

export interface WithFeatureState extends RootState {
  [smartDesignFeatureKey]: SmartDesignState;
}

export const selectSmartDesignState = createFeatureSelector<SmartDesignState>(
  smartDesignFeatureKey
);

const {
  selectAll: selectAllImportedSelection,
} = importedSelectionEntityAdapter.getSelectors();

const filterOnlyNetworkInviteables = (dataObjects: ICRMDataObject[], isEmailInviteAllowed: boolean) => {
  if (!isEmailInviteAllowed) {
    return [];
  }
  const validDataObjects = dataObjects.filter((dataObject) => {
    return dataObject.email && dataObject.isEmailActivityAllowed && !dataObject.weNetworkUserId;
  });
  return validDataObjects;
};

const filterChannelInviteCapableEntities = (dataObjects: ICRMDataObject[], isEmailInviteAllowed: boolean) => {
  if (!isEmailInviteAllowed) {
    return dataObjects;
  }
  const validDataObjects = dataObjects.filter((dataObject) => {
    return dataObject.weNetworkUserId;
  });
  return validDataObjects;
};

const filterChatInviteCapableEntities = (dataObjects: ICRMDataObject[]) => {
  const validDataObjects = dataObjects.filter((dataObject) => {
    return dataObject.weNetworkUserId;
  });
  return validDataObjects;
};

export const selectIsFromSmartDesign = createSelector(
  selectSmartDesignState,
  (state) => state?.isFromSmartDesign
);

export const selectImportedSelection = createSelector(
  selectSmartDesignState,
  (state) => {
    const entities = selectAllImportedSelection(state.importedSelection);
    return entities;
  }
);

export const selectNetworkInviteSelection = createSelector(
  selectSmartDesignState,
  selectFeaturesConfigState,
  (state, featureConfig) => {
    if (!state.isFromSmartDesign || !state?.importedSelection) {
      return [];
    }
    const entities = selectAllImportedSelection(state.importedSelection);
    const validEntities = filterOnlyNetworkInviteables(entities, featureConfig.enableCRMEmailInviteToNetwork);
    return validEntities;
  }
);

export const selectCreateChatSelection = createSelector(
  selectSmartDesignState,
  selectFeaturesConfigState,
  (state, featureConfig) => {
    if (!state.isFromSmartDesign || !state?.importedSelection) {
      return null;
    }
    const { enableCRMEmailInviteToNetwork } = featureConfig;
    const entities = selectAllImportedSelection(state.importedSelection);
    const networkInviteableEntities = filterOnlyNetworkInviteables(entities, enableCRMEmailInviteToNetwork);
    const chatCreatableEntities = filterChatInviteCapableEntities(entities);
    return {
      networkInviteableEntities,
      chatCreatableEntities
    };
  }
);

export const selectChannelInvitableSelection = createSelector(
  selectSmartDesignState,
  selectFeaturesConfigState,
  (state, featureConfig) => {
    if (!state.isFromSmartDesign || !state?.importedSelection) {
      return null;
    }
    const { enableCRMEmailInviteToNetwork } = featureConfig;
    const entities = selectAllImportedSelection(state.importedSelection);
    const networkInviteableEntities = filterOnlyNetworkInviteables(entities, enableCRMEmailInviteToNetwork);
    const channelInviteableEntities = filterChannelInviteCapableEntities(entities, enableCRMEmailInviteToNetwork);
    return {
      networkInviteableEntities,
      channelInviteableEntities
    };
  }
);

export const selectWenUsers = createSelector(
  selectSmartDesignState,
  (state) => {
    if (!state.isFromSmartDesign || !state?.weNetworkUsers) {
      return null;
    }
    return state.weNetworkUsers;
  }
);

export const selectCurrentCRMRootRoute = createSelector(
  selectSmartDesignState,
  (state) => {
    if (!state.isFromSmartDesign) {
      return null;
    }
    return state.currentRootRoute;
  }
);
