@if (chat) {
  @if (chat.isMuted) {
    <button mat-mini-fab color="primary" class="mat-elevation-z0" (click)="unmuteChat()">
      <wen-icon iconName="mute"></wen-icon>
    </button>
  } @else {
    <button mat-mini-fab color="primary" class="mat-elevation-z0" (click)="muteChat()">
      <wen-icon iconName="unmute"></wen-icon>
    </button>
  }
}
