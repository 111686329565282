import { AppConfig, FeaturesConfig } from '@portal/wen-backend-api';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { SuffixProps } from '../../common/types/misc';

class FeaturesProxyWrapper<T = FeaturesConfig> {

  constructor(
    private flags$: Observable<Partial<T>>,
  ) {
    return new Proxy(this, this);
  }

  public get(_: any, propKey: string) {
    const propKeyNormalized = propKey.replace(/\$$/, '');
    return this.flags$.pipe(
      map((config) => config[propKeyNormalized]),
      distinctUntilChanged()
    );
  }

}

type PrefixedConfig<T = FeaturesConfig> = SuffixProps<T, '$'>;
export type ObservableFeaturesProxy = { [K in keyof PrefixedConfig]: Observable<boolean> };
export type ObservableAppProxy = { [K in keyof PrefixedConfig<AppConfig>]: Observable<boolean> };

export const createConfigProxy = <T, R>(
  flags$: Observable<Partial<T>>
) => {
  const proxy = new FeaturesProxyWrapper(flags$);
  return proxy as unknown as R;
};
