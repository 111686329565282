import { Injectable } from '@angular/core';
import { WenRouteId } from '../../../../frame/routing/types';
import { ACTIVE_ITEM_IMAGE_COMMAND_TOKEN } from '../../../../shared/components/active-item-image-view/active-item-image-view-providers';
import { DataObjectType } from '../../../store/common/data-objects';
import { FilterEntityType } from '../../../store/filter/models/filter';
import { HeaderCommandType } from '../../../store/header/models/HeaderCommand';
import { HeaderData, SearchFilterId } from '../../../store/header/models/HeaderData';
import { createAddNewChatCommand, createBackNavigationCommand, createCancelCommand, createCloseProfileNavigationCommand, createCrmPauseMassChatSendingCommand, createCrmProfileReadOnlyViewCommand, createExitEmbeddedAppNavigationCommand, createFinishWizardCommand, createForwardInWizardCommand, createInviteToNetworkCommand, createNewChatMenu, createSaveCommand, createUserProfileNavigationCommand } from '../../../store/header/models/base-commands';
import { FeatureEnablementService } from '../../configuration/feature-enablement';
import { AppNavigator } from '../app-navigator';
import { WenNavigationHelper } from '../types';
import { WenResolvedRouteData } from './route-data-types';

@Injectable()
export class WenMobileRouteDataResolver {

  constructor(
    private featureEnablement: FeatureEnablementService,
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
  ) { }

  getMobileRouteData(routeId: WenRouteId): WenResolvedRouteData {
    switch (routeId) {
      case WenRouteId.LOGIN:
      case WenRouteId.OAUTH_CALLBACK:
        return {
          headerData: null,
          hideNavigationBar: true
        };
      case WenRouteId.DASHBOARD:
      case WenRouteId.USERPROFILE_DASHBOARD:
      case WenRouteId.ERROR_PAGE:
        const fallbackHeaderData: HeaderData = {
          title: null,
          showLogo: true
        };
        return { headerData: fallbackHeaderData, hideNavigationBar: true };
      case WenRouteId.HOME:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createUserProfileNavigationCommand()
          },
          hideNavigationBar: false
        };
      case WenRouteId.CHANNEL_LIST:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createUserProfileNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'CHANNELS_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.CHANNEL_LISTS
              }
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.CHANNEL_DISCOVERY:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'CHANNEL_DISCOVERY_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.CHANNEL_LISTS
              }
            }
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_EXPLORE:
        return {
          headerData: {
            title: 'CHANNEL_SHOW_MORE',
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'CHANNEL_DISCOVERY_SEARCH_PLACEHOLDER',
              disabled: true,
              navigateToFn: () => this.navigationHelper.navigateToChannelDiscover()
            }
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_SCHEDULES:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            title: 'CHANNEL_SCHEDULED_MESSAGES_TITLE'
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_DETAIL:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_SETTING:
        return {
          headerData: {
            title: 'CHANNEL_DETAIL_SETTING_TITLE',
            showLogo: false,
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL_SETTING)
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_MESSAGE_DETAIL:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_INFO_EDIT:
        return {
          headerData: {
            title: 'CHANNEL_EDIT_INFO_FORM_TITLE',
            showLogo: false,
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL)
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_VISIBILITY_SETTINGS:
        return {
          headerData: {
            title: 'CHANNEL_VISIBILITY_SETTINGS_FORM_TITLE',
            showLogo: false,
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL)
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_RESTRICTION_SETTINGS:
        return {
          headerData: {
            title: 'CHANNEL_RESTRICTION_SETTINGS_FORM_TITLE',
            showLogo: false,
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL)
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_INTERACTION_SETTINGS:
        return {
          headerData: {
            title: 'CHANNEL_INTERACTION_SETTINGS_FORM_TITLE',
            showLogo: false,
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL)
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_VIEW:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            rightCommand: {
              type: HeaderCommandType.OPEN_CHANNEL_DETAIL,
              componentToken: ACTIVE_ITEM_IMAGE_COMMAND_TOKEN
            },
            centerCommand: {
              type: HeaderCommandType.OPEN_CHANNEL_DETAIL
            },
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_POST_EDITOR:
        return {
          headerData: {
            showLogo: false,
            title: 'CHANNEL_POST_EDITOR_FORM_TITLE',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHANNEL_POST),
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_POST_LOCATION_SELECTOR:
        return {
          headerData: {
            showLogo: false,
            title: 'CHANNEL_POST_LOCATION_SELECTOR_FORM_TITLE',
            leftCommand: createCancelCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_FEATURED_VIEW:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            centerCommand: {
              type: HeaderCommandType.OPEN_CHANNEL_DETAIL
            }
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_ADD_WELCOME:
      case WenRouteId.APP_ADD_WELCOME:
        return {
          headerData: {
            leftCommand: createCancelCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.ADD_CHANNEL_INFO:
        return {
          headerData: {
            title: 'CREATE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'CREATE_CHANNEL_WIZARD_STEP_1_SUBTITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE),
          },
          hideNavigationBar: true
        };
      case WenRouteId.ADD_CHANNEL_VISIBILITY:
        return {
          headerData: {
            title: 'CREATE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'CREATE_CHANNEL_WIZARD_STEP_2_SUBTITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE),
          },
          hideNavigationBar: true
        };
      case WenRouteId.ADD_CHANNEL_RESTRICTIONS:
        return {
          headerData: {
            title: 'CREATE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'CREATE_CHANNEL_WIZARD_STEP_3_SUBTITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE),
          },
          hideNavigationBar: true
        };
      case WenRouteId.ADD_CHANNEL_INTERACTIONS:
        return {
          headerData: {
            title: 'CREATE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'CREATE_CHANNEL_WIZARD_STEP_4_SUBTITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createFinishWizardCommand(DataObjectType.CHANNEL),
          },
          hideNavigationBar: true
        };
      case WenRouteId.APP_LIST:
        return {
          headerData: {
            title: 'APP_EXPLORE_MINE',
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'APPS_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.APP_LISTS
              }
            }
          },
          hideNavigationBar: true
        };
      case WenRouteId.APP_DISCOVERY:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'APP_DISCOVERY_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.APP_LISTS
              }
            }
          },
          hideNavigationBar: true
        };
      case WenRouteId.APP_DETAIL:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.APP_INFO_EDIT:
        return {
          headerData: {
            title: 'APP_EDIT_INFO_FORM_TITLE',
            showLogo: false,
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.APP)
          },
          hideNavigationBar: true
        };
      case WenRouteId.ADD_APP_INFO:
        return {
          headerData: {
            title: 'CREATE_APP_WIZARD_FORM_TITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createFinishWizardCommand(DataObjectType.APP),
          },
          hideNavigationBar: true
        };
      case WenRouteId.APP_VIEW:
        return {
          headerData: {
            showLogo: true,
            centerCommand: createExitEmbeddedAppNavigationCommand(),
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.APP_EXPLORE:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createUserProfileNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'APP_DISCOVERY_SEARCH_PLACEHOLDER',
              disabled: true,
              navigateToFn: () => this.appNavigator.navigateToRoute(WenRouteId.APP_DISCOVERY)
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.USERPROFILE_VIEW:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createCloseProfileNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.OTHER_USERPROFILE_READ:
      case WenRouteId.USERPROFILE_READ:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_EDIT:
        return {
          headerData: {
            title: 'PROFILE_EDIT_TITLE',
            showLogo: false,
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.USER)
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_EMAIL_SETTINGS:
        return {
          headerData: {
            title: 'USER_PROFILE_EMAIL_SETTINGS_HEADER_TITLE',
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_CHANGE_EMAIL:
        return {
          headerData: {
            title: 'USER_PROFILE_EMAIL_CHANGE_FORM_TITLE',
            showLogo: false,
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.USER)
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_CHANGE_PASSWORD:
        return {
          headerData: {
            title: 'USER_PROFILE_PASSWORD_EDIT_FORM_HEADER_LABEL',
            showLogo: false,
            leftCommand: createCancelCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE)
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_CHANGE_PASSWORD_VERIFY_CODE:
        return {
          headerData: {
            title: 'USER_PROFILE_PASSWORD_EDIT_FORM_HEADER_LABEL',
            showLogo: false,
            leftCommand: createCancelCommand(),
            rightCommand: createFinishWizardCommand(DataObjectType.USER_PASSWORD)
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_HELP:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_HELP',
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_FAQ:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_FAQ',
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_MARKDOWN:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_MARKDOWN',
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_HOTLINE:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_HOTLINE',
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_MANIFEST:
      case WenRouteId.USERPROFILE_LICENSES:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_COMING_SOON:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };

      case WenRouteId.USERPROFILE_LANGUAGE:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_LANGUAGE',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.LANGUAGE)
          },
          hideNavigationBar: true
        };

      case WenRouteId.USERPROFILE_SETTINGS:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_SETTINGS',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.USER)
          },
          hideNavigationBar: true
        };
      case WenRouteId.USER_SETTINGS:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_ACCOUNT_SETTINGS',
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_PERSONAL_ACCOUNT_SETTINGS:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_PERSONAL_ACCOUNT_SETTINGS',
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_CREDENTIALS_MENU:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_CREDENTIALS_MENU',
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_CREDENTIAL_DETAILS:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.USERPROFILE_PRIVACY_SETTINGS:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_PRIVACY_SETTINGS',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.PRIVACY_SETTINGS)
          },
          hideNavigationBar: true
        };
      case WenRouteId.CONTACTS_LIST:
        return {
          headerData: {
            showLogo: false,
            title: 'CONTACTS_LIST_HEADER',
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'CONTACTS_LIST_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.CONTACT_SEARCH,
                filterEntityType: FilterEntityType.CONTACTS_USER_LIST_FILTER
              },
              disableAnimations: true
            }
          },
          hideNavigationBar: true
        };
      case WenRouteId.PENDING_CONTACTS_LIST:
        return {
          headerData: {
            showLogo: false,
            title: 'PENDING_CONTACTS_LIST_HEADER',
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.INVITE_TO_NETWORK:
        return {
          headerData: {
            showLogo: false,
            title: 'USER_PROFILE_INVITE_HEADER_TITLE',
            leftCommand: createCancelCommand(),
            rightCommand: createInviteToNetworkCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.INVITE_TO_CHANNEL_SELECTOR:
        return {
          headerData: {
            title: 'INVITE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'INVITE_CHANNEL_WIZARD_STEP_1_SUBTITLE',
            leftCommand: createCancelCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE),
          },
          hideNavigationBar: true
        };
      case WenRouteId.INVITE_TO_CHANNEL:
        return {
          headerData: {
            title: 'INVITE_CHANNEL_WIZARD_FORM_TITLE',
            subTitle: 'INVITE_CHANNEL_WIZARD_STEP_2_SUBTITLE',
            leftCommand: createCancelCommand(),
            rightCommand: createFinishWizardCommand(DataObjectType.INVITE_TO_CHANNEL),
          },
          hideNavigationBar: true
        };
      case WenRouteId.CRM_ACTION_SELECTOR:
        return {
          headerData: {
            showLogo: false,
            title: 'CRM_ACTION_SELECTOR_HEADER_TITLE',
            rightCommand: createCrmProfileReadOnlyViewCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CRM_PROFILE_READ_ONLY_VIEW:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CRM_DISTRIBUTE_CHAT:
        return {
          headerData: {
            showLogo: false,
            title: 'CRM_DISTRIBUTE_CHAT_HEADER_TITLE',
            leftCommand: this.featureEnablement.isEnableCRMDistributionCancelling() ?
              createCrmPauseMassChatSendingCommand() : null
          },
          hideNavigationBar: true
        };
      case WenRouteId.CRM_CHAT_PREVIEW:
        return {
          headerData: {
            showLogo: true
          },
          hideNavigationBar: true
        };
      case WenRouteId.EMBEDDED_PAGE:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.EVENT_LIST:
        return {
          headerData: {
            title: 'USER_EVENT_LIST_HEADER_TITLE',
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'EVENT_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.EVENT_LISTS
              }
            }
          },
          hideNavigationBar: true
        };
      case WenRouteId.EVENT_DISCOVERY:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'EVENT_DISCOVERY_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.EVENT_LISTS
              }
            }
          },
          hideNavigationBar: true
        };
      case WenRouteId.EVENT_EXPLORE:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createUserProfileNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'EVENT_DISCOVERY_SEARCH_PLACEHOLDER',
              disabled: true,
              navigateToFn: () => this.appNavigator.navigateToRoute(WenRouteId.EVENT_DISCOVERY)
            }
          },
          hideNavigationBar: false
        };
      case WenRouteId.EVENT_DETAIL:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              hideSearchBar: true,
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.EVENT_LISTS
              }
            }
          },
          hideNavigationBar: true
        };
      case WenRouteId.REGISTRATION_START:
      case WenRouteId.REGISTRATION_PROFILE:
      case WenRouteId.REGISTRATION_CODE_VERIFICATION:
        return {
          headerData: null,
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_PENDING_JOIN_REQUESTS:
        return {
          headerData: {
            title: 'CHANNEL_PENDING_JOIN_REQUESTS_FORM_TITLE',
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_ADMIN_LIST:
        return {
          headerData: {
            title: 'CHANNEL_ADMIN_LIST_FORM_TITLE',
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_ADD_ADMIN:
        return {
          headerData: {
            title: 'CHANNEL_ADD_ADMIN_TITLE',
            leftCommand: createBackNavigationCommand(),
            searchConfig: {
              searchBarPlaceholder: 'CHANNEL_ADD_ADMIN_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.ADMIN_SEARCH,
                filterEntityType: FilterEntityType.CHANNEL_ADD_ADMIN_LISTS
              },
            }
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_CONTENT_PROVIDERS:
        return {
          headerData: {
            title: 'CHANNEL_CONTENT_PROVIDER_TITLE',
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_ADD_CONTENT_PROVIDER:
        return {
          headerData: {
            title: 'CHANNEL_ADD_CONTENT_PROVIDER_TITLE',
            searchConfig: {
              searchBarPlaceholder: 'CHANNEL_ADD_CONTENT_PROVIDER_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.USER_SEARCH,
                filterEntityType: FilterEntityType.CHANNEL_ADD_CONTENT_PROVIDER_LISTS
              },
            },
            leftCommand: createCancelCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHANNEL_SUBSCRIBERS:
        return {
          headerData: {
            title: 'CHANNEL_SUBSCRIBERS_TITLE',
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHAT_VIEW:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            centerCommand: {
              type: HeaderCommandType.OPEN_ROOM_DETAIL
            },
            rightCommand: {
              type: HeaderCommandType.OPEN_ROOM_DETAIL,
              componentToken: ACTIVE_ITEM_IMAGE_COMMAND_TOKEN
            },
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHAT_LIST:
        return {
          headerData: {
            leftCommand: createUserProfileNavigationCommand(),
            showLogo: true,
            searchConfig: {
              searchBarPlaceholder: 'CHAT_SEARCH_PLACEHOLDER',
              filterConfig: {
                filterId: SearchFilterId.LIST_SEARCH,
                filterEntityType: FilterEntityType.CHAT_LISTS
              },
            },
            rightCommand: this.featureEnablement.featureFlagMethods.isEnableGroupChat() ? createNewChatMenu() : createAddNewChatCommand()
          },
          hideNavigationBar: false
        };
      case WenRouteId.CHAT_CREATION:
        return {
          headerData: {
            title: 'CHAT_CREATION_TITLE',
            leftCommand: createCancelCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHAT_GROUP_DETAIL:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHAT_SCHEDULES:
        return {
          headerData: {
            showLogo: false,
            leftCommand: createBackNavigationCommand(),
            title: 'CHAT_SCHEDULED_MESSAGES_TITLE'
          },
          hideNavigationBar: true
        };
      case WenRouteId.ADD_CHAT_USERS:
        return {
          headerData: {
            title: 'CREATE_GROUP_CHAT_WIZARD_TITLE',
            subTitle: 'CREATE_GROUP_CHAT_WIZARD_STEP_1_SUBTITLE',
            leftCommand: createCancelCommand(),
            rightCommand: createForwardInWizardCommand(HeaderCommandType.FORWARD_IN_CREATE),
          },
          hideNavigationBar: true
        };
      case WenRouteId.ADD_CHAT_INFO:
        return {
          headerData: {
            title: 'CREATE_GROUP_CHAT_WIZARD_TITLE',
            subTitle: 'CREATE_GROUP_CHAT_WIZARD_STEP_2_SUBTITLE',
            leftCommand: createBackNavigationCommand(),
            rightCommand: createFinishWizardCommand(DataObjectType.CHAT),
          },
          hideNavigationBar: true
        };
      case WenRouteId.CHAT_INFO_EDIT:
        return {
          headerData: {
            title: 'EDIT_GROUP_CHAT_WIZARD_TITLE',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHAT),
          },
          hideNavigationBar: true
        };
      case WenRouteId.ADD_CHAT_GROUP_MEMBERS:
        return {
          headerData: {
            title: 'GROUP_CHAT_ADD_MEMBERS',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.CHAT_MEMBERS)
          },
          hideNavigationBar: true
        };
      case WenRouteId.AUTO_REPLY:
        return {
          headerData: {
            title: 'USER_PROFILE_AUTO_REPLY',
            leftCommand: createCancelCommand(),
            rightCommand: createSaveCommand(DataObjectType.AUTO_REPLY)
          },
          hideNavigationBar: true
        };
      case WenRouteId.EXCLUSIVE_INVITE:
        return {
          headerData: null,
          hideNavigationBar: true
        };
      case WenRouteId.READER_VIEW:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: false
        };
      case WenRouteId.TEXT_VIEW:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: false
        };
      case WenRouteId.WALLET_CREDENTIAL_CREATE:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createCancelCommand()
          },
          hideNavigationBar: false
        };
      case WenRouteId.WALLET_INITIATE_ISSUANCE:
        return {
          headerData: {
            showLogo: true,
            leftCommand: createBackNavigationCommand()
          },
          hideNavigationBar: true
        };
      default:
        console.warn(`Not supported route ${routeId}`);
        return;
    }
  }
}
