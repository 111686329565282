import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PortalButtonModule } from '@portal/ui-kit/button';
import { WenIconModule } from '@portal/wen-components';
import { CommentListModule } from '../../../shared/components/comments/comment-list.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelMessagesViewModule } from '../common/components/channel-messages-view/channel-messages-view.module';
import { ChannelFeaturedViewComponent } from './channel-featured-view.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    ChannelMessagesViewModule,
    WenIconModule,
    PortalButtonModule,
    CommentListModule
  ],
  declarations: [
    ChannelFeaturedViewComponent,
  ],
  exports: [
    ChannelFeaturedViewComponent
  ]
})
export class ChannelFeaturedModule { }
