import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { TracerInitializer } from '@portal/wen-tracer';
import { distinctUntilChanged, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { existy } from '../../../common/operators/existy';
import { filterBy } from '../../../common/operators/fitler-by';
import { isNullOrUndefined } from '../../../common/operators/null-check-util';
import { FeatureEnablementService } from '../../../services/configuration/feature-enablement';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { RootState } from '../../root/public-api';
import { subscribeToProfileUpdates, upsertUserMetaDataSettings } from '../user.actions';
import { selectUserMetaDataSettings } from '../user.selectors';

@Injectable()
export class MetaDataSettingsEffects {

  private readonly store = inject(Store<RootState>);
  private readonly actions$ = inject(Actions);
  private readonly socketIoService = inject(SocketIoService);
  private readonly featureEnablementService = inject(FeatureEnablementService);
  private readonly tracerInitializer = inject(TracerInitializer);

  fetchInitialMetaData$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToProfileUpdates),
    first(),
    switchMap(() => {
      return this.socketIoService.user.getMetaDataSettings.acknowledgement$().pipe(
        filter((response) => !isNullOrUndefined(response?.loggingAllowed)),
        map((response) => {
          const { loggingAllowed } = response;
          return upsertUserMetaDataSettings({
            newSettings: { traceReportsEnabled: loggingAllowed }
          });
        })
      );
    })
  ));

  handleTracingEnablementChanges$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToProfileUpdates),
    first(),
    switchMap(() => {
      return this.store.pipe(
        filterBy(() => this.featureEnablementService.featureConfigObs.enableTracing$),
        select(selectUserMetaDataSettings),
        existy(),
        map(({ traceReportsEnabled }) => traceReportsEnabled),
        distinctUntilChanged(),
        withLatestFrom(this.store.pipe(select(selectCurrentUserData))),
        tap(([traceReportsEnabled, userData]) => {
          if (traceReportsEnabled) {
            const userId = userData.userId;
            this.tracerInitializer.enableSentry({ userData: { userId } });
          } else {
            this.tracerInitializer.disableSentry();
          }
        })
      );
    })
  ), { dispatch: false });

}
