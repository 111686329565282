import { inject, Injectable } from '@angular/core';
import { incrementingRetry } from '@portal/wen-common';
import { Tracer } from '@portal/wen-tracer';
import { deleteDB, IDBPDatabase, IDBPTransaction, openDB, StoreNames } from '@tempfix/idb';
import { BehaviorSubject, catchError, distinctUntilChanged, filter, first, from, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { IdbError } from '../../error-types';
import { WEN_CHAT_CLIENT_CONFIG } from '../../tokens';
import { WenChatClientConfig } from '../../types';
import { getCurrentDateTime } from '../../util/date-util';
import { asObservable } from '../util';
import { ChatCryptoDB, ObjectStores } from './indexed-db-schema';

export enum TransactionMode {
  READWRITE = 'readwrite',
  READONLY = 'readonly',
}

type ChatCryptoDbType<S extends ArrayLike<StoreNames<ChatCryptoDB>>> = IDBPTransaction<ChatCryptoDB, S, TransactionMode>;

@Injectable()
export class OlmIndexedDb {

  private config = inject<WenChatClientConfig>(WEN_CHAT_CLIENT_CONFIG, { optional: true });

  private readonly DB_NAME: string;
  private readonly DB_VERSION = 2;

  private tracer = inject(Tracer);
  private databaseInsance = new BehaviorSubject<IDBPDatabase<ChatCryptoDB>>(null);
  private database$ = this.databaseInsance.pipe(distinctUntilChanged());
  private get currentDb() {
    return this.databaseInsance.getValue();
  }

  constructor(
  ) {
    this.DB_NAME = this.config?.dbName || 'wen-chat-client:crypto';
  }

  init(afterInit?: () => void) {
    const openDb$ = from(openDB<ChatCryptoDB>(this.DB_NAME, this.DB_VERSION, {
      upgrade: (db, oldVersion, newVersion, transaction) => {
        this.doUpgrade(db, oldVersion, newVersion, transaction);
      },
      terminated: () => {
        this.tracer.captureException(new IdbError('Idb terminated'));
        this.databaseInsance.next(null);
        this.init(() => {
          this.tracer.captureException(new IdbError('IDB was restored after unexpected termination'));
        });
      },
    })).pipe(
      first(),
      shareReplay(1),
      catchError(error => {
        this.tracer.addBreadcrumb({ category: 'idb.open_error', level: 'log', });
        this.tracer.captureException(error);
        return of(null);
      })
    );
    openDb$.subscribe((database) => {
      if (!database) {
        return;
      }
      this.databaseInsance.next(database);
      this.tracer.addBreadcrumb({ category: 'idb.opened successfully', level: 'log', });
      if (afterInit) {
        afterInit();
      }
      database.addEventListener('error', (errorEvent) => {
        this.tracer.addBreadcrumb({ category: 'idb.error', level: 'log', });
        this.tracer.captureException(errorEvent);
      }, { once: true });
      database.addEventListener('abort', (abortEvent) => {
        this.tracer.addBreadcrumb({ category: 'idb.abort', level: 'log', });
        this.tracer.captureException(abortEvent);
      }, { once: true });
    });
    return openDb$;
  }

  closeDb() {
    if (this.currentDb) {
      this.currentDb.close();
    }
  }

  clearDb() {
    if (this.currentDb) {
      this.currentDb.close();
    }
    return asObservable(deleteDB(this.DB_NAME));
  }

  openTransaction<T, S extends ArrayLike<StoreNames<ChatCryptoDB>>>(
    mode: TransactionMode,
    stores: S,
    operation: (txn: ChatCryptoDbType<S>) => T
  ) {
    return of(null).pipe(
      switchMap(() => {
        return this.tryOpenTransaction(mode, stores);
      }),
      incrementingRetry(3, 200),
      switchMap(tx => {
        const result = operation(tx);
        let resultPromises = [];
        resultPromises = [
          result instanceof Promise ? result : Promise.resolve(result),
          tx.done
        ];
        return asObservable(Promise.all(resultPromises)).pipe(
          map(([resultValue, _]) => {
            return resultValue as Awaited<typeof result>;
          })
        );
      })
    );
  }

  private tryOpenTransaction<S extends ArrayLike<StoreNames<ChatCryptoDB>>>(
    mode: TransactionMode,
    stores: S,
  ): Observable<IDBPTransaction<ChatCryptoDB, S, TransactionMode>> {
    const tx: Observable<ChatCryptoDbType<S>> = this.database$.pipe(
      filter(db => Boolean(db)),
      first(),
      map(database => {
        return database.transaction(stores, mode);
      }),
      catchError((error) => {
        return this.init().pipe(
          map(() => null),
          tap(() => {
            this.tracer.addBreadcrumb({ category: 'idb.transaction_error', level: 'log', });
            this.tracer.captureException(error);
            throw error;
          })
        );
      })
    );
    return tx;
  }

  private async doUpgrade(
    database: IDBPDatabase<ChatCryptoDB>,
    oldVersion: number,
    newVersion: number | null,
    transaction: IDBPTransaction<ChatCryptoDB, StoreNames<ChatCryptoDB>[], 'versionchange'>
  ) {
    if (oldVersion < 1) {
      database.createObjectStore(ObjectStores.DEVICES);
      database.createObjectStore(ObjectStores.ACCOUNT);
      const sessionStore = database.createObjectStore(ObjectStores.SESSIONS, {
        keyPath: ['curve25519', 'sessionId'],
      });
      sessionStore.createIndex('byCurve25519', 'curve25519');
      database.createObjectStore(ObjectStores.INBOUND_GROUP_SESSIONS, {
        keyPath: ['senderCurve25519', 'sessionId'],
      });
    }
    if (oldVersion === 1 && newVersion === 2) {
      const store = transaction.objectStore(ObjectStores.SESSIONS);
      const currentSessions = await store.getAll();
      currentSessions.forEach(session => {
        session.lastActivityTimestamp = session.lastActivityTimestamp || getCurrentDateTime();
        store.put(session);
      });
    }
  }

}
