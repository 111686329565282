import { Observable, retry, timer } from 'rxjs';

/**
 * Retry a stream when failed
 * Each retry will be delayed incrementally
 *  eg. with 200ms delay: 1st try: 200ms, 2nd try: 400ms, 3rd try: 600ms
 *
 * @param maxCount The number of retries
 * @param delay The increment delay
 * @returns
 */
export const incrementingRetry = (
  maxCount: number, delay: number
) => <T>(source: Observable<T>) => {
  return source.pipe(
    retry({ count: maxCount, delay: (_, retryCount) => timer(retryCount * delay) }),
  );
};
