@if (previewData$ | async; as previewData) {
  <wen-horizontal-card>
    @if (previewData.thumbnail; as thumbnail) {
      <ng-container
        ngProjectAs="[wen-horizontal-card-image]">
        <wen-thumbnail-image wen-horizontal-card-image [thumbnail]="thumbnail" [fixWidth]="112" [disableScaling]="true">
        </wen-thumbnail-image>
      </ng-container>
    } @else {
      @if (previewData$ | async; as previewData) {
        <wen-circle-image-view wen-horizontal-card-image shape="square" [imageUrl]="previewData.image"
        [placeholderText]="previewData.imagePlaceholder" [fontSize]="44"></wen-circle-image-view>
      }
    }
    <h3 wen-horizontal-card-title [innerHTML]="previewData.title | removeMarkdown"></h3>
    <h2 wen-horizontal-card-description [innerHTML]="previewData.description | removeMarkdown"></h2>
    <h3 wen-horizontal-card-link>{{ 'HOME_CARD_OPEN_FEATURED' | translate }}</h3>
  </wen-horizontal-card>
}

