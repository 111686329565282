import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { WenRouteWithId } from '../../../../../frame/routing/types';
import { FormStoreMediator } from '../../../../../shared/form-store/form-store-mediator';
import { BackNavigationBehavior, BackNavigationBehaviorOutlets, BackNavigationBehaviorResult } from './back-navigation-behavior';

@Injectable()
export class FormEditLeaveConfirmationBackNavigation implements BackNavigationBehavior {

  constructor(
    private formStoreMediator: FormStoreMediator,
  ) { }

  execute({ topLevelOutletId }: BackNavigationBehaviorOutlets, data: Partial<WenRouteWithId>) {
    const { formStoreStoreKey = topLevelOutletId } = data;
    const onResult = new ReplaySubject<BackNavigationBehaviorResult>(1);
    this.formStoreMediator.withEditModeConfirmation(() => {
      onResult.next({ canHandle: false });
      onResult.complete();
    }, formStoreStoreKey);
    return onResult.asObservable();
  }

}
