import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Subject, map, shareReplay, takeUntil } from 'rxjs';
import { FilterType } from '../../../../../core/store/filter/models/filter';
import { FilterElementDirective } from '../../../../directives/filter-element/filter-element.directive';
import { Filterable } from '../../models/filter-item';

export type FilterModel = Filterable;

@Component({
  selector: 'wen-filter-select-component',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterSelectComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FilterSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private onDestroy$ = new Subject<void>();

  private onChange: (value: FilterModel) => void = (_: any) => { };
  private onTouched: () => void = () => { };

  modelValue: FilterModel;

  @HostBinding('class.filter-wrapper') className = true;

  @Input() defaultFilter: Filterable;

  constructor(
    private cdr: ChangeDetectorRef,
    private filterElementDirective: FilterElementDirective,
  ) { }

  ngOnInit() {
    this.filterElementDirective.activeFilter$.pipe(
      map(filter => {
        if (!filter?.filterValue) {
          return this.defaultFilter;
        }
        if (filter.filterType === FilterType.SELECT) {
          return filter.filterValue;
        }
        return null;
      }),
      shareReplay(1),
      smartDistinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe((activeFilter) => {
      this.modelValue = activeFilter;
      this.onChange(this.modelValue);
      this.cdr.detectChanges();
    });
  }

  writeValue(newValue: FilterModel): void {
    this.filterElementDirective.updateFilter(newValue, FilterType.SELECT);
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      throw new Error('Disabling FilterSelectComponent is not supported!');
    }
  }

  registerOnChange(fn: (value: FilterModel) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
