import { EntityState } from '@ngrx/entity';
import { ChannelEntity } from '../channel.state';

export const findChannelById = (
  channelId: string,
  channels: EntityState<ChannelEntity>
) => {
  const channelById = channels.entities[channelId];
  return channelById;
};

/**
 * Clean up the beginning of an array based on some special logic:
 *  - If the array has no more than 20 items, leave it as it is
 *  - If no keepId present keep the last items defined by 'cleanOffset'
 *    eg.: with default cleanOffset it returns max 12 items
 *  - If keepId present keep 'cleanOffset' amount items before the specific id
 *    eg.: with default cleanOffset, the array will contain max 12 items before the item with the specified id
 *
 * @returns the cleaned up array and flags indicating if the start or the end of the array was left intact or not
 */
export const cleanupArray = <T extends { id: string }>(
  arr: T[], keepId?: string, cleanOffset = 11
) => {
  if (arr.length < 20) {
    return { result: arr, didCutBefore: false};
  }
  let result: T[] = [];
  let start = Math.max(0, arr.length - cleanOffset - 1);

  if (keepId) {
    const cutKeepIndex = arr.findIndex((item) => {
      return item.id === keepId;
    });
    if (cutKeepIndex !== -1) {
      start = Math.max(0, cutKeepIndex - cleanOffset);
    }
  }

  result = arr.slice(start, arr.length);
  return {
    result,
    didCutBefore: result[0]?.id !== arr[0]?.id,
  };
};
