import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StoreNavigator } from '../../../core/services/navigation/store-navigator';
import { BrowserInfo } from '../../../core/services/util/browser-info';
import { AppBannerVisibility } from './app-banner-visibility';

@Component({
  selector: 'wen-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent {

  @Input() smartStoreButtons = false;

  @Output() closed = new EventEmitter<void>();

  get showAppStoreButton() {
    return this.appBannerVisibility.hasIosConfig();
  }

  get showPlayStoreButton() {
    return this.appBannerVisibility.hasAndroidConfig();
  }

  constructor(
    private browserInfo: BrowserInfo,
    private storeNavigator: StoreNavigator,
    private appBannerVisibility: AppBannerVisibility,
  ) { }

  emitClosed() {
    this.closed.emit();
  }

  goToApp() {
    if (this.browserInfo.browserIsAndroid()) {
      this.goToPlayStore();
    } else if (this.browserInfo.browserIsIos()) {
      this.goToAppStore();
    } else {
      this.goToPlayStore();
    }
  }

  goToPlayStore() {
    this.storeNavigator.goToPlayStore();
  }

  goToAppStore() {
    this.storeNavigator.goToAppStore();
  }

}
