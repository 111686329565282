export class ChatGenericError extends Error {

  constructor(
    public readonly description: string
  ) {
    super();
    this.message = `ChatGenericError: ${description}`;
  }

}

interface MegolmSessionNotFoundErrorParams {
  sessionId: string;
  senderCurve25519: string;
}

export class MegolmSessionNotFoundError extends Error {

  constructor(
    public readonly params: MegolmSessionNotFoundErrorParams
  ) {
    super();
    this.message = `MegolmSessionNotFoundErrorParams + ${JSON.stringify(this.params)}`;
  }

}

interface OlmDecryptionErrorParams {
  sessionId: string;
  senderCurve25519: string;
}

export class OlmDecryptionError extends Error {

  constructor(
    public readonly params: OlmDecryptionErrorParams
  ) {
    super();
    this.message = `OlmDecryptionError + ${JSON.stringify(this.params)}`;
  }

}

export class OlmInboundDesyncError extends OlmDecryptionError {

  constructor(
    public readonly params: OlmDecryptionErrorParams
  ) {
    super(params);
    this.message = `OlmInboundDesyncError + ${JSON.stringify(this.params)}`;
  }

}

/**
 * Error thrown if there are no active receiver devices when creating the outbound
 * This can happen if eg. the target user's all devices ran out of one-time-keys
 */
export class NoActiveReceiverDeviceError extends Error {

  constructor(
  ) {
    super();
    this.message = `NoActiveReceiverDeviceError`;
  }

}

export class IdbError extends Error {

  constructor(
    public readonly description: string
  ) {
    super();
    this.message = `IdbErrorError: ${description}`;
  }

}
