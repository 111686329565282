
import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { WenRouteWithId } from '../../../frame/routing/types';
import { extractOutletDatas, extractOutletIds, extractOutlets } from '../../services/navigation/outlet-specific/outlet-utils';
import { RootState } from './public-api';

export const selectRouter = createFeatureSelector<
  RouterReducerState
>('router');

export const selectOutlets = createSelector(
  selectRouter,
  (router) => {
    return extractOutlets(router?.state?.root);
  }
);

export const selectOutletIds = createSelector(
  selectRouter,
  (router) => {
    return extractOutletIds(router?.state?.root);
  }
);

export const selectOutletDatas = createSelector(
  selectRouter,
  (router) => {
    return extractOutletDatas(router?.state?.root);
  }
);

const { selectRouteData: routeDataSelector, selectRouteParams: routeParamsSelector } = getRouterSelectors(selectRouter);
export const selectRouteData: MemoizedSelector<
  RootState, Partial<WenRouteWithId>, DefaultProjectorFn<Partial<WenRouteWithId>>
> = routeDataSelector;
export const selectRouteParams = routeParamsSelector;
export const selectAllRouteParams = createSelector(
  selectOutlets,
  (outlets) => {
    const allParams = {
      ...outlets.primary?.params,
      ...outlets.sidebar?.params,
      ...outlets.dialog?.params
    };
    return allParams;
  }
);
export const selectRouteParam = <T = string>(param: string) => createSelector(
  selectAllRouteParams,
  (allParams) => {
    const targetParam: T = allParams[param];
    return targetParam;
  }
);
