import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ResizeHandlerProvider, WenBreakpointObserver } from '@portal/wen-components';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { WenNativeApi } from '@portal/wen-native-api';
import { IosKeyboardStickyHelper } from './ios-keyboard-sticky-helper';

@Directive({
  selector: '[wenIosKeyboardStickyTarget], wenIosKeyboardStickyTarget',
  providers: [
    ResizeHandlerProvider
  ]
})
export class IosKeyboardStickyTargetDirective implements OnDestroy {

  private blur = new Subject<void>();

  @HostListener('focus')
  onFocus() {
    if (this.isDisabled() || !this.featureEnablement.featureFlagMethods.isEnableIosKeyboardUtils()) {
      return;
    }
    this.nativeApi.keyboardDidShow$.pipe(
      first(),
    ).subscribe(() => {
      this.keyboardStickyElementHelper.makeSticky();
    });
    this.resizeHandler.observeViewport().pipe(
      takeUntil(this.blur.pipe(first()))
    ).subscribe(() => {
      const element: HTMLInputElement = this.elementRef.nativeElement;
      this.keyboardStickyElementHelper.unSticky();
      element.blur();
    });
  }

  @HostListener('blur')
  onBlur() {
    if (this.isDisabled() || !this.featureEnablement.featureFlagMethods.isEnableIosKeyboardUtils()) {
      return;
    }
    this.blur.next();
    this.nativeApi.keyboardDidHide$.pipe(
      first(),
      takeUntil(this.nativeApi.keyboardDidShow$.pipe(
        first(),
      ))
    ).subscribe(() => {
      this.keyboardStickyElementHelper.unSticky();
    });
  }

  constructor(
    private elementRef: ElementRef,
    private nativeApi: WenNativeApi,
    private breakpointObserver: WenBreakpointObserver,
    private resizeHandler: ResizeHandlerProvider,
    private keyboardStickyElementHelper: IosKeyboardStickyHelper,
    private featureEnablement: FeatureEnablementService
  ) {
  }

  private isDisabled() {
    const isDesktopStyle = this.breakpointObserver.isDesktopStyleDevice;
    const isNativeMobile = this.nativeApi.isReactNativeApp();
    const isIOS = this.nativeApi.isIOS();
    return isDesktopStyle || !(isNativeMobile && isIOS);
  }

  ngOnDestroy() {
    if (this.isDisabled()) {
      return;
    }
    this.keyboardStickyElementHelper.unSticky();
  }
}

@Directive({
  selector: '[wenIosKeyboardSticky], wenIosKeyboardSticky'
})
export class IosKeyboardStickyDirective implements OnInit, OnDestroy {

  /**
   * Add top offset otherwise the background can be seen through the sticky element...
   */
  private STICKY_TOP_OFFSET_FIX = 5;

  private onDestroy = new Subject<void>();
  private scrollSub = new Subscription();
  private placeholder: HTMLElement;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private keyboardStickyElementHelper: IosKeyboardStickyHelper,
  ) {
  }

  ngOnInit() {
    this.keyboardStickyElementHelper.sticky$.pipe(
      takeUntil(this.onDestroy),
      finalize(() => this.unSticky())
    ).subscribe((isSticky) => {
      if (isSticky) {
        this.makeSticky();
      } else {
        this.unSticky();
      }
    });
  }

  private makeSticky() {
    const el: HTMLElement = this.elementRef.nativeElement;
    const htmlEl = document.getElementsByTagName('html')[0];
    this.setPosition(el, htmlEl);
    this.addPlaceholder(el);
    this.scrollSub = fromEvent(document, 'scroll').pipe(
      takeUntil(this.onDestroy)
    ).subscribe(() => {
      this.renderer.setStyle(el, 'top', `${htmlEl.scrollTop - this.STICKY_TOP_OFFSET_FIX}px`);
    });
  }

  private unSticky() {
    this.scrollSub?.unsubscribe();
    this.resetPosition(this.elementRef.nativeElement);
    this.placeholder?.remove();
    this.placeholder = null;
  }


  private setPosition(stickyElement: HTMLElement, container: HTMLElement) {
    this.renderer.setStyle(stickyElement, 'width', `${stickyElement.clientWidth}px`);
    this.renderer.setStyle(stickyElement, 'position', 'fixed');
    this.renderer.setStyle(stickyElement, 'z-index', '999999');
    this.renderer.setStyle(stickyElement, 'top', `${container.scrollTop - this.STICKY_TOP_OFFSET_FIX}px`);
    this.renderer.setStyle(stickyElement, 'left', `0px`);
  }

  private resetPosition(stickyElement: HTMLElement) {
    this.renderer.setStyle(stickyElement, 'width', '');
    this.renderer.setStyle(stickyElement, 'position', '');
    this.renderer.setStyle(stickyElement, 'top', `0px`);
  }

  private addPlaceholder(stickyElement: HTMLElement): void {
    this.placeholder = this.renderer.createElement('div');

    this.renderer.setStyle(this.placeholder, 'position', 'relative');
    this.renderer.setStyle(this.placeholder, 'width', `${stickyElement.offsetWidth}px`);
    this.renderer.setStyle(this.placeholder, 'height', `${stickyElement.offsetHeight}px`);
    this.renderer.insertBefore(stickyElement.parentNode, this.placeholder, stickyElement);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
