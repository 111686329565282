import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { PollModel, PollOption } from '@portal/wen-backend-api';

const OVERALL_PERCENTAGE_VALUE = 100;

const averageCounter = (numOfCounts: number, overallCount: number) => Math.round(numOfCounts / overallCount * OVERALL_PERCENTAGE_VALUE);

interface PollOptionWithCalculations extends PollOption {
  averageVote: number;
}

@Component({
  selector: 'wen-read-only-poll',
  templateUrl: './read-only-poll.component.html',
  styleUrls: ['./read-only-poll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        clickAction: 'noop'
      } as MatCheckboxDefaultOptions
    },
  ]
})
export class ReadOnlyPollComponent {

  pollOptionsWithCalculations: PollOptionWithCalculations[] = [];
  isMultiSelect: boolean;

  @Input() set poll(pollModel: PollModel) {
    this.isMultiSelect = pollModel.multiSelect;
    this.pollOptionsWithCalculations = pollModel.options.map(option => ({
      ...option,
      averageVote: averageCounter(option.chooserCount, pollModel.choiceCount)
    }));
  }

}
