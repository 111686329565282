import { createAction, props } from '@ngrx/store';
import { ChannelMessageDTO, ChannelMessagesSummaryResponse } from '@portal/wen-backend-api';
import { NativeNotificationData, NativeNotificationRegistrationPayload } from '@portal/wen-native-api';
import { ChannelMessagesNotificationEntity, ChatMessagesNotificationEntity, ChatNotificationEventEntity } from './notification.state';

export const subscribeToUserChannelNotificationUpdates = createAction(
  '[Notification] Subscribe to channel notifications for the logged in user'
);

export const initChannelMessagesNotification = createAction(
  '[Notification] Init a new channel message notification entity',
  props<{ newEntity: ChannelMessagesNotificationEntity }>()
);

export const initChatMessagesNotifications = createAction(
  '[Notification] Init a new chat message notification entity',
  props<{ newEntity: Omit<ChatMessagesNotificationEntity, 'messageEvents'>; eventEntities: ChatNotificationEventEntity[] }>()
);

export const addChannelMessagesNotifications = createAction(
  '[Notification] Add new channel messages into the notification entity',
  props<{ channelId: string; messages: ChannelMessageDTO[] }>()
);

export const updateChannelMessagesNotifications = createAction(
  '[Notification] Update all channel notifications',
  props<{ summary: ChannelMessagesSummaryResponse }>()
);

export const upsertChatMessagesNotification = createAction(
  '[Notification] Upsert chat messages in the notification entity',
  props<{ eventEntity: ChatNotificationEventEntity }>()
);

export type ChatMessagesNotificationUpdate = Pick<ChatNotificationEventEntity, 'decryptedEvent' | 'decryptionError' | 'id'>;

export const updateChatMessagesNotifications = createAction(
  '[Notification] Update chat messages in the notification entity',
  props<{ roomId: string; eventEntities: ChatMessagesNotificationUpdate[] }>()
);

export const removeChannelMessagesNotification = createAction(
  '[Notification] Remove notification from a batch of channel message',
  props<{ messages: ChannelMessageDTO[] }>()
);

export const removeFromLatestMessages = createAction(
  '[Notification] Remove a message from the latest messages list',
  props<{ message: ChannelMessageDTO }>()
);

export const editLatestChannelMessage = createAction(
  '[Notification] Edit a message in the latest messages list of a channel',
  props<{ updatedMessage: ChannelMessageDTO }>()
);

export const updateChannelMessageNotifications = createAction(
  '[Notification] Clear all notifications for a given channel or based on timestamp and unread diff',
  props<{ channelId: string; unreadCountDiff?: number; lastAckTimestamp?: string }>()
);

export const handleNativeNotificationRegistration = createAction(
  '[NativeNotification] Handle registration',
  props<{ payload: NativeNotificationRegistrationPayload }>()
);

export const handleNewNativeNotification = createAction(
  '[NativeNotification] Handle new notification',
  props<{ notificationData: NativeNotificationData }>()
);

export const subscribeToMediaUploadNotification = createAction(
  '[Channel] Subscribe to media upload notification'
);

export const updateChatMessageNotificationsAcknowledgementState = createAction(
  `[Notification] Update chat message notification's acknowledge state`,
  props<{ roomId: string; lastAckTimestamp: string }>()
);

export const clearChatMessageNotifications = createAction(
  '[Notification] Clear the notification count for a given room',
  props<{ roomId: string }>()
);

export const removeChatMessagesNotification = createAction(
  '[Notification] Remove chat message notification',
  props<{ eventId: string; roomId: string; messageTimestamp: string }>()
);
