import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EmbedDTOType, FAQ, FaqEntry, FaqList, FaqType } from '@portal/wen-backend-api';
import { map, Observable, switchMap } from 'rxjs';
import { FAQ_TYPE_QUERY_PARAM } from '../../../../../core/services/navigation/query-params';
import { EmbeddedExpandable } from '../../../../../core/store/channel/channel.state';

@Component({
  selector: 'wen-faq-view',
  templateUrl: './faq-view.component.html',
  styleUrls: ['./faq-view.component.scss'],
})
export class FAQViewComponent implements OnInit {

  items$: Observable<EmbeddedExpandable[]>;

  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.items$ = this.activatedRoute.queryParams.pipe(
      switchMap(params => {
        const faqType: FaqType = params[FAQ_TYPE_QUERY_PARAM] ?? 'faq';
        return this.translateService.get(faqType).pipe(
          map((faq: FAQ) => this.faqsToEmbeds(faq.questions))
        );
      })
    );
  }

  private faqsToEmbeds(faqList: FaqList): EmbeddedExpandable[] {
    return Object.values(faqList).map((faqEntry: FaqEntry) => {
      const embed: EmbeddedExpandable = {
        type: EmbedDTOType.EXPANDABLE,
        title: faqEntry.question(),
        description: faqEntry.answer(),
      };
      return embed;
    });
  }
}
