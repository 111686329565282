import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, map, switchMap } from 'rxjs';
import { ChannelMediaPreviewHandler } from '../../../../../core/services/util/channel-media-preview-handler';
import { MediaPreviewHandler } from '../../../../../core/services/util/media-preview-handler';
import { selectDraftChannelMessageById } from '../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../../core/store/root/root.selectors';
import { FeedDatasource } from '../../../../../shared/components/feed/providers/feed-datasource';
import { FeedLayoutMediator } from '../../../../../shared/components/feed/providers/feed-layout-mediator';
import { FileDropOverlayComponent } from '../../../../../shared/components/file-drop-overlay/file-drop-overlay.component';
import { MessageSendFieldDataSource } from '../../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { FutureMessageSeparatorDateFormatter, MessageSeparatorDateFormatter } from '../../../../../shared/directives/directives/message-separator-formatters';
import { FileEventHandlerService } from '../../../../../shared/services/file-event-handler.service';
import { ChannelViewDataSource } from '../../../channel-view/components/channel-view/providers/channel-view-datasource';
import { ChannelMessageSendFieldDataSource } from '../../../common/providers/channel-message-send-field-data-source';
import { channelViewIdentifier } from '../../../tokens';
import { ChannelScheduledMessagesDatasource } from '../../providers/scheduled-messages.datasource';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { MessageBoxActionsHandler } from '../../../../../shared/components/message-box/providers/message-box-tokens';
import { ChannelMessageBoxActionsHandler } from '../../../common/message-box-actions/channel-message-box-actions-handler';

@Component({
  selector: 'wen-channel-schedules',
  templateUrl: './channel-schedules.component.html',
  styleUrls: ['./channel-schedules.component.scss'],
  providers: [
    ChannelViewDataSource,
    { provide: MessageSendFieldDataSource, useClass: ChannelMessageSendFieldDataSource },
    { provide: MediaPreviewHandler, useClass: ChannelMediaPreviewHandler },
    {
      provide: FeedDatasource,
      useClass: ChannelScheduledMessagesDatasource
    },
    FeedLayoutMediator,
    {
      provide: MessageBoxActionsHandler,
      useClass: ChannelMessageBoxActionsHandler
    },
    {
      provide: MessageSeparatorDateFormatter,
      useClass: FutureMessageSeparatorDateFormatter
    }
  ]
})
export class ChannelSchedulesComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  overlayContent = FileDropOverlayComponent;
  isMessageInputVisible$: Observable<boolean>;

  constructor(
    private store: Store<RootState>,
    private messageSendFieldDataSource: MessageSendFieldDataSource,
    private readonly fileEventHandler: FileEventHandlerService,
  ) { }

  ngOnInit() {
    this.isMessageInputVisible$ = this.store.pipe(
      select(selectRouteParam(channelViewIdentifier)),
      switchMap((channelId) => this.store.pipe(
        selectorWithParam(selectDraftChannelMessageById, channelId),
      )),
      map(message => Boolean(message?.id) && Boolean(message?.scheduled)),
      distinctUntilChanged(),
    );
  }

  onFilesDropped(files: FileList) {
    this.fileEventHandler.addFiles(files);
  }

  ngOnDestroy() {
    this.messageSendFieldDataSource.discardDraftMessage();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
