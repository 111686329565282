
import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';
import { ContextMenuStyleDefinition } from './context-menu.models';

export interface ContextMenuData<T = any> {
  component: ComponentType<any>;
  data: T;
  style?: ContextMenuStyleDefinition;
}

export const CONTEXT_MENU_DATA = new InjectionToken<ContextMenuData>('CONTEXT_MENU_DATA');
