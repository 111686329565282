import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCropDialogContent } from '@portal/ui-kit/image-upload/src/image-upload.component';
import { WenBreakpointObserver } from '@portal/wen-components';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

export interface WenImageCropDialogContent extends ImageCropDialogContent {
  aspectRatio: number;
}

@Component({
  selector: 'wen-image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.scss']
})
export class ImageCropDialogComponent {
  @ViewChild('imageCropper') imageCropper: ImageCropperComponent;

  croppedImage: any = '';
  imageLoaded$ = new BehaviorSubject<boolean>(false);
  cropperNotReady$ = new BehaviorSubject<boolean>(true);
  cropperVisible$ = new Observable<string | null>();

  headerGravity = this.breakpointObserver.isDesktopStyleDevice ? 'top' : 'bottom';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WenImageCropDialogContent,
    private dialogRef: MatDialogRef<ImageCropDialogComponent>,
    public breakpointObserver: WenBreakpointObserver,
  ) {
    this.dialogRef.afterOpened().pipe(
      first()
    ).subscribe(() => {
      this.dialogRef.addPanelClass('wen-image-crop-dialog-panel');
    });
    this.cropperVisible$ = this.imageLoaded$.pipe(
      map(isImageLoaded => isImageLoaded ? null : 'none')
    );
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }

  imageLoaded() {
    this.imageLoaded$.next(true);
  }

  cropperReady() {
    this.cropperNotReady$.next(false);
  }

  cropSelected() {
    if (!this.croppedImage) {
      return false;
    }
    this.dialogRef.close(this.croppedImage);
  }

  cropCancelled() {
    this.dialogRef.close();
  }

}
