import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { ContentSchema } from '@portal/wen-backend-api';
import { ProcessedGroupProps } from '../../models/model';
import { CONTENT_HANDLERS } from '../../schema-content-handlers';
import { toProcessedGroup } from '../../utils';

let groupIndexCounter = 0;
export const FORM_GROUP_PREFIX = 'form_group';

@Component({
  selector: 'wen-group',
  templateUrl: './group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class GroupComponent implements OnInit, OnDestroy {

  private contentHandlers = inject(CONTENT_HANDLERS, { optional: true });
  private rootFormGroup = inject(FormGroupDirective);
  private groupIndex: number;
  schemaFormGroup: FormGroup<any>;

  groupViewComponents: ProcessedGroupProps[];

  @HostBinding('class.wen-form-group') className = true;

  @Input() set context(contentSchemas: ContentSchema[]) {
    if (!Object.keys(this.contentHandlers).length) {
      throw new Error('There is no content handler provided');
    }
    this.groupViewComponents = contentSchemas.map(schema => {
      return toProcessedGroup(schema.type, this.contentHandlers, schema);
    });
  }

  constructor() {
    this.groupIndex = groupIndexCounter;
    groupIndexCounter++;
    this.schemaFormGroup = new FormGroup({});
  }

  ngOnInit() {
    this.registerFormGroupToParent();
  }

  private registerFormGroupToParent() {
    const formGroupName = `${FORM_GROUP_PREFIX}_${this.groupIndex}`;
    if (!this.rootFormGroup.form.get(formGroupName)) {
      this.rootFormGroup.form.addControl(formGroupName, this.schemaFormGroup);
    }
  }

  ngOnDestroy() {
    this.groupViewComponents = null;
    groupIndexCounter = 0;
  }
}
