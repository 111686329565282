import { Action } from '@ngrx/store';
import { Observable, OperatorFunction, filter, map, merge, of, switchMap } from 'rxjs';
import { MassDataAction, MassDataActionsFactory, MassDataOperation } from '../mass-data.actions';
import { MassDataLoadingProgress, MassDataType } from '../types';

export const ofMassDataOpTypeAction = <T extends MassDataAction>(op: MassDataOperation): OperatorFunction<MassDataAction, T> => {
  return filter((action): action is T => {
    return action?.payload?.massDataOperation === op;
  });
};

export const ofMassDataOpType = <T extends MassDataAction>(op: MassDataOperation): OperatorFunction<MassDataAction, T['payload']> => {
  return (source: Observable<any>) => {
    return source.pipe(
      ofMassDataOpTypeAction(op),
      map((action) => action.payload)
    );
  };
};

export const withLoadingProgress = (
  massDataType: MassDataType, source$: Observable<Action>, actionFactory: MassDataActionsFactory
) => {
  const loadingProgressAction = actionFactory.createAction({
    massDataType,
    massDataOperation: MassDataOperation.STORE_SET_LOADING_PROGRESS,
    massDataActionData: MassDataLoadingProgress.IN_PROGRESS
  });
  const done$ = source$.pipe(
    switchMap(sourceAction => {
      const loadingDoneAction = actionFactory.createAction({
        massDataType,
        massDataOperation: MassDataOperation.STORE_SET_LOADING_PROGRESS,
        massDataActionData: MassDataLoadingProgress.IDLE
      });
      return [sourceAction, loadingDoneAction];
    })
  );
  return merge(of(loadingProgressAction), done$);
};
