<div id="lightbox-header">
  <div class="lightbox-button" wenTouchEndListener (tap)="close()">
    <wen-icon iconName="chevron_left"></wen-icon>
    <span>{{ "BUTTON_BACK_LABEL" | translate }}</span>
  </div>
  @if (isFileDownloadEnabled) {
    <div
      class="lightbox-button"
      [style.visibility]="(isDownloading | async) ? 'hidden' : 'visible'"
      (tap)="download()"
      >
      <wen-icon iconName="download_inactive"></wen-icon>
    </div>
  }
</div>
