import { Injectable } from '@angular/core';
import { ActionMenuItem, WenSnackbarOpener } from '@portal/wen-components';
import { of } from 'rxjs';
import { ChatListItemModel } from '../chat-list-item.component';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../../../core/store/root/public-api';
import { TranslateService } from '@ngx-translate/core';
import { setMuteForRoom } from '../../../../../../core/store/chat/chat.actions';

@Injectable()
export class ChatMuteAction implements ActionMenuItem {

    private isMuted: boolean;

    label: string;
    icon: string;

    execute(chatListItemModel: ChatListItemModel): void {
        this.store.dispatch(setMuteForRoom({ mute: !chatListItemModel.isMuted, room: chatListItemModel.id }));
        this.showSnackbarMessage();
    }
    isVisible$ = (chatListItemModel: ChatListItemModel) => {
        this.isMuted = chatListItemModel.isMuted;
        this.icon = this.isMuted ? 'unmute' : 'mute';
        this.label = this.translateService.instant(this.isMuted ? 'UNMUTE_BUTTON_LABEL' : 'MUTE_BUTTON_LABEL');
        return of(true);
    };

    constructor(
        private store: Store<RootState>,
        private translateService: TranslateService,
        private wenSnackbarOpener: WenSnackbarOpener
    ) { }

    private showSnackbarMessage() {
        const notificationText = this.translateService.instant(this.isMuted ? 'UNMUTE_BUTTON_LABEL' : 'MUTE_BUTTON_LABEL');
        const notificationIcon = this.isMuted ? 'unmute' : 'mute';
        this.wenSnackbarOpener.openNotificationSnackbar({ notificationIcon, notificationText });
    }
}
