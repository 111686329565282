import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, ReplaySubject, Observable, first } from 'rxjs';
import { UserProfileResponse, WenLanguage } from '@portal/wen-backend-api';
import { SelectionOption } from '@portal/wen-components';
import { DataObjectType } from '../../../../../core/store/common/data-objects';
import { FormValueConverter, NoopFormValueConverter } from '../../../../../shared/form-store/form-store.providers';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { UserProfileService } from '../../user-profile.service';
import { UserLanguageService } from './providers/user-language.service';

@Component({
  selector: 'wen-user-language-setting',
  templateUrl: './user-language-setting.component.html',
  styleUrls: [],
  providers: [
    UserLanguageService,
    UserProfileService,
    { provide: FormValueConverter, useClass: NoopFormValueConverter },
  ],
})
export class UserLanguageSettingComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  private initialSelection$ = new ReplaySubject<WenLanguage>(1);

  @HostBinding('class.custom-form-group') className = true;

  currentUser$: Observable<UserProfileResponse> = this.userProfileService.currentUser$;

  selectionLanguageItems: SelectionOption<WenLanguage>[];
  selectionLanguageModel: SelectionModel<SelectionOption<WenLanguage>>;

  userLanguageForm = new FormGroup({
    primaryLanguage: new FormControl(''),
  });

  constructor(
    private formStoreService: FormStoreService,
    private userLanguageService: UserLanguageService,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit() {
    this.formStoreService.clearFormData();
    this.currentUser$.pipe(first()).subscribe((user) => {
      const languageSettings = this.userLanguageService.getLanguageSettings(user);
      this.selectionLanguageItems = this.userLanguageService.getLanguageSettingItems();
      this.formStoreService.initializeForm(languageSettings);
      this.setupLanguage(languageSettings);
    });
  }

  private setupLanguage(languageSettings: any) {
    this.selectionLanguageItems = this.userLanguageService.getLanguageSettingItems();
    this.setupInitialSelection(languageSettings);
  }

  private setupInitialSelection(languageSettings: any) {
    const initialSelection = languageSettings.primaryLanguage;
    this.initialSelection$.next(initialSelection);
    this.selectionLanguageModel = new SelectionModel(
      false,
      this.selectionLanguageItems.filter(item => item.id === initialSelection)
    );
  }

  onSelectionChange(changedValue: WenLanguage) {
    this.userLanguageForm.controls.primaryLanguage.markAsDirty();
    this.userLanguageForm.controls.primaryLanguage.setValue(changedValue);
  }

  onSelectionChanged(data: WenLanguage[]) {
    this.onSelectionChange(data[0]);
  }

  saveSettings() {
    this.formStoreService.saveForm(DataObjectType.LANGUAGE);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
