import { EventDTO, EventListItemDTO } from '@portal/wen-backend-api';
import { DateUtil } from '../../../common/date/date-util';
import { EventEntity, OccurenceEntity, SubscriptionEntity, WithDateRange, WithPriority } from '../event.state';

export const extractEntitiesFromEventList = (eventList: EventListItemDTO[] | EventDTO[]) => {
  return eventList.map(ev => extractEntitiesFromEvent(ev));
};

export const extractEntitiesFromEvent = (
  {id, subscribedByUser, subscriptionCount, eventId, ...rest}: OccurenceEntity
): {event: Partial<EventEntity>; subscription: SubscriptionEntity} => {
  return {
    event: { id: eventId ?? id, ...rest},
    subscription: {
      id: eventId ?? id,
      subscribedByUser,
      subscriptionCount
    }
  };
};

export const sortEvents = <T extends WithDateRange & WithPriority>(event1: T, event2: T) => {
  const start1 = DateUtil.fromIsoString(event1.start);
  const start2 = DateUtil.fromIsoString(event2.start);

  if (!DateUtil.isInSameDay(start1, start2) || event1.priority === event2.priority) {
    return start1.toMillis() - start2.toMillis();
  } else {
    return event2.priority - event1.priority;
  }
};
