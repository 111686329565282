export class DelayedOperationScheduler {

  private scheduledOperation: () => void;
  private isReady = false;

  setReady() {
    this.isReady = true;
  }

  scheduleOrRun(operation: () => void) {
    if (this.isReady) {
      operation();
      return;
    }
    this.scheduledOperation = operation;
  }

  flush() {
    if (this.scheduledOperation && this.isReady) {
      this.scheduledOperation();
      this.scheduledOperation = null;
    }
  }

}
