import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: 'wen-list-mass-data-item, [wen-list-mass-data-item]'
})
export class ListMassDataViewerItemTemplateDirective {

  constructor(
    public templateRef: TemplateRef<any>
  ) { }

}

@Directive({
  selector: 'wen-list-mass-data-empty, [wen-list-mass-data-empty]'
})
export class ListMassDataViewerEmptyTemplateDirective {

  constructor(
    public templateRef: TemplateRef<any>
  ) { }

}
