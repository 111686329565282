<div class="wen-app-banner-content">
  <wen-icon class="app-banner-close-button" [iconName]="'close'" [color]="'white'" (click)="emitClosed()"></wen-icon>
  <div class="wen-app-banner-info">
    <img class="wen-app-banner-logo" [src]="'/assets/wen-widget/image/we-logo.png'">
    <div class="wen-app-banner-info-text">
      <h2>{{ "APP_BANNER_INFO_HEADING" | translate }}</h2>
      <h3>{{ "APP_BANNER_INFO_SUBHEADING" | translate }}</h3>
    </div>
  </div>
  @if (smartStoreButtons) {
    <div class="wen-app-banner-buttons-wrapper">
      <wen-interact-button class="wen-app-banner-to-app-button wen-interact-button-round" (click)="goToApp()">
        <h2>{{ "APP_BANNER_TO_APP" | translate }}</h2>
      </wen-interact-button>
    </div>
  } @else {
    <div class="wen-app-banner-buttons-wrapper">
      @if (showPlayStoreButton) {
        <wen-interact-button class="wen-app-banner-to-app-button wen-interact-button-round" (click)="goToPlayStore()">
          <h2>{{ "APP_BANNER_TO_APP_ANDROID" | translate }}</h2>
        </wen-interact-button>
      }
      @if (showAppStoreButton) {
        <wen-interact-button class="wen-app-banner-to-app-button wen-interact-button-round" (click)="goToAppStore()">
          <h2>{{ "APP_BANNER_TO_APP_IOS" | translate }}</h2>
        </wen-interact-button>
      }
    </div>
  }
</div>