import { PhoneAreaInfo, PhoneNumberValue } from '../../../common/types/form-types';

export const availableCountries: PhoneAreaInfo[] = [
  {
    name: 'Deutschland',
    areaCode: '+49',
    countrySign: 'DE'
  },
  {
    name: 'Österreich',
    areaCode: '+43',
    countrySign: 'AT'
  },
  {
    name: 'Schweiz',
    areaCode: '+41',
    countrySign: 'CH'
  },
  {
    name: 'Belgien',
    countrySign: 'BE',
    areaCode: '+32'
  },
  {
    name: 'Bulgarien',
    countrySign: 'BG',
    areaCode: '+359'
  },
  {
    name: 'Kroatien',
    countrySign: 'HR',
    areaCode: '+385'
  },
  {
    name: 'Zypern',
    countrySign: 'CY',
    areaCode: '+357'
  },
  {
    name: 'Tschechien',
    countrySign: 'CZ',
    areaCode: '+420'
  },
  {
    name: 'Dänemark',
    countrySign: 'DK',
    areaCode: '+45'
  },
  {
    name: 'Estland',
    countrySign: 'EE',
    areaCode: '+372'
  },
  {
    name: 'Finnland',
    countrySign: 'FI',
    areaCode: '+358'
  },
  {
    name: 'Frankreich',
    countrySign: 'FR',
    areaCode: '+33'
  },
  {
    name: 'Griechenland',
    countrySign: 'GR',
    areaCode: '+30'
  },
  {
    name: 'Ungarn',
    countrySign: 'HU',
    areaCode: '+36'
  },
  {
    name: 'Irland',
    countrySign: 'IE',
    areaCode: '+353'
  },
  {
    name: 'Italien',
    countrySign: 'IT',
    areaCode: '+39'
  },
  {
    name: 'Lettland',
    countrySign: 'LV',
    areaCode: '+371'
  },
  {
    name: 'Litauen',
    countrySign: 'LT',
    areaCode: '+370'
  },
  {
    name: 'Luxemburg',
    countrySign: 'LU',
    areaCode: '+352'
  },
  {
    name: 'Malta',
    countrySign: 'MT',
    areaCode: '+356'
  },
  {
    name: 'Niederlande',
    countrySign: 'NL',
    areaCode: '+31'
  },
  {
    name: 'Norwegen',
    countrySign: 'NO',
    areaCode: '+47'
  },
  {
    name: 'Polen',
    countrySign: 'PL',
    areaCode: '+48'
  },
  {
    name: 'Portugal',
    countrySign: 'PT',
    areaCode: '+351'
  },
  {
    name: 'Rumänien',
    countrySign: 'RO',
    areaCode: '+40'
  },
  {
    name: 'Slowakei',
    countrySign: 'SK',
    areaCode: '+421'
  },
  {
    name: 'Slowenien',
    countrySign: 'SI',
    areaCode: '+386'
  },
  {
    name: 'Serbien',
    countrySign: 'RS',
    areaCode: '+381'
  },
  {
    name: 'Spanien',
    countrySign: 'ES',
    areaCode: '+34'
  },
  {
    name: 'Schweden',
    countrySign: 'SE',
    areaCode: '+46'
  },
  {
    name: 'Türkei',
    countrySign: 'TR',
    areaCode: '+90'
  },
  {
    name: 'Ukraine',
    countrySign: 'UA',
    areaCode: '+380'
  },
  {
    name: 'Vereinigtes Königreich',
    countrySign: 'GB',
    areaCode: '+44'
  }
];

export const initialPhoneValue: PhoneNumberValue = {
  phoneNumber: '',
  areaInfo: availableCountries[0]
};
