import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { WenSnackbarOpener } from '@portal/wen-components';

@Injectable()
export class ContactResponseActionsHandler {

  private socketIoService = inject(SocketIoService);
  private snackbarOpener = inject(WenSnackbarOpener);
  private translateService = inject(TranslateService);

  sendContactRequest(userId: string) {
    this.snackbarOpener.openNotificationSnackbar({
      notificationText: this.translateService.instant('CONTACTS_TOAST_REQUEST_SENT'),
      notificationIcon: 'success'
    });
    this.socketIoService.contact.createRequest.acknowledgement$({
      contactUserId: userId
    });
  }

  withdrawSentContactRequest(userId: string) {
    this.snackbarOpener.openNotificationSnackbar({
      notificationText: this.translateService.instant('CONTACTS_TOAST_REQUEST_WITHDRAWN'),
      notificationIcon: 'success'
    });
    this.socketIoService.contact.withdrawSentRequest.acknowledgement$({
      contactUserId: userId
    });
  }

  handleAcceptReceivedContactRequest(userId: string) {
    this.snackbarOpener.openNotificationSnackbar({
      notificationText: this.translateService.instant('CONTACTS_TOAST_REQUEST_ACCEPTED_RECEIVED'),
      notificationIcon: 'success'
    });
    this.socketIoService.contact.acceptReceivedRequest.acknowledgement$({
      contactUserId: userId
    });
  }

  handleDenyReceivedContactRequest(userId: string) {
    this.snackbarOpener.openNotificationSnackbar({
      notificationText: this.translateService.instant('CONTACTS_TOAST_REQUEST_DENY_RECEIVED'),
      notificationIcon: 'success'
    });
    this.socketIoService.contact.denyReceivedRequest.acknowledgement$({
      contactUserId: userId
    });
  }

  deleteContact(userId: string) {
    this.snackbarOpener.openNotificationSnackbar({
      notificationText: this.translateService.instant('CONTACTS_TOAST_REQUEST_DELETED'),
      notificationIcon: 'success'
    });
    this.socketIoService.contact.deleteExisting.acknowledgement$({
      contactUserId: userId
    });
  }

}
