import type { SmartDesign } from '@smartdesign/web-api';
import { SelectionProvider } from '../selection-provider';
import { GWDataObject, gwDataObjectFactory, GW_RELEVANT_FIELDS } from './gw-data-object';

export class GWSelectionProvider extends SelectionProvider<GWDataObject> {

  protected relevantFields = GW_RELEVANT_FIELDS;

  constructor(protected api: SmartDesign.IAPI<any>) {
    super(gwDataObjectFactory);
  }

}
