import { Injectable, inject } from '@angular/core';
import { NotificationContextType, TriggerUser } from '@portal/wen-backend-api';
import { AppNavigator } from '../../../../../../../core/services/navigation/app-navigator';
import { WenNavigationHelper } from '../../../../../../../core/services/navigation/types';
import { WenRouteId } from '../../../../../../../frame/routing/types';
import { userProfileIdentifier } from '../../../../../../../views/user-profile/tokens';

@Injectable()
export class NotificationNavigator {

  private appNavigator = inject(AppNavigator);
  private navigationHelper = inject(WenNavigationHelper);

  navigateBy(contextType: NotificationContextType, contextId: string, triggerUser: TriggerUser) {
    switch (contextType) {
      case NotificationContextType.CHANNEL_MESSAGE: {
        return this.navigationHelper.navigateToChannelMessageDetail(contextId);
      }
      case NotificationContextType.CHANNEL: {
        return this.navigationHelper.navigateToPendingJoinRequestsPage(contextId);
      }
      case NotificationContextType.CONTACT: {
        return this.appNavigator.navigateToRoute(WenRouteId.OTHER_USERPROFILE_READ, { [userProfileIdentifier]: triggerUser.userId });
      }
      default:
        return;
    }
  }
}
