import type { SmartDesign } from '@smartdesign/web-api';

export enum CRMBackendType {
  GW = 'gw',
  OPEN = 'open',
}

const GWOnlyFieldName = 'GWSALESACTIVITYTYPEALLOWED';

const evaluateCrmBackendType = async (sdApi: SmartDesign.IAPI<any>) => {
  try {
    const primaryDataContextItem = await sdApi.Context.getPrimaryDataObject();
    if (primaryDataContextItem) {
      const doMetaData = await primaryDataContextItem.getMetadata();
      if (doMetaData[GWOnlyFieldName]) {
        return CRMBackendType.GW;
      }
      return CRMBackendType.OPEN;
    }
    const primaryMassDataContextItem = await sdApi.Context.getPrimaryMassData();
    if (primaryMassDataContextItem) {
      const massDataMetaData = await primaryMassDataContextItem.getMetadata();
      if (massDataMetaData[GWOnlyFieldName]) {
        return CRMBackendType.GW;
      }
      return CRMBackendType.OPEN;
    }
  } catch {
    return null;
  }
};

export const crmBackendUtil = {
  evaluateCrmBackendType
};
