import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { firstTrue } from '@portal/wen-common';
import { ChannelViewPreloaderGuard } from '../../views/channel/common/providers/channel-view-preloader.guard';

export const channelDetailsLoadedGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const channelViewPreloaderGuard = inject(ChannelViewPreloaderGuard);
  channelViewPreloaderGuard.ensureCurrentChannelDetailsLoaded();
  return channelViewPreloaderGuard.isChannelDetailsLoaded$.pipe(
    firstTrue()
  );
};
