import { ChangeDetectionStrategy, Component, HostListener, Inject, Optional, AfterContentInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fadeInOutAnimation } from '../../animations/fade-in-out-animation';
import { DownloaderService } from '../../services/download/downloader.service';
import { LightboxDialogData, LightboxImageData } from './models/lightbox.models';
import { OverlayVisibilityService } from './providers/overlay-visibility.service';

@Component({
  selector: 'wen-lightbox-dialog',
  templateUrl: './lightbox-dialog.component.html',
  styleUrls: ['./lightbox-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OverlayVisibilityService],
  animations: [fadeInOutAnimation('150ms', 'ease-in', '150ms', '150ms', 0.8, 0)],
})
export class LightboxDialogComponent implements AfterContentInit {

  headerVisible$: Observable<boolean> = this.overlayVisibilityService.isVisible$;
  images: Array<LightboxImageData>;
  isFileDownloadEnabled: boolean;
  currentSlide: number;
  isDownloading$ = new Observable<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: LightboxDialogData,
    private dialogRef: MatDialogRef<LightboxDialogComponent>,
    @Optional() private downloadService: DownloaderService,
    private overlayVisibilityService: OverlayVisibilityService
  ) {
    this.currentSlide = 0;
    this.images = this.dialogData.images;
    this.isFileDownloadEnabled =
      this.dialogData.isFileDownloadEnabled &&
      this.downloadService?.canDownload(this.images[this.currentSlide]);
    this.isDownloading$ = this.downloadService?.ongoingDownloads$.pipe(
      map(ongoingDownloads =>
        ongoingDownloads.has(this.images[this.currentSlide].src.toString())
      )
    );
  }

  ngAfterContentInit(): void {
    this.overlayVisibilityService.init();
  }

  onDownload(): void {
    if (this.downloadService) {
      const currentMediaElement = this.images[this.currentSlide];
      this.downloadService.downloadInBackground(
        currentMediaElement?.src.toString(),
        currentMediaElement?.mimeType,
        currentMediaElement.fileType,
        currentMediaElement.fileName
      );
    }
  }

  close(): void {
    this.currentSlide = 0;
    this.images = [];
    this.dialogRef.close();
  }

  @HostListener('document:keyup.escape')
  onEscape() {
    this.close();
  }

}
