import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { EMPTY, Observable, ReplaySubject, combineLatest, distinctUntilChanged, first, map, of, shareReplay, startWith, switchMap } from 'rxjs';
import { addDraftChatMember, addDraftChatRoom, removeDraftChatMember, removeDraftChatRoom } from '../../../../../../../core/store/chat/chat.actions';
import { selectAllDraftChatMembers, selectAllDraftChatRooms } from '../../../../../../../core/store/chat/chat.selectors';
import { ChatMemberEditSearchEntityType } from '../../../../../../../core/store/chat/mass-data/providers/chat-member-edit-state-types';
import { EditChatSearchFilterService } from '../../../../../../../core/store/chat/mass-data/providers/edit-chat-search-filter-service';
import { RootState } from '../../../../../../../core/store/root/public-api';
import { InputWithChipModel } from '../../../../../../../shared/components/input-with-chips/input-with-chips.component';
import { maxNumberOfUsersForMassMessageSending } from '../../../../../constants';
import { ChatEditCheckAvailabilityService } from '../../../../providers/chat-edit-check-availability-service';
import { ChatSelectionListItemModel, ChatSelectionListSelectionProvider } from '../../../chat-selection-list/providers/chat-selection-list-providers';

@Injectable()
export class ChatCreationListSelectionService extends ChatSelectionListSelectionProvider {

  public maxSelectionCount = maxNumberOfUsersForMassMessageSending;

  private store = inject(Store<RootState>);
  private chatAvailabilityChecker = inject(ChatEditCheckAvailabilityService);
  private filterService = inject(EditChatSearchFilterService);

  public selectedIds$: Observable<string[]>;
  public selectedChips$: Observable<InputWithChipModel[]>;
  public selectedChipsCount$: Observable<number>;

  public selectionModeEnabled$: Observable<boolean>;

  constructor() {
    super();
    const draftData$ = combineLatest([
      this.store.pipe(select(selectAllDraftChatRooms)),
      this.store.pipe(select(selectAllDraftChatMembers)),
    ]).pipe(shareReplay(1));

    this.selectedIds$ = draftData$.pipe(
      map(([rooms, users]) => {
        const roomIds = rooms.map(room => room.id);
        const userIds = users.map(user => user.id);
        return [...roomIds, ...userIds];
      }),
      smartDistinctUntilChanged()
    );
    this.selectedChips$ = draftData$.pipe(
      map(([rooms, users]) => {
        return [
          ...rooms.map(room => ({ id: room.id, icon: room.avatarUrl, text: room.title })),
          ...users.map(user => ({ id: user.id, icon: user.avatarUrl, text: user.title })),
        ];
      }),
      smartDistinctUntilChanged()
    );
    this.selectedChipsCount$ = this.selectedChips$.pipe(
      map((chips) => chips?.length || 0)
    );
    this.selectionModeEnabled$ = this.filterService.searchTerm$.pipe(
      distinctUntilChanged(),
      map(searchTerm => Boolean(!searchTerm || searchTerm.length < 1)),
      startWith(true),
      shareReplay(1)
    );
  }

  toggleSelection(item: ChatSelectionListItemModel) {
    const result$ = new ReplaySubject(1);
    this.selectedIds$.pipe(
      first(),
      switchMap((selectedIds) => {
        const { listItemEntity: { id } } = item;
        const isSelected = selectedIds.includes(id);
        const shouldCheckKeys = item.entityType !== ChatMemberEditSearchEntityType.GROUP_CHATROOM;
        if (!isSelected) {
          if (selectedIds.length >= this.maxSelectionCount) {
            return EMPTY;
          } else if (shouldCheckKeys) {
            return this.chatAvailabilityChecker.validateSelection(item).pipe(
              switchMap((unavailableKeys) => {
                if (unavailableKeys.includes(id)) {
                  result$.error(new Error());
                  result$.complete();
                  return EMPTY;
                }
                return of(unavailableKeys);
              })
            );
          }
        }
        return of(selectedIds);
      }),
    ).subscribe((selectedIds) => {
      const { entityType, listItemEntity } = item;
      const { id } = listItemEntity;
      const isSelected = selectedIds.includes(id);
      if (isSelected) {
        this.removeSelection(id);
      } else {
        switch (entityType) {
          case ChatMemberEditSearchEntityType.EXISTING_CONTACT:
          case ChatMemberEditSearchEntityType.FURTHER_USER_RESULT:
            this.store.dispatch(addDraftChatMember({ user: listItemEntity }));
            break;
          case ChatMemberEditSearchEntityType.GROUP_CHATROOM:
            this.store.dispatch(addDraftChatRoom({ room: listItemEntity }));
        }
      }
      result$.next(null);
      result$.complete();
    });
    return result$.asObservable();
  }

  removeSelection(id: string) {
    this.store.dispatch(removeDraftChatMember({ userId: id }));
    this.store.dispatch(removeDraftChatRoom({ roomId: id }));
  }

}
