import { Injectable } from '@angular/core';
import * as Olm from '@matrix-org/olm';
import { OlmEncryptedMessage } from '@portal/wen-backend-api';
import { first, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatBackendApi } from './backend-api/backend-api';
import { MegOlmDecryptionResult, MegOlmEncryptionResult, OlmDecryptionResult, OlmEncryptionResult } from './decryption/crypto-results';
import { MegOlmDecryptor } from './decryption/megolm-decryptor';
import { MegOlmEncryptor } from './decryption/megolm-encryptor';
import { OlmDecryptor } from './decryption/olm-decryptor';
import { OlmEncryptor } from './decryption/olm-encryptor';
import { OlmDevice } from './device/olm-device';
import { OneTimeKeyService } from './key-initializers/one-time-key-service';
import { CryptoStorage, GetInboundGroupSessionParams } from './persistence/crypto-storage';
import { DeviceForUsersResult, InboundGroupSessionExportResult, OutboundGroupSession } from './types';

@Injectable()
export class WenChatClient {

  constructor(
    private olmEncryptor: OlmEncryptor,
    private megolmEncryptor: MegOlmEncryptor,
    private megOlmDecryptor: MegOlmDecryptor,
    private olmDecryptor: OlmDecryptor,
    private dataStore: CryptoStorage,
    private oneTimeKeyService: OneTimeKeyService,
    private chatBackendApi: ChatBackendApi,
    private olmDevice: OlmDevice,
  ) { }

  init() {
    return from(Olm.init()).pipe(first());
  }

  clearData() {
    return this.dataStore.clearDb();
  }

  createInboundGroupSession(roomId: string, senderCurve25519: string, sessionKey: string) {
    return this.megOlmDecryptor.createInboundGroupSession(roomId, senderCurve25519, sessionKey);
  }

  importInboundGroupSession(roomId: string, sessionId: string, senderCurve25519: string, sessionExport: string) {
    const session = this.olmDevice.importInboundGroupSession(sessionExport);
    return this.dataStore.storeInboundGroupSession({
      roomId, senderCurve25519, session, sessionId
    });
  }

  ensureOutboundGroupSession(roomId: string): OutboundGroupSession {
    return this.megolmEncryptor.ensureOutboundGroupSession(roomId);
  }

  getDevices(userIds: string[]) {
    return this.chatBackendApi.getDeviceKeys({ userIds }).pipe(
      map((downloadedDevices) => {
        const allDeviceIds = downloadedDevices.deviceKeys.map((deviceKey) => {
          const userDevice: DeviceForUsersResult = {
            userId: deviceKey.userId,
            deviceId: deviceKey.deviceId
          };
          return userDevice;
        });
        return allDeviceIds;
      })
    );
  }

  exportInboundGroupSessions(params: GetInboundGroupSessionParams[]): Observable<InboundGroupSessionExportResult[]> {
    return this.dataStore.getInboundGroupSessions(params).pipe(
      map((sessionModels) => {
        const results = sessionModels.map(sessionModel => {
          const { sessionId, roomId, senderCurve25519, session } = sessionModel;
          const result: InboundGroupSessionExportResult = {
            sessionId, senderCurve25519, roomId,
            sessionExport: this.olmDevice.getInboundGroupSessionExport(session)
          };
          return result;
        });
        return results;
      })
    );
  }

  /**
   * Make sure the one-time-keys are not falling below a specific threshold on the backend
   *
   * @param currentOneTimeKeyCount The current count of one-time-keys for this account on the backend
   */
  ensureOneTimeKeys(currentOneTimeKeyCount: number) {
    return this.oneTimeKeyService.ensureOneTimeKeys(currentOneTimeKeyCount);
  }

  /**
   * Encrypts a message with OLM for a given user's all known devices
   *
   * @param userId Target userId
   * @param content The encryptable message
   * @param targetDeviceId The target device id (if not present the message is encrypted for the user's all known devices)
   * @returns An array of OLM encrypted messages
   */
  encryptMessageOlm(userId: string | string[], content: string, targetDeviceId?: string): Observable<OlmEncryptionResult[]> {
    return this.olmEncryptor.encryptMessage(userId, content, targetDeviceId);
  }

  /**
   * Decrypts a message with OLM for a given user's device
   *
   * @param userId Target userId
   * @param senderCurve25519 The device key of the sender user
   * @param content The decryptable message
   * @returns The decrypted message
   */
  decryptMessageOlm(userId: string, senderCurve25519: string, content: OlmEncryptedMessage): Observable<OlmDecryptionResult> {
    return this.olmDecryptor.decryptMessage(userId, senderCurve25519, content);
  }

  /**
   * Encrypts a message with MegOLM in a given room
   *
   * @param roomId Target userId
   * @param content The encryptable message
   * @returns The encrypted message
   */
  encryptGroupMessage(roomId: string, content: string): Observable<MegOlmEncryptionResult> {
    return this.megolmEncryptor.encryptMessage(roomId, content);
  }

  /**
   * Decrypts a message with MegOLM for a given user's device
   *
   * @param sessionId Target MegOlm sessionId
   * @param senderCurve25519 The device key of the sender user
   * @param content The decryptable message
   * @returns The decrypted message
   */
  decryptGroupMessage(sessionId: string, senderCurve25519: string, content: OlmEncryptedMessage): Observable<MegOlmDecryptionResult> {
    return this.megOlmDecryptor.decryptMessage(sessionId, senderCurve25519, content.body);
  }

}
