
export interface ShareKeyHint {
  eventId: string;
  insertTimestamp: string;
  description: string;
  processedTimestamp?: string;
}

export const convertHintsToObject = (hints: ShareKeyHint[]) => {
  if (!hints?.length) {
    return {};
  }
  return hints.reduce((acc, curr) => {
    const eventId = curr.eventId;
    return {
      ...acc,
      [eventId]: curr
    };
  }, {});
};
