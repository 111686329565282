import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { NetworkDTO } from '@portal/wen-backend-api';

export type NetworkEntity = NetworkDTO;

export interface NetworkState {
  activeNetworkId: string;
  knownNetworks: EntityState<NetworkEntity>;
}

export const networkEntityAdapter = createEntityAdapter<NetworkEntity>({
  selectId: (entity) => entity.id
});
