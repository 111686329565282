import deepMergeLib from 'ts-deepmerge';

const removeEmpty = (obj: Record<string, any>) => {
  return Object.keys(obj)
    .filter((k) => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
};

export const deepMerge: typeof deepMergeLib = (deepMergeLib as any).default ? (deepMergeLib as any).default : deepMergeLib;

export const mergeIgnoreNullish = (
  val1: Record<string, any>, val2: Record<string, any>
) => {
  if (!val1 || !val2) {
    return null;
  }
  return {
    ...removeEmpty(val1),
    ...removeEmpty(val2)
  };
};
