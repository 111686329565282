import { Injectable } from '@angular/core';
import { MessageEventResponses, MessageEvent } from '@portal/wen-backend-api';
import { isNullOrUndefined } from '../../../common/operators/null-check-util';
import { isEditEvent, isRealtimeEditEvent, isRedactEvent, isValidSendEvent } from '../message-event/message-event-helper';
import { asEncryptedEditEvent } from '../message-event/modifiers/edit';
import { asEncryptedRedactEvent } from '../message-event/modifiers/redact';

@Injectable()
export class EncryptedEventModifier {

  /**
   * @param events The message events from a given source
   * @returns The event list which should be decrypted in order to be handled afterwards.
   * By default it is the SEND_MESSAGE event's content
   */
  transformEncryptedEvents(events: MessageEvent<MessageEventResponses>[]): MessageEvent<MessageEventResponses>[] {
    if (isNullOrUndefined(events) || events?.length <= 0) {
      return [];
    }
    return events.map(event => {
      if (isEditEvent(event) || isRealtimeEditEvent(event)) {
        return asEncryptedEditEvent(event);
      }
      if (isRedactEvent(event)) {
        return asEncryptedRedactEvent(event);
      }
      if (isValidSendEvent(event)) {
        return event;
      }
      return null;
    }).filter(event => !isNullOrUndefined(event));
  }
}
