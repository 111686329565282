import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WenIconModule } from '@portal/wen-components';
import { MessageStatusIndicatorComponent } from './components/message-status-indicator.component';
import { CalculateMessageStatusPipe } from './pipes/calculate-message-status.pipe';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule
  ],
  declarations: [
    MessageStatusIndicatorComponent,
    CalculateMessageStatusPipe
  ],
  exports: [
    MessageStatusIndicatorComponent,
    CalculateMessageStatusPipe
  ]
})
export class MessageStatusIndicatorModule { }
