import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChatBackendApi, ChatUserApi, MessageSendTransactionModule, WenChatClientModule } from '@portal/wen-chat-client';
import { ChatAvailabilityCheckerWithOverlay } from '../../core/services/chat/chat-availability-checker-with-overlay';
import { ChatMessageEmbedResolver } from '../../core/services/chat/chat-message-embed-resolver';
import { ExchangeSessionKeysDecryptor } from '../../core/services/chat/decryption/exchange-keys-decryptor';
import { FailedDecryptionHandler } from '../../core/services/chat/decryption/failed-decryption-handler';
import { MessageDecryptor } from '../../core/services/chat/decryption/message-decryptor';
import { ScheduledMessageDecryptor } from '../../core/services/chat/decryption/scheduled-message-decryptor';
import { SendKeyDecryptor } from '../../core/services/chat/decryption/send-key-decryptor';
import { ExchangeSessionKeysEncryptor } from '../../core/services/chat/encryption/exchange-keys-encryptor';
import { SendMessageEncryptor } from '../../core/services/chat/encryption/send-message-encryptor';
import { InboxProcessor } from '../../core/services/chat/history/inbox-processor';
import { MediaRetriever } from '../../core/services/chat/media-retriever';
import { MESSAGE_STORING_PROVIDERS } from '../../core/services/chat/message-event/message-storing-providers';
import { CrmChatMessageSender } from '../../core/services/chat/message-sender/crm-chat-message-sender';
import { EncryptedChatMessageSender } from '../../core/services/chat/message-sender/encrypted-chat-message-sender';
import { MessageLoaderStrategyFactory } from '../../core/services/chat/message-sender/use-cases/message-loader';
import { WenChatBackendApi, WenChatUserApi } from '../../core/services/chat/wen-chat-backend-api';
import { ChatEffects } from '../../core/store/chat/chat.effects';
import { chatFeature } from '../../core/store/chat/chat.reducers';
import { ChatMessageAcknowledgeEffects } from '../../core/store/chat/effects/acknowledge.effect';
import { ChatScheduledMessagesEffects } from '../../core/store/chat/effects/chat-scheduled-message.effects';
import { ExchangeSessionKeyEffects } from '../../core/store/chat/effects/exchange-session-key.effects';
import { ChatMessageModificationEffects } from '../../core/store/chat/effects/message-modification.effect';
import { MessageReceiveEffects } from '../../core/store/chat/effects/message-receive.effects';
import { MessageSendEffects } from '../../core/store/chat/effects/message-send.effects';
import { MessageUpdateEffects } from '../../core/store/chat/effects/message-update.effects';
import { MessagesHistoryEffects } from '../../core/store/chat/effects/messages-history.effects';
import { ChatRoomMemberEffects } from '../../core/store/chat/effects/room-member.effects';
import { ChatRoomEffects } from '../../core/store/chat/effects/room.effects';
import { SessionKeyEffects } from '../../core/store/chat/effects/session-key.effects';
import { ChatMessageOperations } from '../../core/store/chat/operations/chat-message-operations';
import { ChatNotificationEffects } from '../../core/store/notification/effects/chat-notification.effects';

const MESSAGE_SENDING_PROVIDERS = [
  SendMessageEncryptor,
  CrmChatMessageSender,
  EncryptedChatMessageSender,
  MessageLoaderStrategyFactory,
];

@NgModule({
  imports: [
    StoreModule.forFeature(chatFeature),
    EffectsModule.forFeature([
      ChatEffects,
      SessionKeyEffects,
      MessageReceiveEffects,
      MessageSendEffects,
      MessagesHistoryEffects,
      ExchangeSessionKeyEffects,
      ChatMessageAcknowledgeEffects,
      ChatNotificationEffects,
      ChatRoomEffects,
      ChatRoomMemberEffects,
      ChatMessageModificationEffects,
      MessageUpdateEffects,
      ChatScheduledMessagesEffects,
    ]),
    WenChatClientModule.forRoot(),
    MessageSendTransactionModule,
  ]
})
export class WenChatModule {

  static forRoot(): ModuleWithProviders<WenChatModule> {
    return {
      ngModule: WenChatModule,
      providers: [
        WenChatBackendApi,
        {
          provide: ChatBackendApi,
          useClass: WenChatBackendApi
        },
        {
          provide: ChatUserApi,
          useClass: WenChatUserApi
        },
        SendKeyDecryptor,
        ExchangeSessionKeysDecryptor,
        ExchangeSessionKeysEncryptor,
        MessageDecryptor,
        FailedDecryptionHandler,
        ScheduledMessageDecryptor,
        InboxProcessor,
        ChatAvailabilityCheckerWithOverlay,
        ChatMessageEmbedResolver,
        MediaRetriever,
        ChatMessageOperations,
        ...MESSAGE_SENDING_PROVIDERS,
        ...MESSAGE_STORING_PROVIDERS,
      ],
    };
  }

}
