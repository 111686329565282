import { CommonPermissionType, IS_REACT_NATIVE_APP, InitialDeepLinkData, NativeApi, NativeGeolocationApi, NativeNotificationsApi, NativePlatform, NativeShareMenuApi, ShareContent, ShareMenuData } from '@portal/react-native-api';
import { NativeNotificationPayload } from './notification-types';

export const isShareContentWithUrl = (shareContent: ShareContent): shareContent is { url: string } => {
  return (shareContent as any).url;
};

export const MEDIA_PERMISSIONS: CommonPermissionType[] = ['camera', 'microphone'];
export const CAMERA_PERMISSION: CommonPermissionType[] = ['camera'];

export const IS_REACT_NATIVE_ROOT = (IS_REACT_NATIVE_APP && !window.frameElement);

export type WenNativeApiType = NativeApi<NativeNotificationsApi & NativeGeolocationApi & NativeShareMenuApi>;

export type WenNativeInitialData = {
  platform: NativePlatform;
  initialDeepLinkData?: InitialDeepLinkData;
  initialNotificationData?: NativeNotificationPayload;
  initialShareData?: ShareMenuData;
};
