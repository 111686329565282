export const groupBy = <T, R = string>(arr: Array<T>, keyFn: (item: T) => R) => {
  const groups = new Map<R, T[]>();
  arr.forEach((item) => {
    const key = keyFn(item);
    const collection = groups.get(key);
    if (!collection) {
      groups.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return groups;
};
