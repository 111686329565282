import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject, Subject, switchMap, takeUntil } from 'rxjs';
import { ChannelEntity, ChannelMessageEntity } from '../../../core/store/channel/channel.state';
import { MessagePreviewData, MessagePreviewGenerator } from './message-preview-generator';

export interface MessagePreviewCardContext {
  message: Pick<ChannelMessageEntity, 'content' | 'embeds'>;
  channel: Pick<ChannelEntity, 'title' | 'description' | 'icon'>;
}

@Component({
  selector: 'wen-message-preview-card',
  templateUrl: './message-preview-card.component.html',
  styleUrls: ['./message-preview-card.component.scss'],
  providers: [
    MessagePreviewGenerator
  ]
})
export class MessagePreviewCardComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  @HostBinding('class.wen-message-preview-card') className = true;

  private contextUpdates = new ReplaySubject<MessagePreviewCardContext>(1);
  @Input() set context(newValue: MessagePreviewCardContext) {
    this.contextUpdates.next(newValue);
  }

  previewData$ = new ReplaySubject<MessagePreviewData>(1);

  constructor(
    private messagePreviewGenerator: MessagePreviewGenerator
  ) { }

  ngOnInit(): void {
    this.contextUpdates.pipe(
      switchMap((newValue) => this.messagePreviewGenerator.createFrom(newValue)),
      takeUntil(this.onDestroy$),
    ).subscribe(this.previewData$);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
