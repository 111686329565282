@if (isEdit$ | async) {
  <div class="wen-message-discard-edit-button-container">
    <div class="wen-message-edit-indicator">
      <wen-icon iconName="edit" class="wen-icon-small"></wen-icon>
      <div portal-subheading-1>{{'MESSAGE_BOX_CONTEXT_MENU_LABEL_EDIT' | translate}}</div>
    </div>
    <div portal-subheading-1 wen-primary-color class="wen-message-edit-discard-button" (click)="stopEditingMessage()">
      {{'CANCEL_BUTTON_LABEL' | translate}}
    </div>
  </div>
}