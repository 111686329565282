import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem, ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { distinctUntilChanged, map } from 'rxjs';
import { deleteAdminForCurrentChannel } from '../../../../../../../core/store/channel/channel.actions';
import { selectCurrentChannelPermission } from '../../../../../../../core/store/channel/channel.selectors';
import { doBackNavigation } from '../../../../../../../core/store/header/header.actions';
import { RootState } from '../../../../../../../core/store/root/public-api';
import { AdminListActionPayload } from '../../channel-admin-list.component';

@Injectable()
export class RemoveAdminRightAction implements ActionMenuItem {

  label: string;
  icon = 'user_shield_off';

  isVisible$ = () => this.store.pipe(
    select(selectCurrentChannelPermission),
    map(permission => permission?.canDeleteAdmins),
    distinctUntilChanged()
  );

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>,
    private overlayManager: OverlayManager
  ) {
    this.label = this.translateService.instant('CHANNEL_DELETE_ADMIN_CONTEXT_MENU_LABEL');
  }

  execute(payload: AdminListActionPayload) {
    if (payload.isLastAdmin) {
      this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
        header: this.translateService.instant('GROUP_CHAT_LEAVE_LAST_ADMIN_CONFIRM_DIALOG_TITLE'),
        content: this.translateService.instant('GROUP_CHAT_LEAVE_LAST_ADMIN_CONFIRM_DIALOG_TEXT'),
        okLabel: this.translateService.instant('EVERYTHING_IS_CLEAR_BUTTON_LABEL')
      });
    } else {
      this.store.dispatch(deleteAdminForCurrentChannel({ userId: payload.userId }));
      if (payload.isCurrentUser) {
        this.store.dispatch(doBackNavigation());
      }
    }
  }
}
