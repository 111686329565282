import { ChosenOptionRequest, PollModel} from '@portal/wen-backend-api';
import { Observable } from 'rxjs';

export abstract class PollManager {
  /**
   * For additional behaviors, these methods have to be implemented by the consumer
   */
  abstract chooseOption(chosenOption: ChosenOptionRequest): void;
  abstract createPoll(newPoll: PollModel): Observable<void>;
}
