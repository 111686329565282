@if (previewData) {
  <div class="wen-link-embed-preview-wrapper">
    <div class="wen-link-embed-preview">
      @if (previewData.thumbnail) {
        <wen-thumbnail-image [thumbnail]="previewData.thumbnail" [fixWidth]="fixWidth"></wen-thumbnail-image>
      }
      <div class="wen-link-embed-preview-embedded-wrapper">
        @if (previewData.title) {
          <div portal-title class="wen-link-embed-preview-embed-title">
            {{ previewData.title }}
          </div>
        }
        @if (previewData.description) {
          <div portal-subheading-1 class="wen-link-embed-preview-embed-description">
            {{ previewData.description }}
          </div>
        }
      </div>
    </div>
    <wen-icon class="wen-link-embed-preview-dismiss" [class.wen-icon-huge]="isFilledDismissIcon" wen-primary-color (click)="dismissLinkPreview()" iconName="close"></wen-icon>
  </div>
}