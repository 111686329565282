import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogData, OverlayManager } from '@portal/wen-components';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { maxNumberOfUsersForMassMessageSending } from '../../../chat/constants';
import { CRMClosingDialogComponent, CRMClosingDialogData } from '../../crm-closing-dialog/crm-closing-dialog.component';
import { InviteChatCreationDialogComponent } from '../components/invite-chat-creation-dialog/invite-chat-creation-dialog.component';

@Injectable()
export class InviteChatCreationDialogOpener {

  constructor(
    private overlayManager: OverlayManager,
    private navigationHelper: WenNavigationHelper,
    private translateService: TranslateService,
  ) { }

  /**
   * Dialog when invite is needed after chat creation
   */
  openInviteDialog() {
    this.navigationHelper.navigateToCrmActionSelector();
    this.overlayManager.dialog.openConfirmationDialog(InviteChatCreationDialogComponent, {
      okLabel: this.translateService.instant('CRM_SEND_CHAT_INVITE_SEND_DIALOG_SEND_BUTTON_LABEL'),
      dismissLabel: this.translateService.instant('CRM_SEND_CHAT_INVITE_SEND_DIALOG_CANCEL_BUTTON_LABEL')
    }, { disableClose: true });
  }

  /**
   * Dialog when invite is needed after chat creation and the invite was successful
   */
  openInviteSuccessDialog() {
    const data: CRMClosingDialogData = {
      image: '/assets/wen-widget/icons/success.svg',
      title: this.translateService.instant('CRM_SEND_CHAT_INVITE_SEND_DIALOG_SUCCESS_TEXT'),
      acceptLabel: this.translateService.instant('CRM_SEND_CHAT_INVITE_SEND_DIALOG_SUCCESS_BUTTON_LABEL')
    };
    this.overlayManager.dialog.openConfirmationDialog(CRMClosingDialogComponent, data as ConfirmationDialogData, { disableClose: true });
  }

  /**
   * Dialog when all contacts were part of the network and no additional invite is needed
   */
  openSuccessDialog(isInviteEmailEnabled: boolean) {
    const titleTranslationKey = isInviteEmailEnabled ?
      'CRM_SEND_CHAT_SUCCESSFUL_DIALOG_TITLE' : 'CRM_SEND_CHAT_INVITE_SEND_DIALOG_NO_EMAIL_SUCCESS_TEXT';
    const contentTranslationKey = isInviteEmailEnabled ?
      'INVITE_HAS_BEEN_SENT_OK_LABEL' : 'CRM_SEND_CHAT_INVITE_SEND_DIALOG_NO_EMAIL_MESSAGE';
    const data: CRMClosingDialogData = {
      image: '/assets/wen-widget/icons/success.svg',
      title: this.translateService.instant(titleTranslationKey),
      message: this.translateService.instant(contentTranslationKey),
      acceptLabel: this.translateService.instant('INVITE_HAS_BEEN_SENT_OK_LABEL')
    };
    this.overlayManager.dialog.openConfirmationDialog(CRMClosingDialogComponent, data as ConfirmationDialogData, { disableClose: true });
  }

  /**
   * Dialog when too many contacts were part of the selected chat data
   */
  openTooManyChatContactsDialog() {
    this.navigationHelper.navigateToCrmActionSelector();
    const data: CRMClosingDialogData = {
      title: this.translateService.instant('CRM_SEND_CHAT_TOO_MANY_CONTACTS_DIALOG_TITLE'),
      message: this.translateService.instant('CRM_SEND_CHAT_TOO_MANY_CONTACTS_DIALOG_MESSAGE', {
        maximumSelected: maxNumberOfUsersForMassMessageSending
      }),
      acceptLabel: this.translateService.instant('CRM_SEND_CHAT_TOO_MANY_CONTACTS_DIALOG_BUTTON_LABEL')
    };
    this.overlayManager.dialog.openConfirmationDialog(CRMClosingDialogComponent, data as ConfirmationDialogData, { disableClose: true });
  }

}
