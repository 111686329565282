import { Injectable, inject } from '@angular/core';
import { WenRouteId } from '../../../../../frame/routing/types';
import { WenNativeApi } from '@portal/wen-native-api';
import { BackNavigationBehavior, BackNavigationBehaviorOutlets, BackNavigationBehaviorResult } from './back-navigation-behavior';

@Injectable()
export class MinimizeNativeAppBackNavigation implements BackNavigationBehavior {

  private nativeApi = inject(WenNativeApi);

  execute({ sidebarOutletId }: BackNavigationBehaviorOutlets): BackNavigationBehaviorResult {
    if (this.nativeApi.isReactNativeApp()) {
      const shouldMinimize = sidebarOutletId === WenRouteId.HOME;
      if (shouldMinimize) {
        this.nativeApi.minimizeApp();
        return {
          canHandle: true
        };
      }
    }
    return {
      canHandle: false
    };
  }

}
