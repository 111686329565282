import { EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { PagingHistoryFlags } from '@portal/wen-backend-api';
import { WithId, findEntityById, WithMessageProps } from '../../common/entity-state-helpers/state-adapter-utils';
import { CollectionEntityState } from '../../common/entity-state-helpers/nested-entity-adapter';

const mergeFlags = (oldValues: PagingHistoryFlags, newValues: PagingHistoryFlags) => {
  const defaults = { hasMoreNewer: oldValues?.hasMoreNewer, hasMoreOlder: oldValues?.hasMoreOlder };
  return {
    ...defaults,
    ...newValues
  };
};

export type WithHistory<TMessage> = {
  id: string;
  history?: CollectionEntityState<TMessage>;
};

export const createStateWithHistoryAdapter = <TMessageEntity extends WithId & WithMessageProps>({
  entityAdapter,
  messageEntityAdapter
}: {
  entityAdapter: EntityAdapter<WithHistory<TMessageEntity>>;
  messageEntityAdapter: EntityAdapter<TMessageEntity>;
}) => {

  const ensureEntity = (
    entityId: string,
    entityState: EntityState<WithHistory<TMessageEntity>>,
    flags: PagingHistoryFlags
  ) => {
    let targetEntity = findEntityById(entityId, entityState);
    const newFlags = mergeFlags(targetEntity?.history, flags);
    if (!targetEntity) {
      targetEntity = {
        id: entityId, history: messageEntityAdapter.getInitialState(newFlags)
      };
    } else if (!targetEntity.history) {
      targetEntity = { ...targetEntity, history: messageEntityAdapter.getInitialState(newFlags) };
    }
    targetEntity = { ...targetEntity, history: { ...targetEntity.history, ...newFlags } };
    return targetEntity;
  };

  return {
    upsertMany: (
      messages: TMessageEntity[],
      entityId: string,
      entityState: EntityState<WithHistory<TMessageEntity>>,
      flags: PagingHistoryFlags
    ) => {
      const targetEntity = ensureEntity(entityId, entityState, flags);
      const newMessagesHistory = messageEntityAdapter.upsertMany(messages, targetEntity.history);
      const newEntities = entityAdapter.upsertOne({ ...targetEntity, history: newMessagesHistory }, entityState);
      return newEntities;
    },

    updateMany: (
      messages: TMessageEntity[],
      entityId: string,
      entityState: EntityState<WithHistory<TMessageEntity>>,
      flags: PagingHistoryFlags
    ) => {
      const targetEntity = ensureEntity(entityId, entityState, flags);
      if (!targetEntity.history?.ids?.length) {
        return entityState;
      }
      const updates: Update<TMessageEntity>[] = messages.map((message) => {
        return { id: message.id, changes: message };
      });
      const newMessagesHistory = messageEntityAdapter.updateMany(updates, targetEntity.history);
      const newEntityState = entityAdapter.upsertOne({ ...targetEntity, history: newMessagesHistory }, entityState);
      return newEntityState;
    },

    clearHistory: (
      entityId: string,
      entityState: EntityState<WithHistory<TMessageEntity>>,
    ) => {
      const targetEntity = findEntityById(entityId, entityState);
      if (!targetEntity?.history) {
        return entityState;
      }
      const selectors = messageEntityAdapter.getSelectors();
      const historyMessages = selectors.selectAll(targetEntity.history);
      const hasNewInHistory = historyMessages.findIndex((historyMessage) => {
        return historyMessage?.new;
      });
      let cleanedHistory = historyMessages;
      if (hasNewInHistory === -1 && historyMessages.length >= 12) {
        cleanedHistory = historyMessages.slice(historyMessages.length - 12, historyMessages.length);
      }
      const newMessagesHistory = messageEntityAdapter.setAll(cleanedHistory, {
        ...targetEntity.history,
        hasMoreOlder: cleanedHistory.length < historyMessages.length
      });
      const newEntityState = entityAdapter.upsertOne({ ...targetEntity, history: newMessagesHistory }, entityState);
      return newEntityState;
    }

  };
};
