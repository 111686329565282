import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wen-empty-search-result',
  templateUrl: './empty-search-result.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EmptySearchResultComponent {

  @HostBinding('class.wen-empty-search-result') className = true;
  backgroundImageUrl: string;

  private readonly image1 = '/assets/wen-widget/image/empty-placeholders/pic-empty-search-result-image.svg';
  private readonly image2 = '/assets/wen-widget/image/empty-placeholders/pic-empty-search-result-image-robot.svg';

  constructor() {
    this.backgroundImageUrl = Math.random() > 0.5 ? this.image1 : this.image2;
  }

}
