import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

export const slideInForwardAnimation =
  [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      })
    ]),
    query(':enter', [
      style({ left: '80%', 'z-index': 99999 })
    ]),
    query(':leave', [
      animateChild()
    ]),
    group([
      query(':leave', [
        animate('300ms ease-out', style({
          left: '-100%'
        }))
      ]),
      query(':enter', [
        animate('300ms ease-out', style({
          left: '0%'
        }))
      ])
    ]),
    query(':enter', animateChild())
  ];

export const slideInBackwardAnimation =
  [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [
      style({
        left: '-100%', 'z-index': 99999,
        background: 'white' // TODO: this is not right for all views only channel list
      })
    ]),
    query(':leave', [
      animateChild()
    ]),
    group([
      query(':leave', [
        animate('300ms ease-out', style({
          left: '100%'
        }))
      ]),
      query(':enter', [
        animate('300ms ease-out', style({
          left: '0%'
        }))
      ])
    ]),
    query(':enter', animateChild())
  ];

export const slideInSlideOutTopAnimation = trigger('slideInSlideOutTop', [
  state(
    'show',
    style({
      transform: 'translateY(0)',
    })
  ),
  state(
    'hide',
    style({
      transform: 'translateY(-100%)',
    })
  ),
  transition('show <=> hide', [animate('500ms ease')]),
]);
