import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderModule } from '../../../frame/components/header/header.module';
import { LinkEmbedPreviewModule } from '../../../shared/components/link-embed-preview/link-embed-preview.module';
import { CHANNEL_MESSAGE_TYPE_SELECTOR_MENU_ID } from '../../../shared/components/message-type-selector/constants/type-selector-ids';
import { MessageTypeSelectorModule } from '../../../shared/components/message-type-selector/message-type-selector.module';
import { CHANNEL_MESSAGE_TYPE_ACTIONS } from '../../../shared/components/message-type-selector/providers/message-type-actions-provider';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelMessagesViewModule } from '../common/components/channel-messages-view/channel-messages-view.module';
import { ChannelContentCurtainComponent } from './components/channel-content-curtain/channel-content-curtain.component';
import { ChannelRestrictionComponent } from './components/channel-content-curtain/channel-restriction/channel-restriction.component';
import { ChannelMessageSendFieldComponent } from './components/channel-message-send-field/channel-message-send-field.component';
import { ChannelViewDesktopHeaderComponent } from './components/channel-view-desktop/channel-view-desktop-header/channel-view-desktop-header.component';
import { ChannelViewDesktopComponent } from './components/channel-view-desktop/channel-view-desktop.component';
import { ChannelViewComponent } from './components/channel-view/channel-view.component';
import { ChannelSelector } from './components/channel-view/providers/channel-selector';
import { PasswordProtectedSubscriptionDialogModule } from './components/password-protected-subscription-dialog/password-protected-subscription-dialog.module';
import { FeedModule } from '../../../shared/components/feed/feed-module';
import { WalletService } from '../../wallet/providers/wallet.service';
import { ProgressiveLoadingModule } from '../../../shared/components/progressive-slot/progressive-slot.module';
import { ChannelViewPreloaderGuard } from '../common/providers/channel-view-preloader.guard';

@NgModule({
  imports: [
    SharedModule,
    HeaderModule,
    ScrollingModule,
    TranslateModule.forChild(),
    ChannelMessagesViewModule,
    PasswordProtectedSubscriptionDialogModule,
    MessageTypeSelectorModule.forRoot(
      { menuId: CHANNEL_MESSAGE_TYPE_SELECTOR_MENU_ID, menuItems: CHANNEL_MESSAGE_TYPE_ACTIONS }
    ),
    LinkEmbedPreviewModule,
    FeedModule,
    ProgressiveLoadingModule,
    ChannelRestrictionComponent,
  ],
  declarations: [
    ChannelViewComponent,
    ChannelViewDesktopComponent,
    ChannelViewDesktopHeaderComponent,
    ChannelContentCurtainComponent,
    ChannelMessageSendFieldComponent,
  ],
  providers: [
    ChannelSelector,
    WalletService,
    ChannelViewPreloaderGuard
  ]
})
export class ChannelViewModule { }
