import { WenHorizontalCardLinkDirective } from './wen-horizontal-card.directives';
import { Component, ContentChild, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wen-horizontal-card',
  templateUrl: './wen-horizontal-card.component.html',
  styleUrls: ['./wen-horizontal-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WenHorizontalCardComponent {

  @HostBinding('class.wen-horizontal-card') className = true;

  @ContentChild(WenHorizontalCardLinkDirective) cardLink: WenHorizontalCardLinkDirective;

}
