import { APP_INITIALIZER, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { firstValueFrom } from 'rxjs';
import { rehydrateAppState, rehydrateSkipped } from '../store/persistence.actions';


export function rehydrateReadyInitializerFn() {
  const actions$ = inject(Actions);
  return () => firstValueFrom(actions$.pipe(
    ofType(rehydrateAppState, rehydrateSkipped),
  ));
}

export const REHYDRATE_READY_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: rehydrateReadyInitializerFn,
  multi: true,
};
