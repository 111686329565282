import { debounceTime, Observable } from 'rxjs';

export const optionalDebounce = (debounceTimeMs?: number) => <R>(source: Observable<R>) => {
  if (!isNullOrUndefined(debounceTimeMs)) {
    return source.pipe(
      debounceTime(debounceTimeMs)
    );
  }
  return source;
};

const isNullOrUndefined = (value: any): boolean => {
  return value === undefined || value === null;
};
