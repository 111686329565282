import type { SelectionValue } from '@smartdesign/web-api';
import { equalsIgnoreCase } from '../../../../common/operators/null-check-util';
import { ObjectTypeFromArray } from '../../../../common/types/misc';
import { CRM_RELEVANT_FIELDS_META } from '../../data-objects';
import { bestMailFieldCalculator } from '../../util';
import { CRMDataObject, DataObjectFactory, ICRMDataObjectWithOriginalDO } from '../crm-data-object';

export const OPEN_RELEVANT_FIELDS_META = [
  ...CRM_RELEVANT_FIELDS_META,
  'ACTIVITY_TYPE_ALLOWED',
  'STANDARDEMAIL',
  'SDX_WENETWORKID',
] as const;

export const OPEN_RELEVANT_FIELDS = OPEN_RELEVANT_FIELDS_META as unknown as string[];

export type OpenObjectType = ObjectTypeFromArray<typeof OPEN_RELEVANT_FIELDS_META>;

export class OpenDataObject extends CRMDataObject<OpenObjectType> implements ICRMDataObjectWithOriginalDO<OpenObjectType> {

  private readonly EMAIL_ACTIVITY_TYPE_ALLOWED = 'Email';

  get originalDO(): OpenObjectType {
    return this.originalDataObject;
  }

  get email(): string {
    const mailIndex = this.valueMapper<number>(this.originalDataObject.STANDARDEMAIL);
    return bestMailFieldCalculator(this.originalDataObject, mailIndex);
  }

  get christianName(): string {
    return this.valueMapper(this.originalDataObject.CHRISTIANNAME);
  }

  get lastName(): string {
    return this.valueMapper(this.originalDataObject.NAME);
  }

  get gguid(): string {
    return this.valueMapper(this.originalDataObject.GGUID);
  }

  get companyName(): string {
    return this.valueMapper(this.originalDataObject.COMPNAME);
  }

  get weNetworkUserId(): string {
    return this.valueMapper(this.originalDataObject.SDX_WENETWORKID) ?? this.valueMapper(this.originalDataObject.WENETWORKID);
  }

  get isEmailActivityAllowed(): boolean {
    const activityTypeAllowedTypes = this.valueMapper<SelectionValue[]>(this.originalDataObject.ACTIVITY_TYPE_ALLOWED) || [];
    const hasEmailAllowed = activityTypeAllowedTypes
      .some(activityTypeAllowedType => equalsIgnoreCase(activityTypeAllowedType.id, this.EMAIL_ACTIVITY_TYPE_ALLOWED));
    return hasEmailAllowed;
  }

}

export const openDataObjectFactory: DataObjectFactory<OpenDataObject> = {
  toCrmDataObject: (originalDo: any, isFromMassData: boolean) => {
    return new OpenDataObject(originalDo, isFromMassData);
  }
};
