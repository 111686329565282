import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class WenIconRegistry {

  private IconAssetPath = '/assets/wen-widget/icons';

  private iconMappings: Record<string, string> = {
    accessibility: 'accessibility',
    add: 'add',
    add_location: 'location_add',
    add_menu: 'add_menu',
    add_reaction: 'add_emoji',
    add_user: 'add_user',
    android_store_logo: 'google_play_store',
    apple_store_logo: 'apple_app_store',
    arrow_left_up: 'arrow_left_up',
    author: 'author',
    author_off: 'author_off',
    auto_reply: 'auto_reply',
    block: 'block',
    book: 'book',
    camera: 'camera',
    challenge: 'challenge',
    channels_active: 'channels_active',
    channels_inactive: 'channels_inactive',
    chart: 'chart',
    chats_active: 'chats_active',
    chats_inactive: 'chats_inactive',
    checkmark: 'checkmark',
    checkmark_all: 'checkmark_all',
    chevron_down: 'chevron_down',
    chevron_left: 'chevron_left',
    chevron_right: 'chevron_right',
    chevron_up: 'chevron_up',
    clock: 'clock',
    close: 'close',
    comment_active: 'comment_active',
    comment_inactive: 'comment_inactive',
    community_guideline: 'community_guideline',
    compass: 'compass',
    contact_add: 'contact_add',
    contact_author: 'contact_author',
    contact_connected: 'contact_connected',
    contact_delete: 'contact_delete',
    contact_received_pending_request: 'contact_received_pending_request',
    contact_send_request: 'contact_send_request',
    contact_sent_pending_request: 'contact_sent_pending_request',
    contact_us: 'contact_us',
    contacts: 'contacts',
    copy: 'copy',
    current_location: 'current_location',
    delete: 'delete',
    document: 'document',
    download_active: 'download_active',
    download_inactive: 'download_inactive',
    edit: 'edit',
    encryption_key: 'encryption_key',
    error_icon: 'error_outline',
    error_robot: 'error_robot',
    event_checkmark: 'event_checkmark',
    events_active: 'events_active',
    events_inactive: 'events_inactive',
    eye_half_open: 'eye_half_open',
    eye_off: 'eye_off',
    eye_on: 'eye_on',
    favorite_active: 'favorite_active',
    favorite_inactive: 'favorite_inactive',
    file_docx: 'file-docx',
    file_pdf: 'file-pdf',
    file_pptx: 'file-pptx',
    file_xlsx: 'file-xlsx',
    forward: 'forward',
    globe: 'globe',
    group: 'group',
    help: 'help',
    home_active: 'home_active',
    home_inactive: 'home_inactive',
    icon_mail: 'icon_mail',
    icon_mail2: 'icon_mail2',
    image: 'image',
    image_stacked: 'image_stacked',
    impressum: 'impressum',
    info: 'info',
    jump_back: 'jump_back',
    jump_forward: 'jump_forward',
    language: 'language',
    license: 'license',
    link: 'link',
    location: 'location',
    location_top_right: 'location_top_right',
    lock: 'lock',
    logout: 'logout',
    loop: 'loop',
    marktplatz_active: 'marktplatz_active',
    marktplatz_inactive: 'marktplatz_inactive',
    menu: 'menu',
    message_active: 'message_active',
    message_inactive: 'message_inactive',
    more: 'more',
    music: 'music',
    mute: 'mute',
    netiquette: 'netiquette',
    open_link: 'open_link',
    pause: 'pause',
    period: 'period',
    phonecall: 'phonecall',
    pin: 'pin',
    plane: 'plane',
    play: 'play',
    remove_person: 'remove_person',
    reply: 'reply',
    report_problem: 'report_problem',
    report_violation: 'report_violation',
    save_active: 'save_active',
    save_inactive: 'save_inactive',
    schedules_add_date_calendar: 'schedules_add_date_calendar',
    schedules_calendar: 'schedules_calendar',
    schedules_date_added_calendar: 'schedules_date_added_calendar',
    search: 'search',
    send: 'send',
    settings: 'settings',
    share: 'share',
    success: 'success',
    terms_of_use: 'terms_of_use',
    train: 'train',
    unmute: 'unmute',
    unpin: 'unpin',
    upload: 'upload',
    user: 'user',
    user_raise_hand: 'user_raise_hand',
    user_settings: 'user_settings',
    user_shield_off: 'user_shield_off',
    user_shield_on: 'user_shield_on',
    user_subscriptions: 'user_subscriptions',
    value_manifesto: 'value_manifesto',
    video: 'video',
    voice_record: 'voice_record',
    volume_off: 'volume_off',
    volume_on: 'volume_on',
  };

  constructor(
    private sanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) { }

  registerCustomIcons() {
    Object.entries(this.iconMappings).forEach(([iconName, fileName]) => {
      this.registerIcon(iconName, fileName);
    });
  }

  private registerIcon(iconName: string, fileName: string): void {
    this.matIconRegistry.addSvgIcon(iconName,
      this.sanitizer.bypassSecurityTrustResourceUrl(`${this.IconAssetPath}/${fileName}.svg`)
    );
  }
}
