import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WenSnackbarOpener } from '@portal/wen-components';
import { catchError, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WenNativeApi } from '@portal/wen-native-api';

@Injectable()
export class ShareService {

  constructor(
    private clipboard: Clipboard,
    private snackbarOpener: WenSnackbarOpener,
    private translateService: TranslateService,
    private nativeApi: WenNativeApi,
  ) { }

  shareDeepLink(deepLink: string) {
    if (this.nativeApi.isReactNativeApp()) {
      const shareAvailable = this.nativeApi.share({ message: deepLink, url: deepLink });
      from(shareAvailable).pipe(
        map(() => true),
        catchError(() => of(false))
      ).subscribe((success) => {
        if (!success) {
          this.copyString(deepLink);
        }
      });
      return;
    }
    this.copyString(deepLink);
  }

  private copyString(str: string) {
    const isSuccess = this.clipboard.copy(str);
    if (isSuccess) {
      this.snackbarOpener.openNotificationSnackbar({
        notificationIcon: 'copy',
        notificationText: this.translateService.instant('SNACKBAR_LABEL_LINK_COPY_SUCCESS')
      });
    }
  }

}
