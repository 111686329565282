import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AnimationOptions, LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ReactionHandler } from '../../providers/reaction-handler';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@Component({
  standalone: true,
  selector: 'wen-emoji-animation',
  templateUrl: './emoji-animation.component.html',
  styleUrls: ['./emoji-animation.component.scss'],
  imports: [
    AsyncPipe,
    LottieComponent
],
  providers: [
    provideLottieOptions({
      player: playerFactory
    })
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiAnimationComponent implements OnInit {

  private emojiId = new ReplaySubject<number>(1);

  lottieOptions$: Observable<AnimationOptions>;

  @Input() set id(value: number) {
    this.emojiId.next(value);
  }

  constructor(
    private reactionHandler: ReactionHandler,
  ) { }

  ngOnInit(): void {
    this.lottieOptions$ = this.emojiId.pipe(
      distinctUntilChanged(),
      switchMap((value: number) => {
        return this.reactionHandler.getAnimation(value).pipe(
          map((animation: string): AnimationOptions => ({
            animationData: JSON.parse(animation),
            autoplay: true,
            loop: true,
          }))
        );
      })
    );
  }
}
