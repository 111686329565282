import { createFeature, createReducer, on } from '@ngrx/store';
import { FeaturesConfig } from '@portal/wen-backend-api';
import { uiFeatureKey } from './constants';
import { resetChannelMessageOriginTransferData, resetScrollState, updateProgressState, upsertChannelMessageOriginTransferData, upsertFeatureState, upsertScrollState } from './ui.actions';
import { UiState, scrollStateEntityAdapter } from './ui.state';

export const uiInitialState: UiState = {
  storedScrollState: scrollStateEntityAdapter.getInitialState(),
  channelMessageOriginTransferData: null,
  progressState: { hasProgress: false },
  featureConfig: {} as FeaturesConfig
};

export const uiFeature = createFeature({
  name: uiFeatureKey,
  reducer: createReducer(
    uiInitialState,
    on(upsertScrollState, (state, { newState }) => {
      const storedScrollState = scrollStateEntityAdapter.upsertOne(newState, state.storedScrollState);
      return {
        ...state,
        storedScrollState
      };
    }),
    on(resetScrollState, (state, { scrollDataContext }) => {
      const entitiesForDataContext = scrollStateEntityAdapter
        .getSelectors().selectAll(state.storedScrollState)
        .filter((entity) => entity.scrollDataContext === scrollDataContext)
        .map(entity => entity.scrollContextId);
      const newState = scrollStateEntityAdapter.removeMany(entitiesForDataContext, state.storedScrollState);
      return {
        ...state,
        storedScrollState: newState
      };
    }),
    on(updateProgressState, (state, { newState }) => {
      return {
        ...state,
        progressState: newState
      };
    }),
    on(upsertChannelMessageOriginTransferData, (state, { newState }) => {
      return {
        ...state,
        channelMessageOriginTransferData: newState
      };
    }),
    on(resetChannelMessageOriginTransferData, (state) => {
      return {
        ...state,
        channelMessageOriginTransferData: null
      };
    }),
    on(upsertFeatureState, (state, { newState }) => {
      return {
        ...state,
        featureConfig: newState
      };
    }),
  ),
});
