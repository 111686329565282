import { APP_INITIALIZER, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClientCustomizationService } from '../../core/services/customization/client-customization-service';
import { NetworkLoadedGuard } from '../../core/services/network/network-loaded-guard';
import { NetworkEffects } from '../../core/store/network/network.effects';
import { networkFeature } from '../../core/store/network/network.reducers';
import { clientStyleInitializer } from './client-style-initializer';

export interface NetworkModuleProps {
  /**
   * Provider for build-time client side customization setup
   *
   * The provided style config gets applied:
   * - When the subscription for the network is not yet done (eg. before login)
   * - If there is no @networkInitializationHandler provided at all
   */
  clientCustomizationProvider: Provider;
  /**
   * Provider for network initialization handler
   *
   * This provider defines how the client handles the network subscription:
   * - Use the standard @WEN_NETWORK_INITIALIZATION_PROVIDER
   * - Ignore this provider to skip network initialization/subscription of the client
   */
  networkInitializationHandler?: Provider;
}

@NgModule({
  imports: [
    StoreModule.forFeature(networkFeature),
    EffectsModule.forFeature([NetworkEffects]),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: clientStyleInitializer,
      deps: [ClientCustomizationService],
      multi: true
    },
    NetworkLoadedGuard,
  ]
})
export class NetworkModule {

  static forRoot(props: NetworkModuleProps): ModuleWithProviders<NetworkModule> {
    return {
      ngModule: NetworkModule,
      providers: [
        props.clientCustomizationProvider,
        props.networkInitializationHandler || [],
        ClientCustomizationService,
      ]
    };
  }

}
