import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wenHasProperty',
})
export class HasPropertyPipe implements PipeTransform {

  transform(value: Record<string, any>, propertyName: string) {
    return value?.hasOwnProperty(propertyName);
  }
}

@Pipe({
  name: 'wenHasTrueProperty',
})
export class HasTruePropertyPipe implements PipeTransform {

  transform(value: Record<string, any>, propertyName: string) {
    return value?.hasOwnProperty(propertyName) && Boolean(value[propertyName]);
  }
}
