import { Injectable } from '@angular/core';
import { MassDataContainer } from '@portal/wen-data-core';
import { addChatUsersSearchMassDataIdentifier } from '../../constants';
import { ChatMemberEditSearchEntity } from '../providers/chat-member-edit-state-types';

@Injectable()
export class AddChatUsersSearchMassDataContainer
  extends MassDataContainer<ChatMemberEditSearchEntity> {

  getIdentifier() {
    return addChatUsersSearchMassDataIdentifier;
  }

}
