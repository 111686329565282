import { MediaType } from '@portal/wen-backend-api';

export const getMediaTypeData = (mediaType: MediaType) => {
  switch (mediaType) {
    case MediaType.AUDIO:
      return {
        icon: 'music',
        title: 'AUDIO_FILE_LABEL'
      };
    case MediaType.PICTURE:
      return {
        icon: 'image',
        title: 'IMAGE_FILE_LABEL'
      };
    case MediaType.VIDEO:
      return {
        icon: 'video',
        title: 'VIDEO_FILE_LABEL'
      };
    case MediaType.DOCUMENT:
      return {
        icon: 'document',
        title: 'DOCUMENT_FILE_LABEL'
      };
    default:
      return {
        icon: null,
        title: null
      };
  }
};

export const getMediaTypeIcon = (mediaType: MediaType) => {
  return getMediaTypeData(mediaType).icon;
};

export const getMediaTypeTitle = (mediaType: MediaType) => {
  return getMediaTypeData(mediaType).title;
};

export const getFileExtension = (fileName: string): string => {
  return fileName.substring(fileName.lastIndexOf('.') + 1);
};

export const getIconForSupportedFileExtension = (extension: string): string => {
  const supportedFileExtensionIcons = [
    {
      fileTypes: ['docx', 'doc'],
      icon: 'docx'
    },
    {
      fileTypes: ['pdf'],
      icon: 'pdf'
    },
    {
      fileTypes: ['pptx', 'ppt'],
      icon: 'pptx',
    },
    {
      fileTypes: ['xlsx', 'xls'],
      icon: 'xlsx'
    }
  ];
  const icon = supportedFileExtensionIcons.find(item => item.fileTypes.includes(extension))?.icon;
  return icon ? `file_${icon}` : 'document';
};
