import { ActionMenuConfig } from '@portal/wen-components';
import { PromoteToGroupAdminAction } from './promote-to-group-admin.action';
import { RemoveAdminStatusGroupMemberAction } from './remove-admin-status-group-member.action';
import { RemoveMemberFromRoomAction } from './remove-from-chat-group.action';

export const chatDetailMembersMenuId = 'chat-detail-members-menu-id';

export const CHAT_DETAIL_MEMBERS_MENU: ActionMenuConfig = {
  menuId: chatDetailMembersMenuId,
  menuItems: [PromoteToGroupAdminAction, RemoveAdminStatusGroupMemberAction, RemoveMemberFromRoomAction]
};
