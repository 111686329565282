<mat-form-field [floatLabel]="label ? 'auto' : 'never'">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <input
    #scheduleInput
    matInput
    [ngxMatDatetimePicker]="picker"
    [ngModel]="selectedDateViewValue$ | async"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onDateChange($event)"
    [min]="minDate"
    [disabled]="disabled$ | async"
    [placeholder]="placeholder"
    readonly
    />
    <div class="wen-input-suffix" matSuffix>
      @if (isClearVisible$ | async) {
        <wen-icon
          (click)="clearDate()"
          iconName="close"
        ></wen-icon>
      }
      <mat-datepicker-toggle
        [for]="picker"
        (mousedown)="calculateMinDateAndTime()"
        [disableRipple]="true"
      ></mat-datepicker-toggle>
    </div>
    <ngx-mat-datetime-picker [defaultTime]="defaultTime" [touchUi]="isMobileView" #picker (opened)="onPickerOpen(picker)">
      <ngx-mat-datepicker-actions>
        <wen-button class="wen-button-rounded" wenVariant="primary" ngxMatDatepickerApply
          [isDisabled]="isSaveDisabled$ | async">
          {{ "SCHEDULED_DATEPICKER_APPLY" | translate }}
        </wen-button>
      </ngx-mat-datepicker-actions>
    </ngx-mat-datetime-picker>
  </mat-form-field>
