import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';

@Directive({
  selector: '[wenComingSoonFeature], wenComingSoonFeature'
})
export class ComingSoonFeatureDirective implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  private isComingSoonFeature = new ReplaySubject<boolean>();

  @Input('wenComingSoonFeature')
  set value(newValue: any) {
    this.isComingSoonFeature.next(coerceBooleanProperty(newValue));
  }

  constructor(
    private featuresService: FeatureEnablementService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {
  }

  ngOnInit() {
    this.isComingSoonFeature.pipe(
      map((isComingSoonFeature) => {
        return !isComingSoonFeature || Boolean(isComingSoonFeature && this.featuresService.featureFlagMethods.isEnableComingSoonFeatures());
      }),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(isVisible => {
      this.updateView(isVisible);
    });
  }

  private updateView(isVisible: boolean) {
    if (isVisible) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
