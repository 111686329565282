import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { META_REDUCERS, StoreModule } from '@ngrx/store';
import { FeatureEnablementService } from '../../core/services/configuration/feature-enablement';
import { AppsEffects } from '../../core/store/apps/apps.effects';
import { appsReducerFactory } from '../../core/store/apps/apps.reducers';
import { appsFeatureKey } from '../../core/store/apps/constants';
import { AppFiltersEffects } from '../../core/store/apps/effects/app-filters.effect';
import { AppSectionEffects } from '../../core/store/apps/effects/app-sections.effects';
import { ChannelRestrictionEffects } from '../../core/store/channel/channel-restrictions.effect';
import { ChannelEffects } from '../../core/store/channel/channel.effects';
import { channelReducerFactory } from '../../core/store/channel/channel.reducers';
import { channelFeatureKey } from '../../core/store/channel/constants';
import { ChannelAdminEffects } from '../../core/store/channel/effects/channel-admin.effects';
import { ChannelContentProviderEffects } from '../../core/store/channel/effects/channel-content-provider.effect';
import { ChannelFiltersEffects } from '../../core/store/channel/effects/channel-filters.effects';
import { ChannelSectionEffects } from '../../core/store/channel/effects/channel-section.effects';
import { ChannelSubscriberEffects } from '../../core/store/channel/effects/channel-subscriber.effects';
import { GeoChannelffects } from '../../core/store/channel/effects/geo/geo-channel.effects';
import { ChannelInvitationEffects } from '../../core/store/channel/effects/invitation/channel-invitation.effects';
import { ChannelInviteToChannelEffects } from '../../core/store/channel/effects/invitation/channel-invite-to-channel.effect';
import { ChannelJoinRequestEffects } from '../../core/store/channel/effects/invitation/channel-join-request.effects';
import { DiscoveryChannelListEffects } from '../../core/store/channel/effects/lists/discovery-channel-list.effects';
import { UserChannelListEffects } from '../../core/store/channel/effects/lists/user-channel-list.effects';
import { SendChannelMessageEffects } from '../../core/store/channel/effects/send-channel-message.effect';
import { christmasFeature } from '../../core/store/christmas/christmas.reducers';
import { CommentsEffects } from '../../core/store/comments/comments.effects';
import { commentsFeature } from '../../core/store/comments/comments.reducers';
import { eventsFeatureKey } from '../../core/store/events/constants';
import { EventFiltersEffects } from '../../core/store/events/effects/event-filters.effects';
import { EventSectionEffects } from '../../core/store/events/effects/event-section.effects';
import { EventEffect } from '../../core/store/events/event.effects';
import { eventReducerFactory } from '../../core/store/events/event.reducers';
import { FilterEffects } from '../../core/store/filter/filter.effects';
import { filterFeature } from '../../core/store/filter/filter.reducers';
import { formFeatureKey } from '../../core/store/form/constants';
import { AppFormEffects } from '../../core/store/form/effects/app.form.effects';
import { AutoReplyEditFormEffects } from '../../core/store/form/effects/auto-reply-edit.form.effects';
import { ChannelConfigureFormEffects } from '../../core/store/form/effects/channel-configure.form.effects';
import { ChannelPostEditorFormEffects } from '../../core/store/form/effects/channel-post-editor.form.effects';
import { ChannelFormEffects } from '../../core/store/form/effects/channel.form.effects';
import { ChatMembersFormEffects } from '../../core/store/form/effects/chat-members.form.effects';
import { ChatFormEffect } from '../../core/store/form/effects/chat.form.effects';
import { FormEffects } from '../../core/store/form/effects/form.effects';
import { InviteToNetworkFormEffects } from '../../core/store/form/effects/invite.form.effects';
import { ProfileFormEffects } from '../../core/store/form/effects/profile.form.effects';
import { formReducerFactory } from '../../core/store/form/form.reducers';
import { notificationFeatureKey } from '../../core/store/notification/constants';
import { NotificationEffects } from '../../core/store/notification/notification.effects';
import { notificationReducerFactory } from '../../core/store/notification/notification.reducers';
import { PinEffects } from '../../core/store/pin/pin.effects';
import { pinFeature } from '../../core/store/pin/pin.reducers';
import { reactionsFeatureKey } from '../../core/store/reactions/constants';
import { ReactionsEffects } from '../../core/store/reactions/reaction.effects';
import { reactionReducerFactory } from '../../core/store/reactions/reaction.reducers';
import { WEN_STORE_META_REDUCER } from '../../core/store/root/public-api';
import { SectionLoadingStateEffects } from '../../core/store/sections/effects/section-loading-state.effect';
import { sectionFeature } from '../../core/store/sections/sections.reducers';
import { SmartDesignEffects } from '../../core/store/smartdesign/smartdesign.effects';
import { smartDesignFeature } from '../../core/store/smartdesign/smartdesign.reducers';
import { UiStateEffects } from '../../core/store/ui/effects/ui-state.effects';
import { uiFeature } from '../../core/store/ui/ui.reducers';
import { userFeatureKey } from '../../core/store/user/constants';
import { UserEffects } from '../../core/store/user/user.effects';
import { userReducerFactory } from '../../core/store/user/user.reducers';
import { WeatherEffects } from '../../core/store/weather/effects/weather.effects';
import { weatherFeature } from '../../core/store/weather/weather.reducers';
import { SharedModule } from '../../shared/shared.module';
import { pageUpdateInitializer } from '../api/page-update-initializer';
import { HeaderModule } from './header/header.module';
import { NavigationBarModule } from './navigation-bar/navigation-bar.module';
import { RouteDialogModule } from './route-dialog/route-dialog.module';
import { WenWidgetComponentModule } from './wen-widget/wen-widget-component.module';
import { WenWidgetComponent } from './wen-widget/wen-widget.component';
import { walletFeature } from '../../core/store/wallet/wallet.reducers';
import { WalletEffects } from '../../core/store/wallet/wallet.effects';
import { contactsFeature } from '../../core/store/contacts/contacts.reducers';
import { ContactsEffects } from '../../core/store/contacts/contacts.effects';
import { UserNavigationEffects } from '../../core/store/user/effects/user-navigation.effects';
import { LanguageFormEffects } from '../../core/store/form/effects/language.form.effects';
import { PrivacySettingsFormEffects } from '../../core/store/form/effects/privacy-settings.form.effects';
import { MetaDataSettingsEffects } from '../../core/store/user/effects/meta-data-settings.effect';
import { ChangePasswordFormEffects } from '../../core/store/form/effects/change-password.form.effects';

@Injectable()
export class HammerConfigOverride extends HammerGestureConfig {
  overrides = {
    press: { time: 500 },
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    HeaderModule,
    NavigationBarModule,
    WenWidgetComponentModule,
    RouteDialogModule,
    StoreModule.forFeature(channelFeatureKey, channelReducerFactory),
    EffectsModule.forFeature([
      ChannelEffects,
      SendChannelMessageEffects,
      ChannelRestrictionEffects,
      ChannelAdminEffects,
      ChannelContentProviderEffects,
      ChannelSubscriberEffects,
      UserChannelListEffects,
      DiscoveryChannelListEffects,
      ChannelInvitationEffects,
      ChannelJoinRequestEffects,
      ChannelInviteToChannelEffects,
      GeoChannelffects,
      ChannelFiltersEffects,
      ChannelSectionEffects
    ]),
    StoreModule.forFeature(appsFeatureKey, appsReducerFactory),
    EffectsModule.forFeature([AppsEffects, AppFiltersEffects, AppSectionEffects, SectionLoadingStateEffects]),
    StoreModule.forFeature(notificationFeatureKey, notificationReducerFactory),
    EffectsModule.forFeature([NotificationEffects]),
    StoreModule.forFeature(userFeatureKey, userReducerFactory),
    EffectsModule.forFeature([UserEffects, UserNavigationEffects, MetaDataSettingsEffects]),
    StoreModule.forFeature(reactionsFeatureKey, reactionReducerFactory),
    EffectsModule.forFeature([ReactionsEffects]),
    StoreModule.forFeature(formFeatureKey, formReducerFactory),
    EffectsModule.forFeature([
      FormEffects,
      ChannelFormEffects,
      ChannelPostEditorFormEffects,
      AppFormEffects,
      ProfileFormEffects,
      ChangePasswordFormEffects,
      LanguageFormEffects,
      PrivacySettingsFormEffects,
      InviteToNetworkFormEffects,
      ChatFormEffect,
      ChatMembersFormEffects,
      AutoReplyEditFormEffects,
      ChannelConfigureFormEffects,
    ]),
    StoreModule.forFeature(eventsFeatureKey, eventReducerFactory),
    EffectsModule.forFeature([EventEffect, EventFiltersEffects, EventSectionEffects]),
    StoreModule.forFeature(filterFeature),
    EffectsModule.forFeature([FilterEffects]),
    StoreModule.forFeature(commentsFeature),
    EffectsModule.forFeature([CommentsEffects]),
    StoreModule.forFeature(uiFeature),
    EffectsModule.forFeature([UiStateEffects]),
    StoreModule.forFeature(christmasFeature),
    StoreModule.forFeature(smartDesignFeature),
    EffectsModule.forFeature([SmartDesignEffects]),
    StoreModule.forFeature(sectionFeature),
    StoreModule.forFeature(weatherFeature),
    EffectsModule.forFeature([WeatherEffects]),
    StoreModule.forFeature(pinFeature),
    EffectsModule.forFeature([PinEffects]),
    StoreModule.forFeature(walletFeature),
    EffectsModule.forFeature([WalletEffects]),
    StoreModule.forFeature(contactsFeature),
    EffectsModule.forFeature([ContactsEffects]),
  ],
  exports: [
    HeaderModule,
    NavigationBarModule,
    WenWidgetComponent
  ],
  providers: [
    {
      provide: META_REDUCERS,
      useFactory: WEN_STORE_META_REDUCER,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfigOverride,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: pageUpdateInitializer,
      deps: [FeatureEnablementService],
      multi: true
    }
  ],
})
export class WenWidgetModule { }
