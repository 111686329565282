import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HintControlProps } from '@portal/wen-backend-api';

@Component({
  selector: 'wen-hint',
  templateUrl: './hint.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class HintComponent {
  @Input() context: HintControlProps;
}
