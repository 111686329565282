import { ActivatedRoute } from '@angular/router';
import { addQueryParam } from '@portal/wen-common';
import { catchError, of } from 'rxjs';
import { AppConfigurationProvider } from '../../../core/services/configuration/app-confguration';
import { INVITATION_ID_QUERY_PARAM } from '../../../core/services/navigation/query-params';
import { WenNavigationHelper } from '../../../core/services/navigation/types';
import { WenOAuthService } from '../../../core/services/user-management/wen-oauth.service';
import { SignInActionsHandler, SignInViewConfig } from './types';
import { TranslateService } from '@ngx-translate/core';
import { hasTranslationFor } from '../../../shared/util/translation-util';

export class SignInInviteActionsHandler extends SignInActionsHandler {

  viewConfig: SignInViewConfig;
  inviteId: string;

  constructor(
    private readonly navigationHelper: WenNavigationHelper,
    private readonly wenOAuthService: WenOAuthService,
    private readonly config: AppConfigurationProvider,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {
    super();
    this.viewConfig = {
      showLoginButton: true,
      showRegisterButton: true,
      showAnonLoginButton: false,
      showLoginPrompt: false,
      termsAndConditions:
        hasTranslationFor(translateService, 'PRIVACY_POLICY_URL')
        && hasTranslationFor(translateService,'TERMS_AND_CONDITIONS_URL'),
    };
    this.inviteId = this.activatedRoute.snapshot.queryParams.inviteId;
  }

  goToRegister() {
    this.navigationHelper.navigateToRegistrationStart(true, this.inviteId);
  }

  goToLogin() {
    const returnUrl = addQueryParam('/signin/invite', new URLSearchParams({ [INVITATION_ID_QUERY_PARAM]: this.inviteId }));
    this.wenOAuthService.initCodeFlow({ redirectAfterLogin: returnUrl }).pipe(
      catchError(() => {
        this.navigationHelper.navigateToErrorPage();
        return of(false);
      })
    ).subscribe();
  }


  openTermsAndConditionsPage() {
    this.navigationHelper.navigateToUrlWithFallback(this.translateService.instant('TERMS_AND_CONDITIONS_URL'));
    return false;
  }

  openPrivacyPage() {
    this.navigationHelper.navigateToUrlWithFallback(this.translateService.instant('PRIVACY_POLICY_URL'));
    return false;
  }

  goToAnonymousLogin() { }
}
