@if (loadingIndicatorVariant) {
  <wen-loading-indicator [variant]="loadingIndicatorVariant"></wen-loading-indicator>
}

@if (contentErrorModel) {
  <wen-content-error [contentErrorModel]="contentErrorModel"></wen-content-error>
}

@if (restrictionModel) {
  <div class="wen-text-view-restriction-wrapper">
    <wen-channel-restriction [restrictionModel]="restrictionModel"></wen-channel-restriction>
  </div>
}

@if (textView$ | async; as textViewData) {
  <div class="wen-text-view-container">
    <div>
      <img [src]="textViewData.previewImage?.src" class="wen-text-view-title-image"/>
    </div>
    <div class="wen-text-view-title-container">
      <div class="wen-text-view-title-container-title">
        <h2 class="wen-text-view-title">{{textViewData.articleTitle}}</h2>
        <div class="wen-text-view-metadata">
          @if (isDesktopStyleDevice) {
            <h3 class="wen-text-view-issue-info">
              {{ "TEXTVIEW_ISSUE_INFO" | translate:
              { issueTitle: textViewData.magazineTitle, issueNumber: textViewData.issueNumber, issueYear: textViewData.issueYear }
              }}
            </h3>
          }
          <h3 class="wen-text-view-read-time">
            <wen-icon iconName="clock" class="wen-icon-tiny"></wen-icon>
            {{textViewData.readTimeInMin}} {{ "TEXTVIEW_MINUTES" | translate }}
          </h3>
        </div>
      </div>
      @if (textViewData.textToSpeechSrc) {
        <button mat-mini-fab color="primary" class="mat-elevation-z0">
          <wen-text-to-speech-button
            [payload]="{ contextId: textViewData.articleId, contentSrc: textViewData.textToSpeechSrc }"
          enableOnTheFlyContent="false"></wen-text-to-speech-button>
        </button>
      }
      <button mat-mini-fab color="primary" class="mat-elevation-z0" (click)="shareDeepLink()">
        <wen-icon iconName="share" color="white"></wen-icon>
      </button>
      @if (canForward$ | async) {
        <wen-forward-button wen-detail-header-quick-actions [deepLink]="textViewDeepLink" [context]="forwardContext"></wen-forward-button>
      }
    </div>
    <div class="content-separator"></div>
    <div class="wen-text-view-content-container" [innerHTML]="textViewData.safeContentHtml"></div>
  </div>
}
