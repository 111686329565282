import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmbeddedPageViewerComponent } from './embedded-page-viewer.component';
import { DefaultEmbeddedUrlResolver } from './providers/embedded-url-resolver';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    EmbeddedPageViewerComponent
  ],
  providers: [
    DefaultEmbeddedUrlResolver,
  ],
  exports: [
    EmbeddedPageViewerComponent
  ]
})
export class EmbeddedPageViewerModule { }
