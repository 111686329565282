<a class="wen-nav-bar-item-link" [wen-test-id]="'wen-navbar-item-' + label" [routerLink]="itemLink"
  routerLinkActive="wen-nav-bar-item-link-active" #rla="routerLinkActive">
  @if (iconName) {
    <div class="wen-nav-bar-item-icon">
      <wen-icon [iconName]="rla.isActive ? iconNameActive : iconName"></wen-icon>
      @if (notificationCount$ | async; as count) {
        @if (count) {
          <wen-notification-count [value]="count"></wen-notification-count>
        }
      }
    </div>
  }
  <div class="wen-nav-bar-item-label">{{label | translate}}</div>
</a>
