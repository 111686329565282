import { Injectable, OnDestroy } from '@angular/core';
import { ConnectParams, WenWebApiBase } from '@portal/wen-embed-api';
import { expose } from 'comlink';
import { first, fromEvent } from 'rxjs';
import { AuthApiPart } from './api-parts/auth/auth-api';
import { NavigationApiPart } from './api-parts/navigation/navigation-api';
import { DisposableWindowEndpoint, disposableWindowEndpoint } from './comlink-utils/disposable-endpoint';
import { EmbeddedAppContext } from './api-parts/state/embedded-app-context';

@Injectable()
export class EmbeddedApiService implements WenWebApiBase, OnDestroy {

  private activeEndpoint: DisposableWindowEndpoint = null;

  constructor(
    public readonly Auth: AuthApiPart,
    public readonly Navigation: NavigationApiPart,
    private readonly embeddedAppContext: EmbeddedAppContext,
  ) { }

  dispose() {
    if (this.activeEndpoint) {
      this.activeEndpoint.dispose();
      this.activeEndpoint = null;
    }
    this.embeddedAppContext.cleanContext();
  }

  init(embeddedWindow: HTMLIFrameElement) {
    this.dispose();
    fromEvent(embeddedWindow, 'load').pipe(
      first()
    ).subscribe(() => {
      this.activeEndpoint = disposableWindowEndpoint(embeddedWindow.contentWindow);
      expose(this, this.activeEndpoint);
      const initialSrc = embeddedWindow.getAttribute('src');
      this.Navigation.initNavigationForApp(initialSrc);
    });
  }

  connectInternal(params: ConnectParams): Promise<void> {
    this.Navigation.setBackNavigationMode(params.backNavigationMode);
    return Promise.resolve();
  }

  ngOnDestroy() {
    this.dispose();
  }

}
