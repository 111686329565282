import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { SimpleUserDTO } from '@portal/wen-backend-api';
import { WenRouteId } from '../../../frame/routing/types';
import { CRMBackendType } from '../../services/smartdesign/helpers/evaluate-backend-type';
import { ICRMDataObject } from '../../services/smartdesign/providers/crm-data-object';

export type ImportedSelectionEntity = ICRMDataObject;

export interface SmartDesignState {
  isFromSmartDesign: boolean;
  sdHostParam: string;
  backendType: CRMBackendType;
  importedSelection: EntityState<ImportedSelectionEntity>;
  weNetworkUsers: SimpleUserDTO[];
  currentRootRoute: WenRouteId;
}

export const importedSelectionEntityAdapter = createEntityAdapter<ImportedSelectionEntity>({
  selectId: (entity) => entity.gguid
});
