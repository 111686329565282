import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Contactability, UserProfileSettings } from '@portal/wen-backend-api';
import { SelectionOption } from '@portal/wen-components';
import { FeatureEnablementService } from '../../../../../../core/services/configuration/feature-enablement';
import { UserProfile } from '../../../../../../core/store/user/models/UserProfile';

@Injectable()
export class UserVisibilityService {

  getVisibilitySettings(userProfile: UserProfile): UserProfileSettings {
    return {
      searchableByPhone: userProfile.searchableByPhone,
      searchableByName: userProfile.searchableByName,
      enableChatViaProfile: userProfile.enableChatViaProfile,
      contactable: userProfile.contactable,
      primaryLanguage: userProfile.primaryLanguage
    };
  }

  getVisibilitySettingItems(): SelectionOption<Contactability>[] {
    return [
      { id: Contactability.ANYONE, label: 'USER_CONTACTABLE_SETTINGS_ANYONE_LABEL', value: Contactability.ANYONE },
      ...(this.featureEnablementService.featureFlagMethods.isEnableContacts() ?
        [{ id: Contactability.CONTACTS, label: 'USER_CONTACTABLE_SETTINGS_CONTACTS_LABEL', value: Contactability.CONTACTS }] : []),
      { id: Contactability.NO_ONE, label: 'USER_CONTACTABLE_SETTINGS_NO_ONE_LABEL', value: Contactability.NO_ONE }
    ].map(item => {
      return {
        id: item.id,
        value: item.value,
        text: this.translateService.instant(item.label)
      };
    });
  }

  constructor(
    private translateService: TranslateService,
    private featureEnablementService: FeatureEnablementService,
  ) { }

}
