import { Injectable } from '@angular/core';
import { BackNavigationMode, NavigationApi, NavigationInterceptorFn } from '@portal/wen-embed-api';
import { EmbeddedAppContext } from '../state/embedded-app-context';
import { EmbeddedAppNavigator } from './embedded-app-navigator';

@Injectable()
export class NavigationApiPart implements NavigationApi {

  constructor(
    private embeddedAppNavigator: EmbeddedAppNavigator,
    private embeddedAppContext: EmbeddedAppContext,
  ) { }

  initNavigationForApp(initialSrc: string) {
    this.embeddedAppNavigator.initNavigator(initialSrc);
  }

  setBackNavigationMode(mode: BackNavigationMode): void {
    this.embeddedAppContext.setBackNavigationMode(mode);
  }

  setNavigationInterceptor(interceptorFn: NavigationInterceptorFn): void {
    this.embeddedAppContext.setNavigationInterceptor(interceptorFn);
  }

  urlChanged(url: string): void {
    this.embeddedAppNavigator.onPathChanged(url);
  }

  exitApp(): void {
    this.embeddedAppNavigator.exitEmbeddedApp();
  }
}
