import { WenRoutes } from '../../../frame/routing/types';
import { addAppInfoRoute, appAddWelcomeRoute, appDetailRoute, appDiscoveryRoute, appEditRoute, appListRoute, appViewRoute, appExploreRoute } from './apps.routes';

export const dialogOutletAppRoutes: WenRoutes = [
  appDetailRoute,
  appEditRoute,
  addAppInfoRoute,
  appAddWelcomeRoute
];

export const sidebarOutletAppRoutes: WenRoutes = [
  appExploreRoute,
  appDiscoveryRoute,
  appListRoute,
  {
    path: '**',
    redirectTo: 'explore'
  }
];

export const primaryOutletAppRoutes: WenRoutes = [
  appViewRoute,
  {
    path: '**',
    redirectTo: '/home/dashboard(sidebar:apps/explore)'
  }
];
