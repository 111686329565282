import type { Field, SingleValueField } from '@smartdesign/web-api';
import { isNullOrUndefined } from '../../common/operators/null-check-util';

type WithMailfields = Record<`MAILFIELDSTR${number}`, Field<string> | string>;

export const isField = <T>(fieldOrValue: Field<T> | any): fieldOrValue is Field<T> => {
  return Boolean(fieldOrValue?.fieldName);
};

export const getValueFromField = <T>(fieldOrValue: Field<T> | T): T => {
  return isField(fieldOrValue) ? fieldOrValue.value as T : fieldOrValue;
};

export const bestMailFieldCalculator = (dataObject: WithMailfields, mailFieldIndex?: number) => {

  // Get the first existing mail field based on the index of the mail fields like: MAILFIELDSTR[index]
  const getFirstExistingMailField = (data: WithMailfields) => {
    const mailFieldValues = Object.keys(data).filter((key) => {
      return key.includes('MAILFIELDSTR');
    }).map(key => {
      const fieldOrValue: SingleValueField<string> | string = data[key];
      return getValueFromField(fieldOrValue);
    });
    const firstExistingMailField = mailFieldValues.find((emailValue) => {
      return Boolean(emailValue);
    });
    return firstExistingMailField;
  };
  if (!isNullOrUndefined(mailFieldIndex)) {
    const preferredMail = getValueFromField<string>(dataObject[`MAILFIELDSTR${mailFieldIndex}`]);
    if (preferredMail) {
      return preferredMail;
    }
  }
  return getFirstExistingMailField(dataObject);
};
