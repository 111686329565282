import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { LazyLoadParams } from '../../directives/lazy-load/lazy-load.directive';

export interface Thumbnail {
  url: string;
  width: number | string;
  height: number | string;
}

@Component({
  selector: 'wen-thumbnail-image',
  templateUrl: './thumbnail-image.component.html',
  styleUrls: ['./thumbnail-image.component.scss']
})
export class ThumbnailImageComponent {

  @HostBinding('class.wen-thumbnail-image') className = true;

  @Output() clicked = new EventEmitter();
  @Input() set thumbnail(value: Thumbnail) {
    this.thumbnailParams = {
      src: value.url,
      originalWidth: +value.width,
      originalHeight: +value.height
    };
  }
  @Input() fixWidth: number;
  @Input() disableScaling: boolean;
  @Input() roundedEdges = false;

  public thumbnailParams: LazyLoadParams;

  onClick() {
    this.clicked.emit();
  }

}
