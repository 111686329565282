import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { AppUpdatePayload, MediaContextTypes, MediaUseCases, SocketIoService, WeLocalImageHelper } from '@portal/wen-backend-api';
import { EMPTY, merge, Observable, of } from 'rxjs';
import { filter, first, map, mergeAll, mergeMap, switchMap, tap } from 'rxjs/operators';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { hasProperty } from '../../../common/operators/null-check-util';
import { deepMerge } from '../../../common/util/deepmerge';
import { WenNavigationHelper } from '../../../services/navigation/types';
import { updateApp } from '../../apps/apps.actions';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { UserData } from '../../auth/models/UserData';
import { DataObjectType } from '../../common/data-objects';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { clearAllFormValues, clearFormValues } from '../form.actions';
import { selectAllEditForms, selectEditFormById } from '../form.selectors';
import { EditFormEntity } from '../form.state';

@Injectable()
export class AppFormEffects {

  saveEditAppForm$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.APP),
    switchMap(({ formId }) => this.store.pipe(
      select(selectEditFormById(formId)),
      first()
    )),
    filter((editForm) => Boolean(editForm?.initialValues?.id)),
    mapWithFirstFrom(() => this.store.pipe(select(selectCurrentUserData))),
    switchMap(([editForm, userData]) => {
      const { formId, changedValues, initialValues } = editForm;
      const { icon, ...simpleChangedValues } = changedValues;
      const iconRemoved = hasProperty(changedValues, 'icon') && !icon;
      const { id } = initialValues;
      const appUpdate: AppUpdatePayload = {
        id,
        userId: userData.userId,
        ...simpleChangedValues
      };
      const afterSaveActions: Observable<Action>[] = [of([
        clearFormValues({ formId }),
        doBackNavigation(),
      ]).pipe(mergeAll())];

      if (iconRemoved) {
        appUpdate.icon = '';
      } else if (icon) {
        const updateImageAction = this.uploadAndUpdateAppImage(icon, id, userData);
        afterSaveActions.push(updateImageAction);
      }
      this.socketIoService.app.update.emit(appUpdate);
      const result$ = merge(
        afterSaveActions
      ).pipe(mergeAll());
      return result$;
    })
  ));

  saveCreateAppForm$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.APP),
    switchMap(() => this.store.pipe(
      select(selectAllEditForms),
      first()
    )),
    map(editForms => {
      let app = {} as EditFormEntity;
      Object.keys(editForms).forEach(key => {
        app = deepMerge(app, editForms[key]);
      });
      return app;
    }),
    filter((editForm) => !Boolean(editForm?.initialValues?.id)),
    mapWithFirstFrom(() => this.store.pipe(select(selectCurrentUserData))),
    switchMap(([editForm, userData]) => {
      const { status, changedValues } = editForm;
      if (status !== 'VALID') {
        return [];
      }
      const { icon, ...simpleChangedValues } = changedValues;

      return this.socketIoService.app.create.acknowledgement$({
        title: simpleChangedValues.title,
        uri: simpleChangedValues.uri,
        ...simpleChangedValues,
      }).pipe(
        first(),
        switchMap((app) => {
          this.navigationHelper.navigateToAppDetail(app.id, { replaceUrl: true }, true);
          const afterSaveActions: Observable<Action>[] = [of(clearAllFormValues())];
          if (icon) {
            const updateImageAction = this.uploadAndUpdateAppImage(icon, app.id, userData);
            afterSaveActions.push(updateImageAction);
          }
          afterSaveActions.push(of(updateApp({ app })));
          return merge(afterSaveActions).pipe(mergeAll());
        })
      );
    }),
  ));

  constructor(
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
    private imageHelper: WeLocalImageHelper,
    private navigationHelper: WenNavigationHelper,
    private formStoreMediator: FormStoreMediator,
  ) { }

  private uploadAndUpdateAppImage(icon: File, id: string, userData: UserData) {
    return this.imageHelper.uploadImage(icon, [{ type: MediaContextTypes.APP, id }], MediaUseCases.PROFILE).pipe(
      tap((result) => {
        this.socketIoService.app.update.emit({
          id,
          userId: userData.userId,
          icon: result.thumbnailUrl
        });
      }),
      mergeMap(() => EMPTY),
    );
  }

}
