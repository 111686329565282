import deepCopyLib from 'deepcopy';
import equal from 'fast-deep-equal/es6';

export const deepCopy = <T>(obj: T): T => {
  return deepCopyLib(obj);
};

export const deepEqual = (...params: Parameters<typeof equal>): ReturnType<typeof equal> => {
  return equal(...params);
};
