import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { ChatViewPreloaderGuard } from '../providers/chat-view-preloader.guard';

export const chatViewGuardFn: CanActivateFn = (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
  const chatViewPreloaderGuard = inject(ChatViewPreloaderGuard);
  chatViewPreloaderGuard.ensureCurrentChatDetailsLoaded();
  return true;
};
