import { Pipe, PipeTransform } from '@angular/core';
import { ContactListEntity, PendingContactEntity } from '../../../core/store/contacts/contacts.state';
import { UserListItemModel } from '../../../shared/components/user-list-item/models/user-list-item.model';

@Pipe({
  name: 'toUserListItem',
  pure: true
})
export class ToUserListItemPipe implements PipeTransform {

  transform(value: ContactListEntity | PendingContactEntity): UserListItemModel {
    if (!value?.user) {
      return null;
    }
    const { avatarUrl, displayName } = value.user;
    return { avatarUrl, displayName };
  }

}
