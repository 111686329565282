import { Injectable } from '@angular/core';
import { MediaSettings, SocketIoService } from '@portal/wen-backend-api';
import { first } from 'rxjs/operators';

@Injectable()
export class MediaSettingsService {
  private maxFileSizeInMiB = 500;
  private maxImagePixelDimensions = 8000;

  constructor(private socketIOService: SocketIoService) {
  }

  getSettingsFromServer() {
    this.socketIOService.media.getSettings.emit();
    this.socketIOService.media.getSettings.listen
      .pipe(first())
      .subscribe((settings: MediaSettings) => {
        this.maxFileSizeInMiB = settings.maxFileSizeInMiB;
        this.maxImagePixelDimensions = settings.maxImageDimension;
      });
  }

  public getMaxFileSizeInMiB(): number {
    return this.maxFileSizeInMiB;
  }

  public getMaxImagePixelDimensions(): number {
    return this.maxImagePixelDimensions;
  }
}
