import { Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { ChannelListHeaderDataUpdater } from '../../providers/channel-list-header-data-updater';
import { ChannelLoadResult } from '../channel-list/models/channel-load-result';
import { UserChannelListDataSource } from './user-channel-list-data-source';
import { ChannelListItemFactory } from '../channel-list/channel-list-item/providers/channel-list-item-factory';
import { ChannelListItemDataSource } from '../channel-list/channel-list-item/providers/channel-list-item-data-source';

@Component({
  selector: 'wen-user-channel-list',
  templateUrl: './user-channel-list.component.html',
  styleUrls: ['./user-channel-list.component.scss'],
  providers: [
    UserChannelListDataSource, ChannelListHeaderDataUpdater, ChannelListItemFactory, ChannelListItemDataSource
  ]
})
export class UserChannelListComponent implements OnInit {

  @HostBinding('class.wen-user-channel-list') className = true;

  loadResult$: Observable<ChannelLoadResult>;

  constructor(
    private readonly dataSource: UserChannelListDataSource,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly channelListHeaderDataUpdater: ChannelListHeaderDataUpdater,
    private readonly featureEnablementService: FeatureEnablementService,
  ) {
  }

  ngOnInit() {
    this.channelListHeaderDataUpdater.initialize();
    this.loadResult$ = this.dataSource.loadResult$;
  }

  navigateToChannelDiscover() {
    this.navigationHelper.navigateToChannelDiscover();
  }

  navigateToChannelExplore() {
    this.navigationHelper.navigateToChannelExplore();
  }

}
