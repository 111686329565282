import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { RootState } from '../../../core/store/root/public-api';
import { navigateToUserProfile } from '../../../core/store/user/user.actions';
import { UserNavigable } from './model';

@Directive({ selector: '[userNavigable]' })
export class UserNavigableDirective implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  constructor(
    private host: UserNavigable,
    private store: Store<RootState>,
    private featureEnablementService: FeatureEnablementService,
  ) { }

  @Input('userNavigable') userId: string;

  ngAfterViewInit() {
    if (this.host?.getTargetElement && this.featureEnablementService.featureFlagMethods.isEnablePublicProfile()) {
      fromEvent(this.host.getTargetElement(), 'click').pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(() => {
        this.store.dispatch(navigateToUserProfile({ userId: this.userId }));
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
