import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { ActionMenuItemProvider } from '../../../../../core/services/util/action-menu-item.provider';
import { selectCurrentAppView } from '../../../../../core/store/apps/apps.selectors';
import { AppEntity } from '../../../../../core/store/apps/apps.state';
import { appContextMenuId } from '../actions/app-action-menu';
import { ActionMenuComponent, OverlayManager } from '@portal/wen-components';

@Component({
  selector: 'wen-app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppListComponent {

  @Input() apps: AppEntity[];
  @Input() enableContextMenu = false;

  @Output() appClicked = new EventEmitter<AppEntity>();

  currentAppId$: Observable<string>;
  actions$ = this.actionMenuItemProvider.selectActions(appContextMenuId);

  constructor(
    private store: Store,
    private actionMenuItemProvider: ActionMenuItemProvider,
    private overlayManager: OverlayManager
  ) {
    this.currentAppId$ = this.store.pipe(
      select(selectCurrentAppView),
      map(app => app?.id)
    );
  }

  openContextMenu(app: AppEntity) {
    this.overlayManager.menu.open(ActionMenuComponent, { actions: this.actions$, data: app });
  }

  onClick(item: AppEntity) {
    this.appClicked.emit(item);
  }

  trackByFn(item: AppEntity) {
    return item.id;
  }

}
