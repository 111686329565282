export interface ICRMDataObject extends ICRMDataObjectWithWenId {
  get gguid(): string;
  get email(): string;
  get christianName(): string;
  get lastName(): string;
  get companyName(): string;
  get isEmailActivityAllowed(): boolean;
}

export interface ICRMDataObjectWithWenId {
  get weNetworkUserId(): string;
}

export interface ICRMDataObjectWithOriginalDO<T> {
  get originalDO(): T;
}

export abstract class CRMDataObject<T> implements ICRMDataObject {

  protected valueMapper: <R>(field: any) => R;

  constructor(
    protected readonly originalDataObject: T,
    readonly isFromMassData: boolean,
  ) {
    this.valueMapper = isFromMassData ? getValueFromMassData : getValueFromDataObject;
  }

  abstract get gguid(): string;
  abstract get email(): string;
  abstract get christianName(): string;
  abstract get lastName(): string;
  abstract get companyName(): string;
  abstract get weNetworkUserId(): string;
  abstract get isEmailActivityAllowed(): boolean;

  toTransfarable(): ICRMDataObject {
    return {
      gguid: this.gguid,
      weNetworkUserId: this.weNetworkUserId,
      email: this.email,
      christianName: this.christianName,
      lastName: this.lastName,
      companyName: this.companyName,
      isEmailActivityAllowed: this.isEmailActivityAllowed
    };
  }

  toString() {
    return `
    email: ${this.email}\n
    christianName: ${this.christianName}\n
    lastName: ${this.lastName}\n
    gguid: ${this.gguid}\n
    companyName: ${this.companyName}\n
    weNetworkUserId: ${this.weNetworkUserId}\n
    isEmailActivityAllowed: ${this.isEmailActivityAllowed}\n
    `;
  }

}

export type DataObjectFactory<T> = {
  toCrmDataObject: (originalDo: any, isFromMassData: boolean) => T;
};

const getValueFromDataObject = (field: any) => field?.value;
const getValueFromMassData = (field: any) => field;
