import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { filter, first, map, merge, switchMap, withLatestFrom } from 'rxjs';
import { WenRouteId } from '../../../frame/routing/types';
import { DataContextHelper } from '../../services/util/data-context-helper';
import { selectCurrentHeaderData } from '../header/header.selectors';
import { selectOutletIds } from '../root/root.selectors';
import { removeFilter } from './filter.actions';
import { FilterEntityType } from './models/filter';

@Injectable()
export class FilterEffects {

  resetFiltersOnContextChange$ = createEffect(() => this.dataContextHelper.onDataContextChanges().pipe(
    withLatestFrom(this.store.pipe(select(selectCurrentHeaderData))),
    map(([_, headerData]) => {
      return headerData?.searchConfig?.filterConfig?.filterEntityType;
    }),
    filter((filterEntityType) => Boolean(filterEntityType) && filterEntityType !== FilterEntityType.CONTACTS_USER_LIST_FILTER),
    map((filterEntityType) => {
      return removeFilter({ filterEntityType });
    })
  ));

  resetContactFiltersOnContextChange$ = createEffect(() => this.dataContextHelper.onDataContextChanges().pipe(
    withLatestFrom(this.store.pipe(select(selectCurrentHeaderData))),
    filter(([_, headerData]) => {
      const filterEntityType = headerData?.searchConfig?.filterConfig?.filterEntityType;
      return Boolean(filterEntityType) && filterEntityType === FilterEntityType.CONTACTS_USER_LIST_FILTER;
    }),
    switchMap(() => {
      const currentOutletId$ = this.store.pipe(
        select(selectOutletIds),
        first()
      );
      const navigationOutletIdChanges$ = this.actions$.pipe(
        ofType(routerNavigatedAction),
        switchMap(() => this.store.pipe(
          select(selectOutletIds)
        ))
      );
      const outletChanges$ = merge(currentOutletId$, navigationOutletIdChanges$);
      const resetWhenOutOfContext$ = outletChanges$.pipe(
        map(({ primaryId }) => {
          switch (primaryId) {
            case WenRouteId.CONTACTS_LIST:
            case WenRouteId.OTHER_USERPROFILE_READ:
            case WenRouteId.CHAT_VIEW:
              return false;
            default:
              return true;
          }
        }),
      );
      return resetWhenOutOfContext$.pipe(
        first(),
        filter((needsReset) => needsReset)
      );
    }),
    map(() => {
      return removeFilter({ filterEntityType: FilterEntityType.CONTACTS_USER_LIST_FILTER });
    })
  ));

  resetContactFiltersOnContextChangeOnDesktop$ = createEffect(() => this.store.pipe(
    select(selectOutletIds),
    filter(({ dialogId }) => dialogId === WenRouteId.CONTACTS_LIST),
    switchMap(() => {
      return this.store.pipe(
        select(selectOutletIds),
        filter(({ dialogId }) => !Boolean(dialogId)),
        first()
      );
    }),
    map(() => {
      return removeFilter({ filterEntityType: FilterEntityType.CONTACTS_USER_LIST_FILTER });
    })
  ));

  constructor(
    private store: Store,
    private actions$: Actions,
    private dataContextHelper: DataContextHelper,
  ) { }

}
