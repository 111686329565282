import { inject, Injectable } from '@angular/core';

import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';

export const inviteToNetworkEnabledGuardFn = () => {
  return inject(InviteToNetworkEnabledGuard).canActivate();
};

@Injectable()
export class InviteToNetworkEnabledGuard {

  constructor(
    private featureEnablement: FeatureEnablementService,
  ) { }

  canActivate() {
    return this.featureEnablement.isInviteToNetworkEnabled();
  }

}
