import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wen-app-logo',
  templateUrl: './app-logo.component.html',
  styleUrls: ['./app-logo.component.scss']
})
export class AppLogoComponent implements OnInit {

  ngOnInit(): void {
  }

}
