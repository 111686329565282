import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CircleInitialViewModule, ImageUrlTransformModule } from '@portal/wen-components';
import { WenPipesModule } from '../../pipes/wen-pipes.module';
import { DetailHeaderComponent } from './detail-header.component';
import { DetailHeaderAdditionalContentDirective, DetailHeaderQuickActionsDirective } from './detail-header.directives';

@NgModule({
  imports: [
    CommonModule,
    CircleInitialViewModule,
    ImageUrlTransformModule,
    ScrollingModule,
    WenPipesModule,
  ],
  declarations: [
    DetailHeaderComponent,
    DetailHeaderQuickActionsDirective,
    DetailHeaderAdditionalContentDirective,
  ],
  exports: [
    DetailHeaderComponent,
    DetailHeaderQuickActionsDirective,
    DetailHeaderAdditionalContentDirective,
  ]
})
export class DetailHeaderModule { }
