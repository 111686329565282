import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { DateUtil } from '../../../core/common/date/date-util';

@Injectable()
export class MessageDateFormatter {

  constructor(
    private translateService: TranslateService
  ) { }

  formatMessageDateForList(dateTime: DateTime): string {
    if (!dateTime) {
      return null;
    }
    if (DateUtil.isToday(dateTime)) {
      return DateUtil.toTimeClientFormat(dateTime);
    } else if (DateUtil.isYesterday(dateTime)) {
      return this.translateService.instant('CHANNEL_VIEW_SEPARATOR_YESTERDAY');
    } else if (DateUtil.isWithinLast7Days(dateTime)) {
      return `${DateUtil.toShortWeekDay(dateTime)}.`;
    } else if (DateUtil.isCurrentYear(dateTime)) {
      return `${DateUtil.toMonthAndDayOnly(dateTime)}`;
    }
    return DateUtil.toDateOnly(dateTime);
  }

}
