import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { of } from 'rxjs';
import { ColorPalette } from '../../../../shared/directives/colorize/palette-provider';
import { WenNavigationHelper } from '../../../services/navigation/types';

@Injectable()
export class GroupChatAction implements ActionMenuItem<string, void> {

  label: string;
  icon = 'group';
  isVisible$ = () => of(true);

  iconColor = 'white';
  fillColor = ColorPalette.DARK_BLUE;

  constructor(
    private translateService: TranslateService,
    private navigationHelper: WenNavigationHelper
  ) {
    this.label = this.translateService.instant('CHAT_CREATE_NEW_GROUP');
  }

  execute() {
    this.navigationHelper.navigateToGroupChatCreation();
  }
}
