import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ActionMenuConfig, ActionMenuModule, ACTION_MENU_ITEMS, PopperModule, WenIconModule } from '@portal/wen-components';
import { ChannelMediaPreviewHandler } from '../../../core/services/util/channel-media-preview-handler';
import { ChatMediaPreviewHandler } from '../../../core/services/util/chat-media-preview-handler';
import { DraftChatMediaPreviewHandler } from '../../../core/services/util/draft-chat-media-preview-handler';
import { FileSelectorHandler } from '../../../core/services/util/file-selector-handler';
import { MessageTypeSelectorComponent } from './message-type-selector.component';

@NgModule({
  declarations: [MessageTypeSelectorComponent],
  imports: [
    ActionMenuModule,
    WenIconModule,
    PopperModule,
    CommonModule,
  ],
  exports: [MessageTypeSelectorComponent],
  providers: [
    FileSelectorHandler,
    DraftChatMediaPreviewHandler,
    ChatMediaPreviewHandler,
    ChannelMediaPreviewHandler,
  ]
})
export class MessageTypeSelectorModule {

  static forRoot(config: ActionMenuConfig): ModuleWithProviders<MessageTypeSelectorModule> {
    return {
      ngModule: MessageTypeSelectorModule,
      providers: [
        {
          provide: ACTION_MENU_ITEMS,
          useValue: config,
          multi: true
        }
      ]
    };
  }
}
