import { OlmDeviceModel } from '@portal/wen-backend-api';
import { first, from } from 'rxjs';

export const asObservable = <T>(promise: Promise<T>) => {
  return from(promise).pipe(first());
};

export const filterDuplicateDevices = (devicesToFilter: OlmDeviceModel[], devices: OlmDeviceModel[]) => {
  return devicesToFilter.filter((deviceToFilter) => {
    const isDuplicate = devices.some(device => {
      return device.algorithm === deviceToFilter.algorithm &&
        device.deviceId === deviceToFilter.deviceId &&
        device.key === deviceToFilter.key &&
        device.userId === deviceToFilter.userId;
    });
    return !isDuplicate;
  });
};
