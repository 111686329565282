import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, OverlayManager } from '@portal/wen-components';
import { of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { firstExisty } from '../../../../../../core/common/operators/first-existy';
import { acknowledgeDeletedCurrentChannel } from '../../../../../../core/store/channel/channel.actions';
import { RootState } from '../../../../../../core/store/root/public-api';
import { ChannelSelector } from '../providers/channel-selector';

export const channelDeletedGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(ChannelDeletedGuard).canActivate(route, state);
};

@Injectable()
export class ChannelDeletedGuard  {
  constructor(
    private store: Store<RootState>,
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
    private channelSelector: ChannelSelector
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.channelSelector.currentChannel$.pipe(
      firstExisty(),
      switchMap((channel) => {
        if (!channel) {
          return of(false);
        }
        if (!channel.deleted) {
          return of(true);
        }
        const { deletedReason } = channel;
        const content = deletedReason ?
          `${this.translateService.instant('DELETED_CHANNEL_INFO_DIALOG_CONTENT_WITH_REASON')} ${deletedReason}` :
          this.translateService.instant('DELETED_CHANNEL_INFO_DIALOG_CONTENT');

        const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
          header: this.translateService.instant('DELETED_CHANNEL_INFO_DIALOG_TITLE'),
          content,
          okLabel: this.translateService.instant('EVERYTHING_IS_CLEAR_BUTTON_LABEL'),
        }, { disableClose: true });
        return dialog.afterClosed().pipe(
          first(),
          switchMap(() => {
            this.store.dispatch(acknowledgeDeletedCurrentChannel());
            return of(false);
          }));
      }),
    );
  }

}
