import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, filter, map } from 'rxjs/operators';
import { selectCurrentAppView } from '../../../../../core/store/apps/apps.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { EmbeddedAppData, EmbeddedUrlResolver } from '../../../../../shared/components/embedded-page-viewer/providers/embedded-url-resolver';

@Injectable()
export class AppEmbeddedUrlResolver extends EmbeddedUrlResolver {

  constructor(
    private store: Store<RootState>,
  ) {
    super();
  }

  resolveEmbeddedData(): Observable<EmbeddedAppData> {
    return this.store.pipe(
      select(selectCurrentAppView),
      filter(app => Boolean(app?.uri)),
      map((app) => ({
        url: app.uri,
        embeddedApiSupported: app.embedded
      })),
      distinctUntilKeyChanged('url')
    );
  }

}
