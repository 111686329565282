import { NgModule } from '@angular/core';
import { RegistrationCodeVerificationModule } from './registration-code-verification/registration-code-verification.module';
import { RegistrationFormModule } from './registration-profile/registration-profile.module';
import { RegistrationStartModule } from './registration-start/registration-start.module';

@NgModule({
  imports: [
    RegistrationStartModule,
    RegistrationCodeVerificationModule,
    RegistrationFormModule,
  ],
  exports: [
    RegistrationStartModule,
    RegistrationCodeVerificationModule,
    RegistrationFormModule,
  ]
})
export class RegistrationModule { }
