import { InviteeDTO } from '@portal/wen-backend-api';
import { ImportedSelectionEntity } from '../../store/smartdesign/smartdesign.state';

export const convertToInviteeDTO = (entities: ImportedSelectionEntity[]): InviteeDTO[] => {
  if (!entities) {
    return [];
  }
  return entities.map((entity) => {
    const { gguid, christianName, lastName, companyName, email, weNetworkUserId } = entity;
    const dto: InviteeDTO = {
      foreignId: gguid,
      christianName, lastName, companyName, email, weNetworkUserId
    };
    return dto;
  });
};
