import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CUSTOM_IMAGE_CROPPER_TOKEN, PortalImageUploadModule } from '@portal/ui-kit/image-upload';
import { CircleInitialViewModule, DialogLayoutModule, WenIconModule } from '@portal/wen-components';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropDialogComponent } from './image-crop-dialog/image-crop-dialog.component';
import { ImageUploadComponent } from './image-upload.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    DialogLayoutModule,
    TranslateModule,
    WenIconModule,
    ImageCropperModule,
    CircleInitialViewModule,
    PortalImageUploadModule,
  ],
  declarations: [
    ImageUploadComponent,
    ImageCropDialogComponent
  ],
  providers: [
    {
      provide: CUSTOM_IMAGE_CROPPER_TOKEN,
      useValue: ImageCropDialogComponent
    }
  ],
  exports: [
    ImageUploadComponent
  ]
})
export class ImageUploadModule { }
