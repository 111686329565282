import { Action, createReducer, on } from '@ngrx/store';
import { updateHeaderData, updateSearchbarActive, upsertHeaderData } from './header.actions';
import { HeaderState } from './header.state';

export const defaultSearchTerm = '';

export const headerInitialState: HeaderState = {
  headerData: {
    title: null,
    showLogo: true
  },
  searchbarActive: false
};

const headerReducer = createReducer(
  headerInitialState,
  on(updateHeaderData, (state, { headerData }) => {
    return {
      ...state,
      headerData
    };
  }),
  on(upsertHeaderData, (state, { headerData }) => {
    return {
      ...state,
      headerData: state.headerData || headerData ? { ...state.headerData, ...headerData } : null
    };
  }),
  on(updateSearchbarActive, (state, { active }) => {
    return {
      ...state,
      searchbarActive: active
    };
  })
);

export function headerReducerFactory(state: HeaderState | undefined, action: Action) {
  return headerReducer(state, action);
}
