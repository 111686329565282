import { AppPermission, ChannelPermission } from './models/Permission';
import { PrivilegeDTO } from './models/Privilege';
import { UserProfile } from './models/UserProfile';

export interface UserMetaDataSettings {
  traceReportsEnabled: boolean;
}

export enum ProfileImageState {
  LOADED = 'LOADED',
  PENDING_SAFE = 'PENDING_SAFE',
  PENDING_UNSAFE = 'PENDING_UNSAFE',
  ERROR = 'ERROR',
}

export interface UserState {
  userProfile: UserProfile;
  privileges: PrivilegeDTO[];
  channelPermission: ChannelPermission;
  appPermission: AppPermission;
  profileImageState: ProfileImageState;
  userMetaDataSettings: UserMetaDataSettings;
}
