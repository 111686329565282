@if (!textBelowEmbeds) {
  <ng-container *ngTemplateOutlet="text"></ng-container>
}

@if (messageBoxData$ | async; as messageBoxData) {
  @if(messageBoxData.embeds?.length > 0) {
    <div class="wen-message-box-embeds">
      <ng-container [wen-embed-host]="messageBoxData"></ng-container>
    </div>
  }
}

@if (textBelowEmbeds) {
  <ng-container *ngTemplateOutlet="text"></ng-container>
}

<ng-template #text>
  @if (textContent$ | async; as textContent) {
    <wen-markdown-text class="wen-message-box-content-text"
      [content]="textContent"
      [markdownEnabled]="true"
      [wen-test-id]="'wen-message-box-content'"
    ></wen-markdown-text>
  }
</ng-template>
