<div class="wen-form">
  <div class="wen-form-group wen-single-group">
    <div class="wen-form-field-label" portal-caption>{{ 'CHANNEL_ADMIN_LIST_LABEL' | translate }}</div>
    @if (canAddAdmins$ | async) {
      <div class="wen-channel-admin-list-add-admin-button" portal-body-2 wen-primary-color (click)="navigateToAddAdminPage()">
        <wen-icon class="wen-icon-big" [iconName]="'add_user'"></wen-icon>
        {{ "CHANNEL_ADD_ADMIN_TITLE" | translate }}
      </div>
    }
    <div class="wen-form-field-scroller">
      @for (listItem of adminList$ | async; track listItem) {
        <wen-user-list-item [user]="listItem.user" [userNavigable]="listItem.user.userId">
          @if (listItem.hasMenu) {
            <wen-icon [iconName]="'more'" [color]="contextMenuOpenerFillColor" (click)="openContextMenu(listItem.payload)"
            wen-user-after></wen-icon>
          }
        </wen-user-list-item>
      }
    </div>
    <div class="wen-form-group-hint" portal-subheading-2>{{ 'CHANNEL_ADMIN_LIST_DESCRIPTION_LABEL' | translate }}</div>
  </div>
</div>
