import { Component, Input } from '@angular/core';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { WenRouteId } from '../../../../../frame/routing/types';
import { chatViewIdentifier } from '../../../tokens';

@Component({
  selector: 'wen-navigate-chat-scheduled-messages-view-button',
  templateUrl: './navigate-chat-scheduled-messages-view-button.component.html',
})
export class NavigateChatScheduledMessagesViewButtonComponent {

  @Input() roomId: string;

  constructor(
    private appNavigator: AppNavigator
  ) { }

  navigateToChatSchedulesForRoom() {
    if (this.roomId) {
      this.appNavigator.navigateToRoute(WenRouteId.CHAT_SCHEDULES, {
        [chatViewIdentifier]: this.roomId
      });
    }
  }

}
