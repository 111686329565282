import { inject, Injectable } from '@angular/core';
import { RoomType, SocketIoService } from '@portal/wen-backend-api';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { ChatMessageTransactionData } from '../../types/chat-transaction-data';
import { ChatAvailabilityChecker } from '../chat-availability-checker';
import { ValidRoomResult } from '../prepare-chat-room-service';

type ChatRoomValidityResult = {
  validRoomRequests: ChatMessageTransactionData[];
  invalidRoomRequests?: ChatMessageTransactionData[];
};

@Injectable()
export class PrepareDialogRoomService {

  private socketIoService = inject(SocketIoService);
  private chatAvailabilityChecker = inject(ChatAvailabilityChecker);

  public prepareDialogRoom(
    datas: ChatMessageTransactionData[],
    currentUserId: string
  ) {
    return this.validateDialogRoomsAvailability(datas).pipe(
      switchMap((valdiationResult) => {
        const { validRoomRequests, invalidRoomRequests = [] } = valdiationResult;
        return this.createDialogRooms(validRoomRequests, currentUserId).pipe(
          map((prepareResults) => {
            return {
              prepareResults,
              invalidRoomRequests
            };
          })
        );
      })
    );
  }

  private createDialogRooms(
    transactionDataRequests: ChatMessageTransactionData[],
    currentUserId: string
  ): Observable<ValidRoomResult[]> {
    if (!transactionDataRequests?.length) {
      return of([]);
    }
    const allUserIds = transactionDataRequests
      .map((composerDataRequest) => composerDataRequest.dialogUserId);

    if (!allUserIds?.length) {
      return of(null);
    }
    const roomCreations$ = transactionDataRequests.map(transactionDataRequest => {
      const { dialogUserId } = transactionDataRequest;
      const memberUserIds = [currentUserId, dialogUserId];
      return this.socketIoService.chat.room.createRoom.acknowledgement$({
        userIds: memberUserIds,
        roomType: RoomType.DIALOG
      }).pipe(
        map((result) => {
          const prepareRoomResult: ValidRoomResult = {
            roomMemberData: {
              roomId: result.id,
              memberUserIds
            },
            transactionDataRequest
          };
          return prepareRoomResult;
        })
      );
    });
    return forkJoin(roomCreations$);
  }

  private validateDialogRoomsAvailability(composerDataRequests: ChatMessageTransactionData[]): Observable<ChatRoomValidityResult> {
    const userIds = composerDataRequests
      .map((composerDataRequest) => composerDataRequest.dialogUserId);

    if (!this.chatAvailabilityChecker) {
      return of({
        validRoomRequests: composerDataRequests
      });
    }
    const uniqueUserIds = new Set(userIds.flat());
    return this.chatAvailabilityChecker.checkChatAvailabilityWithUsers(Array.from(uniqueUserIds)).pipe(
      map((unavailableUserIds) => {
        if (!unavailableUserIds?.length) {
          return {
            validRoomRequests: composerDataRequests
          };
        }
        const initialAcc: ChatRoomValidityResult = {
          validRoomRequests: [],
          invalidRoomRequests: [],
        };
        const invalidDialogRooms = composerDataRequests.reduce((acc, currentRequest) => {
          const isUnavailable = unavailableUserIds.includes(currentRequest.dialogUserId);
          const { validRoomRequests: availableDialogRooms, invalidRoomRequests: unavailableDialogRooms } = acc;
          if (isUnavailable) {
            return {
              ...acc,
              invalidRoomRequests: [...unavailableDialogRooms, currentRequest]
            };
          } else {
            return {
              ...acc,
              validRoomRequests: [...availableDialogRooms, currentRequest]
            };
          }
        }, initialAcc);
        return invalidDialogRooms;
      })
    );
  }

}
