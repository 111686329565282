import { WenRoutes } from '../../../frame/routing/types';
import { addAppInfoRoute, appAddWelcomeRoute, appDetailRoute, appDiscoveryRoute, appEditRoute, appListRoute, appViewRoute, appExploreRoute } from './apps.routes';

export const singleOutletAppRoutes: WenRoutes = [
  appExploreRoute,
  appListRoute,
  appDiscoveryRoute,
  appEditRoute,
  appAddWelcomeRoute,
  addAppInfoRoute,
  appDetailRoute,
  appViewRoute,
  {
    path: '**',
    redirectTo: 'explore'
  }
];
