import { FactoryProvider, InjectionToken, Injector, Optional } from '@angular/core';
import { DefaultEmbeddedUrlResolver, EmbeddedUrlResolver } from './embedded-url-resolver';

export const EMBEDDED_URL_RESOLVER = new InjectionToken<EmbeddedUrlResolver>('EMBEDDED_URL_RESOLVER');

export const embeddedUrlResolverProvider: FactoryProvider = {
  provide: EmbeddedUrlResolver,
  useFactory: (externalResolver: EmbeddedUrlResolver, injector: Injector) => {
    return externalResolver || injector.get(DefaultEmbeddedUrlResolver);
  },
  deps: [[new Optional(), EMBEDDED_URL_RESOLVER], Injector]
};
