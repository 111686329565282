import { Component, OnInit } from '@angular/core';
import { ChatGetAutoReplyResponse, SocketIoService } from '@portal/wen-backend-api';
import { Observable, map } from 'rxjs';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';

@Component({
  selector: 'wen-auto-reply-text',
  templateUrl: './auto-reply-text.component.html',
  styleUrls: ['./auto-reply-text.component.scss']
})
export class AutoReplyTextComponent implements OnInit {
  autoReplyText$: Observable<string>;
  autoReplyEnable$: Observable<boolean>;
  autoReplyResult$: Observable<ChatGetAutoReplyResponse>;

  constructor(
    private readonly socketIOService: SocketIoService,
    private readonly featureEnablementService: FeatureEnablementService
  ) { }

  ngOnInit(): void {
    this.socketIOService.chat.getAutoReply.emit({});
    this.autoReplyResult$ = this.socketIOService.chat.getAutoReply.listen.pipe(
      shareReplay(1)
    );
    this.autoReplyText$ = this.autoReplyResult$.pipe(
      map(result => result.replyMessage)
    );
    this.autoReplyEnable$ = this.autoReplyResult$.pipe(
      map(result => result.active && this.featureEnablementService.featureFlagMethods.isEnableChatAutoReply())
    );
  }
}
