import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'wen-progressive-slot',
  templateUrl: './progressive-slot.component.html',
  styleUrls: ['./progressive-slot.component.scss'],
})
export class ProgressiveSlotComponent {

  @HostBinding('class.wen-progressive-slot-visible') @Input() visibilityCondition = false;

}
