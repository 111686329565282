import { WenRouteId, WenRoutes } from '../../frame/routing/types';
import { EmbeddedPageViewerComponent } from '../../shared/components/embedded-page-viewer/embedded-page-viewer.component';

export const embeddedPageRoutes: WenRoutes = [
  {
    path: '',
    component: EmbeddedPageViewerComponent,
    data: {
      routeId: WenRouteId.EMBEDDED_PAGE
    }
  }
];
