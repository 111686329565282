import { Injectable } from '@angular/core';
import { ReplaySubject, shareReplay } from 'rxjs';
import { ChatMessageComposerItem } from './chat-message-composer-item';
import { ChatMessageComposerData, ChatMessageComposerExtras } from './types';

@Injectable()
export class ChatMessageComposer {

  constructor(
    private chatMessageItem: ChatMessageComposerItem,
  ) { }

  sendMessage(data: ChatMessageComposerData, extras: ChatMessageComposerExtras) {
    const result = new ReplaySubject<{ eventId: string }>(1);
    const message$ = this.chatMessageItem.upsertMessage(data, extras).pipe(
      shareReplay(1)
    );
    message$.subscribe({
      next: ({ eventId }) => {
        result.next({ eventId });
        result.complete();
      },
      error: (error) => {
        result.error(error);
      }
    });
    return result.pipe(shareReplay(1));
  }

}
