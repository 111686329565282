import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AppDTO, CategoriesDTO } from '@portal/wen-backend-api';
import { LoadingState } from '../../common/types/store-loading-state';
import { createNetworkEntityHelper, EntityWithNetworkMeta } from '../network/util/network-entity-helper';
import { AppPermission } from '../user/models/Permission';

export type AppEntity = AppDTO & {
  permission?: AppPermission;
};

export type AppMeta = EntityWithNetworkMeta;

export interface AppsState {
  apps: EntityState<AppEntity>;
  appMetas: EntityState<AppMeta>;
  userAppIds: string[];
  discoverAppIds: string[];
  userAppsLoading: LoadingState;
  discoverAppsLoading: LoadingState;
  categories: CategoriesDTO;
}

export const appMetaAdapter: EntityAdapter<AppMeta> = createEntityAdapter<AppMeta>({
  selectId: (appMeta: AppMeta) => appMeta.entityId,
  sortComparer: false,
});

export const appNetworkEntityHelper = createNetworkEntityHelper();

export const appsAdapter: EntityAdapter<AppEntity> = createEntityAdapter<AppEntity>({
  selectId: (app: AppEntity) => app.id,
  sortComparer: false,
});
