import { NetworkMeta } from '@portal/wen-backend-api';
import { NetworkEntity } from '../network.state';

export interface EntityWithNetworkMeta {
  entityId: string;
  networkMetas: NetworkMeta[];
}

export const createNetworkEntityHelper = <TEntity extends EntityWithNetworkMeta = EntityWithNetworkMeta>() => {

  const filterActiveInNetwork = (
    networkMetas: TEntity[], entityIds: string[], activeNetwork: NetworkEntity
  ) => {
    if (!activeNetwork) {
      return [];
    }
    const activeNetworkId = activeNetwork.id;
    const filteredForNetwork = entityIds.filter((id) => {
      const entityMetas = networkMetas.find(metaEntity => metaEntity.entityId === id);
      if (!Array.isArray(entityMetas?.networkMetas)) {
        return false;
      }
      return entityMetas.networkMetas.find(network => network.id === activeNetworkId);
    });
    return filteredForNetwork;
  };

  return {
    filterActiveInNetwork
  };
};
