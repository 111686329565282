import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideInSlideOutTopAnimation = trigger('slideInSlideOutTop', [
    state(
      'show',
      style({
        transform: 'translateY(0)',
      })
    ),
    state(
      'hide',
      style({
        transform: 'translateY(-100%)',
      })
    ),
    transition('show <=> hide', [animate('500ms ease')]),
  ]);
