import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { Component, ContentChild, HostBinding, Input, OnInit } from '@angular/core';
import { WenBreakpointObserver } from '@portal/wen-components';
import { AdaptiveBackgroundContentDirective } from './adaptive-background-directives';

export type AdaptiveBackgroundStyle = 'flat' | 'image';

@Component({
  selector: 'wen-adaptive-background',
  templateUrl: './adaptive-background.component.html',
  styleUrls: ['./adaptive-background.component.scss']
})
export class AdaptiveBackgroundComponent implements OnInit {

  isDesktopStyleDevice = this.breakpointObserver.isDesktopStyleDevice;

  @HostBinding('class.wen-adaptive-background-flat') isFlatClassname = false;
  @HostBinding('class.wen-adaptive-background-desktop-mode') isDesktopClassname = this.isDesktopStyleDevice;

  @ContentChild(AdaptiveBackgroundContentDirective) projectedContent: AdaptiveBackgroundContentDirective;

  @Input()
  set backgroundStyle(newValue: AdaptiveBackgroundStyle) {
    this.isFlatClassname = newValue === 'flat' ? true : false;
  }

  desktopWidthPx: string;

  @Input()
  set desktopWidth(newValue: any) {
    this.desktopWidthPx = coerceCssPixelValue(newValue);
  }

  constructor(
    private breakpointObserver: WenBreakpointObserver,
  ) { }

  ngOnInit(): void {
  }

}
