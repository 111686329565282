import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdaptiveBackgroundContentDirective } from './adaptive-background-directives';
import { AdaptiveBackgroundComponent } from './adaptive-background.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AdaptiveBackgroundComponent,
    AdaptiveBackgroundContentDirective
  ],
  exports: [
    AdaptiveBackgroundComponent,
    AdaptiveBackgroundContentDirective
  ]
})
export class AdaptiveBackgroundModule { }
