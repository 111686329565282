import { EntityState } from '@ngrx/entity';

export type IdKeyType = string | number;

export type WithId = {
  id: string;
};

export type WithMessageProps = {
  new?: boolean;
};

export const findEntityById = <TEntity>(
  entityId: IdKeyType,
  entityState: EntityState<TEntity>
) => {
  const entityById = entityState.entities[entityId];
  return entityById;
};
