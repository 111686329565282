import { Injectable } from '@angular/core';
import { first } from 'rxjs';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { selectMessageDetailById } from '../../../../core/store/channel/channel.selectors';
import { EmbeddedDocument } from '../../../../core/store/channel/channel.state';
import { upsertChannelMessageOriginTransferData } from '../../../../core/store/ui/ui.actions';
import { ChannelMessageBoxActionsHandler } from './channel-message-box-actions-handler';

@Injectable()
export class ChannelViewMessageBoxActionsHandler extends ChannelMessageBoxActionsHandler {

  override handleCommentsClicked(messageId: string): void {
    this.getMessageById(messageId).subscribe((message) => {
      const { channelId, timestamp } = message;
      this.store.dispatch(upsertChannelMessageOriginTransferData({
        newState: { channelId, messageId, messageTimestamp: timestamp }
      }));
      super.handleCommentsClicked(messageId);
    });
  }

  override handleDocumentClicked(messageId: string, documentModel: EmbeddedDocument): void {
    this.getMessageById(messageId).subscribe((message) => {
      const { channelId, timestamp } = message;
      const { articleId, docId } = documentModel;
      if (articleId || docId) {
        this.store.dispatch(upsertChannelMessageOriginTransferData({
          newState: { channelId, messageId, messageTimestamp: timestamp }
        }));
      }
      super.handleDocumentClicked(messageId, documentModel);
    });
  }

  override handleSenderClicked(messageId: string, authorId: string): void {
    this.getMessageById(messageId).subscribe((message) => {
      const { channelId, timestamp } = message;
      this.store.dispatch(upsertChannelMessageOriginTransferData({
        newState: { channelId, messageId, messageTimestamp: timestamp }
      }));
      super.handleSenderClicked(messageId, authorId);
    });
  }

  override handleForwardClicked(messageId: string): void {
    this.getMessageById(messageId).subscribe((message) => {
      const { channelId, timestamp } = message;
      this.store.dispatch(upsertChannelMessageOriginTransferData({
        newState: { channelId, messageId, messageTimestamp: timestamp }
      }));
      super.handleForwardClicked(messageId);
    });
  }

  private getMessageById(messageId: string) {
    return this.store.pipe(
      selectorWithParam(selectMessageDetailById, messageId),
      first()
    );
  }

}
