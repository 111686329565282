import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { HeaderModule } from '../../../frame/components/header/header.module';
import { SharedModule } from '../../../shared/shared.module';
import { EmailChangeFormComponent } from './components/email-change-form/email-change-form.component';
import { EmailSettingsComponent } from './components/email-settings/email-settings.component';

@NgModule({
  imports: [
    SharedModule,
    ScrollingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    HeaderModule,
    PortalFieldModule,
  ],
  declarations: [
    EmailSettingsComponent,
    EmailChangeFormComponent
  ],
  exports: [
    EmailSettingsComponent,
    EmailChangeFormComponent
  ]
})
export class EmailSettingsModule { }
