import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { WenBreakpointObserver } from '@portal/wen-components';
import { of } from 'rxjs';
import { catchError, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { WenRouteId } from '../../frame/routing/types';
import { SD_WEB_API_BACKEND_TYPE_QUERY_PARAM, SD_WEB_API_HOST_QUERY_PARAM } from '../services/navigation/query-params';
import { WenNavigationHelper } from '../services/navigation/types';
import { CRMBackendType } from '../services/smartdesign/helpers/evaluate-backend-type';
import { SmartDesignApi } from '../services/smartdesign/smartdesing-api';
import { PermissionLevel } from '../services/user-management/permission-level';
import { WenOAuthService } from '../services/user-management/wen-oauth.service';
import { RootState } from '../store/root/public-api';
import { loadImportedSelectionData, setCurrentCrmRootRoute, updateSmartDesignState } from '../store/smartdesign/smartdesign.actions';

export const crmIntegrationGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(CrmIntegrationGuard).canActivate(route, state);
};

@Injectable()
export class CrmIntegrationGuard {

  firstRun = true;

  constructor(
    private store: Store<RootState>,
    private smartDesignApi: SmartDesignApi,
    private navigationHelper: WenNavigationHelper,
    private breakpointObserver: WenBreakpointObserver,
    private authService: WenOAuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.firstRun) {
      const sdHostParam = route.queryParamMap.get(SD_WEB_API_HOST_QUERY_PARAM) || null;
      const sdBackendTypeParam = route.queryParamMap.get(SD_WEB_API_BACKEND_TYPE_QUERY_PARAM) as CRMBackendType || null;
      this.store.dispatch(updateSmartDesignState({
        newState: {
          isFromSmartDesign: true,
          sdHostParam,
          backendType: sdBackendTypeParam
        }
      }));
    }
    return of(this.authService.getUserData()).pipe(
      map(userData => {
        if (userData.permissionLevel === PermissionLevel.ANONYMOUS) {
          this.navigationHelper.navigateToPopupSignIn(state.url);
          return false;
        }
        return true;
      }),
      switchMap((result) => {
        const isCrmRoot = route.data.isCrmRoot;
        if (result && this.breakpointObserver.isDesktopStyleDevice && isCrmRoot) {
          this.store.dispatch(setCurrentCrmRootRoute({ routeId: route.data.routeId }));
          if (route.data.routeId === WenRouteId.CRM_ACTION_SELECTOR) {
            this.navigationHelper.navigateToCrmActionSelector();
            return of(false);
          } else if (route.data.routeId === WenRouteId.CRM_DISTRIBUTE_CHAT) {
            this.navigationHelper.navigateToCrmDistributeChat();
            return of(false);
          }
        }
        return this.smartDesignApi.ensureApiLoaded().pipe(
          first(),
          tap(() => {
            this.store.dispatch(loadImportedSelectionData());
          }),
          map(() => true),
          catchError(() => {
            this.navigationHelper.navigateToCrmHomeBackground();
            return of(false);
          })
        );
      }),
      catchError(() => {
        this.navigationHelper.navigateToPopupSignIn(state.url);
        return of(false);
      }),
      finalize(() => this.firstRun = false)
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

}

