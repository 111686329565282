
import { EntityState, Update } from '@ngrx/entity';
import { DraftMessageEntity } from '../../common/models';
import { channelAdapter, ChannelEntity } from '../channel.state';
import { findChannelById } from './state-adapter-utils';

export const channelDraftMessagesHelper = {

  upsertOne: (
    message: DraftMessageEntity,
    channelEntities: EntityState<ChannelEntity>
  ): EntityState<ChannelEntity> => {
    let newChannels = channelEntities;
    const targetChannel = findChannelById(message.contextId, newChannels);
    if (!targetChannel) {
      return newChannels;
    }

    const updates: Update<ChannelEntity> = {
      id: message.contextId,
      changes: {
        draftMessage: { ...targetChannel.draftMessage, ...message }
      }
    };
    newChannels = channelAdapter.updateOne(updates, channelEntities);
    return newChannels;
  },

  removeOne: (
    channelId: string,
    channelEntities: EntityState<ChannelEntity>
  ): EntityState<ChannelEntity> => {
    let newChannels = channelEntities;

    const updates: Update<ChannelEntity> = {
      id: channelId,
      changes: {
        draftMessage: null
      }
    };
    newChannels = channelAdapter.updateOne(updates, channelEntities);
    return newChannels;
  }

};
