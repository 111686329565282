import { Injectable } from '@angular/core';
import { RoomType, SimpleChatDTO, SocketIoService } from '@portal/wen-backend-api';
import { Observable, map, switchMap } from 'rxjs';
import { UserSearchListItem } from '../../../../shared/components/user-search-list/user-search-list.types';

@Injectable()
export class UserSearchService {

  constructor(
    private socketIoService: SocketIoService,
  ) { }

  private searchForRooms(searchTerm: string): Observable<SimpleChatDTO[]> {
    return this.socketIoService.chat.search.acknowledgement$({ search: searchTerm, lastMessage: '' }).pipe(
      map(result => result.chats)
    );
  }

  searchForUsers(searchTerm: string): Observable<UserSearchListItem[]> {
    return this.socketIoService.user.search.acknowledgement$({ search: searchTerm });
  }

  searchForUsersWithRoomDuplicateFiltering(searchTerm: string): Observable<UserSearchListItem[]> {
    return this.searchForRooms(searchTerm).pipe(
      map(rooms => rooms.filter(room => room.type === RoomType.DIALOG).map(room => room.userId)),
      switchMap(userIds => this.socketIoService.user.search.acknowledgement$({ search: searchTerm }).pipe(
        map(users => users.filter(user => !userIds.includes(user.userId)))
      ))
    );
  }
}
