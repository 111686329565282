import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../core/store/root/public-api';
import { WenSnackbarOpener } from '@portal/wen-components';
import { TranslateService } from '@ngx-translate/core';
import { togglePinChannel } from '../../../core/store/channel/channel.actions';

@Injectable()
export class PinChannelService {

  constructor(
    private readonly store: Store<RootState>,
    private readonly wenSnackbarOpener: WenSnackbarOpener,
    private readonly translateService: TranslateService
  ) { }

  public setPinForChannel(channelId: string, isPinned: boolean, withNotification: boolean = false) {
    this.store.dispatch(togglePinChannel({ channelId, isPinned }));
    if (withNotification) {
      const notificationText = this.translateService.instant(isPinned ? 'CHANNEL_PIN_NOTIFICATION' : 'CHANNEL_UNPIN_NOTIFICATION');
      const notificationIcon = isPinned ? 'pin' : 'unpin';
      this.wenSnackbarOpener.openNotificationSnackbar({ notificationIcon, notificationText });
    }
  }

}
