import { Injectable } from '@angular/core';
import { Observable, map, of, switchMap } from 'rxjs';
import { MassDataContainer } from '../mass-data-container';
import { ExtractEntityType } from '../types';
import { MassDataPageResponse, MassDataRepository, PageRequestDescriptor } from './mass-data-repository';

type CurrentRepositoryInfo = {
  index: number;
  pagingStartOffset: number;
};

const initialRepositoryInfo: CurrentRepositoryInfo = {
  index: 0,
  pagingStartOffset: 0
};

@Injectable()
export abstract class MultitronicMassDataRepository<
  C extends MassDataContainer<any>,
  E extends ExtractEntityType<C> = ExtractEntityType<C>
> extends MassDataRepository<C> {

  private currentRepositoryInfo: CurrentRepositoryInfo = initialRepositoryInfo;

  constructor(
    private repositories: MassDataRepository<C>[]
  ) {
    super();
  }

  private rotateRepository(pagingStartOffset: number) {
    this.currentRepositoryInfo = {
      index: this.currentRepositoryInfo.index + 1,
      pagingStartOffset
    };
    return this.repositories[this.currentRepositoryInfo.index];
  }

  private getCurrentRepository() {
    return this.repositories[this.currentRepositoryInfo.index];
  }

  override fetchPage(pagingParams: PageRequestDescriptor) {
    const { offset } = pagingParams.paging;
    if (offset === 0) {
      /**
       * Handle the use-case when the pagination is restarted
       * The sum length of each repository's items are unknow so unfortunately only this use-case can be handled
       *  when the pagination is not incremental eg.: because of reloading the data as whole
       */
      this.currentRepositoryInfo = initialRepositoryInfo;
    }
    return this.fetchPageLoop(pagingParams);
  }

  private fetchPageLoop(pagingParams: PageRequestDescriptor): Observable<MassDataPageResponse<E>> {
    const currentRepo = this.getCurrentRepository();
    const { size: pageSize } = pagingParams.paging;
    if (!currentRepo) {
      return of({ data: [] });
    }
    const calculatedOffsetWithLeftover = Math.max(pagingParams.paging.offset - this.currentRepositoryInfo.pagingStartOffset, 0);
    return currentRepo.fetchPage({
      ...pagingParams,
      paging: {
        ...pagingParams.paging,
        offset: calculatedOffsetWithLeftover
      }
    }).pipe(
      switchMap(result => {
        const dataCount = result.data?.length || 0;
        const leftover = pageSize - dataCount;
        if (leftover > 0) {
          const carryPagingOffset = Math.max(pagingParams.paging.offset + pagingParams.paging.size - leftover, 0);
          this.rotateRepository(carryPagingOffset);
          const leftoverPagingParams = {
            paging: { offset: 0, size: leftover }
          };
          return this.fetchPageLoop(leftoverPagingParams).pipe(
            map((nextData) => {
              return {
                data: [...result.data, ...nextData.data]
              };
            })
          );
        }
        return of(result);
      })
    );
  }

}
