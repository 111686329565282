import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { combineLatest, map } from 'rxjs';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { editChatMessageEvent, editScheduledChatMessageEvent } from '../../../../../core/store/chat/actions/chat-message.actions';
import { RootState } from '../../../../../core/store/root/public-api';
import { MessageBoxModel } from '../../models/message-box.models';
import { EmbeddedMedia } from '../../../../../core/store/channel/channel.state';
import { isMediaPlayable } from '../../../../util/embed-media-util';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { selectCurrentUserData } from '../../../../../core/store/auth/auth.selectors';
import { isForwardEmbed, isMediaEmbed, MessageModificationState, ToRoomEventType } from '@portal/wen-backend-api';

@Injectable()
export class ChatMessageBoxEditAction implements ActionMenuItem {

  label: string;
  icon = 'edit';

  private currentUserId$ = this.store.pipe(
    select(selectCurrentUserData),
    firstExisty(),
    map(({ userId }) => userId),
  );

  constructor(
    private translateService: TranslateService,
    private featureEnablementService: FeatureEnablementService,
    private store: Store<RootState>
  ) {
    this.label = this.translateService.instant('MESSAGE_BOX_CONTEXT_MENU_LABEL_EDIT');
  }

  isVisible$(data: MessageBoxModel) {
    const embeds = data.embeds ?? [];

    const editableMediaEmbeds = embeds
      .filter(embed => isMediaEmbed(embed))
      .every((embed: EmbeddedMedia) => isMediaPlayable(embed) || embed.error) ?? true;

    const noForwardEmbeds = !embeds.some(embed => isForwardEmbed(embed));

    const isScheduled = data?.scheduled;

    return combineLatest([
      this.featureEnablementService.featureConfigObs.enableChatEdit$,
      this.currentUserId$,
    ]).pipe(
      map(([enableChatEdit, currentUserId]) => {
        const isProperMessage = data.state === MessageModificationState.ORIGINAL || data.state === MessageModificationState.EDITED;
        return (
          (enableChatEdit || isScheduled) &&
          currentUserId === data.authorId &&
          data?.eventType !== ToRoomEventType.AUTO_REPLY &&
          isProperMessage &&
          noForwardEmbeds &&
          editableMediaEmbeds
        );
      })
    );
  }

  execute(data: MessageBoxModel) {
    return data.scheduled
      ? this.store.dispatch(editScheduledChatMessageEvent({ eventId: data.messageId }))
      : this.store.dispatch(editChatMessageEvent({eventId: data.messageId}));
  }
}
