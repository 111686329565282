import { NgModule } from '@angular/core';
import { ProgressiveSlotComponent } from './progressive-slot.component';

@NgModule({
  declarations: [
    ProgressiveSlotComponent
  ],
  exports: [
    ProgressiveSlotComponent
  ],
})
export class ProgressiveLoadingModule { }
