<div class="wen-menu-entry-icon">
  <ng-content select="wen-icon[menuEntryIcon]"></ng-content>
</div>
<div class="wen-menu-entry-title" [class.wen-menu-entry-title-ellipsis]="!menuEntryDescription">
  <ng-content select="[menuEntryTitle]"></ng-content>
</div>
<div class="wen-menu-entry-description">
  <ng-content select="[menuEntryDescription]"></ng-content>
</div>
@if (!disableArrow) {
  <div class="wen-menu-entry-action">
    <wen-icon [iconName]="'chevron_right'"></wen-icon>
  </div>
}
