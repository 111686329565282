
import { NgModule } from '@angular/core';
import { ActionMenuModule, ACTION_MENU_ITEMS, ContextMenuComponent, ContextMenuTriggerDirective } from '@portal/wen-components';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelContentProviderListComponent } from './channel-content-provider-list.component';
import { AddContentProviderModule } from './components/add-content-provider/add-content-provider.module';
import { CHANNEL_CONTENT_PROVIDER_ACTION_MENU } from './providers/action-menu/constants';

@NgModule({
  imports: [
    SharedModule,
    AddContentProviderModule,
    ActionMenuModule,
    ContextMenuTriggerDirective,
    ContextMenuComponent
  ],
  providers: [
    { provide: ACTION_MENU_ITEMS, useValue: CHANNEL_CONTENT_PROVIDER_ACTION_MENU, multi: true },
  ],
  exports: [ChannelContentProviderListComponent],
  declarations: [ChannelContentProviderListComponent]
})
export class ChannelContentProviderListModule { }
