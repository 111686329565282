@if (data$ | async; as data) {
  @if (contactButtonProps$ | async; as contactButtonProps) {
    <wen-button class="wen-button-rounded wen-button-animated"
      [wenVariant]="contactButtonProps.wenVariant" [iconName]="contactButtonProps.iconName" iconSize="huge"
      (click)="contactButtonProps.action && handleAction($event, contactButtonProps.action, data); contactButtonProps.contextMenu && openContextMenu(contactButtonProps, data)">
      @if (!iconOnly) {
        {{ contactButtonProps.translationKey | translate }}
      }
    </wen-button>
  }
}