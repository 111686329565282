export enum PagingReplayDirection {
  Up = 'Up',
  Down = 'Down'
}

export type PagingHistoryFlags = {
  hasMoreOlder?: boolean;
  hasMoreNewer?: boolean;
};

export type RelevantMessagesHistoryFlags = {
  moreDown: boolean;
  moreUp: boolean;
  /**
   * @deprecated
   */
  direction?: PagingReplayDirection;
  /**
   * @deprecated
   */
  more?: boolean;
};

export type PageResult = {
  hasResult: boolean;
};

export interface Paging extends Partial<{ filter: string }> {
  first: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
}

export interface WithOffsetBasedPaging {
  paging: OffsetBasedPagingParams;
}

export interface OffsetBasedPagingParams {
  offset: number;
  size: number;
}
