import { CdkScrollable } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuComponent, ContextMenuTriggerDirective, WeatherModule, WenIconModule } from '@portal/wen-components';
import { DetailedForecastComponent } from './components/weather-context-menu/components/detailed-forecast/detailed-forecast.component';
import { WeatherContextMenuComponent } from './components/weather-context-menu/weather-context-menu.component';
import { EmbedWeatherComponent } from './embed-weather.component';


@NgModule({
  imports: [
    CommonModule,
    CdkScrollable,
    ContextMenuTriggerDirective,
    ContextMenuComponent,
    TranslateModule.forChild(),
    WeatherModule,
    WenIconModule
  ],
  declarations: [EmbedWeatherComponent, WeatherContextMenuComponent, DetailedForecastComponent],
  exports: [EmbedWeatherComponent]
})
export class EmbedWeatherModule { }
