import { NgModule } from '@angular/core';
import { ChannelDeepLinkable } from './deep-linkables/channel-deep-linkable';
import { DEEP_LINKABLES, DeepLinkableUtils } from './deep-linkable';
import { EventDeepLinkable } from './deep-linkables/event-deep-linkable';
import { MessageDeepLinkable } from './deep-linkables/message-deep-linkable';
import { DeepLinkNavigator } from './deep-link-navigator';
import { TextViewDeepLinkable } from './deep-linkables/text-view-deep-linkable';

@NgModule({
  providers: [
    DeepLinkableUtils,
    ChannelDeepLinkable,
    EventDeepLinkable,
    MessageDeepLinkable,
    TextViewDeepLinkable,
    DeepLinkNavigator,
    {
      provide: DEEP_LINKABLES,
      useExisting: ChannelDeepLinkable,
      multi: true
    },
    {
      provide: DEEP_LINKABLES,
      useExisting: EventDeepLinkable,
      multi: true
    },
    {
      provide: DEEP_LINKABLES,
      useExisting: MessageDeepLinkable,
      multi: true
    },
    {
      provide: DEEP_LINKABLES,
      useExisting: TextViewDeepLinkable,
      multi: true
    },
  ]
})
export class DeepLinkModule { }
