import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { cleanUpFileEmbedCacheAfterFullyProcessed, updateChatMedia, updateChatMessageMediaByStatus } from '../chat.actions';

@Injectable()
export class MessageUpdateEffects {

  updateChatMediaForRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateChatMedia),
      switchMap(({ status }) => {
        const actions: Action[] = [];
        if (status.mediaProcessed) {
          actions.push(cleanUpFileEmbedCacheAfterFullyProcessed({ status }));
        }
        actions.push(updateChatMessageMediaByStatus({ status }));
        return actions;
      })
    );
  });

  constructor(
    private actions$: Actions,
  ) { }

}
