import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { DateUtil } from '../../core/common/date/date-util';

@Pipe({
  name: 'eventDate',
})
export class EventDatePipe implements PipeTransform {

  transform(startDate: string, endDate?: string): string {
    if (!startDate) {
      return null;
    }

    const [start, end] = [startDate, endDate].map((d) => this.toDateTime(d));

    const hideYear = DateUtil.isCurrentYear(start) && (!end || DateUtil.isCurrentYear(end));

    let displayableDate = this.formatFullDate(start, hideYear);
    if (end && !end.hasSame(start, 'minute')) {
      const displayableEnd = DateUtil.isInSameDay(start, end)
        ? DateUtil.toTimeClientFormat(end)
        : this.formatFullDate(end, hideYear);
      displayableDate = displayableDate + ` - ${displayableEnd}`;
    }

    return displayableDate;
  }

  private toDateTime(dateISO: string) {
    if(DateUtil.isValidISOString(dateISO)) {
      return DateUtil.fromIsoString(dateISO);
    }

    return null;
  }

  private formatFullDate(date: DateTime, hideYear: boolean): string {
    const isDateOnly = DateUtil.isDateOnly(date);
    const formattedDate: string =
      DateUtil.isNearToday(date)
      ? isDateOnly
        ? DateUtil.toRelativeDate(date)
        : DateUtil.toRelativeTime(date)
      : DateUtil.isFromYesterTo6DaysInFuture(date)
        ? isDateOnly
          ? DateUtil.toDayNameOnly(date)
          : DateUtil.toDayNameWithTime(date)
        : isDateOnly
          ? hideYear
            ? DateUtil.toMonthAndDayOnly(date)
            : DateUtil.toDateOnly(date)
          : hideYear
            ? DateUtil.toNoYearDateTime(date)
            : DateUtil.toFullDateAndTime(date);

    return formattedDate;
  }
}
