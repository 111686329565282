@if (channel) {
  @if (isMuted) {
    <button *ifSubscribedTo="channelId" mat-mini-fab color="primary" class="mat-elevation-z0 mute-unmute-button"
      (click)="unmuteChannel($event)">
      <wen-icon class="wen-icon-huge" iconName="mute" color="white"></wen-icon>
    </button>
  } @else {
    <button *ifSubscribedTo="channelId" mat-mini-fab color="primary" class="mat-elevation-z0 mute-unmute-button"
      (click)="muteChannel($event)">
      <wen-icon class="wen-icon-huge" iconName="unmute" color="white"></wen-icon>
    </button>
  }
}