import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { META_REDUCERS } from '@ngrx/store';
import { PersistenceStorage } from './providers/persistence-storage';
import { REHYDRATE_READY_INITIALIZER } from './providers/rehydrate-ready-initializer';
import { PersistenceEffects } from './store/persistence.effects';
import { WEN_ASYNC_PERSISTENCE_META_REDUCER } from './store/persistence.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([PersistenceEffects]),
  ]
})
export class WenStorePersistenceModule {

  static forRoot(): ModuleWithProviders<WenStorePersistenceModule> {
    return {
      ngModule: WenStorePersistenceModule,
      providers: [
        PersistenceStorage,
        {
          provide: META_REDUCERS,
          useFactory: WEN_ASYNC_PERSISTENCE_META_REDUCER,
          multi: true
        },
        REHYDRATE_READY_INITIALIZER,
      ]
    };
  }

}
