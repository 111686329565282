import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { deepEqual } from '@portal/wen-components';
import { Subject, filter, map, takeUntil } from 'rxjs';
import { FilterId } from '../../../../../core/common/types/filter-id';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { fetchDiscoveryEventsNextPage, reloadEventDateFilters } from '../../../../../core/store/events/event.actions';
import { Filter, FilterEntityType } from '../../../../../core/store/filter/models/filter';
import { WenRouteId } from '../../../../../frame/routing/types';
import { Filterable, hasCustomLabel } from '../../../../../shared/components/filter-view/models/filter-item';
import { LocationFormatter } from '../../../../../shared/pipes/location-formatter.service';
import { GeoFilterValueModel } from '../../../../channel/channel-view/components/channel-view/filter-sources/geo-location-filter-datasource';
import { EventListDataSource } from '../../providers/event-list-datasource';
import { EventCategoryFilterDataSource } from './filter-sources/event-category-filter-data-source';
import { EventDateFilterDataSource } from './filter-sources/event-date-filter-data-source';
import { EventGeoLocationFilterDataSource } from './filter-sources/event-geo-filter-data-source';

@Component({
  selector: 'wen-discovery-event-list',
  templateUrl: './discovery-event-list.component.html',
  styleUrls: ['./discovery-event-list.component.scss'],
  providers: [
    EventCategoryFilterDataSource, EventDateFilterDataSource, EventGeoLocationFilterDataSource
  ]
})
export class DiscoveryEventListComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  loadResult$ = this.eventListDataSource.discoveryEventLoadResult$;

  isFilteringEnabled = this.featureEnablementService.featureFlagMethods.isEnableFiltering();

  filterEntityType = FilterEntityType.EVENT_LISTS;
  eventDateControlName = FilterId.EVENT_DATES;
  eventCategoryControlName = FilterId.EVENT_CATEGORIES;
  eventGeoLocationControlName = FilterId.EVENT_GEO_LOCATION;
  filterGroup = new FormGroup({
    [this.eventCategoryControlName]: new FormControl(),
    [this.eventDateControlName]: new FormControl(),
    [this.eventGeoLocationControlName]: new FormControl(),
  });
  get eventCategoryControl() {
    return this.filterGroup.controls[this.eventCategoryControlName];
  }
  get eventDateControl() {
    return this.filterGroup.controls[this.eventDateControlName];
  }
  get eventGeoLocationControl() {
    return this.filterGroup.controls[this.eventGeoLocationControlName];
  }

  constructor(
    private eventListDataSource: EventListDataSource,
    private appNavigator: AppNavigator,
    private store: Store,
    private featureEnablementService: FeatureEnablementService,
    public categoryFilterService: EventCategoryFilterDataSource,
    public dateFilterService: EventDateFilterDataSource,
    public geoLocationFilterService: EventGeoLocationFilterDataSource,
    private locationFormatter: LocationFormatter
  ) { }

  ngOnInit(): void {
    this.categoryFilterService.currentActiveEventCategoryFilter$.subscribe((activeEventCategoryFilter: Filter) => {
      this.eventCategoryControl.setValue(activeEventCategoryFilter?.filterValue);
    });

    this.dateFilterService.currentActiveEventDateFilter$.subscribe((activeEventDateFilter: Filter) => {
      this.eventDateControl.setValue(activeEventDateFilter?.filterValue);
    });

    this.geoLocationFilterService.currentActiveEventGeoLocationFilter$.subscribe((activeEventGeoFilter: Filter) => {
      this.eventGeoLocationControl.setValue(activeEventGeoFilter?.filterValue);
    });
    // TODO: Remove manual re-load of event dates when proper react native notification arrives about the app state
    this.store.dispatch(reloadEventDateFilters());
  }

  endReached() {
    this.store.dispatch(fetchDiscoveryEventsNextPage());
  }

  navigateToEventList() {
    this.appNavigator.navigateToRoute(WenRouteId.EVENT_LIST);
  }

  openEventCategoryFilterSelector() {
    const activeCategory: Filterable = this.eventCategoryControl.value;
    this.categoryFilterService.openFilterSelector(activeCategory).subscribe((newValue) => {
      this.eventCategoryControl.setValue(newValue);
    });
  }

  openEventDateFilterSelector() {
    const activeDate: Filterable = this.eventDateControl.value;
    this.dateFilterService.openFilterSelector(activeDate).subscribe((newValue) => {
      const controlValue = { ...newValue };
      if (hasCustomLabel(controlValue)) {
        controlValue.name = controlValue.customLabel(newValue.data);
      }
      this.eventDateControl.setValue(controlValue);
    });
  }

  openGeoLocationFilterSelector() {
    const activeGeoFilter: GeoFilterValueModel = this.eventGeoLocationControl.value;
    this.geoLocationFilterService.openFilterSelector(activeGeoFilter).pipe(
      map((dialogResult) => dialogResult?.selectedGeoFilter),
      filter((newValue) => {
        const newDistance = newValue?.distance;
        const oldDistance = activeGeoFilter?.data?.distance;
        const newGeo = newValue?.geoJson?.geometry?.coordinates;
        const oldGeo = activeGeoFilter?.data?.geoJson.geometry?.coordinates;
        if (!newGeo && !oldGeo) {
          return false;
        }
        return newDistance !== oldDistance || !deepEqual(newGeo, oldGeo);
      }),
      takeUntil(this.onDestroy$)
    ).subscribe((geoLocation) => {
      const geoFilterValue: GeoFilterValueModel = {
        id: null,
        name: this.locationFormatter.format(geoLocation.geoJson) as string,
        data: geoLocation
      };
      this.eventGeoLocationControl.setValue(geoFilterValue);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
