import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { EmbeddedAppOauthService } from '../../core/services/user-management/embedded-app-oauth-service';
import { EmbeddedApiAuthEffects } from '../../core/store/embedded-api/effects/embedded-api-auth.effects';
import { AuthApiPart } from './api-parts/auth/auth-api';
import { EmbeddedAppNavigator } from './api-parts/navigation/embedded-app-navigator';
import { NavigationApiPart } from './api-parts/navigation/navigation-api';
import { EmbeddedOauthWrapper } from './providers';
import { EmbeddedAppContext } from './api-parts/state/embedded-app-context';

@NgModule({
  imports: [
    EffectsModule.forFeature([EmbeddedApiAuthEffects])
  ]
})
export class WenEmbeddedApiModule {

  static forRoot(): ModuleWithProviders<WenEmbeddedApiModule> {
    return {
      ngModule: WenEmbeddedApiModule,
      providers: [
        EmbeddedAppContext,
        EmbeddedOauthWrapper,
        EmbeddedAppOauthService,
        AuthApiPart,
        EmbeddedAppNavigator,
        NavigationApiPart,
      ],
    };
  }

}
