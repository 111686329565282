import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OutletHelper } from '../../../../../core/services/navigation/outlet-specific/outlet-helper';
import { WenRouteId } from '../../../../routing/types';

export enum CommandButtonPosition {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export interface CommandButtonEnablementEvent {
  buttonPosition: CommandButtonPosition;
  rootOutletId: WenRouteId;
  isDisabled: boolean;
}

@Injectable()
export class AppHeaderController {

  private enablementEvents = new Subject<CommandButtonEnablementEvent>();
  readonly enablementEvents$ = this.enablementEvents.asObservable();

  constructor(
    private outletHelper: OutletHelper
  ) { }

  setRightCommandEnablement(isEnabled: boolean) {
    const rootOutletId = this.outletHelper.resolveClosestId();
    this.enablementEvents.next({ buttonPosition: CommandButtonPosition.RIGHT, rootOutletId, isDisabled: !isEnabled });
  }

  setLeftCommandEnablement(isEnabled: boolean) {
    const rootOutletId = this.outletHelper.resolveClosestId();
    this.enablementEvents.next({ buttonPosition: CommandButtonPosition.LEFT, rootOutletId, isDisabled: !isEnabled });
  }

}
