<wen-confirmation-dialog>
  <div wen-confirmation-dialog-custom-content>
    {{ "DATA_PROTECTION_DIALOG_TEXT.0" | translate }}
    <a [href]="termsOfUseLink" (click)="onLinkClicked($event, termsOfUseLink)">{{ "DATA_PROTECTION_DIALOG_TEXT.1" |
      translate }}</a>
    {{ "DATA_PROTECTION_DIALOG_TEXT.2" | translate }}
    <a [href]="privacyPolicyLink" (click)="onLinkClicked($event, privacyPolicyLink)">{{ "DATA_PROTECTION_DIALOG_TEXT.3"
      | translate }}</a>
    {{ "DATA_PROTECTION_DIALOG_TEXT.4" | translate }}
  </div>
</wen-confirmation-dialog>