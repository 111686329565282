import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { WenTestIdModule } from '../../directives/test-id/test-id.module';
import { MarkdownTextModule } from '../markdown-text/markdown-text.module';
import { ConfirmationDialogCustomContentDirective, ConfirmationDialogOkActionDirective } from './confirmation-dialog-component.directives';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@NgModule({
  imports: [
    MatDialogModule,
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MarkdownTextModule,
    WenTestIdModule
  ],
  declarations: [
    ConfirmationDialogComponent,
    ConfirmationDialogCustomContentDirective,
    ConfirmationDialogOkActionDirective,
  ],
  exports: [
    ConfirmationDialogComponent,
    ConfirmationDialogCustomContentDirective,
    ConfirmationDialogOkActionDirective,
  ]
})
export class ConfirmationDialogModule { }
