import { FormControl, Validators } from '@angular/forms';
import { updateableValidator } from '@portal/wen-components';

export const VERIFICATION_SUBMIT_TIMER_SEC = 30;
export const VERIFICATION_CODE_LENGTH = 6;

// TODO: find a way to remove this from global scope...
export const resendValidator = updateableValidator('verifyCooldown');

export const createCodeVerificationControl = () => {
  return new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
    resendValidator.validator
  ]);
};
