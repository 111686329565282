import { Injectable, inject } from '@angular/core';
import { Observable, defaultIfEmpty, forkJoin } from 'rxjs';
import { generateId } from '../../../../../common/generate-id';
import { EmbedDTOType, EmbeddedMediaDTO, getMediaType } from '../../../../../data-types/embed/embed';
import { MediaContext, MediaContextTypes, MediaUseCases } from '../../../../../events/events';
import { EmbedImageUploader } from '../embed-image-uploader';

export class EmbeddedMediaHandler {

  private uploadCache = new Map<File, EmbeddedMediaDTO>();
  private previewUploadCache = new Map<string, EmbeddedMediaDTO>();
  private uploadOps: ((contexts: MediaContext[], useCase: MediaUseCases) => Observable<unknown>)[] = [];

  constructor(
    private imageUploader: EmbedImageUploader
  ) { }

  prepareForPreview(
    embedMedia: EmbeddedMediaDTO
  ) {
    const { file, uploadId, uploaded, contextType } = embedMedia;
    if (uploadId && contextType === MediaContextTypes.PREVIEW) {
      const embedPreview = this.createOrGetCachedUploadIdForPreview(embedMedia);
      return embedPreview;
    } else if (uploaded) {
      return embedMedia;
    } else if (file && !uploadId) {
      const embedPreview = this.createOrGetCachedUploadIdForNewUpload(embedMedia);
      return embedPreview;
    } else {
      return embedMedia;
    }
  }

  persist(
    contexts: MediaContext[],
    useCase: MediaUseCases,
  ): Observable<unknown> {
    const uploads$ = this.uploadOps.map(uploadOp => uploadOp(contexts, useCase));
    this.uploadOps = [];
    return forkJoin(uploads$).pipe(
      defaultIfEmpty([])
    );
  }

  private createOrGetCachedUploadIdForNewUpload(embedMedia: EmbeddedMediaDTO) {
    const { id, type, subType, file } = embedMedia;
    const cacheItem = this.uploadCache.get(file);
    if (!cacheItem) {
      const uploadId = generateId();
      const embedPreview: EmbeddedMediaDTO = {
        id: null,
        uploadId,
        type: EmbedDTOType.MEDIA,
        subType: subType || getMediaType(file),
        uploaded: false,
        playable: false,
        fullyProcessed: false
      };
      const embeddedUploadDTO: EmbeddedMediaDTO = {
        id, type, subType, file, uploadId
      };
      const bindEmbedToContexts = (
        contexts: MediaContext[],
        useCase: MediaUseCases,
      ) => this.imageUploader.createUpload(embeddedUploadDTO, contexts, useCase);
      this.uploadOps.push(bindEmbedToContexts);
      this.uploadCache.set(file, embedPreview);
    }
    return this.uploadCache.get(file);
  }

  private createOrGetCachedUploadIdForPreview(embedMedia: EmbeddedMediaDTO) {
    const { uploadId } = embedMedia;
    const cacheItem = this.previewUploadCache.get(uploadId);
    if (!cacheItem) {
      const upload = (
        contexts: MediaContext[],
        useCase: MediaUseCases,
      ) => this.imageUploader.activatePreview(uploadId, contexts, useCase);
      this.uploadOps.push(upload);
      this.previewUploadCache.set(uploadId, embedMedia);
    }
    return this.previewUploadCache.get(uploadId);
  }

  getUploads() {
    return Array.from(this.uploadCache.entries()).map(([file, embedData]) => {
      return {
        file,
        uploadId: embedData.uploadId
      };
    });
  }

  getPreviewUploads() {
    return Array.from(this.previewUploadCache.keys()).map((uploadId) => {
      return { uploadId };
    });
  }

}

@Injectable()
export class EmbeddedMediaUploaderFactory {

  private imageUploader = inject(EmbedImageUploader);

  get() {
    return new EmbeddedMediaHandler(this.imageUploader);
  }
}
