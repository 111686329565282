import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MegOlmMessage } from '@portal/wen-backend-api';
import { retryEventDecryptionPossible } from '../../../store/chat/key-actions';
import { RootState } from '../../../store/root/public-api';

@Injectable()
export class FailedDecryptionHandler {

  private store = inject(Store<RootState>);

  private failedSessionDecryptionRegistry = new Map<string, MegOlmMessage>();

  registerFailedDecryption(megolmMessage: MegOlmMessage) {
    this.failedSessionDecryptionRegistry.set(megolmMessage.sessionId, megolmMessage);
  }

  unregisterFailedDecryption(megolmSessionId: string) {
    this.failedSessionDecryptionRegistry.delete(megolmSessionId);
  }

  emitRetry(megolmSessionId: string) {
    const existingSession = this.failedSessionDecryptionRegistry.get(megolmSessionId);
    if (existingSession) {
      this.store.dispatch(retryEventDecryptionPossible({ megolmSessionId }));
    }
  }
}
