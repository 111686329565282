import { NgModule } from '@angular/core';
import { IosKeyboardLockScrollHelper } from './ios-keyboard-lock/ios-keyboard-lock-scroll';
import { IosKeyboardLockTargetDirective, IosKeyboardScrollableDirective } from './ios-keyboard-lock/ios-keyboard-lock.directive';
import { IosKeyboardStickyHelper } from './ios-keyboard-sticky/ios-keyboard-sticky-helper';
import { IosKeyboardStickyDirective, IosKeyboardStickyTargetDirective } from './ios-keyboard-sticky/ios-keyboard-sticky.directive';
import { IosSwiperDetectorDirective } from './ios-swipe/ios-swipe-detector.directive';
import { IosViewportFixDirective } from './ios-viewport-fix/ios-viewport-fix.directive';
import { IosSwipeSuppressorDirective } from './ios-swipe/ios-swipe-suppressor.directive';

@NgModule({
  providers: [
    IosKeyboardLockScrollHelper,
    IosKeyboardStickyHelper,
  ],
  declarations: [
    IosKeyboardLockTargetDirective,
    IosKeyboardScrollableDirective,
    IosKeyboardStickyTargetDirective,
    IosKeyboardStickyDirective,
    IosSwiperDetectorDirective,
    IosViewportFixDirective,
    IosSwipeSuppressorDirective,
  ],
  exports: [
    IosKeyboardLockTargetDirective,
    IosKeyboardScrollableDirective,
    IosKeyboardStickyTargetDirective,
    IosKeyboardStickyDirective,
    IosSwiperDetectorDirective,
    IosViewportFixDirective,
    IosSwipeSuppressorDirective
  ]
})
export class IosUtilsModule { }
