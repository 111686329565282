export enum WenNetwork {
  WE_NETWORK = 'WE_NETWORK',
  COKA = 'COKA',
  BIOLAND = 'BIOLAND',
  WEREAD = 'WEREAD',
}

export type WenNetworkConfig = WenNetwork | string;

export const mapNetworkId = (network: WenNetwork | string) => {
  switch (network) {
    case WenNetwork.WE_NETWORK:
      return '4FFA2A98-977D-4BA7-A9CE-B03A5267DB1A';
    case WenNetwork.COKA:
      return 'FD3879BD-71D4-4E63-9CDE-470FBA2D3218';
    case WenNetwork.BIOLAND:
      return '92B2B48A-3DEC-46CC-8600-9DEA99A9C18C';
    case WenNetwork.WEREAD:
      return '1FD9F224-5206-4F02-BEF9-EA731DA27850';
    default:
      if (typeof network === 'string') {
        return network;
      }
      throw new Error('Unsupported network mapping');
  }
};

export const toNetworkId = (network: WenNetworkConfig): string => {
  if (!network) {
    return null;
  }
  return mapNetworkId(network);
};
