import { Directive } from '@angular/core';

@Directive({
  selector: 'wen-confirmation-dialog-custom-content, [wen-confirmation-dialog-custom-content]'
})
export class ConfirmationDialogCustomContentDirective { }

@Directive({
  selector: 'wen-confirmation-dialog-ok-action, [wen-confirmation-dialog-ok-action]'
})
export class ConfirmationDialogOkActionDirective { }
