import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ChannelMessageDTO, InsertUser, SendMessageContent, MessageEvent } from '@portal/wen-backend-api';
import { DateUtil } from '../../common/date/date-util';
import { ChatMessageDecryptionError } from '../../services/chat/decryption/message-decryptor';
import { NativeNotificationRegistrationPayload } from '@portal/wen-native-api';
import { createNestedEntityAdapter } from '../common/entity-state-helpers/nested-entity-adapter';

export interface ChannelMessagesNotificationEntity {
  channelId: string;
  latestMessages: ChannelMessageDTO[];
  unreadCount: number;
}

export type ChatNotificationEventEntity = {
  id: string; // Event id
  originalEvent: MessageEvent;
  decryptedEvent?: SendMessageContent<false>;
  decryptionError?: ChatMessageDecryptionError;
  redacted?: boolean;
  insertUser: InsertUser;
};

export type ChatMessagesNotificationEntity = {
  id: string; // Room id
  lastAckTimestamp: string;
  unreadCount: number;
  messageEvents?: EntityState<ChatNotificationEventEntity>;
};

export interface NotificationState {
  channelNotifications: EntityState<ChannelMessagesNotificationEntity>;
  nativeNotificationRegistrationToken: NativeNotificationRegistrationPayload;
  chatNotifications: EntityState<ChatMessagesNotificationEntity>;
}

export const channelMessageNotificationAdapter = createEntityAdapter<ChannelMessagesNotificationEntity>({
  selectId: (entity) => entity.channelId
});

export const chatMessageNotificationAdapter = createEntityAdapter<ChatMessagesNotificationEntity>({
  selectId: (entity) => entity.id
});

export const chatMessageNotificationEventAdapter = createEntityAdapter<ChatNotificationEventEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (channelMessage1, channelMessage2) => {
    // Sort to have the newest message first
    return DateUtil.compare(channelMessage2.originalEvent.insertTimestamp, channelMessage1.originalEvent.insertTimestamp) * -1;
  },
});

export const chatMessageNotificationStateAdapter = createNestedEntityAdapter({
  rootEntityAdapter: chatMessageNotificationAdapter,
  nestedEntityAdapter: chatMessageNotificationEventAdapter,
  nestedEntityKey: 'messageEvents',
  rootEntityInitializer: (id: string) => {
    const defaultEntity: ChatMessagesNotificationEntity = {
      id,
      messageEvents: chatMessageNotificationEventAdapter.getInitialState(),
      lastAckTimestamp: null,
      unreadCount: 0
    };
    return defaultEntity;
  }
});
