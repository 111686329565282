import { ShareContent, ShareOptions } from '@portal/react-native-api';
import { WenNativeApi } from '@portal/wen-native-api';

export abstract class InviteHandler {
  abstract initInviteFlow();
}

export abstract class NativeInviteHandler extends InviteHandler {
  constructor(protected readonly nativeApi: WenNativeApi) {
    super();
  }

  protected initNativeEmailShare(emailSubject: string, emailContent: string) {
    const shareContent: ShareContent = {
      message: emailContent,
      title: emailSubject
    };
    const shareOptions: ShareOptions = {
      subject: emailSubject
    };
    this.nativeApi.share(shareContent, shareOptions);
  }
}
