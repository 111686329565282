import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { SignInComponent } from '../components/signin/sign-in.component';
import { signInInviteGuardFn } from '../guards/sign-in-invite-guard';


export const signInStartRoute: WenRoute = {
  path: 'start',
  component: SignInComponent,
  data: {
    routeId: WenRouteId.SIGN_IN_START,
    routeTransitionEnabled: true
  }
};

export const signInPopupRoute: WenRoute = {
  path: 'popup',
  component: SignInComponent,
  data: {
    routeId: WenRouteId.SIGN_IN_POPUP,
  }
};

export const signInInviteRoute: WenRoute = {
  path: 'invite',
  component: SignInComponent,
  canActivate: [signInInviteGuardFn],
  data: {
    routeId: WenRouteId.SIGN_IN_INVITE,
  }
};
