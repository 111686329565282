import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { EmbeddedMediaDTO } from '@portal/wen-backend-api';
import { map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { MediaPreviewHandler } from '../../../../../core/services/util/media-preview-handler';
import { FileEventHandlerService } from '../../../../services/file-event-handler.service';
import { MessageSendFieldDataSource } from '../../providers/message-send-field-data-source';

@Component({
  selector: 'wen-media-embed-preview',
  templateUrl: './media-embed-preview.component.html',
  styleUrls: ['./media-embed-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaEmbedPreviewComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  mediaEmbed$: Observable<EmbeddedMediaDTO>;

  constructor(
    private readonly dataSource: MessageSendFieldDataSource,
    private mediaPreviewHandler: MediaPreviewHandler,
    private fileEventHandler: FileEventHandlerService
  ) { }

  ngOnInit(): void {
    this.fileEventHandler.filesChanged().pipe(
      map(fileList => fileList[0]),
      takeUntil(this.onDestroy$)
    ).subscribe(file => {
      this.mediaPreviewHandler.handleMediaPreview(file);
    });

    this.mediaEmbed$ = this.dataSource.draftMessage$.pipe(
      map((message) => message?.fileEmbed),
      shareReplay(1)
    );
  }

  handleDismiss() {
    this.dataSource.updateDraftMessage({ fileEmbed: null });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
