import { Action, createReducer, on } from '@ngrx/store';
import { generateId } from '@portal/wen-backend-api';
import { LoadingState } from '../../common/types/store-loading-state';
import { deepMerge } from '../../common/util/deepmerge';
import { appendDiscoveryListOccurences, appendUserListOccurences, setEventSubscriptions, updateDiscoveryEventsLoadingState, updateDiscoveryEventsPaging, updateDiscoveryListOccurences, updateEventDetails, updateEventSubscription, updateUserEventsLoadingState, updateuserEventsPaging, updateUserEventVisibility, updateUserListOccurences, upsertCategories } from './event.actions';
import { eventAdapter, EventEntity, EventState, occurenceAdapter, OccurenceEntity, subscriptionAdapter } from './event.state';

export const eventInitialState: EventState = {
  events: eventAdapter.getInitialState({}),
  subscriptions: subscriptionAdapter.getInitialState({}),
  discoveryListOccurences: occurenceAdapter.getInitialState({}),
  userListOccurences: occurenceAdapter.getInitialState({}),
  discoverEventsPaging: null,
  userEventsPaging: null,
  userEventsLoading: LoadingState.NOT_LOADED,
  discoverEventsLoading: LoadingState.NOT_LOADED,
  categories: []
};

const eventReducer = createReducer(
  eventInitialState,
  on(updateuserEventsPaging, (state, { userEventsPaging }) => {
    return {
      ...state,
      userEventsPaging
    };
  }),
  on(updateDiscoveryEventsPaging, (state, { discoverEventsPaging }) => {
    return {
      ...state,
      discoverEventsPaging
    };
  }),
  on(updateEventDetails, (state, { events }) => {
    if (events.length <= 0) {
      return state;
    }
    const eventsAsDictionary = events.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr
      };
    }, {});
    const mergedEventDictionary = deepMerge.withOptions({ mergeArrays: false }, state.events.entities, eventsAsDictionary);
    const mergedEventArray = Object.entries(mergedEventDictionary).map(([id, event]) => event);
    return {
      ...state,
      events: eventAdapter.upsertMany(mergedEventArray, state.events)
    };
  }),
  on(setEventSubscriptions, (state, { subscriptions }) => {
    if (subscriptions.length <= 0) {
      return state;
    }
    return {
      ...state,
      subscriptions: subscriptionAdapter.upsertMany(subscriptions, state.subscriptions)
    };
  }),
  on(updateEventSubscription, (state, { id, subscribed }) => {
    let targetSubscriptionCount = state.subscriptions.entities[id]?.subscriptionCount ?? 0;
    const newSubscriptionCount = subscribed ? ++targetSubscriptionCount : --targetSubscriptionCount;
    return {
      ...state,
      subscriptions: subscriptionAdapter.upsertOne({
        id,
        subscribedByUser: subscribed,
        subscriptionCount: newSubscriptionCount
      }, state.subscriptions)
    };
  }),
  on(updateUserEventsLoadingState, (state, { loadingState }) => {
    return {
      ...state,
      userEventsLoading: loadingState
    };
  }),
  on(updateDiscoveryEventsLoadingState, (state, { loadingState }) => {
    return {
      ...state,
      discoverEventsLoading: loadingState
    };
  }),
  on(updateUserEventVisibility, (state, { eventId, subscribed }) => {
    const event: Partial<EventEntity> = state.events.entities[eventId];
    if (subscribed) {
      const occurence = { ...event, id: generateId(), eventId: event.id } as OccurenceEntity;
      return {
        ...state,
        userListOccurences: occurenceAdapter.setOne(occurence, state.userListOccurences)
      };
    } else {
      let occurenceId;
      Object.entries(state.userListOccurences.entities).forEach(([_, value]) => {
        if (value.eventId === eventId) {
          occurenceId = value.id;
        }
      });

      return {
        ...state,
        userListOccurences: occurenceAdapter.removeOne(occurenceId, state.userListOccurences)
      };
    }
  }),
  on(updateDiscoveryListOccurences, (state, { data }) => {
    return {
      ...state,
      discoveryListOccurences: occurenceAdapter.setAll(data, state.discoveryListOccurences)
    };
  }),
  on(appendDiscoveryListOccurences, (state, { data }) => {
    return {
      ...state,
      discoveryListOccurences: occurenceAdapter.upsertMany(data, state.discoveryListOccurences)
    };
  }),
  on(updateUserListOccurences, (state, { data }) => {
    return {
      ...state,
      userListOccurences: occurenceAdapter.setAll(data, state.userListOccurences)
    };
  }),
  on(appendUserListOccurences, (state, { data }) => {
    return {
      ...state,
      userListOccurences: occurenceAdapter.addMany(data, state.userListOccurences)
    };
  }),
  on(upsertCategories, (state, { categories }) => {
    return {
      ...state,
      categories
    };
  })
);

export function eventReducerFactory(state: EventState | undefined, action: Action) {
  return eventReducer(state, action);
}
