@if (quote) {
  <div class="wen-quote-border" [ngStyle]="{'border-color': borderColor }"></div>
  <div class="wen-quote-content-wrapper">
    <div class="wen-quote-content">
      <div portal-caption class="wen-quote-author" [wenTextColorizer]="quote.author">{{ quote.author }}</div>
      <div class="wen-quote-text">
        <div portal-subheading-1 [innerHTML]="quote.text | removeMarkdown"></div>
        @if(!quote.text && quote?.embed){
          <wen-embed-description [embed]="quote.embed"></wen-embed-description>
        }
      </div>
    </div>
    @if(quote?.embed) {
      <div class="wen-quote-after">
        <wen-media-preview [mediaData]="quote.embed" previewSize="media-only"></wen-media-preview>
      </div>
    }
  </div>
}