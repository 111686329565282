import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

enum ScreenMediaBreakPoint {
  Tiny = '320px',
  Small = '640px',
  Standard = '768px',
  Medium = '1023px',
  Large = '1200px'
}

const MediaQueries = {
  isSmallDeviceQuery: () => {
    return `(orientation: portrait) and (max-width: ${ScreenMediaBreakPoint.Small})` +
      ` , (orientation: landscape) and (max-width: ${ScreenMediaBreakPoint.Medium})`;
  },

  isSmallLandscapeDeviceQuery: () => {
    return `(orientation: landscape) and (max-width: ${ScreenMediaBreakPoint.Medium})`;
  }
};

@Injectable()
export class WenBreakpointObserver {

  private orientation = new BehaviorSubject<'portrait' | 'landscape'>('portrait');
  public orientation$ = this.orientation.asObservable();

  orientationChanged$ = this.breakpointObserver.observe([
    '(orientation: portrait)',
    '(orientation: landscape)',
  ]).pipe(
    map((result: BreakpointState) => result.matches),
  );

  readonly isSmallDevice = this.breakpointObserver.isMatched(MediaQueries.isSmallDeviceQuery());
  readonly isSmallLandscapeDevice = this.breakpointObserver.isMatched(MediaQueries.isSmallLandscapeDeviceQuery());

  get isDesktopStyleDevice(): boolean {
    return !this.isSmallDevice;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {

    this.orientation$ = this.orientationChanged$.pipe(
      shareReplay(1),
      map(() => {
        if (this.breakpointObserver.isMatched(MediaQueries.isSmallLandscapeDeviceQuery()) && this.isSmallDevice) {
          return 'landscape';
        } else {
          return 'portrait';
        }
      })
    );
  }
}
