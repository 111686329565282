import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PasswordVisibilityToggleComponent } from './password-visibility-toggle.component';
import { MatButtonModule } from '@angular/material/button';
import { WenIconModule } from '@portal/wen-components';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule,
    MatButtonModule,
  ],
  declarations: [
    PasswordVisibilityToggleComponent,
  ],
  exports: [
    PasswordVisibilityToggleComponent,
  ],
})
export class PasswordVisibilityToggleModule { }
