import { createFeature, createReducer, on } from '@ngrx/store';
import { DateUtil } from '../../common/date/date-util';
import { LoadingState } from '../../common/types/store-loading-state';
import { clearRoomMessagesHistory, replaceChatMessagesHistory, updateRoomMessagesHistory, upsertRoomMessagesHistory } from './actions/chat-history.actions';
import { updateMemberState, upsertNewMemberJoined, upsertOtherRoomMemberLeft } from './actions/chat-member.actions';
import { findRoomById } from './chat-state-adapters/detail-util';
import { addDraftChatMember, addDraftChatRoom, chatInitializationSuccess, cleanUpDraftChat, cleanUpDraftChatMessage, cleanUpFileEmbedCacheAfterFullyProcessed, clearChatMessageNewState, initFileEmbedCacheForRoom, invalidateFetchState, removeDraftChatMember, removeDraftChatMessage, removeDraftChatRoom, removePendingChatMessage, roomRemoved, storeChatMessage, storePendingChatMessage, updateAutoReadAcknowledgeRoomId, updateChatDraftMessage, updateChatMessageAcknowledgeStatusInRoom, updateChatMessageMediaByEmbedData, updateChatMessageMediaByStatus, updateDraftChatMessage, updatePendingChatMessageFileAttachmentLoadState, updateRoomData, updateRoomMuteState, updateUserChatListLoadingState, upsertChatFetched, upsertChatRoom, upsertChatRoomMembers, upsertChatRooms } from './chat.actions';
import { ChatMessageEntity, ChatRoomEntity, ChatState, chatHistoryStateAdapter, chatMessageStateAdapter, chatRoomsEntityAdapter, chatSearchListItemEntityAdapter, pendingChatMessageStateAdapter, stateWithHistoryAdapter, stateWithMessagesAdapter, userSearchListItemEntityAdapter } from './chat.state';
import { chatFeatureKey } from './constants';
import { removeShareInboundGroupSessionForCurrentRoom, requestShareInboundGroupSessionForCurrentRoomSuccess } from './key-actions';
import { chatScheduledMessageReducers } from './reducers/chat-scheduled-messages.reducers';
import { cleanupFileEmbedCacheForRoom, toChatRoomEntity, updateMessageMediaEmbed, updateMessageMediaEmbedByUploadStatus } from './utils/chat-room-utils';
import { isMediaEmbed } from '@portal/wen-backend-api';

export const chatInitialState: ChatState = {
  chatInitialized: false,
  rooms: chatRoomsEntityAdapter.getInitialState(),
  sharedInboundGroupSessions: [],
  currentAutoReadAcknowledgeRoomId: null,
  userChatListLoadingState: LoadingState.NOT_LOADED,
  draft: {
    users: userSearchListItemEntityAdapter.getInitialState({}),
    rooms: chatSearchListItemEntityAdapter.getInitialState({}),
    message: null
  }
};

export const chatFeature = createFeature({
  name: chatFeatureKey,
  reducer: createReducer(
    chatInitialState,
    on(chatInitializationSuccess, (state) => {
      return { ...state, chatInitialized: true };
    }),
    on(upsertChatFetched, (state, { room, members }) => {
      const roomEntity: ChatRoomEntity = {
        ...toChatRoomEntity(room),
        loadingState: LoadingState.LOADED,
        members
      };
      const newEntity = chatRoomsEntityAdapter.upsertOne(roomEntity, state.rooms);
      return { ...state, rooms: newEntity };
    }),
    on(upsertChatRoom, (state, { room }) => {
      const roomEntity: ChatRoomEntity = toChatRoomEntity(room);
      const newEntities = chatRoomsEntityAdapter.upsertOne(roomEntity, state.rooms);
      return { ...state, rooms: newEntities };
    }),
    on(invalidateFetchState, (state) => {
      const updatedRooms = Object.keys(state.rooms.entities).reduce<Record<string, ChatRoomEntity>>((rooms, key) => {
        rooms[key] = {
          ...state.rooms.entities[key],
          loadingState: LoadingState.NOT_LOADED
        };
        return rooms;
      }, {});
      return {
        ...state,
        rooms: {
          ...state.rooms,
          entities: updatedRooms
        }
      };
    }),
    on(upsertChatRooms, (state, { rooms }) => {
      const roomEntities: ChatRoomEntity[] = rooms.map(room => toChatRoomEntity(room));
      const newEntities = chatRoomsEntityAdapter.upsertMany(roomEntities, state.rooms);
      return { ...state, rooms: newEntities };
    }),
    on(upsertChatRoomMembers, (state, { members, roomId }) => {
      const room = findRoomById(roomId, state.rooms);

      const updatedRooms = chatRoomsEntityAdapter.upsertOne({ ...room, members }, state.rooms);
      return { ...state, rooms: updatedRooms };
    }),
    on(upsertOtherRoomMemberLeft, (state, { userId, roomId }) => {
      const room = findRoomById(roomId, state.rooms);
      const updatedMembers = room.members.filter(member => member.userId !== userId);
      const updatedRooms = chatRoomsEntityAdapter.upsertOne({ ...room, members: updatedMembers }, state.rooms);
      return {
        ...state,
        rooms: updatedRooms
      };
    }),
    on(upsertNewMemberJoined, (state, { newUser, roomId}) => {
      const room = findRoomById(roomId, state.rooms);
      if (!room) {
        return state;
      }
      const updatedMembers = [ ...room.members, newUser ];
      const updateRooms = chatRoomsEntityAdapter.upsertOne({ ...room, members: updatedMembers}, state.rooms);
      return {
        ...state,
        rooms: updateRooms
      };
    }),
    on(updateRoomData, (state, {roomId, changedProperties}) => {
      const room = findRoomById(roomId, state.rooms);

      const updatedDetails = {
        ...room.details,
        ...changedProperties
      };
      const updatedRooms = chatRoomsEntityAdapter.upsertOne({...room, details: updatedDetails}, state.rooms);
      return {
        ...state,
        rooms: updatedRooms
      };
    }),
    on(upsertRoomMessagesHistory, (state, { roomId, messages, flags }) => {
      const entityState = state.rooms;
      const newRooms = stateWithHistoryAdapter.upsertMany(messages, roomId, entityState, flags);
      return {
        ...state,
        rooms: newRooms
      };
    }),
    on(updateRoomMessagesHistory, (state, { roomId, messages, flags }) => {
      const entityState = state.rooms;
      const newRoomsHistoryUpdated = stateWithHistoryAdapter.updateMany(messages, roomId, entityState, flags);
      const newRoomsMessagesUpdated = chatMessageStateAdapter.updateMany(roomId, messages, newRoomsHistoryUpdated, flags);
      return {
        ...state,
        rooms: newRoomsMessagesUpdated
      };
    }),
    on(replaceChatMessagesHistory, (state, { roomId, messages, flags }) => {
      const clearedRooms = stateWithHistoryAdapter.clearHistory(roomId, state.rooms);
      const newRooms = stateWithHistoryAdapter.upsertMany(messages, roomId, clearedRooms, flags);
      return {
        ...state,
        rooms: newRooms
      };
    }),
    on(storeChatMessage, (state, { roomId, message }) => {
      const newEntities = stateWithMessagesAdapter.upsertMany(roomId, [message], state.rooms);
      return { ...state, rooms: newEntities };
    }),
    on(updateUserChatListLoadingState, (state, { loadingState }) => {
      return {
        ...state,
        userChatListLoadingState: loadingState
      };
    }),
    on(updateRoomMuteState, (state, { isMuted, roomId }) => {
      const roomEntity = findRoomById(roomId, state.rooms);
      const updatedDetails = { ...roomEntity.details, isMuted };
      return {
        ...state,
        rooms: chatRoomsEntityAdapter.upsertOne({ ...roomEntity, details: updatedDetails }, state.rooms)
      };
    }),
    on(updateChatMessageAcknowledgeStatusInRoom, (state, { roomId, lastReceivedTimestamp, lastReadTimestamp }) => {
      let newRoom: ChatRoomEntity = state.rooms.entities[roomId];
      if (!newRoom) {
        return state;
      }

      let receivedTs = newRoom.lastReceivedTimestamp || lastReceivedTimestamp;
      let readTs = newRoom.lastReadTimestamp || lastReadTimestamp;

      const isNewerReceived = DateUtil.compareNullSafe(newRoom.lastReceivedTimestamp, lastReceivedTimestamp);
      if (isNewerReceived > 0) {
        receivedTs = lastReceivedTimestamp;
      }
      const isNewerRead = DateUtil.compareNullSafe(newRoom.lastReadTimestamp, lastReadTimestamp);
      if (isNewerRead > 0) {
        readTs = lastReadTimestamp;
      }
      const shouldCorrectReceived = DateUtil.compare(receivedTs, readTs) > 0;
      if (shouldCorrectReceived) {
        // Use the read timestamp if the read is newer to make sure the read if at least the received timestamp
        receivedTs = readTs;
      }
      if (!isNewerReceived && !isNewerRead && !shouldCorrectReceived) {
        return state;
      }

      newRoom = { ...newRoom, lastReadTimestamp: readTs, lastReceivedTimestamp: receivedTs };
      const newRooms = chatRoomsEntityAdapter.upsertOne(newRoom, state.rooms);
      return { ...state, rooms: newRooms };
    }),
    on(clearRoomMessagesHistory, (state, { roomId }) => {
      const entityState = state.rooms;
      const newRooms = stateWithHistoryAdapter.clearHistory(roomId, entityState);
      return {
        ...state,
        rooms: newRooms
      };
    }),
    on(updateChatDraftMessage, (state, { message, force }) => {
      const roomId = message.contextId;
      const existingDraftMessage = state.rooms.entities[roomId]?.draftMessage || {};
      const newDraftMessage = force ? message : { ...existingDraftMessage, ...message };
      const newEntities = chatRoomsEntityAdapter.updateOne({
        id: roomId,
        changes: { draftMessage: newDraftMessage }
      }, state.rooms);
      return { ...state, rooms: newEntities };
    }),
    on(removeDraftChatMessage, (state, { roomId }) => {
      const newEntities = chatRoomsEntityAdapter.updateOne({
        id: roomId,
        changes: { draftMessage: null }
      }, state.rooms);
      return { ...state, rooms: newEntities };
    }),
    on(storePendingChatMessage, (state, { pendingMessage }) => {
      const newEntities = pendingChatMessageStateAdapter.upsertOne(pendingMessage.encryptionData.roomId, pendingMessage, state.rooms);
      return { ...state, rooms: newEntities };
    }),
    on(updateChatMessageMediaByStatus, (state, { status }) => {
      const mapperFn = (messageEntity: ChatMessageEntity) => {
        return updateMessageMediaEmbedByUploadStatus(messageEntity, status);
      };
      const historicalRooms = chatHistoryStateAdapter.mapAll(mapperFn, state.rooms);
      const newHistoricalState = { ...state, rooms: historicalRooms };
      const realtimeRooms = chatMessageStateAdapter.mapAll(mapperFn, newHistoricalState.rooms);
      return { ...state, rooms: realtimeRooms };
    }),
    on(updateChatMessageMediaByEmbedData, (state, { mediaEmbed }) => {
      const mapperFn = (messageEntity: ChatMessageEntity) => {
        return updateMessageMediaEmbed(messageEntity, mediaEmbed);
      };
      const historicalRooms = chatHistoryStateAdapter.mapAll(mapperFn, state.rooms);
      const newHistoricalState = { ...state, rooms: historicalRooms };
      const realtimeRooms = chatMessageStateAdapter.mapAll(mapperFn, newHistoricalState.rooms);
      return { ...state, rooms: realtimeRooms };
    }),
    on(initFileEmbedCacheForRoom, (state, { blobUrl: blob, roomId, uploadId }) => {
      const roomEntity = findRoomById(roomId, state.rooms);
      const existingFileCache = roomEntity.fileEmbedCache || {};
      const newFileCache = { ...existingFileCache, [uploadId]: blob };
      const newEntities = chatRoomsEntityAdapter.updateOne({
        id: roomId,
        changes: { fileEmbedCache: newFileCache }
      }, state.rooms);
      return { ...state, rooms: newEntities };

    }),
    on(cleanUpFileEmbedCacheAfterFullyProcessed, (state, { status }) => {
      const newRooms = chatRoomsEntityAdapter.map((roomEntity) => {
        return cleanupFileEmbedCacheForRoom(roomEntity, status);
      }, state.rooms);
      return { ...state, rooms: newRooms };
    }),
    on(removePendingChatMessage, (state, { messageId }) => {
      const newEntities = pendingChatMessageStateAdapter.removeOneByNestedId(messageId, state.rooms);
      return { ...state, rooms: newEntities };
    }),
    on(updatePendingChatMessageFileAttachmentLoadState, (state, { loadState, mediaId }) => {
      const newEntities = pendingChatMessageStateAdapter.mapAll((pendingEntity) => {
        const mediaEmbed = pendingEntity.messageContent.embeds.map(embed => {
          if (isMediaEmbed(embed) && embed.id === mediaId) {
            return { ...embed, pendingType: loadState };
          }
          return embed;
        });
        const { messageContent } = pendingEntity;
        return {
          ...pendingEntity, messageContent: {
            ...messageContent, embeds: mediaEmbed
          }
        };
      }, state.rooms);
      return { ...state, rooms: newEntities };
    }),
    on(requestShareInboundGroupSessionForCurrentRoomSuccess, (state, { roomId }) => {
      const { sharedInboundGroupSessions } = state;
      return { ...state, sharedInboundGroupSessions: [...sharedInboundGroupSessions, { roomId }] };
    }),
    on(removeShareInboundGroupSessionForCurrentRoom, (state, { roomId }) => {
      const { sharedInboundGroupSessions } = state;
      const updatedInboundSessions = sharedInboundGroupSessions.filter(session => session.roomId !== roomId);
      return { ...state, sharedInboundGroupSessions: updatedInboundSessions };
    }),
    on(updateAutoReadAcknowledgeRoomId, (state, { roomId }) => {
      return { ...state, currentAutoReadAcknowledgeRoomId: roomId };
    }),
    on(addDraftChatMember, (state, { user }) => {
      const users = userSearchListItemEntityAdapter.upsertOne(user, state.draft.users);
      return {
        ...state,
        draft: { ...state.draft, users }
      };
    }),
    on(removeDraftChatMember, (state, { userId }) => {
      const users = userSearchListItemEntityAdapter.removeOne(userId, state.draft.users);
      return {
        ...state,
        draft: { ...state.draft, users }
      };
    }),
    on(addDraftChatRoom, (state, { room }) => {
      const rooms = chatSearchListItemEntityAdapter.upsertOne(room, state.draft.rooms);
      return {
        ...state,
        draft: { ...state.draft, rooms }
      };
    }),
    on(removeDraftChatRoom, (state, { roomId }) => {
      const rooms = chatSearchListItemEntityAdapter.removeOne(roomId, state.draft.rooms);
      return {
        ...state,
        draft: { ...state.draft, rooms }
      };
    }),
    on(updateDraftChatMessage, (state, { message }) => {
      const existingDraftMessage = state.draft.message || {};
      const newDraftMessage = { ...existingDraftMessage, ...message };
      return {
        ...state,
        draft: { ...state.draft, message: newDraftMessage }
      };
    }),
    on(cleanUpDraftChat, (state) => {
      return {
        ...state,
        draft: chatInitialState.draft
      };
    }),
    on(cleanUpDraftChatMessage, (state) => {
      return {
        ...state,
        draft: {
          users: state.draft.users,
          rooms: state.draft.rooms,
          message: chatInitialState.draft.message
        }
      };
    }),
    on(clearChatMessageNewState, (state, { roomId }) => {
      const mapperFn = (messageEntity: ChatMessageEntity) => {
        return messageEntity.encryptionData?.roomId === roomId ? { ...messageEntity, new: false } : messageEntity;
      };
      const historicalRooms = chatHistoryStateAdapter.mapAll(mapperFn, state.rooms);
      const newHistoricalState = { ...state, rooms: historicalRooms };
      const realtimeRooms = chatMessageStateAdapter.mapAll(mapperFn, newHistoricalState.rooms);
      return { ...state, rooms: realtimeRooms };
    }),
    on(roomRemoved, (state, { roomId }) => {
      return {
        ...state,
        rooms: chatRoomsEntityAdapter.removeOne(roomId, state.rooms)
      };
    }),
    on(updateMemberState, (state, { roomId, userId, newState }) => {
      const roomEntity = findRoomById(roomId, state.rooms);
      const updatedMembers = roomEntity.members.map(member => {
        let data = member;
        if (member.userId === userId) {
          data = {
            ...data,
            state: newState
          };
        }
        return data;
      });
      return {
        ...state,
        rooms: chatRoomsEntityAdapter.upsertOne({ ...roomEntity, members: updatedMembers }, state.rooms)
      };
    }),
    ...chatScheduledMessageReducers
  ),
});
