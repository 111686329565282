import { Injectable } from '@angular/core';
import { channelMessageDetailIdentifier } from '../../../../../views/channel/tokens';
import { matchFirstRegExpGroup } from '../../../../common/util/match-first-regexp';
import { messageMatcher } from '../deep-link-regexes';
import { DeepLinkable } from '../deep-linkable';

@Injectable()
export class MessageDeepLinkable extends DeepLinkable {

  isMatchForUrl(link: string) {
    return Boolean(this.extractRouteLink(link));
  }

  extractRouteLink(link: string) {
    const match = matchFirstRegExpGroup(link, messageMatcher());
    return match;
  }

  getDeepLink(params: Record<typeof channelMessageDetailIdentifier, string>) {
    const idParam = params[channelMessageDetailIdentifier];
    if (!idParam) {
      return null;
    }
    return `${this.rootUrl}/message/${idParam}`;
  }

  getOpenerTranslation(): string {
    return 'EMBED_OPEN_CHANNEL_MESSAGE_LINK';
  }

}
