import { Action, createReducer, on } from '@ngrx/store';
import { isInviteToChannelState, isJoinRequestChannelState } from '@portal/wen-backend-api';
import { LoadingState } from '../../common/types/store-loading-state';
import { cleanUpInviteToChannelRequestChannel, cleanUpPendingJoinRequestChannel, clearBatchChannelMessagesNewState, clearChannelMessagesHistory, clearChannelMessagesNewState, clearHistoricalMessagesForChannel, createPendingChannelMessage, disableAutoAcknowledge, enableAutoAcknowledge, removeDraftChannelMessage, removePendingChannelMessage, removeScheduledMessage, removeScheduledMessages, replaceChannelMessagesHistory, setFeaturedData, updateAdminListWithNewUserForChannel, updateChannel, updateChannelConfiguration, updateChannelDetail, updateChannelExtraMessages, updateChannelList, updateChannelMessages, updateChannelMessagesHistory, updateChannelPermission, updateChannelRestrictions, updateContentProvider, updateContentProvidersForChannel, updateCurrentChannelAdmins, updateDiscoverChannelList, updateDiscoverChannelLoadingState, updateDraftChannelMessage, updateFeaturedChannel, updateInviteRequestUserChannelList, updateLastReadTimestamp, updatePendingChannelMessage, updateScheduledMessage, updateScheduledMessages, updateScheduledMessagesCount, updateSubscriberList, updateUserChannelList, updateUserChannelLoadingState, upsertChannelExtraMessages, upsertChannelMessages, upsertChannelMessagesHistory, upsertChannelMeta } from './channel.actions';
import { ChannelEntity, ChannelState, channelAdapter, channelMetaAdapter } from './channel.state';
import { channelsHelper } from './state-adapter-helpers/channel-state-adapter';
import { findChannelById } from './state-adapter-helpers/state-adapter-utils';

export const channelInitialState: ChannelState = {
  channels: channelAdapter.getInitialState({}),
  channelMeta: channelMetaAdapter.getInitialState({}),
  userChannelsLoading: LoadingState.NOT_LOADED,
  discoverChannelsLoading: LoadingState.NOT_LOADED,
  userChannelIds: [],
  userJoinRequestChannelIds: [],
  inviteToChannelRequestChannelIds: [],
  discoverChannelIds: [],
  channelViewScrollTopRestoration: null,
  currentAutoAcknowledgeChannelId: null,
  featuredData: null,
};

const channelReducer = createReducer(
  channelInitialState,
  on(updateChannel, updateChannelDetail, (state, { channel }): ChannelState => {
    let newChannels = state.channels;
    // temporary solution as updateChannelDetail of an admin channel has private flag instead of isPrivate
    // returns a ChannelEntity with the isPrivate flag instead of private
    newChannels = channelAdapter.upsertOne({ ...channel, isPrivate: channel.private ?? channel.isPrivate } as ChannelEntity, newChannels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(upsertChannelMeta, (state, { channelMetas }): ChannelState => {
    const newchannelMeta = channelMetaAdapter.upsertMany(channelMetas, state.channelMeta);
    return {
      ...state,
      channelMeta: newchannelMeta
    };
  }),
  on(updateChannelList, (state, { channels }): ChannelState => {
    let newChannels = state.channels;
    channels.forEach((channel) => {
      newChannels = channelAdapter.upsertOne(channel, newChannels);
    });
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateUserChannelList, (state, { channels }): ChannelState => {
    let newChannels = state.channels;
    channels.forEach((channel) => {
      newChannels = channelAdapter.upsertOne(channel, newChannels);
    });
    return {
      ...state,
      channels: newChannels,
      userChannelIds: [...channels.map(c => c.id)]
    };
  }),
  on(cleanUpPendingJoinRequestChannel, (state, { channelId }): ChannelState => {
    return {
      ...state,
      channels: channelsHelper.channelJoinRequests.clearInviteFromChannel(channelId, state.channels),
      userJoinRequestChannelIds: state.userJoinRequestChannelIds.filter(id => id !== channelId),
    };
  }),
  on(cleanUpInviteToChannelRequestChannel, (state, { channelId }): ChannelState => {
    return {
      ...state,
      channels: channelsHelper.channelJoinRequests.clearInviteFromChannel(channelId, state.channels),
      inviteToChannelRequestChannelIds: state.inviteToChannelRequestChannelIds.filter(id => id !== channelId),
    };
  }),
  on(updateInviteRequestUserChannelList, (state, { channels }): ChannelState => {
    let newChannels = state.channels;
    channels.forEach((channel) => {
      newChannels = channelAdapter.upsertOne({
        ...channel,
      }, newChannels);
    });
    const joinRequestIds = channels
      .filter(channel => isJoinRequestChannelState(channel.invite))
      .map(channel => channel.id);

    const inviteToChannelRequestIds = channels
      .filter(channel => isInviteToChannelState(channel.invite))
      .map(channel => channel.id);

    return {
      ...state,
      channels: newChannels,
      userJoinRequestChannelIds: joinRequestIds,
      inviteToChannelRequestChannelIds: inviteToChannelRequestIds
    };
  }),
  on(updateDiscoverChannelList, (state, { channels }): ChannelState => {
    let newChannels = state.channels;
    channels.forEach((channel) => {
      newChannels = channelAdapter.upsertOne(channel, newChannels);
    });
    return {
      ...state,
      channels: newChannels,
      discoverChannelIds: [...channels.map(c => c.id)]
    };
  }),
  on(upsertChannelMessages, (state, { messages }): ChannelState => {
    const newChannels = channelsHelper.channelMessages.upsertMany(messages, state);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(upsertChannelMessagesHistory, (state, { channelId, messages, flags }): ChannelState => {
    const newChannels = channelsHelper.channelMessagesHistory.upsertMany(messages, channelId, state, flags);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(replaceChannelMessagesHistory, (state, { channelId, messages, flags }): ChannelState => {
    const clearedState = {
      ...state,
      channels: channelsHelper.channelCleaner.clearAllMessagesFromChannel(channelId, state.channels)
    };
    const newChannels = channelsHelper.channelMessagesHistory.upsertMany(messages, channelId, clearedState, flags);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateChannelMessages, (state, { messages }): ChannelState => {
    const newChannels = channelsHelper.channelMessages.updateMany(messages, state);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateChannelMessagesHistory, (state, { channelId, messages, flags }): ChannelState => {
    const newChannels = channelsHelper.channelMessagesHistory.updateMany(messages, channelId, state, flags);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(clearChannelMessagesHistory, (state, { channelId }): ChannelState => {
    const newChannels = channelsHelper.channelMessagesHistory.clearHistory(channelId, state);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(clearHistoricalMessagesForChannel, (state, { channelId, keepMessageId }): ChannelState => {
    const newChannels = channelsHelper.channelMessagesHistory.clearMessageHistory(channelId, keepMessageId, state);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(upsertChannelExtraMessages, (state, { messages }): ChannelState => {
    const newChannels = channelsHelper.channelExtraMessages.upsertMany(messages, state);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateChannelExtraMessages, (state, { messages }): ChannelState => {
    const newChannels = channelsHelper.channelExtraMessages.updateMany(messages, state);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateUserChannelLoadingState, (state, { loadingState }): ChannelState => {
    return { ...state, userChannelsLoading: loadingState };
  }),
  on(updateDiscoverChannelLoadingState, (state, { loadingState }): ChannelState => {
    return { ...state, discoverChannelsLoading: loadingState };
  }),
  on(clearChannelMessagesNewState, (state, { channelId, lastReadTimestamp }): ChannelState => {
    return {
      ...state,
      channels: channelsHelper.channelMessages.clearNewStateFromMessages(channelId, state.channels, lastReadTimestamp)
    };
  }),
  on(clearBatchChannelMessagesNewState, (state, { messages }): ChannelState => {
    return {
      ...state,
      channels: channelsHelper.channelMessages.clearNewStateFromABatchOfMessage(messages, state.channels)
    };
  }),
  on(enableAutoAcknowledge, (state, { channelId }): ChannelState => {
    return {
      ...state,
      currentAutoAcknowledgeChannelId: channelId
    };
  }),
  on(disableAutoAcknowledge, (state): ChannelState => {
    return {
      ...state,
      currentAutoAcknowledgeChannelId: null
    };
  }),
  on(updateChannelPermission, (state, { channelId, permission }): ChannelState => {
    return {
      ...state,
      channels: channelsHelper.channelPermission.updatePermissionFor(channelId, permission, state.channels)
    };
  }),
  on(updateChannelRestrictions, (state, { channelId, restrictions }): ChannelState => {
    const newChannels = channelsHelper.channelRestriction.updateRestrictionFor(channelId, restrictions, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(createPendingChannelMessage, (state, { message }): ChannelState => {
    return {
      ...state,
      channels: channelsHelper.channelPendingMessages.addPendingMessage(message, state.channels)
    };
  }),
  on(removePendingChannelMessage, (state, { uploadId, channelId }): ChannelState => {
    return {
      ...state,
      channels: channelsHelper.channelPendingMessages.removePendingMessage(uploadId, channelId, state.channels)
    };
  }),
  on(updatePendingChannelMessage, (state, { uploadId, channelId }) => {
    return {
      ...state,
      channels: channelsHelper.channelPendingMessages.updatePendingMessage(uploadId, channelId, state.channels)
    };
  }),
  on(setFeaturedData, (state, { featuredData }) => {
    let newChannels = state.channels;
    newChannels = channelAdapter.upsertOne({ ...featuredData.channel } as ChannelEntity, newChannels);
    return {
      ...state,
      featuredData,
      channels: newChannels
    };
  }),
  on(updateFeaturedChannel, (state, { channel }) => {
    return {
      ...state,
      featuredData: channelsHelper.featuredData.updateOne({ channel }, state)
    };
  }),
  on(updateScheduledMessagesCount, (state, { channelId, count }) => {
    const newChannels = channelsHelper.channelScheduledMessages.setCount(count, channelId, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateScheduledMessages, (state, { messages, channelId, hasMore }) => {
    const newChannels = channelsHelper.channelScheduledMessages.upsertMany(messages, channelId, state.channels, hasMore);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateScheduledMessage, (state, { message }) => {
    const newChannels = channelsHelper.channelScheduledMessages.upsertOne(message, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(removeScheduledMessage, (state, { message }) => {
    const newChannels = channelsHelper.channelScheduledMessages.removeOne(message, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(removeScheduledMessages, (state, { channelId, messages }) => {
    const newChannels = channelsHelper.channelScheduledMessages.removeMany(channelId, messages, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateDraftChannelMessage, (state, { message }) => {
    const newChannels = channelsHelper.channelDraftMessages.upsertOne(message, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(removeDraftChannelMessage, (state, { channelId }) => {
    const newChannels = channelsHelper.channelDraftMessages.removeOne(channelId, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateContentProvidersForChannel, (state, { channelId, users: contentProviders }) => {
    const channel = findChannelById(channelId, state.channels);
    const newChannels = channelAdapter.upsertOne({ ...channel, contentProviders }, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateContentProvider, (state, { channelId, user }) => {
    const channel = findChannelById(channelId, state.channels);
    const newContentProviders = [...channel.contentProviders, user];
    const newChannels = channelAdapter.upsertOne({ ...channel, contentProviders: newContentProviders }, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateCurrentChannelAdmins, (state, { channelId, users }) => {
    const channel = findChannelById(channelId, state.channels);
    const updatedChannels = channelAdapter.upsertOne({ ...channel, admins: users }, state.channels);
    return {
      ...state,
      channels: updatedChannels
    };
  }),
  on(updateAdminListWithNewUserForChannel, (state, { user, channelId }) => {
    const channel = findChannelById(channelId, state.channels);
    const newAdmins = [...channel.admins, user];
    const updatedChannels = channelAdapter.upsertOne({ ...channel, admins: newAdmins }, state.channels);
    return {
      ...state,
      channels: updatedChannels
    };
  }),
  on(updateSubscriberList, (state, { channelId, users }) => {
    const channel = findChannelById(channelId, state.channels);
    const newChannels = channelAdapter.upsertOne({ ...channel, subscribers: users }, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
  on(updateLastReadTimestamp, (state, { channelId, lastReadTimestamp }): ChannelState => {
    return {
      ...state,
      channels: channelsHelper.channelMessages.updateLastReadTimestamp(channelId, lastReadTimestamp, state.channels)
    };
  }),
  on(updateChannelConfiguration, (state, { response: { channelId, ...configuration } }) => {
    const channel = findChannelById(channelId, state.channels);
    const newChannels = channelAdapter.upsertOne({ ...channel, configuration: { ...configuration, channelId } }, state.channels);
    return {
      ...state,
      channels: newChannels
    };
  }),
);

export function channelReducerFactory(state: ChannelState | undefined, action: Action) {
  return channelReducer(state, action);
}
