import { Observable } from 'rxjs';
import { WenNativeApi } from '@portal/wen-native-api';
import { BrowserConnectionMonitor } from './browser-connection-monitor';
import { NativeConnectionMonitor } from './native-connection-monitor';

export interface ConnectionMonitor {
  isOnline$: Observable<boolean>;
}

export const networkConnectionMonitorFactory = (
  nativeApi: WenNativeApi
) => {
  if (nativeApi.isReactNativeApp()) {
    return new NativeConnectionMonitor(nativeApi);
  }
  return new BrowserConnectionMonitor();
};
