import { CdkScrollable } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';

@Injectable()
export class IosKeyboardLockScrollHelper {

  private scrollables: CdkScrollable[] = [];

  getScrollables() { return this.scrollables; }
  addScrollable(scrollable: CdkScrollable) { this.scrollables.push(scrollable); }

  removeScrollables() {
    this.scrollables = [];
  }

}
