import { AfterViewInit, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { PortalDragOverlayDirective } from '@portal/ui-kit/drag-overlay';
import { combineLatest, map, merge, Observable, of, sample, scan, shareReplay, Subject } from 'rxjs';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { startWith } from 'rxjs/internal/operators/startWith';
import { WenNativeApi } from '@portal/wen-native-api';
import { ChatMediaPreviewHandler } from '../../../core/services/util/chat-media-preview-handler';
import { MediaPreviewHandler } from '../../../core/services/util/media-preview-handler';
import { ChatMessageEntity } from '../../../core/store/chat/chat.state';
import { requestShareInboundGroupSessionForCurrentRoom } from '../../../core/store/chat/key-actions';
import { RootState } from '../../../core/store/root/public-api';
import { ChatHeaderDataUpdater } from '../../../shared/chat-specific/chat-view-header-data-updater';
import { FeedDatasource } from '../../../shared/components/feed/providers/feed-datasource';
import { FeedLayoutMediator } from '../../../shared/components/feed/providers/feed-layout-mediator';
import { FileDropOverlayComponent } from '../../../shared/components/file-drop-overlay/file-drop-overlay.component';
import { MessageSendFieldDataSource } from '../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { MessageSeparatorDateFormatter, PastMessageSeparatorDateFormatter } from '../../../shared/directives/directives/message-separator-formatters';
import { FileEventHandlerService } from '../../../shared/services/file-event-handler.service';
import { MessagesDatasource } from '../../../shared/services/messages.datasource';
import { ChatFeedDatasource } from './providers/chat-feed-datasource';
import { ChatMessageSendFieldDataSource } from './providers/chat-message-send-field-data-source';
import { ChatViewDatasource } from './providers/chat-view-datasource';
import { ChatViewMessagesDatasource } from './providers/chat-view-messages.datasource';
import { mapAllBool } from '../../../core/common/operators/map-all-bool';
import { firstTrue } from '@portal/wen-common';
import { ChatViewPreloaderGuard } from './providers/chat-view-preloader.guard';
import { MessageBoxActionsHandler } from '../../../shared/components/message-box/providers/message-box-tokens';
import { ChatMessageBoxActionsHandler } from '../common/message-box-actions/chat-message-box-actions-handler';

@Component({
  selector: 'wen-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss'],
  providers: [
    { provide: MessagesDatasource, useClass: ChatViewMessagesDatasource },
    { provide: MessageSendFieldDataSource, useClass: ChatMessageSendFieldDataSource },
    { provide: MediaPreviewHandler, useClass: ChatMediaPreviewHandler },
    ChatViewDatasource, ChatHeaderDataUpdater,
    FeedLayoutMediator,
    { provide: FeedDatasource, useClass: ChatFeedDatasource },
    { provide: MessageSeparatorDateFormatter, useClass: PastMessageSeparatorDateFormatter },
    { provide: MessageBoxActionsHandler, useClass: ChatMessageBoxActionsHandler },
  ]
})
export class ChatViewComponent implements OnInit, AfterViewInit, OnDestroy {
  overlayContent = FileDropOverlayComponent;

  private onDestroy$ = new Subject<void>();
  private nativeApi = inject(WenNativeApi);

  private initialKeepInputFocus = false;
  private messageSent$ = new Subject<void>();

  @ViewChild(PortalDragOverlayDirective, { read: ElementRef }) viewContent: ElementRef<HTMLDivElement>;

  isChatDataReady$: Observable<boolean>;
  keepInputFocused$: Observable<boolean>;
  isGroupChat$: Observable<boolean>;

  constructor(
    private readonly chatViewDatasource: ChatViewDatasource,
    private readonly chatViewHeaderDataUpdater: ChatHeaderDataUpdater,
    private readonly store: Store<RootState>,
    private readonly fileEventHandler: FileEventHandlerService,
    private readonly feedLayoutMediator: FeedLayoutMediator,
    private readonly messagesDatasource: MessagesDatasource<ChatMessageEntity>,
    private readonly chatViewPreloaderGuard: ChatViewPreloaderGuard,
  ) { }

  ngOnInit(): void {
    this.chatViewDatasource.initialize();
    this.messagesDatasource.initialize();
    this.chatViewHeaderDataUpdater.initialize();
    this.isGroupChat$ = this.chatViewDatasource.isGroupChat$;
    this.isChatDataReady$ = combineLatest([
      this.chatViewPreloaderGuard.isChatHistoryLoaded$,
      this.chatViewPreloaderGuard.isChatDetailsLoaded$,
    ]).pipe(
      mapAllBool(),
      firstTrue(),
      shareReplay(1)
    );
  }

  ngAfterViewInit() {
    const viewContentClick$ = fromEvent(this.viewContent.nativeElement, 'click').pipe(
      map(() => this.initialKeepInputFocus)
    );
    const isVirtualKeyboardVisible$ = !!this.nativeApi?.keyboardDidShow$ ? merge(
      this.nativeApi?.keyboardDidHide$?.pipe(map(() => false)),
      this.nativeApi?.keyboardDidShow$?.pipe(map(() => true))
    ) : of(false);
    const keyboardVisibility$ = isVirtualKeyboardVisible$.pipe(
      sample(this.messageSent$),
      scan((shouldKeepFocus, isVirtualKeyboardVisible) => {
        if (isVirtualKeyboardVisible) {
          shouldKeepFocus = isVirtualKeyboardVisible;
        }
        return shouldKeepFocus;
      }, this.initialKeepInputFocus),
      startWith(this.initialKeepInputFocus)
    );
    this.keepInputFocused$ = merge(
      keyboardVisibility$,
      viewContentClick$
    );
  }

  onFocusSendField() {
    this.store.dispatch(requestShareInboundGroupSessionForCurrentRoom());
    this.feedLayoutMediator.scrollToBottom();
  }

  onMessageSent() {
    this.feedLayoutMediator.scrollToBottom();
    this.messageSent$.next();
  }

  onFilesDropped(files: FileList) {
    this.fileEventHandler.addFiles(files);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
