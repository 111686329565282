import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActionMenuModule} from '@portal/wen-components';
import { SearchInputModule } from '../../../../shared/components/search-input/search-input.module';
import { SharedModule } from '../../../../shared/shared.module';
import { HeaderModule } from '../../header/header.module';
import { SearchBarDesktopComponent } from './search-bar-desktop.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SearchInputModule,
    ActionMenuModule,
    HeaderModule,
    TranslateModule.forChild()
  ],
  declarations: [
    SearchBarDesktopComponent
  ],
  exports: [
    SearchBarDesktopComponent
  ]
})
export class SearchBarDesktopModule { }
