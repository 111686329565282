import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { QuoteHandler } from '../../../../../core/services/util/quote-handler';
import { MessageBoxModel } from '../../models/message-box.models';
import { map } from 'rxjs';

@Injectable()
export class ChatMessageBoxQuoteAction implements ActionMenuItem {

  private translateService = inject(TranslateService);
  private featureEnablementService = inject(FeatureEnablementService);
  private quoteHandler = inject(QuoteHandler);

  label: string = this.translateService.instant('CONTEXT_MENU_LABEL_QUOTE');
  icon = 'reply';

  isVisible$ = (message: MessageBoxModel) => {
    return this.featureEnablementService.featureConfigObs.enableChatMessageQuote$.pipe(
      map((isChatMessageQuoting) => isChatMessageQuoting && !message.scheduled)
    );
  };

  execute(message: MessageBoxModel) {
    this.quoteHandler.initiateQuote(message.referenceId);
  }
}
