import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wen-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComingSoonComponent {
  @HostBinding('class.wen-coming-soon') className = true;

}
