import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ChannelMemberRoles, SocketIoService } from '@portal/wen-backend-api';
import { filter, map, switchMap, tap } from 'rxjs';
import { switchMapFirst } from '../../../common/operators/switch-map-first';
import { WenNavigationHelper } from '../../../services/navigation/types';
import { RootState } from '../../root/public-api';
import { addAdminForCurrentChannel, deleteAdminForCurrentChannel, listElevatedPrivilegeChannelMembers, subscribeToChannelRoleUpdates, updateCurrentChannelAdmins } from '../channel.actions';
import { selectCurrentChannel } from '../channel.selectors';

@Injectable()
export class ChannelAdminEffects {

  listElevatedPrivilegeChannelMembers$ = createEffect(() => this.actions$.pipe(
    ofType(listElevatedPrivilegeChannelMembers),
    switchMapFirst(() => this.store.pipe(select(selectCurrentChannel))),
    tap(({ id: channelId }) => this.socketIoService.channel.listRoles.emit({ channelId }))
  ), { dispatch: false });

  updateAdminList$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToChannelRoleUpdates),
    switchMap(() => {
      return this.socketIoService.channel.listRoles.listen.pipe(
        filter((result) => Boolean(result.admins?.length)),
        map(({ channelId, admins: users }) => updateCurrentChannelAdmins({ channelId, users }))
      );
    })
  ));

  addAdmin$ = createEffect(() => this.actions$.pipe(
    ofType(addAdminForCurrentChannel),
    switchMapFirst(({ userId }) => this.store.pipe(
      select(selectCurrentChannel),
      map((channel) => ({ channel, userId }))
    )),
    filter(({ channel }) => channel.permission?.canAddAdmins),
    tap(({ channel: { id: channelId }, userId }) => {
      this.socketIoService.channel.changeRole.emit({ userId, channelId, targetRole: ChannelMemberRoles.ADMIN });
      this.navigationHelper.navigateToAdminListPage(channelId, { replaceUrl: true });
    }),
  ), { dispatch: false });

  deleteAdmin$ = createEffect(() => this.actions$.pipe(
    ofType(deleteAdminForCurrentChannel),
    switchMapFirst(({ userId }) => this.store.pipe(
      select(selectCurrentChannel),
      map((channel) => ({ channel, userId }))
    )),
    filter(({ channel }) => channel.permission?.canDeleteAdmins),
    tap(({ channel: { id: channelId }, userId }) => {
      this.socketIoService.channel.changeRole.emit({ userId, channelId, targetRole: ChannelMemberRoles.SUBSCRIBER });
      this.navigationHelper.navigateToAdminListPage(channelId, { replaceUrl: true });
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper
  ) { }
}
