import { Directive, Input, ViewContainerRef } from '@angular/core';
import { WenSeparatorComponent } from '@portal/wen-components';
import { MessageSeparatorData, SeparatorFactory } from './separator-factory';

@Directive({
  selector: '[messageSeparator]',
  providers: [
    SeparatorFactory
  ]
})
export class MessageSeparatorDirective {

  private context: MessageSeparatorData;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private separatorFactory: SeparatorFactory,
  ) { }

  @Input()
  set messageSeparator(context: MessageSeparatorData) {
    this.context = context;
    this.updateView();
  }

  private updateView() {
    const displayData = this.separatorFactory.getSeparatorData(this.context);
    if (displayData) {
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(WenSeparatorComponent);
      const component = componentRef.instance;
      component.displayData = displayData;
    } else {
      this.viewContainerRef.clear();
    }
  }

}
