@if (thumbnail$ | async; as thumbnail) {
  <img
    #image
    [wenLazyload]="thumbnail"
    [wenLazyloadDisableScaling]="true"
    (tap)="onMediaClick($event,thumbnail)"
    (error)="handleImageError()"
    (load)="hadleImageLoaded()"
    [retry-image-load]="onFinalError"
    />
}
@if (showLoading) {
  <div class="wen-embed-overlay" >
    <wen-loading-indicator [variant]="'picture'"></wen-loading-indicator>
  </div>
}
@if (error) {
  <div
    (tap)="reloadImage()"
    class="wen-failed-image-load"
    >
    {{'RETRY_IMAGE_LOAD_TEXT' | translate}}
    <wen-icon [iconName]="'jump_forward'"/>
  </div>
}
