import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContextMenuComponent, ContextMenuTriggerDirective, TouchEndListenerModule, WenSeparatorModule } from '@portal/wen-components';
import { MessageTimestampPipe } from '../../../views/channel/common/components/channel-messages-view/pipes/message-timestamp.pipe';
import { MessageBoxModule } from '../message-box/message-box.module';
import { WeFeedComponent } from './components/we-feed/we-feed.component';
import { SharedModule } from '../../shared.module';
import { ObserveVisibilityModule } from '../../directives/message-visibility/observe-visibility.module';
import { IosUtilsModule } from '../../ios-util/ios-util.module';
import { WeFeedEmptyPlaceholderComponent } from './components/we-feed-empty-placeholder/we-feed-empty-placeholder.component';
import { WeFeedCustomItemTemplateDirective } from './directives/we-feed-custom-item-template.directives';

const PUBLIC_DECLARATIONS = [
  WeFeedComponent,
  WeFeedEmptyPlaceholderComponent,
  WeFeedCustomItemTemplateDirective,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ScrollingModule,
    MessageBoxModule,
    WenSeparatorModule,
    ObserveVisibilityModule,
    IosUtilsModule,
    ContextMenuTriggerDirective,
    ContextMenuComponent,
    TouchEndListenerModule
  ],
  declarations: [
    ...PUBLIC_DECLARATIONS,
    MessageTimestampPipe,
  ],
  exports: [
    ...PUBLIC_DECLARATIONS,
  ],
})
export class FeedModule { }
