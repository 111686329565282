import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { enableAutoAcknowledge, prepareChannelMessage } from '../channel.actions';

export const createAutoAcknowledgeForMessageSendingEffect = (
  actions$: Actions,
) => {
  return createEffect(() =>
    merge(
      actions$.pipe(
        ofType(prepareChannelMessage),
        map(({ channelId }) => {
          return enableAutoAcknowledge({ channelId });
        })
      )
    )
  );
};
