import { NgModule, StaticProvider } from '@angular/core';
import { MASS_DATA_CONFIG, MassDataConfigEntry, MassDataModule, OffsetBasedMassDataPagingAdapter } from '@portal/wen-data-core';
import { contactsMassDataIdentifier } from '../constants';
import { ContactsMassDataAdapter } from './contacts-mass-data-adapter';
import { ContactsMassDataContainer } from './contacts-mass-data-container';
import { ContactsMassDataRepository } from './contacts-mass-data-repository';
import { ContactsMassDataUpdatesPlugin } from './contacts-mass-data-updates';

const contactsConfig: MassDataConfigEntry = {
  identifier: contactsMassDataIdentifier,
  container: ContactsMassDataContainer,
  adapter: ContactsMassDataAdapter,
  repository: ContactsMassDataRepository,
  pagingAdapter: OffsetBasedMassDataPagingAdapter,
  updatesAdapter: ContactsMassDataUpdatesPlugin,
};

const CONTACTS_CONTAINER_CONFIG: StaticProvider = {
  provide: MASS_DATA_CONFIG,
  multi: true,
  useValue: contactsConfig
};

@NgModule({
  imports: [
    MassDataModule,
  ],
  providers: [
    ContactsMassDataContainer,
    ContactsMassDataAdapter,
    ContactsMassDataRepository,
    ContactsMassDataUpdatesPlugin,
    CONTACTS_CONTAINER_CONFIG
  ]
})
export class ContactsMassDataModule { }
