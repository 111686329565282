import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { EmbedDTOType, EmbeddedMediaDTO, getMediaType } from '../../../../data-types/embed/embed';
import { MediaContext, MediaUseCases } from '../../../../events/events';
import { MediaData, WeLocalImageHelper } from '../../../we-local/we-local-image-helper';

@Injectable()
export class EmbedImageUploader {

  constructor(
    private imageUploader: WeLocalImageHelper,
  ) { }

  createUpload(
    mediaEmbed: EmbeddedMediaDTO, // TODO: use simplier interface than EmbeddedMediaDTO
    contexts: MediaContext[],
    useCase: MediaUseCases,
  ): Observable<EmbeddedMediaDTO> {
    const { file: mediaFile, id: mediaId, uploadId } = mediaEmbed;
    const request$ = this.imageUploader.uploadImage(mediaFile, contexts, useCase, uploadId ?? mediaId).pipe(
      shareReplay(1)
    );
    const result$ = request$.pipe(
      map((result) => {
        const persistedMediaEmbed: EmbeddedMediaDTO = {
          type: EmbedDTOType.MEDIA,
          subType: getMediaType(mediaFile),
          ...result
        };
        return persistedMediaEmbed;
      }),
      shareReplay(1)
    );
    return result$;
  }

  activatePreview(
    uploadId: string,
    contexts: MediaContext[],
    useCase: MediaUseCases,
  ): Observable<MediaData> {
    return this.imageUploader.activatePreview(contexts, useCase, uploadId);
  }

}
