import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { animate } from 'motion';

@Component({
  selector: 'wen-swipeable',
  templateUrl: './swipeable.component.html',
  styleUrls: ['./swipeable.component.scss'],
  standalone: true
})
export class SwipeableComponent implements OnInit {

  @HostBinding('class.wen-swipeable') className = true;
  @HostBinding('style.width') elementWidth = '100%';

  @Input() enableSwipe = true;

  constructor(
    private readonly element: ElementRef
  ) { }

  @Input() set swipeDirection(value: 'left' | 'right' | 'horizontal') {
    this.direction = value;
    this.hammerManager.remove(this.hammerRecognizer);
    switch (this.direction) {
      case 'left':
        this.contentAlign = 'align-right';
        this.hammerRecognizer = new Hammer.Pan({ direction: Hammer.DIRECTION_LEFT });
        break;
      case 'horizontal':
        this.contentAlign = 'align-center';
        this.hammerRecognizer = new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL });
        break;
      default:
        this.contentAlign = 'align-left';
        this.hammerRecognizer = new Hammer.Pan({ direction: Hammer.DIRECTION_RIGHT });
        break;
    }
    this.hammerManager.add(this.hammerRecognizer);
  }

  @Input() swipeTriggerRatio = 0.25;
  @Input() swipeDistanceLimitRatio = 0.60;

  @Output() swipeGesture = new EventEmitter<'left' | 'right'>();

  @ViewChild('content') readonly contentElement: ElementRef<HTMLElement>;
  @ViewChild('leftIndicator') readonly leftIndicatorElement: ElementRef<HTMLElement>;
  @ViewChild('rightIndicator') readonly rightIndicatorElement: ElementRef<HTMLElement>;

  contentAlign = 'align-left';
  private direction = 'right';
  private hammerManager = new Hammer.Manager(this.element.nativeElement);
  private hammerRecognizer = new Hammer.Pan({ direction: Hammer.DIRECTION_RIGHT });

  ngOnInit(): void {
    if(this.enableSwipe) {
      this.hammerManager.on('pan', (event) => this.handlePan(event));
      this.hammerManager.on('panend', (event) => this.handlePanEnd(event));
    }
  }

  private clampAnimationOffset(offset) {
    const swipeLimit = (this.element.nativeElement as HTMLElement).offsetWidth * this.swipeDistanceLimitRatio;
    const min = this.direction === 'right' ? 0 : -swipeLimit;
    const max = this.direction === 'left' ? 0 : swipeLimit;
    return Math.min(Math.max(offset, min), max);
  }

  private handlePan(event: HammerInput) {
    const swipeTrigger = (this.element.nativeElement as HTMLElement).offsetWidth * this.swipeTriggerRatio;
    const swipeOffset = this.clampAnimationOffset(event.deltaX);

    animate(this.leftIndicatorElement.nativeElement,
      { opacity: Math.min(1, swipeOffset / swipeTrigger) },
      { duration: 0 }
    );
    animate(this.rightIndicatorElement.nativeElement,
      { opacity: Math.min(1, -swipeOffset / swipeTrigger) },
      { duration: 0 }
    );
    animate(this.contentElement.nativeElement,
      { x: swipeOffset },
      { duration: 0 }
    );
  }

  private handlePanEnd(event: HammerInput) {
    const swipeTrigger = (this.element.nativeElement as HTMLElement).offsetWidth * this.swipeTriggerRatio;
    const indicators = [this.leftIndicatorElement.nativeElement, this.rightIndicatorElement.nativeElement];

    animate(indicators, { opacity: 0 });
    animate(this.contentElement.nativeElement, { x: 0 });

    if (Math.abs(this.clampAnimationOffset(event.deltaX)) >= swipeTrigger) {
      this.swipeGesture.emit(event.deltaX > 0 ? 'right' : 'left');
    }
  }

}
