import { transition, trigger } from '@angular/animations';
import { WenRouteId } from '../../../routing/types';
import { slideInBackwardAnimation, slideInForwardAnimation } from './slide-in.animation';

export const routeTransitionsArray =
  trigger('routeAnimations', [
    transition(`${WenRouteId.HOME} => ${WenRouteId.CHANNEL_FEATURED_VIEW}`, slideInForwardAnimation),
    transition(`${WenRouteId.CHANNEL_FEATURED_VIEW} => ${WenRouteId.HOME}`, slideInBackwardAnimation),
  ]);

export const routeTransitions = routeTransitionsArray;
