import { ExchangeRoomKeyRequestEventDTO } from './exchange-room-key-request';
import { ExchangeRoomKeyResponseEventDTO } from './exchange-room-key-response';
import { ShareKeyEventDTO } from './share-key';

export type SendToUsersEventResponses = ShareKeyEventDTO<true> | ExchangeRoomKeyRequestEventDTO | ExchangeRoomKeyResponseEventDTO<true>;

/**
 * The event data of a received message in rooms
 *
 * @see {@link SOCKET_EVENTS.CHAT_SEND_TO_USERS}
 */
export type SendToUsersEventResponse = {
  id: string;
  insertUserId: string;
  // Timestamp of real-time share key events
  insertUserTimestamp?: string;
  // Timestamp of inbox-time share key events
  insertTimestamp?: string;
  payload: SendToUsersEventResponses;
};

export const SendToUsersEventResponseUtil = {
  // The backend sends multiple timetsamp variations for the same share-key events and has to be merged
  fixInsertTimestamp: (event: SendToUsersEventResponse) => {
    return event.insertUserTimestamp || event.insertTimestamp;
  }
};
