import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { RouterNavigatedPayload, routerNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { WenBreakpointObserver } from '@portal/wen-components';
import { ReplaySubject, Subject, distinctUntilChanged, first, map, pairwise, switchMap, filter, shareReplay, takeUntil } from 'rxjs';
import { DataContext } from '../../common/types/data-context';
import { RootState } from '../../store/root/public-api';
import { selectOutletDatas } from '../../store/root/root.selectors';

@Injectable()
export class DataContextHelper implements OnDestroy {

  constructor(
    private readonly store: Store<RootState>,
    private readonly actions$: Actions,
    private readonly breakpointObserver: WenBreakpointObserver
  ) {
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      takeUntil(this.onDestroy$),
    ).subscribe((action) => this.routerNavigated$.next(action.payload));
  }

  private onDestroy$ = new Subject<void>();
  private routerNavigated$ = new ReplaySubject<RouterNavigatedPayload>(1);

  selectCurrentDataContext() {
    return this.store.pipe(
      select(selectOutletDatas),
      first(),
      map((outletDatas) => {
        const isMobile = this.breakpointObserver.isSmallDevice;
        const outletData = isMobile ? outletDatas.primaryData : outletDatas.sidebarData;
        return outletData?.dataContext;
      })
    );
  }

  onDataContextChanges() {
    return this.routerNavigated$.pipe(
      switchMap(() => {
        return this.selectCurrentDataContext();
      }),
      distinctUntilChanged(),
      pairwise(),
      filter(([fromContext, toContext]) => {
        return !(fromContext === DataContext.PROFILE && toContext === DataContext.PROFILE);
      }),
      map(([fromContext, toContext]) => { return { fromContext, toContext }; }),
      shareReplay(1),
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
