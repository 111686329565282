@if (isAdmin$ | async) {
  <div class="wen-chat-detail-add-member-header" portal-body-2 wen-primary-color>
    <div [ngClass]="{'disabled': isMemberAddDisabled }" class="wen-chat-detail-add-member-action" (click)="navigateToAddMemberForGroupChat()">
      <wen-icon class="wen-icon-big" [iconName]="'add_user'"></wen-icon>
      {{ "GROUP_CHAT_ADD_MEMBERS" | translate }}
    </div>
    <span class="wen-chat-detail-add-member-counter">{{ (memberListView$ | async).length }}/{{ maxNumberOfUsers }}</span>
  </div>
}
@for (listItem of memberListView$ | async; track listItem) {
  <wen-user-list-item [user]="listItem.member" [userNavigable]="listItem.member.userId">
    <wen-user-role [userRole]="listItem.member.role" wen-user-description></wen-user-role>
    @if (listItem.member.canHaveActions) {
      <wen-icon
        [iconName]="'more'"
        [color]="contextMenuOpenerFillColor"
        (click)="openContextMenu(listItem)"
        wen-user-after>
      </wen-icon>
    }
  </wen-user-list-item>
}
