import { Injectable } from '@angular/core';
import { MassDataContainer } from '@portal/wen-data-core';
import { contactsMassDataIdentifier } from '../constants';
import { ContactListEntity } from '../contacts.state';

@Injectable()
export class ContactsMassDataContainer extends MassDataContainer<ContactListEntity> {

  getIdentifier() {
    return contactsMassDataIdentifier;
  }

}
