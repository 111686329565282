import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { CommentDeleteResponse, CommentListResponse } from '@portal/wen-backend-api';
import { distinctUntilChanged, map, mergeMap, Observable, switchMap, withLatestFrom } from 'rxjs';
import { WenComment } from '../../../../shared/components/comments/types';
import { fetchReactionsForMessages } from '../../reactions/reaction.actions';
import { RootState } from '../../root/public-api';
import { fetchCommentCountForParentId, fetchCommentsForParentElements, updateComments } from '../comments.actions';
import { selectCommentsForMessage } from '../comments.selectors';
import { CommentEntity } from '../comments.state';

export const createUpdateCommentsEffect = (
  actions$: Actions,
  commentList$: Observable<CommentListResponse>,
  currentParentId$: Observable<string>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(fetchCommentsForParentElements),
      mergeMap(() => commentList$),
      distinctUntilChanged(),
      withLatestFrom(currentParentId$),
      switchMap(([{ content, ...paging }, currentParentId]) => {
        if (!content) {
          // currently there is no handling of error response, therefore the flow is swallowed
          return;
        }
        const commentIds = content.map(c => c.id);
        return [
          fetchReactionsForMessages({ messageIds: commentIds, parentIds: commentIds }),
          updateComments({ comments: content, paging, parentId: currentParentId })
        ];
      })
    )
  );
};

export const refreshAfterCommentCreationEffect = (
  newComment$: Observable<WenComment>,
  currentParentId$: Observable<string>,
  currentTimestamp$: Observable<string>,
  store: Store<RootState>
) => {
  return createEffect(() =>
    reTriggerCommentFetches(newComment$, currentParentId$, currentTimestamp$, store)
  );
};

export const refreshAfterCommentDeleteEffect = (
  deleteComment$: Observable<CommentDeleteResponse>,
  currentParentId$: Observable<string>,
  currentTimestamp$: Observable<string>,
  store: Store<RootState>
) => {
  return createEffect(() =>
    reTriggerCommentFetches(deleteComment$, currentParentId$, currentTimestamp$, store)
  );
};

const reTriggerCommentFetches = (
  trigger$: Observable<WenComment | CommentDeleteResponse>,
  currentParentId$: Observable<string>,
  currentTimestamp$: Observable<string>,
  store: Store<RootState>
) => {
  return trigger$.pipe(
    withLatestFrom(currentParentId$, currentTimestamp$),
    concatLatestFrom(([_, parentId]) => store.pipe(
      select(selectCommentsForMessage),
      map(projectorFn => projectorFn(parentId)),
      map((commentEntity: CommentEntity) => commentEntity.pagingInformation)
    )),
    switchMap(([[_, parentId, timestamp], pagingData]) => {
      const sizeRequest = pagingData.page === 0 ? pagingData.size : (pagingData.page + 1) * pagingData.size;
      return [
        fetchCommentCountForParentId({ parentId }),
        fetchCommentsForParentElements({
          parentIds: [parentId],
          size: sizeRequest,
          filter: `createTimestamp < '${timestamp}'`
        })
      ];
    })
  );
};
