import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, select } from '@ngrx/store';
import { ListEventRequestParameters, SocketIoService } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, share } from 'rxjs/operators';
import { WenRouteId } from '../../../frame/routing/types';
import { selectorWithParam } from '../../common/util/selector-with-param';
import { selectActiveFiltersByEntityType } from '../filter/filter.selectors';
import { Filter, FilterEntityType, FilterType } from '../filter/models/filter';
import { FilterHelperService } from '../filter/services/filter-helper.service';
import { RootState } from '../root/public-api';
import { selectOutletIds } from '../root/root.selectors';
import { createEventDetailEffect } from './effects/event-detail.effect';
import { createEventDiscoveryListEffect, createEventDiscoveryListFilterUpdateEffect, createEventDiscoveryListNavigationEffect, resetDiscoveryEventListPagingOnFilterChange, setNotLoadedStateForDiscoveryEventListOnFilterChange } from './effects/event-discovery-list.effect';
import { fetchNextPageEvent, listenForDiscoverEventNextPage, listenForUserEventNextPage } from './effects/event-paging.effect';
import { createSubscriptionExtractEffect } from './effects/event-subscription-extract.effect';
import { createEventSubscribeEffect, createEventSubscriptionEffect, createEventUnSubscribeEffect } from './effects/event-subscription.effect';
import { createUserEventListEffect, createUserEventListFilterUpdateEffect, createUserEventListNavigationEffect, resetUserEventListPagingOnFilterChange, setNotLoadedStateForUserEventListOnFilterChange } from './effects/event-user-list.effect';
import { smartDistinctUntilChanged } from '@portal/wen-components';

@Injectable()
export class EventEffect {

  private readonly eventDetail$ = this.socketIOService.events.detail.listen.pipe(
    share()
  );

  currentPage$ = this.store.pipe(
    select(selectOutletIds),
    map(({ sidebarId, primaryId }) => sidebarId ?? primaryId),
    distinctUntilChanged(),
  );

  currentFilters$ = this.store.pipe(
    selectorWithParam(selectActiveFiltersByEntityType, FilterEntityType.EVENT_LISTS),
    distinctUntilChanged()
  );

  activeFilters$: Observable<ListEventRequestParameters> = this.currentFilters$.pipe(
    concatLatestFrom(() => this.currentPage$),
    map(([activeFilters, routeId]) => {
      const pageFilters = this.getPageBasedFilters(activeFilters, routeId);
      return pageFilters;
    }),
    smartDistinctUntilChanged(),
    map((activeFilters) => {
      const requestParams = this.filterHelper.convertFiltersToRequestParameters(activeFilters);
      return requestParams;
    })
  );

  carriedOverFiltersDuringNavigation$: Observable<ListEventRequestParameters> = this.currentPage$.pipe(
    concatLatestFrom(() => this.currentFilters$),
    map(([routeId, activeFilters]) => {
      const pageFilters = this.getPageBasedFilters(activeFilters, routeId);
      const requestParams = this.filterHelper.convertFiltersToRequestParameters(pageFilters);

      return requestParams;
    }),
    distinctUntilChanged()
  );

  fetchUserListOnNavigation$ =
    createUserEventListNavigationEffect(this.actions$, this.carriedOverFiltersDuringNavigation$, this.socketIOService, this.store);
  userEventList$ = createUserEventListEffect(this.actions$, this.store);
  updateUserEventListLoadingStateOnFilterChange$ =
    setNotLoadedStateForUserEventListOnFilterChange(this.activeFilters$);
  fetchUserEventListOnFilterChange$ =
    createUserEventListFilterUpdateEffect(this.activeFilters$, this.currentPage$, this.socketIOService);
  resetUserListPagingOnFilterChange$ = resetUserEventListPagingOnFilterChange(this.activeFilters$);


  fetchDiscoveryListOnNavigation$ =
    createEventDiscoveryListNavigationEffect(this.actions$, this.carriedOverFiltersDuringNavigation$, this.socketIOService, this.store);
  discoveryList$ = createEventDiscoveryListEffect(this.actions$, this.store);
  updateDiscoveryListLoadingStateOnFilterChange$ =
    setNotLoadedStateForDiscoveryEventListOnFilterChange(this.activeFilters$);
  fetchDiscoveryListOnFilterChange$ =
    createEventDiscoveryListFilterUpdateEffect(this.activeFilters$, this.currentPage$, this.socketIOService);
  resetDiscoveryListPagingOnFilterChange$ = resetDiscoveryEventListPagingOnFilterChange(this.activeFilters$);


  listenForUserEventNextPage$ = listenForUserEventNextPage(this.store, this.actions$, this.socketIOService);
  listenForDiscoverEventNextPage$ = listenForDiscoverEventNextPage(this.store, this.actions$, this.socketIOService);
  fetchNextPage$ = fetchNextPageEvent(this.store, this.actions$, this.activeFilters$);


  eventDetailUpdates$ = createEventDetailEffect(this.store, this.actions$, this.eventDetail$, this.socketIOService);

  eventEntitySeparation$ = createSubscriptionExtractEffect(this.actions$);


  eventSubscription$ = createEventSubscriptionEffect(this.actions$, this.socketIOService);
  eventSubscribe$ = createEventSubscribeEffect(this.store, this.actions$, this.socketIOService);
  eventUnSubscribe$ = createEventUnSubscribeEffect(this.store, this.actions$, this.socketIOService);

  constructor(
    private readonly actions$: Actions,
    private readonly socketIOService: SocketIoService,
    private store: Store<RootState>,
    private filterHelper: FilterHelperService
  ) { }

  private getPageBasedFilters(activeFilters: Filter[], routeId: WenRouteId) {
    const filteredFilters = routeId === WenRouteId.EVENT_LIST
      ? activeFilters.filter(f => f.filterType !== FilterType.SELECT)
      : activeFilters;

    return this.filterHelper.removeUserSearchFilters(filteredFilters);
  }

}
