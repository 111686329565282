@if (pendingJoinRequests$ | async; as pendingJoinRequests) {
  <div class="wen-pending-join-request-container">
    <div portal-subheading-2>{{ "CHANNEL_PENDING_JOIN_REQUESTS_LIST_LABEL" | translate }}</div>
    @if (pendingJoinRequests.length) {
      @for (joinRequest of pendingJoinRequests; track joinRequest) {
        <wen-user-list-item  [user]="joinRequest.user" [userNavigable]="joinRequest.user.userId">
          <div class="wen-list-item-after" wen-user-after>
            <wen-icon (click)="acceptRequest(joinRequest.id, joinRequest.user.displayName)" class="wen-icon-medium" iconName="checkmark"></wen-icon>
            <wen-icon (click)="denyRequest(joinRequest.id, joinRequest.user.displayName)" class="wen-icon-medium" iconName="close"></wen-icon>
          </div>
        </wen-user-list-item>
      }
    } @else {
      <img src="/assets/wen-widget/image/empty-placeholders/pic-empty-list-wen-sitting-robot.svg"/>
    }
  </div>
}
