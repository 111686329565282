import { Injectable } from '@angular/core';
import { map, distinctUntilChanged, shareReplay, startWith, switchMap, interval, EMPTY } from 'rxjs';
import { ConnectionMonitor } from './types';
import { WenOAuthService } from '../user-management/wen-oauth.service';

@Injectable()
export class AuthServerConnectionMonitor implements ConnectionMonitor {
  public readonly isOnline$ = this.authService.isDiscoveryDocumentLoaded().pipe(
    map(isLoaded => this.authService.hasValidTokens() || isLoaded),
    startWith(true),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private readonly reconnectTimer$ = this.isOnline$.pipe(
    switchMap((isOnline) => isOnline ? EMPTY : interval(5000))
  );

  constructor(private readonly authService: WenOAuthService) {
    this.reconnectTimer$.subscribe(() => {
      this.authService.loadDiscoveryDocument();
    });
  }
}
