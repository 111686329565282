import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ContextMenuHandler } from './openers/context-menu-opener';
import { LegacyContextMenuOverlayService } from './openers/context-menu-overlay.service';
import { DialogHandler } from './openers/dialog-opener';
import { OverlayManager } from './overlay-manager';

@NgModule({
  imports: [
    MatDialogModule,
  ],
  providers: [
    DialogHandler,
    LegacyContextMenuOverlayService,
    ContextMenuHandler,
    OverlayManager,
  ],
})
export class WenOverlayModule { }
