import { EntityAdapter } from '@ngrx/entity';
import { MassDataEntityState, MassDataUpdates } from '../types';

export class MassDataOperations<ENTITY> {

  constructor(
    private entityAdapter: EntityAdapter<ENTITY>,
  ) { }

  updateMany(
    collection: MassDataEntityState<ENTITY>,
    updates: MassDataUpdates<ENTITY>
  ) {
    return this.entityAdapter.updateMany(updates, collection);
  }

  setAll(
    collection: MassDataEntityState<ENTITY>,
    data: ENTITY[]
  ) {
    return this.entityAdapter.setAll(data, collection);
  }

  upsertMany(
    collection: MassDataEntityState<ENTITY>,
    data: ENTITY[]
  ) {
    return this.entityAdapter.upsertMany(data, collection);
  }

  removeMany(
    collection: MassDataEntityState<ENTITY>,
    ids: string[]
  ) {
    return this.entityAdapter.removeMany(ids, collection);
  }

  removeAll(
    collection: MassDataEntityState<ENTITY>,
  ) {
    return this.entityAdapter.removeAll(collection);
  }

}
