import { Injectable, OnDestroy, Renderer2, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import PhotoSwipe, { SlideData, PhotoSwipeOptions } from 'photoswipe';
import Content from 'photoswipe/dist/types/slide/content';
import { VideoPlayerComponent } from '../../video-player/video-player.component';
import { LightboxImageData } from '../models/lightbox.models';

@Injectable()
export class PhotoSwipeService implements OnDestroy {
  private galleryItems: SlideData[] = [];
  private parentElement: HTMLElement;
  private currentPhotoSwipe: PhotoSwipe;

  constructor(
    private translateService: TranslateService,
    private renderer: Renderer2,
    private vcr: ViewContainerRef
  ) { }

  registerGalleryItem(item: LightboxImageData) {
    const url = item.src.toString();
    this.galleryItems.push({
      src: url,
      w: item.width,
      h: item.height,
      msrc: item.msrc,
      type: item.type
    });
  }

  initPhotoSwipe(): PhotoSwipe {
    const options: PhotoSwipeOptions = {
      dataSource: this.galleryItems,
      appendToEl: this.parentElement,
      bgOpacity: 1,
      initialZoomLevel: 'fit',
      clickToCloseNonZoomable: false,
      wheelToZoom: true,
      zoom: false,
      close: false,
      counter: false,
      pinchToClose: false,
      errorMsg: this.translateService.instant('ERROR_IMAGE_LOAD_IN_LIGHTBOX'),
      bgClickAction: () => { },
      imageClickAction: () => { },
      hideAnimationDuration: 200
    };
    this.currentPhotoSwipe = new PhotoSwipe(options);
    this.currentPhotoSwipe.addFilter('contentErrorElement', (cEE, content) => {
      const el = this.renderer.createElement('img');
      this.renderer.setProperty(el, 'src', content.data.msrc);
      this.renderer.addClass(el, 'pswp_error_img');
      return el;
    });
    this.currentPhotoSwipe.addFilter('isContentZoomable', (_, content) => {
      return !this.isVideoContent(content);
    });
    this.currentPhotoSwipe.addFilter('isContentZoomable', (_, content) => {
      return !this.isVideoContent(content);
    });
    this.currentPhotoSwipe.on('contentAppend', (event) => {
      if (this.isVideoContent(event.content)) {
        event.preventDefault();
        event.content.appendImage();
      }
    });
    this.currentPhotoSwipe.on('contentResize', (event) => {
      const { content } = event;
      if (this.isVideoContent(content)) {
        event.preventDefault();
      }
    });
    this.currentPhotoSwipe.on('contentLoad', (event) => {
      const { content } = event;
      if (this.isVideoContent(content)) {
        event.preventDefault();
        this.createVideoPlayer(content);
      }
    });
    this.currentPhotoSwipe.init();
    return this.currentPhotoSwipe;
  }

  registerAppendToElement(parentElement: HTMLElement) {
    this.parentElement = parentElement;
  }

  destroyPhotoSwipe() {
    if (this.currentPhotoSwipe) {
      this.currentPhotoSwipe.destroy();
      this.currentPhotoSwipe = null;
    }
  }

  private createVideoPlayer(content: Content) {
    this.renderer.removeClass(content.slide.container, 'pswp__zoom-wrap');
    const divEl = document.createElement('div');
    this.renderer.addClass(divEl, 'pswp__video-container');
    const componentRef = this.vcr.createComponent(VideoPlayerComponent);
    componentRef.setInput('src', content.data.src);
    // to make the lifecycle hooks kick in
    componentRef.changeDetectorRef.detectChanges();
    this.renderer.appendChild(divEl, componentRef.location.nativeElement);
    content.element = divEl;
  }

  private isVideoContent(slideData: SlideData) {
    return slideData.type === 'video';
  }

  ngOnDestroy(): void {
    this.destroyPhotoSwipe();
    this.galleryItems = [];
  }
}
