import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PollManager } from '@portal/wen-components';
import { ChannelMessageComposer, ChosenOptionRequest, EmbedDTOType, GetPollRequest, PollModel, SocketIoService } from '@portal/wen-backend-api';
import { combineLatest, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { updateDraftChannelMessage } from '../../store/channel/channel.actions';
import { selectCurrentChannel } from '../../store/channel/channel.selectors';
import { RootState } from '../../store/root/public-api';

@Injectable()
export class PollService extends PollManager {
  getPollResponse$: Observable<PollModel>;
  chooseOptionResponse$: Observable<PollModel>;
  updatePollResponse$: Observable<PollModel>;


  constructor(
    private socketIOService: SocketIoService,
    private store: Store<RootState>,
    private channelMessageComposer: ChannelMessageComposer,
  ) {
    super();
    this.setupSocketConnections();
  }


  getPoll(pollRequest: GetPollRequest) {
    this.socketIOService.messageEmbed.poll.get.emit(pollRequest);
  }

  chooseOption(selectedOption: ChosenOptionRequest) {
    this.socketIOService.messageEmbed.poll.chooseOption.emit(selectedOption);
  }

  createPoll(
    newPoll: PollModel
  ) {
    return combineLatest([
      this.store.pipe(select(selectCurrentChannel)),
    ]).pipe(
      first(),
      switchMap(([channel]) => {
        const { id: channelId } = channel;
        const poll: PollModel = {
          ...newPoll,
          channelId
        };
        this.store.dispatch(updateDraftChannelMessage({
          message: {
            contextId: channelId,
            pollEmbed: {
              pollId: null,
              type: EmbedDTOType.POLL,
              poll
             }
          }
        }));
        return of(null);
      })
    );
  }

  private setupSocketConnections() {
    this.getPollResponse$ = this.socketIOService.messageEmbed.poll.get.listen;
    this.chooseOptionResponse$ = this.socketIOService.messageEmbed.poll.chooseOption.listen;
    this.updatePollResponse$ = this.socketIOService.messageEmbed.poll.update.listen;
  }
}
