import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MessageTypeSelectorModule } from '../../../../../shared/components/message-type-selector/message-type-selector.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { ChatSendScheduledMessageModule } from '../../../common/components/chat-send-scheduled-message/chat-send-scheduled-message.module';
import { NavigateChatScheduledMessagesViewButtonComponent } from '../../../common/components/navigate-chat-scheduled-messages-view-button/navigate-chat-scheduled-messages-view-button.component';
import { ChatMessageSendFieldComponent } from './chat-message-send-field.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    MessageTypeSelectorModule,
    ChatSendScheduledMessageModule,
  ],
  exports: [
    ChatMessageSendFieldComponent,
    NavigateChatScheduledMessagesViewButtonComponent,
  ],
  declarations: [
    ChatMessageSendFieldComponent,
    NavigateChatScheduledMessagesViewButtonComponent,
  ],
})
export class ChatMessageSendFieldModule { }
