/**
 * Type type of the search result determines which endpoint returned that:
 *  - EXISTING_CONTACT: contact search
 *  - GROUP_CHATROOM: existing group chat room search
 *  - FURTHER_USER_RESULT: chat user search
 */
export enum ChatMemberEditSearchEntityType {
  EXISTING_CONTACT = 'EXISTING_CONTACT',
  GROUP_CHATROOM = 'GROUP_CHATROOM',
  FURTHER_USER_RESULT = 'FURTHER_USER_RESULT',
}

export type ChatMemberEditSearchEntity = {
  entityType: ChatMemberEditSearchEntityType;
  listItemEntity: {
    id: string;
    title: string;
    avatarUrl: string;
  };
};
