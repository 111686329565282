import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { WenOAuthService } from '../../../../core/services/user-management/wen-oauth.service';
import { ActivatedRoute } from '@angular/router';
import { PermissionLevel } from '../../../../core/services/user-management/permission-level';
import { PopupEventHandler, PopupFlowEvent } from '../../../../core/services/util/popup-event-handler';

@Component({
  selector: 'wen-logout',
  template: ``,
})
export class LogoutComponent implements OnInit {

  constructor(
    private wenAuthService: WenOAuthService,
    private route: ActivatedRoute,
    private popupEventHandler: PopupEventHandler
  ) { }

  ngOnInit(): void {
    const currentUserIsAnon = this.wenAuthService.getUserData()?.permissionLevel === PermissionLevel.ANONYMOUS;
    const onlyLogoutAnon = this.route.snapshot.queryParamMap.get('onlyAnon')?.toLocaleLowerCase() === 'true';

    if (!onlyLogoutAnon || currentUserIsAnon) {
      this.wenAuthService.logout().pipe(first()).subscribe(() => {
        this.popupEventHandler.postPopupEvent(PopupFlowEvent.LOGGED_OUT);
      });
    } else {
      this.popupEventHandler.postPopupEvent(PopupFlowEvent.CONTINUE);
    }
  }
}
