import { inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';
import { FeatureEnablementService } from '../../core/services/configuration/feature-enablement';

export function pageUpdateInitializer(featureEnabledService: FeatureEnablementService) {
  const swUpdate = inject(SwUpdate, { optional: true });
  if (swUpdate && featureEnabledService.isPwaEnabled() && featureEnabledService.isAutoRefreshByAssetChangesEnabled()) {
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        document.location.reload();
      });
  }
  return () => Promise.resolve();
}
