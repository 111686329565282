import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SECTION_LIMIT, SectionService } from '../../../../../core/store/sections/sections.facade';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { SectionProps } from '../../../../../core/common/util/section.model';
import { SectionEntity } from '../../../../../core/store/sections/sections.state';
import { combineLatest, first, map, Observable, Subject } from 'rxjs';
import { ChannelIndicatorComponent, CircleImageViewModule, IndicatorType, ThousandFormatModule, WenCardModule, WenIconModule, WenSectionModule, WenTestIdModule } from '@portal/wen-components';
import { TranslateModule } from '@ngx-translate/core';
import { WenNativeApi } from '@portal/wen-native-api';
import { RootState } from '../../../../../core/store/root/public-api';
import { select, Store } from '@ngrx/store';
import { selectUserChannels } from '../../../../../core/store/channel/channel.selectors';
import { setSubscriptionToChannelById } from '../../../../../core/store/channel/channel.actions';
import { GreyPalette } from '../../../../../shared/directives/colorize/palette-provider';
import { isNullOrUndefined } from '../../../../../core/common/operators/null-check-util';
import { DataContext } from '../../../../../core/common/types/data-context';
import { selectUnitedKioskFlatCredential } from '../../../../../core/store/wallet/wallet.selectors';

type ChannelSectionItemModel = {
  id: string;
  title: string;
  icon?: string;
  subscriptions: number;
  restricted: boolean;
  isPrivate?: boolean;
  geo?: boolean;
  readingContent?: boolean;
  isSubscribed: boolean;
  iconColor: string;
  iconName: string;
};

@Component({
  selector: 'wen-channel-section',
  imports: [
    TranslateModule,
    CommonModule,
    WenIconModule,
    WenSectionModule,
    CircleImageViewModule,
    WenCardModule,
    ThousandFormatModule,
    WenTestIdModule,
    ChannelIndicatorComponent,
  ],
  templateUrl: './channel-section.component.html',
  styleUrls: ['./channel-section.component.scss'],
  standalone: true
})
export class ChannelSectionComponent implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  private sectionProps: SectionProps;

  sectionName: string;
  sectionItems$: Observable<ChannelSectionItemModel[]>;
  imageSize = { height: 110 };
  sectionLimit = SECTION_LIMIT;

  lockColor = GreyPalette.GREY7;

  @Input() set section(section: SectionEntity) {
    this.sectionName = section.name;
    this.sectionProps = {
      filterRef: section.filterRef,
      filterEntityType: section.filterType,
      filterId: section.filterId
    };

    this.sectionItems$ = combineLatest([
      this.store.pipe(select(selectUserChannels)),
      this.sectionService.loadSectionContent(section.id, section.filterRef, DataContext.CHANNEL),
      this.store.pipe(select(selectUnitedKioskFlatCredential))
     ]).pipe(
      map(([userChannels, sectionChannelItems, unitedKioskFlatCredential]) => {
        const channelWithOpenInvites = userChannels.filter(c => c.invite).map(c => c.id);
        const userChannelIds = userChannels.filter(c => isNullOrUndefined(c.invite)).map(c => c.id);
        const channels = sectionChannelItems?.map((channel) => {
          const isSubscribed = userChannelIds?.includes(channel.id);
          const isInvited = channelWithOpenInvites?.includes(channel.id);
          return {
            ...channel,
            isSubscribed,
            iconColor: this.getIconColor(channel, isSubscribed, isInvited, unitedKioskFlatCredential.active),
            iconName: this.getIconName(channel, isSubscribed, isInvited, unitedKioskFlatCredential.active)
          };
        });
        return channels;
      })
     );
  }

  @Output() showAll = new EventEmitter<SectionProps>();

  isNativeApp = this.wenNativeApi.isReactNativeApp();

  constructor(
    private sectionService: SectionService,
    private navigationHelper: WenNavigationHelper,
    private wenNativeApi: WenNativeApi,
    private store: Store<RootState>,
  ) { }

  navigateToChannelView(channelId: string){
    this.navigationHelper.navigateToChannelView(channelId);
  }


  navigateToChannelDetail(channelId: string){
    this.navigationHelper.navigateToChannelDetail(channelId);
  }

  showAllElements() {
    this.showAll.emit(this.sectionProps);
  }

  onQuickActionClick(channel: ChannelSectionItemModel) {
    this.store.pipe(select(selectUnitedKioskFlatCredential), first()).subscribe(
      (unitedKioskFlatCredential) => {
        if(channel.isPrivate && !(channel.restricted && channel.readingContent && unitedKioskFlatCredential.active)) {
          this.navigateToChannelDetail(channel.id);
          return;
        }

        this.store.dispatch(setSubscriptionToChannelById({ channelId: channel.id, setSubscribed: !channel.isSubscribed }));
      }
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  trackById(index, item: ChannelSectionItemModel) {
    return item.id;
  }

  getIndicatorType(channel: ChannelSectionItemModel) {
    if (channel.readingContent) {
      return (channel.restricted && channel.isPrivate) ? IndicatorType.PREMIUM_READING_CHANNEL : IndicatorType.READING_CHANNEL;
    }
    if (channel.geo) {
      return IndicatorType.GEO_CHANNEL;
    }
  }

  getIconName(channel: ChannelSectionItemModel, isSubscribed: boolean, isInvited: boolean, hasUKFlatCredential: boolean) {
    if (isSubscribed) {
      return 'save_active';
    }
    if (channel.isPrivate && channel.readingContent && channel.restricted && hasUKFlatCredential) {
      return 'save_inactive';
    }
    if (channel.isPrivate && !isInvited) {
      return 'lock';
    }
    return 'save_inactive';
  }

  getIconColor(channel: ChannelSectionItemModel, isSubscribed: boolean, isInvited: boolean, hasUKFlatCredential: boolean) {
    if (isInvited) {
      return this.lockColor;
    }
    if (channel.isPrivate && channel.readingContent && channel.restricted && hasUKFlatCredential) {
      return null;
    }
    if(channel.isPrivate && !isSubscribed) {
      return this.lockColor;
    }
    return null;
  }
}
