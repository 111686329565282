import { EmbeddedDocumentDTO, EmbeddedExpandableDTO, EmbeddedForwardDTO, EmbeddedLinkDTO, EmbeddedLocationDTO, EmbeddedMediaDTO, EmbeddedNotificationDTO, EmbeddedPollDTO, EmbeddedWeatherDTO, EmbedDTOType, EmbedDTOTypes } from './embed';

export const isLinkEmbed = (embed: EmbedDTOTypes): embed is EmbeddedLinkDTO => embed?.type === EmbedDTOType.LINK;
export const isDocumentEmbed = (embed: EmbedDTOTypes): embed is EmbeddedDocumentDTO => embed?.type === EmbedDTOType.DOCUMENT;
export const isExpandableEmbed = (embed: EmbedDTOTypes): embed is EmbeddedExpandableDTO => embed?.type === EmbedDTOType.EXPANDABLE;
export const isPollEmbed = (embed: EmbedDTOTypes): embed is EmbeddedPollDTO => embed?.type === EmbedDTOType.POLL;
export const isMediaEmbed = (embed: EmbedDTOTypes): embed is EmbeddedMediaDTO => embed?.type === EmbedDTOType.MEDIA;
export const isLocationEmbed = (embed: EmbedDTOTypes): embed is EmbeddedLocationDTO => embed?.type === EmbedDTOType.LOCATION;
export const isWeatherEmbed = (embed: EmbedDTOTypes): embed is EmbeddedWeatherDTO => embed?.type === EmbedDTOType.WEATHER;
export const isNotificationEmbed = (embed: EmbedDTOTypes): embed is EmbeddedNotificationDTO => embed?.type === EmbedDTOType.NOTIFICATION;
export const isForwardEmbed = (embed: EmbedDTOTypes): embed is EmbeddedForwardDTO => embed?.type === EmbedDTOType.FORWARD;

export const categorizeEmbeds = (embeds: EmbedDTOTypes[] = []) => {
  return {
    mediaEmbed: embeds.find(isMediaEmbed),
    linkEmbed: embeds.find(isLinkEmbed),
    documentEmbed: embeds.find(isDocumentEmbed),
    locationEmbed: embeds.find(isLocationEmbed),
  };
};
