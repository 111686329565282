import { Component, HostBinding, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RoomType } from '@portal/wen-backend-api';
import { filter, map, Observable } from 'rxjs';
import { selectCurrentRoom } from '../../../../../../core/store/chat/chat.selectors';
import { ChatRoomEntity } from '../../../../../../core/store/chat/chat.state';
import { headerCommandClicked } from '../../../../../../core/store/header/header.actions';
import { HeaderCommandType } from '../../../../../../core/store/header/models/HeaderCommand';
import { RootState } from '../../../../../../core/store/root/public-api';

@Component({
  selector: 'wen-chat-view-desktop-header',
  templateUrl: './chat-view-desktop-header.component.html',
  styleUrls: ['./chat-view-desktop-header.component.scss']
})
export class ChatViewDesktopHeaderComponent implements OnInit {

  @HostBinding('class.wen-chat-view-desktop-header') className = true;

  private currentRoom$: Observable<ChatRoomEntity>;
  roomHeader$: Observable<{ title: string; members: string; isMuted: boolean }>;

  constructor(
    private store: Store<RootState>,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentRoom$ = this.store.pipe(
      select(selectCurrentRoom)
    );
    this.roomHeader$ = this.currentRoom$.pipe(
      filter(v => !!v),
      map(({ members, details }) => {
        const title = details.title;
        const memberCount = members?.length;
        return {
          title,
          ...details?.type === RoomType.GROUP && { members: `${memberCount} ${this.translateService.instant('GROUP_CHAT_MEMBERS')}` },
          isMuted: details.isMuted
        };
      })
    );
  }

  openDetail() {
    this.store.dispatch(headerCommandClicked({ commandType: HeaderCommandType.OPEN_ROOM_DETAIL }));
  }
}
