import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SocketIoService } from '@portal/wen-backend-api';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { INVITATION_ID_QUERY_PARAM } from '../navigation/query-params';
import { WenNavigationHelper } from '../navigation/types';
import { NetworkLoadedGuard } from '../network/network-loaded-guard';
import { ExclusiveInviteHelper } from '../util/exclusive-invite-helper';
import { AuthStateValidator, NotAuthenticatedError } from './auth-state-validator.service';
import { PermissionLevel } from './permission-level';
import { WenOAuthService } from './wen-oauth.service';

export const registrationGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(RegistrationGuard).canActivate(route, state);
};

@Injectable()
export class RegistrationGuard  {

  constructor(
    private authStateValidator: AuthStateValidator,
    private wenOAuthService: WenOAuthService,
    private navigationHelper: WenNavigationHelper,
    private exclusiveInviteHelper: ExclusiveInviteHelper,
    private networkLoadedGuard: NetworkLoadedGuard,
    private socketIoService: SocketIoService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const inviteId = route.queryParamMap.get(INVITATION_ID_QUERY_PARAM);
    return this.authStateValidator.validateAuthState().pipe(
      switchMap((userData) => {
        if (inviteId) {
          this.socketIoService.invites.confirmInvitation.emit({ id: inviteId });
        }
        if (userData.permissionLevel === PermissionLevel.REGISTERED_USER) {
          this.navigationHelper.navigateToWelcomeScreen();
          return of(false);
        }
        const currentUrl = state.url;
        const isCodeDialogNeeded = this.exclusiveInviteHelper.isCodeDialogNeeded();
        if (isCodeDialogNeeded) {
          this.navigationHelper.navigateToExclusiveInvite(currentUrl);
          return of(false);
        }
        return this.networkLoadedGuard.canActivateChild(route, state).pipe(
          first(),
          map((hasLoadedNetwork) => hasLoadedNetwork && Boolean(userData))
        );
      }),
      catchError((error) => {
        if (error instanceof NotAuthenticatedError) {
          return this.wenOAuthService.initCodeFlow({
            redirectAfterLogin: state.url,
            anonymousAutoLogin: true,
            needsDataProtectionDialog: false,
          }).pipe(
            map(() => false),
            catchError(() => {
              this.navigationHelper.navigateToErrorPage();
              return of(false);
            })
          );
        }
        return of(false);
      })
    );
  }

}
