import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '@portal/wen-components';
import { ChatMessageDeletedComponent } from './chat-message-deleted.component';


@NgModule({
  imports: [TranslateModule.forChild(), WenIconModule],
  exports: [ChatMessageDeletedComponent],
  declarations: [ChatMessageDeletedComponent],
})
export class ChatMessageDeletedModule { }
