import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { BroadcastInformation, ReactionContext } from '@portal/wen-backend-api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ContextMenuData, CONTEXT_MENU_DATA } from '../../../context-menu/context-menu-data';
import { ContextMenuRef, CONTEXT_MENU_REF } from '../../../context-menu/context-menu-ref';
import { AncestorReference, ReactionHandler } from '../../providers/reaction-handler';

export interface ReactionSelectorData {
  reactionParentId: string;
  reactionParentType: ReactionContext;
  broadcastInformation?: BroadcastInformation;
  possibleBlockingAncestor?: AncestorReference;
}

@Component({
  selector: 'wen-reaction-selector',
  templateUrl: './reaction-selector.component.html',
  styleUrls: ['./reaction-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactionSelectorComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private reactionSelectorData$ = new BehaviorSubject<ReactionSelectorData>(null);

  availableEmojiIds$: Observable<number[]>;
  currentlySelectedEmojiId: number;

  @Input() data: ReactionSelectorData;

  constructor(
    private reactionHandler: ReactionHandler,
    @Optional() @Inject(CONTEXT_MENU_DATA) private contextMenuData: ContextMenuData<{ reactionSelectorData: ReactionSelectorData }>,
    @Optional() @Inject(CONTEXT_MENU_REF) private contextMenuRef: ContextMenuRef
  ) { }

  ngOnInit(): void {
    this.setMenuOrInputData(this.contextMenuData);
    // TODO: Common context menu data API for reaction selector
    if ((this.contextMenuData.data as any).reactionSelectorData) {
      this.availableEmojiIds$ = this.reactionHandler.getAvailableEmojiIds();
    }
    this.reactionSelectorData$.pipe(
      map(value => value?.reactionParentId),
      distinctUntilChanged(),
      switchMap(reactionParentId => {
        return this.reactionHandler.getReactionSummaryFor(reactionParentId).pipe(
          filter(summary => !!summary),
        );
      }),
      map((reactionSummary) => {
        return reactionSummary.currentUsersSelection;
      }),
      takeUntil(this.onDestroy$)
    ).subscribe((selectedEmojiId) => {
      this.currentlySelectedEmojiId = selectedEmojiId;
    });
  }

  onReaction(clickedEmojiId: number) {
    const {
      reactionParentId,
      reactionParentType,
      broadcastInformation,
      possibleBlockingAncestor
    } = this.reactionSelectorData$.getValue();

    const emojiId = clickedEmojiId === this.currentlySelectedEmojiId ? null : clickedEmojiId;
    this.reactionHandler.sendUserReaction(emojiId, reactionParentId, reactionParentType, broadcastInformation, possibleBlockingAncestor);
  }

  tryCloseMenu(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.contextMenuRef) {
      this.contextMenuRef.close();
    }
  }

  private setMenuOrInputData(ctxMenuData: ContextMenuData<{ reactionSelectorData: ReactionSelectorData }>) {
    const data = 'reactionSelectorData' in ctxMenuData.data ? ctxMenuData.data : { reactionSelectorData: this.data };
    this.reactionSelectorData$.next(data.reactionSelectorData);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
