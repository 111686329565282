import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { PopperComponent } from './popper.component';

// TODO: Remove this module when the UI-Kit contains the improved popper component
@NgModule({
  imports: [
    OverlayModule,
    PortalModule
  ],
  declarations: [
    PopperComponent,
  ],
  exports: [
    PopperComponent,
  ]
})
export class PopperModule { }
