import { validateId } from '@portal/wen-backend-api';
import { BehaviorSubject, Observable, defaultIfEmpty, first, forkJoin, of, shareReplay, tap } from 'rxjs';

export class CachedItemLoader<T> {

  private readonly items = new BehaviorSubject<Map<string, T>>(new Map<string, T>());
  public readonly items$ = this.items.pipe();

  constructor(
    private itemLoader: (id: string) => Observable<T>,
    private idFn: (data: T) => string,
  ) { }

  private getOrLoadSingleItem(id: string) {
    const values = this.items.getValue();
    const existingItem = values.get(id);
    if (existingItem) {
      return of(existingItem);
    }
    return this.itemLoader(id);
  }

  getOrLoadItem(id: string | string[]) {
    const ids = Array.isArray(id) ? id : [id];
    const validIds = ids.filter((idItem) => validateId(idItem));
    if (validIds?.length !== ids?.length) {
      console.error('Invalid id provided for CachedItemLoader!');
    }
    const requests$ = validIds.map(itemId => {
      return this.getOrLoadSingleItem(itemId);
    });
    return forkJoin(requests$).pipe(
      tap((items) => {
        const values = this.items.getValue();
        items.forEach(item => values.set(this.idFn(item), item));
        this.items.next(values);
      })
    );
  }

  reloadCache() {
    const values = this.items.getValue();
    const itemReloads$ = Array.from(values.keys()).map((id) => {
      return this.itemLoader(id);
    });
    const reloadedData$ = forkJoin(itemReloads$).pipe(
      defaultIfEmpty([] as T[]),
      first(),
      shareReplay(1)
    );
    reloadedData$.subscribe(newDatadata => {
      const newData = new Map(newDatadata.map(item => [this.idFn(item), item]));
      this.items.next(newData);
    });
    return reloadedData$;
  }

  replaceCache(id: string, newItems: T[]) {
    const values = this.items.getValue();
    newItems.forEach(items => values.set(id, items));
    this.items.next(values);
  }

}
