import { Observable, map } from 'rxjs';
import { ChatMessageTransactionData } from './chat-transaction-data';

export abstract class ChatMessageBatchLoader {

  abstract loadNext(): Observable<ChatMessageTransactionData[]>;

  next() {
    return this.loadNext().pipe(
      map((result) => {
        const data = result || [];
        return { data };
      })
    );
  }

  abstract hasMore(): boolean;
}
