import { Category } from '@portal/wen-backend-api';
import { filterByJoinedObjectIds } from '../../../../core/store/filter/utils/rsql-utils';
import { Filterable } from './filter-item';

export const createFilterableFromCategory = (propertyName: string, category: Category): Filterable => {
  return {
    id: category.id,
    name: category.name,
    filterString: filterByJoinedObjectIds(propertyName, category.id)
  };
};
