import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { isWenRouteWithId } from '../../../../frame/routing/types';

export const paramBasedRouteReuseCheck = (paramName: string) => (
  futureRoute: ActivatedRouteSnapshot,
  currentRoute: ActivatedRouteSnapshot,
) => {
  const futureParam = futureRoute.paramMap.get(paramName);
  const currentParam = currentRoute.paramMap.get(paramName);
  if (!currentParam || !futureParam) {
    return false;
  }
  const angularDefaultCondition = futureRoute.routeConfig === currentRoute.routeConfig;
  return angularDefaultCondition && futureParam === currentParam;
};

export class WenRouteReuseStrategy implements RouteReuseStrategy {

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(futureRoute: ActivatedRouteSnapshot, currentRoute: ActivatedRouteSnapshot): boolean {
    const futureRouteData = futureRoute.data;
    if (isWenRouteWithId(futureRouteData) && futureRouteData.customRouteReuseCheck) {
      return futureRouteData.customRouteReuseCheck(futureRoute, currentRoute);
    }
    return futureRoute.routeConfig === currentRoute.routeConfig;
  }
}
