import { Injectable, inject } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { MassDataRepository, MultitronicMassDataRepository, PageRequestDescriptor } from '@portal/wen-data-core';
import { map } from 'rxjs';
import { PendingContactsMassDataContainer } from './pending-contacts-mass-data-container';


@Injectable()
export class PendingReceivedContactsMassDataRepository extends MassDataRepository<PendingContactsMassDataContainer> {

  private socketIoService = inject(SocketIoService);

  fetchPage(pagingParams: PageRequestDescriptor) {
    return this.socketIoService.contact.listPendingReceivedForUser.acknowledgement$(pagingParams).pipe(
      map(result => ({ data: result.contacts }))
    );
  }

}

@Injectable()
export class PendingSentContactsMassDataRepository extends MassDataRepository<PendingContactsMassDataContainer> {

  private socketIoService = inject(SocketIoService);

  fetchPage(pagingParams: PageRequestDescriptor) {
    return this.socketIoService.contact.listPendingSentForUser.acknowledgement$(pagingParams).pipe(
      map(result => ({ data: result.contacts }))
    );
  }

}


@Injectable()
export class PendingContactsMassDataRepository extends MultitronicMassDataRepository<PendingContactsMassDataContainer> {

  constructor(
    pendingReceivedContactsMassDataRepository: PendingReceivedContactsMassDataRepository,
    pendingSentContactsMassDataRepository: PendingSentContactsMassDataRepository
  ) {
    super([pendingReceivedContactsMassDataRepository, pendingSentContactsMassDataRepository]);
  }

}
