import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ChannelRelevantMessagesResponse, SocketIoService } from '@portal/wen-backend-api';
import { distinctUntilChanged, filter, first, map, merge, mergeMap, switchMap, tap } from 'rxjs';
import { mapRelevantDirectionToStateFlags } from '../../../../shared/components/paginated-scrollview/replay-direction-mapper';
import { existy } from '../../../common/operators/existy';
import { ChannelEventOrchestrator } from '../../../services/socket-io/helpers/channel-event-orchestrator';
import { RootState } from '../../root/public-api';
import { bulkAction } from '../../root/root.actions';
import { replaceChannelMessagesHistory, subscribeToDiscoverChannelUpdates, subscribeToUserChannelUpdates } from '../channel.actions';
import { selectCurrentChannel } from '../channel.selectors';

const convertRelevantResponseToHistoryAction = (
  response: ChannelRelevantMessagesResponse | ChannelRelevantMessagesResponse[]
) => {
  const responses = Array.isArray(response) ? response : [response];
  return responses.map(({ channelId, messages, moreDown, moreUp }) => {
    const flags = mapRelevantDirectionToStateFlags(moreDown, moreUp);
    return replaceChannelMessagesHistory({
      channelId,
      messages,
      flags
    });
  });
};

const createSmartChannelRelevantMessageHandler = () => {
  const channelEventOrchestrator = inject(ChannelEventOrchestrator);
  const store = inject(Store<RootState>);
  const socketIoService = inject(SocketIoService);

  const loadRelevantMessagesOnContext$ = store.pipe(
    select(selectCurrentChannel),
    existy(),
    filter((channelEntity) => !Boolean(channelEntity?.history)),
    map((channelEntity) => channelEntity.id),
    distinctUntilChanged(),
    mergeMap((channelId) => {
      return channelEventOrchestrator.ensureRelevantMessagesLoaded(channelId).pipe(
        map((relevantResponse) => {
          const action = convertRelevantResponseToHistoryAction(relevantResponse);
          return action[0];
        })
      );
    })
  );
  const syncRelevantMessages$ = socketIoService.onReconnected$.pipe(
    switchMap(() => channelEventOrchestrator.ensureRelevantMessagesSynced().pipe(
      first()
    )),
    map((relevantResponses) => {
      const targetActions = convertRelevantResponseToHistoryAction(relevantResponses);
      return bulkAction({ targetActions });
    })
  );

  const forceUpdateRelevantMessages$ = socketIoService.channel.relevantMessages.listen.pipe(
    tap(messages => channelEventOrchestrator.ensureRelevantMessagesReplace(messages)),
    map((relevantResponses) => {
      const targetActions = convertRelevantResponseToHistoryAction(relevantResponses);
      return bulkAction({ targetActions });
    })
  );

  return merge(
    loadRelevantMessagesOnContext$, syncRelevantMessages$, forceUpdateRelevantMessages$
  );
};

export const createChannelRelevantMessageHandlerEffect = () => {
  const actions$ = inject(Actions);
  const smartChannelRelevantMessageHandler = createSmartChannelRelevantMessageHandler();

  return createEffect(() => actions$.pipe(
    ofType(subscribeToUserChannelUpdates, subscribeToDiscoverChannelUpdates),
    first(),
    switchMap(() => smartChannelRelevantMessageHandler),
    distinctUntilChanged(),
  ));
};
