import { Injectable, Provider } from '@angular/core';
import { FeaturedSectionConfig, SocketIoService } from '@portal/wen-backend-api';
import { tap } from 'rxjs';
import { NetworkInitializationHandler } from '../../../frame/network/tokens';
import { ClientCustomizationService } from '../customization/client-customization-service';

@Injectable()
export class WenNetworkInitializationHandler extends NetworkInitializationHandler {

  constructor(
    private socketIoService: SocketIoService,
    private clientCustomizationService: ClientCustomizationService,
  ) {
    super();
  }

  setupNetwork(networkId: string) {
    return this.socketIoService.network.subscribe.acknowledgement$({ network: networkId }).pipe(
      tap((networkData) => {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const clientConfig = networkData.clientConfig;
        if (!clientConfig) {
          console.warn('no clientConfig?', networkData);
          return;
        }
        const translations = clientConfig.translations;
        const styleOverrides = clientConfig.styleOverrides;
        const styles = Object.assign({}, clientConfig, styleOverrides);

        if(styles) {
          this.clientCustomizationService.applyStylingConfigs(styles);
        }
        this.clientCustomizationService.applyTranslationConfig(translations);
        this.clientCustomizationService.applyWeatherConfigs(networkData.clientConfig.weather);
        this.clientCustomizationService.applyHomeCoverImage(networkData.clientConfig.homeCoverImage);
        this.clientCustomizationService.applyHomeButtonSectionLink(networkData.clientConfig.buttonSectionLink);

        const featuredSections = [
          this.setupSection(networkData.clientConfig.featuredSection1, 'HOME_SECTION_TITLE_1'),
          this.setupSection(networkData.clientConfig.featuredSection2, 'HOME_SECTION_TITLE_2'),
          this.setupSection(networkData.clientConfig.featuredSection3, 'HOME_SECTION_TITLE_3'),
          this.setupSection(networkData.clientConfig.featuredSection4, 'HOME_SECTION_TITLE_4'),
          this.setupSection(networkData.clientConfig.featuredSection5, 'HOME_SECTION_TITLE_5')
        ].filter(section => section);

        this.clientCustomizationService.applyHomeFeaturedSectionConfig(featuredSections);
      })
    );
  }

  private setupSection(featuredSection?: FeaturedSectionConfig, sectionTextKey?: string) {
    if (featuredSection) {
      featuredSection.sectionTextKey = sectionTextKey ?? '';
    }
    return featuredSection;
  }

}

export const WEN_NETWORK_INITIALIZATION_PROVIDER: Provider = {
  provide: NetworkInitializationHandler,
  useClass: WenNetworkInitializationHandler
};
