import { CdkScrollable } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, Input, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subject, animationFrameScheduler, filter, first, observeOn, switchMap, takeUntil } from 'rxjs';
import { ListMassDataViewerItemTemplateDirective } from './directives/list-mass-data-viewer.directives';
import { ListMassDataViewerDatasource } from './providers/list-mass-data-viewer-datasource';

@Component({
  selector: 'wen-list-mass-data-viewer',
  templateUrl: './list-mass-data-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListMassDataViewerComponent<T> implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  @ContentChild(ListMassDataViewerItemTemplateDirective) itemTemplate: ListMassDataViewerItemTemplateDirective;
  @ViewChild(CdkScrollable) private scrollable: CdkScrollable;

  trackByFn = this.trackByFnImpl.bind(this);

  private currentDatasource: ListMassDataViewerDatasource<T>;

  @Input() set datasource(newDatasource: ListMassDataViewerDatasource<T>) {
    this.currentDatasource = newDatasource;
    this.reWireDatasource();
  }
  get datasource() { return this.currentDatasource; }

  @Input() itemSize = 56;

  isEmpty$: Observable<boolean>;

  constructor() { }

  reWireDatasource(): void {
    this.isEmpty$ = this.datasource.isEmpty().pipe(
      observeOn(animationFrameScheduler)
    );
  }

  trackByFnImpl(index: number, item: T) {
    return this.datasource.selectItemId(item, index);
  }

  ngAfterViewInit(): void {
    this.scrollable.elementScrolled().pipe(
      switchMap(() => {
        return this.datasource.isLoading().pipe(
          first(),
          filter(isLoading => !isLoading)
        );
      }),
      filter(() => {
        const bottomOffset = this.scrollable.measureScrollOffset('bottom');
        return bottomOffset < 500;
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.datasource.fetchNextPage();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
