import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

export enum MessageBoxStatus {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  READ = 'READ',
  PENDING = 'PENDING',
}

@Component({
  selector: 'wen-message-status-indicator',
  styleUrls: ['./message-status-indicator.component.scss'],
  templateUrl: './message-status-indicator.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MessageStatusIndicatorComponent {

  @HostBinding('class.wen-message-status-indicator') className = true;

  @Input() messageStatus: MessageBoxStatus;

  @Input() iconClassName: 'wen-icon-tiny' | 'wen-icon-small' | 'wen-icon-medium';
}
