import { PhoneAreaInfo, PhoneNumberValue } from '../types/form-types';

export const extractPhoneNumber = (data: PhoneNumberValue) => {
  const { phoneNumber, areaInfo } = data;
  if (!phoneNumber) {
    return null;
  }
  const matches = phoneNumber.match(/\d+/g);
  if (Array.isArray(matches) && matches.length) {
    const cleanedPhoneNumber = matches.join('');
    return areaInfo.areaCode + cleanedPhoneNumber;
  }
  return null;
};

export const isGermanAreaCode = (areaInfo: PhoneAreaInfo) => {
  return areaInfo?.areaCode === '+49';
};
