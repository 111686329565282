import { Injectable } from '@angular/core';
import { Store, createSelector, select } from '@ngrx/store';
import { selectChannelById, selectIsChannelSubscribed, selectPinForChannel } from '../../../../../../../core/store/channel/channel.selectors';
import { selectRecentMessageForChannel, selectNotificationCountForChannel } from '../../../../../../../core/store/notification/notification.selectors';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Observable } from 'rxjs';
import { ChannelListItemProperties } from './types';
import { isInviteToChannelState } from '@portal/wen-backend-api';

@Injectable()
export class ChannelListItemDataSource {

  constructor(
    private readonly store: Store
  ) { }

  getChannelListItemProps(channelId: string): Observable<ChannelListItemProperties | null> {
    return this.store.pipe(
      select(this.selectListItemProps(channelId)),
      smartDistinctUntilChanged()
    );
  }

  private selectListItemProps(channelId: string) {
    return createSelector(
      selectChannelById,
      selectIsChannelSubscribed,
      selectRecentMessageForChannel,
      selectNotificationCountForChannel,
      selectPinForChannel,
      (
        channelSelector,
        isSubscribedSelector,
        mostRecentMessageSelector,
        notificationCountSelector,
        pinTimestampSelector
      ) => {
        const channel = channelSelector(channelId);
        if (!channel) {
          return null;
        }
        const {
          id, icon, title, description, isMuted,
          isPrivate, restricted, geo, invite, readingContent
        } = channel;
        const mostRecentMessage = mostRecentMessageSelector(channelId);

        const messageForProps = mostRecentMessage ? {
          timestamp: mostRecentMessage.timestamp,
          embeds: mostRecentMessage.embeds,
          content: mostRecentMessage.content
        } : null;
        const inviteForProps = invite ? {
          state: invite.state,
          updateTimestamp: invite.updateTimestamp,
          insertUserName: isInviteToChannelState(invite) ? invite.insertUserName : null
        } : null;
        return {
          channel: {
            id,
            icon,
            title,
            description,
            isMuted,
            isPrivate,
            restricted,
            geo,
            invite: inviteForProps,
            readingContent
          },
          subscribed: isSubscribedSelector(channelId),
          mostRecentMessage: messageForProps,
          notificationCount: notificationCountSelector(channelId),
          pinTimestamp: pinTimestampSelector(channelId)
        } satisfies ChannelListItemProperties;
      }
    );
  }
}
