import { AfterViewInit, Directive, OnDestroy, ViewContainerRef } from '@angular/core';
import { WenBreakpointObserver } from '@portal/wen-components';
import { Subject, takeUntil } from 'rxjs';
import { addScrollableSelector, clearQueueScrollLocks, disablePageScroll, enablePageScroll } from 'scroll-lock';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { WenNativeApi } from '@portal/wen-native-api';
import { ViewportResizeDirective } from '../../directives/util/viewport-resize.directive';

@Directive({
  selector: 'wenIosViewportFix, [wenIosViewportFix]',
  hostDirectives: [
    {
      directive: ViewportResizeDirective,
      outputs: ['afterViewportChange']
    }
  ]
})
export class IosViewportFixDirective implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  constructor(
    private vcRef: ViewContainerRef,
    private nativeApi: WenNativeApi,
    private breakpointObserver: WenBreakpointObserver,
    private featureEnablement: FeatureEnablementService,
    private viewportResizeDirective: ViewportResizeDirective,
  ) { }

  ngAfterViewInit() {
    if (!this.isEnabled()) {
      return;
    }
    this.viewportResizeDirective.afterViewportChange.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(({ viewportHeight }) => {
      this.fixAppSize(viewportHeight);
    });
  }

  private isEnabled() {
    const isDesktopStyle = this.breakpointObserver.isDesktopStyleDevice;
    const isNativeIOS = this.nativeApi.isReactNativeApp() && this.nativeApi.isIOS();
    const isFeatureEnabled = this.featureEnablement.featureFlagMethods.isEnableIosKeyboardUtils();
    return isFeatureEnabled && !isDesktopStyle && isNativeIOS;
  }

  private fixAppSize(targetHeight: number) {
    addScrollableSelector('textarea');
    addScrollableSelector('[cdkScrollable]');
    addScrollableSelector('cdk-virtual-scroll-viewport');
    disablePageScroll(this.vcRef.element.nativeElement);
    const htmlTag = document.getElementsByTagName('html')[0];
    htmlTag.style.height = targetHeight + 'px';
    document.body.style.height = targetHeight + 'px';
    htmlTag.scrollTo(0, 0);
  }

  private resetAppSize() {
    const htmlTag = document.getElementsByTagName('html')[0];
    htmlTag.style.height = 'auto';
    document.body.style.height = '100vh';
    clearQueueScrollLocks();
    enablePageScroll(this.vcRef.element.nativeElement);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    if (this.isEnabled()) {
      this.resetAppSize();
    }
  }

}
