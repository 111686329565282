import { sectionFeatureKey } from './constants';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { FilterEntityType } from '../filter/models/filter';
import { sectionAdapter, SectionState } from './sections.state';
import { LoadingState } from '../../common/types/store-loading-state';

export const selectSectionState = createFeatureSelector<SectionState>(sectionFeatureKey);

const {
  selectAll: sectionSelectAll
} = sectionAdapter.getSelectors();

export const selectSections = createSelector(
  selectSectionState,
  state => {
    return sectionSelectAll(state.sectionEntities);
  }
);

export const selectSectionsByFilterEntityType = createSelector(
  selectSections,
  sections => (filterType: FilterEntityType) => {
    return sections.filter(section => section.filterType === filterType);
  }
);

export const selectSectionById = createSelector(
  selectSections,
  sections => (id: string) => {
    return sections.filter(section => section.id === id)[0];
  }
);

export const selectSectionContentById = createSelector(
  selectSections,
  sections => (sectionId: string) => {
    return sections?.find(section => section.id === sectionId)?.content;
  }
);

export const selectChannelExploreIsLoaded = createSelector(
  selectSectionState,
  (state) => state.channelExploreSectionLoadingState !== LoadingState.NOT_LOADED
);

export const selectEventExploreIsLoaded = createSelector(
  selectSectionState,
  (state) => state.eventExploreSectionLoadingState !== LoadingState.NOT_LOADED
);

export const selectAppExploreIsLoaded = createSelector(
  selectSectionState,
  (state) => state.appExploreSectionLoadingState !== LoadingState.NOT_LOADED
);
