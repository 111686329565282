<div class="wen-image-upload-container">
  <portal-image-upload [allowCropping]="allowCroppingFn" [acceptedExtensions]="acceptedExtensions" [allowedImageSize]="allowedImageSize" [copyFileInput]="true" (hasError)="handleError($event)" portalFileDropTarget>
    <wen-circle-initial-view portal-image-upload-default-image [text]="fallbackText" [fontSize]="42" [shape]="imageShape">
      <wen-icon initialViewFallback class="wen-image-upload-fallback wen-icon-very-big" [iconName]="fallbackImage"></wen-icon>
    </wen-circle-initial-view>
    <ng-container portal-image-upload-select-file>
      <wen-icon class="wen-image-upload-edit-button wen-icon-medium" iconName="edit" (click)="onSelectorClicked($event)"></wen-icon>
    </ng-container>
    <ng-container portal-image-upload-remove-image>
      <wen-icon class="wen-image-upload-remove-button wen-icon-medium" iconName="delete"></wen-icon>
    </ng-container>
  </portal-image-upload>
</div>
