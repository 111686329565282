import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { InvitedToChannelDTO, isInviteToChannelState, RestrictionDTO, RestrictionType } from '@portal/wen-backend-api';
import { handleInviteToChannelRequest } from '../../../../core/store/channel/channel.actions';
import { RootState } from '../../../../core/store/root/public-api';

@Component({
  selector: 'wen-channel-subscription-controls',
  templateUrl: './channel-subscription-controls.component.html',
  styleUrls: ['./channel-subscription-controls.component.scss']
})
export class ChannelSubscriptionControlsComponent {

  @Input() channelId: string;
  @Input() restrictions: RestrictionDTO[] = null;
  @Input() inviteData: InvitedToChannelDTO = null;

  get isRestrictionLayout() {
    return Boolean(!this.inviteData || (this.restrictions?.length && !isInviteToChannelState(this.inviteData)));
  }

  get isHiddenSubscriptionLayout() {
    return Boolean(this.restrictions?.find(restriction => {
      return restriction.restrictionType === RestrictionType.WalletCredentialRequired && !restriction.fulfilled;
    }));
  }

  get isSubscriptionAllowed(): boolean {
    if (!this.restrictions?.length) {
      return true;
    }
    return !this.restrictions.some(restriction => restriction.restrictionType === RestrictionType.IsManaged);
  }

  constructor(
    private readonly store: Store<RootState>,
  ) { }

  handleInvite(accepted: boolean) {
    const { id: inviteId } = this.inviteData;
    if (!inviteId) {
      return;
    }
    this.store.dispatch(handleInviteToChannelRequest({ inviteId, accepted }));
  }

}
