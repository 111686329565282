import { createAction, props } from '@ngrx/store';

export const updateNavBarVisibility = createAction(
  '[NavigationBar] Update navigation bar visibility',
  props<{ isNavBarVisible: boolean }>()
);

export const reloadNavBarVisibility = createAction(
  '[NavigationBar] Reload navigation bar visibility from config'
);
