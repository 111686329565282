import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

export const generateId = () => {
  return uuidv4();
};

export const generateDeterministicId = (name: string) => {
  const UUID_NAMESPACE = '9bf838a6-6d14-4ca6-87e5-dc8d23273a55';
  return uuidv5(name, UUID_NAMESPACE);
};

export const validateId = (id: string) => {
  const regex = /^(?:[0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{12}$)/i;
  return typeof id === 'string' && regex.test(id);
};

export const formatGuid = (id: string) => {
  return id.replace(/([0-z]{8})([0-z]{4})([0-z]{4})([0-z]{4})([0-z]{12})/,'$1-$2-$3-$4-$5');
};
