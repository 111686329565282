
import { NgModule } from '@angular/core';

import { UserNavigableDirective } from './user-navigable.directive';

@NgModule({
  declarations: [UserNavigableDirective],
  exports: [UserNavigableDirective]
})
export class UserNavigationModule { }
