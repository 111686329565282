import { CdkScrollable } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, ElementRef, HostBinding, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { PortalStickyService } from '@portal/ui-kit/sticky';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { existy } from '../../../../../core/common/operators/existy';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { PermissionLevel } from '../../../../../core/services/user-management/permission-level';
import { logout } from '../../../../../core/store/auth/auth.actions';
import { selectCurrentUserData } from '../../../../../core/store/auth/auth.selectors';
import { upsertHeaderData } from '../../../../../core/store/header/header.actions';
import { HeaderData } from '../../../../../core/store/header/models/HeaderData';
import { RootState } from '../../../../../core/store/root/public-api';
import { UserProfile } from '../../../../../core/store/user/models/UserProfile';
import { selectUserProfile } from '../../../../../core/store/user/user.selectors';
import { WenRouteId } from '../../../../../frame/routing/types';
import { InviteHandler } from '../../../../invite/invite-service/types';
import { UserProfileViewConfiguration } from './user-profile-view-configuration';
import { EntryId } from './user-profile-view-types';


@Component({
  selector: 'wen-user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.scss'],
  providers: [
    UserProfileViewConfiguration
  ]
})
export class UserProfileViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostBinding('class.wen-user-profile-view') className = true;

  @ViewChild('summaryWrapper', { read: ElementRef }) private summaryWrapper: ElementRef<HTMLDivElement>;
  @ViewChild(CdkScrollable) private scroller: CdkScrollable;

  private onDestroy$ = new Subject<void>();
  readonly stickyId = 'profile-sticky-container';
  userProfile$: Observable<UserProfile>;
  isRegisteredUser$: Observable<boolean>;
  isTrademarkVisible: boolean;

  constructor(
    private renderer: Renderer2,
    private zone: NgZone,
    private elementRef: ElementRef,
    private store: Store<RootState>,
    public config: UserProfileViewConfiguration,
    private stickyService: PortalStickyService,
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
    private readonly inviteHandler: InviteHandler,
    private readonly featureEnablementService: FeatureEnablementService,
  ) { }

  ngOnInit() {
    this.userProfile$ = this.store.pipe(
      select(selectUserProfile),
      existy()
    );
    this.isRegisteredUser$ = this.store.pipe(
      select(selectCurrentUserData),
      existy(),
      map(userData => userData.permissionLevel === PermissionLevel.REGISTERED_USER)
    );
    this.isTrademarkVisible = this.featureEnablementService.isPartnerApp();
  }

  ngAfterViewInit() {
    this.isRegisteredUser$.pipe(
      first(),
      takeUntil(this.onDestroy$)
    ).subscribe((isRegisteredUser) => {
      if (isRegisteredUser) {
        this.initHeaderBehavior();
      } else {
        this.initStickyBehavior();
      }
    });
  }

  handleEntryClick(entryId: EntryId) {
    switch (entryId) {
      case EntryId.LOGOUT: {
        this.store.dispatch(logout({ isByUserInteraction: true }));
        return;
      }
      case EntryId.REGISTER: {
        this.navigationHelper.navigateToRegistrationStart(true);
        return;
      }
      case EntryId.HELP: {
        this.navigationHelper.navigateToHelp();
        return;
      }
      case EntryId.USER_SETTINGS: {
        this.navigationHelper.navigateToSettings();
        return;
      }
      case EntryId.PROFILE: {
        this.goToProfileRead();
        return;
      }
      case EntryId.CONTACTS: {
        this.appNavigator.navigateToRoute(WenRouteId.CONTACTS_LIST);
        return;
      }
      case EntryId.INVITE: {
        this.inviteHandler.initInviteFlow();
        return;
      }
      case EntryId.AUTO_REPLY: {
        this.goToAutoReplyEdit();
        return;
      }
      case EntryId.HOTLINE: {
        this.navigationHelper.navigateToHotline();
        return;
      }
      default: {
        return;
      }
    }
  }

  private initStickyBehavior() {
    this.stickyService.registerStickyContainer(this.scroller.getElementRef().nativeElement, this.stickyId);
    this.stickyService.addStickyElement(this.summaryWrapper.nativeElement, this.stickyId);
    this.stickyService.stickyChangeEvents$.pipe(
      map(event => {
        const { stickyElement: { isSticky } } = event;
        return isSticky;
      }),
      startWith(false),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe(isSticky => {
      const hostEl = this.elementRef.nativeElement;
      if (isSticky) {
        this.renderer.addClass(hostEl, 'wen-user-profile-view-sticky');
      } else {
        this.renderer.removeClass(hostEl, 'wen-user-profile-view-sticky');
      }
    });
  }

  private initHeaderBehavior() {
    this.scroller.elementScrolled().pipe(
      debounceTime(100),
      map(() => {
        const summaryWrapperEl = this.summaryWrapper.nativeElement;
        const scrollTop = this.scroller.measureScrollOffset('top');
        return summaryWrapperEl.offsetTop - 10 <= scrollTop;
      }),
      distinctUntilChanged(),
      withLatestFrom(this.userProfile$),
      takeUntil(this.onDestroy$)
    ).subscribe(([needsHeaderText, profile]) => {
      let headerData: Partial<HeaderData>;
      if (needsHeaderText) {
        headerData = {
          title: profile.displayname,
        };
      } else {
        headerData = {
          title: '',
          subTitle: ''
        };
      }
      this.zone.run(() => {
        this.store.dispatch(upsertHeaderData({ headerData }));
      });
    });
  }

  goToProfileRead() {
    this.navigationHelper.navigateToUserProfileRead(true);
  }

  goToAutoReplyEdit() {
    this.navigationHelper.navigateToAutoReplyEdit();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.stickyService.removeStickyContainer(this.stickyId);
  }

}
