import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, catchError, filter, first, of, switchMap } from 'rxjs';
import { AppConfigurationProvider } from '../../../core/services/configuration/app-confguration';
import { SIGNIN_RETURN_URL } from '../../../core/services/navigation/query-params';
import { WenNavigationHelper } from '../../../core/services/navigation/types';
import { PermissionLevel } from '../../../core/services/user-management/permission-level';
import { WenOAuthService } from '../../../core/services/user-management/wen-oauth.service';
import { SignInActionsHandler, SignInViewConfig } from './types';
import { TranslateService } from '@ngx-translate/core';
import { hasTranslationFor } from '../../../shared/util/translation-util';

export class SignInPopupActionsHandler extends SignInActionsHandler {

  viewConfig: SignInViewConfig;
  startSilentLogout: BehaviorSubject<boolean>;

  constructor(
    private readonly navigationHelper: WenNavigationHelper,
    private readonly wenOAuthService: WenOAuthService,
    private readonly config: AppConfigurationProvider,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {
    super();
    this.viewConfig = {
      showLoginButton: true,
      showRegisterButton: true,
      showAnonLoginButton: false,
      showLoginPrompt: true,
      termsAndConditions:
        hasTranslationFor(translateService, 'PRIVACY_POLICY_URL')
        && hasTranslationFor(translateService,'TERMS_AND_CONDITIONS_URL'),
      popupFlow: {
        returnUrl: this.activatedRoute.snapshot.queryParams[SIGNIN_RETURN_URL] || '/',
      }
    };
  }

  init(startSilentLogout: BehaviorSubject<boolean>) {
    this.startSilentLogout = startSilentLogout;
    this.logAnonUserOut();
  }

  logAnonUserOut() {
    if (this.wenOAuthService.getUserData()?.permissionLevel === PermissionLevel.ANONYMOUS) {
      this.startSilentLogout.next(true);
    }
  }

  goToRegister() {
    window.open('/register', '_blank');
  }

  goToLogin() {
    this.logAnonUserOut();
    this.startSilentLogout.pipe(
      filter(value => !value),
      first(),
      switchMap(() => this.wenOAuthService.initLoginFlowInPopup({}, { prompt: 'login' })),
      catchError((error) => {
        return of(error.type === 'popup_closed' || error.type === 'popup_blocked');
      }),
    ).subscribe((result) => {
      if (typeof result === 'boolean' && !result) {
        this.navigationHelper.navigateToErrorPage();
      } else {
        this.router.navigateByUrl(this.viewConfig.popupFlow.returnUrl);
      }
    });
  }

  openTermsAndConditionsPage() {
    this.navigationHelper.navigateToUrlWithFallback(this.translateService.instant('TERMS_AND_CONDITIONS_URL'));
    return false;
  }

  openPrivacyPage() {
    this.navigationHelper.navigateToUrlWithFallback(this.translateService.instant('PRIVACY_POLICY_URL'));
    return false;
  }

  goToAnonymousLogin() { }
}
