<div class="wen-extended-text-editor-content" [wenContentBoundary]>
  <textarea #textArea cdkTextareaAutosize [ngModel]="textContent" (input)="onTextContentInput()" [autocomplete]="'off'"
  cdkAutosizeMinRows="1" [placeholder]="inputPlaceholder"></textarea>
  @if (mediaData) {
    <wen-media-preview class="wen-media-preview-geo" [mediaData]="mediaData" (dismiss)="handleMediaDismiss()"></wen-media-preview>
  }
  <wen-link-embed-preview [orientation]="'vertical'" [previewContent]="linkData" [disabled]="isLinkPreviewDisabled"
  (previewChanged)="handleLinkEmbedChange($event)"></wen-link-embed-preview>
  @if (locationData) {
    <wen-location-preview [wenVariant]="'normal'" [iconName]="locationData | locationIconClassifier"
    [locationData]="locationData | locationFormatter" (dismiss)="handleLocationDismiss()"></wen-location-preview>
  }
</div>