import { Observable, map } from 'rxjs';

/**
 * Map an array of streamed values into a single boolean
 *
 * @returns Boolean based on the truthyness of the array
 *  - True if the the array is defined and every element is true
 *  - False otherwise
 */
export const mapAllBool = <T extends Array<any>>() => (source: Observable<T>) => {
  return source.pipe(
    map(valueArr => {
      if (!valueArr?.length) {
        return false;
      }
      return !valueArr.some(value => !Boolean(value));
    })
  );
};

/**
 * Map an array of streamed values into a single boolean
 *
 * @returns Boolean based on the truthyness of the array
 *  - True if the the array has at least one true element
 *  - False otherwise
 */
export const mapAnyTrue = <T extends Array<any>>() => (source: Observable<T>) => {
  return source.pipe(
    map(valueArr => {
      if (!valueArr?.length) {
        return false;
      }
      return valueArr.some(value => Boolean(value));
    })
  );
};
