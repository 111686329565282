import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ChannelMessagesModificationsResponse, ChannelMessagesResponse, MessageModificationState, SocketIoService } from '@portal/wen-backend-api';
import { Observable, merge } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { filterBy } from '../../../common/operators/fitler-by';
import { groupBy } from '../../../common/util/group-by';
import { ChannelEventOrchestrator } from '../../../services/socket-io/helpers/channel-event-orchestrator';
import { bulkAction } from '../../root/root.actions';
import { addChannelMessagesNotifications, editLatestChannelMessage, initChannelMessagesNotification, removeFromLatestMessages, subscribeToUserChannelNotificationUpdates, updateChannelMessagesNotifications } from '../notification.actions';

const createSmartChannelSummaryMessageHandler = (
  socketIoService: Pick<SocketIoService, 'channel' | 'onReconnected$'>,
  channelEventOrchestrator: ChannelEventOrchestrator,
) => {
  const channelSummaryResult$ = socketIoService.channel.listForUser.listenWithReplay.pipe(
    first(),
    switchMap((listmyResponse) => {
      const channelIds = listmyResponse.map((listMyItem) => listMyItem.id);
      return channelEventOrchestrator.ensureSummaryMessagesLoaded(channelIds).pipe(
        switchMap(() => channelEventOrchestrator.bufferedSummary$),
      );
    })
  );
  const syncChannelSummaryResult$ = socketIoService.onReconnected$.pipe(
    switchMap(() => channelEventOrchestrator.ensureSummaryMessagesSynced().pipe(
      first()
    ))
  );
  const forceChannelSummaryUpdate$ = socketIoService.channel.messagesSummary.listen.pipe(
    tap(summary => channelEventOrchestrator.ensureSummaryMessagesReplace(summary)),
    map((summary) => {
      const actions: Action[] = [];
      actions.push(updateChannelMessagesNotifications({ summary }));
      bulkAction({ targetActions: actions });
      return [summary];
    })
  );
  return merge(channelSummaryResult$, syncChannelSummaryResult$, forceChannelSummaryUpdate$);
};

export const createMessageNotificationUpdatesEffect = (
  actions$: Actions,
  newChannelMessages$: Observable<ChannelMessagesResponse>,
  messageModifications$: Observable<ChannelMessagesModificationsResponse>,
  isFromSmartDesign$: Observable<boolean>,
  socketIoService: Pick<SocketIoService, 'channel' | 'onReconnected$'>,
  channelEventOrchestrator: ChannelEventOrchestrator,
) => {
  return createEffect(() => actions$.pipe(
    ofType(subscribeToUserChannelNotificationUpdates),
    filterBy(() => isFromSmartDesign$, false),
    first(),
    switchMap(() => {
      const channelSummaryResult$ = createSmartChannelSummaryMessageHandler(
        socketIoService,
        channelEventOrchestrator,
      ).pipe(
        map(summaries => {
          const targetActions = summaries.map((summary) => {
            return initChannelMessagesNotification({ newEntity: summary });
          });
          return bulkAction({ targetActions });
        })
      );
      const notificationAdditions$ = newChannelMessages$.pipe(
        switchMap((messages) => {
          const groups = groupBy(messages, (item) => item.channelId);
          return Array.from(groups).map(([channelId, groupMessages]) => {
            return addChannelMessagesNotifications({ channelId, messages: groupMessages });
          });
        })
      );
      const notificationModifications$ = messageModifications$.pipe(
        switchMap((message) => {
          const actions: Action[] = [];
          const type = message?.type;
          if (type === MessageModificationState.DELETED) {
            actions.push(removeFromLatestMessages({ message }));
          } else if (type === MessageModificationState.EDITED) {
            actions.push(editLatestChannelMessage({ updatedMessage: message }));
          }
          return actions;
        })
      );
      return merge(channelSummaryResult$, notificationAdditions$, notificationModifications$);
    })
  ));
};
