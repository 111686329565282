import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { LoadResultMode } from '../../../../../core/common/types/misc';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { selectDiscoverApps, selectDiscoverAppsLoaded, selectUserAppIds } from '../../../../../core/store/apps/apps.selectors';
import { selectActiveFiltersByEntityType } from '../../../../../core/store/filter/filter.selectors';
import { FilterEntityType } from '../../../../../core/store/filter/models/filter';
import { AppListLoadResult } from '../../models/AppLoadResult';

@Injectable()
export class DiscoverAppListDataSource {

  public readonly loadResult$: Observable<AppListLoadResult>;

  constructor(
    private readonly store: Store,
  ) {
    this.loadResult$ = combineLatest([
      this.store.pipe(select(selectDiscoverAppsLoaded)),
      this.store.pipe(select(selectDiscoverApps)),
      this.store.pipe(select(selectUserAppIds))
    ]).pipe(
      withLatestFrom(this.store.pipe(
        selectorWithParam(selectActiveFiltersByEntityType, FilterEntityType.APP_LISTS),
      )),
      switchMap(([[loaded, apps, userAppIds], filters]) => {
        let result: AppListLoadResult = null;
        if (!loaded) {
          result = { mode: LoadResultMode.NORMAL, data: null };
        } else {
          const appItems = apps.map((app) => {
            return {
              ...app,
              isSubscribed: userAppIds?.includes(app.id)
            };
          });
          result = { mode: filters.length ? LoadResultMode.SEARCH : LoadResultMode.NORMAL, data: appItems };
        }
        return of(result);
      })
    );
  }

}
