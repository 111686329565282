import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { FeatureEnablementService } from '../configuration/feature-enablement';
import { WenNativeApi } from '@portal/wen-native-api';
import { WenStorageService } from '../storage/wen-storage.service';
import { AuthGuard } from './auth-guard';

export const onboardingAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(OnboardingAuthGuard).canActivate(route, state);
};

@Injectable()
export class OnboardingAuthGuard {

  constructor(
    private router: Router,
    private authGuard: AuthGuard,
    private storageService: WenStorageService,
    private nativeApi: WenNativeApi,
    private featureConfiguration: FeatureEnablementService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.storageService.isOnboardingDone() && this.nativeApi.isReactNativeApp() &&
      !this.featureConfiguration.featureFlagMethods.isEnableSkipOnboarding()) {
      return of(this.router.parseUrl('/onboarding'));
    }
    return this.authGuard.canActivateChild(route, state);
  }
}
