import { AfterViewInit, Component, ContentChildren, ElementRef, HostBinding, Input, OnDestroy, QueryList, ViewChild, ViewEncapsulation } from '@angular/core';
import { delay, EMPTY, map, Observable, of, startWith, Subject, animationFrameScheduler, observeOn, takeUntil } from 'rxjs';
import { SectionContentDirective } from './section.directive';
import { ResizeHandlerProvider } from '../../util/resize-handler';

@Component({
  selector: 'wen-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  providers: [
    ResizeHandlerProvider,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class WenSectionComponent implements AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  private scrollPressed$ = new Subject<void>();
  scrollVisibility$: Observable<{ left: boolean; right: boolean }> = EMPTY;
  isScrollersVisible$: Observable<boolean> = EMPTY;

  private scrollDistance: number;
  private sectionFullWidth: number;
  private section: HTMLDivElement;
  private sectionContentWrapper: HTMLDivElement;

  @HostBinding('class.wen-section') className: true;
  @Input() @HostBinding('class.wen-section-flat') flat = false;
  @ViewChild('sectionContent') set sectionContent(content: ElementRef<HTMLDivElement>) {
    this.section = content.nativeElement;
  }
  @ViewChild('contentWrapper') set contentWrapper(contentWrapper: ElementRef<HTMLDivElement>) {
    this.sectionContentWrapper = contentWrapper.nativeElement;
  }
  @ContentChildren(SectionContentDirective) contentElements: QueryList<SectionContentDirective>;

  @Input() isNativeApp = false;
  @Input() hasUnifiedItems = true;

  constructor(private resizeHandlerProvider: ResizeHandlerProvider) {
  }

  scrollLeft() {
    this.scrollPressed$.next();
    this.section.scrollBy({ left: -this.scrollDistance, behavior: 'smooth' });
  }

  scrollRight() {
    this.scrollPressed$.next();
    this.section.scrollBy({ left: this.scrollDistance, behavior: 'smooth' });
  }

  ngAfterViewInit() {
    this.ListenToContentSizeChanges();
    this.recalculateArrowVisibilityOnPress();
  }

  calculateScrollDistance() {
    this.sectionFullWidth = this.sectionContentWrapper.scrollWidth;
    if (this.hasUnifiedItems && this.contentElements?.first?.elRef) {
      const singleItemWidth = this.contentElements.first.elRef.nativeElement.clientWidth;
      this.scrollDistance = singleItemWidth * 2;
    } else {
      this.scrollDistance = this.section.clientWidth / 2;
    }
  }

  private recalculateArrowVisibilityOnPress() {
    this.scrollVisibility$ = this.scrollPressed$.pipe(
      delay(300),
      map(() => {
        return {
          left: this.section.scrollLeft > 0,
          right: this.section.scrollLeft < this.sectionFullWidth - this.section.clientWidth
        };
      }),
      startWith({
        left: false,
        right: this.section.clientWidth <= this.sectionFullWidth
      })
    );
    this.isScrollersVisible$ = of(!this.isNativeApp).pipe(
      observeOn(animationFrameScheduler)
    );
  }

  private ListenToContentSizeChanges() {
    const resizeHandler = this.resizeHandlerProvider.create(this.sectionContentWrapper);
    resizeHandler.onResize$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.calculateScrollDistance();
      this.scrollPressed$.next();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
