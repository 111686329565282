import { Injectable } from '@angular/core';
import { Action, createAction, props } from '@ngrx/store';
import { MassDataLoadingProgress, MassDataType } from './types';

type EntityData = Record<string, any>;

export enum MassDataOperation {
  CLEAR_ALL = 'CLEAR_ALL',
  REMOTE_FETCH_INITIAL_PAGE = 'REMOTE_FETCH_INITIAL_PAGE',
  STORE_SET_ALL = 'STORE_SET_ALL',
  REMOTE_FETCH_NEXT_PAGE = 'REMOTE_FETCH_NEXT_PAGE',
  STORE_UPSERT_MANY = 'STORE_UPSERT_MANY',
  STORE_UPDATE_MANY = 'STORE_UPDATE_MANY',
  STORE_REMOVE_MANY = 'STORE_REMOVE_MANY',
  STORE_SET_LOADING_PROGRESS = 'STORE_SET_LOADING_PROGRESS',
}

export interface MassDataBasePayload<O extends MassDataOperation, D> {
  massDataOperation: O;
  massDataType: MassDataType;
  massDataActionData?: D;
}

export type MassDataActions =
  | MassDataBasePayload<MassDataOperation.CLEAR_ALL, void>
  | MassDataBasePayload<MassDataOperation.REMOTE_FETCH_INITIAL_PAGE, void>
  | MassDataBasePayload<MassDataOperation.STORE_SET_ALL, EntityData[]>
  | MassDataBasePayload<MassDataOperation.REMOTE_FETCH_NEXT_PAGE, void>
  | MassDataBasePayload<MassDataOperation.STORE_UPSERT_MANY, EntityData | EntityData[]>
  | MassDataBasePayload<MassDataOperation.STORE_UPDATE_MANY, EntityData | EntityData[]>
  | MassDataBasePayload<MassDataOperation.STORE_REMOVE_MANY, EntityData | EntityData[]>
  | MassDataBasePayload<MassDataOperation.STORE_SET_LOADING_PROGRESS, MassDataLoadingProgress>;

export type MassDataAction<P extends MassDataBasePayload<any, any> = MassDataBasePayload<any, any>> = Action & { payload: P };

const actionTemplate = <D extends MassDataBasePayload<any, any>>(payload: D) => {
  const { massDataOperation, massDataType } = payload;
  return createAction(
    `[MassData] [${massDataType}] ${massDataOperation}`,
    props<{ payload: D }>()
  );
};

@Injectable()
export class MassDataActionsFactory {

  createAction<D extends MassDataActions>(
    actionProps: D
  ): MassDataAction<D> {
    const massDataAction = actionTemplate(actionProps);
    return massDataAction({ payload: actionProps });
  }

}
