import { Injectable, inject } from '@angular/core';
import { Observable, defaultIfEmpty, forkJoin } from 'rxjs';
import { generateId } from '../../../../../common/generate-id';
import { EmbedDTOType, EmbeddedForwardDTO, EmbeddedMediaDTO } from '../../../../../data-types/embed/embed';
import { ForwardData } from '../../../../../data-types/forward/forward';
import { SourceMediaReference, TargetMediaReferences } from '../../../../../data-types/media/media';
import { MediaContext, MediaContextTypes } from '../../../../../events/events';
import { EmbedMediaReplicator } from '../embed-media-replicator';

export class EmbeddedForwardHandler {

  private embedCopyCache = new Map<string, string>();
  private copyOps: ((targetRefs: MediaContext[]) => Observable<unknown>)[] = [];

  constructor(
    private embedMediaReplicator: EmbedMediaReplicator
  ) { }

  prepareForPreview(
    embedForward: EmbeddedForwardDTO
  ): EmbeddedForwardDTO {
    const { type, forwardData } = embedForward;
    const preparedForwardData = this.prepareForwardData(forwardData);
    return {
      type,
      forwardData: preparedForwardData
    };
  }

  persist(
    targetContexts: MediaContext[],
  ): Observable<unknown> {
    const uploads$ = this.copyOps.map(copyOp => copyOp(targetContexts));
    this.copyOps = [];
    return forkJoin(uploads$).pipe(
      defaultIfEmpty([])
    );
  }

  private prepareForwardData(forwardData: ForwardData): ForwardData {
    const embeds = forwardData.data?.embeds;
    if (!embeds?.length) {
      return forwardData;
    }
    const preparedForwardEmbeds = embeds.map(embed => {
      switch (embed.type) {
        case EmbedDTOType.MEDIA:
          const { uploadId: oldUploadId, contextId, publicId: oldPublicId } = embed;
          const existingUploadId = this.embedCopyCache.get(oldUploadId);
          let newUploadId: string;
          if (existingUploadId) {
            newUploadId = existingUploadId;
          } else {
            newUploadId = generateId();
            this.embedCopyCache.set(oldUploadId, newUploadId);
            const bindForwardToContexts = (
              targetContexts: MediaContext[],
            ) => {
              const [messageId, roomId] = contextId.split('_');
              const sourceRef: SourceMediaReference = {
                uploadId: oldUploadId,
                publicId: oldPublicId,
                context: {
                  id: messageId,
                  type: MediaContextTypes.CHAT_MESSAGE,
                  parent: {
                    id: roomId,
                    type: MediaContextTypes.CHAT
                  }
                }
              };
              const targetRefs: TargetMediaReferences = {
                uploadId: newUploadId,
                contexts: targetContexts
              };
              return this.embedMediaReplicator.createCopy(sourceRef, targetRefs);
            };
            this.copyOps.push(bindForwardToContexts);
          }
          const forwardedMedia: EmbeddedMediaDTO = {
            id: null,
            subType: embed.subType,
            type: embed.type,
            oldUploadId,
            uploadId: newUploadId,
            contextId: embed.contextId || forwardData.id + '_fakeSourceChatId'
          };
          return forwardedMedia;
        default:
          return embed;
      }
    });
    const { id, context, data } = forwardData;
    const { content } = data;
    const preparedForwardData: ForwardData = {
      id, context,
      data: {
        content,
        embeds: preparedForwardEmbeds
      }
    };
    return preparedForwardData;
  }

}

@Injectable()
export class EmbeddedForwardUploadFactory {

  private embedMediaReplicator = inject(EmbedMediaReplicator);

  get() {
    return new EmbeddedForwardHandler(this.embedMediaReplicator);
  }
}
