import { BackgroundGradient } from './weather.model';

export type BackgroundConfig = {
  weatherTypes: number[];
  gradient: BackgroundGradient;
};

export const gradientConfig: Record<string, { config: BackgroundConfig[] }> = {
  daylight: {
    config: [
      {
        weatherTypes: [0, 1, 2],
        gradient: { start: '#63ABFF', end: '#0075CA' }
      },
      {
        weatherTypes: [3],
        gradient: { start: '#A1C3DD', end: '#5795C2' }
      },
      {
        weatherTypes: [45, 48],
        gradient: { start: '#BCBCBC', end: '#93989B' }
      },
      {
        weatherTypes: [56, 57, 66, 67],
        gradient: { start: '#9FC7EF', end: '#5AA0E0' }
      },
      {
        weatherTypes: [51, 53, 55, 61, 63, 65, 71, 73, 75, 77, 80, 81, 82, 85, 86, 95, 96],
        gradient: { start: '#BCC9D2', end: '#6D838E' }
      },
    ]
  },
  nighttime: {
    config: [
      {
        weatherTypes: [0, 1, 2],
        gradient: { start: '#035198', end: '#003465' }
      },
      {
        weatherTypes: [3],
        gradient: { start: '#5C6E80', end: '#31455A' }
      },
      {
        weatherTypes: [45, 48],
        gradient: { start: '#747678', end: '#282B2E' }
      },
      {
        weatherTypes: [56, 57, 66, 67],
        gradient: { start: '#678CB0', end: '#2F506F' },
      },
      {
        weatherTypes: [51, 53, 55, 61, 63, 65, 71, 73, 75, 77, 80, 81, 82, 85, 86, 95, 96],
        gradient: { start: '#4F5E6A', end: '#112634' }
      }
    ]
  }
};

type ForegroundConfig = {
  weatherTypes: number[];
  config: {
    icon: string;
    descriptionKey: string;
  };
};

export const foregroundConfig: ForegroundConfig[] = [
  {
    weatherTypes: [0, 1],
    config: {
      icon: 'sunny',
      descriptionKey: 'WEATHER_TYPE_SUNNY'
    },
  },
  {
    weatherTypes: [2],
    config: {
      icon: 'partlycloudy',
      descriptionKey: 'WEATHER_TYPE_PARTLY_CLOUDY'
    }
  },
  {
    weatherTypes: [3],
    config: {
      icon: 'cloudy',
      descriptionKey: 'WEATHER_TYPE_CLOUDY'
    }
  },
  {
    weatherTypes: [45, 48],
    config: {
      icon: 'fog',
      descriptionKey: 'WEATHER_TYPE_FOG'
    }
  },
  {
    weatherTypes: [51, 53],
    config: {
      icon: 'sprinkle',
      descriptionKey: 'WEATHER_TYPE_SPRINKLE'
    }
  },
  {
    weatherTypes: [55],
    config: {
      icon: 'rain_mix',
      descriptionKey: 'WEATHER_TYPE_RAIN_MIX'
    }
  },
  {
    weatherTypes: [56, 57, 66, 67],
    config: {
      icon: 'snowflake_cold',
      descriptionKey: 'WEATHER_TYPE_FREEZING'
    }
  },
  {
    weatherTypes: [61, 63, 65],
    config: {
      icon: 'rain',
      descriptionKey: 'WEATHER_TYPE_RAIN'
    }
  },
  {
    weatherTypes: [71, 73, 75, 77, 85, 86],
    config: {
      icon: 'snow',
      descriptionKey: 'WEATHER_TYPE_SNOW'
    }
  },
  {
    weatherTypes: [80, 81],
    config: {
      icon: 'showers',
      descriptionKey: 'WEATHER_TYPE_SHOWERS'
    }
  },
  {
    weatherTypes: [82],
    config: {
      icon: 'storm_showers',
      descriptionKey: 'WEATHER_TYPE_STORM_SHOWERS'
    }
  },
  {
    weatherTypes: [95, 96],
    config: {
      icon: 'thunderstorm',
      descriptionKey: 'WEATHER_TYPE_THUNDERSTORM'
    }
  },
];
