import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ACTION_MENU_ITEMS, ActionMenuModule, ContextMenuComponent, } from '@portal/wen-components';
import { CHALLENGE_CONTEXT_MENU_ITEMS } from './actions/challenge-action-menu';
import { TranslateModule } from '@ngx-translate/core';
import { FilterViewModule } from '../filter-view/filter-view.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChallengeContextMenuDatasource } from './challenge-context-menu/challenge-context-menu-datasource';

@NgModule({
  imports: [
    CommonModule,
    ActionMenuModule,
    ContextMenuComponent,
    TranslateModule,
    FilterViewModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    { provide: ACTION_MENU_ITEMS, useValue: CHALLENGE_CONTEXT_MENU_ITEMS, multi: true },
    ChallengeContextMenuDatasource
  ]
})
export class ChallengeModule { }
