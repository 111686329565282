import { inject, Injectable } from '@angular/core';
import { UserProfileResponse } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';
import { UserProfileService } from '../../../user-settings/user-profile.service';

@Injectable()
export class UserPersonalAccountSettingsDatasource {

  private userProfileService = inject(UserProfileService);

  public readonly userProfile$: Observable<UserProfileResponse>;

  constructor() {
    this.userProfile$ = this.userProfileService.currentUser$;
  }

}
