import { Inject, Injectable } from '@angular/core';
import { NativeConfig, NATIVE_CONFIG } from '../../../frame/api/tokens';
import { WenNativeApi } from '@portal/wen-native-api';

@Injectable()
export class NativeConfigurationService {

  constructor(
    @Inject(NATIVE_CONFIG) private nativeConfig: NativeConfig,
    private nativeApi: WenNativeApi,
  ) { }

  getNativeDeepLink() {
    return this.nativeConfig?.nativeDeepLink;
  }

  isSSoDialogEnabled() {
    return this.nativeApi.isReactNativeApp() && this.nativeConfig?.useNativeDialog;
  }

  isSSoIos() {
    return this.isSSoDialogEnabled() && this.nativeApi.isIOS();
  }
}
