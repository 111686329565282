import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ChannelMessageDTO } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Observable, Subject, combineLatest, distinctUntilChanged, filter, first, interval, map, merge, of, race, shareReplay, switchMap, takeUntil } from 'rxjs';
import { DateUtil } from '../../../../../../core/common/date/date-util';
import { filterBy } from '../../../../../../core/common/operators/fitler-by';
import { distinctIdChanges } from '../../../../../../core/common/util/operators/distinct-id-changes';
import { selectorWithParam } from '../../../../../../core/common/util/selector-with-param';
import { FeatureEnablementService } from '../../../../../../core/services/configuration/feature-enablement';
import { selectCurrentAutoAcknowledgeChannelId, selectCurrentChannel, selectCurrentChannelMessagesHistory, selectCurrentChannelPendingMessages } from '../../../../../../core/store/channel/channel.selectors';
import { ChannelMessageEntity } from '../../../../../../core/store/channel/channel.state';
import { selectNotificationCountForChannel } from '../../../../../../core/store/notification/notification.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';
import { MessageFilterService } from '../../../../../../shared/services/message-filter.service';
import { MessagesDatasource } from '../../../../../../shared/services/messages.datasource';
import { ChannelViewPreloaderGuard } from '../../../../common/providers/channel-view-preloader.guard';
import { ChannelViewDataSource } from './channel-view-datasource';

const MESSAGE_LOADING_TIMEOUT = 2000;

@Injectable()
export class ChannelViewMessagesDatasource extends MessagesDatasource<ChannelMessageEntity> implements OnDestroy {

  private onDestroy$ = new Subject<void>();
  private normalPendingMessageFilterFn = (message: ChannelMessageDTO) => !message.scheduled;

  lastUnreadMessage$: Observable<ChannelMessageDTO>;
  messages$: Observable<ChannelMessageDTO[]>;
  hasUnreadMessages$: Observable<boolean>;

  constructor(
    private store: Store<RootState>,
    private messageFilter: MessageFilterService,
    private channelViewDataSource: ChannelViewDataSource,
    private featureEnablementService: FeatureEnablementService,
    private channelViewPreloaderGuard: ChannelViewPreloaderGuard,
  ) {
    super();
  }

  override initialize(): void {
    const historicalMessages$ = this.store.pipe(
      select(selectCurrentChannelMessagesHistory),
      filterBy(() => this.channelViewPreloaderGuard.isChannelHistoryLoaded$)
    );
    const sortedHistoricalMessages$ = historicalMessages$.pipe(
      smartDistinctUntilChanged(),
      map((messages) => {
        return messages.sort((message1, message2) => DateUtil.compare(message2.timestamp, message1.timestamp));
      })
    );
    const historicalMessagesWithPendingMessages$ = combineLatest([
      historicalMessages$,
      this.store.pipe(
        select(selectCurrentChannelPendingMessages),
        map(projectionFn => projectionFn(this.normalPendingMessageFilterFn))
      )
    ]).pipe(
      distinctUntilChanged(),
      map(([messages, pendingMessages]) => {
        const pendingIds = pendingMessages.map(pendingMessage => pendingMessage.id);
        const uniqueMessages = messages.filter(message => !pendingIds.includes(message.id));
        const allMessages = pendingMessages.length ? uniqueMessages.concat(pendingMessages) : uniqueMessages;
        return allMessages.sort((message1, message2) => DateUtil.compare(message2.timestamp, message1.timestamp));
      })
    );

    const storeMessages$ = this.featureEnablementService.featureFlagMethods.isEnableDraftMessageSending() ?
      sortedHistoricalMessages$ : historicalMessagesWithPendingMessages$;

    this.messages$ = race(
      storeMessages$.pipe(
        filter(Boolean),
        map(() => true)
      ),
      interval(MESSAGE_LOADING_TIMEOUT).pipe(
        map(() => true)
      )
    ).pipe(
      first(),
      switchMap(() => storeMessages$.pipe(
        map(messages => this.messageFilter.filterMessages(messages)),
        map(messages => messages.length ? messages : null),
      )),
      shareReplay(1),
      smartDistinctUntilChanged(),
      takeUntil(this.onDestroy$)
    );

    this.lastUnreadMessage$ = this.store.pipe(
      select(selectCurrentChannel),
      distinctIdChanges(),
      switchMap(() => this.channelViewDataSource.isSubscribedToCurrentChannel$.pipe(
        first(),
        filter((isSubscribed) => isSubscribed),
      )),
      switchMap(() =>
        merge(
          storeMessages$.pipe(
            filter(messages => Boolean(messages?.length) && messages.some(message => message.new)),
            first(),
            switchMap(messages => this.store.pipe(
              select(selectCurrentAutoAcknowledgeChannelId),
              first(),
              map((currentAutoAcknowledgeChannelId) => {
                const newMessage = messages.find(message => message.new) ?? null;
                return currentAutoAcknowledgeChannelId === newMessage?.channelId ? null : newMessage;
              }),
            ))
          ),
          storeMessages$.pipe(
            filter(messages => Boolean(messages?.length) && messages.every(message => !message.new)),
            first(),
            map(() => null)
          )
        )
      ),
      distinctUntilChanged((a, b) => a?.id === b?.id),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );

    this.hasUnreadMessages$ = this.store.pipe(
      select(selectCurrentChannel),
      filter(channel => Boolean(channel)),
      map(channel => channel.id),
      distinctUntilChanged(),
      switchMap(channelId => this.store.pipe(selectorWithParam(selectNotificationCountForChannel, channelId))),
      switchMap(count => {
        if (count < 1) {
          return of(false);
        }
        return this.store.pipe(
          select(selectCurrentAutoAcknowledgeChannelId),
          map(isEnabled => !isEnabled)
        );
      }),
      distinctUntilChanged(),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );

    super.initialize();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
