import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfigurationProvider } from '../../../../../core/services/configuration/app-confguration';
import { VersionInfo, VERSION_INFO } from '../../../../../frame/api/tokens';

@Component({
  selector: 'wen-licenses-view',
  templateUrl: './licenses-view.component.html',
  styleUrls: ['./licenses-view.component.scss']
})
export class LicensesViewComponent implements OnInit {

  licensesUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.config.licensesPage);

  constructor(
    @Inject(VERSION_INFO) public versionInfo: VersionInfo,
    private config: AppConfigurationProvider,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

}
