import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { ReadCategoriesFieldModule } from '../../../../../../../shared/components/read-categories-field/read-categories-field.module';
import { SharedModule } from '../../../../../../../shared/shared.module';
import { AppInfoEditComponent } from './app-info-edit.component';

@NgModule({
  imports: [
    SharedModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    ReadCategoriesFieldModule,
    PortalFieldModule,
  ],
  declarations: [
    AppInfoEditComponent,
  ],
  exports: [
    AppInfoEditComponent,
  ]
})
export class AppInfoEditModule { }
