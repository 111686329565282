import { Directive, HostBinding, Input, Self, ViewContainerRef } from '@angular/core';
import { VariantConfig } from './variant-config';
import { kebabize } from './kebabize';

@Directive({
  selector: '[wenVariant]'
})
export class WithVariantDirective {

  private readonly baseName: string;

  @Input() wenVariant: string;

  @HostBinding('class') get classNames() {
    const { defaultVariant, possibleVariants } = this.variantConfig;
    const usedVariant = this.wenVariant || defaultVariant;
    const variant = possibleVariants[usedVariant];
    if (!variant) {
      throw new Error(`Invalid variant: ${usedVariant} for ${this.baseName}!`);
    }
    return `${this.baseName}-variant-${kebabize(this.wenVariant)}`;
  }

  constructor(
    @Self() private variantConfig: VariantConfig,
    vcRef: ViewContainerRef
  ) {
    const nativeElement: HTMLElement = vcRef.element.nativeElement;
    this.baseName = nativeElement.nodeName.toLowerCase();
    if (!variantConfig) {
      throw new Error(`Missing VariantConfig for: ${this.baseName}!`);
    }
  }
}
