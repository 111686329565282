import { MessageModificationState, ToRoomEventType } from '@portal/wen-backend-api';
import { DateUtil } from '../../../../core/common/date/date-util';
import { ChatMessageEntity, ChatRoomEntity } from '../../../../core/store/chat/chat.state';
import { MessageBoxStatus } from '../components/message-status-indicator.component';

export type ChatMessageEntityForStatus = Pick<
  ChatMessageEntity,
  'decryptionError' | 'eventId' | 'messageContent' | 'state' | 'insertTimestamp'
>;

export const calculateChatMessageStatus = (
  messageEntity: ChatMessageEntityForStatus,
  room: Pick<ChatRoomEntity, 'lastReadTimestamp' | 'lastReceivedTimestamp'>
) => {
  if (!messageEntity || !room) {
    return null;
  }
  if (messageEntity.state === MessageModificationState.DELETED) {
    return null;
  }
  if (messageEntity.decryptionError) {
    return null;
  }
  if (messageEntity?.messageContent?.eventType === ToRoomEventType.AUTO_REPLY) {
    return null;
  }
  if (!messageEntity.eventId) {
    return MessageBoxStatus.PENDING;
  }
  const isRead = DateUtil.compare(messageEntity.insertTimestamp, room.lastReadTimestamp);
  if (isRead >= 0) {
    return MessageBoxStatus.READ;
  }
  const isReceived = DateUtil.compareNullSafe(messageEntity.insertTimestamp, room.lastReceivedTimestamp);
  if (isReceived >= 0) {
    return MessageBoxStatus.RECEIVED;
  }
  return MessageBoxStatus.SENT;
};
