import { Inject, Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { WenBreakpointObserver } from '@portal/wen-components';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, of } from 'rxjs';
import { FeatureEnablementService } from '../../../core/services/configuration/feature-enablement';
import { WenNativeApi } from '@portal/wen-native-api';
import { BrowserInfo } from '../../../core/services/util/browser-info';
import { RootState } from '../../../core/store/root/public-api';
import { selectOutletDatas } from '../../../core/store/root/root.selectors';
import { selectIsFromSmartDesign } from '../../../core/store/smartdesign/smartdesign.selectors';
import { NATIVE_CONFIG, NativeConfig } from '../../../frame/api/tokens';

@Injectable()
export class AppBannerVisibility {

  private isAppBannerDismissed$ = new BehaviorSubject(false);

  constructor(
    private featureEnablementService: FeatureEnablementService,
    @Inject(NATIVE_CONFIG) private nativeConfig: NativeConfig,
    private store: Store<RootState>,
    private browserInfo: BrowserInfo,
    private nativeApi: WenNativeApi,
    private breakpointObserver: WenBreakpointObserver,
  ) {
  }

  hasIosConfig() {
    return (this.nativeConfig?.storeInfos?.iosAppName && this.nativeConfig?.storeInfos?.iosAppStoreId)
      || this.nativeConfig?.storeInfos?.iosTestFlightLink;
  }

  hasAndroidConfig() {
    return this.nativeConfig?.storeInfos?.androidPlayStoreId;
  }

  isVisible() {
    const appBannerDisabled = this.nativeApi.isReactNativeApp() || !this.featureEnablementService.isAppBannerEnabled();
    if (appBannerDisabled) {
      return of(false);
    }
    const isDisabledOnRoute$ = this.store.pipe(
      select(selectOutletDatas),
      map(outletDatas => {
        return Boolean(outletDatas?.primaryData?.appBannerDisabled);
      }),
      distinctUntilChanged()
    );
    const isFromSmartDesign$ = this.store.pipe(
      select(selectIsFromSmartDesign),
      distinctUntilChanged()
    );
    const isIos = this.browserInfo.browserIsIos();
    const isAndroid = this.browserInfo.browserIsAndroid();
    const hasIosData = this.hasIosConfig();
    const hasAndroidData = this.hasAndroidConfig();
    return combineLatest([this.isAppBannerDismissed$, isDisabledOnRoute$, isFromSmartDesign$]).pipe(
      map(([isDisabledOnRoute, isAppBannerDismissed, isFromSmartDesign]) => {
        if (isAppBannerDismissed || isDisabledOnRoute || isFromSmartDesign) {
          return false;
        }

        if (this.breakpointObserver.isDesktopStyleDevice) {
          return Boolean(hasIosData || hasAndroidData);
        } else {
          return Boolean((isIos && hasIosData) || (isAndroid && hasAndroidData));
        }
      })
    );
  }

  setAppBannerDismissed() {
    this.isAppBannerDismissed$.next(true);
  }

}
