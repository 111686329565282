import { ENVIRONMENT_INITIALIZER, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { MassDataRegistry } from './config/mass-data-module-utils';
import { createMassDataFeature } from './feature/mass-data-feature';
import { MassDataEffects } from './effects/mass-data.effects';
import { MassDataActionsFactory } from './mass-data.actions';
import { OffsetBasedMassDataPagingAdapter } from './paging/offset-based-mass-data-paging-adapter';
import { MassDataSelectorsFactory } from './selector/mass-data-selector';

@NgModule({
  imports: [
    EffectsModule.forFeature([MassDataEffects])
  ],
  providers: [
    MassDataActionsFactory,
    OffsetBasedMassDataPagingAdapter,
    MassDataRegistry,
    MassDataSelectorsFactory,
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => createMassDataFeature(),
      multi: true
    }
  ]
})
export class MassDataModule { }
