
import { Pipe, PipeTransform } from '@angular/core';
import { ChannelMessageDTO } from '@portal/wen-backend-api';

@Pipe({
  name: 'messageTimestamp',
  pure: true
})
export class MessageTimestampPipe implements PipeTransform {

  transform(message: ChannelMessageDTO): string {
    return message.timestamp;
  }
}
