import { Directive } from '@angular/core';

@Directive({
  selector: 'wen-list-item-icon'
})
export class ListItemIconDirective { }

@Directive({
  selector: 'wen-list-item-title'
})
export class ListItemTitleDirective { }

@Directive({
  selector: 'wen-list-item-title-suffixicon'
})
export class ListItemTitleSuffixIconDirective { }

@Directive({
  selector: 'wen-list-item-title-prefixicon'
})
export class ListItemTitlePrefixIconDirective { }

@Directive({
  selector: 'wen-list-item-description, [wen-list-item-description]'
})
export class ListItemDescriptionDirective {
}

@Directive({
  selector: '[wen-list-item-subtitle]'
})
export class ListItemSubtitleDirective { }

@Directive({
  selector: 'wen-list-item-after, [wen-list-item-after]'
})
export class ListItemAfterDirective { }
