import { Injectable, inject } from '@angular/core';
import { SocketIoService, isPendingStatus } from '@portal/wen-backend-api';
import { MassDataUpdatesPlugin } from '@portal/wen-data-core';
import { filter } from 'rxjs';
import { PendingContactEntity } from '../contacts.state';

@Injectable()
export class PendingContactsMassDataUpdatesPlugin extends MassDataUpdatesPlugin<PendingContactEntity> {

  private socketIoService = inject(SocketIoService);

  upserts$ = this.socketIoService.contact.contactUpdated.listen.pipe(
    filter((contactUpdate) => {
      return isPendingStatus(contactUpdate.requestStatus);
    }),
  );

  removes$ = this.socketIoService.contact.contactUpdated.listen.pipe(
    filter((contactUpdate) => {
      return !isPendingStatus(contactUpdate.requestStatus);
    }),
  );

}
