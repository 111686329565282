
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActionMenuComponent, ActionMenuItem, OverlayManager } from '@portal/wen-components';
import { map, ReplaySubject, combineLatest, filter, switchMap, from, scan } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { ActionMenuItemProvider } from '../../../../../core/services/util/action-menu-item.provider';
import { selectCurrentRoomMembers } from '../../../../../core/store/chat/chat.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { chatDetailMembersMenuId } from '../../action-menu/constants';
import { MemberState, WenUserRole } from '@portal/wen-backend-api';
import { ChatUserDTO } from '../../../../../core/store/chat/chat.state';
import { ColorPalette } from '../../../../../shared/directives/colorize/palette-provider';
import { maxNumberOfUsersForChatGroup } from '../../../constants';
import { userProfileIdentifier } from '../../../../user-profile/tokens';
import { WenRouteId } from '../../../../../frame/routing/types';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';

type ChatDetailUser = ChatUserDTO & {
  role: WenUserRole | null;
  canHaveActions: boolean;
};

type ChatMemberInfo = {
  member: ChatDetailUser;
  actions: ActionMenuItem[];
};

@Component({
  selector: 'wen-chat-detail-user-list',
  templateUrl: './chat-detail-user-list.component.html',
  styleUrls: ['./chat-detail-user-list.component.scss'],
})
export class ChatDetailUserListComponent implements OnInit {

  isAdmin$ = new ReplaySubject<boolean>();

  memberListView$: Observable<ChatMemberInfo[]>;

  maxNumberOfUsers = maxNumberOfUsersForChatGroup;

  @Input() set isAdmin(value: boolean) {
    this.isAdmin$.next(value);
  }

  @Input() roomId: string;

  @Input() set memberCount(value: number) {
    this.isMemberAddDisabled = value >= this.maxNumberOfUsers;
  }

  isMemberAddDisabled: boolean;
  actions$: Observable<ActionMenuItem[]>;

  constructor(
    private store: Store<RootState>,
    private navigationHelper: WenNavigationHelper,
    private actionMenuItemProvider: ActionMenuItemProvider,
    private appNavigator: AppNavigator,
    private overlayManager: OverlayManager,
  ) { }

  get contextMenuOpenerFillColor(): ColorPalette {
    return ColorPalette.GREY;
  }

  ngOnInit(): void {
    this.memberListView$ = combineLatest([
      this.isAdmin$,
      this.store.pipe(select(selectCurrentRoomMembers))
    ]).pipe(
      filter(([_, members]) => !!members),
      switchMap(([isAdmin, members]) => from(members).pipe(
        switchMap(member => {
          let memberInfo: ChatMemberInfo;
          return this.actionMenuItemProvider.selectActions<string, ChatUserDTO, void>(chatDetailMembersMenuId, member).pipe(
            map(actions => {
              const memberView = {
                ...member,
                role: member.state === MemberState.OWNER ? WenUserRole.ADMINISTRATOR : null,
                canHaveActions: isAdmin && !!actions.length && !member.isDeleted
              };
              memberInfo = { member: memberView, actions };
              return memberInfo;
            })
          );
        }),
        scan((arr, element) => [...arr, element], [])
      ))
    );
  }

  navigateToUserProfile(userId: string) {
    this.appNavigator.navigateToRoute(WenRouteId.OTHER_USERPROFILE_READ, {[userProfileIdentifier]: userId});
  }

  navigateToAddMemberForGroupChat() {
    if (this.isMemberAddDisabled) {
      return;
    }
    this.navigationHelper.navigateToGroupChatAddMembers(this.roomId);
  }

  openContextMenu(chatMemberInfo: ChatMemberInfo){
    this.overlayManager.menu.open(ActionMenuComponent, {actions: chatMemberInfo.actions, data: chatMemberInfo.member.userId});
  }

}
