<ng-container>
  <cdk-virtual-scroll-viewport #scrollable [itemSize]="itemSize" class="wen-pending-contacts-list-viewport">
    @if (isEmpty$ | async) {
      <ng-content select="wen-list-mass-data-empty, [wen-list-mass-data-empty]"></ng-content>
    } @else {
      <ng-container *cdkVirtualFor="let item of datasource; let index = index; trackBy: trackByFn">
        <ng-container [ngTemplateOutlet]="itemTemplate.templateRef"
        [ngTemplateOutletContext]="{ $implicit: item, index: index }"></ng-container>
      </ng-container>
    }
  </cdk-virtual-scroll-viewport>
</ng-container>