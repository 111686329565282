import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { WenRouteId } from '../../../../frame/routing/types';
import { AppNavigator } from '../../../../core/services/navigation/app-navigator';

export interface CreateWelcomeConfig {
  imagePath: string;
  translationKey: string;
  navigateAction: () => void;
}

@Injectable()
export class CreateWelcomeContentResolver {

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
  ) { }

  resolveContent(): CreateWelcomeConfig {
    const routeId = this.activatedRoute.snapshot.data.routeId;
    if (routeId === WenRouteId.CHANNEL_ADD_WELCOME) {
      return {
        translationKey: 'CREATE_CHANNEL_WELCOME_CONTENT',
        imagePath: '/assets/wen-widget/image/channel-create-welcome-image.svg',
        navigateAction: () => this.navigationHelper.navigateToAddChannelInfo()
      };
    } else if (routeId === WenRouteId.APP_ADD_WELCOME) {
      return {
        translationKey: 'CREATE_APP_WELCOME_CONTENT',
        imagePath: '/assets/wen-widget/image/app-create-welcome-image.svg',
        navigateAction: () => this.appNavigator.navigateToRoute(WenRouteId.ADD_APP_INFO)
      };
    }
    return { translationKey: '', imagePath: '', navigateAction: () => { } };
  }

}
