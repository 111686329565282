import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';

@Injectable()
export abstract class FormValueConverter<TFormModel, TServerModel> {
  abstract convertToFormModel(value: TServerModel): Observable<TFormModel>;
  abstract convertToServerModel(value: TFormModel): Observable<TServerModel>;
}

export class NoopFormValueConverter extends FormValueConverter<any, any> {
  convertToFormModel(value: any): Observable<any> {
    return of(value);
  }
  convertToServerModel(value: any): Observable<any> {
    return of(value);
  }
}

@Injectable()
export abstract class FormAsyncValidator<TFormModel> implements AsyncValidator {
  abstract validate(control: AbstractControl<TFormModel>): Observable<ValidationErrors>;
}
