import { Directive, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, switchMap } from 'rxjs/operators';
import { selectorWithParam } from '../../../core/common/util/selector-with-param';
import { selectIsCurrentChannelGeoChannel, selectCurrentChannel, selectIsChannelSubscribed } from '../../../core/store/channel/channel.selectors';
import { RootState } from '../../../core/store/root/public-api';
import { selectRouteData } from '../../../core/store/root/root.selectors';
import { WenRouteId } from '../../../frame/routing/types';

@Directive({
  selector: '[ifGeoChannelClass], ifGeoChannelClass'
})
export class GeoChannelClassDirective implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  @HostBinding('class.wen-geo-channel-filter-context') hasFilterContextClassName: boolean;

  constructor(
    private store: Store<RootState>,
  ) { }

  ngOnInit() {
    const isGeoChannel$ = this.store.pipe(
      select(selectIsCurrentChannelGeoChannel),
      distinctUntilChanged()
    );
    const isChannelSubscribed$ = this.store.pipe(
      select(selectCurrentChannel),
      filter(data => !!data),
      switchMap((data) => this.store.pipe(
        selectorWithParam(selectIsChannelSubscribed, data.id)
      )),
      distinctUntilChanged()
    );
    const routeId$ = this.store.pipe(
      select(selectRouteData),
      filter((data) => Boolean(data)),
      map(({ routeId }) => routeId),
      distinctUntilChanged()
    );
    combineLatest([
      isGeoChannel$, routeId$, isChannelSubscribed$
    ]).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(([isGeoChannel, routeId, isChannelSubscribed]) => {
      this.hasFilterContextClassName = isGeoChannel && routeId === WenRouteId.CHANNEL_VIEW && isChannelSubscribed;
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
