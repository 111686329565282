import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '@portal/wen-components';
import { ThumbnailImageModule } from '../thumbnail-image/thumbnail-image.module';
import { LinkEmbedPreviewComponent } from './link-embed-preview.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ThumbnailImageModule,
    WenIconModule,
  ],
  declarations: [
    LinkEmbedPreviewComponent
  ],
  exports: [
    LinkEmbedPreviewComponent
  ]
})
export class LinkEmbedPreviewModule { }
