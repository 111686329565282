import { ChannelMessageDTO, EmbedDTOTypes, MessageModificationState } from '@portal/wen-backend-api';
import { DateUtil } from '../../../common/date/date-util';
import { nonNullArray } from '../../../common/operators/array-utils';
import { UserData } from '../../auth/models/UserData';
import { DraftMessageEntity } from '../../common/models';
import { createPendingChannelMessage } from '../channel.actions';
import { ChannelMessageEntity } from '../channel.state';

type PendingMessageProps = Pick<ChannelMessageEntity, 'id' | 'content' | 'scheduled' | 'timestamp' | 'channelId'>;

export const createPendingMessageActionFromDraft = (
  draftMessage: DraftMessageEntity,
  userData: UserData,
  temporaryId: string
) => {
  const { id, contextId, content, fileEmbed, linkEmbed, scheduled, timestamp } = draftMessage;
  const embeds = nonNullArray([fileEmbed, linkEmbed]);
  return createPendingMessageAction({
    id, channelId: contextId, content, timestamp, scheduled
  }, userData, temporaryId, embeds);
};

export const createPendingMessageAction = (
  baseProps: PendingMessageProps,
  userData: UserData,
  temporaryId: string,
  embeds: EmbedDTOTypes[] = []
) => {
  const { id, content, scheduled = false, channelId, timestamp } = baseProps;
  const isEdit = Boolean(id);
  const message: ChannelMessageDTO = {
    content,
    scheduled,
    channelId,
    timestamp: timestamp || DateUtil.currentDateString(),
    updateTimestamp: isEdit ? DateUtil.currentDateString() : null,
    embeds,
    id: isEdit ? id : temporaryId,
    new: false,
    type: isEdit ? MessageModificationState.EDITED : MessageModificationState.ORIGINAL,
    userId: userData?.username || '',
    authorId: userData.userId,
  };
  return createPendingChannelMessage({ message });
};
