import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { EmbeddedDocumentDTO } from '@portal/wen-backend-api';
import { MessageBoxActionsHandler } from '../../../providers/message-box-tokens';

@Component({
  selector: 'wen-embed-document',
  templateUrl: './embed-document.component.html',
  styleUrls: ['./embed-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedDocumentComponent implements OnInit {

  @HostBinding('class.wen-embedded-document') className = true;

  @Input() model: EmbeddedDocumentDTO;
  @Input() messageId: string;

  constructor(
    private actionsHandler: MessageBoxActionsHandler,
  ) { }

  ngOnInit(): void {
  }

  navigateToDocument() {
    this.actionsHandler.handleDocumentClicked(this.messageId, this.model);
  }

}
