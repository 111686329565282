import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { PendingChannelJoinRequestComponent } from './pending-channel-join-request.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    PendingChannelJoinRequestComponent,
  ]
})
export class PendingChannelJoinRequestModule { }
