
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { catchError, filter, first, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { DateUtil } from '../../../../core/common/date/date-util';
import { fetchPermissionsForChannel } from '../../../../core/store/channel/channel.actions';
import { selectChannelById, selectFeaturedData, selectMessageDetailById } from '../../../../core/store/channel/channel.selectors';
import { fetchCommentsForParentElements, setParentIdForComment, updateTimestampOfPageVisit } from '../../../../core/store/comments/comments.actions';
import { RootState } from '../../../../core/store/root/public-api';
import { selectRouteParam } from '../../../../core/store/root/root.selectors';
import { WenRouteId } from '../../../../frame/routing/types';
import { channelMessageDetailIdentifier } from '../../../../views/channel/tokens';

export const commentListDataResolverFn = (route: ActivatedRouteSnapshot) => {
  return inject(CommentListDataResolver).resolve(route);
};

@Injectable()
export class CommentListDataResolver  {

  constructor(private store: Store<RootState>) { }

  resolve(route: ActivatedRouteSnapshot) {
    const parentInformation$ = this.getParentInformation(route.data?.routeId);
    return parentInformation$.pipe(
      first(),
      switchMap((parentData) => {
        if (!parentData) {
          return of(true);
        }
        return of(parentData).pipe(
          tap(({ channel }) => {
            if (!channel?.permission) {
              this.store.dispatch(fetchPermissionsForChannel({ channelId: channel.id }));
            }
          }),
          tap(({ message }) => {
            const parentId = message.id;
            this.store.dispatch(setParentIdForComment({ parentId }));
            this.store.dispatch(updateTimestampOfPageVisit({ timestamp: DateUtil.toSQLDateFilter(new Date().toISOString()) }));
            this.store.dispatch(fetchCommentsForParentElements({ parentIds: [parentId] }));
          }),
        );
      }),
      catchError(e => {
        console.error(e);
        return of(e);
      })
    );
  }

  private getParentInformation(routeId: WenRouteId): Observable<any | null> {
    switch (routeId) {
      case WenRouteId.CHANNEL_FEATURED_VIEW:
        return this.store.pipe(
          select(selectFeaturedData),
          filter(featuredData => Boolean(featuredData?.message))
        );
      case WenRouteId.CHANNEL_MESSAGE_DETAIL:
        return this.store.pipe(
          select(selectRouteParam(channelMessageDetailIdentifier)),
          switchMap(parentId => {
            return this.store.pipe(
              select(selectMessageDetailById),
              map(projectorFn => projectorFn(parentId))
            );
          }),
          switchMap(messageEntity => {
            if (!messageEntity) {
              return of(null);
            }
            return this.store.pipe(
              select(selectChannelById),
              map(projectorFn => projectorFn(messageEntity.channelId)),
              map(channelEntity => {
                return {
                  channel: channelEntity,
                  message: messageEntity
                };
              })
            );
          })
        );
      default:
        return throwError(() => new Error(`No parent id selection method is defined for route: ${routeId}`));
    }
  }
}
