import { Injectable } from '@angular/core';

@Injectable()
export class WenColorService {

  private get style() {
    return getComputedStyle(document.body);
  }

  get primaryColor() {
    return this.style.getPropertyValue('--wen-primary-color')?.trim();
  }

  get headerBackgroundColor() {
    return this.style.getPropertyValue('--wen-header-background-color')?.trim();
  }

  get headerTextColor() {
    return this.style.getPropertyValue('--wen-header-text-color')?.trim();
  }

  setCssProperty(propName: string, value: string) {
    const root: HTMLElement = document.querySelector(':root');
    root.style.setProperty(propName, value);
  }

}
