import { Component, Input } from '@angular/core';
import { ForwardContext } from '@portal/wen-backend-api';
import { ForwardService } from '../../../core/services/forward/forward.service';

@Component({
  selector: 'wen-forward-button',
  templateUrl: './forward-button.component.html'
})
export class ForwardButtonComponent {

  @Input() deepLink: string;

  @Input() context: ForwardContext;

  constructor(
    private forwardService: ForwardService
  ) { }

  handleForward() {
    const id = this.deepLink.substring(this.deepLink.lastIndexOf('/') + 1);
    this.forwardService.initiateForward(this.deepLink, this.context, id);
  }

}
