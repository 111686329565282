import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { WenBreakpointObserver } from '@portal/wen-components';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { WenDesktopRouteDataResolver } from '../../services/navigation/route-data/desktop-route-data-resolver';
import { WenMobileRouteDataResolver } from '../../services/navigation/route-data/mobile-route-data-resolver';
import { WenResolvedRouteData } from '../../services/navigation/route-data/route-data-types';
import { selectSearchbarActive } from '../header/header.selectors';
import { RootState } from '../root/public-api';
import { selectOutletIds } from '../root/root.selectors';
import { reloadNavBarVisibility, updateNavBarVisibility } from './navigation-bar.actions';

@Injectable()
export class NavigationBarEffects {

  loadInitialNavBarVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, reloadNavBarVisibility),
    switchMap(() => combineLatest([
      this.store.pipe(select(selectOutletIds), first()),
    ])),
    map(([{ primaryId, sidebarId }]) => {
      const isDesktopDevice = this.breakpointObserver.isDesktopStyleDevice;
      if (isDesktopDevice) {
        return [this.desktopRouteDataResolver.getDesktopRouteData(
          primaryId, sidebarId
        ), isDesktopDevice] as [WenResolvedRouteData, boolean];
      } else {
        return [this.mobileRouteDataResolver.getMobileRouteData(
          primaryId
        ), isDesktopDevice] as [WenResolvedRouteData, boolean];
      }
    }),
    filter(([data]) => Boolean(data)),
    switchMap((data) => this.store.pipe(
      select(selectSearchbarActive),
      distinctUntilChanged(),
      map((searchbarActive) => {
        const [{ hideNavigationBar, headerData }, isDesktopDevice] = data;
        const hasSearchBar = Boolean(headerData?.searchConfig);
        const isNavBarVisible = !isDesktopDevice && hasSearchBar && searchbarActive ? false : !hideNavigationBar;
        return updateNavBarVisibility({ isNavBarVisible });
      })
    ))
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly breakpointObserver: WenBreakpointObserver,
    private readonly mobileRouteDataResolver: WenMobileRouteDataResolver,
    private readonly desktopRouteDataResolver: WenDesktopRouteDataResolver,
  ) { }

}
