<wen-we-feed (scrolledToBottom)="enableAutoAcknowledge()"
  [flowDirection]="flowDirection" [flowStyle]="'alternating'"
  [reactionContext]="reactionContext"
  [messageMaxWidth]="336"
  [messagePaddingCorrection]="60">
  <ng-template wen-message-box-content-template let-data="data">
    <wen-chat-message-content (requestShareKey)="onRequestShareKeyClicked()" [message]="data"></wen-chat-message-content>
  </ng-template>
  <ng-container ngProjectAs="wen-we-feed-empty-placeholder">
    <ng-container *ngTemplateOutlet="emptyPlaceholder" ngProjectAs="wen-we-feed-empty-placeholder"></ng-container>
  </ng-container>
  @if (!disableScrollToBottomButton) {
    <wen-scroll-to-button (scrolledTo)="onScrollToBottomClicked()"></wen-scroll-to-button>
  }
</wen-we-feed>

<ng-template #emptyPlaceholder>
  <ng-content select="wen-we-feed-empty-placeholder"></ng-content>
  @if (isGroupChat) {
    <wen-we-feed-empty-placeholder [text]="'GROUP_CHAT_NO_MESSAGE_TITLE' | translate"
    [imageUrl]="'/assets/wen-widget/image/pic-group-chat-success-120.svg'"></wen-we-feed-empty-placeholder>
  }
</ng-template>
