import { EmitMetaData } from '../data-types/user/metadata';
import { SocketPayload } from '../events/events';

export const extendPayload = (
  originalPayload: SocketPayload,
  extendData: EmitMetaData
) => {
  const cleanedExtendData = Object.fromEntries(
    Object.entries(extendData).filter(([key, value]) => Boolean(value))
  );
  if (!originalPayload || !cleanedExtendData) {
    return originalPayload;
  }
  return {
    ...originalPayload,
    ...cleanedExtendData
  };
};
