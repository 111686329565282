<form wenFormStore class="wen-user-change-password-form wen-form-v2" [formGroup]="userChangePasswordFormGroup"
  wenFocusFirstInput>
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper">
      <wen-password-field [forFormControlName]="'oldPassword'" [labels]="oldLabels$ | async"></wen-password-field>
      <div class="wen-user-change-password-reset-password-link" portal-subheading-1 wen-primary-color
        (click)="navigateToPasswordReset()">{{ "USER_PROFILE_FORM_CHANGE_PASSWORD_FORGOT_PASSWORD_HINT_LABEL" |
        translate }}</div>
    </div>
    <div class="wen-form-field-wrapper">
      <wen-password-field [forFormControlName]="'newPassword'" [withRepeat]="true"
        [labels]="newLabels$ | async"></wen-password-field>
    </div>
  </div>
</form>