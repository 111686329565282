import { NgModule } from '@angular/core';
import { RegistrationCommonModule } from '../common/registration-common.module';
import { RegistrationStartComponent } from './components/registration-start/registration-start.component';

@NgModule({
  imports: [
    RegistrationCommonModule,
  ],
  declarations: [
    RegistrationStartComponent
  ],
  exports: [
    RegistrationStartComponent
  ]
})
export class RegistrationStartModule { }
