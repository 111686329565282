import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ChannelEntity } from '../../../../core/store/channel/channel.state';
import { ChannelSubscribedToSource } from '../../../../views/channel/common/providers/channel-subscribed-to-source';
import { SubscribedToSource } from '../../../directives/subscibed-to/subscribed-to-source';
import { ChannelMuteService } from '../../providers/channel-mute.service';

@Component({
  selector: 'wen-mute-unmute-channel-button',
  templateUrl: './mute-unmute-channel-button.component.html',
  viewProviders: [
    { provide: SubscribedToSource, useClass: ChannelSubscribedToSource }
  ]
})
export class MuteUnmuteChannelButtonComponent implements OnInit {

  @HostBinding('class.wen-mute-unmute-channel-button') className = true;

  @Input() channel: ChannelEntity;

  get isMuted() {
    return this.channel?.isMuted;
  }

  get channelId() {
    return this.channel?.id;
  }

  constructor(
    private channelMuteService: ChannelMuteService
  ) { }

  ngOnInit(): void {
  }

  muteChannel(event: Event) {
    this.channelMuteService.setMuteForChannel(this.channelId, true, true);
    event.stopPropagation();
  }

  unmuteChannel(event: Event) {
    this.channelMuteService.setMuteForChannel(this.channelId, false, true);
    event.stopPropagation();
  }

}
