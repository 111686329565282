import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SocketIoService } from '@portal/wen-backend-api';
import { first, map, shareReplay, switchMap } from 'rxjs';
import { subscribeToJoinRequestChannelUpdates, updateInviteRequestUserChannelList } from '../../channel.actions';

@Injectable()
export class ChannelInvitationEffects {

  private readonly channelInvitationsListForUser$ = this.socketIoService.channelInvitation.channelInvitationsList.listen.pipe(
    shareReplay(1)
  );

  invitationList$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToJoinRequestChannelUpdates),
    first(),
    switchMap(() => this.channelInvitationsListForUser$.pipe(
      map((channels) => {
        return updateInviteRequestUserChannelList({ channels });
      })
    ))
  ));

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
  ) { }
}
