import { SessionModel } from '../persistence/data-models';

export const findBestFittingSession = (
  sessions: SessionModel[]
) => {
  if (sessions.length === 0) {
    return null;
  } else if (sessions.length === 1) {
    return sessions[0];
  }
  let bestSession: SessionModel = sessions[0];
  sessions.slice(1).forEach((session) => {
    const bestTimestamp = bestSession?.lastActivityTimestamp || 0;
    const currentTimestamp = session?.lastActivityTimestamp || 0;
    if (currentTimestamp > bestTimestamp || (currentTimestamp === bestTimestamp && session.sessionId < bestSession.sessionId)) {
      bestSession = session;
    }
  });
  return bestSession;
};
