import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService, TranslateStore, USE_EXTEND } from '@ngx-translate/core';
import { WenLanguage } from '@portal/wen-backend-api';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { WenTranslateLoader } from './providers/wen.translate.loader';
import { TRANSLATION_CONFIG, WenTranslationConfig } from './tokens/translation.token';

@NgModule({
  imports: [
    TranslateModule.forRoot({ extend: true })
  ],
  exports: [TranslateModule],
})
export class WenTranslationModule {

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe(({ lang }) => this.translateService.getTranslation(lang));
  }

  static forRoot(config: Partial<WenTranslationConfig> = {}): ModuleWithProviders<WenTranslationModule> {
    const updatedConfig = this.setSupportedLanguages(config);
    return {
      ngModule: WenTranslationModule,
      providers: [
        config.loader || { provide: TranslateLoader, useClass: WenTranslateLoader },
        config.compiler || { provide: TranslateCompiler, useClass: TranslateMessageFormatCompiler },
        { provide: USE_EXTEND, useValue: config.extend },
        TranslateStore,
        { provide: TRANSLATION_CONFIG, useValue: updatedConfig },
        WenTranslateLoader
      ]
    };
  }

  private static setSupportedLanguages(config: Partial<WenTranslationConfig>): Partial<WenTranslationConfig> {
    const supportedLanguages = config?.supportedLanguages || [WenLanguage.GERMAN];
    config.supportedLanguages = supportedLanguages;
    return config;
  }
}
