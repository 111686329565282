import { NgModule } from '@angular/core';
import { FormStoreDirective } from './form-store.directive';
import { FormErrorStateMatcher } from './form-error-state-matcher';

@NgModule({
  declarations: [
    FormStoreDirective,
  ],
  providers: [
    FormErrorStateMatcher
  ],
  exports: [
    FormStoreDirective,
  ]
})
export class FormStoreModule { }
