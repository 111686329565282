import { ChangeDetectionStrategy, Component, ContentChild, forwardRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatHint } from '@angular/material/form-field';
import { isErrorVisible } from '@portal/ui-kit/core';
import { createPasswordControl, createPasswordErrorStateMatcher, createPasswordsEqualsValidator, PASSWORD_REPEAT_VIRTUAL_CONTROL_NAME } from './password-field-utils';

export type PasswordFieldLabels = {
  passwordLabel: string;
  passwordRepeatLabel?: string;
  passwordRepeatError?: string;
};

@Component({
  selector: 'wen-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PasswordFieldComponent),
    },
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordFieldComponent implements ControlValueAccessor, OnInit {

  @HostBinding('class.wen-password-field') className = true;

  readonly PASSWORD_REPEAT_VIRTUAL_CONTROL_NAME = PASSWORD_REPEAT_VIRTUAL_CONTROL_NAME;
  comparePasswordErrorMatcher: ErrorStateMatcher;

  private formGroupDirective = inject(FormGroupDirective);

  @ContentChild(MatHint) hint: MatHint;

  @Input() forFormControlName: string;

  private cleanupWithRepeatControl: () => void;
  withRepeatValue: boolean;
  @Input() set withRepeat(newValue: boolean) {
    if (newValue && !this.withRepeatValue) {
      this.comparePasswordErrorMatcher = createPasswordErrorStateMatcher(this.forFormControlName);
      this.formGroupDirective.control.addControl(PASSWORD_REPEAT_VIRTUAL_CONTROL_NAME, createPasswordControl());
      const eqValidator = createPasswordsEqualsValidator(this.forFormControlName);
      this.formGroupDirective.control.addValidators(eqValidator);
      this.cleanupWithRepeatControl = () => {
        this.comparePasswordErrorMatcher = null;
        this.formGroupDirective.control.removeValidators(eqValidator);
        this.formGroupDirective.control.removeControl(PASSWORD_REPEAT_VIRTUAL_CONTROL_NAME);
        this.cleanupWithRepeatControl = null;
      };
    } else if (this.cleanupWithRepeatControl) {
      this.cleanupWithRepeatControl();
    }
    this.withRepeatValue = newValue;
  }

  @Input() labels: PasswordFieldLabels;

  onChange = (value: boolean) => { };
  onTouched = () => { };

  get passwordControl() {
    return this.formGroupDirective.control.get(this.forFormControlName);
  }

  get passwordRepeatControl() {
    return this.formGroupDirective.control.get(this.PASSWORD_REPEAT_VIRTUAL_CONTROL_NAME);
  }

  get passwordValue() {
    return this.passwordControl.value;
  }

  get passwordRepeatValue() {
    return this.passwordRepeatControl.value;
  }

  ngOnInit() {
    if (!this.forFormControlName) {
      throw new Error('PasswordFieldComponent input: forFormControlName must be defined statically!');
    }
  }

  writeValue(value: string): void {
    throw new Error('WriteValue is not yet supported by PasswordFieldWithRepeatComponent!');
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void { }

  showGenericPasswordError() {
    const control = this.passwordControl;
    return !this.showMinLengthPasswordError() && isErrorVisible(control);
  }

  showMinLengthPasswordError() {
    const control = this.passwordControl;
    return isErrorVisible(control, 'minlength');
  }

}
