import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { LinkOpener } from '@portal/wen-components';
import { EmbeddedLinkDTO } from '@portal/wen-backend-api';
import { isNullOrUndefined } from '../../../../../../core/common/operators/null-check-util';

@Component({
  selector: 'wen-embed-link',
  templateUrl: './embed-link.component.html',
  styleUrls: ['./embed-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedLinkComponent implements OnInit {

  linkViewModel: EmbeddedLinkDTO;
  linkOpenerTranslationKey: string;

  @HostBinding('class.wen-embedded-link') className = true;

  @Input() set model(value: EmbeddedLinkDTO) {
    this.linkViewModel = value;
    if (!isNullOrUndefined(value)) {
      this.linkOpenerTranslationKey = this.linkOpener.evaluateOpenerLabel(value.url);
    }
  }

  constructor(
    private linkOpener: LinkOpener
  ) { }

  ngOnInit(): void {
  }

  openUrl() {
    const { url } = this.linkViewModel;
    this.linkOpener.openUrl(url);
  }

}
