import { NestedUserDTO } from '../user/nested-user-object';

/**
 * Enum representing the contact connection between 2 users:
 * Sender -> Receiver
 *
 * The enum values represent the connection status from the current user who is logged in
 */
export enum ContactRequestStatus {
  /**
   * The 2 users have a verified connection
   */
  ACCEPTED = 'ACCEPTED',
  /**
   * The other user denied your connection request
   */
  DENIED = 'DENIED',
  /**
   * The other preson requested a contact connection with the current user
   */
  REQUESTER = 'REQUESTER',
  /**
   * The current user requested a contact connection with the other user
   */
  RECIPIENT = 'RECIPIENT',
  /**
   * The other user requested a contact connection with the currently logged in user but withdraw it
   */
  WITHDRAW = 'WITHDRAW',
  /**
   * The current user had a contact connection with the other user, but someone deleted it
   */
  DELETED = 'DELETED',
}

/**
 * Object representing a minimum required user object for the client to handle contacts
 */
export interface ContactBaseDTO {
  user: NestedUserDTO;
}

/**
 * Object representing a user object where the connection status is known for the client
 */
export interface ContactDTO<S extends ContactRequestStatus = ContactRequestStatus> extends ContactBaseDTO {
  requestStatus: S;
}

/**
 * Object representing a user object where the connection is not yet verified
 */
export type PendingContactDTO = ContactDTO<Exclude<ContactRequestStatus, ContactRequestStatus.ACCEPTED>>;

export type ContactRequestCreatePayload = {
  contactUserId: string;
};

export type ContactRequestPayload = {
  contactUserId: string;
};

export type ContactOpenRequestCountResponse = {
  count: number;
};

/**
 * Check if the connection status is a verified connection status which is not pending
 *  If no value is provided the default value is that the connection doesn't exist
 */
export const isConnectedStatus = (status: ContactRequestStatus) => {
  if (!status) {
    return false;
  }
  return status === ContactRequestStatus.ACCEPTED;
};

/**
 * Check if the connection status is pending
 *  If no value is provided the default value is that the connection is not pending
 */
export const isPendingStatus = (status: ContactRequestStatus) => {
  if (!status) {
    return false;
  }
  const pendingStatuses = [ContactRequestStatus.RECIPIENT, ContactRequestStatus.REQUESTER];
  return pendingStatuses.includes(status);
};

export const isContactLikeWithRequestStatus = (contactDTO: Partial<ContactDTO>): contactDTO is ContactDTO => {
  return Boolean(contactDTO.user && contactDTO.requestStatus);
};
