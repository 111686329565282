import { LocationStrategy } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, UrlHandlingStrategy } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { RoutesProvider, WenPathLocationStrategy } from '@portal/wen-components';
import { CrmIntegrationGuard } from '../../core/guards/crm-integration-guard';
import { NativeNotificationHandler } from '../../core/services/native-notification/native-notification-handler';
import { AppInitGuard } from '../../core/services/navigation/app-init-guard';
import { AppNavigator } from '../../core/services/navigation/app-navigator';
import { BackNavigationBehaviorsModule } from '../../core/services/navigation/back-navigation/back-navigation-behaviors/back-navigation-behaviors.module';
import { DeepLinkModule } from '../../core/services/navigation/deep-link/deep-link.module';
import { createNavigationHelper } from '../../core/services/navigation/navigation-helper-proxy';
import { MultiOutletNavigator } from '../../core/services/navigation/outlet-specific/multi-outlet-navigator';
import { SingleOutletNavigator } from '../../core/services/navigation/outlet-specific/single-outlet-navigator';
import { WenRouteUrlBuilder } from '../../core/services/navigation/outlet-specific/wen-route-url-builder';
import { RouteConfigInitializer } from '../../core/services/navigation/route-config-initializer';
import { WenRouteReuseStrategy } from '../../core/services/navigation/route-reuse-strategy/wen-route-reuse-strategy';
import { WenNavigationHelper } from '../../core/services/navigation/types';
import { AuthGuard } from '../../core/services/user-management/auth-guard';
import { OnboardingAnonymousAutoLoginGuard } from '../../core/services/user-management/onboarding-anonymous-auto-login.guard';
import { OnboardingAuthGuard } from '../../core/services/user-management/onboarding-auth.guard';
import { RegistrationGuard } from '../../core/services/user-management/registration-guard';
import { NavigationEffects } from '../../core/store/navigation/navigation.effects';
import { AppsModule } from '../../views/apps/apps.module';
import { ChannelModule } from '../../views/channel/channel.module';
import { ChatModule } from '../../views/chat/chat.module';
import { ComingSoonModule } from '../../views/coming-soon-page/coming-soon.module';
import { ContactsModule } from '../../views/contacts/contacts.module';
import { CrmViewModule } from '../../views/crm/crm-view.module';
import { EmbeddedPageModule } from '../../views/embedded-page/embedded-page.module';
import { EventsModule } from '../../views/events/events.module';
import { ExclusiveInviteModule } from '../../views/exclusive-invite/exclusive-invite.module';
import { HomeModule } from '../../views/home/home.module';
import { InviteViewModule } from '../../views/invite/invite-view/invite-view.module';
import { InviteModule } from '../../views/invite/invite.module';
import { OnboardingModule } from '../../views/onboarding/onboarding.module';
import { ReaderModule } from '../../views/reader/reader.module';
import { RegistrationModule } from '../../views/registration/registration.module';
import { SignInModule } from '../../views/sign-in/sign-in.module';
import { UserProfileModule } from '../../views/user-profile/user-profile.module';
import { routerInitializer } from './router-initializer';
import { WenRoutesProvider } from './routes/routes.provider';
import { WEN_ROUTING_CONFIG, WenRoutingConfig } from './tokens';
import { DynamicOuletUrlHandlingStrategy } from '../../core/services/navigation/outlet-specific/dynamic-outlet-url-handling-strategy';

@NgModule({
  imports: [
    HomeModule,
    AppsModule,
    ChannelModule,
    ComingSoonModule,
    EmbeddedPageModule,
    UserProfileModule,
    RegistrationModule,
    OnboardingModule,
    SignInModule,
    ExclusiveInviteModule,
    EventsModule,
    ChatModule,
    InviteViewModule,
    InviteModule,
    CrmViewModule,
    ReaderModule,
    ContactsModule,
    RouterModule.forRoot([]),
    EffectsModule.forFeature([NavigationEffects]),
    DeepLinkModule,
    BackNavigationBehaviorsModule,
  ],
  providers: [
    AuthGuard,
    AppInitGuard,
    RegistrationGuard,
    OnboardingAnonymousAutoLoginGuard,
    OnboardingAuthGuard,
    CrmIntegrationGuard,
    SingleOutletNavigator,
    MultiOutletNavigator,
    RouteConfigInitializer,
    NativeNotificationHandler,
    WenRouteUrlBuilder,
    WenRoutesProvider,
    AppNavigator,
    {
      provide: RoutesProvider,
      useClass: WenRoutesProvider
    },
    {
      provide: LocationStrategy,
      useClass: WenPathLocationStrategy
    },
    {
      provide: UrlHandlingStrategy,
      useClass: DynamicOuletUrlHandlingStrategy
    },
    {
      provide: WenNavigationHelper,
      useFactory: createNavigationHelper,
      deps: [RouteConfigInitializer]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: routerInitializer,
      deps: [RouteConfigInitializer],
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: WenRouteReuseStrategy,
    },
  ],
  exports: [
    RouterModule
  ]
})
export class WenRoutingModule {

  static forRoot(config?: WenRoutingConfig): ModuleWithProviders<WenRoutingModule> {
    return {
      ngModule: WenRoutingModule,
      providers: [
        { provide: WEN_ROUTING_CONFIG, useValue: config }
      ]
    };
  }

}
