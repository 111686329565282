import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { PortalStickyModule } from '@portal/ui-kit/sticky';
import { SharedModule } from '../../../shared/shared.module';
import { UserProfileViewComponent } from './components/user-profile-view/user-profile-view.component';
import { HelpViewComponent } from './components/user-profile-view/sub-views/help-view/help-view.component';
import { AutoReplyTextModule } from '../auto-reply-text/auto-reply-text.module';
import { HotlineViewComponent } from './components/user-profile-view/sub-views/hotline-view/hotline-view.component';
import { SettingViewComponent } from './components/user-profile-view/sub-views/setting-view/setting-view.component';

@NgModule({
  imports: [
    SharedModule,
    PortalStickyModule,
    ScrollingModule,
    AutoReplyTextModule,
  ],
  declarations: [
    UserProfileViewComponent,
    HelpViewComponent,
    HotlineViewComponent,
    SettingViewComponent,
  ],
  exports: [
    UserProfileViewComponent,
  ],
})
export class UserProfileViewModule { }
