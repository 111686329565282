import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogModule } from '@portal/wen-components';
import { AgbDataProtectionDialogComponent } from './agb-data-protection-dialog/agb-data-protection-dialog.component';
import { DataProtectionDialogOpener } from './data-protection-dialog-opener';
import { SimpleDataProtectionDialogComponent } from './simple-data-protection-dialog/simple-data-protection-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    ConfirmationDialogModule,
  ],
  declarations: [
    SimpleDataProtectionDialogComponent,
    AgbDataProtectionDialogComponent,
  ],
  providers: [
    DataProtectionDialogOpener
  ],
  exports: [
    SimpleDataProtectionDialogComponent,
    AgbDataProtectionDialogComponent,
  ]
})
export class DataProtectionDialogModule { }
