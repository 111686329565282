import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WenBreakpointObserver } from '@portal/wen-components';
import { Observable, Subject, takeUntil } from 'rxjs';
import { distinct, filter, map, shareReplay } from 'rxjs/operators';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { YellowMapMapService } from '../../../../../core/services/yellowmap/yellowmap-map-service';
import { EventEntity } from '../../../../../core/store/events/event.state';
import { DetailHeaderModel } from '../../../../../shared/components/detail-header/detail-header.component';
import { toGermanString } from '../../util/rruleToGermanText';
import { EventDetailDataSource } from './providers/event-detail-data-source';
import { EventDeepLinkable } from '../../../../../core/services/navigation/deep-link/deep-linkables/event-deep-linkable';
import { ForwardContext } from '@portal/wen-backend-api';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';

@Component({
  selector: 'wen-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
  providers: [EventDetailDataSource],
})
export class EventDetailComponent implements OnInit, OnDestroy {

  locationUrl$: Observable<string>;
  currentEvent$: Observable<EventEntity>;
  headerData$: Observable<DetailHeaderModel> = this.eventDetailDataSource.headerData$;
  isMobileView = !this.breakPointObserver.isDesktopStyleDevice;
  private onDestroy$ = new Subject<void>();

  currentEventSubscription$ = this.eventDetailDataSource.eventSubscription$;
  canForward$: Observable<boolean>;
  rruleText: string;
  forwardContext = ForwardContext.EVENT;

  @ViewChild('iframe') set iframeEl(iframe: ElementRef<HTMLIFrameElement>) {
    this.locationUrl$.pipe(
      filter(() => !!iframe?.nativeElement),
      takeUntil(this.onDestroy$),
    ).subscribe(url => iframe.nativeElement.contentWindow.location.replace(url));
  }

  get eventDeepLink(): string {
    return this.deepLinkProvider.getDeepLink();
  }

  constructor(
    private eventDetailDataSource: EventDetailDataSource,
    private navigationHelper: WenNavigationHelper,
    private yellowMapMapService: YellowMapMapService,
    private breakPointObserver: WenBreakpointObserver,
    private deepLinkProvider: EventDeepLinkable,
    private featureEnablementService: FeatureEnablementService
  ) {
  }

  ngOnInit() {
    this.currentEvent$ = this.eventDetailDataSource.eventDetail$.pipe(
      distinct(),
      map((event: EventEntity) => {
        if (!event) {
          return undefined;
        }
        const formattedContact = this.formatOrganizer(event);

        if (event.rrule) {
          this.rruleText = toGermanString(event.rrule);
        } else {
          this.rruleText = null;
        }

        return {
          ...event,
          organizerContact: formattedContact
        };
      }),
      takeUntil(this.onDestroy$)
    );

    this.locationUrl$ = this.currentEvent$.pipe(
      map((event: EventEntity) => {
        const mapUrl = this.yellowMapMapService.getMapUrlFromEvent(event);
        if (!mapUrl) {
          return null;
        }
        return mapUrl;
      }),
      shareReplay(1)
    );

    this.canForward$ = this.featureEnablementService.appConfigObs.enableChat$.pipe(
      map((isChatEnabled) => isChatEnabled && !!this.eventDeepLink)
    );
  }

  private formatOrganizer(event: EventEntity) {
    let organizerContact = event?.organizerContact;
    if (organizerContact?.url && !organizerContact?.url.startsWith('http')) {
      organizerContact = { ...organizerContact, url: `https://${organizerContact?.url}` };
    }

    try {
      organizerContact = { ...organizerContact, url: new URL(organizerContact?.url).href };
    } catch (e) {
      if (!e) {
        organizerContact = { ...organizerContact, url: '' };
      }
    }

    return organizerContact;
  }

  openUrl(url: string) {
    this.navigationHelper.navigateToExternalUrl(url);
  }

  mailTo(email: string) {
    this.navigationHelper.navigateToSendEmailTo(email);
  }

  callPhoneNumber(phoneNumber: string) {
    this.navigationHelper.navigateToCallPhoneNumber(phoneNumber);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
