import { createFeature, createReducer, on } from '@ngrx/store';
import { networkFeatureKey } from './constants';
import { setActiveNetwork, upsertKnownNetworks } from './network.actions';
import { NetworkState, networkEntityAdapter } from './network.state';

export const networkInitialState: NetworkState = {
  activeNetworkId: null,
  knownNetworks: networkEntityAdapter.getInitialState()
};

export const networkFeature = createFeature({
  name: networkFeatureKey,
  reducer: createReducer(
    networkInitialState,
    on(upsertKnownNetworks, (state, { networks }) => {
      const newKnownNetworks = networkEntityAdapter.upsertMany(networks, state.knownNetworks);
      return {
        ...state,
        knownNetworks: newKnownNetworks
      };
    }),
    on(setActiveNetwork, (state, { networkId }) => {
      return {
        ...state,
        activeNetworkId: networkId
      };
    }),
  ),
});
