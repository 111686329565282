import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { RoomType } from '@portal/wen-backend-api';
import { WenBreakpointObserver } from '@portal/wen-components';
import { combineLatest } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { WenRouteId } from '../../../frame/routing/types';
import { FormStoreMediator } from '../../../shared/form-store/form-store-mediator';
import { CustomFormStoreKey, FormStoreKey } from '../../../shared/form-store/form-store-types';
import { ForbiddenForAnonymousUserDialogService } from '../../../shared/services/forbidden-for-anonymous-user-dialog.service';
import { MissingPermissionDialogService } from '../../../shared/services/missing-permission-dialog.service';
import { userProfileIdentifier } from '../../../views/user-profile/tokens';
import { CrmChatMessageSender } from '../../services/chat/message-sender/crm-chat-message-sender';
import { AppNavigator } from '../../services/navigation/app-navigator';
import { BackNavigationHelper } from '../../services/navigation/back-navigation/back-navigation-helper';
import { MultiOutletNavigator } from '../../services/navigation/outlet-specific/multi-outlet-navigator';
import { WenDesktopRouteDataResolver } from '../../services/navigation/route-data/desktop-route-data-resolver';
import { WenMobileRouteDataResolver } from '../../services/navigation/route-data/mobile-route-data-resolver';
import { WenNavigationHelper } from '../../services/navigation/types';
import { PermissionLevel } from '../../services/user-management/permission-level';
import { DataContextHelper } from '../../services/util/data-context-helper';
import { selectCurrentUserData } from '../auth/auth.selectors';
import { selectCurrentChannel } from '../channel/channel.selectors';
import { selectCurrentRoom } from '../chat/chat.selectors';
import { removeFilter } from '../filter/filter.actions';
import { RootState } from '../root/public-api';
import { selectOutletDatas, selectOutletIds } from '../root/root.selectors';
import { selectUserChannelPermission } from '../user/user.selectors';
import { doBackNavigation, forwardInCreateHeaderCommandClicked, headerCommandClicked, updateHeaderData, updateSearchbarActive } from './header.actions';
import { HeaderCommandType } from './models/HeaderCommand';

@Injectable()
export class HeaderEffects {

  setupHeaderData$ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    switchMap(() => combineLatest([
      this.store.pipe(select(selectOutletIds), first()),
    ])),
    map(([{ primaryId, sidebarId }]) => {
      const isDesktopDevice = this.breakpointObserver.isDesktopStyleDevice;
      if (isDesktopDevice) {
        return this.desktopRouteDataResolver.getDesktopRouteData(primaryId, sidebarId);
      } else {
        return this.mobileRouteDataResolver.getMobileRouteData(primaryId);
      }
    }),
    map((nextdata) => {
      return updateHeaderData({ headerData: nextdata?.headerData });
    })
  ));

  navigateBack$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked, doBackNavigation),
    filter((action) => {
      if (action.type === doBackNavigation().type) {
        return true;
      }
      const commandIds = [
        HeaderCommandType.BACK_NAVIGATION,
        HeaderCommandType.CANCEL_BACK_NAVIGATION,
      ];
      return commandIds.includes(action.commandType);
    }),
    switchMap(() => combineLatest([
      this.store.pipe(select(selectOutletIds)),
      this.store.pipe(select(selectOutletDatas))
    ]).pipe(first())),
    switchMap(([outletIds, data]) => {
      const topLevelOutletId = outletIds?.dialogId || outletIds?.primaryId;
      const sidebarOutletId = outletIds?.sidebarId;
      const mainData = data?.dialogData || data?.primaryData;
      return this.backNavigationHelper.handleBackNavigation(topLevelOutletId, sidebarOutletId, mainData);
    })
  ), { dispatch: false });

  closeDialog$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(action => {
      const isDesktopDevice = this.breakpointObserver.isDesktopStyleDevice;
      return action.commandType === HeaderCommandType.CLOSE_DIALOG && isDesktopDevice;
    }),
    tap(() => {
      this.multiOutletNavigator.closeDialog({ replaceUrl: true });
    })
  ), { dispatch: false });

  openChannelDetail$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.OPEN_CHANNEL_DETAIL),
    switchMap(() => this.store.pipe(
      select(selectCurrentChannel),
      first()
    )),
    tap((currentChannel) => {
      this.navigationHelper.navigateToChannelDetail(currentChannel.id);
    })
  ), { dispatch: false });

  openUserProfile$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.OPEN_USER_PROFILE || commandType === HeaderCommandType.BACK_TO_PROFILE),
    tap(() => {
      this.navigationHelper.navigateToUserProfile();
    })
  ), { dispatch: false });

  closeUserProfile$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.CLOSE_PROFILE_NAVIGATION),
    tap(() => {
      this.appNavigator.leaveStackAndNavigateBack();
    })
  ), { dispatch: false });

  channelAddWelcome$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.CHANNEL_ADD_WELCOME),
    switchMap(() =>
      combineLatest([
        this.store.select(selectUserChannelPermission).pipe(map(permission => permission.canCreate)),
        this.store.select(selectCurrentUserData).pipe(map(value => value.permissionLevel === PermissionLevel.ANONYMOUS))
      ]).pipe(
        map(([canCreate, isAnonym]) => ({ canCreate, isAnonym })),
        first()
      )
    ),
    tap(({ canCreate, isAnonym }) => {
      if (canCreate) {
        this.navigationHelper.navigateToChannelAddWelcome();
      } else if (isAnonym) {
        this.anonymousUserDialogService.openChannelCreationForbiddenWhenAnonymous().subscribe();
      } else {
        this.missingPermissionDialogService.openChannelCreationForbiddenWhenEmailNotVerified().subscribe();
      }
    })
  ), { dispatch: false });

  formForwardNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(forwardInCreateHeaderCommandClicked),
    switchMap(({ formId }) => {
      return this.formStoreMediator.detectFormChanges(formId, false).pipe(
        filter(status => status === 'VALID'),
        map(() => formId)
      );
    }),
    tap((outletId: FormStoreKey) => {
      switch (outletId) {
        case WenRouteId.ADD_CHANNEL_INFO:
          this.navigationHelper.navigateToAddChannelVisibility();
          return;
        case WenRouteId.ADD_CHANNEL_VISIBILITY:
          this.navigationHelper.navigateToAddChannelRestrictions();
          return;
        case WenRouteId.ADD_CHANNEL_RESTRICTIONS:
          this.navigationHelper.navigateToAddChannelInteractions();
          return;
        case WenRouteId.ADD_CHANNEL_INTERACTIONS:
          this.navigationHelper.navigateToChannelList();
          return;
        case WenRouteId.ADD_CHAT_USERS:
          this.navigationHelper.navigateToAddGroupChatInfo();
          return;
        case WenRouteId.INVITE_TO_CHANNEL_SELECTOR:
          this.navigationHelper.navigateToCrmInviteToChannel();
          return;
        case CustomFormStoreKey.USER_CHANGE_PASSWORD_FORM_KEY:
          this.appNavigator.navigateToRoute(WenRouteId.USERPROFILE_CHANGE_PASSWORD_VERIFY_CODE);
          return;
        default:
          console.warn(`Not supported route ${outletId}`);
          return;
      }
    }),
  ), { dispatch: false });

  appAddWelcome$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.APP_ADD_WELCOME),
    tap(() => {
      this.appNavigator.enterStackAndNavigate(WenRouteId.APP_ADD_WELCOME);
    })
  ), { dispatch: false });

  chatAddNew$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.CHAT_ADD_NEW),
    tap(() => {
      this.navigationHelper.navigateToDialogChatCreation();
    })
  ), { dispatch: false });

  resetSearchbarOnFilterContextChangeEffect$ = createEffect(() => this.dataContextHelper.onDataContextChanges().pipe(
    map(() => updateSearchbarActive({ active: false }))
  ));

  resetSearchOnSearchFilterReset$ = createEffect(() => this.actions$.pipe(
    ofType(removeFilter),
    map(({ }) => updateSearchbarActive({ active: false }))
  ));

  openRoomDetail$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.OPEN_ROOM_DETAIL),
    switchMap(() => this.store.pipe(
      select(selectCurrentRoom),
      first()
    )),
    filter(currentRoom => currentRoom.details.type === RoomType.DIALOG),
    switchMap(currentRoom => {
      return this.store.pipe(
        select(selectCurrentUserData),
        map((currentUser) => currentRoom.members.find(m => m.userId !== currentUser.userId).userId),
        first()
      );
    }),
    tap((userId) => {
      this.appNavigator.navigateToRoute(WenRouteId.OTHER_USERPROFILE_READ, { [userProfileIdentifier]: userId });
    })
  ), { dispatch: false });

  openRoomEdit$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.OPEN_ROOM_DETAIL),
    switchMap(() => this.store.pipe(
      select(selectCurrentRoom),
      first()
    )),
    filter(currentRoom => currentRoom.details.type === RoomType.GROUP),
    tap(({ id }) => {
      this.navigationHelper.navigateToChatEdit(id);
    })
  ), { dispatch: false });

  openGroupChatDetail$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.OPEN_ROOM_DETAIL),
    switchMap(() => this.store.pipe(
      select(selectCurrentRoom),
      first()
    )),
    filter(currentRoom => currentRoom.details.type === RoomType.GROUP),
    tap((currentRoom) => {
      this.navigationHelper.navigateToGroupChatDetail(currentRoom.id);
    })
  ), { dispatch: false });

  openCrmProfileReadOnlyView$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.CRM_PROFILE_READ_ONLY_VIEW),
    tap(() => this.navigationHelper.navigateToCrmProfileReadOnlyView())
  ), { dispatch: false });

  pauseCrmMassChatSending$ = createEffect(() => this.actions$.pipe(
    ofType(headerCommandClicked),
    filter(({ commandType }) => commandType === HeaderCommandType.CRM_PAUSE_MASS_CHAT_SENDING),
    tap(() => this.crmChatMessageSender.pauseMessageSending())
  ), { dispatch: false });

  constructor(
    private readonly actions$: Actions,
    private readonly backNavigationHelper: BackNavigationHelper,
    private readonly store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly appNavigator: AppNavigator,
    private readonly multiOutletNavigator: MultiOutletNavigator,
    private readonly formStoreMediator: FormStoreMediator,
    private readonly mobileRouteDataResolver: WenMobileRouteDataResolver,
    private readonly desktopRouteDataResolver: WenDesktopRouteDataResolver,
    private readonly breakpointObserver: WenBreakpointObserver,
    private readonly dataContextHelper: DataContextHelper,
    private readonly anonymousUserDialogService: ForbiddenForAnonymousUserDialogService,
    private readonly missingPermissionDialogService: MissingPermissionDialogService,
    private readonly crmChatMessageSender: CrmChatMessageSender
  ) { }

}
