import { Injectable } from '@angular/core';
import { ChatMessageComposerData, EmbedDTOType, EmbeddedForwardHandler, EmbeddedMediaHandler, RoomMessageDTO, SendMessageContent } from '@portal/wen-backend-api';

export class ChatMessageTransactionPreviewGenerator {

  constructor(
    private embedMediaHandler: EmbeddedMediaHandler,
    private embedForwardHandler: EmbeddedForwardHandler
  ) { }

  createPreview(data: ChatMessageComposerData): ChatMessageComposerData {
    const { roomMessage } = data;
    const { embeds } = roomMessage.message;
    const previewEmbeds = embeds.map((embed) => {
      switch (embed.type) {
        case EmbedDTOType.MEDIA:
          const mediaEmbedPreview = this.embedMediaHandler.prepareForPreview(embed);
          return mediaEmbedPreview;
        case EmbedDTOType.FORWARD:
          const forwardPreview = this.embedForwardHandler.prepareForPreview(embed);
          return forwardPreview;
        default:
          return embed;
      }
    });
    const message: RoomMessageDTO = {
      ...roomMessage.message,
      embeds: previewEmbeds
    };
    const content: SendMessageContent<false> = {
      message
    };
    return {
      ...data,
      roomMessage: content
    };
  }

}

@Injectable()
export class MessagePreviewGeneratorFactory {

  create(
    embedMediaHandler: EmbeddedMediaHandler,
    embedForwardHandler: EmbeddedForwardHandler
  ) {
    return new ChatMessageTransactionPreviewGenerator(embedMediaHandler, embedForwardHandler);
  }
}
