import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RoomType } from '@portal/wen-backend-api';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { selectCurrentRoom } from '../../core/store/chat/chat.selectors';
import { upsertHeaderData } from '../../core/store/header/header.actions';
import { HeaderData } from '../../core/store/header/models/HeaderData';
import { RootState } from '../../core/store/root/public-api';

@Injectable()
export class ChatHeaderDataUpdater implements OnDestroy {

  private onDestroy$ = new Subject<void>();

  public readonly headerData$ = this.store.pipe(
    select(selectCurrentRoom),
    filter((currentRoom) => Boolean(currentRoom?.details)),
    map((currentRoom) => {
      const title = currentRoom.details.title;
      const subTitle = currentRoom.details?.type === RoomType.GROUP ?
        `${currentRoom.members.length} ${this.translateService.instant('GROUP_CHAT_MEMBERS')}`
        : null;
      const titleIcon = currentRoom.details.isMuted ? 'mute' : null;
      const headerData: HeaderData = {
        title,
        ...subTitle && { subTitle },
        titleIcon
      };
      return headerData;
    })
  );

  constructor(
    private store: Store<RootState>,
    private translateService: TranslateService
  ) { }

  initialize() {
    this.headerData$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((headerData) => {
      this.store.dispatch(upsertHeaderData({ headerData }));
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
