import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WenSnackbarOpener } from '@portal/wen-components';
import { setMuteForRoom } from '../../../core/store/chat/chat.actions';
import { RootState } from '../../../core/store/root/public-api';

@Injectable()
export class ChatMuteService {

  constructor(
    private readonly store: Store<RootState>,
    private readonly wenSnackbarOpener: WenSnackbarOpener,
    private readonly translateService: TranslateService
  ) { }

  public setMuteForCurrentChat(room: string, mute: boolean, withNotification: boolean = false) {
    this.store.dispatch(setMuteForRoom({ mute, room }));
    if (withNotification) {
      const notificationText = this.translateService.instant(mute ? 'MUTE_BUTTON_LABEL' : 'UNMUTE_BUTTON_LABEL');
      const notificationIcon = mute ? 'mute' : 'unmute';
      this.wenSnackbarOpener.openNotificationSnackbar({ notificationIcon, notificationText });
    }
  }
}
