import { Injectable } from '@angular/core';
import { LinkOpenerResult } from '@portal/react-native-api';
import { Subject, Subscription, first, from } from 'rxjs';
import { NativeConfigurationService } from '../configuration/native-configuration';
import { WenNavigationHelper } from '../navigation/types';

@Injectable()
export class OauthUrlOpener {

  private activeBrowserDialogSub: Subscription;
  private nativeBrowserEvents = new Subject<LinkOpenerResult>();
  public readonly nativeBrowserEvents$ = this.nativeBrowserEvents.asObservable();

  constructor(
    private navigationHelper: WenNavigationHelper,
    private nativeConfiguration: NativeConfigurationService,
  ) { }

  openUrl(url: string) {
    if (this.activeBrowserDialogSub) {
      this.activeBrowserDialogSub.unsubscribe();
      this.activeBrowserDialogSub = null;
    }
    if (this.nativeConfiguration.isSSoDialogEnabled()) {
      const result$ = from(this.navigationHelper.navigateToUrlWithFallback(url));
      if (result$) {
        this.activeBrowserDialogSub = result$.pipe(
          first(),
        ).subscribe((result) => {
          this.nativeBrowserEvents.next(result);
        });
      }
    } else {
      location.href = url;
    }
  }

}
