import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, filter, first, map, of, throwError } from 'rxjs';
import { SocketIoService } from '@portal/wen-backend-api';
import { WenNativeApi } from '@portal/wen-native-api';
import { EXCLUSIVE_INVITE_CONFIG, ExclusiveInviteConfig } from '../../../frame/api/tokens';
import { WenSnackbarOpener } from '@portal/wen-components';
import { NativeInviteHandler } from './types';

@Injectable()
export class NativeInviteHandlerWithCode extends NativeInviteHandler {

  constructor(
    protected readonly nativeApi: WenNativeApi,
    protected readonly translateService: TranslateService,
    @Inject(EXCLUSIVE_INVITE_CONFIG) protected readonly inviteConfig: ExclusiveInviteConfig,
    protected readonly socketIOService: SocketIoService,
    protected readonly snackBarOpener: WenSnackbarOpener,
  ) {
    super(nativeApi);
  }

  initInviteFlow() {
    this.socketIOService.user.requestInvitationCode.acknowledgement$().pipe(
      map(response => {
        if (!Boolean(response.code) || !Boolean(this.inviteConfig.inviteUrl)) {
          return throwError(() => 'Invite url or code not set');
        }
        return response.code;
      }),
      catchError(() => {
        this.snackBarOpener.openNotificationSnackbar({
          notificationIcon: 'error_robot',
          notificationText: this.translateService.instant('ERROR_GENERIC_DIALOG_MESSAGE')
        });
        return of(null);
      }),
      first(),
      filter(inviteCode => Boolean(inviteCode))
    ).subscribe(inviteCode => {
      const emailSubject = this.translateService.instant('INVITE_NATIVE_SHARE_EMAIL_SUBJECT');
      const emailContent = this.translateService.instant('INVITE_NATIVE_SHARE_MESSAGE_WITH_CODE', {
        inviteUrl: this.inviteConfig.inviteUrl,
        inviteCode
      });
      this.initNativeEmailShare(emailSubject, emailContent);
    });
  }
}
