import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { animationFrameScheduler, Observable, Subject, timer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { delay, first, observeOn, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[wenFocusFirstInput]'
})
export class FocusFirstInputDirective implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private immediateFocus$ = timer(100).pipe(first());
  private customTrigger$: Observable<void>;

  @Input() set focusTrigger(obs: AnonymousSubject<void>) {
    this.customTrigger$ = obs.pipe(
      delay(100),
      observeOn(animationFrameScheduler),
      takeUntil(this.onDestroy$)
    );
  }

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngAfterViewInit() {
    const element: HTMLElement = this.elementRef.nativeElement;
    const firstInput = element.querySelector('input, textarea') as HTMLElement;
    if (firstInput) {
      const targetObservable: Observable<void | 0> = this.customTrigger$ ?? this.immediateFocus$;
      targetObservable.subscribe(() => firstInput.focus());
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
