import { Injectable } from '@angular/core';
import { GWDataObject } from './providers/gw/gw-data-object';
import { OpenDataObject } from './providers/open/open-data-object';
import { SelectionProvider } from './providers/selection-provider';
import { SmartDesignApi } from './smartdesing-api';

@Injectable()
export class SelectionDataImporter {

  private inviteSelectionProvider: SelectionProvider<OpenDataObject | GWDataObject>;

  constructor(
    private wenSdApi: SmartDesignApi,
  ) {
  }

  async preloadInviteData() {
    if (!this.inviteSelectionProvider) {
      this.inviteSelectionProvider = this.wenSdApi.createSelectionProvider();
    }
    return this.inviteSelectionProvider.loadSelection();
  }

}
