import { ChannelDTO } from './channel';

export enum ChannelInviteState {
  DENIED = 'denied_unacked',
  REQUESTED = 'requested',
  INVITED = 'invited'
}

const joinRequestStateValues = [ChannelInviteState.DENIED, ChannelInviteState.REQUESTED];
const invitedToChannelStateValues = [ChannelInviteState.INVITED];

export interface WithChannelInviteState {
  state: ChannelInviteState;
}

export interface InvitedToChannelDTO extends WithChannelInviteState {
  id: string;
  state: ChannelInviteState.INVITED;
  insertUserId: string;
  insertUserName: string;
  notesUser: string;
  notesAdmin: string;
  updateTimestamp: string;
}

export interface ChannelJoinRequestDTO extends WithChannelInviteState {
  id: string;
  state: ChannelInviteState.DENIED | ChannelInviteState.REQUESTED;
  subjectUserName: string;
  subjectUserId: string;
  updateTimestamp: string;
  contextId: string;
  subjectAvatarUrl: string;
}

export type ChannelInviteDataDTO = InvitedToChannelDTO | ChannelJoinRequestDTO;

export const isJoinRequestChannelState = (data: WithChannelInviteState): data is InvitedToChannelDTO => {
  return joinRequestStateValues.includes(data?.state);
};

export const isInviteToChannelState = (data: WithChannelInviteState): data is InvitedToChannelDTO => {
  return invitedToChannelStateValues.includes(data?.state);
};

export type ChannelInviteResponse = Array<Pick<ChannelDTO, 'id'> & Pick<ChannelDTO, 'invite'> & Partial<ChannelDTO>>;
export type ChannelJoinRequestPayload = { userId: string; channelId: string };
export type ChannelJoinRequestDecisionPayload = { userId: string; inviteId: string };
export type JoinRequestListResponse = ChannelJoinRequestDTO[];
export type WithdrawJoinRequestResponse = { channelId: string };
export type AcceptJoinRequestResponse = { channelId: string };
export type AcknowledgeJoinRequestDenialResponse = { channelId: string };
export type ChannelInviteToChannelAcceptPayload = { userId: string; inviteId: string };
export type ChannelInviteToChannelAcceptResponse = { channelId: string };
export type ChannelInviteToChannelDenyPayload = { userId: string; inviteId: string };
export type ChannelInviteToChannelDenyResponse = { channelId: string };
