import { VgApiService, VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { AfterContentInit, Component, OnDestroy, Optional, Input } from '@angular/core';
import { Observable, Subject, interval, map, takeUntil } from 'rxjs';
import { OverlayVisibilityService } from '../../lightbox-dialog/providers/overlay-visibility.service';
import { MuteComponent } from '../custom-controls/mute/mute.component';
import { VideoHideDelayService } from '../providers/video-hide-delay.service';

@Component({
  selector: 'wen-video-controls',
  templateUrl: './video-controls.component.html',
  styleUrls: ['./video-controls.component.scss'],
  standalone: true,
  imports: [
    VgCoreModule,
    VgControlsModule,
    MuteComponent
  ],
  providers: [
    VideoHideDelayService
  ]
})
export class VideoControlsComponent implements AfterContentInit, OnDestroy {

  @Input() videoRef: HTMLVideoElement;

  private scrubPinUp$ = new Subject<void>();
  private onDestroy$ = new Subject<void>();

  private pinHoldEmitter$ = new Observable<void>();

  constructor(
    private videoHideDelayService: VideoHideDelayService,
    @Optional() private vgApiService: VgApiService,
    @Optional() private overlayVisibilityService: OverlayVisibilityService
  ) { }

  ngAfterContentInit(): void {
    this.vgApiService.getDefaultMedia().subscriptions.seeking.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.overlayVisibilityService?.childEvent$?.next(this.videoHideDelayService.getHideDelay());
    });
  }

  onPointerDown(event: Event) {
    event.stopPropagation();
    this.pinHoldEmitter$ = interval(1000).pipe(
      map(() => { return; }),
      takeUntil(this.scrubPinUp$)
    );
    this.pinHoldEmitter$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.overlayVisibilityService?.childEvent$?.next(this.videoHideDelayService.getHideDelay());
    });
  }

  onPointerUp(event: Event) {
    event.stopPropagation();
    this.scrubPinUp$.next();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
