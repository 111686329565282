import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';

export abstract class ListMassDataViewerDatasource<T> extends DataSource<T> {

  abstract dataStream$: Observable<T[]>;
  abstract isLoading(): Observable<boolean>;
  abstract isEmpty(): Observable<boolean>;
  protected abstract onConnect(): void;
  protected abstract onDisconnect(): void;
  abstract fetchNextPage(): void;
  abstract selectItemId(model: T, index: number): string;

  connect(collectionViewer: CollectionViewer): Observable<readonly T[]> {
    this.onConnect();
    return this.dataStream$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.onDisconnect();
  }

}
