import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions, BMEnterFrameEvent } from 'ngx-lottie';
import { AppConfigurationProvider } from '../../../../../core/services/configuration/app-confguration';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { WenStorageService } from '../../../../../core/services/storage/wen-storage.service';
import { fadeInOutAnimation } from '@portal/wen-components';

@Component({
  selector: 'wen-onboarding-start',
  templateUrl: './onboarding-start.component.html',
  styleUrls: ['./onboarding-start.component.scss'],
  animations: [
    trigger('controlsEnter', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('120ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('120ms', style({ opacity: 0 })),
      ])
    ]),
    fadeInOutAnimation('400ms 400ms', 'ease-in', '400ms')
  ]
})
export class OnboardingStartComponent implements OnInit {

  private animationSize = {
    width: 550,
    height: 600
  };
  private animationItem: AnimationItem;
  readonly options: AnimationOptions = {
    path: this.config.onboardingConfig.lottieAnimationUrl,
    autoplay: false,
    loop: false,
    rendererSettings: {
      viewBoxSize: `0 0 620 680`,
      viewBoxOnly: true,
      preserveAspectRatio: 'xMidYMid meet'
    }
  };
  readonly styles: Partial<CSSStyleDeclaration> = {
    width: `${this.animationSize.width}px`,
    height: `${this.animationSize.height}px`,
    marginTop: '-180px'
  };

  public currentSegment = 0;
  readonly segments = [
    0, 594, 682, 811, 902
  ];

  public controlsVisible = false;

  public get isLastSegment() {
    return this.currentSegment > 3;
  }

  public get canGoBack() {
    return this.currentSegment > 1;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly ngZone: NgZone,
    private readonly wenNavigationHelper: WenNavigationHelper,
    private readonly storageService: WenStorageService,
    private readonly config: AppConfigurationProvider,
    private readonly translateService: TranslateService,
  ) { }

  ngOnInit() {
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    this.animationItem.setSpeed(1.5);
  }

  domLoaded() {
    this.playSegment(1);
  }

  enterFrame(event: BMEnterFrameEvent) {
    const currentFrame = this.animationItem.firstFrame + event.currentTime;
    const canShowControls = currentFrame > 540 && !this.controlsVisible;
    if (canShowControls) {
      this.controlsVisible = true;
      this.cdr.detectChanges();
    }
  }

  playSegment(increment: number) {
    if (!this.animationItem.isPaused) {
      return;
    }
    const nextSegment = this.currentSegment + increment;
    if (nextSegment < 1) {
      return;
    }
    if (nextSegment >= this.segments.length) {
      this.goToWenAuthPage();
      return;
    }
    this.animationItem.playSegments([this.segments[this.currentSegment], this.segments[nextSegment]], true);
    this.currentSegment = nextSegment;
    this.cdr.detectChanges();
  }

  goToWenAuthPage() {
    this.ngZone.run(() => {
      this.storageService.setOnboardingDone();
      this.wenNavigationHelper.navigateToSignIn();
    });
  }

  goToPrivacyPolicyPage() {
    this.wenNavigationHelper.navigateToEmbeddedPage(this.translateService.instant('PRIVACY_POLICY_URL'));
  }

  onSwipeLeft() {
    if (this.isLastSegment) {
      this.goToWenAuthPage();
    } else {
      this.playSegment(1);
    }
  }

  onSwipeRight() {
    if (this.canGoBack) {
      this.playSegment(-1);
    }
  }

}
