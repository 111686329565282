import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PortalFieldModule } from '@portal/ui-kit/field';
import { WenIconModule } from '@portal/wen-components';
import { LocationSelectorModule } from '../../../shared/components/location-selector/location-selector.module';
import { FormControlledHeaderModule } from '../../../shared/directives/form-controlled-header/form-controlled-header.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelPostEditorComponent } from './components/channel-post-editor/channel-post-editor.component';
import { ChannelPostLocationSelectorComponent } from './components/channel-post-location-selector/channel-post-location-selector.component';
import { ChannelPostEditorDataResolver } from './guards/channel-post-editor-edit.resolver';

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    PortalFieldModule,
    MatInputModule,
    WenIconModule,
    LocationSelectorModule,
    FormControlledHeaderModule,
  ],
  providers: [
    ChannelPostEditorDataResolver,
  ],
  declarations: [
    ChannelPostEditorComponent,
    ChannelPostLocationSelectorComponent,
  ]
})
export class ChannelPostEditorModule { }
