import { createAction, props } from '@ngrx/store';
import { FeaturesConfig } from '@portal/wen-backend-api';
import { DataContext } from '../../common/types/data-context';
import { ChannelMessageOriginTransferData, ProgressState, ScrollStateEntity } from './ui.state';

export const upsertScrollState = createAction(
  '[Ui] Upsert a scroll state for a given context',
  props<{ newState: ScrollStateEntity }>()
);

export const resetScrollState = createAction(
  '[Ui] Reset a scroll state for all entities in given data-context',
  props<{ scrollDataContext: DataContext }>()
);

export const upsertChannelMessageOriginTransferData = createAction(
  '[Ui] Store context related information',
  props<{ newState: ChannelMessageOriginTransferData }>()
);

export const resetChannelMessageOriginTransferData = createAction(
  '[Ui] Reset context related information'
);

export const updateProgressState = createAction(
  '[Ui] Update the progress state',
  props<{ newState: ProgressState }>()
);

export const upsertFeatureState = createAction(
  '[Ui] Update the feature config state',
  props<{ newState: FeaturesConfig }>()
);
