import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { InviteToChannelSelectorComponent } from '../invite-to-channel/components/invite-to-channel-selector/invite-to-channel-selector.component';
import { InviteToChannelComponent } from '../invite-to-channel/components/invite-to-channel/invite-to-channel.component';
import { InviteToNetworkViewComponent } from '../invite-view/components/invite-to-network/invite-to-network-view.component';
import { inviteToNetworkEnabledGuardFn } from '../invite-view/guards/invite-to-network-enabled.guard';

export const inviteToNetwork: WenRoute = {
  path: 'network',
  component: InviteToNetworkViewComponent,
  canActivate: [inviteToNetworkEnabledGuardFn],
  data: {
    routeId: WenRouteId.INVITE_TO_NETWORK
  }
};

export const inviteToChannelSelector: WenRoute = {
  path: 'channel/selector',
  component: InviteToChannelSelectorComponent,
  canActivate: [inviteToNetworkEnabledGuardFn],
  data: {
    routeId: WenRouteId.INVITE_TO_CHANNEL_SELECTOR
  }
};

export const inviteToChannel: WenRoute = {
  path: 'channel/invite',
  component: InviteToChannelComponent,
  canActivate: [inviteToNetworkEnabledGuardFn],
  data: {
    routeId: WenRouteId.INVITE_TO_CHANNEL
  }
};
