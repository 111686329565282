@if (featuredData$ | async; as featuredData) {
  <div class="wen-channel-featured-view-content">
    <wen-channel-messages-view [flowDirection]="'down'" [disableContextMenu]="true" [disableScrollToBottomButton]="true">
      <div wen-messages-view-before-content>
        <wen-navigate-to-channel-button [image]="featuredData.channel.icon" [description]="featuredData.channel.title"
        (clicked)="navigateToChannel(featuredData.channel.id)"></wen-navigate-to-channel-button>
      </div>
    </wen-channel-messages-view>
  </div>
}
@if (commentsEnabled$ | async) {
  <wen-comment-message-send-field
  [parentId]="(featuredData$ | async)?.message.id"></wen-comment-message-send-field>
}