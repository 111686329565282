import { Injectable } from '@angular/core';
import { EmbeddedDocument } from '../../../../core/store/channel/channel.state';
import { BaseMessageModel } from '../models/base-message-box.model';

@Injectable()
export abstract class MessageBoxActionsHandler {
  abstract handleCommentsClicked(messageId: string): void;
  abstract handleDocumentClicked(messageId: string, documentModel: EmbeddedDocument): void;
  abstract handleSenderClicked(messageId: string, authorId: string): void;
  abstract handleForwardClicked(messageId: string): void;
  abstract handleShareClicked(messageId: string): void;
  abstract handleChallengeClicked(messageId: string): void;
  abstract handleReactionClicked(message: BaseMessageModel): void;
  abstract handleMessageContentClicked(message: BaseMessageModel): void;
  abstract handleSwipe(message: BaseMessageModel): void;
}
