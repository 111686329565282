import { createAction, props } from '@ngrx/store';
import { ChatRoomEntity } from './chat.state';

export const requestShareInboundGroupSessionForCurrentRoom = createAction(
  '[Chat] Request to share inbound group session for active chat room'
);

export const requestShareInboundGroupSession = createAction(
  '[Chat] Request to share inbound group session for chat room',
  props<{ room: ChatRoomEntity }>()
);

export const requestShareInboundGroupSessionForCurrentRoomSuccess = createAction(
  '[Chat] Request to share inbound group session for active chat room was successful',
  props<{ roomId: string }>()
);

export const removeShareInboundGroupSessionForCurrentRoom = createAction(
  '[Chat] Remove current inbound group session',
  props<{ roomId: string }>()
);

export const requestExchangeInboundGroupSession = createAction(
  '[Chat] Request to exchange a session with the current user other devices',
  props<{ roomId: string; sessionId: string; senderKey: string }>()
);

export const requestExchangeInboundGroupSessionForCurrentRoom = createAction(
  `[Chat] Request to exchange a session with the current user's other devices for the current room's all known messages`,
  props<{ withDialogFallback: boolean }>()
);

export const requestOpenSyncDialog = createAction(
  `[Chat] Request to open the chat sync dialog`
);

export type SessionsByRoom = { [key: string]: { sessionId: string }[] };

export const onExchangedSessionsReceived = createAction(
  '[Chat] Request to decrypt a set of existing message entites',
  props<{ sessionsByRoom: SessionsByRoom }>()
);

export const retryEventDecryptionPossible = createAction(
  '[Chat] Retry the decryption of the given event',
  props<{ megolmSessionId: string }>()
);
