import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigurationProvider } from '../../../../../core/services/configuration/app-confguration';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { PrivacySettingsChangedValues, PrivacySettingsFormChangedValues } from '../../../../../core/store/form/types/privacy-settings.types';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { PrivacySettingsViewDatasource } from './privacy-settings-view.datasource';

@Component({
  selector: 'wen-privacy-settings-view',
  templateUrl: './privacy-settings-view.component.html',
  providers: [
    PrivacySettingsViewDatasource
  ]
})
export class PrivacySettingsViewComponent implements OnInit {

  readonly privacyPolicyLink = this.translateService.instant('PRIVACY_POLICY_URL');

  privacySettingsForm = new FormGroup<PrivacySettingsFormChangedValues>({
    traceReportsEnabled: new FormControl(false),
  });

  constructor(
    public readonly config: AppConfigurationProvider,
    private formStoreService: FormStoreService<PrivacySettingsChangedValues>,
    private navigationHelper: WenNavigationHelper,
    private datasource: PrivacySettingsViewDatasource,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.datasource.formInitialValue$.subscribe((data) => {
      this.formStoreService.initializeForm(data);
    });
  }

  onLinkClicked(event: Event) {
    event.preventDefault();
    this.navigationHelper.navigateToUrlWithFallback(this.privacyPolicyLink);
  }

}
