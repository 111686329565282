import { UserData } from '../../../../core/store/auth/models/UserData';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ChannelMessageDTO, PagingReplayDirection, SocketIoService } from '@portal/wen-backend-api';
import { combineLatest, exhaustMap, filter, first, map, Observable, of } from 'rxjs';
import { DateUtil } from '../../../../core/common/date/date-util';
import { selectCurrentChannelHasMore, selectCurrentChannelPendingMessages, selectCurrentChannelScheduledMessages } from '../../../../core/store/channel/channel.selectors';
import { ChannelMessageEntity } from '../../../../core/store/channel/channel.state';
import { RootState } from '../../../../core/store/root/public-api';
import { PageRequestEvent, WeFeedDataset, WeFeedItemType, WeFeedMessageItem } from '../../../../shared/components/feed/components/we-feed/we-feed-model';
import { FeedDatasource } from '../../../../shared/components/feed/providers/feed-datasource';
import { ChannelConfigurationProvider } from '../../common/providers/channel-configuration';
import { fetchScheduledMessagesForCurrentChannel, updateScheduledMessages } from '../../../../core/store/channel/channel.actions';

@Injectable()
export class ChannelScheduledMessagesDatasource extends FeedDatasource {

  private scheduledPendingMessageFilterFn = (message: ChannelMessageEntity) => message.scheduled;

  disableEmojiReaction$ = of(true);

  constructor(
    protected store: Store<RootState>,
    private channelConfigurationProvider: ChannelConfigurationProvider,
    private socketIoService: SocketIoService,
  ) {
    super(store);
  }

  bindToSource(): Observable<WeFeedDataset> {
    const commentsEnabled$ = this.channelConfigurationProvider.getCommentsEnabledForCurrentChannel();
    return combineLatest([
      this.store.pipe(select(selectCurrentChannelScheduledMessages)),
      this.store.pipe(
        select(selectCurrentChannelPendingMessages),
        map((projectionFn) => projectionFn(this.scheduledPendingMessageFilterFn))
      ),
      this.disableEmojiReaction$,
      commentsEnabled$,
      this.currentUser$,
    ]).pipe(
      map(([messages, pendingMessages, disableEmojiReaction, commentsEnabled, currentUser]) => {
        const pendingIds = pendingMessages.map(pendingMessage => pendingMessage.id);
        const uniqueMessages = messages.filter(message => !pendingIds.includes(message.id));
        const allMessages = pendingMessages.length ? uniqueMessages.concat(pendingMessages) : uniqueMessages;
        const sortedMessages = allMessages.sort((message1, message2) => DateUtil.compare(message2.timestamp, message1.timestamp));
        const feedItems = sortedMessages.map(message => {
          return this.convertToFeedItem(message, disableEmojiReaction, commentsEnabled, currentUser);
        });
        const dataSet: WeFeedDataset = {
          items: feedItems
        };
        return dataSet;
      })
    );
  }

  loadNextPage(event: PageRequestEvent): Observable<{ hasResult: boolean }> {
    return combineLatest([
      this.store.pipe(select(selectCurrentChannelHasMore)),
      this.store.pipe(select(selectCurrentChannelScheduledMessages)),
    ]).pipe(
      filter(([hasMore, scheduledMessages]) => {
        return hasMore && scheduledMessages?.length > 0;
      }),
      first(),
      exhaustMap(([_, scheduledMessages]) => {
        const { direction } = event;
        const firstMessage = (direction === PagingReplayDirection.Up) ?
          scheduledMessages[0] : scheduledMessages[scheduledMessages.length - 1];
        this.store.dispatch(fetchScheduledMessagesForCurrentChannel({
          channelId: firstMessage.channelId,
          timestamp: firstMessage.timestamp,
          direction
        }));
        return this.waitForNextPage().pipe(
          map((response) => ({ response })),
          first()
        );
      }),
      map(({ response }) => {
        return {
          hasResult: Boolean(response?.hasResult)
        };
      })
    );
  }

  loadFirstPage(): Observable<{ hasResult: boolean }> {
    return of({ hasResult: false });
  }

  private waitForNextPage() {
    return this.socketIoService.channel.scheduledMessagesReplay.listen.pipe(
      map((response) => {
        this.store.dispatch(updateScheduledMessages({
          channelId: response.channelId,
          messages: response.messages,
          hasMore: response.more
        }));
        return {
          hasResult: response.messages?.length > 0
        };
      })
    );
  }

  private convertToFeedItem(message: ChannelMessageDTO, disableEmojiReaction: boolean, commentsEnabled: boolean, currentUser: UserData) {
    const {
      id,
      authorId,
      userId,
      content,
      scheduled,
      embeds,
      timestamp,
      formattedUpdateUserId,
      type,
      updateTimestamp,
    } = message;
    const lastEditorId = formattedUpdateUserId ?? authorId;
    const feedItem: WeFeedMessageItem = {
      key: message.id,
      type: WeFeedItemType.MESSAGEBOX,
      value: {
        messageId: id,
        referenceId: id,
        authorId,
        currentUserUpdater: currentUser.userId === lastEditorId,
        sender: userId,
        content,
        scheduled,
        embeds,
        timestamp,
        shareVisible: false,
        commentsVisible: false,
        commentsEnabled,
        highlighted: false,
        wide: false,
        disableEmojiReaction,
        hideEmojiReactionPlaceholder: false,
        status: null,
        modificationState: type,
        updateTimestamp,
        textToSpeechEnabled: false,
        challengeEnabled: false
      },
    };
    return feedItem;
  }

}
