import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { filter, first, map, mergeMap, Observable, of, tap } from 'rxjs';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { UserData } from '../../auth/models/UserData';
import { fetchPermissionsForChannel } from '../../channel/channel.actions';
import { selectChannelPermission } from '../../channel/channel.selectors';
import { RootState } from '../../root/public-api';

export const createChannelPermissionEffect = (
  actions$: Actions,
  store: Store<RootState>,
  socketIoService: Pick<SocketIoService, 'system'>,
  userData$: Observable<UserData>,
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(fetchPermissionsForChannel),
      filter(({ channelId }) => Boolean(channelId)),
      mergeMap(action => {
        const { channelId, useCache } = action;
        if (useCache) {
          return store.pipe(
            selectorWithParam(selectChannelPermission, channelId),
            first(),
            filter(cachedPermissions => !Boolean(cachedPermissions)),
            map(() => action),
          );
        }
        return of(action);
      }),
      concatLatestFrom(() => userData$),
      tap(([{ channelId }, { userId }]) => {
        socketIoService.system.channelPolicy.emit({ userId, channelId });
      })
    ), { dispatch: false }
  );
};
