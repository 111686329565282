import { InjectionToken, Type } from '@angular/core';
import { MassDataStateAdapter } from '../mass-data-state-adapter';
import { MassDataContainer } from '../mass-data-container';
import { MassDataRepository } from '../repository/mass-data-repository';
import { MassDataPagingAdapter } from '../paging/mass-data-paging-adapter';
import { MassDataType } from '../types';
import { MassDataUpdatesPlugin } from '../updates/mass-data-updates-plugin';

export interface MassDataConfigEntry {
  identifier: MassDataType;
  container: Type<MassDataContainer<any>>;
  adapter: Type<MassDataStateAdapter<any>>;
  repository: Type<MassDataRepository<any>>;
  pagingAdapter: Type<MassDataPagingAdapter<any>>;
  updatesAdapter?: Type<MassDataUpdatesPlugin<any>>;
}

export const MASS_DATA_CONFIG = new InjectionToken<MassDataConfigEntry>('MASS_DATA_CONFIG');
