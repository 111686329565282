import { NgxMatDatepickerBase, NgxMatDatepickerContent } from '@angular-material-components/datetime-picker';
import { NgxMatDateSelectionModel } from '@angular-material-components/datetime-picker/lib/date-selection-model';
import { Subject, first, takeUntil } from 'rxjs';

/**
 * Helper class to bridge between the date input and the picker dialog
 */
export class NgxDatePickerUtils {

  // The currently open datepicker dialog
  private datePickerContent: NgxMatDatepickerContent<any, any>;

  // The currently selected pending date value in the dialog
  private readonly selectedDate = new Subject<Date>();
  public readonly selectedDate$ = this.selectedDate.asObservable();

  constructor(
    ngxPicker: NgxMatDatepickerBase<any, any, any>
  ) {
    /* eslint-disable no-underscore-dangle */
    this.datePickerContent = (ngxPicker as any)?._componentRef.instance;
    const pendingSelectionModel = ((this.datePickerContent as any)._model as NgxMatDateSelectionModel<any, any>);
    pendingSelectionModel.selectionChanged.pipe(
      takeUntil(ngxPicker.closedStream.pipe(
        first()
      ))
    ).subscribe((newValue) => {
      this.selectedDate.next(newValue.selection);
    });
  }

  forceSeletion(selectedDate: Date) {
    this.datePickerContent._handleUserSelection({
      value: selectedDate,
      event: null
    });
  }

}
