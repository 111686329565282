
import { Injectable } from '@angular/core';
import { ChannelConfiguration } from '@portal/wen-backend-api';

@Injectable()
export class FormValueManager {

  private formValue: ChannelConfiguration['config'];

  getFormValue() {
    return this.formValue;
  }

  setFormValue(formValue: ChannelConfiguration['config']) {
    this.formValue = formValue;
  }
}
