import { WenPipesModule } from '../../../../../shared/pipes/wen-pipes.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  CircleImageViewModule,
  ThousandFormatModule,
  WenCardModule,
  WenIconModule,
  WenSectionModule,
} from '@portal/wen-components';
import { EventSectionComponent } from './event-section.component';
import { EventQuickSubscriptionPanelModule } from '../quick-subscription-panel/quick-subscription-panel.module';

@NgModule({
  imports: [
    ThousandFormatModule,
    WenIconModule,
    CommonModule,
    WenSectionModule,
    WenCardModule,
    TranslateModule.forChild(),
    WenPipesModule,
    CircleImageViewModule,
    EventQuickSubscriptionPanelModule
  ],
  exports: [EventSectionComponent],
  declarations: [EventSectionComponent],
  providers: [],
})
export class EventSectionModule {}
