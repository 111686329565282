import { Component, OnInit, InjectionToken, Inject, Optional, ElementRef, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { createPopper, Options, Instance } from '@popperjs/core';

export interface PopperData<T = any> {
  component: any;
  payload: T;
  reference: Element;
  options: Options;
}
export const POPPER_DATA = new InjectionToken<PopperData>('POPPER_DATA');

@Component({
  selector: 'wen-popper',
  templateUrl: './popper.component.html',
  styleUrls: ['./popper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopperComponent<T> implements OnInit, OnDestroy {

  popperRef: Instance;
  portal: ComponentPortal<T>;

  constructor(
    private elementRef: ElementRef,
    @Optional() @Inject(POPPER_DATA) private data: PopperData
  ) { }

  ngOnInit() {
    this.portal = new ComponentPortal(this.data.component);

    const options: Options = {
      strategy: 'absolute',
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ]
    };

    if (this.data.options) {
      Object.assign(options, this.data.options);
    }
    this.popperRef = createPopper(this.data.reference, this.elementRef.nativeElement, options);
  }

  ngOnDestroy() {
    if (this.popperRef) {
      this.popperRef.destroy();
      this.popperRef = null;
    }
  }
}
