import { Injectable } from '@angular/core';
import { RestrictionDTO, RestrictionType } from '@portal/wen-backend-api';
import { SelectionOption } from '@portal/wen-components';
import { Observable, map, shareReplay, withLatestFrom } from 'rxjs';
import { ChannelEntity } from '../../../../../../core/store/channel/channel.state';
import { ChannelRestrictionFormValueConverter } from './channel-restriction-form-value-converter';

export interface ChannelRestrictionsModel {
  visibleRestrictions: SelectionOption<RestrictionType>[];
  initialRestrictions?: SelectionOption<RestrictionType>[];
}

@Injectable()
export class ChannelRestrictionItemProvider {

  private publicChannelRestrictions = [RestrictionType.NoRestriction, RestrictionType.RegistrationRequired];
  private notSelectableRestrictions = [RestrictionType.WalletCredentialRequired];

  restrictionItems$: Observable<ChannelRestrictionsModel>;

  initialize(channel$: Observable<ChannelEntity>) {
    this.restrictionItems$ = this.formValueConverter.restrictionPossibleValues$.pipe(
      withLatestFrom(channel$),
      map(([dynamicRestrictionOptions, currentChannel]) => {
        const noRestrictionOptions = this.formValueConverter.noRestrictionPossibleValues;
        const allRestrictions = [...noRestrictionOptions, ...dynamicRestrictionOptions];
        if (!currentChannel?.id) {
          return {
            visibleRestrictions: allRestrictions,
            initialRestrictions: noRestrictionOptions
          };
        }

        const isPublicChannel = !currentChannel.isPrivate;

        const visibleRestrictions = allRestrictions.filter(restriction => {
          const isPublicRestriction = this.publicChannelRestrictions.includes(restriction.value);
          return isPublicChannel === isPublicRestriction && restriction;
        });

        const selectableChannelRestrictions = currentChannel.restrictions.filter(restriction => {
          return !this.notSelectableRestrictions.includes(restriction.restrictionType);
        });

        const currentRestrictions = selectableChannelRestrictions.length ?
          this.toSelectionOption(selectableChannelRestrictions) : [visibleRestrictions[0]];

        let initialRestrictions;
        if (currentRestrictions?.length > 0) {
          initialRestrictions = visibleRestrictions.filter(visibleRestriction => {
            return currentRestrictions.some(initialRestriction => {
              return visibleRestriction.id === initialRestriction.id || visibleRestriction.value === initialRestriction.value;
            });
          });
        }
        return {
          visibleRestrictions,
          initialRestrictions
        };
      }),
      shareReplay(1)
    );
  }

  constructor(private formValueConverter: ChannelRestrictionFormValueConverter) { }

  private toSelectionOption(restrictions: RestrictionDTO[]): Partial<SelectionOption<RestrictionType>[]> {
    return restrictions.map(restriction => {
      return {
        id: restriction.id,
        text: '',
        value: restriction.restrictionType
      };
    });
  }

}
