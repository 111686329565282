import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { WenSnackbarOpener } from '../../../services/snackbar/wen-snackbar-opener';

export interface WenSnackbarNotificationData {
  notificationIcon: string;
  notificationText: string;
  durationInMilliseconds?: number;
}

@Component({
  selector: 'wen-snackbar-notification',
  templateUrl: './snackbar-notification.component.html',
  styleUrls: ['./snackbar-notification.component.scss']
})
export class SnackbarNotificationComponent implements OnInit {

  constructor(
    private snackbarRef: MatSnackBarRef<WenSnackbarOpener>,
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: WenSnackbarNotificationData
  ) { }

  ngOnInit(): void {
  }

  dismiss(): void {
    this.snackbarRef.dismiss();
  }

}
