import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wen-channel-view-desktop',
  templateUrl: './channel-view-desktop.component.html',
  styleUrls: ['./channel-view-desktop.component.scss']
})
export class ChannelViewDesktopComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
