import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { EmbedDTOTypes } from '../../../../data-types/embed/embed';
import { ChannelEditMessagePayload, ChannelSendPayload } from '../../../../events/events';
import { SocketIoService } from '../../../api-providers/socket-io-service';
import { ChannelMessageComposerData } from './types';

@Injectable()
export class ChannelMessageComposerItem {

  constructor(
    private socketIoService: SocketIoService,
  ) { }

  private createMessage(
    payload: ChannelSendPayload,
    scheduled: boolean
  ) {
    if (scheduled) {
      return this.socketIoService.channel.sendScheduled.acknowledgement$(payload);
    } else {
      return this.socketIoService.channel.send.acknowledgement$(payload);
    }
  }

  private editMessage(
    payload: ChannelEditMessagePayload
  ) {
    if (!payload?.scheduled) {
      delete payload.scheduled;
      delete payload.timestamp;
    }
    this.socketIoService.channel.editMessage.emit(payload);
    return of(null);
  }

  private executeUpsertMessage(
    data: ChannelMessageComposerData,
    isEdit: boolean,
    embedArray?: EmbedDTOTypes[]
  ) {
    const { id, channelId, userId, content, timestamp, scheduled, draft } = data;
    if (isEdit) {
      return this.editMessage({
        id,
        userId,
        content,
        ...embedArray?.length && { embeds: embedArray },
        scheduled,
        timestamp
      });
    } else {
      return this.createMessage({
        id,
        channelId,
        userId,
        content,
        timestamp,
        draft,
        ...embedArray?.length && { embeds: embedArray }
      }, scheduled);
    }
  }

  upsertMessage(
    data: ChannelMessageComposerData,
    isEdit: boolean,
    embeds?: EmbedDTOTypes[]
  ) {
    return this.executeUpsertMessage(data, isEdit, embeds);
  }

}
