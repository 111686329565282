import { NgModule } from '@angular/core';
import { ScrollStateHandlerDirective } from './scroll-state-handler.directive';

@NgModule({
  declarations: [
    ScrollStateHandlerDirective
  ],
  exports: [
    ScrollStateHandlerDirective
  ],
})
export class ScrollStateModule { }
