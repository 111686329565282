import { Component } from '@angular/core';

@Component({
  selector: 'wen-chat-schedules-desktop-view',
  templateUrl: './chat-schedules-desktop-view.component.html',
  styleUrls: ['./chat-schedules-desktop-view.component.scss']
})
export class ChatSchedulesDesktopViewComponent {

}
