<div class="wen-app-detail-layout" cdkScrollable>
  @if (currentApp$ | async; as currentApp) {
    @if (headerData$ | async; as headerData) {
      <wen-detail-header [model]="headerData" [coverImageStyle]="'square'">
        <div wen-detail-header-title>{{ headerData.title }}</div>
        @if (canEdit$ | async) {
          <button wen-detail-header-quick-actions mat-mini-fab color="primary"
            class="mat-elevation-z0" (click)="editApp(currentApp.id)" [wen-test-id]="'wen-app-detail-edit-app-button'">
            <wen-icon iconName="edit" color="white"></wen-icon>
          </button>
        }
        @if (hasAndroidStoreInfo$ | async) {
          <button wen-detail-header-quick-actions mat-mini-fab color="transparent"
            class="mat-elevation-z0" (click)="openAndroidStore(currentApp.androidPlayStoreId)">
            <wen-icon class="wen-icon-huge wen-icon-store wen-icon-colorful" iconName="android_store_logo"></wen-icon>
          </button>
        }
        @if (hasIOSStoreInfo$ | async) {
          <button wen-detail-header-quick-actions mat-mini-fab color="transparent"
            class="mat-elevation-z0" (click)="openIOSStore(currentApp.iosAppName, currentApp.iosAppStoreId)">
            <wen-icon class="wen-icon-huge wen-icon-store wen-icon-colorful" iconName="apple_store_logo"></wen-icon>
          </button>
        }
        <button wen-detail-header-quick-actions *ifSubscribedTo="currentAppId$ | async; extraCondition: hasUri$ | async" mat-mini-fab color="primary"
          class="mat-elevation-z0" (click)="navigateToApp(currentApp)">
          <wen-icon iconName="open_link" color="white"></wen-icon>
        </button>
        <button wen-detail-header-quick-actions *ifSubscribedTo="currentAppId$ | async" mat-mini-fab color="primary"
          class="mat-elevation-z0" (click)="unSubscribeFromApp()">
          <wen-icon iconName="delete" color="white"></wen-icon>
        </button>
      </wen-detail-header>
    }
    @if (currentApp.description || currentApp.categories?.length) {
      <div class="wen-app-detail-content-group">
        @if (currentApp.description) {
          <div class="wen-app-detail-field">
            <div class="wen-app-detail-info-label" portal-caption>{{ 'LABEL_INFO' | translate }}</div>
            <div class="wen-app-detail-info-text" portal-body>{{ currentApp.description }}</div>
          </div>
        }
        @if (currentApp.categories?.length) {
          <wen-read-categories-field
            class="wen-app-detail-field"
            [fieldLabel]="'APP_DETAIL_CATEGORY_DESCRIPTION' | translate"
            [categories]="currentApp.categories">
          </wen-read-categories-field>
        }
      </div>
    }
  }
</div>
<ng-container *ifNotSubscribedTo="currentAppId$ | async">
  <wen-interact-button (click)="subscribeToApp()">{{
  "APP_SUBSCRIBE_BUTTON_LABEL" | translate }}</wen-interact-button>
</ng-container>
