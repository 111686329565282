import { createFeature, createReducer, on } from '@ngrx/store';
import { WalletState } from './wallet.state';
import { walletFeatureKey } from './constants';
import { walletCredentialsRetrieved } from './wallet.actions';

export const walletInitialState: WalletState = {
  credentials: []
};

export const walletFeature = createFeature({
  name: walletFeatureKey,
  reducer: createReducer(
    walletInitialState,
    on(walletCredentialsRetrieved, (_, { newState }) => {
      return {
        credentials: newState
      };
    })
  )
});
