<vg-player (onPlayerReady)="onPlayerReady($event)">
  <video #videoRef [vgMedia]="videoRef" playsInline disablePictureInPicture="true" id="lightbox-video">
    <source [src]="src">
  </video>
  @if (controlsVisible$ | async) {
    <wen-overlay-controls @fadeInOut></wen-overlay-controls>
  }
  @if (controlsVisible$ | async) {
    <wen-video-controls @fadeInOut [videoRef]="videoRef"></wen-video-controls>
  }
</vg-player>
