import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { DateUtil } from '../../../core/common/date/date-util';

const VIEW_SEPARATOR_SCHEDULED_LABEL = 'VIEW_SEPARATOR_SCHEDULED';

@Injectable()
export abstract class MessageSeparatorDateFormatter {
  abstract formatSeparatorDate(dateTime: DateTime): string;
}

@Injectable()
export class FutureMessageSeparatorDateFormatter implements MessageSeparatorDateFormatter {

  constructor(
    private translateService: TranslateService
  ) { }

  formatSeparatorDate(dateTime: DateTime): string {
    if (!dateTime) {
      return null;
    }

    if (DateUtil.isToday(dateTime)) {
      return this.translateService.instant(VIEW_SEPARATOR_SCHEDULED_LABEL) +
        this.translateService.instant('CHANNEL_VIEW_SEPARATOR_TODAY');
    } else if (DateUtil.isTomorrow(dateTime)) {
      return this.translateService.instant(VIEW_SEPARATOR_SCHEDULED_LABEL) +
        this.translateService.instant('CHANNEL_VIEW_SEPARATOR_TOMORROW');
    } else if (DateUtil.isLaterInCurrentYear(dateTime)) {
      return this.translateService.instant(VIEW_SEPARATOR_SCHEDULED_LABEL) + DateUtil.toMonthAndDayOnly(dateTime);
    } else {
      return this.translateService.instant(VIEW_SEPARATOR_SCHEDULED_LABEL) + DateUtil.toDateOnly(dateTime);
    }
  }
}

@Injectable()
export class PastMessageSeparatorDateFormatter implements MessageSeparatorDateFormatter {

  constructor(
    private translateService: TranslateService
  ) { }

  formatSeparatorDate(dateTime: DateTime): string {
    if (!dateTime) {
      return null;
    }

    if (DateUtil.isToday(dateTime)) {
      return this.translateService.instant('CHANNEL_VIEW_SEPARATOR_TODAY');
    } else if (DateUtil.isYesterday(dateTime)) {
      return this.translateService.instant('CHANNEL_VIEW_SEPARATOR_YESTERDAY');
    } else if (DateUtil.isWithinLast7Days(dateTime)) {
      return DateUtil.toLongWeekDay(dateTime);
    } else if (DateUtil.isCurrentYear(dateTime)) {
      return `${DateUtil.toMonthAndDayOnly(dateTime)}`;
    } else {
      return DateUtil.toDateOnly(dateTime);
    }
  }
}
