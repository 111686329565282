<ng-container cdkSelection>
  @if (isGroupedItems) {
    @for (group of visibleSelectionItems; track group) {
      <div portal-caption>{{ group.label | translate }}</div>
      <div class="wen-selection-group">
        @for (item of group.items; track item) {
          <ng-container *ngTemplateOutlet="listItem; context: {$implicit: item}"></ng-container>
        }
      </div>
    }
  } @else {
    @for (item of visibleSelectionItems; track item) {
      <ng-container *ngTemplateOutlet="listItem; context: {$implicit: item}"></ng-container>
    }
  }
</ng-container>


<ng-template #listItem let-data>
  <wen-selection-list-item [selectionOption]="data" [isDisabled]="isDisabled"
    [selectionModel]="selectionModel" [selectionOptionPosition]="selectionOptionPosition"
    [selectionOptionBorderType]="selectionOptionBorderType" [allowSameSelection]="allowEmptyList">
    @if (withImage) {
      <wen-circle-image-view [imageUrl]="data.icon" [placeholderText]="data.text"></wen-circle-image-view>
    }
    <span class="wen-selection-list-text">{{ data.text }}</span>
  </wen-selection-list-item>
</ng-template>
