import { APP_INITIALIZER, Provider } from '@angular/core';
import { NativeBridge } from '@portal/react-native-api';
import { IS_REACT_NATIVE_ROOT, WenNativeApi } from '@portal/wen-native-api';
import { firstValueFrom } from 'rxjs';
import { ShareMenuHandler } from '../navigation/share-menu/share-menu-handler';
import { NativeConnector } from './native-connector';
import { ReactNativeWrapper } from './react-native-wrapper';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function nativeWrapperFactory() {
  return IS_REACT_NATIVE_ROOT ? new ReactNativeWrapper(new NativeBridge()) : null;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function nativeConnectorInitializer(nativeConnector: NativeConnector) {
  return () => {
    if (IS_REACT_NATIVE_ROOT) {
      return firstValueFrom(nativeConnector.connect());
    }
    return Promise.resolve();
  };
}

export const nativeAppProviders: Provider[] = [
  NativeConnector,
  WenNativeApi,
  ShareMenuHandler,
  {
    provide: ReactNativeWrapper,
    useFactory: nativeWrapperFactory
  },
  {
    provide: APP_INITIALIZER,
    useFactory: nativeConnectorInitializer,
    deps: [NativeConnector],
    multi: true
  }
];
