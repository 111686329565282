import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { RoomType, SocketIoService } from '@portal/wen-backend-api';
import { EncryptionInitializer, WenChatClient } from '@portal/wen-chat-client';
import { of } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { InboxProcessor } from '../../services/chat/history/inbox-processor';
import { FeatureEnablementService } from '../../services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../services/navigation/types';
import { WenStorageService } from '../../services/storage/wen-storage.service';
import { PermissionLevel } from '../../services/user-management/permission-level';
import { logout } from '../auth/auth.actions';
import { selectCurrentUserData } from '../auth/auth.selectors';
import { RootState } from '../root/public-api';
import { addDraftChatMember, chatInitializationSuccess, createAndNavigateToDialogChat, initiateDraftMessageWithUser, navigateToRoom, roomCreateRequest, subscribeChatUpdates } from './chat.actions';

@Injectable()
export class ChatEffects {

  initializeChat$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => this.store.pipe(
      select(selectCurrentUserData),
      first()
    )),
    switchMap((userData) => {
      const lastUserId = this.storageService.getLastUserId();
      if (lastUserId !== userData.userId) {
        return this.chatClient.clearData().pipe(
          map(() => userData)
        );
      }
      return of(userData);
    }),
    tap(({ userId }) => {
      this.storageService.setLastUserId(userId);
    }),
    filter((userData) => {
      const isChatEnabled = this.featureEnablementService.isChatEnabled();
      const isAnonymous = userData.permissionLevel === PermissionLevel.ANONYMOUS;
      return !isAnonymous && isChatEnabled;
    }),
    switchMap(() => {
      const inits$ = this.encryptionInitializer.initialize();
      return inits$.pipe(
        switchMap(() => {
          return this.socketIoService.chat.oneTimeKeysStatus.listen.pipe(
            switchMap((data) => {
              const { deviceOneTimeKeysCount } = data;
              if (deviceOneTimeKeysCount > 0) {
                return of(null);
              }
              return this.chatClient.ensureOneTimeKeys(deviceOneTimeKeysCount);
            }),
            first()
          );
        }),
      );
    }),
    switchMap(() => {
      return this.inboxProcessor.startInboxProcessing();
    }),
    map(() => chatInitializationSuccess())
  ));

  initiateDraftMessageWithUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateDraftMessageWithUser),
      switchMap(({ user }) => {
        return this.socketIoService.chat.room.getDialogId.acknowledgement$({ userId: user.userId }).pipe(
          map(({ dialogId }) => {
            if (dialogId) {
              return navigateToRoom({ roomId: dialogId, fromCreateMode: false });
            }
            this.navigationHelper.navigateToDialogChatCreation();
            const { userId: id, avatarUrl, displayName: title } = user;
            return addDraftChatMember({
              user: { id, avatarUrl, title }
            });
          })
        );
      })
    )
  );

  createAndNavigateToDialogChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAndNavigateToDialogChat),
      switchMap(({ userId }) => {
        return this.socketIoService.chat.room.getDialogId.acknowledgement$({ userId }).pipe(
          map(({ dialogId }) => {
            if (dialogId) {
              return navigateToRoom({ roomId: dialogId, fromCreateMode: false });
            }
            return roomCreateRequest({ targetUserIds: [userId], roomType: RoomType.DIALOG, fromCreateMode: false });
          })
        );
      })
    )
  );

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(logout),
    filter(({ isByUserInteraction }) => isByUserInteraction),
    switchMap(() => {
      return this.chatClient.clearData();
    })
  ), { dispatch: false });

  constructor(
    private store: Store<RootState>,
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private navigationHelper: WenNavigationHelper,
    private encryptionInitializer: EncryptionInitializer,
    private featureEnablementService: FeatureEnablementService,
    private chatClient: WenChatClient,
    private storageService: WenStorageService,
    private inboxProcessor: InboxProcessor,
  ) {
  }

}
