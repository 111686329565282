import { ActionCreator, ReducerTypes, on } from '@ngrx/store';
import { MediaContextTypes } from '@portal/wen-backend-api';
import { removeAllScheduledChatMessages, removeOneScheduledChatMessage, upsertManyScheduledChatMessages, upsertScheduledChatHistoryStateExtras } from '../actions/chat-scheduled-messages.actions';
import { updateChatMessageMediaByStatus } from '../chat.actions';
import { ChatState, scheduledChatMessagesStateAdapter } from '../chat.state';
import { updateMessageMediaEmbedByUploadStatus } from '../utils/chat-room-utils';

export const chatScheduledMessageReducers: ReducerTypes<ChatState, ActionCreator[]>[] = [
  on(upsertScheduledChatHistoryStateExtras, (state, data) => {
    const { roomId, extras } = data;
    const newRooms = scheduledChatMessagesStateAdapter.upsertStateExtras(roomId, state.rooms, extras);
    return { ...state, rooms: newRooms };
  }),
  on(upsertManyScheduledChatMessages, (state, data) => {
    const { roomId, messages, flags } = data;
    const newRooms = scheduledChatMessagesStateAdapter.upsertMany(roomId, messages, state.rooms, {
      ...flags
    });
    return { ...state, rooms: newRooms };
  }),
  on(removeAllScheduledChatMessages, (state, data) => {
    const { roomId } = data;
    const newRooms = scheduledChatMessagesStateAdapter.removeAll(roomId, state.rooms);
    return { ...state, rooms: newRooms };
  }),
  on(removeOneScheduledChatMessage, (state, data) => {
    const { eventId, roomId } = data;
    const newRooms = scheduledChatMessagesStateAdapter.removeOne(roomId, eventId, state.rooms);
    return { ...state, rooms: newRooms };
  }),
  on(updateChatMessageMediaByStatus, (state, data) => {
    const { status } = data;
    const newRooms = scheduledChatMessagesStateAdapter.mapOne({
      id: status.contexts.find(c => c.type === MediaContextTypes.CHAT_MESSAGE).id,
      map: (scheduledEntity) => {
        return updateMessageMediaEmbedByUploadStatus(scheduledEntity, status);
      }
    }, state.rooms);
    return { ...state, rooms: newRooms };
  }),
];
