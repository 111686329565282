import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { PublicUserProfileResponse } from '@portal/wen-backend-api';
import { createAndNavigateToDialogChat } from '../../../../../../core/store/chat/chat.actions';
import { RootState } from '../../../../../../core/store/root/public-api';
import { ForbiddenForAnonymousUserDialogService } from '../../../../../../shared/services/forbidden-for-anonymous-user-dialog.service';

@Injectable()
export class ProfileActionsHandler {

  private store = inject(Store<RootState>);
  private dialogOpener = inject(ForbiddenForAnonymousUserDialogService);

  initiateChatWithUser(profile: PublicUserProfileResponse) {
    const { userId } = profile;
    if(!this.dialogOpener.isAnonymous) {
      this.store.dispatch(createAndNavigateToDialogChat({ userId }));
    } else {
      this.dialogOpener.openChatCreationForbiddenWhenAnonymous().subscribe();
    }
  }

}
