import { Update } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { commentDeleteResponse, deleteComments, removeDraftComment, updateCommentCount, updateComments, updateDraftComment } from './comments.actions';
import { CommentEntity, commentEntityAdapter, CommentState } from './comments.state';
import { commentsFeatureKey } from './constants';
import { commentHelper } from './util/comment-helper';


export const commentsInitialCommentsState: CommentState = {
  comments: commentEntityAdapter.getInitialState({}),
  draftComment: null,
};

export const commentsFeature = createFeature({
  name: commentsFeatureKey,
  reducer: createReducer(
    commentsInitialCommentsState,
    on(updateComments, (state, { comments, paging, parentId }) => {
      const existingCommentEntity = state.comments.entities[parentId];
      const commentEntity: CommentEntity = {
        referenceId: parentId,
        pagingInformation: paging,
        commentList: paging.page === 0 ? comments : [...existingCommentEntity?.commentList ?? [], ...comments]
      };

      return {
        ...state,
        comments: commentEntityAdapter.upsertOne(commentEntity, state.comments),
      };
    }),
    on(updateCommentCount, (state, { commentCountByParentId }) => {
      return {
        ...state,
        comments: commentHelper.setCount(commentCountByParentId, state.comments),
      };
    }),
    on(commentDeleteResponse, (state, { type, ...response }) => {
      const matchingCommentEntity = state.comments.entities[response.parentId];
      const commentEntityUpdate: Update<CommentEntity> = {
        id: response.parentId,
        changes: {
          ...matchingCommentEntity,
          commentList: matchingCommentEntity.commentList.filter(c => c.id !== response.id)
        }
      };
      return {
        ...state,
        comments: commentEntityAdapter.updateOne(commentEntityUpdate, state.comments)
      };
    }),
    on(deleteComments, (state, { messageIds }) => {
      return {
        ...state,
        comments: commentHelper.deleteComments(messageIds, state.comments)
      };
    }),
    on(updateDraftComment, (state, { comment }) => {
      return {
        ...state,
        draftComment: {...state.draftComment, ...comment}
      };
    }),
    on(removeDraftComment, (state) => {
      return {
        ...state,
        draftComment: null
      };
    }),
  ),
});
