import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenIconModule } from '@portal/wen-components';
import { TextToSpeechButtonComponent } from './text-to-speech-button/text-to-speech-button.component';
import { TextToSpeechService } from '../../../core/services/text-to-speech/text-to-speech.service';
import { PastMessageSeparatorDateFormatter } from '../../directives/directives/message-separator-formatters';
import { TranslateService } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule
  ],
  declarations: [
    TextToSpeechButtonComponent
  ],
  exports: [
    TextToSpeechButtonComponent
  ],
  providers: [
    TextToSpeechService,
    PastMessageSeparatorDateFormatter,
    TranslateService
  ]
})
export class TextToSpeechModule { }
