import { Inject, Injectable } from '@angular/core';
import { BACKEND_API_CONFIG, BackendApiConfig, SocketIoConfig, SocketIoService, AppConfig } from '@portal/wen-backend-api';
import { APP_CONFIG } from '../../../frame/api/tokens';
import { DateUtil } from '../../common/date/date-util';
import { WenStorageService } from '../storage/wen-storage.service';
import { WenOAuthService } from '../user-management/wen-oauth.service';
import { SocketIoAuthValidator } from './hooks/socket-io-auth-validator';
import { SocketIoUpstreamGuard } from './hooks/socket-io-upstream-guard';

@Injectable()
export class SocketIoServiceImpl extends SocketIoService {

  constructor(
    socketIoAuthValidator: SocketIoAuthValidator,
    socketIoUpstreamGuard: SocketIoUpstreamGuard,
    @Inject(BACKEND_API_CONFIG) backendApiConfig: BackendApiConfig,
    @Inject(APP_CONFIG) appConfig: AppConfig,
    storageService: WenStorageService,
    oauthService: WenOAuthService,
  ) {
    const config: SocketIoConfig = {
      url: backendApiConfig.backendUrl,
      authHelloOptions: {
        disableChatRelevantAutoEmit: true,
        disableChannelRelevantAutoEmit: true,
        disableChatSummaryAutoEmit: true,
        disableChannelSummaryAutoEmit: true,
      },
      network: backendApiConfig.network,
      socketIoOptions: backendApiConfig.socketIoOptions,
      connectionDataProvider: {
        getDeviceId: () => storageService.getDeviceId(),
        getIdToken: () => oauthService.getIdToken(),
      },
      upstreamGuard: socketIoUpstreamGuard,
      authValidator: socketIoAuthValidator,
      getCurrentDate: () => DateUtil.currentDateString(),
    };
    super(config);
  }

}
