import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmbedDTOType, GeoAutocompleteFeature, GeoFeatureEntityType } from '@portal/wen-backend-api';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LocationCustomEndpoint, LocationSelectorModel } from '../../../../../location-selector/location-selector.component';

export interface GeoFilterLocationSelectorDialogData {
  activeGeoFilter: GeoAutocompleteFeature;
  geoFilterType?: GeoFeatureEntityType[];
  customPlaceholder?: string;
  customEndpoint?: LocationCustomEndpoint;
}

export interface GeoFilterLocationSelectorDialogResponse {
  selectedGeoFilter: GeoAutocompleteFeature;
}

@Component({
  selector: 'wen-geo-filter-location-selector',
  templateUrl: './geo-filter-location-selector.component.html',
  styleUrls: ['./geo-filter-location-selector.component.scss']
})
export class GeoFilterLocationSelectorComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  locationSelectorForm: FormGroup;

  get embedControl() {
    return this.locationSelectorForm.controls.embedLocation;
  }

  get initialValue() {
    const value: LocationSelectorModel = {
      locationData: this.dialogData.activeGeoFilter,
      type: EmbedDTOType.LOCATION,
      geoEntityTypeRestriction: this.dialogData.geoFilterType || null,
      customPlaceholder: this.dialogData.customPlaceholder || null
    };
    return value;
  }

  customEndpoint = this.dialogData?.customEndpoint || null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: GeoFilterLocationSelectorDialogData,
    private dialogRef: MatDialogRef<GeoFilterLocationSelectorComponent, GeoFilterLocationSelectorDialogResponse>,
  ) {
    this.locationSelectorForm = new FormGroup({
      embedLocation: new FormControl(this.initialValue)
    });
  }

  ngOnInit(): void {
    const valueChanges$: Observable<LocationSelectorModel> = this.embedControl.valueChanges;
    valueChanges$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((val) => {
      this.dialogRef.close({
        selectedGeoFilter: val.locationData
      });
    });
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
