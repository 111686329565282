import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CircleInitialViewComponent } from './circle-initial-view.component';
import { ColorizeModule } from '../../directives/colorize/colorize.module';
import { CircleInitialViewFallbackDirective } from './circle-initial-view-fallback.directive';

@NgModule({
  imports: [
    CommonModule,
    ColorizeModule
  ],
  declarations: [
    CircleInitialViewComponent,
    CircleInitialViewFallbackDirective,
  ],
  exports: [
    CircleInitialViewComponent,
    CircleInitialViewFallbackDirective,
  ]
})
export class CircleInitialViewModule { }
