import { Injectable } from '@angular/core';
import { NetworkDTO } from '@portal/wen-backend-api';
import { WenClientHomeCoverImageConfig, WenClientStyleConfig, WenClientWeatherConfig } from '../../core/services/customization/customization-types';
import { Observable } from 'rxjs';

export const networkIdentifier = 'networkId';

@Injectable()
export abstract class ClientCustomizationProvider {
  abstract getDefaultStyleConfig(): WenClientStyleConfig;
  abstract getDefaultWeatherConfig(): WenClientWeatherConfig;
  abstract getDefaultHomeCoverImageConfig(): WenClientHomeCoverImageConfig;
}

@Injectable()
export abstract class NetworkInitializationHandler {
  abstract setupNetwork(networkId: string): Observable<NetworkDTO>;
}
