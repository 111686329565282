import { inject } from '@angular/core';
import { Action, ActionReducer } from '@ngrx/store';
import { PersistenceStorage } from '../providers/persistence-storage';
import { StorePersistenceDataSerializer } from '../store-persistence.tokens';
import { deepMerge } from '../util/deepmerge';
import { rehydrateAppState, scheduleSaveAppState } from './persistence.actions';

const mergeStates = (state: any, rehydratedState: any) => {
  const overwriteMerge = (destinationArray: any, sourceArray: any) => sourceArray;
  state = deepMerge(state, rehydratedState, {
    arrayMerge: overwriteMerge,
  });
  return state;
};

export const WEN_ASYNC_PERSISTENCE_META_REDUCER = (
) => (reducer: ActionReducer<any>): ActionReducer<any> => {
  const storeDataSerializer = inject(StorePersistenceDataSerializer);
  const storage = inject(PersistenceStorage);
  return function(state: any, action: Action): any {
    if (action.type === rehydrateAppState(null).type) {
      const { restoredState } = action as any;
      state = mergeStates(state, restoredState);
      return reducer(state, action);
    } else if (action.type === scheduleSaveAppState().type) {
      const nextState = reducer(state, action);
      const backup = storeDataSerializer.serialize(nextState);
      if (backup) {
        storage.scheduleStateBackup(backup);
      }
      return nextState;
    }
    return reducer(state, action);
  };
};
