import { createAction, props } from '@ngrx/store';
import { PagingHistoryFlags, ScheduledRoomEventsReplayPayload } from '@portal/wen-backend-api';
import { CollectionEntityStateExtras } from '../../common/entity-state-helpers/nested-entity-adapter';
import { ChatScheduledMessageEntity } from '../chat.state';

export const fetchScheduledChatMessages = createAction(
  '[Chat] Fetch a page of scheduled chat messages',
  props<{ payload: ScheduledRoomEventsReplayPayload }>()
);

export const fetchScheduledChatMessagesComplete = createAction(
  '[Chat] Fetching of a page of scheduled chat messages was complete',
  props<{ roomId: string; hasResult: boolean }>()
);

export const requestRedactScheduledChatEvent = createAction(
  `[Chat] Request redact of a scheduled chat message's event for the current room`,
  props<{ eventId: string }>()
);

export const removeAllScheduledChatMessages = createAction(
  '[Chat] Remove all scheduled chat messages in the store collection state',
  props<{ roomId: string }>()
);

export const upsertScheduledChatHistoryStateExtras = createAction(
  '[Chat] Upsert the extras in the scheduled chat history entity state',
  props<{ roomId: string; extras: Partial<CollectionEntityStateExtras> }>()
);

export const upsertManyScheduledChatMessages = createAction(
  '[Chat] Add the scheduled chat messages to the entity state',
  props<{ roomId: string; messages: ChatScheduledMessageEntity[]; flags: PagingHistoryFlags }>()
);

export const removeOneScheduledChatMessage = createAction(
  '[Chat] Remove the given schdeuled chat message from the entity state',
  props<{ roomId: string; eventId: string }>()
);
