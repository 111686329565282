<form wenFormStore class="wen-form" [formGroup]="privacySettingsForm">
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper">
      <div class="wen-form-field wen-toggle-field">
        <div>{{ "USER_PROFILE_PRIVACY_SETTINGS_OPTION_LABEL" | translate }}</div>
        <wen-slide-toggle [forFormControlName]="'traceReportsEnabled'"></wen-slide-toggle>
      </div>
    </div>
    <div class="wen-form-group-hint" portal-caption>
      {{ "USER_PROFILE_PRIVACY_SETTINGS_OPTION_TEXT.0" | translate }}
      <a portal-caption [href]="privacyPolicyLink" (click)="onLinkClicked($event)">
        {{"USER_PROFILE_PRIVACY_SETTINGS_OPTION_TEXT.1" | translate }}</a>
      {{ "USER_PROFILE_PRIVACY_SETTINGS_OPTION_TEXT.2" | translate }}
    </div>
  </div>
</form>