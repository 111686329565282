import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wen-registration-code-resend',
  templateUrl: './registration-code-resend.component.html',
  styleUrls: ['./registration-code-resend.component.scss']
})
export class RegistrationCodeResendComponent implements OnInit {

  @Input() disabled: boolean;
  @Input() remaining: number;
  @Output() resend = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.resend.emit();
  }

}
