import { WenRoute, WenRouteId } from '../../../frame/routing/types';
import { ContactsListComponent } from '../contacts-list/components/contacts-list/contacts-list.component';
import { PendingContactsListComponent } from '../pending-contacts-list/components/pending-contacts-list/pending-contacts-list.component';

export const contactsListRoute: WenRoute = {
  path: 'list',
  component: ContactsListComponent,
  data: {
    routeId: WenRouteId.CONTACTS_LIST
  }
};

export const pendingContactsListRoute: WenRoute = {
  path: 'pending-list',
  component: PendingContactsListComponent,
  data: {
    routeId: WenRouteId.PENDING_CONTACTS_LIST
  }
};
