import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogLayoutContentDirective } from './dialog-layout-content.directive';
import { DialogLayoutHeaderLeftDirective, DialogLayoutHeaderRightDirective, DialogLayoutHeaderTitleDirective } from './dialog-layout-header/dialog-layout-header-parts';
import { DialogLayoutHeaderComponent } from './dialog-layout-header/dialog-layout-header.component';
import { DialogLayoutComponent } from './dialog-layout.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DialogLayoutComponent,
    DialogLayoutContentDirective,
    DialogLayoutHeaderComponent,
    DialogLayoutHeaderLeftDirective,
    DialogLayoutHeaderTitleDirective,
    DialogLayoutHeaderRightDirective,
  ],
  exports: [
    DialogLayoutComponent,
    DialogLayoutContentDirective,
    DialogLayoutHeaderComponent,
    DialogLayoutHeaderLeftDirective,
    DialogLayoutHeaderTitleDirective,
    DialogLayoutHeaderRightDirective,
  ]
})
export class DialogLayoutModule { }
